<template>
  <div class="filter-result">
    <div class="first_main">
      <ul class="first_list">
        <li
          v-for="item in firstList"
          :key="item.id"
          :class="titleActive === item.id ? 'active' : ''"
          @click="changTitleType(item.id)"
        >
          {{ item.name }}
        </li>
      </ul>
      <i class="line" :style="{ left: `${titleActive * 1.36}rem` }"></i>
    </div>
    <ERefreshLoadV3
      ref="ERefreshLoadV3"
      class="main_list"
      :beforeRefresh="onRefresh"
      :moreData="onLoad"
      :finished="finished"
    >
      <template>
        <component
          :is="compComponent(titleActive)"
          :isNovelItem="type === 3 ? 'novel' : ''"
          class="detail_list"
          :itemList="dataList"
          :datasList="dataList"
        >
          <template #left="data">
            <img
              class="collect_icon"
              :src="
                data.item.isCollect
                  ? require('@/assets/imgs/acg/acg-is-collect.png')
                  : require('@/assets/imgs/acg/acg_no-collect.png')
              "
              alt=""
            />
            <div class="title">
              <img src="@/assets/imgs/acg/comisc_card_play.png" alt="" />
              <p>{{ data.item.title }}</p>
            </div>
          </template>

          <template #right="data">
            <img
              class="collect_icon"
              :src="
                data.item.isCollect
                  ? require('@/assets/imgs/acg/acg-is-collect.png')
                  : require('@/assets/imgs/acg/acg_no-collect.png')
              "
              alt=""
            />
            <div class="title">
              <img src="@/assets/imgs/acg/comisc_card_play.png" alt="" />
              <p>{{ data.item.title }}</p>
            </div>
          </template>
        </component>
      </template>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { filterResult } from '@/api/acg'
export default {
  components: {
    // 小说或者漫画
    ComicsOrNovelDetail: () => import('@/components/ComicsTypeCard/columnSixCard.vue'),
    // 动漫瀑布流
    CartoonDetail: () => import('@/components/WaterfallList/notAi.vue')
  },
  props: {
    type: {
      type: Number,
      default() {
        return 1
      }
    },

    parentId: {
      type: Number,
      default() {
        return 1
      }
    },
    secondTags: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      firstList: [
        { id: 0, name: '精选推荐' },
        { id: 1, name: '最新发布' },
        { id: 2, name: '最高热度' }
      ],
      titleActive: 0,
      pageSize: 10,
      pageNum: 0,
      finished: false,
      dataList: []
    }
  },
  methods: {
    changTitleType(id) {
      this.titleActive = id
      this.pageNum = 0
      this.onLoad()
    },
    async onLoad() {
      this.pageNum++
      await this.getFilterResult()
    },
    onRefresh() {
      this.dataList = []
      this.pageNum = 0
    },
    async getFilterResult(type) {
      if (type) {
        this.dataList = []
        this.pageNum = 1
      }
      const res = await filterResult({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        secondaryTag: this.secondTags,
        sort: this.titleActive,
        tag: this.parentId,
        type: this.type
      })
      if (res.code === 200) {
        const lisType = {
          1: 'comicsList',
          2: 'mediaList',
          3: 'novelList'
        }
        let typeList = res.data[lisType[this.type]]
        if (typeList && typeList.length) {
          this.dataList = [...this.dataList, ...typeList]
          if (typeList.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
        }
      } else {
        return this.$toast(res.tip)
      }
    },
    compComponent(detailType) {
      switch (detailType) {
        case 'comics':
        case 'novel':
          return 'ComicsOrNovelDetail'
        case 'cartoon':
          return 'CartoonDetail'
        default:
          return 'ComicsOrNovelDetail'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.first_main {
  position: relative;
  font-size: 0.26rem;
  flex: none;
  z-index: 1;
  width: 1.36 * 3rem;
  margin: 0.36rem auto;
  .first_list {
    border-radius: 0.64rem;
    background: rgba(0, 0, 0, 0.06);
    @include flexbox($jc: flex-center);

    li {
      width: 1.36rem;
      height: 0.5rem;
      text-align: center;
      line-height: 0.5rem;
    }

    .active {
      color: #fff;
    }
  }
  .line {
    position: absolute;
    border-radius: 0.27rem;
    background: #e8619d;
    top: 0;
    padding: 0.04rem 0.16rem;
    width: 1.36rem;
    height: 0.5rem;
    z-index: -1;
    transition: left 0.1s linear;
  }
}
.main_list {
  padding: 0.32rem;
}
</style>
