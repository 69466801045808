<template>
  <div>
    <div class="title" @click="handleClickTitle">{{ title }} <img src="@/assets/imgs/home/more.png" alt="" /></div>
    <div class="content">
      <div v-for="(data, index) in videoList" :key="index" class="content-item" @click="toShortVideo(list, index)">
        <div class="cover">
          <EDecryptImg :imgURL="data.coverImg" class="cover-img"> </EDecryptImg>
          <VideoCardMask :data="data"></VideoCardMask>
        </div>
        <VideoCardFooter :data="data"></VideoCardFooter>
      </div>
    </div>
    <div class="footerBtn">
      <div @click.stop="handleClickTitle"><img src="@/assets/imgs/home/more2.png" alt="" /> 更多片源</div>
      <div @click="fn_videoTopicChange"><img src="@/assets/imgs/home/reload.png" alt="" /> 更换一批</div>
    </div>
  </div>
</template>

<script>
import { videoTopicChange } from 'api/home'
import { JumpTo } from '@/utils/utils_tools'
export default {
  components: {
    VideoCardFooter: () => import('@/components/Home/VideoCardFooter.vue'),
    VideoCardMask: () => import('@/components/Home/VideoCardMask.vue')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    id: {
      type: Number,
      default: () => {
        return 0
      }
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      videoList: []
    }
  },
  watch: {
    list: {
      handler(val) {
        console.log(val)
        this.videoList = val
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    toShortVideo(list, index) {
      this.$store.commit('SET_VIDEOLIST', list)
      this.$store.commit('SET_SHORT_VIDEO_INDEX', index)
      this.$router.push({
        path: '/play/shortVideo'
      })
    },
    async fn_videoTopicChange() {
      const res = await videoTopicChange({
        id: this.id,
        count: 6
      })
      if (res.code === 200 && res.data) {
        this.videoList = res.data.mediaList
      } else {
        return this.$toast(res.tip)
      }
    },
    handleClickTitle() {
      if (this.item.jumpType) {
        if (this.item.jumpUrl) {
          JumpTo(`${this.item.jumpUrl}?showType=${this.item.showType}}`)
        } else {
          this.$router.push({
            path: '/home/videoTopicDetail',
            query: {
              id: this.item.id,
              name: this.item.title,
              showType: this.item.showType || ''
            }
          })
        }
      } else {
        this.$emit('handleClickTitle', this.item.jumpCategoryId)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  width: 100%;
  height: 0.44rem;
  font-size: 0.32rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content {
  margin-top: 0.24rem;
  @include flexbox($jc: flex-start, $ai: flex-start, $fd: row, $fw: wrap, $flex: flex);

  .content-item {
    width: 1.09 * 2rem;
    height: 2.18 * 2rem;
    margin-right: 0.08 * 2rem;
    flex-shrink: 0;
    position: relative;
    margin-bottom: 0.32rem;
    &:nth-child(3n + 3) {
      margin-right: 0;
    }
    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
      margin-bottom: 0;
    }

    .cover {
      position: relative;
      height: 1.45 * 2rem;
      border-radius: 0.06rem 0.06rem 0 0;
      overflow: hidden;
    }
  }
}

.footerBtn {
  margin: 0.32rem 0.29rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    width: 2.76rem;
    height: 0.64rem;
    border-radius: 0.37rem;
    background-color: #f6f6f6;
    border: 0.02rem solid #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.2rem;
    }
  }

  > div:last-child {
    background: #fff;
    border: 0.02rem solid #666666;
  }
}
</style>
