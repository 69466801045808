<template>
  <div class="session-main" :style="{ height }">
    <EBackTop :open-fallback="true" :title="friendInfo.nickName" class="back" />
    <van-pull-refresh
      v-model="refreshing"
      pulling-text=""
      success-text=""
      loosing-text="加载消息中..."
      pull-distance="20"
      head-height="40"
      @refresh="onRefresh"
    >
      <div class="conetnt-list message-listWrap">
        <div
          v-for="(item, index) in msgList"
          :key="index"
          class="msg-inner-item"
          :class="{ right: item.fromId === userInfo.id }"
        >
          <EDecryptImg
            :class="{
              avatarLeft: item.fromId !== userInfo.id,
              avatarRight: item.fromId === userInfo.id
            }"
            :imgURL="item.fromId === userInfo.id ? userInfo.avatarUrl : item.avatar"
          >
          </EDecryptImg>

          <div
            class="msg-item msg-align-left"
            :class="{
              reply: item.fromId !== userInfo.id,
              sendMsg: item.fromId === userInfo.id,
              'msg-align-left': item.fromId !== userInfo.id,
              'msg-align-right': item.fromId === userInfo.id
            }"
          >
            <div
              v-if="!item.picture"
              class="msg-item-content"
              :class="{
                'msg-border-ridius-right': item.fromId === userInfo.id,
                'msg-color-right': item.fromId === userInfo.id,
                'msg-border-ridius-left': item.fromId !== userInfo.id,
                'msg-color-left': item.fromId !== userInfo.id
              }"
              style=""
            >
              <p>
                {{ item.text }}
              </p>
            </div>
            <div
              v-else
              class="msg-item-picture"
              :class="{
                'msg-border-ridius-right': item.fromId === userInfo.id,
                'msg-color-right': item.fromId === userInfo.id,
                'msg-border-ridius-left': item.fromId !== userInfo.id,
                'msg-color-left': item.fromId !== userInfo.id
              }"
              style=""
            >
              <EDecryptImg :imgURL="item.picture"> </EDecryptImg>
            </div>
            <div
              class="msg-item-time"
              :class="{
                'msg-item-time-right': item.fromId === userInfo.id,
                'msg-item-time-left': item.fromId !== userInfo.id
              }"
            >
              {{ item.createdAt | formatDate }}
            </div>
          </div>
        </div>
        <div id="scrollIntoViewChat"></div>
      </div>
    </van-pull-refresh>
    <!-- 评论输入框 -->
    <div class="comment-text">
      <!-- <van-loading class="loading" /> -->

      <van-field v-model="text" class="send-input" placeholder="请输入你的文字内容" />
      <div class="uploadImg-wrap">
        <van-uploader ref="Uploader" class="btn" :after-read="onReadImg" multiple accept="image/*"> </van-uploader>
      </div>
      <div class="send-text" @click="sendMsg"></div>
    </div>
    <NoticeVipPop v-model="showVipPop" />
  </div>
</template>

<script>
import { get_message_info, send_message } from 'api/chat'
import { upload_imgs } from '@/utils'

export default {
  components: {
    NoticeVipPop: () => import('@/components/Popup/noticeVipPop.vue')
  },
  validate({ params }) {
    // 必须是number类型
    return /^\d+$/.test(params.userID)
  },

  data() {
    return {
      showVipPop: false,
      id: 0,
      nickName: '',
      msgList: [],
      text: '',
      picture: null,
      pageNum: 1,
      pageSize: 20,
      refreshing: false,
      timer: null,
      hasPageNumSuccess: true,
      navTit: '',
      upLoadImgs: [],
      // 聊天对象信息
      friendInfo: {
        id: '',
        nickName: '',
        avatar: ''
      },
      height: '100vh'
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.info
    },
    // 获取个人user
    userId() {
      return this.$store.getters['user/userId']
    },
    isMember() {
      return this.$store.getters['isZsMember']
    }
  },
  async mounted() {
    this.computeHeight()
    await this.initUserData()
    if (this.isMember) {
      await this.getMessageInfo()
      this.fn_scrollView('start')
    } else {
      this.showVipPop = true
      this.$toast('亲，只有会员才支持私信哦～')
      return
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  },
  methods: {
    computeHeight() {
      let height = window.innerHeight
      const fontSize = +document.documentElement.style.fontSize.replace('px', '')
      this.height = (height - 60) / fontSize + 'rem'
    },
    // 选择图片
    onReadImg(file) {
      file = Array.isArray(file) ? file : [file]
      if (this.upLoadImgs && file.length + this.upLoadImgs.length > 1) {
        return this.$toast('最多传1张')
      }
      this.upLoadImgs.push(...file)
      this.sendPictureMsg()
    },
    // 初始化聊天对象数据
    initUserData() {
      this.friendInfo.id = Number(this.$route.params.userID)
      this.friendInfo.nickName = this.$route.query.nickName
      this.friendInfo.avatar = this.$route.query.avatar
      if (this.friendInfo.nickName) {
        this.navTit = `与${this.friendInfo.nickName}私信中`
      } else {
        this.navTit = `私信`
      }
    },

    //获取消息列表
    async getMessageInfo(query) {
      if (!query) {
        query = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          peerId: this.friendInfo.id
        }
      }
      const res = await get_message_info(query)
      if (res.code === 200) {
        this.refreshing = false
        this.msgList = [...res.data.messageList.reverse(), ...this.msgList]
        if (!res.data.messageList || (res.data && res.data.messageList.length < this.pageSize)) {
        }
      } else {
        this.refreshing = false
        this.$toast(res.tip)
      }
    },
    //上拉刷新
    onRefresh() {
      ++this.pageNum
      this.getMessageInfo()
    },
    //发送消息
    async sendMsg() {
      if (!this.isMember) return
      if (!this.text) return this.$toast('请输入消息')
      const picture = await upload_imgs(this.upLoadImgs)
      const res = await send_message({
        text: this.text,
        picture: picture[0],
        peerId: this.friendInfo.id
      })

      if (res.code === 200) {
        this.msgList.push({
          createdAt: Date.now(),
          text: this.text,
          picture: picture[0],
          fromId: this.userInfo.id
        })
        this.$toast('发送成功')

        this.text = ''
        this.$nextTick(() => {
          this.fn_scrollView()
        })
      } else {
        this.$toast(res.tip)
      }
    },
    //发送图片消息
    async sendPictureMsg() {
      if (!this.isMember) return
      const picture = await upload_imgs(this.upLoadImgs)
      const res = await send_message({
        text: '',
        picture: picture[0],
        peerId: this.friendInfo.id
      })

      if (res.code === 200) {
        this.msgList.push({
          createdAt: Date.now(),
          picture: picture[0],
          text: this.text,
          fromId: this.userInfo.id
        })
        this.$toast('发送成功')

        this.text = ''
        this.upLoadImgs = []
        this.$nextTick(() => {
          this.fn_scrollView()
        })
      } else {
        this.$toast(res.tip)
      }
    },
    //滚动
    fn_scrollView(type) {
      if (type === 'start') {
        this.$nextTick(() => {
          document.querySelector('.message-listWrap').lastChild.scrollIntoView({})
        })
        return
      }
      this.$nextTick(() => {
        document.querySelector('.message-listWrap').lastChild.scrollIntoView({
          behavior: 'smooth'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.session-main {
  overflow-y: auto;
  background: #efefef;

  .back {
    position: fixed;
    top: 0;
    z-index: 2;
  }

  .conetnt-list {
    padding: 1.4rem 0.3rem 1rem;
    min-height: 4rem;
    box-sizing: border-box;
    -webkit-user-select: text;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

// 输入框
.comment-text {
  position: fixed;
  width: 100%;
  height: 1.84rem;
  display: flex;
  //align-items: center;
  justify-content: space-between;
  padding: 0.28rem;
  font-size: 0.32rem;
  bottom: 0;
  box-sizing: border-box;
  background-color: #ffffff;

  .send-input {
    height: 0.6rem;
    line-height: 0.6rem;
    ::v-deep {
      .van-field__body {
        padding-left: 0.2rem;
      }

      .field-placeholder-text-color {
        color: #9493b1;
      }

      .van-field__control {
      }
    }
  }

  ::v-deep {
    .van-cell {
      padding: 0 !important;
      border-radius: 0.15rem;
    }

    .van-cell::after {
      display: none;
    }
  }

  .uploadImg-wrap {
    margin-left: 0.36rem;
    flex-shrink: 0;
    width: 0.57rem;
    height: 0.57rem;
    overflow: hidden;
    background: url('~assets/imgs/upload-img.png') center center/100% 100% no-repeat;
    .btn {
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .send-text {
    flex-shrink: 0;
    margin-left: 0.36rem;
    width: 0.56rem;
    height: 0.46rem;
    background: url('~assets/imgs/msg-send.png') center center/100% 100% no-repeat;
  }
}

.msg-inner-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  //align-items: center;
  width: 100%;
  &.right {
    flex-direction: row-reverse;
    .msg-item {
      padding-right: 0.24rem;
    }
  }
}

.avatarLeft {
  width: 0.76rem;
  height: 0.76rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ccc;
  background-size: contain;
  //position: absolute;
  //left: 0;
}

.avatarRight {
  flex-shrink: 0;
  width: 0.76rem;
  height: 0.76rem;
  overflow: hidden;
  //position: absolute;
  //right: 0;
  border-radius: 50%;
  background-color: #ccc;
  background-size: contain;
}

.msg-item {
  margin-top: 0.24rem;
  width: 100%;
  padding-left: 0.24rem;
  margin-bottom: 0.25rem;
  box-sizing: border-box;
  display: flex;

  flex-flow: column nowrap;

  .msg-item-content {
    // min-width: 3.5rem;
    max-width: 3.5rem;
    padding: 0.15rem 0.15rem;
    border-radius: 0.08rem;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    word-break: break-all;

    p {
      box-sizing: border-box;
      margin: 0 0;
      padding: 0 0;
      width: 100%;
      font-size: 0.28rem;
    }

    .time {
      display: block;
      margin-bottom: 0.15rem;
      color: rgb(250, 250, 250);
      text-align: right;
      padding: 0.05rem 0.12rem;
      font-size: 0.18rem;
    }

    .msg-item-footer {
      width: 100%;
      text-align: right;
    }

    .msg-item-time {
      font-size: 0.12rem;
      color: #fff;
      margin-top: 0.05rem;
      opacity: 0.5;
    }

    .msg-item-name {
      font-size: 0.12rem;
      color: #999;
      margin-bottom: 0.05rem;
    }
  }

  .msg-item-picture {
    max-width: 4.86rem;
    min-height: 2rem;
    max-height: 4.86rem;
    border-radius: 0.16rem;
    overflow: hidden;
  }

  .msg-border-ridius-right {
    border-radius: 0.45rem 0.16rem 0.45rem 0.45rem;
  }

  .msg-border-ridius-left {
    border-radius: 0.08rem 0.4rem 0.4rem 0.4rem;
  }
}

.msg-align-left {
  align-items: flex-start;
}

.msg-align-right {
  align-items: flex-end;
}

.reply {
  justify-content: flex-end;
  overflow: hidden;

  .msg-item-content {
    align-items: flex-end;
  }
}

.sendMsg {
  overflow: hidden;
  justify-content: center;
  min-width: 100%;

  .msg-item-content {
    background-color: rgb(83, 80, 80);
    // width: 100%;

    p {
      width: 100%;
      color: #fff;
    }
  }
}

.msg-color-right {
  background-color: #0087ff !important;
}

.msg-color-left {
  color: #ffffff;
  background-color: #333333;
}
</style>
