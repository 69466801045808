<template>
  <ERefreshLoadV3
    ref="ERefreshLoadV3"
    :autoOnload="autoOnload"
    :beforeRefresh="onRefresh"
    :moreData="onLoad"
    :finished="params.finished"
  >
    <slot :list="list"></slot>
  </ERefreshLoadV3>
</template>
<script>
export default {
  name: 'RefreshLoadContainer',
  props: {
    getList: {
      type: Function,
      default: () => {
        return async () => {
          this.params.finished = true
        }
      }
    },
    autoOnload: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      list: [],
      params: {
        finished: false,
        pageNum: 0,
        pageSize: 10
      }
    }
  },
  methods: {
    refresh() {
      this.$refs.ERefreshLoadV3.onRefresh()
      // this.params.finished = false
      // this.params.pageNum = 0
      // this.list = []
    },
    onRefresh() {
      this.params.finished = false
      this.params.pageNum = 0
      this.list = []
    },
    async onLoad() {
      this.params.pageNum++
      await this.getList(
        {
          params: this.params,
          list: this.list
        },
        (data = { params: this.params, list: this.list }) => {
          this.params = {
            ...this.params,
            ...data.params
          }
          this.list = data.list
        }
      )
    }
  }
}
</script>

<style scoped lang="scss"></style>
