<template>
  <div class="actor-page">
    <actor-filter v-model="sortId" class="actor-page-filter" :filter-status="filterStatus" @input="restFn" />
    <ERefreshLoadV3 :finished="finished" :beforeRefresh="refreshData" :moreData="moreData">
      <div class="list_data">
        <template v-if="queryParams.contentType === 0">
          <video-list2 :list="mediaList" />
        </template>
        <template v-else-if="queryParams.contentType === 1">
          <EWaterFall :datasList="photoList">
            <template #left="{ handleLoad, data }">
              <ImgshowType2 :data="data" class="leftItem" @loadedImg="handleLoad"></ImgshowType2>
            </template>
            <template #right="{ handleLoad, data }">
              <ImgshowType2 :data="data" class="rightItem" @loadedImg="handleLoad"></ImgshowType2>
            </template>
          </EWaterFall>
        </template>
        <template v-else-if="queryParams.contentType === 2">
          <EWaterFall :datasList="gifList">
            <template #left="{ handleLoad, data }">
              <ImgshowType2 :data="data" class="leftItem" @loadedImg="handleLoad"></ImgshowType2>
            </template>
            <template #right="{ handleLoad, data }">
              <ImgshowType2 :data="data" class="rightItem" @loadedImg="handleLoad"></ImgshowType2>
            </template>
          </EWaterFall>
        </template>
      </div>
    </ERefreshLoadV3>
  </div>
</template>
<script>
import { media_actor_details } from 'api/home'

export default {
  name: 'ActorVideoPage',
  components: {
    ActorFilter: () => import('./actorFilter.vue'),
    videoList2: () => import('@/components/Home/listType/videoList/videoList2.vue'),
    ImgshowType2: () => import('components/Home/showType/pictrueListType/imgshowType2.vue')
  },
  props: {
    params: {
      type: Object,
      default: function () {
        return {
          contentType: 0, //内容类型： 0:视频 1:图集 2:GIFs
          coverType: 1, //封面类型： 1:横版封面 2:竖版封面
          id: +this.$route.params.id, //演员ID
          sort: 1, //排序: 0:最新 1:最热 2:最受好评
          tagIds: [] //视频标签
        }
      }
    },
    filterStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sortId: 0,
      finished: false,
      mediaList: [],
      gifList: [],
      photoList: [],
      pageNum: 0,
      pageSize: 10,
      mediaInfo: {}
    }
  },
  computed: {
    queryParams() {
      return {
        ...this.params,
        sort: this.sortId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
    }
  },
  watch: {
    'params.tagIds': {
      handler(v, o) {
        this.mediaList = []
        this.photoCount = []
        this.gifList = []
        this.pageNum = 1
        this.fn_labelTopicList()
      }
    }
  },
  methods: {
    switchFilter(e) {
      this.$emit('on-filter', e)
    },
    restFn() {
      this.mediaList = []
      this.fn_labelTopicList()
    },
    async fn_labelTopicList() {
      const res = await media_actor_details(this.queryParams)
      if (res.code === 200 && res.data) {
        this.mediaList = [...this.mediaList, ...(res.data.mediaList || [])]
        this.gifList = [...this.gifList, ...(res.data.gifList || [])]
        this.photoList = [...this.photoList, ...(res.data.photoList || [])]
        this.mediaInfo = res.data
        this.$emit('mediaInfoFn', res.data)
        switch (this.queryParams.contentType) {
          case 0:
            if (res.data.mediaList.length < this.pageSize || !res.data.mediaList) {
              this.finished = true
            }
            break
          case 1:
            if ((res.data.photoList && res.data.photoList.length < this.pageSize) || !res.data.photoList) {
              this.finished = true
            }
            break
          case 2:
            if ((res.data.gifList && res.data.gifList.length < this.pageSize) || !res.data.gifList) {
              this.finished = true
            }
            break
        }
      } else {
        this.finished = true
        // return this.$toast(res.tip)
      }
    },
    async moreData() {
      this.pageNum += 1
      await this.fn_labelTopicList()
    },
    // 下啦刷新
    refreshData() {
      this.finished = false
      this.mediaList = []
      this.photoCount = []
      this.gifList = []
      this.pageNum = 0
    }
  }
}
</script>

<style scoped lang="scss">
.actor-page {
  padding: 0.24rem 0.32rem;
  .actor-page-filter {
    margin-bottom: 0.24rem;
  }
}
</style>
