<template>
  <ul class="show-ranking-list">
    <li v-for="(item, index) in list" :key="index" class="hot-item" @click="clickRank(item)">
      <MyVideo v-if="showType == 1" :item="item" />
      <div v-if="[2, 5].includes(showType)">
        <!-- <img src="@/assets/imgs/temp/hbch.png" alt="" /> -->
        <EDecryptImg :imgCDN="imgCDN" class="mine_top_bg" :imgURL="item.cover || item.avatar">
          <div v-if="showType == 2" class="photo-yellow">
            <img src="@/assets/imgs/discover/photo-yellow.png" alt="" />
            <span>图片</span>
          </div>
          <div v-if="showType == 2" class="photo-count">60张</div>
        </EDecryptImg>

        <div v-if="showType == 5" class="hot-item-right">
          <div>{{ item.name }}</div>
          <span class="cup"> {{ item.cup }}-CUP </span>
          <div>
            <span>视频：{{ item.movieCount }}</span>
            <span>点赞：{{ item.likeCount | numberFilter }}</span>
          </div>
        </div>
        <div v-else class="hot-item-post">
          <div>{{ item.name }}</div>
          <div class="tags">
            <span v-for="tag in item.tags" :key="tag">{{ tag }}</span>
          </div>
          <div>
            <span v-if="item.addedTime">{{ item.addedTime.split('T')[0] }}</span>
            <span v-else>{{ item.createdAt.split('T')[0] }}</span>
            <span>点赞量:{{ item.likes | numberFilter }}</span>
          </div>
        </div>
      </div>
      <div v-if="showType == 4">
        <!-- <img src="@/assets/imgs/temp/hbch.png" alt="" /> -->
        <EDecryptImg :imgCDN="imgCDN" class="mine_top_bg" :imgURL="item.node.imgs[0]"> </EDecryptImg>

        <div v-if="showType == 5" class="hot-item-right">
          <div>{{ item.base.name }}</div>
          <span class="cup"> {{ item.base.cup }}-CUP </span>
          <div>
            <span>视频：{{ item.base.movieCount }}</span>
            <span>图片：{{ item.base.photoCount }}</span>
            <span>点赞：{{ item.base.likeCount | numberFilter }}</span>
          </div>
        </div>
        <div v-else class="tz_right">
          <div class="over-item top">{{ item.base.title }}</div>
          <div class="tags mar">
            <span>{{ item.base.postSection }}</span>
          </div>
          <div class="btm">
            <span v-if="item.base.addedTime">{{ item.base.addedTime.split('T')[0] }}</span>
            <span v-else>{{ item.base.createdAt.split('T')[0] }}</span>
            <span class="dzl">点赞量:{{ item.base.likes | numberFilter }}</span>
          </div>
        </div>
      </div>
      <div v-if="showType == 3">
        <EDecryptImg :imgCDN="imgCDN" class="mine_top_bg" :imgURL="item.cover">
          <div class="photo-yellow">
            <img src="@/assets/imgs/discover/photo-yellow.png" alt="" />
            <span>图片</span>
          </div>
        </EDecryptImg>
        <div class="hot-item-post">
          <div class="tags">{{ item.title }}</div>

          <EDecryptImg :imgCDN="imgCDN" class="mine_top_bg_avatar" :imgURL="item.userAvartar">
            <span> {{ item.userName }} </span>
          </EDecryptImg>
          <div>
            <span>{{ item.total }}张图片</span>
          </div>
        </div>
      </div>
      <div class="rank">
        <img v-if="index == 0" class="rank-img" src="@/assets/imgs/discover/ranking1.png" alt="" />
        <img v-else-if="index == 1" class="rank-img" src="@/assets/imgs/discover/ranking2.png" alt="" />
        <img v-else-if="index == 2" class="rank-img" src="@/assets/imgs/discover/ranking3.png" alt="" />
        <span v-else>
          {{ index + 1 }}
        </span>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  components: {
    MyVideo: () => import('./video.vue')
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    showType: {
      type: Number,
      default: 1
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    clickRank(item) {
      switch (this.showType) {
        case 1:
          this.$router.push({
            path: `/play/longVideo/${item.id}`
          })
          break
        case 2:
          this.$router.push({
            path: `/discover/imgList`,
            query: {
              id: item.id
            }
          })
          break
        case 4:
          this.$router.push({
            path: `/community/detail/${item.base.id}`
          })
          break
        case 3:
          this.$router.push({
            path: `/discover/detail`,
            query: {
              id: item.id,
              type: item.type
            }
          })
          break
        case 5:
          this.$router.push({
            path: `/discover/actor/${item.id}`
          })
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hot-item {
  .mine_top_bg {
    width: 1.56rem;
    margin-right: 0.24rem;
    height: 1.56rem;
    flex: none;
    border-radius: 0.08rem;
    :deep() img {
      border-radius: 0.08rem;
    }
  }
  .mine_top_bg_avatar {
    display: flex;
    color: #999;
    font-size: 0.2rem;
    font-weight: 400;
    justify-content: flex-start;
    :deep() img {
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      margin: 0 0.12rem 0 0;
    }
  }
  > div {
    @include flex-align-center;
    margin-bottom: 0.2rem;
  }
  &-right {
    > div {
      &:nth-child(1) {
        // color: $txtDefault;
        // font-size: 0.28rem;
        // font-style: normal;
        // font-weight: 400;
        // margin-bottom: 0.04rem;
      }
      &:nth-child(2) {
        display: flex;
        color: $grey67;
        span {
          margin-right: 0.18rem;
          position: relative;
          &:nth-child(-n + 2) {
            &::after {
              content: '|';
              position: absolute;
              right: -0.08rem;
              top: 0;
              // transform: translate(0, -50%);
              width: 0.02rem;
              height: 0.24rem;
            }
          }
        }
      }
    }
  }
  &-post {
    > div {
      &:nth-child(1) {
        color: $txtDefault;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 500;
        @include textoverflow;
      }
      &:nth-child(2) {
        margin: 0.12rem 0;
      }
      &:nth-child(3) {
        color: $grey;
        font-size: 0.2rem;
        font-weight: 400;
        > span:first-child {
          margin-right: 0.16rem;
        }
      }
    }
  }
  .tags {
    display: flex;
    > span {
      margin-right: 0.1rem;
      border-radius: 0.08rem;
      border: 0.5px solid $txtActive;
      color: $txtActive;
      font-size: 0.2rem;
      padding: 0 0.04rem;
    }
  }
  .cup {
    padding: 0rem 0.12rem;
    border-radius: 0.64rem;
    background: rgba(0, 0, 0, 0.05);
  }
  .photo-yellow {
    position: absolute;
    right: 0.08rem;
    top: 0.08rem;
    width: 0.92rem;
    height: 0.38rem;
    @include flex-center;
    border-radius: 0.08rem;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    img {
      width: 0.2rem;
    }
  }
  .photo-count {
    position: absolute;
    right: 0rem;
    bottom: 0.08rem;
    width: 100%;
    @include flex-center;
    border-radius: 0.08rem;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    padding-right: 0.08rem;
  }
  .over-item {
    @include textoverflow;
  }
}
ul {
  padding: 0.4rem 0.32rem;
  overflow-y: auto;
  > li {
    position: relative;
  }
  .rank {
    position: absolute;
    left: 0.08rem;
    top: 0.08rem;
    > span {
      color: #e6e6e6;
      border-radius: 50%;
      background: #7f7f7f;
      width: 0.32rem;
      height: 0.28rem;
      @include flex-center;
    }
  }
  .rank-img {
    width: 0.28rem;
  }
  .tz_right {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    margin: 0.15rem 0;
    .top {
      font-size: 0.24rem;
    }
    .mar {
      margin: 0.12rem 0;
    }
    .btm {
      color: #999;
      font-size: 0.2rem;
      .dzl {
        margin-left: 0.5rem;
      }
    }
  }
}
</style>
