<template>
  <!-- 头部头像 -->
  <div class="top-avatar" @click.stop="$router.push(`/community/info?id=${data.base.userId}`)">
    <div class="up_info_l">
      <div class="avatarPack">
        <EDecryptImg :img-c-d-n="imgCDN" class="avatar" :img-u-r-l="data.base.userAvatar" />
      </div>

      <div class="avatar_name">
        <p style="display: flex; align-items: center">
          {{ data.base.userName }}
          <!-- <span class="level">LV.{{ data.base.userLevel }}</span> -->
        </p>
        <div class="watches_num">{{ data.base.addedTime | dateFormat08 }}</div>
      </div>
    </div>

    <div class="up_info_r">
      <span :class="{ avatar: data.base.isCare }" @click.stop="addCare(data)">
        <img v-if="!data.base.isCare" src="@/assets/imgs/jiah.svg" alt="" />
        <!-- <img src="@/assets/imgs/community/line.png" alt="" v-if="!newData.base.isLike"> -->
        {{ data.base.isCare ? '已关注' : '关注' }}
      </span>
    </div>
  </div>
</template>
<script>
import { care_add } from 'api/home'

export default {
  name: 'TopAvatar',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo() {
      return this.$store.getters['getUserInfo']
    }
  },
  mounted() {},
  methods: {
    async addCare(item) {
      // if (this.userInfo.loginType == 0) {
      //   return this.$toast('仅注册的用户才能关注')
      // }
      // 关注用户
      try {
        const res = await care_add({
          id: item.base.userId,
          add: !item.base.isCare,
          userType: 0
        })
        if (res.code === 200) {
          item.base.isCare = !item.base.isCare
          if (item.base.isCare) {
            return this.$toast('关注成功')
          } else {
            return this.$toast('取消关注')
          }
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        return this.$toast('请求失败，请稍后再试')
      }
    }
  }
}
</script>

<style scoped lang="scss">
// 顶部头像
.top-avatar {
  @include flexbox($jc: space-between, $ai: center, $fd: row, $fw: nowrap);
  font-size: 0.22rem;
  white-space: nowrap;

  .up_info_l {
    display: flex;
    align-items: center;
    .avatarPack {
      flex-shrink: 0;
      min-width: 0.8rem;
      max-width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      overflow: hidden;
    }
    .avatar_name {
      margin-left: 0.19rem;
      p {
        margin: 0;
        font-size: 0.28rem;
        font-weight: 600;
        .level {
          margin-left: 0.08rem;
          background: #ffd300;
          border: 0.02rem solid #000000;
          border-radius: 0.16rem;
          width: 0.6rem;
          height: 0.28rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 0.2rem;
        }
      }
      div {
        font-size: 0.22rem;
        color: #999999;
      }
    }
  }
  .up_info_r {
    span {
      padding: 0.1rem 0.23rem;
      border: 0.02rem solid $btnBg;
      color: $btnBg;
      border-radius: 1rem;
      margin-left: 0.3rem;
      display: flex;
      align-items: center;
      img {
        height: 0.19rem;
        margin-right: 0.04rem;
      }
    }
    .avatar {
      border: none;
      color: #fff;
      background-color: rgba(39, 39, 51, 1);
    }
    &.wantTo {
      span {
        padding: 0.07rem 0.18rem;
        background: $btnBg;
        color: #fff;
        img {
          height: 0.25rem;
          margin-right: 0.07rem;
        }
      }
      .avatar {
        border: 0.02rem solid #fff;
        background: #fff;
      }
    }
  }
}
</style>
