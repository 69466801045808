import { render, staticRenderFns } from "./ResourceOpr.vue?vue&type=template&id=0abae7f1&scoped=true&"
import script from "./ResourceOpr.vue?vue&type=script&lang=js&"
export * from "./ResourceOpr.vue?vue&type=script&lang=js&"
import style0 from "./ResourceOpr.vue?vue&type=style&index=0&id=0abae7f1&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0abae7f1",
  null
  
)

export default component.exports