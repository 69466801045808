<template>
  <!-- 视频分类标签 -->
  <div class="video-type">
    <div v-if="data.payType === 1 || data.comicsPayType === 1" class="video-type-vip">VIP</div>
    <div v-if="data.payType === 2 || data.comicsPayType === 2" class="video-type-gold">
      <img src="@/assets/imgs/mine/gold.png" alt="" />
      {{ data.price | changeGold }}
    </div>
    <div v-if="data.payType === 0" class="video-type-free">免费</div>
  </div>
</template>
<script>
export default {
  name: 'VideoType',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped lang="scss">
.video-type {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.35rem;
  line-height: 0.35rem;
  color: #fff;
  font-size: 0.26rem;
  font-weight: 600;

  div {
    height: 0.35rem;
    line-height: 0.35rem;
    text-align: center;
    border-radius: 0.08rem 0rem 0.16rem 0rem;
  }
  &-vip {
    width: 0.64rem;
    background: $gradientBg;
  }
  &-gold {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0.68rem;
    background: linear-gradient(278deg, #892dff 3.02%, #521b99 97.58%);
    img {
      width: 0.22rem;
      height: 0.22rem;
      margin-right: 0.04rem;
    }
  }
  &-free {
    width: 0.64rem;
    background: #1a81ec;
  }
}
</style>
