<template>
  <div style="border-bottom: 0.01rem solid #f0f0f0">
    <div class="reaActTitle" @click.stop="handleClickTitle">
      {{ title }} <img src="~/assets/imgs/home/more.png" alt="" />
    </div>
    <div class="recActList">
      <div class="recActContent">
        <div v-for="(i, index) in item.photoCategoryList" :key="index" @click="clickItem(i)">
          <div class="img">
            <EDecryptImg :imgURL="i.cover"> </EDecryptImg>
          </div>
          <div class="title">{{ i.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JumpTo } from '@/utils/utils_tools'
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {},

  mounted() {},

  methods: {
    handleClickTitle() {
      if (this.item.jumpType) {
        if (this.item.jumpUrl) {
          JumpTo(`${this.item.jumpUrl}?showType=${this.item.showType}}`)
        } else {
          this.$router.push({
            path: '/home/videoTopicDetail',
            query: {
              id: this.item.id,
              name: this.item.title,
              showType: this.item.showType || ''
            }
          })
        }
      } else {
        this.$emit('handleClickTitle', this.item.jumpCategoryId)
      }
    },
    clickItem(i) {
      this.$router.push({
        path: '/discover/theme',
        query: {
          id: i.id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.reaActTitle {
  width: 100%;
  height: 0.44rem;
  font-size: 0.32rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recActList {
  margin-top: 0.24rem;
  width: 100%;
  overflow-x: auto;
  margin-top: 0.24rem;

  .recActContent {
    height: 2rem;
    display: flex;
    align-items: center;
    > div {
      width: 1.2rem;
      height: 2rem;
      margin-right: 0.36rem;
      flex-shrink: 0;
      .img {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 99px;
        overflow: hidden;
      }
      .title {
        font-size: 0.24rem;
        font-weight: 500;
        color: #666666;
        text-align: center;
        margin-top: 0.12rem;
        line-height: 0.36rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      //   > div:first-child {
      //     width: 0.8rem;
      //     height: 0.8rem;
      //     margin: 0 auto;
      //   }
      //   > div:last-child {
      //     font-size: 0.24rem;
      //     font-weight: 400;
      //     color: #333333;
      //     text-align: center;
      //     margin-top: 0.08rem;
      //     line-height: 0.36rem;
      //   }
    }
  }
}
::-webkit-scrollbar {
  display: none;
}
</style>
