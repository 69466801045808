<template>
  <div class="short-video1" @click="toShortVideo">
    <div class="cover">
      <EDecryptImgNolazy :imgURL="data.coverImg" class="cover-img" @loadedImg="loadedImg"> </EDecryptImgNolazy>
      <VideoCardMask :data="data"></VideoCardMask>
      <slot name="mask" :data="data"></slot>
    </div>
    <VideoCardFooter :data="data"></VideoCardFooter>
  </div>
</template>

<script>
//短视频瀑布流自适应高度展示模版
export default {
  components: {
    VideoCardFooter: () => import('@/components/Home/VideoCardFooter.vue'),
    VideoCardMask: () => import('@/components/Home/VideoCardMask.vue')
  },
  props: ['data', 'list'],
  data() {
    return {}
  },
  mounted() {},
  methods: {
    loadedImg() {
      this.$emit('loadedImg')
    },
    // 跳转短视频播放页
    toShortVideo() {
      const index = this.list.findIndex(item => item.id === this.data.id)
      this.$store.commit('SET_VIDEOLIST', this.list)
      this.$store.commit('SET_SHORT_VIDEO_INDEX', index)
      this.$router.push({
        path: '/play/shortVideo'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.short-video1 {
  width: 1.68 * 2rem;
  flex-shrink: 0;
  position: relative;
  margin-bottom: 0.32rem;
  &:nth-child(2n + 2) {
    margin-right: 0;
  }
  &:nth-last-child(1),
  &:nth-last-child(2) {
    margin-bottom: 0;
  }

  .cover {
    position: relative;
    min-height: 0.2rem;
    border-radius: 0.06rem 0.06rem 0 0;
    overflow: hidden;
  }
}
</style>
