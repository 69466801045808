<template>
  <div class="rank-video-item">
    <div class="left">
      <!-- <img class="img" src="@/assets/imgs/temp/hk_doll.png" alt="" /> -->
      <EDecryptImg :imgCDN="imgCDN" class="img" :imgURL="item.coverImg" />
      <div class="bottom">
        <div>
          <img class="rank-img" src="@/assets/imgs/watch_count.png" alt="" />
          <span>{{ item.watchTimes | numberFilter }}</span>
        </div>
        <span>
          {{ item.playTime | secondToDate }}
        </span>
      </div>
    </div>
    <div class="right">
      <div>
        {{ item.title }}
      </div>
      <div class="btm">
        <span>{{ item.addedTime.split('T')[0] }}</span>
        <span class="dzl">点赞量:{{ item.likes | numberFilter }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  }
}
</script>

<style lang="scss" scoped>
.rank-video-item {
  display: flex;
  .img {
    width: 2.8rem;
    height: 1.56rem;
    border-radius: 0.08rem;
    :deep() img {
      border-radius: 0.08rem;
    }
  }
  .left {
    position: relative;
  }
  .bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    @include flexbox;
    width: 100%;
    color: $white;
    font-size: 0.2rem;
    font-weight: 400;
    padding: 0 0.08rem 0.08rem;
    > div {
      @include flex-center;
      > img {
        margin-right: 0.04rem;
      }
    }
    .rank-img {
      width: 0.28rem;
    }
  }
  .right {
    padding: 0.18rem 0.24rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div {
      &:nth-child(1) {
        color: $txtDefault;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 500;
        @include textoverflow(2);
        margin-bottom: 0.12rem;
      }
      &:nth-child(2) {
        @include flexbox;
        color: $grey;
        font-size: 0.2rem;
        font-style: normal;
        font-weight: 400;
      }
    }
    .btm {
      display: block !important;
      .dzl {
        display: inline-block;
        margin-left: 0.2rem;
      }
    }
  }
}
</style>
