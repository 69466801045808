<template>
  <div class="welfare_default">
    <div class="title">
      <img src="@/assets/imgs/mine/incom_left_icon.png" alt="" />
      官方推荐
    </div>
    <!-- 推荐列表 -->
    <ul class="rem_list">
      <li v-for="(item, index) in getRecreationList" :key="index" @click="toDown(item)">
        <EDecryptImg class="rem_list_icon" :imgURL="item.avatar ? item.avatar : ''"> </EDecryptImg>
        <p>{{ item.name }}</p>
      </li>
      <i></i>
      <i></i>
    </ul>
    <div class="title">
      <img src="@/assets/imgs/mine/incom_left_icon.png" alt="" />
      热门应用
    </div>
    <!-- 官方应用 -->
    <ul class="official_list">
      <li v-for="(item, index) in getHotList" :key="index" @click="toDown(item)">
        <div class="item_left">
          <EDecryptImg class="item_left_icon" :imgURL="item.avatar ? item.avatar : ''"> </EDecryptImg>
          <p>{{ item.name }}</p>
        </div>
        <p class="btn">立即下载</p>
      </li>
      <i></i>
      <i></i>
    </ul>
  </div>
</template>

<script>
import { JumpTo } from '@/utils/utils_tools'
export default {
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    // 落地页
    ldyCdn({ $store }) {
      return $store.getters['ldyCdn']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取热门应用列表
    getRecreationList({ $store }) {
      return $store.getters['iconRecreationAds']
    },
    // 获取官方推荐列表
    getHotList({ $store }) {
      return $store.getters['hotList']
    }
  },
  methods: {
    toDown(item) {
      if (item.download_url) {
        JumpTo(item.download_url)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.welfare_default {
  .title {
    @include flexbox($jc: flex-start);
    font-size: 0.32rem;
    font-weight: 600;

    img {
      margin-right: 0.16rem;
      width: 0.16rem;
      height: 0.44rem;
    }
  }
}

.rem_list {
  @include flexbox($fw: wrap);
  padding: 0.32rem;

  li {
    text-align: center;
    margin-bottom: 0.3rem;

    .rem_list_icon {
      width: 1.56rem;
      height: 1.56rem;
      margin-bottom: 0.12rem;
    }
  }

  i {
    width: 1.56rem;
  }
}

.official_list {
  padding: 0.32rem;
  li {
    @include flexbox();
    padding-bottom: 0.3rem;
    border-bottom: 0.01rem solid #f0f0f0;
    margin-bottom: 0.3rem;
    .item_left {
      @include flexbox($jc: flex-start);

      .item_left_icon {
        width: 1rem;
        height: 1rem;
        border-radius: 0.08rem;
        margin-right: 0.2rem;
      }
    }
    .btn {
      border-radius: 0.36rem;
      background: $txtActive;
      color: #fff;
      padding: 0 0.24rem;
      height: 0.56rem;
      text-align: center;
      line-height: 0.56rem;
    }
  }
}
</style>
