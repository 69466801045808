<template>
  <div class="main">
    <EBackTop title="官方招募" />
    <div class="recruitment">
      <p class="title">招募令</p>
      <p class="sub_title">原创博主火热招募中</p>
      <p class="mb">
        认证博主发布 <span>视频帖子</span> 即可获得<span>70%收益分成</span>。<br />
        持续获得收益 躺着也能赚钱
      </p>
      <ul class="group">
        <li class="title">添加官方管理</li>
        <li class="top_list_main">
          <div class="left-img">
            <EDecryptImg :imgURL="1" class="left-img-icon" />
            <div class="title-desc">
              <p>Telegram</p>
              <p>立即加群，神秘福利等你领！</p>
            </div>
          </div>
          <p class="btn">立即加入</p>
        </li>
      </ul>
      <ul>
        <li class="title">入驻说明</li>
        <li class="mb">1、平台不会通过个人任何入驻费用</li>
        <li class="mb">2、请与官方群管理或APP客服确认真实性</li>
        <li class="mb">3、更多问题请添加官方管理员咨询</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 0.24rem;
  font-size: 0.32rem;
  font-weight: 600;
}
.sub_title {
  color: $grey;
  margin-bottom: 0.24rem;
}
span {
  color: $txtActive;
}
.recruitment {
  padding: 0.6rem 0.3rem;
}
.top_list_main {
  padding: 0.36rem 0.24rem;
  @include flexbox();
  border-radius: 0.16rem 0.16rem 0rem 0rem;
  background: var(--4, rgba(0, 0, 0, 0.04));
}
.left-img {
  @include flexbox($jc: flex-start);
  .left-img-icon {
    width: 0.8rem;
    height: 0.8rem;
    flex-shrink: 0;
    margin-right: 0.24rem;
  }
}
.btn {
  background: $gradientBg;
  color: #fff;
  padding: 0.1rem 0.24rem;
  border-radius: 0.72rem;
}
.mb,
.group {
  margin-bottom: 0.24rem;
}
</style>
