<template>
  <div class="home-index">
    <ERefreshLoadV3 ref="ERefreshLoadV3" :beforeRefresh="onRefresh" :moreData="onLoad" :finished="finished">
      <data>
        <div v-if="navItem.adsType === 2 && homeAD.length">
          <AdAvatarList :list="iconHomeAds" :wrap="true" key-name="avatar" :maxLength="10" />
        </div>
        <div v-else-if="navItem.adsType !== 3 && homeAD.length" class="top_banner">
          <EADSwiper :imgCDN="imgCDN" :list="homeAD" key-name="cover" @clickImg="clickImg" />
        </div>
      </data>
      <!-- 跑马灯 -->
      <div
        v-if="isDownCountShow && runTxt"
        class="info"
        @click="tojump($store.state.config.config.runningLight.jumpUrl)"
      >
        <div class="bg_box">
          <div class="close" @click.stop="isDownCountShow = false">
            <van-icon :name="require('@/assets/imgs/home/close.png')" />
          </div>
          <div class="scroll-text">
            <div ref="text" class="text">{{ runTxt }}</div>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <JGList v-if="iconAdsShow && jumptabList.length" class="maindom" :list="jumptabList"></JGList>

      <template v-for="(item, index) in comTopicList">
        <div v-if="item.list && item.list.length > 0" :key="index" class="maindom">
          <component
            :is="compComponent(item.showType)"
            :id="item.id"
            :title="item.name"
            :item="item"
            :list="item.list"
            @handleClickTitle="handleClickTitle"
          />
        </div>
      </template>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { index_home } from 'api/home'
import { JumpTo } from '@/utils/utils_tools'
export default {
  name: 'HomeIndex', //主题模版
  components: {
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue'),
    EADSwiper: () => import('@/components/web_base/component_base/EADSwiper/index.vue'),
    JGList: () => import('@/components/Home/showType/JGList'), //金刚区
    FourGrid: () => import('@/components/Home/showType/FourGrid.vue'), //四宫格(换一换)1
    FiveGrid: () => import('@/components/Home/showType/FiveGrid.vue'), //五宫格(换一换)2
    SixGrid: () => import('@/components/Home/showType/SixGrid.vue'), //六宫格(换一换)3
    ShortSixGrid: () => import('@/components/Home/showType/ShortSixGrid.vue'), //六宫格(换一换)3
    SwiperPic: () => import('@/components/Home/showType/SwiperPic.vue'), //套图轮播4
    PicRow: () => import('@/components/Home/showType/PicRow.vue'), //套图合集横排 5
    ShortRow: () => import('@/components/Home/showType/ShortRow.vue'), //竖版横排 6
    Banner: () => import('@/components/Home/showType/Banner.vue'), //广告专题 7
    ActressRow: () => import('@/components/Home/showType/ActressRow.vue'), //女优横排 8
    LongRow: () => import('@/components/Home/showType/LongRow.vue'), // 长视频横排 9
    JavBigList: () => import('@/components/Home/showType/JavBigList.vue') // 长视频横排 9
  },
  props: ['navItem', 'id'],
  data() {
    return {
      pageNum: 0,
      pageSize: 10,
      finished: false,
      newAdvertise: {}, //中间活动广告
      // remDataList: [], // 推荐
      // actorList: [], //女优列表
      jumptabList: [], //金刚区列表
      // mediaList: [], //视频列表
      // recomendActorList: [], //推荐女优列表
      // shortList: [], //短视频列表
      // tagList: [], //标签列表
      topicList: [], //主题列表
      iconAdsShow: false,
      isDownCountShow: false
    }
  },
  computed: {
    //实时使用数据
    comTopicList() {
      let arr = this.topicList.map(item => {
        if (item.mediaList && item.mediaList.length) {
          item.list = item.mediaList
        }
        if (item.actorList && item.actorList.length) {
          item.list = item.actorList
        }
        if (item.photoList && item.photoList.length) {
          item.list = item.photoList
        }
        if (item.photoCategoryList && item.photoCategoryList.length) {
          item.list = item.photoCategoryList
        }
        return item
      })
      return arr
    },
    // 获取首页轮播广告
    homeAD({ $store }) {
      return $store.getters['homeAD']
    },
    iconHomeAds({ $store }) {
      return $store.getters['iconHomeAds']
    },

    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    runTxt({ $store }) {
      if ($store.state.config.config.runningLight) {
        return $store.state.config.config.runningLight?.content
      }
    }
  },

  mounted() {
    this.isDownCountShow = true
  },

  methods: {
    handleClickTitle(id) {
      this.$emit('handleClickTitle', id)
      // this.onRefresh()
    },
    tojump(item) {
      JumpTo(item)
    },
    async onLoad() {
      this.pageNum += 1
      await this.getHomePageData()
    },
    // 下拉刷新
    async onRefresh() {
      this.pageNum = 0
      // this.remDataList = []
      // this.actorList = []
      this.jumptabList = []
      // this.mediaList = []
      // this.recomendActorList = []
      // this.tagList = []
      this.topicList = []
    },

    // 获取首页数据
    async getHomePageData() {
      await index_home({
        id: this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code === 200) {
          this.iconAdsShow = res.data.iconAdsShow
          if (res.data.jumptabList) this.jumptabList = [...this.jumptabList, ...res.data.jumptabList]
          if (res.data.topicList) this.topicList = [...this.topicList, ...res.data.topicList]
          if (res.data.topicList.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      })
    },
    compComponent(showType) {
      switch (showType) {
        case 1: //四宫格(换一换)1
          return 'FourGrid'
        case 2: //五宫格(换一换)2
          return 'FiveGrid'
        case 3: //横排六宫格(换一换)3
          return 'SixGrid'
        case 4: //套图轮播4
          return 'SwiperPic'
        case 5: //套图合集横排 5
          return 'PicRow'
        case 6: //竖版横排 6
          return 'ShortRow'
        case 7: //广告专题 7
          return 'Banner'
        case 8: //女优横排 8
          return 'ActressRow'
        case 9: //横版横滑 9
          return 'LongRow'
        case 18: //竖版六宫格(换一换)
          return 'ShortSixGrid'
        case 26: //大列表
          return 'JavBigList'
      }
    },
    // banner跳转
    clickImg(item) {
      JumpTo(item.href)
    }
  }
}
</script>

<style lang="scss" scoped>
.home-index {
  padding: 0 0.32rem 1rem;

  .top_banner {
    width: 6.86rem;
    height: 2.86rem;
    border-radius: 0.16rem;
    margin: 0 auto;
  }
}

.maindom {
  padding-top: 0.18rem;
  padding-bottom: 0.18rem;
  box-sizing: content-box;
}

.info {
  background-color: #fff;
  margin: 0.36rem 0;

  .bg_box {
    width: 6.86rem;
    height: 0.8rem;
    border-radius: 1rem;
    border: 1px solid #fff;
    background: #333;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    margin: 0 auto;
    position: relative;
    font-size: 0.3rem;
    padding: 0 0.36rem;
    color: #fff;

    .scroll-text {
      margin-left: 0.12rem;
      overflow: hidden;
      height: 100%;
      width: 100%;
      line-height: 0.8rem;

      .text {
        white-space: nowrap;
        animation: scroll-text 20s linear infinite;
        position: relative;
      }
    }

    @keyframes scroll-text {
      from {
        left: 100%;
      }

      to {
        left: -200%;
      }
    }
  }

  .btn {
    border-radius: 0.04rem;
    background: #f41500;
    padding: 0.02rem 0.16rem;
  }

  .close {
    position: absolute;
    right: 0;
    top: -0.2rem;
    font-size: 0.36rem;
  }
}

.divider {
  background-color: rgba(240, 240, 240, 1);
  width: 6.86rem;
  height: 0.02rem;
}
</style>
