<template>
  <div>
    <EBackTop :title="content"></EBackTop>
    <div class="video_wrap">
      <ERefreshLoadV3 ref="VideoSearchList" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
        <ul class="main_list">
          <VideoShowType1 v-for="(item, index) in tagVideoList" :key="index" :data="item" class="item">
          </VideoShowType1>
        </ul>
      </ERefreshLoadV3>
    </div>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
import { search_tag_detail } from 'api/search'
export default {
  mixins: [scrollMixin],
  name: 'SearchTagVideo',
  components: {
    VideoShowType1: () => import('@/components/Home/showType/videoListType/videoshowType1.vue')
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      content: '',
      finished: false,
      tagVideoList: []
    }
  },
  watch: {
    content: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.pageNum = 1
          this.tagVideoList = []
          this.search_tag_detailFn()
        }
      }
    }
  },
  mounted() {
    this.content = this.$route.query.content
  },
  activated() {
    this.content = this.$route.query.content
  },
  methods: {
    async onLoad() {
      this.pageNum++
      await this.search_tag_detailFn()
    },
    async onRefresh() {
      this.pageNum = 0
      this.tagVideoList = []
    },
    async search_tag_detailFn() {
      const res = await search_tag_detail({
        contentType: 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        tag: this.content
      })
      if (res.code === 200 && res.data.mediaList) {
        this.tagVideoList = [...this.tagVideoList, ...res.data.mediaList]
        if (res.data.mediaList.length < this.pageSize || !res.data.mediaList) {
          this.finished = true
        }
      } else {
        this.finished = true
        this.$toast(res.tip)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video_wrap {
  padding: 0 0.32rem;
  .main_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      margin-bottom: 0.2rem;
    }
  }
}
</style>
