<template>
  <div class="item" @click="handleClick(data)">
    <div class="cover">
      <div class="top">
        <img src="@/assets/imgs/discover/photo-yellow.png" alt="" />
        <span>图片</span>
      </div>
      <EDecryptImgNolazy :imgURL="data.cover" @loadedImg="loadedImg"> </EDecryptImgNolazy>
      <div slot="mask" class="videoFooter">
        <div><img src="@/assets/imgs/discover/eyes.png" alt="" />{{ data.watch?.yearWatchs | numberFilter }}</div>
        <div>
          <img src="@/assets/imgs/discover/photo-transparent.png" alt="" />{{
            data.pictures ? data.pictures.length : 0
          }}
        </div>
      </div>
    </div>
    <div class="title">
      <div>{{ data.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['data'],
  data() {
    return {}
  },

  mounted() {},

  methods: {
    handleClick() {
      this.$router.push({
        path: `/discover/imgList`,
        query: {
          id: this.data.id
        }
      })
    },
    loadedImg() {
      this.$emit('loadedImg')
    }
  }
}
</script>
<style lang="scss" scoped>
.item {
  width: 3.36rem;
  .cover {
    width: 100%;
    border-radius: 0.06rem 0.06rem 0 0;
    overflow: hidden;
    position: relative;
    .top {
      position: absolute;
      width: 0.46 * 2rem;
      height: 0.19 * 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      right: 0.12rem;
      top: 0.12rem;
      background-color: rgba($color: #000000, $alpha: 0.5);
      color: #fff;
      border-radius: 0.04rem;
      > img {
        width: 0.2rem;
        height: 0.2rem;
        margin-right: 0.08rem;
      }
    }

    .videoFooter {
      position: absolute;
      bottom: 0rem;
      width: 100%;
      height: 0.6rem;
      padding: 0 0.12rem;
      font-size: 0.22rem;
      font-weight: 400;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > div {
        display: flex;
        align-items: center;
        img {
          width: 0.28rem;
          height: 0.28rem;
          margin-right: 0.04rem;
        }
      }
    }
  }
  .title {
    width: 100%;
    height: 0.96rem;
    padding: 0.12rem 0.2rem;
    background: #f7f7f7;

    > div {
      font-size: 0.26rem;
      font-weight: 500;
      color: #333;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      /* 这里是超出几行省略 */
      overflow: hidden;
    }
  }
}
</style>
