<!--
 * @Author:
 * @Mail:
 * @Date: 2024-02-01 11:52:06
 * @LastEditTime: 2024-02-13 09:15:53
 * @LastEditors: 江涛 wangtao524713@gmail.com
 * @FilePath: /haoseH5/src/components/Home/showType/FourGrid.vue
-->
<template>
  <div>
    <div class="title" @click.stop="handleClickTitle">
      {{ title }}
      <img src="~/assets/imgs/home/more.png" alt />
    </div>
    <div class="content">
      <div v-for="item in videoList.length ? videoList : list" :key="item.id">
        <VideoshowType1 :data="item"></VideoshowType1>
      </div>
    </div>
    <div class="footerBtn">
      <div @click.stop="handleClickTitle"><img src="~/assets/imgs/home/more2.png" alt /> 更多片源</div>
      <div @click="fn_videoTopicChange"><img src="~/assets/imgs/home/reload.png" alt /> 更换一批</div>
    </div>
  </div>
</template>

<script>
import { videoTopicChange } from 'api/home'
import { JumpTo } from '@/utils/utils_tools'

export default {
  components: {
    VideoshowType1: () => import('./videoListType/videoshowType1')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    id: {
      type: Number,
      default: () => {
        return 0
      }
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      videoList: []
    }
  },

  mounted() {},
  methods: {
    async fn_videoTopicChange() {
      const res = await videoTopicChange({
        id: this.id,
        count: 4
      })
      if (res.code === 200 && res.data) {
        this.videoList = res.data.mediaList
      } else {
        return this.$toast(res.tip)
      }
    },
    handleClickTitle() {
      if (this.item.jumpType) {
        if (this.item.jumpUrl) {
          JumpTo(`${this.item.jumpUrl}?showType=${this.item.showType}}`)
        } else {
          this.$router.push({
            path: '/home/videoTopicDetail',
            query: {
              id: this.item.id,
              name: this.item.title,
              showType: this.item.showType || ''
            }
          })
        }
      } else {
        this.$emit('handleClickTitle', this.item.jumpCategoryId)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  width: 100%;
  height: 0.44rem;
  font-size: 0.32rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  display: flex;
  flex-flow: wrap;
  margin-top: 0.24rem;

  > div {
    width: 3.36rem;
    height: 3rem;
    margin-bottom: 0.24rem;
    margin-right: 0.14rem;
  }

  > div:nth-child(2n) {
    margin-right: 0;
  }
}

.footerBtn {
  margin: 0.32rem 0.29rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    width: 2.76rem;
    height: 0.64rem;
    border-radius: 0.37rem;
    background-color: #f6f6f6;
    border: 0.02rem solid #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.2rem;
    }
  }

  > div:last-child {
    background: #fff;
    border: 0.02rem solid #666666;
  }
}
</style>
