<template>
  <div class="community-detail">
    <EBackTop :title="'帖子详情'" :open-fallback="true"> </EBackTop>
    <postDetailWithOpr :itemData="detailInfo" style="margin-top: 0.24rem" class="content-main"
      @getNewDetail="getDetail">
      <template #top="{ data }">
        <TopAvatar :data="data" class="top-avatar" />
      </template>
    </postDetailWithOpr>
    <Comment :is-show-upload="true" class="comment-box" :publisher="detailInfo.base" :object-id="+$route.params.id"
      :objectype="2" :disabled-pull="true" />
    <!--    <SharePopup-->
    <!--      :sharePopShow="showShare"-->
    <!--      @close="-->
    <!--        e => {-->
    <!--          showShare = e-->
    <!--        }-->
    <!--      "-->
    <!--    />-->
  </div>
</template>

<script>
import { community_detail } from 'api/community'
import { comment_list, comment_add, collect } from 'api/home'
export default {
  name: 'CommunityDetail',
  components: {
    postDetailWithOpr: () => import('@/components/Community/postDetailWithOpr.vue'),
    // PopupComment: () => import('@/components/Community/popupComment.vue'),
    Comment: () => import('@/components/comment/index.vue'),
    TopAvatar: () => import('@/components/Community/topAvatar.vue')

    // SharePopup: () => import('@/components/SharePopup.vue')
  },
  data() {
    return {
      detailInfo: {
        nodes: [],
        base: {}
      },
      finished: false,
      loading: false,
      refreshing: false,
      activeIndex: 0, // 展开的id
      commentList: [], // 一级评论列表
      pageNum: 1,
      pageSize: 10,
      text: '', // 评论
      placeholder: '喜欢就给个评论支持一下～',
      parentsId: 0, // 父级id
      secondList: [], // 二级评论列表
      popupShow: false,
      dataItem: {},
      showShare: false
    }
  },
  computed: {
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  async created() { },
  async mounted() {
    await this.getAndSetDetail()
  },
  beforeDestroy() { },
  methods: {
    clickShare() {
      this.showShare = true
    },
    async getAndSetDetail() {
      await this.getDetail().then(() => {
        this.$store.dispatch('setPostList', {
          type: 'add',
          item: this.detailInfo
        })
      })
    },
    async addLike(item) {
      // 收藏信息
      try {
        const res = await collect({
          flag: !item.isCollect,
          object_id: item.id,
          collectType: 10,
          type: 1
        })
        if (res.code === 200) {
          item.isCollect = !item.isCollect
          if (item.isCollect) {
            item.collects += 1
            return this.$toast('收藏成功')
          } else {
            item.collects -= 1
            return this.$toast('取消收藏')
          }
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试')
      }
    },
    // 获取详情信息
    async getDetail() {
      try {
        const res = await community_detail({
          id: +this.$route.params.id
        })
        if (res.code === 200) {
          res.data.id = this.$route.params.id
          this.detailInfo = res.data
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 触发评论列表刷新
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.finished = false
      this.loading = true
      this.commentList = []
      this.secondList = []
      this.activeIndex = 0
      this.getCommentList({
        objectId: +this.$route.params.id,
        objectType: 2, //1:视频 2旧帖子 3: 4发现页帖子
        pageNum: 1,
        pageSize: 10,
        parentsId: null
      })
    },
    // 触发onload
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
      this.getCommentList({
        objectId: +this.$route.params.id,
        objectType: 2, //1:视频 2旧帖子 3: 4发现页帖子
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        parentsId: this.parentsId
      })
    },
    // 获取评论列表
    async getCommentList(data) {
      try {
        const res = await comment_list(data)
        if (res.code === 200) {
          // 有parentsId 为二级列表请求
          this.loading = false
          this.refreshing = false
          if (data.parentsId) {
            this.secondList = [...this.secondList, ...res.data.list]
            if (!res.data.list || (res.data && res.data.list.length < this.pageSize)) {
              this.parentsId = null
            }
          } else {
            this.commentList = [...this.commentList, ...res.data.list]
            if (!res.data.list || (res.data && res.data.list.length < this.pageSize)) {
              this.finished = true
            }
          }
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
          return this.$toast(res.tip || res.data.msg)
        }
      } catch (error) {
        this.loading = false
        this.refreshing = false
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },

    closePopup() {
      this.popupShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.community-detail {
  border-radius: 0.12rem;
  min-height: 100vh;
}

//  评论主体内容
.comment-box,
.content-main {
  padding: 0.3rem 0.32rem;
  box-sizing: border-box;
  max-width: 100%;
}
</style>
