<template>
  <div class="hot-play">
    <div class="title padding">
      {{ title }} <img src="@/assets/imgs/home/more.png" alt="" @click="$router.push('/discover/girl')" />
    </div>
    <div class="content">
      <div class="hot-play-second">
        <LadyListType1 v-for="(item, index) in list" :key="index" :data="item" class="hot-play-item"></LadyListType1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    LadyListType1: () => import('./ladyListType/ladyListType1.vue')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },

  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.hot-play {
  .padding {
    padding: 0 0.32rem;
  }
  .title {
    width: 100%;
    height: 0.44rem;
    font-size: 0.32rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content {
    margin-top: 0.24rem;
    padding-left: 0.32rem;
    .hot-play-first {
      margin-top: 0.24rem;
      @include flexbox(flex-start);
      overflow-x: auto;
      @include scrollbar-hide;
      .hot-play-item {
        margin-right: 0.1rem;
      }
    }
    .hot-play-second {
      @include flexbox(flex-start);
      overflow-x: auto;
      @include scrollbar-hide;
      .hot-play-item {
        margin-right: 0.14rem;
      }
    }

    > .center-intro {
      margin: 0.08rem 0 0;
      .center-intro-title {
        margin-top: 0.04rem;
        color: #333333;
        font-size: 0.28rem;
        font-weight: 500;
      }
      .center-intro-content {
        color: #666666;
        font-size: 0.24rem;
        font-weight: 400;
        margin: 0.04rem 0 0.24rem;
        @include flexbox(flex-start, center);
        .avatar-name {
          color: #ffb267;
          font-size: 0.24rem;
          line-height: 0.32rem;
        }
        .avatar {
          margin: 0 0.24rem 0 0.08rem;
          width: 0.32rem;
          height: 0.32rem;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
