<template>
    <div class="default-avatar">
        <EBackTop class="header"  :title="'修改头像'">
  
        </EBackTop>

        <div class="avatar-list">
            <EDecryptImg :imgCDN="imgCDN" @click.native="changeImg(item)" v-for="item in list" :key="item" :imgURL="item"
                class="item">
                <img class="check_active" v-if="item == checkUrl" src="@/assets/imgs/mine/check_item.png" alt="">
            </EDecryptImg>
        </div>
        <div class="btn" @click="checkRight">
            确认选择
        </div>
    </div>
</template>

<script>
import { user_avatar, update_info } from 'api/user'
export default {
    name: 'Avatar',
    components: {

    },

    data() {
        return {
            list: [],
            avatarUrl: '',
            checkUrl:undefined,
        }
    },
    created() {
        this.avatarUrl = this.$route.query.userAvatarUrl
        this.getAvatar()
    },
    computed: {
        imgCDN({ $store }) {
            return $store.getters['cdn']
        },
        isMember({ $store }) {
            return $store.getters['isMember']
        },
        info({ $store }) {
            return $store.getters['getUserInfo']
        },
    },
    methods: {
        checkActive(){
   
        },
        // 获取头像列表
        async getAvatar() {

            try {
                const res = await user_avatar()
                if (res.code === 200) {
                    this.list = res.data
                } else {
                    this.$toast(res.tip)
                }
            } catch (error) {
                console.log(error)
                this.$toast('请求错误，请稍后再试！')
            }
        },
        // 完成按钮
        async checkRight() {
            if (this.isMember) {
                const res = await update_info({
                    avatar: this.checkUrl
                })
                if (res.code === 200) {
                    this.$toast('修改成功')

                } else {
                    this.$toast(res.tip)
                }
            } else {
                this.$toast('亲，您还不是会员，不能修改噢')
            }
        },
        // 选择头像
        changeImg(item) {
            this.avatarUrl = item
          this.checkUrl = item
        }
    }
}
</script>

<style lang="scss" scoped>
:deep() {
    .default {
        background-color: #fff;

        .realImg {
            border-radius: 50%;
        }
    }
}

.default-avatar {
    min-height: 100vh;
}

.avatar-list {
    padding: 0.54rem;
    padding-top: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0.72rem;

    .item {
        width: 1.3rem;
        height: 1.3rem;
        margin: 0 auto 0.2rem auto;
        border-radius: 50%;
        font-size: 0;
        position: relative;

        :deep() {
            img {
                font-size: 0;
                border-radius: 50%;
            }
        }

        .check_active {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0.44rem;
            height: 0.44rem;
        }
    }

    .item:last-child {
        margin-left: 0.35rem;
    }
}

.avatar-list::after {
    content: '';
    flex: auto;
    margin-left: 0.1rem;
}

.btn {
    background: $gradientBg;
    border-radius: 0.44rem;
    width: 5.68rem;
    height: 0.88rem;
    line-height: 0.88rem;
    text-align: center;
    color: #fff;
    position: fixed;
    bottom: 1.84rem;
    left: 50%;
    transform: translate(-50%, 0);
}</style>
