<template>
  <div class="resource-layout">
    <div v-if="showInput" class="resource-layout-search">
      <van-field
        :value="params.search"
        maxlength="50"
        class="search-box"
        placeholder="输入搜索文字"
        right-icon="search"
        @input="changeSearch"
      />
    </div>
    <div class="resource-layout-select">
      <TagSelect :value="params.sort" :data="tagList" @input="sortSwitch" />
    </div>
  </div>
</template>
<script>
const debounce = require('lodash/debounce')
export default {
  name: 'ResourceLayout',
  components: {
    TagSelect: () => import('@/components/Community/TagSelect.vue')
  },
  model: {
    prop: 'params',
    event: 'input'
  },
  props: {
    params: {
      type: Object,
      default: () => ({
        sort: 0,
        search: ''
      })
    },
    showInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sort: 0,
      tagList: [
        {
          title: '播单',
          id: 13
        },
        {
          title: '视频',
          id: 1
        },
        {
          title: '图集',
          id: 9
        }
      ]
    }
  },
  computed: {
    initParams: {
      get() {
        return this.params
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    changeSearch: debounce(function (e) {
      //切换排序
      this.initParams = {
        ...this.params,
        search: e
      }
    }, 500),
    sortSwitch(sort) {
      //切换排序
      this.initParams = {
        ...this.params,
        sort
      }
    }
  }
}
</script>

<style scoped lang="scss">
.resource-layout {
  .search-box {
    height: 0.6rem;
    padding: 0.1rem 0.24rem;
    border-radius: 0.3rem;
    :deep(.van-field) {
      height: 0.6rem;
    }
  }
  .resource-layout-select {
    font-size: 0.28rem;
    margin: 0.24rem 0;
    padding: 0 1.2rem;
  }
}
</style>
