<template>
  <div class="commentsItem" @click="$router.push(`/chat/${itemData.peerId}?nickName=${itemData.nickName}`)">
    <EDecryptImg :imgCDN="imgCDN" class="commentsItem_avatar" :imgURL="itemData.avatar ? itemData.avatar : ''">
    </EDecryptImg>
    <div class="right">
      <div class="right_top">
        <span>{{ itemData.nickName }}</span>
        <span>{{ itemData.updatedAt | dateFormat08 }}</span>
      </div>
      <div class="right_bot">
        <span>{{ itemData.lastText }}</span>
        <span>{{ itemData.count }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['itemData'],
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    info({ $store }) {
      return $store.getters['getUserInfo']
    }
  }
}
</script>

<style lang="scss" scoped>
.commentsItem {
  @include flexbox($jc: flex-start);

  margin: 0.32rem 0.24rem 0.24rem 0.32rem;

  .commentsItem_avatar {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    margin-right: 0.2rem;
  }

  .right {
    font-size: 0.28rem;
    padding-bottom: 0.24rem;
    border-bottom: 0.01rem solid #f0f0f0;
    flex: 1;
    .right_top,
    .right_bot {
      @include flexbox();
    }

    .right_top {
      span {
        &:first-child {
          color: var(--, #999);
        }
      }
    }

    .right_bot {
      margin-top: 0.22rem;

      span {
        &:first-child {
          color: var(--2, #666);
        }

        &:last-child {
          border-radius: 0.2rem;
          background: $txtActive;
          padding: 0rem 0.16rem;
          color: #fff;
        }
      }
    }
  }
}
</style>
