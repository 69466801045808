<template>
  <div class="generate-item">
    <div class="top-donwload">
      <div>创建时间:{{ item.createdAt | formatDate }}</div>
      <div v-if="item.status == 3" class="download" @click="downloadFn('photo')">
        <img class="add-img" src="@/assets/imgs/aiLab/download_icon.svg" alt="" />
        <span>下载</span>
      </div>
    </div>
    <div class="generate-img-list">
      <div @click="previewImage(1, 1)">
        <span>模板</span>
        <!-- <img src="@/assets/imgs/no_data.png" alt="" /> -->
        <img v-if="item.aoContentType == 2" src="@/assets/imgs/aiLab/play-icon.svg" alt="" class="play-icon" />
        <EDecryptImg
          ref="refImgModel"
          :objectFit="'cover'"
          :imgCDN="imgCDN"
          class="resetImg"
          :imgURL="item.tplPictures && item.tplPictures[0] ? item.tplPictures[0] : item.afterStripPic"
        />
        <img class="add-img" src="@/assets/imgs/aiLab/video-add.png" alt="" />
        <div class="tplTitle">
          {{ item.tplTitle }}
        </div>
      </div>
      <div>
        <span>素材</span>
        <!-- <img src="@/assets/imgs/no_data.png" alt="" /> -->
        <EDecryptImg
          ref="refImgMatireal"
          :objectFit="'cover'"
          :imgCDN="imgCDN"
          class="resetImg"
          :imgURL="item.sourceImgs"
          @click.native.stop="previewImage(2)"
        />
      </div>
      <div>
        <!-- <img src="@/assets/imgs/no_data.png" alt="" /> -->
        <div v-if="item.status == 2" class="in-progress">
          <span>{{ showText }}</span>
          <img src="@/assets/imgs/aiLab/progress.svg" alt="" />
        </div>
        <EDecryptImg
          v-if="item.status == 3"
          ref="refImgSuccess"
          :imgCDN="imgCDN"
          class="resetImg"
          :imgURL="item.vidCover ? item.vidCover : item.modifyImgs[0]"
          :objectFit="'cover'"
          @click.native.stop="previewImage(3, 0)"
        >
          <img v-if="item.aoContentType == 2" src="@/assets/imgs/aiLab/play-icon.svg" alt="" class="play-icon" />
        </EDecryptImg>
        <img v-if="item.status == 4" class="fail-img" src="@/assets/imgs/aiLab/generate-fail.png" alt="" />
      </div>
    </div>
    <div>
      <div></div>
      <div>{{ showText }}</div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import { downVideo } from '@/utils/utils_tools'
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    aiImg({ $store }) {
      return $store.getters['aiImg']
    },

    showText() {
      const map = {
        1: '排队中',
        2: '生成中',
        3: '已完成',
        4: '生成失败'
      }
      return map[this.item.status]
    }
  },
  methods: {
    downloadFn(type) {
      if (this.item.aoContentType == 1) {
        this.$toast('请点击图片截图保存！')
        // downVideo(`${this.aiImg}${this.item.modifyImgs[0]}`, this.item.tplTitle || '图片', 'image')
      } else {
        this.$copyText(this.item.modifyVideoUrl).then(() => {
          this.$toast('复制成功，请前往浏览器下载视频！')
        })
        // downVideo(this.item.modifyVideoUrl, this.item.tplTitle, 'video')
      }
    },
    previewImage(index, type) {
      if (index != 2 && this.item.aoContentType == 2) {
        const item = {}
        if (type) {
          item.videoUrl = this.item.tplVideoUrl
          item.videoCover = this.item.tplVideoCover
          item.type = type
        } else {
          item.videoUrl = this.item.vidUrl
          item.videoCover = this.item.vidCover
          if (this.item.vidUrl.endsWith('.mp4')) {
            item.type = 1
          }
        }
        this.$emit('fn_play_video', item)
        return
      }
      const map = {
        1: 'refImgModel',
        2: 'refImgMatireal',
        3: 'refImgSuccess'
      }
      const domArr = this.$refs[map[index]]
      ImagePreview({
        images: [domArr.realUrl], // 需要预览的图片 URL 数组
        closeable: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.generate-item {
  height: 4.02rem;
  border-radius: 0.08rem;
  padding: 0.23rem 0.32rem 0.32rem;
  margin-bottom: 0.36rem;
  border: 0.5px dashed rgba(0, 0, 0, 0.15);
  background: rgba(0, 0, 0, 0.06);
  .top-donwload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .download {
      width: 0.96rem;
      height: 0.42rem;
      border-radius: 0.39rem;
      background: #d73c44;
      @include flex-center;
      color: #fff;
      font-family: PingFang SC;
      font-size: 0.24rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      img {
        width: 0.24rem;
        height: 0.24rem;
      }
    }
  }
  > div {
    &:nth-child(1) {
      padding-bottom: 0.16rem;
      color: #787979;
      font-family: PingFang SC;
      font-size: 0.22rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &:nth-child(2) {
      border-top: 1px solid rgba(151, 151, 151, 0.3);
      border-bottom: 1px solid rgba(151, 151, 151, 0.3);
      padding: 0.24rem 0 0.4rem;
      display: flex;
      // justify-content: space-evenly;
      > div {
      }
    }
    &:nth-child(3) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 0.24rem;
      > div:nth-child(1) {
        color: #e8619d;
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      > div:nth-child(2) {
        width: 1.1rem;
        height: 0.44rem;
        @include flex-center;
        border-radius: 0.08rem;
        // background: rgba(215, 60, 68, 0.102);
        background: #d73c44;
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.26rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  > .generate-img-list {
    display: flex;
    > div {
      height: 1.68rem;
      width: 1.68rem;
      margin-right: 0.67rem;
      position: relative;
      .play-icon {
        width: 0.42rem;
        height: 0.42rem;
        position: absolute;
        @include transformCenter;
        left: 50%;
        top: 50%;
        z-index: 2;
      }
      .add-img {
        width: 0.36rem;
        height: 0.36rem;
        position: absolute;
        right: -0.51rem;
        top: 50%;
        transform: translateY(-50%);
      }
      .tplTitle {
        position: absolute;
        bottom: 0;
        left: 0;
        @include textoverflow;
        background: rgba(0, 0, 0, 0.3);
        padding-left: 0.13rem;
      }
      > img {
        width: 100%;
        height: 100%;
        // &:nth-of-type(2) {
        //   width: 0.42rem;
        //   height: 0.42rem;
        //   position: absolute;
        //   left: 50%;
        //   top: 50%;
        //   @include transformCenter;
        // }
      }
      &:nth-last-child(1) {
        margin-right: 0;
      }
      &:nth-last-child(2) {
        background: rgba(0, 0, 0, 0.2);
        > span {
          background: #e8619d;
        }
      }
      > span {
        position: absolute;
        left: 0;
        top: 0;
        padding: 0rem 0.1rem;
        border-radius: 0.06rem 0rem;
        background: #d73c44;
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.22rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        z-index: 1;
      }
    }
    .in-progress {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      height: 1.2rem;
      width: 100%;
      left: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      padding-top: 0.07rem;
      > span {
        color: #e8619d;
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0.04rem;
      }
    }
  }
}
</style>
