<template>
  <van-popup
    class="save-topical"
    v-model="showClosed"
    round
    position="bottom"
    get-container="body"
    @closed="showClosed = false"
  >
    <div class="title">
      <div><span style="color: #f64562">余额不足</span>，完整版需要{{ videoInfo.price / 100 }}金币才能观看！</div>
      <div>请选择您要充值的金额</div>
    </div>
    <!-- <div class="btnYe">
      <span class="ye">当前余额</span>
      <span class="num">{{ Math.round(userInfo.balance / 100) }}</span>
    </div> -->
    <!-- <div class="line"></div> -->
    <ul class="check-type">
      <li v-for="(item, index) in priceList" @click="changeActive(index, item.payAmount)" :key="item.id">
        <div class="check_item" :class="{ active: active === index }">
          <div class="pos_tips" v-if="item.giftGold / 100 > 0">赠送{{ item.giftGold | changeGold }}金币</div>
          <div class="top-text">
            <div class="sum">{{ item.goldBase }}金币</div>
            <!-- <div class="line"></div> -->
            <div class="rmb"><span>¥</span>{{ item.payAmount | changeGold }}</div>
            <div class="rmb_old">{{ item.payAmount | changeGold }}</div>
          </div>
        </div>
      </li>
    </ul>
    <!-- <div class="other-btn" @click="$router.push('/mine/setting/kf')">5000以上的大额充值 请点击这里</div> -->
    <!-- 支付小贴士 -->
    <!-- <div class="tips-list">
      <span>支付小帖士</span><br />
      1.跳转后请及时付款，超时支付无法到账，需重新提交订单<br />
      2.连续发起且未支付，账号可能被加入黑名单<br />
      3.有任何支付问题可联系<span @click="$router.push('/mine/setting/kf')">在线客服</span>处理
    </div> -->
    <div class="btm" @click="showPopFn">¥{{ showMoney }}/立即支付</div>
    <div class="btmkf">支付问题反馈，点击联系 <span @click="$router.push('/mine/setting/kf')">在线客服</span></div>
    <!-- 底部支付弹窗 -->
    <van-popup v-model="showPop" position="bottom" :style="{ height: '50vh' }" class="submit-pop">
      <div class="content">
        <div class="title">请确认订单</div>
        <div class="pice">
          已选择：<span style="color: #be9155">¥{{ activeData.payAmount | changeMoneyYuan }}</span>
        </div>
        <ul class="pay-list">
          <li
            :key="index"
            v-for="(item, index) in activeData.rchgType"
            @click="fn_sel(item.type)"
            class="item"
            :class="{ active: item.type === payMode }"
          >
            <div class="left" v-if="item.type === 'alipay'">
              <img v-if="payMode === item.type" src="@/assets/imgs/mine/zfb2.png" alt="" />
              <img v-else src="@/assets/imgs/mine/zfb2.png" alt="" />{{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'wechat'">
              <img v-if="payMode === item.type" src="@/assets/imgs/mine/weChat2.png" alt="" />
              <img v-else src="@/assets/imgs/mine/weChat2.png" alt="" />{{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'overage'">
              <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'union'">
              <img src="@/assets/imgs/mine/bank.png" alt="" />{{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'daichong'">
              <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
            </div>
            <div class="right">
              <div class="active" v-if="item.type == payMode"></div>
            </div>
          </li>
        </ul>
        <div class="tip-wrap">
          付款提示：<br />
          1、支付前请先绑定手机号，避免重新安装时用户权益遗失！<br />
          2、支付前先选择支付方式，再点“确认支付”跳转后请及时付款，超时支付无法到账，需重新发起支付。<br />
          3、若支付时出现任何风险提示请不要担心，重新支付一次即可，并不会重复付款。<br />
          4、付款如遇到其他问题，可咨询在线客服处理
        </div>
        <div class="submit" @click="fn_submit">
          <div>确认支付</div>
        </div>
      </div>
    </van-popup>
  </van-popup>
</template>

<script>
import { paytypeinfo, recharge_sumbit } from 'api/home'
export default {
  name: 'buyGoldPop',
  props: ['videoInfo'],
  data() {
    return {
      showClosed: false,
      priceList: [],
      active: 0,
      activeData: {},
      showPop: false,
      payMode: '',
      showMoney: ''
    }
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  async created() {
    await this.getPaytypeinfo()
    if (this.priceList && this.priceList.length > 0) {
      this.activeData = this.priceList[0]
      if (this.activeData.rchgType && this.activeData.rchgType.length !== 0) {
        this.payMode = this.activeData.rchgType[0].type
      }
    }
  },
  methods: {
    // 切换卡片类型
    changeActive(index, val) {
      this.showMoney = val / 100
      this.active = index
      this.activeData = this.priceList[index]
      if (this.activeData.rchgType && this.activeData.rchgType.length !== 0) {
        this.payMode = this.activeData.rchgType[0].type
      }
    },
    // 打开底部弹窗
    showPopFn() {
      this.showPop = true
    },
    // 获取购买金币列表
    async getPaytypeinfo() {
      const res = await paytypeinfo({})
      if (res.code === 200) {
        this.priceList = res.data.payModes
        this.showMoney = this.priceList[0].payAmount / 100
      } else {
        this.$toast(res.tip)
      }
    },
    // 选择支付方式
    fn_sel(type) {
      this.payMode = type
    },
    // 支付金币
    async fn_submit() {
      if (!this.payMode) return this.$toast('请选择支付通道！！！')
      this.$toast.loading({
        duration: 0,
        message: '跳转中请稍后'
      })
      const res = await recharge_sumbit({
        payAmount: this.activeData.payAmount,
        payMode: this.payMode,
        productId: this.activeData.id,
        rchgUse: this.activeData.rchgUse
      })
      if (res.code === 200) {
        this.showPop = false
        if (!res.data) return this.$toast('购买成功！！！')
        if (res.data && res.data.isOpenNewBrowser) {
          this.downloadFileByTagA(res.data.payUrl)
        }
        this.$toast.clear()
      } else {
        this.$toast.clear()
        this.$toast(res.tip)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.save-topical {
  border-radius: 0.42rem 0.42rem 0 0;
  background: #fff;
  height: 60%;
  overflow-y: auto;
  width: 7.5rem;
  padding: 0.5rem 0 1.8rem;
  // 弹窗
  .submit-pop {
    border-top-left-radius: 0.15rem;
    border-top-right-radius: 0.15rem;
    padding: 0.2rem 0.3rem;
    background-color: #fff;
    .right {
      width: 0.28rem;
      height: 0.28rem;
      border-radius: 50%;
      border: 0.02rem solid #ed9200;
      position: relative;

      .active {
        width: 0.2rem;
        height: 0.2rem;
        background: #ed9200;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .title {
      font-size: 0.38rem;
      margin-bottom: 0.48rem;
      margin-top: 0.4rem;
      text-align: center;
    }
    .pice {
      font-size: 0.25rem;
    }
    .tip-wrap {
      font-size: 0.24rem;
      margin-top: 0.5rem;
      margin-right: 0.7rem;
    }
    // 支付按钮
    .submit {
      width: 6.4rem;
      height: 0.9rem;
      text-align: center;
      line-height: 0.9rem;
      background: $txtActive;
      color: #fff;
      font-size: 0.32rem;
      margin: 0 auto;
      margin-top: 0.6rem;
    }
  }
}
.title {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 0.28rem;
  > div:last-child {
    margin-top: 0.2rem;
    font-weight: 500;
  }
}
.btnYe {
  text-align: center;
  .ye {
    display: inline-block;
    margin-bottom: 0.2rem;
    color: var(--jin, #be9155);
    font-size: 0.24rem;
    font-weight: 400;
    padding: 0.04rem 0.08rem;
    border-radius: 0.08rem;
    background: rgba(190, 145, 85, 0.2);
  }
  .num {
    display: inline-block;
    margin-left: 0.15rem;
    color: #be1d1d;
    font-size: 0.48rem;
    font-weight: 500;
    vertical-align: -0.08rem;
    padding-bottom: 0.1rem;
  }
}
.line {
  width: 100%;
  height: 0.01rem;
  border-bottom: 0.01rem dashed #dba85c;
}
.check-type {
  display: flex;
  flex-wrap: wrap;
  padding: 0.3rem;
  li {
    width: 30%;
    margin-right: 5%;
    margin-bottom: 0.24rem;
    .check_item {
      @include flexbox($jc: center, $ai: center, $fd: column);
      position: relative;
      color: #be9155;
      height: 2.5rem;
      border-radius: 0.32rem;
      border: 1px solid var(--jin, #be9155);
      .pos_tips {
        position: absolute;
        color: #fff;
        font-weight: 400;
        font-size: 0.24rem;
        top: -1px;
        left: -1px;
        padding: 0.08rem 0.12rem;
        border-top-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        background: var(--111, linear-gradient(91deg, #652323 6.02%, #320707 100.68%));
      }
      .top-text {
        text-align: center;
        font-size: 0.28rem;
        font-weight: 500;
        .sum {
          font-size: 0.28rem;
          color: #333;
          font-weight: 400;
        }
        .rmb {
          font-size: 0.56rem;
          color: #000;
          font-weight: 400;
          span {
            font-size: 0.28rem;
          }
        }
        .rmb_old {
          font-size: 0.28rem;
          font-weight: 400;
          color: #999;
          text-decoration: line-through;
        }
      }
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    .active {
      color: #67401c !important;
      border-radius: 0.24rem;
      border: none !important;
      background: linear-gradient(148deg, #fae3d0 0%, #eec7b2 101.54%, #efcab5 101.55%);
      .sum {
        color: #000 !important;
      }
      .rmb {
        color: #8b3200 !important;
      }
      .rmb_old {
        color: rgba(130, 47, 34, 0.5) !important;
      }
    }
  }
}
// .other-btn {
//   border-radius: 0.39rem;
//   background: #862424;
//   width: 4.6rem;
//   height: 0.7rem;
//   margin: 0.2rem auto 0.3rem auto;
//   line-height: 0.7rem;
//   text-align: center;
//   font-size: 0.28rem;
// }
// .tips-list {
//   font-size: 0.24rem;
//   margin-left: 0.4rem;
//   line-height: 0.4rem;
//   span {
//     border-radius: 0.08rem;
//     background: rgba(190, 145, 85, 0.2);
//     color: #be9155;
//     font-size: 0.26rem;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//     padding: 0.04rem 0.08rem;
//     margin-bottom: 0.2rem;
//     display: inline-block;
//   }
// }
.btm {
  color: #fff;
  display: flex;
  font-size: 0.36rem;
  width: 6.86rem;
  height: 0.88rem;
  padding: 0.22rem 2.2rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.44rem;
  background: linear-gradient(101deg, #ffea7a 3.16%, #ff74b2 65.51%, #ff74b2 93.52%);
  position: absolute;
  bottom: 0.8rem;
  left: 50%;
  transform: translateX(-50%);
}
.btmkf {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.3rem;
  font-size: 0.24rem;
  margin-top: 0.2rem;
  color: #999;
  text-align: center;
  span {
    color: #e8619d;
  }
}
</style>
