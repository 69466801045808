var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"square_exhibit"},[(_vm.title)?_c('div',{staticClass:"top"},[_c('div',[_vm._v(_vm._s(_vm.title))]),(_vm.jump)?_c('div',[_vm._v(_vm._s(_vm.jump)),_c('van-icon',{attrs:{"name":"arrow"}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"exhibit_list"},[_c('ul',{on:{"touchmove":function($event){$event.stopPropagation();}}},_vm._l((_vm.circleList),function(item,index){return _c('li',{key:index,staticClass:"topics_list",style:(index % 5 == 0
            ? 'background: linear-gradient(109.99deg,#00dbd4 -0.6%,#31e5b0 46.87%,#31e5b0 100.66%)'
            : index % 5 == 1
            ? 'background: linear-gradient(109.99deg, #F99FE7 -0.6%, #F38ECA 46.87%, #FFA5ED 100.66%)'
            : index % 5 == 2
            ? 'background: linear-gradient(109.99deg, #F99E68 -0.6%, #FAB076 46.87%, #FBC788 100.66%)'
            : index % 5 == 3
            ? 'background: linear-gradient(109.99deg, #C2BFCE -0.6%, #CDCADC 46.87%, #DBD8E7 100.66%)'
            : 'background: linear-gradient(109.99deg, #ABF459 -0.6%, #ABF459 46.87%, #DCFF7E 100.66%)'),on:{"click":function($event){return _vm.toTopicDetail(item)}}},[_c('div',{staticClass:"topics_margin"},[_c('h2',{staticClass:"topics_title"},[_vm._v("#"+_vm._s(item.title))]),_c('div',{staticClass:"topics_active"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("numberFilter")(item.careUser)))]),_vm._v("参与 ")]),_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("numberFilter")(item.hotValue)))]),_vm._v("互动数 ")])]),_c('div',{staticClass:"topics_Intercom"},[_c('div',{staticClass:"topics_avatar"},[_c('EDecryptImg',{staticClass:"portrait",attrs:{"imgURL":item.cover}})],1),_c('div',[_vm._v(" 帖子"),_c('em',[_vm._v(_vm._s(_vm._f("numberFilter")(item.postCount,'w')))])]),_c('span',[_vm._v("|")]),_c('div',[_vm._v(" 浏览"),_c('em',[_vm._v(_vm._s(_vm._f("numberFilter")(item.publishCount,'w')))])])])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }