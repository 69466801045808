<template>
  <div class="item" @click="toPlay(data)">
    <div class="cover">
      <EDecryptImg :imgURL="data.coverImg">
        <VideoType :data="data"></VideoType>
      </EDecryptImg>
      <VideoCardMask :data="data"></VideoCardMask>
    </div>
    <div class="title">
      <div>{{ data.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    VideoType: () => import('@/components/VideoType.vue'),
    VideoCardMask: () => import('@/components/Home/VideoCardMask.vue')
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  mounted() {},

  methods: {
    toPlay(data) {
      if (data) {
        this.$router.push(`/play/longVideo/${data.id}`)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.item {
  width: 1.52 * 2rem;
  height: 1.1 * 2rem;
  flex-shrink: 0;
  .cover {
    width: 100%;
    height: 0.86 * 2rem;
    border-radius: 0.06rem;
    overflow: hidden;
    position: relative;
  }

  .title {
    width: 100%;
    height: 0.48rem;
    padding: 0.12rem 0.2rem 0 0;
    line-height: 0.36rem;
    @include textoverflow(1);
    > div {
      font-size: 0.26rem;
      font-weight: 500;
      color: #333;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      /* 这里是超出几行省略 */
      overflow: hidden;
    }
  }
}
</style>
