<template>
  <div class="playlist-detail">
    <div class="top-bg">
      <EBackTop
        title="播单详情"
        :background="'#fff'"
        :color="'#000'"
        :open-fallback="true"
        :fixed="true"
        :placeholder="true"
      >
      </EBackTop>
      <TopAvatar :detail="detail" @showComment="showCommentFn" @getNewDetail="getNewDetail" />
      <EDecryptImg class="playlist-img" :imgURL="detail.cover" />
    </div>
    <Comment
      v-if="showComment"
      :is-show-upload="true"
      class="comment-box"
      :publisher="{}"
      :object-id="+$route.query.id"
      :objectype="6"
      :disabled-pull="true"
    />
    <Recommend v-else ref="recommendDetail" @playListDetail="getDetail" />
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
export default {
  components: {
    TopAvatar: () => import('../components/topAvatar.vue'),
    Recommend: () => import('../components/recommend.vue'),
    Comment: () => import('@/components/comment/index.vue')
  },
  mixins: [scrollMixin],
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      detail: {},
      showComment: false,
      background: 'transparent',
      color: '#fff',
      id: null
    }
  },
  async mounted() {
    this.id = this.$route.query.id
    window.addEventListener('scroll', this.scrollHandle) //绑定页面滚动事件
  },
  activated() {
    if (this.$route.query.id !== this.id) {
      this.id = this.$route.query.id
      this.$refs.recommendDetail.tabbarChange(1)
    }
  },
  deactivated() {
    window.removeEventListener('scroll', this.scrollHandle)
    this.$store.dispatch('setPlayList', {
      type: 'add',
      item: this.detail
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandle)
    this.$store.dispatch('setPlayList', {
      type: 'add',
      item: this.detail
    })
  },
  methods: {
    // 监听滚动高度
    scrollHandle(e) {
      let top = e.srcElement.scrollingElement.scrollTop // 获取页面滚动高度
      if (top >= 200) {
        this.background = '#fff'
        this.color = '#000'
      } else {
        this.background = 'transparent'
        this.color = '#fff'
      }
    },
    getDetail({ info }) {
      console.log(info)
      this.detail = info
    },
    showCommentFn() {
      this.showComment = !this.showComment
    },
    getNewDetail(item) {
      // this.$refs.recommendDetail.getList('pull')
      if (item.type == 1) {
        this.$set(this.detail, 'isCollect', !this.detail.isCollect)
      }
      if (item.type == 2) {
        if (item.isLike) {
          this.$set(this.detail, 'someData', {
            ...this.detail.someData,
            likes: this.detail.someData.likes - 1
          })
        } else {
          this.$set(this.detail, 'someData', {
            ...this.detail.someData,
            likes: this.detail.someData.likes + 1
          })
        }
        this.$set(this.detail, 'isLike', !this.detail.isLike)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.playlist-detail {
  position: relative;
  min-height: 100vh;
  background: #f5f5f5;
}
// .BackTop {
//   position: fixed;
//   left: 0;
//   top: 0;
//   width: 100%;
//   min-height: 52px;
// }
.top-bg {
  min-height: 2.76 * 2rem;
  position: relative;
  .playlist-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    &::after {
      content: '';
      left: 0;
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);

      /* 背景模糊 */
      backdrop-filter: blur(10px);
      z-index: 1;
    }
  }
  .top-avatar {
    position: relative;
    z-index: 3;
  }
}
</style>
