<template>
  <div class="tag-detail">
    <EBackTop :title="$route.query.name ? $route.query.name : topicInfoData.name" background="transparent"
      color="#ffffff" />
    <div class="tag-detail-header-wrap">
      <div class="bg-wrap">
        <img class="bg" :src="bgUrl[Math.floor(Math.random() * bgUrl.length)]" />
      </div>
    </div>
    <!-- <div class="tag-detail-content">
      <ETabBar
        :list="sortList"
        :tabbar-config="tabbarConfig"
        :active-num="sort"
        class="tag-detail-tab"
        @change="change"
      >
        <template>
          <ERefreshLoadV3 v-if="!isTabChanging" :finished="finished" :beforeRefresh="refreshData" :moreData="moreData">
            <div class="post-list">
              <VideoDetail :list="dataList" />
            </div>
          </ERefreshLoadV3>
        </template>
</ETabBar>
</div> -->
    <!--切换面板 -->
    <div class="more-pack">
      <ETabBar :list="category" :tabbarConfig="tabbarConfig" @change="changeId">
        <template #default="{ data }">
          <RefreshLoadContainer :ref="`ERefreshLoadV3${data.id}`" class="more-scroll" :getList="getMediaTag">
            <template #default="{ list }">
              <component :is="compComponent(data)" :id="+$route.query.id" :list="list" :typeTxt="'详情'"></component>
            </template>
          </RefreshLoadContainer>
        </template>
      </ETabBar>
    </div>
  </div>
</template>
<script>
import { mediaTagDetails } from '@/api/acg'
export default {
  name: 'TagDetail',
  components: {
    VideoDetail: () => import('@/components/Home/showType/SixList.vue'),
    VideoList2: () => import('@/components/Home/listType/videoList/videoList2.vue'),
    shortVideoList1: () => import('@/components/Home/listType/videoList/shortVideoList1.vue'),
    RefreshLoadContainer: () => import('@/components/RefreshLoadContainer.vue')
  },
  data() {
    return {
      sort: 0,
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.28rem', //默认字体大小
        fontSizeActive: '0.3rem', //选中字体大小
        fontColor: '#999', //默认字体颜色
        fontColorActive: '#333', //选中字体颜色
        bg: '#ffffff', //默认背景色
        bgActive: '#ffffff', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#333',
        lineWidth: '0', //下划线宽度 String
        sticky: true, //粘性布局
        offsetTop: '0rem', //距顶部距离
        tabFlex: true,
        sticky: true, //粘性布局
        offsetTop: '0rem' //距顶部距离
      },
      category: [
        {
          id: 0,
          name: '最新'
        },
        {
          id: 1,
          name: '最热'
        }
      ],
      pageNum: 0,
      pageSize: 10,
      finished: false, //上拉加载完成
      dataList: [],
      topicInfoData: {},
      bgUrl: [
        require('@/assets/imgs/videoTopic_bg1.png'),
        require('@/assets/imgs/videoTopic_bg2.png'),
        require('@/assets/imgs/videoTopic_bg3.png'),
        require('@/assets/imgs/videoTopic_bg4.png')
      ],
      isTabChanging: false
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    async changeId(e) {
      //切换排序
      this.sort = e
    },

    async getMediaTag(queryParams, updateFn) {
      const {
        params: { pageSize, pageNum },
        list
      } = queryParams
      const res = await mediaTagDetails({
        pageNum,
        pageSize,
        tagId: +this.$route.query.id,
        sort: this.sort,
        location: 1
      })
      if (res.code === 200 && res.data) {
        if (res.data.mediaList && res.data.mediaList.length) {
          updateFn({
            list: [...list, ...res.data.mediaList],
            params: {
              finished: res.data.mediaList.length < pageSize
            }
          })
        } else {
          updateFn({
            list,
            params: {
              finished: true
            }
          })
          this.$toast(res.tip)
        }
      } else {
        updateFn({
          list,
          params: {
            finished: true
          }
        })
        return this.$toast(res.tip)
      }
    },

    compComponent(item) {
      switch (item.id) {
        case 1: // 长视频*
          return 'VideoList2'
        case 2: // 短视频
        case 4: // 短剧
          return 'shortVideoList1'
        default:
          return 'VideoList2'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tag-detail {
  :deep(.BackTop) {
    position: fixed;
    z-index: 1;
  }

  .tag-detail-header-wrap {
    position: relative;
    height: 3.44rem;

    .bg-wrap {
      position: absolute;
      width: 100%;
      height: 100%;

      .bg {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        // background-color: rgba(0, 0, 0, 0.7);
        //filter: blur(1px);
      }
    }

    .tag-detail-header {
      padding: 1.3rem 0.32rem;
      position: relative;
      display: flex;
      align-items: center;

      .header-left {
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 0.13rem;
        overflow: hidden;
        flex-shrink: 0;
      }

      .header-right {
        margin-left: 0.24rem;

        .header-tag {
          color: #ffffff;
          font-size: 0.28rem;
        }

        .header-an {
          margin-top: 0.12rem;
          display: flex;
          align-items: center;
          color: #999999;
          font-size: 0.24rem;
          line-height: 0.34rem;

          > :nth-child(2) {
            margin-left: 0.16rem;
          }
        }

        .header-desc {
          margin-top: 0.12rem;
          color: #999999;
          font-size: 0.24rem;
          line-height: 0.34rem;
          @include textoverflow(1);
        }
      }
    }
  }

  .tag-detail-content {
    top: -0.6rem;
    position: relative;
    padding: 0.32rem 0;
    background-color: #fff;
    border-radius: 0.4rem 0.4rem 0 0;

    // :deep(.pull-container) {
    //   height: calc(100vh - 3.44rem + 0.6rem);
    //   overflow-y: auto;
    // }
    :deep(.van-tabs__wrap) {
      border-bottom: 0.01rem solid #f5f5f5;

      .van-tabs__nav {
        .van-tab {
          width: 50%;
        }
      }

      .van-tab--active {
        &::after {
          transition: all 0.2s ease-in-out;
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.08rem;
          background: url('~@/assets/imgs/tab_bottom.png') center bottom/100% 100% no-repeat;
        }
      }
    }
  }
}

.more-scroll {
  padding: 0 0.32rem;
  border-radius: 0.48rem 0.32rem 0 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.05);
}

.more-pack {
  top: -0.6rem;
  position: relative;
  padding: 0.32rem 0;
  background-color: #fff;
  border-radius: 0.4rem 0.4rem 0 0;
  // :deep(.pull-container) {
  //   height: calc(100vh - 3.44rem + 0.6rem);
  //   overflow-y: auto;
  // }

  ul {
    display: flex;
    align-items: center;
    position: absolute;
    right: calc(50% - 3.45rem);
    top: 0.5rem;
    z-index: 999;
    color: rgba(51, 51, 51, 0.5);
    font-size: 0.32rem;

    .active {
      color: #333333;
    }

    li {
      padding: 0 0.1rem;
    }
  }
}
</style>
