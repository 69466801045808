<template>
  <div class="list_wrap">
    <div v-for="item in list" :key="item.id">
      <shortVideoshowType9 :item="item" :list="list"></shortVideoshowType9>
    </div>
  </div>
</template>
<script>
// 短视频瀑布流列表
export default {
  components: {
    shortVideoshowType9: () => import('@/components/Home/showType/videoListType/shortVideoshowType9.vue')
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.list_wrap {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
