<!--
 * @Author:
 * @Mail:
 * @Date: 2024-02-01 11:52:06
 * @LastEditTime: 2024-02-16 11:10:23
 * @LastEditors: 江涛 wangtao524713@gmail.com
 * @FilePath: /haoseH5/src/components/Home/showType/recActList.vue
-->
<template>
  <div style="border-bottom: 0.01rem solid #f0f0f0">
    <div class="reaActTitle" @click.stop="handleClickTitle">
      {{ title }} <img src="~/assets/imgs/home/more.png" alt="" />
    </div>
    <div class="recActList">
      <div class="recActContent">
        <div v-for="(item, index) in list" :key="index" class="item">
          <LadyListType2 :data="item"></LadyListType2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JumpTo } from '@/utils/utils_tools'

export default {
  components: {
    LadyListType2: () => import('./ladyListType/ladyListType2.vue')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {
    handleClickTitle() {
      if (this.item.jumpType) {
        if (this.item.jumpUrl) {
          JumpTo(`${this.item.jumpUrl}?showType=${this.item.showType}}`)
        } else {
          this.$router.push({
            path: '/home/videoTopicDetail',
            query: {
              id: this.item.id,
              name: this.item.title,
              showType: this.item.showType || ''
            }
          })
        }
      } else {
        this.$emit('handleClickTitle', this.item.jumpCategoryId)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.reaActTitle {
  width: 100%;
  height: 0.44rem;
  font-size: 0.32rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recActList {
  margin-top: 0.24rem;
  width: 100%;
  overflow-x: auto;
  margin-top: 0.24rem;

  .recActContent {
    //height: 2.5rem;
    display: flex;
    align-items: center;
    .item {
      padding-bottom: 0.32rem;
    }
  }
}
::-webkit-scrollbar {
  display: none;
}
</style>
