<template>
  <div class="search-girl">
    <div class="top-search">
      <van-search v-model="content" placeholder="女优名字" @search="onSearch" />
      <span @click="$router.back()">返回</span>
    </div>
    <ETabBar :list="topTabList" :tabbarConfig="tabbarConfig" :activeNum="actorType" @change="tabbarChange">
      <template #default="scope">
        <ul class="cuplist">
          <li v-for="item in cupList" :key="item.id" @click="clickCup(item)">
            <!-- <img class="cover-img" src="@/assets/imgs/temp/bodan.png" alt="" /> -->
            <EDecryptImg
              :imgCDN="imgCDN"
              class="cover-img"
              :imgURL="item.cover"
              :class="tagIds.includes(item.id) ? 'tagActive' : ''"
            />
            <span>
              {{ item.name }}
            </span>
          </li>
        </ul>
        <ERefreshLoad
          :finished="finished"
          :loading="loading"
          :refreshing="refreshing"
          @refreshData="refreshData"
          @moreData="moreData"
        >
          <GirlList :mediaActorList="mediaActorList" @activeIndexChange="activeIndexChange" />
        </ERefreshLoad>
      </template>
    </ETabBar>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
import { actor_search } from '@/api/discover'
export default {
  components: {
    GirlList: () => import('../components/girlList.vue')
  },
  mixins: [scrollMixin],
  data() {
    return {
      content: '',
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.32rem', //默认字体大小
        fontSizeActive: '0.32rem', //选中字体大小
        fontColor: '#333333', //默认字体颜色
        fontColorActive: '#E8619D', //选中字体颜色
        bg: '#fff', //默认背景色
        bgActive: '#fff', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#E8619D',
        lineWidth: '0.16rem', //下划线宽度 String
        sticky: true, //粘性布局
        offsetTop: '0rem', //距顶部距离
        tabFlex: true
      },
      topTabList: [
        {
          id: 0,
          name: '女优'
        },
        {
          id: 1,
          name: '网黄'
        }
        // {
        //   id: 2,
        //   name: '主播'
        // }
      ],
      cupList: [],
      activeIndex: 1,
      actorType: +this.$route.query.sort || 0,
      mediaActorList: [],
      sort: 0,
      pageNum: 1,
      pageSize: 10,
      loading: false, //上拉加载
      finished: false, //上拉加载完成
      refreshing: false, //下拉刷
      tagIds: []
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  created() {
    this.getList()
  },
  methods: {
    onSearch() {
      this.refreshData()
    },
    clickCup(item) {
      if (this.tagIds.includes(item.id)) {
        this.tagIds.splice(this.tagIds.indexOf(item.id), 1)
      } else {
        this.tagIds.push(item.id)
      }
      this.refreshData()
      // this.$router.push({
      //   path: '/discover/filter',
      //   query: {
      //     id: item.id
      //   }
      // })
    },
    handleClick(idx) {
      this.activeIndex = idx
    },
    async getList(type) {
      try {
        const res = await actor_search({
          content: this.content,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          actorType: this.actorType,
          sort: this.sort,
          tagIds: this.tagIds || null
        })
        if (res.code === 200) {
          this.loading = false
          this.refreshing = false
          if (type == 'pull') {
            this.mediaActorList = []
          }
          this.cupList = res.data.tagList
          this.mediaActorList = [...this.mediaActorList, ...res.data.mediaActorList]
          if (!res.data.mediaActorList || res.data.mediaActorList.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
          this.$toast(res.tip)
        }
      } catch (error) {
        this.loading = false
        this.refreshing = false
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试')
      }
    },
    tabbarChange(id) {
      this.actorType = id
      this.pageNum = 1
      this.getList('pull')
    },
    activeIndexChange(idx) {
      this.sort = idx
      this.getList('pull')
    },
    refreshData() {
      this.finished = false
      this.refreshing = true
      this.pageNum = 1
      this.getList('pull')
    },
    //下拉加载
    async moreData() {
      this.loading = true
      this.pageNum += 1
      await this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.search-girl {
  padding: 0.14rem 0.32rem;
  .cuplist {
    overflow: auto;
    @include flexbox($jc: flex-start);
    // display: flex;
    padding-left: 0.15rem;
    padding-top: 0.24rem;
    padding: 0.24rem 0 0.36rem 0.15rem;
    border-bottom: 0.02rem solid $greyf0;
    margin-bottom: 0.36rem;
    > li {
      @include flexbox($jc: center);
      flex-direction: column;
      margin-right: 0.56rem;
      padding: 0rem 0.08rem 0 0;
      white-space: nowrap;
      &:last-child {
        margin-right: 0;
      }
    }
    .tagActive {
      border: 0.02rem solid red;
    }
  }
  .cover-img {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.16rem;
    margin-bottom: 0.08rem;
    :deep() img {
      border-radius: 0.16rem;
    }
  }
}
.top-search {
  @include flex-align-center;
  > span {
    flex: none;
    color: $txtDefault;
    font-size: 0.32rem;
    font-style: normal;
  }
  .van-search {
    flex: 1;
    border-radius: 0.24rem 0.3rem 0.3rem 0.08rem;

    .van-search__content {
      border-radius: 0.24rem 0.3rem 0.3rem 0.08rem;
    }
  }
}
.girllist {
  padding-top: 0.36rem;
  &-top {
    @include flexbox;
    color: $grey;
    font-family: 'PingFang SC';
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 400;
    > ul {
      display: flex;
      li {
        margin-right: 0.12rem;
        transition: all 0.5s;
        &.active {
          color: $txtActive;
        }
      }
      img {
        width: 0.12rem;
      }
    }
    > div {
      @include flex-align-center;
      img {
        height: 0.28rem;
        &:nth-of-type(2) {
          height: 0.34rem;
          margin: 0 0.08rem 0 0.1rem;
        }
      }
    }
  }
}
.hot-item {
  margin-top: 0.24rem;
  img {
    width: 1.56rem;
  }
  > div {
    @include flex-align-center;
    margin-bottom: 0.2rem;
  }
  &-right {
    margin-left: 0.3rem;
    > div {
      &:nth-child(1) {
        color: $txtDefault;
        font-size: 0.32rem;
        font-style: normal;
        font-weight: 400;
      }
      &:nth-child(2) {
        color: $grey;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        @include textoverflow;
        margin: 0.08rem 0;
      }
      &:nth-child(3) {
        display: flex;
        color: $grey67;
        span {
          margin-right: 0.18rem;
          position: relative;
          &:nth-child(-n + 2) {
            &::after {
              content: '|';
              position: absolute;
              right: -0.08rem;
              top: 0;
              // transform: translate(0, -50%);
              width: 0.02rem;
              height: 0.24rem;
            }
          }
        }
      }
    }
  }
}
</style>
