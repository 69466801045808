<template>
  <div v-if="itemList && itemList.length" class="jjhp-list-main">
    <HomeTitle
      :title="name"
      :path="`/acg/acgTopicDetail/?title=${name}&type=${isNovelItem ? 'novel' : 'comics'}&id=${id}`"
    />
    <ul class="jjhp-lsit">
      <li v-for="item in itemList" :key="item.id" @click.stop="toDetail(item)">
        <EDecryptImg class="jjhp-lsit-card_bg" :imgURL="item.coverImg ? item.coverImg : ''">
          <img v-if="isNovelItem" class="isNovelIcon" src="@/assets/imgs/acg/ysxh_left_icon.png" alt="" />
          <!-- 视频分类标签 -->
          <!-- <div class="video-type">
            <div v-if="item.isVip" class="video-type-vip">VIP</div>
            <div v-else-if="item.price > 0" class="video-type-gold">
              <img src="@/assets/imgs/mine/gold.png" alt="" />
              {{ item.price | changeGold }}
            </div>
            <div v-else class="video-type-free">免费</div>
          </div> -->
        </EDecryptImg>
        <div>
          <p class="title">{{ item.title }}</p>
          <div class="tag-list">
            <span
              v-for="tagItem in item.tags"
              :key="tagItem.id"
              @click.stop="
                $router.push(
                  `/acg/AcgTagsList??title=${tagItem.name}&type=${isNovelItem ? 'novel' : 'comics'}&id=${tagItem.id}`
                )
              "
              >{{ tagItem.name }}</span
            >
          </div>
          <SerialStatus :data="item"></SerialStatus>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {
    HomeTitle: () => import('@/components/homeTitle.vue'),
    SerialStatus: () => import('@/components/Home/SerialStatus.vue')
  },
  props: ['itemList', 'name', 'isNovelItem', 'id'],
  methods: {
    toDetail(item) {
      if (!this.isNovelItem) {
        this.$router.push(`/acg/ComicsDetail/?id=${item.id}`)
      } else {
        this.$router.push(`/acg/NovelDetail/?id=${item.id}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.jjhp-list-main {
  margin-bottom: 0.36rem;
}
.jjhp-lsit {
  overflow-x: auto;
  height: 6.4rem;
  @include flexbox($jc: flex-start, $fd: column, $fw: wrap, $ai: flex-start);
  li {
    flex: none;
    @include flexbox($jc: flex-start);
    margin-bottom: 0.2rem;
    margin-right: 0.6rem;

    .jjhp-lsit-card_bg {
      width: 1.44rem;
      height: 2rem;
      margin-right: 0.2rem;
      border-radius: 0.08rem;
      position: relative;
      flex: none;
      :deep() {
        img {
          border-radius: 0.08rem;
        }
      }
    }
    .title {
      max-width: 3.6rem;
      max-height: 0.68rem;
      color: var(--2, #666);
      @include textoverflow(2);
      margin-bottom: 0.22rem;
    }
    &:nth-child(3n) {
      margin-bottom: 0;
    }
  }
}
.tag-list {
  max-width: 3.6rem;
  max-height: 0.68rem;
  @include flexbox($jc: flex-start);

  overflow-x: scroll;
  span {
    flex: none;
    border-radius: 0.64rem;
    border: 0.01rem solid #00929c;
    padding: 0.02rem 0.08rem;
    margin-bottom: 0.08rem;
    margin-right: 0.06rem;
    color: #00929c;
  }
}
.isNovelIcon {
  width: 0.36rem;
  height: 0.36rem;
  position: absolute;

  bottom: 0.08rem;
  left: 0.08rem;
}
.video-type {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.35rem;
  line-height: 0.35rem;
  color: #fff;
  font-size: 0.26rem;
  font-weight: 600;

  div {
    height: 100%;
    height: 0.35rem;
    line-height: 0.35rem;
    text-align: center;
    border-radius: 0.08rem 0rem 0.16rem 0rem;
  }
  &-vip {
    width: 0.64rem;
    background: $gradientBg;
  }
  &-gold {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0.68rem;
    background: linear-gradient(278deg, #892dff 3.02%, #521b99 97.58%);
    img {
      width: 0.22rem;
      height: 0.22rem;
      margin-right: 0.04rem;
    }
  }
  &-free {
    width: 0.64rem;
    background: #1a81ec;
  }
}
</style>
