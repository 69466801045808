<template>
  <div class="home-index">
    <ERefreshLoadV3 ref="ERefreshLoadV3" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
      <ul class="six-list">
        <li v-for="(item, index) in mediaList" :key="index">
          <ComicsOneCard :isDetail="true" :isNovelItem="false" width="2.18rem" height="2.86rem" :itemData="item" />
        </li>
      </ul>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { comicsDetail } from '@/api/acg'
export default {
  name: 'ComicsRecomList', //纯视频模版
  components: {
    ComicsOneCard: () => import('@/components/ComicsTypeCard/comicsOneCard.vue')
  },
  props: ['sortId', 'id'],
  data() {
    return {
      pageNum: 0,
      pageSize: 10,
      finished: false,
      mediaList: [], //视频列表
      sort: null
    }
  },
  computed: {
    // 获取首页轮播广告
    homeAD({ $store }) {
      return $store.getters['homeAD']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  watch: {
    sortId(nval) {
      this.sort = nval
      this.$refs['ERefreshLoadV3'].onRefresh()
    }
  },

  mounted() {
    this.sort = this.sortId
  },

  methods: {
    async onLoad() {
      this.pageNum += 1
      await this.getHomePageData()
    },
    // 下拉刷新
    async onRefresh() {
      this.finished = false
      //表示处于可以下拉状态
      this.pageNum = 0
      this.mediaList = []
    },
    // 获取首页数据
    async getHomePageData() {
      const res = await comicsDetail({
        id: +this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sort: this.sort
      })
      if (res.code === 200) {
        if (res.data.comicsData.lookComics && res.data.comicsData.lookComics.length) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          this.mediaList = [...this.mediaList, ...res.data.comicsData.lookComics]
          if (res.data.comicsData.lookComics.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.six-list {
  @include flexbox($jc: flex-start, $fw: wrap);

  li {
    width: 2.18rem;
    height: 100%;
    margin-right: 0.14rem;
    flex: 1;
    margin-bottom: 0.2rem;
    position: relative;
    z-index: 10;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
