<template>
  <div class="play-main">
    <JavVideo
      v-if="videoInfo && videoInfo.id"
      ref="videoPlayNow"
      :videoInfo="videoInfo"
      :hasPreview="hasPreview"
      @skipPreview="skipPreview"
      @currentTime="fn_currentTime"
    />
    <!-- <van-icon name="arrow-left" /> -->
    <EBackTop class="return" background="transparent" :open-fallback="true" :openFallback="true" color="#fff">
    </EBackTop>
    <div class="packFree">
      <div v-if="videoInfo.payType == 1 && !isVipMember && watchCount" class="freeTimes">
        免费次数剩余：
        <span>{{ watchCount }}</span>
      </div>
      <div v-else-if="videoInfo.payType == 2 && !videoInfo.isBuy" class="freeTimes" @click="skipPreview">
        <!--        <span>{{ videoInfo.price / 100 }}金币</span> 解锁该视频-->
        <span>金币解锁</span> 畅享完整内容
      </div>
      <div v-else-if="videoInfo.payType == 1 && !isVipMember" class="freeTimes" @click="showVipPop = true">
        <span>开通会员</span> 畅享完整内容
      </div>
    </div>
    <!-- 活动倒计时24小时 -->
    <div v-if="hourT > 0 && !isVipMember" class="activity_time">
      <img src="@/assets/imgs/play/activity_icon.png" alt />
      <span>新用户升级会员特惠</span>
      <van-count-down :time="hourT" millisecond>
        <template #default="timeData">
          <span class="advertiseTimeOut-block">{{ timeData.hours >= 10 ? timeData.hours : `0${timeData.hours}` }}</span>
          <span class="advertiseTimeOut-txt">:</span>
          <span class="advertiseTimeOut-block">
            {{ timeData.minutes >= 10 ? timeData.minutes : `0${timeData.minutes}` }}
          </span>
          <span class="advertiseTimeOut-txt">:</span>
          <span class="advertiseTimeOut-block">
            {{ timeData.seconds >= 10 ? timeData.seconds : `0${timeData.seconds}` }}
          </span>
        </template>
      </van-count-down>
      <div class="btn" @click="goToVip">立即开通</div>
    </div>
    <div class="tabs">
      <ETabBar :list="tabList" :tabbarConfig="tabbarConfig" :activeNum="activeId" @change="e => (activeId = e)">
      </ETabBar>
      <PlayInfo
        v-if="activeId === 1"
        :key="1"
        :video-info="videoInfo"
        :episode-info="episodeInfo"
        @updateVideoInfo="getVideoInfo"
      />
      <Comment
        v-else-if="activeId === 2"
        ref="comment"
        :key="2"
        class="comment-box"
        :publisher="videoInfo.publisher"
        :object-id="+$route.params.id"
        :objectype="1"
        :show-opr="false"
      />
      <div v-if="activeId === 2" class="header-right">
        <div class="btn" :class="{ active: sortId === 0 }" @click="switchSort(0)">最新</div>
        <div class="btn" :class="{ active: sortId === 1 }" @click="switchSort(1)">最热</div>
      </div>
      <SelectLine class="select-line" @on-switch="switchLine" />
    </div>

    <NoticeVipPop v-model="showVipPop" @showVip="goToVip" />
    <NoticeCoinPop v-model="showCoinPop" @showCoin="goToCoin" />
    <ConfirmCoinPop
      v-model="showConfirmCoinPop"
      :movieTickets="user.movieTickets"
      :price="videoInfo.price"
      :movieDiscount="videoInfo.movieDiscount"
      @confirm="videoPlay"
    />
    <!-- 会员购买弹窗-->
    <BuyVipPop ref="BuyVipPop" />
    <!--  金币购买弹窗-->
    <BuyGoldPop :videoInfo="videoInfo" ref="BuyGoldPop" />
  </div>
</template>

<script>
import { video_play, video_pay } from 'api/play'
import { advertiseClick } from 'api/home'
import { JumpTo } from '@/utils/utils_tools'
import { isWhiteList } from '@/components/web_base/utils_base/EUtils/utils_tools.js'
import { numberFilter } from '@/filters/filter'
export default {
  name: 'Play',
  components: {
    JavVideo: () => import('@/components/JavVideo.vue'),
    PlayInfo: () => import('@/components/Play/playInfo.vue'),
    SelectLine: () => import('@/components/Play/selectLine.vue'),
    NoticeVipPop: () => import('@/components/Popup/noticeVipPop.vue'),
    NoticeCoinPop: () => import('@/components/Popup/noticeCoinPop.vue'),
    ConfirmCoinPop: () => import('@/components/Popup/confirmCoinPop.vue'),
    Comment: () => import('@/components/comment/index.vue'),
    BuyVipPop: () => import('@/components/Popup/buyVipPop.vue'),
    BuyGoldPop: () => import('@/components/Popup/buyGoldPop.vue')
  },
  inject: ['reload'],
  data() {
    return {
      activeId: 1,
      videoInfo: {
        publisher: {}
      },
      episodeInfo: {},
      videoLike: [],
      viedeoinfoAll: {},
      pageNum: 1,
      pageSize: 10,
      finished: false,
      loading: false,
      refreshing: false,
      skeleton: false,
      code: 0, // 是否可以播放code  6018。金币不足。6031/6032。 次数不足。   6033 需付费
      playable: false, // 是否可以播放
      showPorp: false,
      showShare: false,
      collectInfo: {
        mediaList: []
      },
      showBuy: false,
      EADconfig: {
        speed: 300,
        autoplay: false,
        loop: true,
        delay: 2000
      },
      watchCount: 0,
      hourT: 0,
      hourC: 0,
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.32rem', //默认字体大小
        fontSizeActive: '0.32rem', //选中字体大小
        fontColor: '#333333', //默认字体颜色
        fontColorActive: '#E8619D', //选中字体颜色
        bg: '#fff', //默认背景色
        bgActive: '#fff', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#E8619D',
        lineWidth: '0.16rem', //下划线宽度 String
        offsetTop: '0rem', //距顶部距离
        tabFlex: true
        // sticky: true
      },
      showVipPop: false,
      showCoinPop: false,
      showConfirmCoinPop: false,
      sortId: 0
    }
  },
  computed: {
    tabList() {
      return [
        {
          id: 1,
          name: '详情'
        },
        {
          id: 2,
          name: `评论 ${this.videoInfo.comments ? numberFilter(this.videoInfo.comments) + '+' : 0}`
        }
      ]
    },
    isVipMember({ $store }) {
      return $store.getters['isMember']
    },
    hasPreview() {
      // 只有不是免费视频才走下面一层逻辑
      if (this.playable && this.code === 200) {
        return true
      } else {
        return false
      }
    },
    // 用户余额
    user({ $store }) {
      return $store.getters['getUserInfo']
    },
    playAD({ $store }) {
      return $store.getters['playAD']
    },
    shareUrl() {
      let url = this.$store.getters['shareUrl'] + '/'
      if (url[url.length - 1] == '/') {
        url = url.slice(0, url.length - 1)
      }
      return url
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  watch: {
    $route: {
      async handler(n, o) {
        if (o) {
          if (n.path != o.path) {
            if (!isWhiteList(this.hasPreview)) {
              this.reload()
            }
          }
        }
        await this.getVideoInfo()
        // await this.refreshData()
        // if (this.videoInfo.subType === 1) {
        //   this.$store.dispatch('setAvList', {
        //     type: 'add',
        //     item: this.videoInfo,
        //   });
        // } else {

        // }
        this.setHistory()
      },
      immediate: true
    }
  },
  created() {
    const minute = 1000 * 60
    const hour = minute * 60
    this.hourT = Date.parse(this.user.promotionExpiredAt) - new Date().getTime()
    this.hourC = (Date.parse(this.user.promotionExpiredAt) - new Date().getTime()) / hour
  },
  mounted() {},
  methods: {
    goToVip() {
      this.$refs.BuyVipPop.showClosed = true
    },
    goToCoin() {
      this.$refs.BuyGoldPop.showClosed = true
    },
    switchSort(e) {
      this.sortId = e
      this.$refs.comment.switchSort(e)
    },
    // 设置历史
    setHistory() {
      if (this.videoInfo.videoType === 3) {
        //动漫
        let newVideo = JSON.parse(JSON.stringify(this.videoInfo))
        this.$store.dispatch('setCartoonList', {
          type: 'add',
          item: newVideo
        })
      } else if (this.videoInfo.videoType === 22) {
        this.$store.dispatch('setDreamList', {
          type: 'add',
          item: this.videoInfo
        })
      } else {
        this.$store.dispatch('setLongVideoList', {
          type: 'add',
          item: this.videoInfo
        })
      }
    },
    fn_currentTime(time) {
      //存储本片播放历史记录
      this.videoInfo.currentTime = time
      this.setHistory()
    },
    async clickImg(item) {
      JumpTo(item.href)
      const res = await advertiseClick({
        id: item.id
      }).then()
      if (res !== 200) {
        return this.$toast(res.tip)
      }
    },
    // 跳过预览事件
    skipPreview() {
      if (this.videoInfo.price && !this.videoInfo.isBuy) {
        if (this.userInfo.balance < this.videoInfo.price && !this.userInfo.movieTickets) {
          this.showCoinPop = true
        } else {
          this.showConfirmCoinPop = true
        }
        return
      } else if (!this.videoInfo.price && this.videoInfo.payType === 1 && !this.videoInfo.isBuy && !this.playable) {
        this.showVipPop = true
        return
      }
    },
    // 获取视频信息
    async getVideoInfo() {
      try {
        const res = await video_play({
          id: +this.$route.params.id
        })
        if (res.code === 200) {
          console.log(res, 'rrr')
          this.collectInfo = res.data.collectInfo
          this.videoInfo = res.data.mediaInfo
          this.episodeInfo = res.data.episode
          this.code = res.data.code
          this.playable = res.data.playable
          this.watchCount = res.data.watchCount
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求错误，请稍后再试!')
      }
    },
    //  视频购买接口
    async videoPlay(type) {
      try {
        const res = await video_pay({
          id: +this.$route.params.id,
          payType: type
        })
        if (res.code === 200) {
          if (res.data.code !== 200) {
            return this.$toast(res.data.msg)
          }
          this.$refs.videoPlayNow.skipVideoPlay()
          this.showPorp = false
          this.code = 200
          this.playable = true
          await this.getVideoInfo()
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试！')
      }
    },
    switchLine() {
      // 如果用户是相应的VIP，执行以下操作
      this.$refs.videoPlayNow.dp.currentTime = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.of-limits-area {
  width: 100%;
  height: 100%;
  max-height: 4.16rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > div:nth-child(1) {
    position: absolute;
    right: 0.82rem;
    top: 1.03rem;
    background: #00f6f8;
    width: 0.32rem;
    height: 0.32rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: 0.3rem;
      height: 0.3rem;
    }
  }

  .info {
    > div {
      text-align: center;

      &:nth-child(1) {
        color: #fff;
        font-size: 0.32rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &:nth-child(2) {
        color: rgba(255, 255, 255, 0.5);
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0.24rem 0;
      }

      &:nth-child(3) {
        border-radius: 0.32rem;
        background: linear-gradient(272deg, #491ccb -11.14%, #8235ff 94.43%);
        padding: 0.08rem 0.32rem;
        color: #fff;
        font-size: 0.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        > span:nth-child(2) {
          color: #f9c006;
          margin: 0 0.1rem;
        }
      }
    }
  }
}

.play-main {
  min-height: 100vh;
  padding-top: 4.16rem;

  .return {
    font-size: 0.36rem;
    position: fixed;

    z-index: 999;

    :deep() {
      .van-nav-bar__placeholder {
        height: 0 !important;
      }
    }
  }

  .packFree {
    width: 100%;
    z-index: 2012;
    position: fixed;
    top: 0;
    max-width: $pcMaxWidth;

    .freeTimes {
      line-height: 0.54rem;
      padding: 0 0.16rem;
      border-radius: 0.12rem;
      background: rgba(0, 0, 0, 0.6);
      font-size: 0.24rem;
      color: #fff;
      position: absolute;
      top: 0.46rem;
      right: 0.32rem;
      z-index: 2012;

      span {
        color: rgba(253, 227, 156, 1);
      }
    }
  }
}

.time-txt {
  font-size: 0.26rem;
  color: #848494;
}

.other-main {
  padding: 0.3rem 0.25rem;

  .recommend-top {
    margin-bottom: 0.3rem;
    font-size: 0.34rem;
  }

  .swiperPack {
    width: 100%;
    border-radius: 0.1rem;
    overflow: hidden;
  }
}

// 视频购买提示
.playWaring {
  width: 100%;
  z-index: 2013;
  height: 4.16rem;
  position: fixed;
  max-width: $pcMaxWidth;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);

  color: #fff;

  // 金币购买
  .goldTip,
  .vipTip,
  .goldTip-noGold {
    font-size: 0.3rem;
    width: 100%;
    height: 100%;
    text-align: right;
    max-height: 4.16rem;

    :deep() {
      .van-icon {
        margin-top: 0.3rem;
      }
    }

    &-first {
      @include flexbox($jc: center, $ai: center, $fd: column, $fw: nowrap);

      h2 {
        font-size: 0.32rem;
        margin: 0.2rem 0;
      }

      p {
        margin: 0;
      }

      p,
      span {
        font-size: 0.2rem;
      }

      .btn {
        margin: 0.5rem 0;
        width: 100%;
        text-align: center;
        @include flexbox($jc: center, $ai: center, $fd: row, $fw: nowrap);
        font-size: 0.3rem;

        span {
          padding: 0.08rem 0.3rem;
          background: $btnBg;
          border-radius: 0.3rem;
          color: #333;
          margin-right: 0.3rem;
        }

        span:last-child {
          background: none;
          border: 0.02rem solid #fff;
          color: #fff;
        }
      }
    }
  }
}

// 新用户活动倒计时
.activity_time {
  background: linear-gradient(91.4deg, #a00014 6.02%, #4e0202 100.68%);
  height: 0.72rem;
  margin-top: 0;
  @include flexbox($jc: flex-start);
  padding: 0 0.36rem;
  color: rgba(255, 190, 93, 1);

  > span {
    white-space: nowrap;
  }

  img {
    width: 0.3rem;
    height: 0.27rem;
    margin-right: 0.2rem;
  }

  .btn {
    margin: 0 0 0 auto;
    border-radius: 0.08rem;
    background: #ffcf86;
    padding: 0.04rem 0.16rem;
    color: rgba(75, 21, 24, 1);
  }

  :deep() {
    .van-count-down {
      font-size: 0.24rem;
      color: #fff;
      margin: 0 0.26rem;
    }

    .advertiseTimeOut-txt {
      margin: 0 0.1rem;
    }

    .advertiseTimeOut-block {
      display: inline-block;
      border-radius: 0.06rem;
      border: 0.01rem solid rgba(255, 227, 129, 1);
      background: #1b1b1b;
      width: 0.4rem;
      height: 0.34rem;
      text-align: center;
      line-height: 0.34rem;
    }
  }
}

.movie-tickets-btn,
.movie-gold-btn {
  @include flexbox();
  border-radius: 0.32rem;
  background: #886232;
  padding: 0.08rem 0.32rem;
  font-size: 0.3rem;

  img {
    width: 0.30857rem;
    height: 0.24rem;
    margin-right: 0.1rem;
  }
}

.movie-gold-btn {
  background: linear-gradient(90deg, #00bbbc 0%, #0070c0 100%);
  margin-right: 0.2rem;

  img {
    width: 0.32rem;
    height: 0.32rem;
  }
}

.tabs {
  position: relative;

  .comment-box {
    padding: 0 0.32rem 1.34rem;
    // :deep() {
    //   .content-main-wrap {
    //     height: 9.5rem !important;
    //   }
    // }
  }

  .header-right {
    position: absolute;
    right: 2rem;
    top: 0.25rem;
    z-index: 99;
    display: flex;
    align-items: center;
    color: #999999;

    .btn {
      margin: 0 0.08rem;

      &.active {
        color: #e8619d;
      }
    }
  }

  .select-line {
    position: absolute;
    right: 0.32rem;
    top: 0.25rem;
    z-index: 99;
  }
}
</style>
