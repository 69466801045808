<template>
  <div class="my_publish">
    <EBackTop title="我的发布">
      <slot slot="right" name="right">
        <!--        <p class="edit-btn" @click="editList">{{ isEdit ? '取消管理' : '管理' }}</p>-->
        <div class="right-txt" @click="addPublish">
          <img src="@/assets/imgs/mine/publish_icon.png" alt />
          创建
        </div>
      </slot>
    </EBackTop>
    <!-- tabs -->
    <div class="tabs">
      <div class="first_main">
        <ul class="first_list">
          <li
            v-for="item in firstList"
            :key="item.id"
            :class="titleActive === item.id ? 'active' : ''"
            @click="changTitleType(item.id)"
          >
            {{ item.name }}
          </li>
        </ul>
        <i class="line" :style="{ left: `${titleActive * 1.36}rem` }"></i>
      </div>

      <!-- 播单选项卡 -->
      <div v-if="titleActive == 0" class="secound_main">
        <ul class="secound_list">
          <li v-for="item in tabList" :key="item.id" :class="tabId === item.id ? 'active' : ''" @click="changTab(item)">
            {{ item.name }}
            <i v-if="tabId === item.id" class="line"></i>
          </li>
        </ul>
      </div>

      <!-- 帖子选项卡 -->
      <div v-if="titleActive == 1" class="secound_main">
        <ul class="secound_list">
          <li
            v-for="item in secoundList"
            :key="item.id"
            :class="typeActive === item.type ? 'active' : ''"
            @click="changSlotType(item.type, item.id)"
          >
            {{ item.name }}
            <i v-if="typeActive === item.type" class="line"></i>
          </li>
        </ul>
      </div>
    </div>
    <!-- 列表数据 -->
    <ERefreshLoadV3
      ref="ERefreshLoadV3"
      class="main_list"
      :beforeRefresh="onRefresh"
      :moreData="onLoad"
      :finished="finished"
    >
      <div class="my_publish_list_main">
        <!-- 播单 -->
        <template v-if="titleActive == 0">
          <BoDanItem
            v-for="itemData in dataList"
            :key="itemData.id"
            :type="typeActive"
            itemContent="publish"
            :itemData="itemData"
            :isEdit="isEdit"
            @reloadList="getMyPublishList('pull')"
          />
        </template>
        <!-- 帖子 -->
        <template v-else>
          <PostHistoryItem
            v-for="itemData in dataList"
            :key="itemData.id"
            :type="typeActive"
            :itemData="itemData"
            itemContent="publish"
          />
        </template>
      </div>
    </ERefreshLoadV3>
    <!-- 发布帖子类型弹窗 -->
    <van-action-sheet v-model="showUpBtn" title="选择发布类型">
      <ul class="btn_detail">
        <li class="btn-item" @click.stop="$router.push(`/community/publish?postType=${'img'}&from=2`)">
          <img src="@/assets/imgs/community/up_photo.png" alt />
          <div class="label">图片</div>
        </li>
        <li class="btn-item" @click.stop="$router.push(`/community/publish?postType=${'video'}&from=2`)">
          <img src="@/assets/imgs/community/up_video.png" alt />
          <div class="label">视频</div>
        </li>
        <li class="btn-item" @click.stop="$router.push(`/community/publish?postType=${'txt'}&from=2`)">
          <img src="@/assets/imgs/community/up_photo_txt.png" alt />
          <div class="label">图文</div>
        </li>
      </ul>
    </van-action-sheet>
  </div>
</template>

<script>
import { user_post_publish } from '@/api/dating.js'
import { myPlayList } from '@/api/playList'
export default {
  components: {
    BoDanItem: () => import('@/components/historyItems/boDanPublishItem.vue'),
    PostHistoryItem: () => import('@/components/historyItems/postPublishItem.vue')
  },
  data() {
    return {
      firstList: [
        { id: 0, name: '我的播单' },
        { id: 1, name: '我的帖子' }
      ],
      secoundList: [
        // { id: 0, name: '全部', type: 3 },
        { id: 1, name: '审核中', type: 3 },
        { id: 2, name: '已发布', type: 1 },
        { id: 3, name: '未通过', type: 4 }
      ],
      activeTypeId: 0,
      isEdit: false,
      titleActive: 1,
      typeActive: 3,
      itemData: {
        base: {},
        node: {}
      },
      tabList: [
        { id: 0, name: '全部' },
        { id: 1, name: '我的分享' }
      ],
      tabId: 0,
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 关注列表
      dataList: [],

      // 当前页
      pageNum: 0,
      // 当前个数
      pageSize: 10,
      showUpBtn: false
    }
  },
  computed: {
    info({ $store }) {
      return $store.getters['getUserInfo']
    },
    getPlayListData({ $store }) {
      return $store.getters['getPlayListData']
    }
  },

  methods: {
    addPublish() {
      if (this.titleActive === 0) {
        this.$router.push('/playList/publish')
      } else {
        this.showUpBtn = true
      }
    },
    async changTitleType(titleId) {
      this.titleActive = titleId
      await this.getMyPublishList('pull')
    },
    // 下拉加载
    async onLoad() {
      this.pageNum++
      await this.getMyPublishList()
    },
    // 上拉刷新
    async onRefresh() {
      this.pageNum = 0
      this.dataList = []
    },
    async changSlotType(slotType, id) {
      this.typeActive = slotType
      this.activeTypeId = id
      await this.getMyPublishList('pull')
    },

    changTab(item) {
      this.tabId = item.id
      this.getMyPublishList('pull')
    },

    editList() {
      this.isEdit = !this.isEdit
    },
    // 获取我的发布列表
    async getMyPublishList(type) {
      if (type) {
        this.pageNum = 1
        this.dataList = []
      }
      if (this.titleActive == 0 && this.tabId == 0) {
        this.dataList = this.getPlayListData
      }
      const lisApi = {
        0: myPlayList,
        1: user_post_publish
      }
      const res = await lisApi[this.titleActive]({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        status: this.typeActive,
        userId: this.info.id
      })
      if (res.code == 200) {
        if (this.titleActive === 0) {
          if (res.data.playList && res.data.playList.length > 0) {
            this.dataList = [...res.data.playList, ...this.dataList]
            if (res.data.playList.length < this.pageSize) {
              this.finished = true
            }
          } else {
            this.finished = true
          }
        } else {
          if (res.data.list && res.data.list.length > 0) {
            this.dataList = [...res.data.list, ...this.dataList]
            if (res.data.list.length < this.pageSize) {
              this.finished = true
            }
          } else {
            this.finished = true
          }
        }
      } else {
        this.finished = true
        return this.$toast('没有更多数据!')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my_publish {
  :deep() {
    .van-nav-bar__content {
      justify-content: flex-start;
    }
    .van-nav-bar__title {
      margin-left: 1rem;
    }
  }
}
.right-txt {
  padding: 0.06rem 0.24rem;
  border-radius: 0.64rem;
  color: #fff;
  background: $gradientBg;
  @include flexbox($jc: flex-start);

  img {
    width: 0.28rem;
    height: 0.28002rem;
    margin-right: 0.12rem;
  }
}
.edit-btn {
  margin-right: 0.24rem;
}

.tabs {
  position: fixed;
  top: 0.9rem;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: $pcMaxWidth;
  height: 1rem;
  @include flexbox();
  padding: 0 0.36rem;
  background-color: #fff;
  z-index: 1;

  .first_main,
  .secound_main {
    position: relative;
    font-size: 0.26rem;
    flex: none;
  }

  .first_list,
  .secound_list {
    @include flexbox($jc: flex-start);
  }

  .first_list {
    border-radius: 0.64rem;
    background: rgba(0, 0, 0, 0.06);

    li {
      width: 1.36rem;
      height: 0.5rem;
      text-align: center;
      line-height: 0.5rem;
    }

    .active {
      color: #fff;
    }
  }

  .first_main {
    .line {
      position: absolute;
      border-radius: 0.27rem;
      background: #e8619d;
      top: 0;
      padding: 0.04rem 0.16rem;
      width: 1.36rem;
      height: 0.5rem;
      z-index: -1;
      transition: left 0.1s linear;
    }
  }

  .secound_main {
    .secound_list {
      color: rgba($color: #666, $alpha: 0.8);

      li {
        margin-right: 0.16rem;
        height: 0.5rem;
        width: 0.8rem;
        text-align: center;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:last-child {
          margin-right: 0;
        }
      }

      .active {
        color: #333;
      }
      .line {
        position: absolute;
        border-radius: 0.02rem;
        background: #e8619d;
        top: 0.5rem;
        padding: 0.04rem 0.16rem;
        width: 0.36rem;
        height: 0.04rem;
        z-index: -1;
        transition: left 0.1s linear;
      }
    }
  }
}

.main_list {
  padding: 0 0.36rem 0.38rem;
  margin-top: 1rem;
}
.btn_detail {
  padding-top: 0.7rem;
  padding-bottom: 0.76rem;
  @include flex-center;
  text-align: center;
  .btn-item {
    margin: 0.25rem;
    color: #999999;
    .label {
      margin-top: 0.16rem;
    }
  }
}
</style>
