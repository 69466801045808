<!--
 * @Author:
 * @Mail:
 * @Date: 2024-02-02 11:16:31
 * @LastEditTime: 2024-02-06 10:09:36
 * @LastEditors: 江涛 wangtao524713@gmail.com
 * @FilePath: /haoseH5/src/components/Home/showType/ShortRow.vue
-->
<template>
  <div>
    <div class="title" @click="handleClickTitle">{{ title }} <img src="@/assets/imgs/home/more.png" alt="" /></div>
    <div class="content">
      <div v-for="(data, index) in list" :key="index" class="content-item" @click="toShortVideo(list, index)">
        <div class="cover">
          <EDecryptImg :imgURL="data.coverImg" class="cover-img"> </EDecryptImg>
          <VideoCardMask :data="data"></VideoCardMask>
        </div>
        <VideoCardFooter :data="data"></VideoCardFooter>
      </div>
    </div>
  </div>
</template>

<script>
import { JumpTo } from '@/utils/utils_tools'
export default {
  components: {
    VideoCardFooter: () => import('@/components/Home/VideoCardFooter.vue'),
    VideoCardMask: () => import('@/components/Home/VideoCardMask.vue')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {
    toShortVideo(list, index) {
      this.$store.commit('SET_VIDEOLIST', list)
      this.$store.commit('SET_SHORT_VIDEO_INDEX', index)
      this.$router.push({
        path: '/play/shortVideo'
      })
    },
    handleClickTitle() {
      if (this.item.jumpType) {
        if (this.item.jumpUrl) {
          JumpTo(`${this.item.jumpUrl}?showType=${this.item.showType}`)
        } else {
          this.$router.push({
            path: '/home/videoTopicDetail',
            query: {
              id: this.item.id,
              name: this.item.title,
              showType: this.item.showType || ''
            }
          })
        }
      } else {
        this.$emit('handleClickTitle', this.item.jumpCategoryId)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  width: 100%;
  height: 0.44rem;
  font-size: 0.32rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  margin-top: 0.24rem;
  overflow-x: auto;
  @include flexbox($jc: flex-start, $ai: flex-start, $fd: row, $fw: norwap, $flex: flex);

  .content-item {
    width: 1.09 * 2rem;
    height: 2 * 2rem;
    margin-right: 0.08 * 2rem;
    flex-shrink: 0;
    position: relative;
    margin-bottom: 0.32rem;
    // &:nth-child(3n + 3) {
    //   margin-right: 0;
    // }
    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
      margin-bottom: 0;
    }

    .cover {
      position: relative;
      height: 1.45 * 2rem;
      border-radius: 0.06rem 0.06rem 0 0;
      overflow: hidden;
    }
  }
}
</style>
