<template>
  <div class="index-label">
    <EBackTop :background="bg" class="bg" :color="color"> </EBackTop>
    <div class="header-content">
      <img src="@/assets/imgs/community/myInfoBg.jpg" alt="" class="labelBg" />
      <div class="labelData">
        <div class="label-top">
          <div class="labelAvatar">
            <EDecryptImg :img-c-d-n="imgCDN" :img-u-r-l="publisher.avatar" class="item-bg"></EDecryptImg>
          </div>
          <div class="user_name">
            <span class="nick_name">{{ publisher.nickname }}</span>
            <!--            <span v-if="publisher.cardName" class="card_name">{{ publisher.cardName }}</span>-->
          </div>
          <div class="right">
            <div class="message" @click="toMsg">
              <img src="@/assets/imgs/community/message.png" alt="" />
              <span>私信TA</span>
            </div>
            <div :class="publisher.isCare ? 'isFollow' : 'follow'" @click="toFollow">
              {{ publisher.isCare ? '已关注' : '+关注' }}
            </div>
          </div>
        </div>
        <div class="label-middle">
          <div class="label-id">Mr.色ID {{ +$route.query.id }}</div>
          <div class="label-line"></div>
          <div class="label-level">LV.{{ publisher.userLevel }}</div>
        </div>
        <div class="label-bottom">{{ publisher.introduction }}</div>
        <div class="label-ans-wrap">
          <div class="label-ans">
            <div class="label-ans-item">
              <div class="label-ans-count">{{ publisher.likeCount | numberFilter }}</div>
              <div class="label-ans-label">获赞</div>
            </div>
            <div class="divider"></div>
            <div class="label-ans-item">
              <div class="label-ans-count">{{ publisher.careCount | numberFilter }}</div>
              <div class="label-ans-label">关注</div>
            </div>
            <div class="divider"></div>
            <div class="label-ans-item">
              <div class="label-ans-count">{{ publisher.fans | numberFilter }}</div>
              <div class="label-ans-label">粉丝</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    切换面板-->
    <div class="label-pack">
      <div class="title">
        推荐播单<img src="@/assets/imgs/home/more.png" alt="" @click="$router.push('/search?type=13')" />
      </div>
      <div class="label-pack-recommend">
        <PlayListShowType2 v-for="item in rdPlayList" :key="item.id" class="label-pack-recommend-item" :data="item" />
      </div>
      <div class="divider"></div>
      <ERefreshLoadV3 :finished="finished" :beforeRefresh="refreshData" :moreData="moreData">
        <div class="tab-wrap">
          <ETabBar :list="category" :tabbar-config="tabbarConfig" :active-num="type" @change="tabbarChange">
            <template #default="scope">
              <div class="list_data">
                <template v-if="scope.data.id === 0 && type === 0">
                  <PostItemWithOpr v-for="(item, index) in myPostList" :key="index" :data="item" class="post-item">
                    <template #top="{ data }">
                      <!-- 发布时间 -->
                      <div class="watches_num">
                        <p>发布时间: {{ data.base.createdAt | timeDiff }}</p>
                      </div>
                    </template>
                  </PostItemWithOpr>
                </template>
                <template v-else-if="scope.data.id === 1 && type === 1">
                  <PostItemWithOpr v-for="(item, index) in myPostList" :key="index" :data="item" class="post-item">
                    <template #top="{ data }">
                      <!-- 发布时间 -->
                      <div class="watches_num">
                        <p>发布时间: {{ data.base.createdAt | timeDiff }}</p>
                      </div>
                    </template>
                  </PostItemWithOpr>
                </template>
                <template v-else-if="scope.data.id === 2 && type === 2">
                  <CommentCard
                    v-for="item in myComment"
                    :key="item.id"
                    :data="item"
                    :publisher="publisher"
                  ></CommentCard>
                </template>
              </div>
            </template>
          </ETabBar>

          <div class="header-right">
            <div class="btn" :class="{ active: sortId === 0 }" @click="switchSort(0)">最新</div>
            <div>/</div>
            <div class="btn" :class="{ active: sortId === 1 }" @click="switchSort(1)">最热</div>
          </div>
        </div>
      </ERefreshLoadV3>
    </div>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
import { user_media } from 'api/community'
import { care_add } from 'api/home'
export default {
  components: {
    PostItemWithOpr: () => import('@/components/Community/postItemWithOpr.vue'),
    CommentCard: () => import('@/components/comment/CommentCard.vue'),
    PlayListShowType2: () => import('@/components/Home/showType/playListType/playListShowType2.vue')
  },
  mixins: [scrollMixin],
  data() {
    return {
      category: [
        { name: '帖子', id: 0 },
        { name: '喜欢', id: 1 },
        { name: '评论', id: 2 }
      ],
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.28rem', //默认字体大小
        fontSizeActive: '0.3rem', //选中字体大小
        fontColor: '#999', //默认字体颜色
        fontColorActive: '#E8619D', //选中字体颜色
        bg: '#ffffff', //默认背景色
        bgActive: '#ffffff', //选中背景色
        lineBott: '0.2rem',
        lineColorActive: '#E8619D',
        lineWidth: '.28rem', //下划线宽度 String
        lineHeight: '.04rem', //下划线高度 String
        sticky: true, //粘性布局
        offsetTop: '1rem', //距顶部距离
        tabFlex: true
      },
      pageNum: 1,
      pageSize: 10,
      loading: false,
      refreshing: false,
      finished: false,
      myComment: [],
      myPostList: [],
      type: 0,
      publisher: {},
      rdPlayList: [],
      sortId: 0,
      bg: 'transparent',
      color: '#fff'
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  created() {
    this.type = this.category[0].id

    this.refreshData()
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandler)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler)
  },
  methods: {
    scrollHandler() {
      if ((document.documentElement.scrollTop || document.body.scrollTop) > 300) {
        this.bg = '#ffffff'
        this.color = '#000'
      } else {
        this.bg = 'transparent'
        this.color = '#ffffff'
      }
    },
    toMsg() {
      this.$router.push(`/chat/${this.$route.query.id}?nickName=${this.publisher.nickname}`)
    },
    // 关注
    async toFollow() {
      // if (this.userInfo.loginType == 0) {
      //   return this.$toast('仅注册的用户才能关注')
      // }
      // 关注用户
      try {
        const res = await care_add({
          id: +this.$route.query.id,
          add: !this.publisher.isCare,
          userType: 0
        })
        if (res.code === 200) {
          this.publisher.isCare = !this.publisher.isCare
          if (this.publisher.isCare) {
            return this.$toast('关注成功')
          } else {
            return this.$toast('取消关注')
          }
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试')
      }
    },
    async fn_labelTopicList() {
      const res = await user_media({
        userId: +this.$route.query.id,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        collectType: 5,
        sort: this.sortId,
        type: this.type
      })
      if (res.code === 200 && res.data) {
        this.publisher = res.data
        this.rdPlayList = res.data.rdPlayList
        if (this.type === 0 || this.type === 1) {
          this.myPostList = [...this.myPostList, ...res.data.myPostList]
          if (res.data.myPostList.length < this.pageSize || !res.data.myPostList) {
            this.finished = true
          }
        } else {
          this.myComment = [...this.myComment, ...res.data.myComment]
          if (res.data.myComment.length < this.pageSize || !res.data.myComment) {
            this.finished = true
          }
        }
      } else {
        this.finished = true
        return this.$toast(res.tip)
      }
    },
    async tabbarChange(type) {
      this.type = type
      this.myPostList = []
      this.myComment = []
      this.pageNum = 0
      this.finished = false
      // await this.fn_labelTopicList()
    },

    switchSort(e) {
      this.sortId = e
      this.pageNum = 1
      this.myPostList = []
      this.myComment = []
      this.fn_labelTopicList()
    },
    // 上拉加载更多
    async moreData() {
      this.pageNum += 1
      await this.fn_labelTopicList()
    },
    // 下啦刷新
    refreshData() {
      this.finished = false
      this.myPostList = []
      this.myComment = []
      this.pageNum = 0
    }
  }
}
</script>
<style lang="scss" scoped>
.index-label {
  .bg {
    position: fixed;
    top: 0;
    z-index: 2;
  }

  .header-content {
    position: relative;
    height: 6.08rem;
    width: 100%;
    max-width: $pcMaxWidth;

    .labelBg {
      -webkit-filter: brightness(35%);
      /*考虑浏览器兼容性：兼容 Chrome, Safari, Opera */
      filter: brightness(35%);
      width: 100%;
      height: 4.6rem;
    }

    .labelData {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;

      padding: 1rem 0 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      display: flex;
      flex-direction: column;

      .label-top {
        padding: 0 0.32rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .labelAvatar {
          width: 1.12rem;
          height: 1.12rem;
          border-radius: 50%;
          overflow: hidden;
        }
        .user_name {
          flex-grow: 1;
          margin-left: 0.24rem;
          .nick_name {
            font-weight: 500;
            font-size: 0.32rem;
            line-height: 0.44rem;
            color: #ffffff;
          }
        }
        .right {
          @include flex-center;
          color: #ffffff;
          .message {
            @include flex-center;
            margin-right: 0.12rem;
            border-radius: 0.24rem;
            width: 1.52rem;
            height: 0.48rem;
            background-color: rgba(255, 255, 255, 0.2);
            > img {
              margin-right: 0.12rem;
              width: 0.28rem;
              height: 0.28rem;
            }
          }
          .follow {
            @include flex-center;
            border-radius: 0.24rem;
            width: 1.2rem;
            height: 0.48rem;
            background-color: rgba(255, 255, 255, 0.2);
          }
          .isFollow {
            border-radius: 1.04rem;
            padding: 0.06rem 0.24rem;
            color: $btnBg;
            // margin-top: 0.24rem;
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }
      .label-middle {
        margin: 0.24rem 0;
        padding: 0 0.32rem;
        display: flex;
        align-items: center;
        color: #999999;
        .label-id {
          font-weight: 500;
          font-size: 0.24rem;
          line-height: 0.36rem;
          color: #999999;
        }
        .label-line {
          margin: 0 0.24rem;
          width: 0.02rem;
          height: 0.2rem;
          background-color: #666666;
        }
        .label-level {
          @include flex-center;
          background: linear-gradient(0deg, #ffd300, #ffd300);
          width: 0.6rem;
          height: 0.24rem;
          border-radius: 0.16rem;
          color: #000000;
          font-size: 0.2rem;
        }
      }
      .label-bottom {
        padding: 0 0.32rem;
        font-weight: 400;
        font-size: 0.24rem;
        line-height: 0.34rem;
        height: 0.68rem;
        color: #666666;
        @include textoverflow(2);
      }
      .label-ans-wrap {
        margin: 0.24rem 0;
        padding: 0.14re 0 0;
        flex-grow: 1;
        background-color: #000000;

        .label-ans {
          @include flex-center;
          color: #ffffff;
          height: 0.9rem;
          .label-ans-item {
            text-align: center;
            .label-ans-count {
              font-weight: 500;
              font-size: 0.32remx;
              line-height: 0.44rem;
            }
            .label-ans-label {
              font-style: normal;
              font-weight: 500;
              font-size: 0.24rem;
              line-height: 0.34rem;
              opacity: 0.6;
            }
          }
          .divider {
            margin: 0 0.88rem;
            flex-shrink: 0;
            width: 0.02rem;
            height: 0.4rem;
            opacity: 0.2;
            background-color: #ffffff;
          }
        }
      }
    }
  }

  .label-pack {
    background: #ffffff;
    border-radius: 0.42rem 0.42rem 0 0;
    border-top: 0.01rem solid #535353;
    position: relative;
    padding-top: 0.32rem;
    top: -0.64rem;

    .title {
      padding: 0 0.32rem;
      width: 100%;
      height: 0.44rem;
      font-size: 0.32rem;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .label-pack-recommend {
      margin-top: 0.24rem;
      padding: 0 0 0 0.32rem;
      display: flex;
      overflow-x: auto;
      @include scrollbar-hide;
      .label-pack-recommend-item {
        margin-right: 0.1rem;
      }
    }
    .divider {
      margin: 0.36rem auto;
      width: 6.86rem;
      height: 0.02rem;
      background: #f0f0f0;
    }
    .tab-wrap {
      position: relative;
      :deep() {
        .van-sticky {
          z-index: 99;
          padding: 0 0.16rem;
          background-color: #ffffff;
          .van-tab {
            flex: inherit;
          }
        }

        .van-tabs__nav {
          background: transparent !important;
        }
      }
      .list_data {
        @include flexbox($fw: wrap, $ai: flex-start);
        padding: 0 0.32rem;
        .post-item {
          margin-top: 0.36rem;
          border-bottom: 0.01rem solid #f0f0f0;
          .watches_num {
            margin: 0 0 0.24rem;
            display: inline-block;
            background: rgba(221, 0, 27, 0.05);
            border-radius: 0.48rem;
            color: #e8619d;
            padding: 0.04rem 0.16rem;
          }
        }
      }

      .header-right {
        position: absolute;
        right: 0.32rem;
        top: 0.2rem;
        display: flex;
        align-items: center;
        color: #999999;
        z-index: 99;
        .btn {
          &.active {
            color: #e8619d;
          }
        }
      }
    }
  }
}
</style>
