<!--
使用方法
    <ESCVideoCover class="md-img" :imgURL="item.coverImg" :videoURL="item.preVideoUrl?videoPath+item.preVideoUrl:''">
      <DmHomeVideoPayType :imgRadius="imgRadius" :item="item"></DmHomeVideoPayType>
      <div class="time-wrap">
        <div class="hot">
          <template>
            <img src="/newAppMh/home-comic/fire.png" alt="" />
            <span>{{ item.watchTimes | numberFilter }}</span>
          </template>
        </div>
        <div>
        <span class="time" v-if="isTopic">{{item.playTime | secondToDate}}</span>
        <span class="time" v-else>{{ item.addedTime | timeDiff }}</span>
        </div>
      </div>
    </ESCVideoCover>
    topHeight:指示器距上边框位置，默认8rem
    imgURL:封面url
    videoUrl:视频m3u8（加接口 加路径 加token的完整版）例如： this.videoUrl = '/api/app/media/m3u8ex/' + this.data.preVideoUrl + `?token=${this.token}`;
-->
<template>
  <div ref="item" class="video-item-cover">
    <div style="width:100%;height:100%;position:absolute;z-index: 100;"></div>
    <div v-if="showVideo" class="player" ref="palyer"></div>
    <EDecryptImg v-else  :imgURL="imgURL">
      <slot></slot>
    </EDecryptImg>

  </div>
</template>
<script>
export default {
  name: 'ESCVideoCover',
  components: {
    EDecryptImg: () => import('../EDecryptImg'),
  },
  props: {
    imgURL: {
      type: String,
      default() {
        return '';
      },
    },
    videoURL: {
      type: String,
      default() {
        return '';
      },
    },
    topHeight: {
      type: Number,
      default() {
        return 316;
      },
    },
  },
  data() {
    return {
      showVideo: false,
      player: null,
      elH:undefined,//当前item高度
      topH: 8, //距上边距实际距离
    };
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
  },

  mounted() {
    this.elH=this.$el.offsetHeight
    this.topH = this.topHeight ;
    if (this.videoURL) {
      window.addEventListener('scroll', this.scroll,true);
    }
  },
  methods: {
    scroll() {
      let top = this.$el.getBoundingClientRect().top; //获取当前上边距 距上边框距离
      if (top < this.topH && top >= this.topH-this.elH) {
        if (!this.player) {
          this.showVideo = true;
          this.$nextTick(() => {
            this.initPlayer();
          });
        }
      } else if (top < this.topH-this.elH) {
        if (this.showVideo !== false) {
          this.player.destroy(true);
          this.showVideo = false;
          this.player = null;
        }
      } else if (top >= this.topH) {
        if (this.showVideo !== false) {
          this.player.destroy(true);
          this.showVideo = false;
          this.player = null;
        }
      }
    },
    async initPlayer() {
      let width = this.$refs['item'].offsetWidth;
      let height = this.$refs['item'].offsetHeight;
      const HlsJsPlayer = require('xgplayer-hls.js');
      let playerDom = this.$refs['palyer'];
      let that = this;
      this.player = new HlsJsPlayer({
        el: playerDom, //容器ID
        width: width,
        height: height,
        lang: 'zh-cn',
        fluid: true, //流式布局
        autoplay: true, // 是否自动播放
        autoplayMuted: true, //是否静音播放
        volume: 0, // 音量

        loop: false, // 循环
        controls: false,
        cssFullscreen: false,
        videoInit: true, // 初始化视频首帧
        airplay: true,
        playsinline: true,
        url: this.videoURL,
        execBeforePluginsCall: [
          () => {
            console.log('Execute before plugins call');
          },
        ],
        'x5-video-player-type': 'h5', //微信同层播放：
        'x5-video-player-fullscreen': 'true', //微信全屏播放：
        'x5-video-orientation': 'landscape', //微信横竖屏控制
      });

      this.player.on('error', error => {
        console.log(error);
      });
      this.player.on('ended', () => {
        that.player.destroy(true);
        that.showVideo = false;
      });
    },
  },
  beforeDestroy(){
    window.removeEventListener('scroll', this.scroll,true);
  }
};
</script>
<style lang="scss" scoped>
.video-item {
  overflow: hidden;
  display: block;
  margin-bottom: 0.2rem;

  // padding-right: 5px;
  .video-item-cover {
    position:relative;
    border-radius: 0.08rem;
    overflow: hidden;
  }
}

.player {
  width: 100%;
  height: 100%;
}
</style>
