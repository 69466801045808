<template>
  <div>
    <EBackTop
      :background="'transparent'"
      :color="'#333'"
      :placeholder="false"
      @goBack="leftArrowBack"
      :hasSuperiorClick="true"
    />
    <div class="ai-generate">
      <div @click="$router.push('/ai/myGenerate')">
        <img src="../../assets/imgs/aiLab/icon/Magicpencil.png" alt="" />
        <span>我的生成</span>
      </div>
      <div>
        <div class="top_banner" @click="$router.push(`/ai/facePhoto?type=photo`)">
          <!-- <EADSwiper :imgCDN="imgCDN" :list="commityAD" keyName="cover" @clickImg="clickImg" /> -->

          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#ff0000">
            <van-swipe-item>
              <img src="../../assets/imgs/aiLab/swiper/bg_ai_image1.png" alt="" />
            </van-swipe-item>
            <van-swipe-item>
              <img src="../../assets/imgs/aiLab/swiper/bg_ai_image2.png" alt="" />
            </van-swipe-item>
            <van-swipe-item>
              <img src="../../assets/imgs/aiLab/swiper/bg_ai_image3.png" alt="" />
            </van-swipe-item>
          </van-swipe>
          <div class="bottom">
            <div class="title">
              <div>
                <img src="../../assets/imgs/aiLab/frame1.png" alt="" />
                <span>AI换脸</span>
                <span>(图片)</span>
              </div>
              <img src="../../assets/imgs/aiLab/warning.svg" @click.stop="showModal1 = !showModal1" alt="" />
            </div>
            <!-- <div class="content">我是说明我是说明我是说明我是说明我是说明我是说明我是说明我是说明</div> -->
          </div>
        </div>
        <div class="middle-not-banner top_banner" @click="$router.push('/ai/facePhoto?type=video')">
          <video
            class="profile_bg"
            webkit-playsinline="true"
            playsinline="true"
            src="@/assets/imgs/aiLab/shili.mp4"
            muted
            autoplay
            loop
          ></video>
          <div class="bottom">
            <div class="title">
              <div>
                <img src="../../assets/imgs/aiLab/frame2.svg" alt="" />
                <span>AI换脸</span>
                <span id="yellow">(视频)</span>
              </div>
              <img src="../../assets/imgs/aiLab/warning.svg" @click.stop="showModal1 = !showModal1" alt="" />
            </div>
            <!-- <div class="content">我是说明我是说明我是说明我是说明我是说明我是说明我是说明我是说明</div> -->
          </div>
          <!-- <img src="@/assets/imgs/aiLab/shili.png" alt="" /> -->
        </div>
        <div class="top_banner" @click="$router.push('/ai/uploadFace')">
          <!-- <EADSwiper :imgCDN="imgCDN" :list="commityAD" keyName="cover" @clickImg="clickImg" /> -->
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#ff0000">
            <van-swipe-item>
              <img src="../../assets/imgs/aiLab/swiper/bg_ai_strip1.png" alt="" />
            </van-swipe-item>
            <van-swipe-item>
              <img src="../../assets/imgs/aiLab/swiper/bg_ai_strip2.png" alt="" />
            </van-swipe-item>
            <van-swipe-item>
              <img src="../../assets/imgs/aiLab/swiper/bg_ai_strip3.png" alt="" />
            </van-swipe-item>
            <van-swipe-item>
              <img src="../../assets/imgs/aiLab/swiper/bg_ai_strip4.png" alt="" />
            </van-swipe-item>
          </van-swipe>
          <div class="bottom">
            <div class="title">
              <div>
                <img src="../../assets/imgs/aiLab/frame3.png" alt="" />
                <span>AI脱衣</span>
                <span></span>
              </div>
              <img src="../../assets/imgs/aiLab/warning.svg" @click.stop="showModal2 = !showModal2" alt="" />
            </div>
            <!-- <div class="content">我是说明我是说明我是说明我是说明我是说明我是说明我是说明我是说明</div> -->
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showModal1" position="bottom" class="error-tips-pop1">
      <div class="up-material">
        <div>素材规范</div>
        <div>
          <img src="@/assets/imgs/aiLab/error_tips.svg" alt="" />
          <span style="color: #fff">
            上传素材需要为正脸,且无遮挡,无表情最好, <span style="color: red">严禁使用未成年素材</span>
          </span>
        </div>
        <ul class="errorTipsList">
          <li v-for="(item, index) in errorTipsList1" :key="index">
            <img :src="item.img" alt="" />
            <img :src="item.icon" alt="" />
            <div>{{ item.text }}</div>
            <!-- <div v-if="index === 0" style="color: #E8619D">(比基尼最佳)</div> -->
          </li>
        </ul>
      </div>
    </van-popup>
    <van-popup v-model="showModal2" position="bottom" class="error-tips-pop2">
      <div class="up-material">
        <div>素材规范</div>
        <div>
          <img src="@/assets/imgs/aiLab/error_tips.svg" alt="" />
          <span style="color: #fff">
            上传素材需要为正脸,素材清晰, <span style="color: red">严禁使用未成年素材</span>
          </span>
        </div>
        <ul class="errorTipsList">
          <li v-for="(item, index) in errorTipsList2" :key="index">
            <img :src="item.img" alt="" />
            <img :src="item.icon" alt="" />
            <div>{{ item.text }}</div>
            <div v-if="index === 0" style="color: #e8619d">(比基尼最佳)</div>
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'generate',
  data() {
    return {
      showModal1: false,
      showModal2: false,
      errorTipsList1: [
        {
          img: require('@/assets/imgs/aiLab/error1.png'),
          icon: require('@/assets/imgs/aiLab/corret_icon.svg'),
          text: '正朝向,素材清晰,无遮挡'
        },
        {
          img: require('@/assets/imgs/aiLab/error2.png'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '素材清晰,但是不是正脸'
        },
        {
          img: require('@/assets/imgs/aiLab/error3.png'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '像素太过模糊'
        },
        {
          img: require('@/assets/imgs/aiLab/error4.png'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '脸部被遮挡'
        },
        {
          img: require('@/assets/imgs/aiLab/error5.png'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '表情过于浮夸'
        },
        {
          img: require('@/assets/imgs/aiLab/error6.png'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '多人'
        }
      ],
      errorTipsList2: [
        {
          img: require('@/assets/imgs/aiLab/section3/error1.jpg'),
          icon: require('@/assets/imgs/aiLab/corret_icon.svg'),
          text: '正朝向,素材清晰'
        },
        {
          img: require('@/assets/imgs/aiLab/section3/error2.jpg'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '包裹的过于严实'
        },
        {
          img: require('@/assets/imgs/aiLab/section3/error3.jpg'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '像素太过模糊'
        },
        {
          img: require('@/assets/imgs/aiLab/section3/error4.jpg'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '多人'
        }
      ]
    }
  },
  methods: {
    leftArrowBack() {
      this.$router.go(-1)
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取轮播广告
    commityAD({ $store }) {
      return $store.getters['commityAD']
    }
  }
}
</script>

<style lang="scss" scoped>
.ai-generate {
  background: url('../../assets/imgs/aiLab/generate_bg.png') top center no-repeat;
  background-size: 100% 6.95rem;
  padding-top: 2rem;
  > div {
    &:nth-child(1) {
      width: 4.48rem;
      height: 0.9rem;
      border-radius: 0.64rem;
      // background: #d73c44;
      background: $gradientBg;
      color: #fff;
      font-family: PingFang SC;
      font-size: 0.3rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @include flex-center;
      margin: 0 auto 0.64rem;

      > img {
        width: 0.32rem;
        height: 0.32rem;
        margin-right: 0.16rem;
      }
    }
    &:nth-child(2) {
      padding: 0 0.36rem 0.7rem;
      // background: url('../../assets/imgs/mine/mine_top_bg.png') repeat-y;
    }
  }
  .top_banner {
    :deep() .swiper-slide {
      img {
        border-top-left-radius: 0.16rem;
        border-top-right-radius: 0.16rem;
      }
    }
    .bottom {
      padding: 0.42rem 0.32rem 0.41rem 0.32rem;
      border-radius: 0rem 0rem 0.16rem 0.16rem;
      // background: #111;
      background: rgba(0, 0, 0, 0.04);
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.16rem;
      // border-bottom: 1px solid #333;

      > img {
        width: 0.36rem;
        height: 0.36rem;
      }
      > div {
        @include flex-center;
        span {
          &:nth-of-type(1) {
            color: #333;
            font-family: PingFang SC;
            font-size: 0.34rem;
            font-style: normal;
            font-weight: 500;
          }
          &:nth-of-type(2) {
            color: #d73c44;
            font-family: PingFang SC;
            font-size: 0.26rem;
            font-style: normal;
            font-weight: 400;
            margin: 0 0.16rem;
          }
          &:nth-of-type(3) {
            padding: 0.02rem 0.12rem;
            border-radius: 0.32rem;
            border: 1px solid #8d54d6;
            color: #8d54d6;
            font-family: PingFang SC;
            font-size: 0.24rem;
            font-style: normal;
            font-weight: 400;
            line-height: 0.3rem; /* 125% */
          }
        }
        > img {
          width: 0.3rem;
          height: 0.3rem;
          margin-right: 0.15rem;
        }
      }
    }
    .content {
      padding-top: 0.16rem;
      color: rgba(255, 255, 255, 0.3);
      font-family: PingFang SC;
      font-size: 0.24rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.36rem; /* 150% */
    }
  }
  .middle-not-banner {
    margin: 0.6rem 0;
    > video {
      width: 100%;
      border-top-left-radius: 0.16rem;
      border-top-right-radius: 0.16rem;
    }
    #yellow {
      color: #e8619d;
      font-family: PingFang SC;
      font-size: 0.26rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.525rem;
    }
  }
  .my-swipe {
    :deep() img {
      width: 100%;
      border-radius: 0.16rem 0.16rem 0 0;
    }
  }
}
.error-tips-pop1 {
  padding: 0.6rem 0.36rem;
  border-radius: 0.42rem 0.42rem 0rem 0rem;
  background: #fff;
  .up-material {
    display: block;
    > div {
      &:nth-child(1) {
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.34rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include flex-align-center;
        &::before {
          content: '';
          width: 0.12rem;
          height: 0.25rem;
          background-color: #d73c44;
          display: block;
          border-radius: 0.12rem;
          margin-right: 0.16rem;
        }
      }
      &:nth-child(2) {
        margin: 0.34rem 0 0.47rem;
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        > img {
          width: 0.28rem;
          margin-right: 0.09rem;
        }
      }
    }
  }

  .errorTipsList {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.15rem;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1.8rem;
      margin-right: 0.51rem;
      text-align: center;
      color: #fff;
      text-align: center;
      font-size: 0.24rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0.34rem;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    img {
      &:nth-of-type(1) {
        height: 1.9rem;
        border-radius: 0.12rem;
      }
      &:nth-of-type(2) {
        width: 0.32rem;
        height: 0.32rem;
        margin: 0.15rem 0 0.06rem;
      }
    }
  }
}
.error-tips-pop2 {
  padding: 0.6rem 0.36rem;
  border-radius: 0.42rem 0.42rem 0rem 0rem;
  border: 1px solid #535353;
  background: #292929;
  .up-material {
    display: block;
    > div {
      &:nth-child(1) {
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.34rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include flex-align-center;
        &::before {
          content: '';
          width: 0.12rem;
          height: 0.25rem;
          background-color: #d73c44;
          display: block;
          border-radius: 0.12rem;
          margin-right: 0.16rem;
        }
      }
      &:nth-child(2) {
        margin: 0.34rem 0 0.47rem;
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        > img {
          width: 0.28rem;
          margin-right: 0.09rem;
        }
      }
    }
  }
  .errorTipsList {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.15rem;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1.8rem;
      margin-right: 0.51rem;
      text-align: center;
      color: #fff;
      text-align: center;
      font-size: 0.24rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0.34rem;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    img {
      &:nth-of-type(1) {
        width: 1.8rem;
        height: 2.2rem;
        border-radius: 0.12rem;
      }
      &:nth-of-type(2) {
        width: 0.32rem;
        height: 0.32rem;
        margin: 0.15rem 0 0.06rem;
      }
    }
  }
}
</style>
