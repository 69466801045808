<template>
  <div class="search-index">
    <div class="topTab">
      <van-icon name="arrow-left" size="25" @click="$router.go('-1')" />
      <div class="search-box">
        <img class="search-img" src="@/assets/imgs/search/search.png" />
        <input v-model="value" class="input" placeholder="搜片名、女优、番号" />
      </div>
      <span class="search-btn" @click="searchBtn">搜索</span>
    </div>

    <div class="search-main">
      <!-- 历史记录 -->
      <ul v-if="historyList.length > 0" class="history">
        <li class="search-title">
          <p>历史记录</p>
          <img src="@/assets/imgs/search/clear.png" alt @click="clear([], 'delAll')" />
        </li>
        <li class="history-item">
          <ul>
            <li v-for="(item, index) in historyList" :key="item">
              <span @click="toSearchType(item)">{{ item }}</span>
              &nbsp; | &nbsp;
              <img src="@/assets/imgs/search/item-clear.png" alt @click.stop="clear(index, 'del')" />
            </li>
          </ul>
        </li>
      </ul>
      <!-- 搜索广告 -->
      <!-- <div v-if="topicAD.length" class="search-ad">
        <EADSwiper :list="topicAD" keyName="cover" :EADconfig="EADconfig" @clickImg="activityBtn" />
      </div> -->

      <!-- 热门标签 -->
      <div class="hot_main">
        <div class="title">热门标签</div>
        <ul class="hot_list">
          <li v-for="(item, index) in hotTagList" :key="index" @click="toSearchType(item.name)">
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="tab_wrap" :class="isXiDing ? 'xiDing' : ''">
      <ul>
        <li
          :class="item.id === tabIndex ? 'active' : ''"
          v-for="item in tabData"
          :key="item.id"
          @click="handelTab(item)"
        >
          <img class="imgIcon" v-if="item.id === tabIndex" :src="require('@/assets/imgs/search/huo.png')" />
          <img class="imgFont" v-if="item.id === tabIndex" :src="item.font" />
          <img class="imgFont" v-else :src="item.showFont" />
        </li>
      </ul>
    </div>
    <ERefreshLoadV3
      ref="ERefreshLoadV3"
      :autoOnload="false"
      :beforeRefresh="onRefresh"
      :moreData="onLoad"
      :finished="finished"
      class="more-scroll"
    >
      <component :is="'VideoList2'" :list="mediaList"></component>
    </ERefreshLoadV3>
  </div>
</template>
<script>
import { media_search_home, hot_tag_list } from 'api/search'
// import { JumpTo } from '@/utils/utils_tools'
export default {
  name: 'SearchIndex',
  components: {
    VideoList2: () => import('@/components/Home/listType/videoList/videoList2.vue')
  },
  data() {
    return {
      isXiDing: false,
      finished: false,
      pageNum: 1,
      pageSize: 10,
      value: '',
      hotTagList: [],
      mediaList: [],
      tabIndex: 0,
      tabData: [
        {
          id: 0,
          showFont: require('@/assets/imgs/search/day_hot_show.png'),
          font: require('@/assets/imgs/search/day_hot.png')
        },
        {
          id: 1,
          showFont: require('@/assets/imgs/search/week_hot_show.png'),
          font: require('@/assets/imgs/search/week_hot.png')
        },
        {
          id: 2,
          showFont: require('@/assets/imgs/search/moth_hot_show.png'),
          font: require('@/assets/imgs/search/moth_hot.png')
        }
      ],
      tabDataActive: {}
    }
  },
  computed: {
    historyList({ $store }) {
      if (JSON.stringify($store.getters['getSaerchlist'])) {
        const arr = JSON.parse(JSON.stringify($store.getters['getSaerchlist'].reverse())).splice(0, 8)
        return arr
      }
    },
    // 获取首页底部轮播广告
    topicAD({ $store }) {
      return $store.getters['topicAD']
    }
  },
  mounted() {
    this.hotTagListFn()
    this.mediaSearchHome()
    window.addEventListener('scroll', this.scrollEvent, true)
  },
  destoryed() {
    window.removeEventListener('scroll', this.scrollEvent, true)
  },
  methods: {
    scrollEvent() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 300) {
        this.isXiDing = true
      } else {
        this.isXiDing = false
      }
    },
    onRefresh() {
      this.pageNum = 0
      this.mediaList = []
    },
    async onLoad() {
      this.pageNum++
      await this.mediaSearchHome()
    },
    handelTab(item) {
      this.tabIndex = item.id
      this.$refs.ERefreshLoadV3.onRefresh()
    },
    // 活动广告跳转
    // activityBtn(href) {
    //   JumpTo(href)
    // },
    // 清空历史记录
    claerHistory() {
      this.$store.dispatch('setSaerchlist', {
        type: 'remove',
        value: []
      })
    },
    // 搜索按钮
    searchBtn() {
      if (this.value) {
        this.$store.dispatch('setSaerchlist', {
          type: 'add',
          value: this.value
        })
        this.$router.push(`/search/Acg/result/${this.value}`)
        this.value = null
      } else {
        return this.$toast('请输入您想搜索的内容')
      }
    },
    // 跳转搜索结果
    toSearchType(content) {
      if (content) {
        this.$store.dispatch('setSaerchlist', {
          type: 'add',
          value: content
        })
        this.$router.push(`/search/Acg/result/${content}`)
        content = null
      }
    },
    // 跳转排行榜
    toRank() {
      this.$router.push(`/rank`)
    },
    // 清除历史记录
    clear(index, type) {
      this.$store.dispatch('setSaerchlist', {
        type: type,
        value: index
      })
    },
    // 强力推荐
    // async getHotList2() {
    //   // type 1:首页/2:acg
    //   const res = await search_hot2({ type: 2 })
    //   if (res.code === 200) {
    //     this.hotList = res.data.list
    //     this.avMediaList = res.data.avMediaList
    //     this.shortMediaList = res.data.shortMediaList
    //   } else {
    //     return this.$toast('请求失败，请稍后再试')
    //   }
    // },
    // // 强力推荐换一换
    // async getHotChangeOne2() {
    //   const res = await hot_changeOne({ type: this.tabId })
    //   if (res.code === 200) {
    //     if (this.tabId == 1) {
    //       this.avMediaList = res.data.avMediaList
    //     } else {
    //       this.shortMediaList = res.data.shortMediaList
    //     }
    //   } else {
    //     return this.$toast('请求失败，请稍后再试')
    //   }
    // }
    async hotTagListFn() {
      await hot_tag_list({ location: 0 }).then(res => {
        if (res.code === 200) {
          this.hotTagList = res.data.tagList.slice(0, 10)
        } else {
          return this.$toast(res.tip)
        }
      })
    },
    async mediaSearchHome() {
      const res = await media_search_home({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sortType: this.tabIndex,
        type: 3
      })
      if (res.code === 200) {
        if (res.data.list && res.data.list.length) {
          this.mediaList = [...this.mediaList, ...res.data.list]
          if (res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      } else {
        this.finished = true
        return this.$toast(res.tip)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-index {
  padding: 0.36rem;
}
.topTab {
  @include flexbox();
  font-size: 0.28rem;

  .search-box {
    display: flex;
    align-items: center;
    border-radius: 0.08rem;
    background: rgba(153, 153, 153, 0.1);
    padding: 0.1rem 0.2rem;
    width: 5rem;

    .bank {
      display: flex;
      align-items: center;
      font-size: 0.24rem;
      font-weight: 400;
      border-radius: 0.08rem;
      background: #ff75b3;
      padding: 0.05rem 0.1rem;
      color: #fff;
      margin-right: 0.1rem;
      img {
        width: 0.24rem;
        margin-right: 0.05rem;
      }
    }
    .search-img {
      width: 0.32rem;
      padding-left: 0.1rem;
      // border-left: 1px solid #999;
    }
    .input {
      width: 100%;
      background-color: transparent;
      font-size: 0.24rem;
      margin-left: 0.1rem;
    }
  }

  .search-btn {
    color: #fff;
    border-radius: 0.08rem;
    background: linear-gradient(101deg, #ffea7a 3.23%, #ff74b2 47.25%, #ff74b2 84.38%, #ffea7a 102.17%);
    padding: 0.08rem 0.24rem;
  }
}
// 记录
.history {
  font-size: 0.22rem;
  width: 100%;

  margin-top: 0.55rem;
  overflow: hidden;

  .search-title {
    @include flexbox();
    font-size: 0.32rem;
    font-weight: 500;
    img {
      width: 0.27rem;
      height: 0.3rem;
    }
  }

  .history-item {
    overflow: auto;
    @include scrollbar-hide;

    ul {
      width: 100%;
      padding: 0;
      height: 100%;
      @include flexbox($jc: flex-start, $fw: wrap);

      li {
        @include flexbox();
        padding: 0.08rem 0.16rem;
        margin-top: 0.24rem;
        color: #666666;
        font-size: 0.28rem;
        border-radius: 0.08rem;
        background: rgba(153, 153, 153, 0.1);
        margin-right: 0.32rem;

        span {
          max-width: 2rem;
          @include textoverflow();
        }
        img {
          width: 0.28rem;
          height: 0.28rem;
        }
      }
    }
  }
}
.hot_main {
  font-size: 0.24rem;
  margin-top: 0.3rem;
  .title {
    font-size: 0.32rem;
    font-weight: 500;
    @include flexbox($jc: flex-start);
    padding-bottom: 0.24rem;

    border-bottom: 0.01rem solid rgba(255, 255, 255, 0.2);
    img {
      width: 0.24rem;
      height: 0.28rem;
      margin-right: 0.12rem;
    }
  }
  .hot_list {
    display: flex;
    flex-wrap: wrap;
    max-height: 3rem;
    li {
      font-size: 0.28rem;
      border-radius: 0.08rem;
      background: rgba(51, 51, 51, 0.05);
      padding: 0.08rem 0.24rem;
      margin: 0 0.2rem 0.2rem 0;
    }
  }
}
.tab_wrap {
  margin: 0.2rem 0 0.4rem;
  ul {
    display: flex;
    justify-content: space-between;
    li {
      width: 1.86rem;
      height: 0.6rem;
      border-radius: 0.08rem;
      background: rgba(255, 0, 92, 0.05);
      display: flex;
      justify-content: center;
      align-items: center;
      .imgIcon {
        width: 0.28rem;
        height: 0.28rem;
        margin-right: 0.05rem;
      }
      .imgFont {
        width: 1.12rem;
        height: 0.3rem;
      }
    }
    .active {
      background: linear-gradient(101deg, #ffea7a 3.16%, #ff74b2 65.51%, #ff74b2 93.52%);
    }
  }
}
.xiDing {
  position: fixed;
  top: 0;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 9999;
  padding: 0.2rem 0.38rem;
  margin: 0 !important;
}
// .search-ad {
//   width: 100%;
//   height: 1.5rem;
//   :deep() {
//     img {
//       border-radius: 0.08rem;
//     }
//   }
// }
</style>
