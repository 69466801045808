<template>
  <div class="header-content">
    <img src="@/assets/imgs/community/myInfoBg.jpg" alt="" class="labelBg" />
    <div class="labelData">
      <div class="label-top">
        <div class="labelAvatar">
          <EDecryptImg :img-u-r-l="publisher.avatar" class="item-bg"></EDecryptImg>
        </div>
        <div class="center">
          <div class="user_name">
            <span class="nick_name">{{ publisher.name }}</span>
          </div>
          <div class="percent-box">
            <img
              :src="
                publisher.isLike ? require('@/assets/imgs/play/like-a.png') : require('@/assets/imgs/play/like.png')
              "
              alt=""
              class="video-opr-up"
              @click="checkLike(publisher, 2)"
            />
            <div class="percent">
              <progressbar :value="percent" class="progressbar" />
              <div class="percent-label">好评率{{ percent + '%' }}</div>
            </div>
            <img
              :src="
                publisher.isDisLike
                  ? require('@/assets/imgs/play/unlike-a.png')
                  : require('@/assets/imgs/play/unlike.png')
              "
              alt=""
              class="video-opr-down"
              @click="checkLike(publisher, 3)"
            />
          </div>
        </div>
        <div :class="publisher.isCare ? 'isFollow' : 'follow'" @click="toFollow">
          {{ publisher.isCare ? '已关注' : '+关注' }}
        </div>
      </div>
      <div class="label-middle">
        <div>国家：{{ publisher.country }}</div>
        <div>身高：{{ publisher.height }}</div>
        <div>生日：{{ publisher.birthday }}</div>
        <div>罩杯：{{ publisher.cup }}</div>
        <div>影片数量：{{ publisher.movieCount }}</div>
        <div>
          三围：<span style="color: #ff996d">{{ publisher.bwh }}</span>
        </div>
      </div>
      <div class="label-bottom">
        <div class="label-bottom-content" :class="{ isFold }">
          {{ publisher.desc || '暂无简介～' }}
        </div>
        <div class="btn" :class="{ isFold }" @click="isFold = !isFold"></div>
      </div>
      <div class="label-ans-wrap">
        <div class="label-ans">
          <div class="label-ans-item">
            <div class="label-ans-count">{{ publisher.visitorCount | numberFilter }}</div>
            <div class="label-ans-label">访客</div>
          </div>
          <div class="divider"></div>
          <div class="label-ans-item">
            <div class="label-ans-count">{{ publisher.likes | numberFilter }}</div>
            <div class="label-ans-label">点赞</div>
          </div>
          <div class="divider"></div>
          <div class="label-ans-item">
            <div class="label-ans-count">{{ publisher.fans | numberFilter }}</div>
            <div class="label-ans-label">粉丝</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { care_add, collect, media_actor_info } from 'api/home'

export default {
  name: 'ActorInfo',
  components: {
    Progressbar: () => import('@/components/Progressbar.vue')
  },
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      publisher: {},
      isFold: true
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    percent() {
      if (!this.publisher.likes && !this.publisher.disLikes) {
        return 100
      }
      return ((this.publisher.likes / (this.publisher.disLikes + this.publisher.likes)) * 100).toFixed(0)
    }
  },
  created() {
    this.getActorDetail()
  },
  activated() {
    this.getActorDetail()
  },
  beforeDestroy() {},
  methods: {
    // 关注
    async toFollow() {
      // if (this.userInfo.loginType == 0) {
      //   return this.$toast('仅注册的用户才能关注')
      // }
      // 关注用户
      try {
        const res = await care_add({
          id: +this.id,
          add: !this.publisher.isCare,
          userType: 1
        })
        if (res.code === 200) {
          this.publisher.isCare = !this.publisher.isCare
          if (this.publisher.isCare) {
            return this.$toast('关注成功')
          } else {
            return this.$toast('取消关注')
          }
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试')
      }
    },
    // 请求女优详情
    async getActorDetail() {
      try {
        const res = await media_actor_info({
          id: +this.id
        })
        if (res.code === 200) {
          this.publisher = res.data.mediaActorInfo
          this.$emit('postData', this.publisher)
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 视频收藏接口
    async checkLike(item, type) {
      const res = await collect({
        collectType: 7,
        flag: type == 2 ? !item.isLike : !item.isDisLike,
        object_id: item.id,
        type: type
        // type: type //1:收藏 2:点赞 3:踩
      })
      if (res.code === 200) {
        const typeMap = {
          1: { property: 'isCollect', counter: 'collects', successMsg: '收藏成功', failMsg: '收藏取消' },
          2: { property: 'isLike', counter: 'likes', successMsg: '点赞成功', failMsg: '点赞取消' },
          3: { property: 'isDisLike', counter: 'disLikes', successMsg: '踩踏成功', failMsg: '踩踏取消' }
        }
        if (typeMap.hasOwnProperty(type)) {
          const { property, counter, successMsg, failMsg } = typeMap[type]
          item[property] = !item[property]
          if (item[property]) {
            item[counter] += 1
            return this.$toast(successMsg)
          } else {
            item[counter] -= 1
            return this.$toast(failMsg)
          }
        }
      } else {
        return this.$toast(res.tip)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.header-content {
  position: relative;
  height: 6.92rem;
  width: 100%;
  max-width: $pcMaxWidth;

  .labelBg {
    -webkit-filter: brightness(35%);
    /*考虑浏览器兼容性：兼容 Chrome, Safari, Opera */
    filter: brightness(35%);
    width: 100%;
    height: 6.28rem;
  }

  .labelData {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    padding: 1rem 0 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    display: flex;
    flex-direction: column;

    .label-top {
      padding: 0 0.32rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .labelAvatar {
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
        overflow: hidden;
      }

      .center {
        margin-left: 0.36rem;
        flex-grow: 1;
        .user_name {
          flex-grow: 1;
          .nick_name {
            font-weight: 500;
            font-size: 0.32rem;
            line-height: 0.44rem;
            color: #ffffff;
          }
        }
        .percent-box {
          margin-top: 0.36rem;
          @include flexbox($jc: flex-start, $ai: center);
          color: #ffffff;
          .percent {
            margin: 0 0.1rem;
            .progressbar {
            }
            .percent-label {
              margin-top: 0.04rem;
              font-size: 0.18rem;
              line-height: 0.26rem;
            }
          }
          > img {
            margin-top: -0.1rem;
            width: 0.4rem;
            height: 0.4rem;
          }
        }
      }

      .follow {
        @include flex-center;
        border-radius: 0.24rem;
        border: 0.02rem solid #e8619d;
        color: $btnBg;
        width: 1.2rem;
        height: 0.48rem;
      }
      .isFollow {
        border-radius: 1.04rem;
        background: #292929;
        padding: 0.06rem 0.24rem;
        color: $btnBg;
        margin-top: 0.24rem;
      }
    }
    .label-middle {
      margin: 0.24rem 0;
      padding: 0 0.32rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      color: #999999;
      line-height: 0.34rem;
      > div {
        margin: 0.08rem 0;
      }
      > :nth-child(1),
      > :nth-child(4) {
        width: 30%;
      }
      > :nth-child(2),
      > :nth-child(5) {
        width: 30%;
      }
      > :nth-child(3),
      > :nth-child(6) {
        width: 40%;
      }
    }
    .label-bottom {
      position: relative;
      margin: 0 0.32rem;
      padding: 0.16rem 0.64rem 0.16rem 0.16rem;
      color: #666666;
      border: 0.01rem solid #ffffff1a;
      border-radius: 0.06rem;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
        linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));

      .label-bottom-content {
        max-height: 2rem;
        overflow: auto !important;
        font-weight: 400;
        font-size: 0.24rem;
        line-height: 0.34rem;
        @include textoverflow(5);
        &.isFold {
          @include textoverflow(1);
        }
      }
      .btn {
        position: absolute;
        right: 0.16rem;
        top: 0.16rem;
        background: url('~@/assets/imgs/unfold.png') center center/100% 100% no-repeat;
        width: 0.32rem;
        height: 0.28rem;
        &.isFold {
          background: url('~@/assets/imgs/fold.png') center center/100% 100% no-repeat;
        }
      }
    }
    .label-ans-wrap {
      margin: 0.4rem 0;
      padding: 0.14re 0 0;
      flex-grow: 1;
      background-color: #000000;

      .label-ans {
        @include flex-center;
        color: #ffffff;
        height: 0.9rem;
        text-align: center;
        .label-ans-item {
          .label-ans-count {
            font-weight: 500;
            font-size: 0.32rem;
            line-height: 0.44rem;
          }
          .label-ans-label {
            margin-top: 0.12rem;
            font-size: 0.24rem;
            line-height: 0.34rem;
            opacity: 0.6;
          }
        }
        .divider {
          margin: 0 0.88rem;
          flex-shrink: 0;
          width: 0.02rem;
          height: 0.4rem;
          opacity: 0.2;
          background-color: #ffffff;
        }
      }
    }
  }
}
</style>
