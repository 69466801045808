<template>
  <div>
    <EBackTop class="header" :title="'账号凭证'"> </EBackTop>
    <div ref="codeBg" class="code_pop_bg">
      <div class="account-img">
        <div class="code">
          <div class="code_bg">
            <EGenerateImg
              ref="EGenerateImg"
              :link="qrCode"
              :name="`来自${info.nickName}的个人凭证.png`"
              :config="config"
            >
              <!-- <div class="code-bg"></div> -->
            </EGenerateImg>
          </div>
        </div>
        <div class="account_info">
          <p class="code_title">账号凭证</p>
          <p>
            Mr.91撸色ID: <span>{{ info.id }}</span>
          </p>
          <p>我的-账号凭证</p>
          <p class="desc van-ellipsis">永久官网地址 {{ ldyCdn }}</p>
        </div>
      </div>
    </div>
    <div class="bottom-btn" @click="clickSaveImg">保存账号凭证</div>
  </div>
</template>

<script>
import { userQrcode, userIsCert } from 'api/user'
// import html2canvas from 'html2canvas'

export default {
  data() {
    return {
      web: '',
      app: '',
      qrCode: '',
      backup: '',
      config: {
        qrSize: 160
      }
    }
  },
  computed: {
    // 落地页
    ldyCdn({ $store }) {
      return $store.getters['ldyCdn']
    },
    info({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  async mounted() {
    await this.getUerQrcode()
  },
  methods: {
    async getUerQrcode() {
      const res = await userQrcode()
      if (res && res.code === 200) {
        this.web = res.data.web
        this.app = res.data.app
        this.qrCode = res.data.value
        this.backup = res.data.backup
      }
    },
    clickSaveImg() {
      this.$toast('请截图保存！')
      // userIsCert({
      //   isSave: true
      // }).then(res => {
      //   if (res.code === 200) {
      //     html2canvas(this.$refs.codeBg, {
      //       dpi: 300,
      //       scale: 2,
      //       useCORS: true // 解决文件跨域问题
      //     }).then(canvas => {
      //       canvas.toBlob(
      //         blob => {
      //           const url = URL.createObjectURL(blob)
      //           // 可以上传后端或者直接显示
      //           this.download(url)
      //         },
      //         'image/jpeg',
      //         0
      //       )
      //       this.$toast('保存成功')
      //     })
      //   } else {
      //     this.$toast('保存失败，请联系在线客服！')
      //   }
      // })
    },
    download(href) {
      const eleLink = document.createElement('a')
      eleLink.href = href
      eleLink.setAttribute('download', `来自${this.info.nickName}的个人凭证`)
      document.body.appendChild(eleLink)
      eleLink.click()
      document.body.removeChild(eleLink)
      this.$store.commit('isSaveImg', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.code_pop_bg {
  background-image: url('../../../assets/imgs/mine/mine-acc-code.png');
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0.32rem;
  height: 11.42rem;
  padding-bottom: 0.64rem;
  text-align: center;
  border-radius: 0.32rem;

  .title {
    width: 2rem;
  }

  .tip {
    margin: 0.52rem 0 0.36rem 0;
    font-size: 0.24rem;
    text-align: center;

    span {
      color: $btnBg;
    }
  }
}

.account-img {
  padding: 4.7rem 0.36rem 0 0.36rem;

  flex-shrink: 0;
  border-radius: 0.2rem;

  .code {
    border-radius: 0.16rem;
    background: url('../../../assets/imgs/mine/accCode-bg.png') no-repeat;
    background-size: 100% 100%;
    width: 4rem;
    height: 4rem;
    flex-shrink: 0;
    @include flexbox($jc: center);
    margin: 0 auto 0.32rem auto;
    padding: 0.14rem 0.2rem;
    .code_bg {
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 0.15rem;
      padding: 0.2rem;
      position: relative;
      :deep() {
        .template {
          position: static;
        }
        .qrBg {
          width: 3.48rem !important;
          height: 3.48rem !important;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.account_info {
  .code_title {
    font-weight: 600;
    font-size: 0.36rem;
  }

  span {
    color: $txtActive;
  }

  p {
    margin-bottom: 0.12rem;
  }
}

.bottom-btn {
  border-radius: 0.42rem;
  background: $gradientBg;
  width: 5.68rem;
  height: 0.88rem;
  color: #fff;
  @include flexbox($jc: center);
  margin: 0.52rem auto 0 auto;
}
</style>
