<template>
  <li class="item">
    <div>{{ showTitile }}排行榜-日榜</div>
    <ul>
      <li v-for="(item, idx) in list" :key="item.id" @click="clickRank(item)">
        <template v-if="index == 0">
          <div class="left">
            <!-- <img class="img" src="@/assets/imgs/temp/hk_doll.png" alt="" /> -->
            <EDecryptImg :imgCDN="imgCDN" class="img" :imgURL="item.coverImg" />
            <div class="rank">
              <img v-if="idx == 0" class="rank-img" src="@/assets/imgs/discover/ranking1.png" alt="" />
              <img v-else-if="idx == 1" class="rank-img" src="@/assets/imgs/discover/ranking2.png" alt="" />
              <img v-else class="rank-img" src="@/assets/imgs/discover/ranking3.png" alt="" />
            </div>
            <div class="bottom">
              <div>
                <img class="rank-img" src="@/assets/imgs/watch_count.png" alt="" />
                <span>{{ item.watchTimes | numberFilter }}</span>
              </div>
              <span>{{ item.playTime | secondToDate }}</span>
            </div>
          </div>
          <div class="right">
            <div>{{ item.title }}</div>
            <div>
              <span>{{ item.addedTime.split('T')[0] }}</span>
              <span>点赞量:{{ item.likes | numberFilter }}</span>
            </div>
          </div>
        </template>
        <div v-else-if="index == 2" class="not-media">
          <EDecryptImg :imgCDN="imgCDN" class="mine_top_bg" :imgURL="item.node.imgs[0]">
            <div class="rank">
              <img v-if="idx == 0" class="rank-img" src="@/assets/imgs/discover/ranking1.png" alt="" />
              <img v-else-if="idx == 1" class="rank-img" src="@/assets/imgs/discover/ranking2.png" alt="" />
              <img v-else class="rank-img" src="@/assets/imgs/discover/ranking3.png" alt="" />
            </div>
          </EDecryptImg>
          <div class="hot-item-post">
            <div>{{ item.base.title }}</div>
            <div class="tags">{{ item.base.postSection }}</div>
            <div class="hot-item-post-right">
              <span v-if="item.base.addedTime">{{ item.base.addedTime.split('T')[0] }}</span>
              <span v-else>{{ item.base.createdAt.split('T')[0] }}</span>
              <span>点赞量:{{ item.base.likes | numberFilter }}</span>
            </div>
          </div>
        </div>
        <div v-else-if="index == 4" class="not-media">
          <EDecryptImg :imgCDN="imgCDN" class="mine_top_bg" :imgURL="item.avatar">
            <div class="rank">
              <img v-if="idx == 0" class="rank-img" src="@/assets/imgs/discover/ranking1.png" alt="" />
              <img v-else-if="idx == 1" class="rank-img" src="@/assets/imgs/discover/ranking2.png" alt="" />
              <img v-else class="rank-img" src="@/assets/imgs/discover/ranking3.png" alt="" />
            </div>
          </EDecryptImg>
          <div class="hot-item-post">
            <div>{{ item.name }}</div>
            <div class="tags">
              <span>视频:{{ item.movieCount }}</span
              >| <span>图片:{{ item.photoCount }}</span
              >| <span>点赞:{{ item.likeCount }}</span>
            </div>
          </div>
        </div>
        <div v-else class="not-media">
          <EDecryptImg :imgCDN="imgCDN" class="mine_top_bg" :imgURL="item.cover">
            <div class="photo-yellow">
              <img src="@/assets/imgs/discover/photo-yellow.png" alt="" />
              <span>图片</span>
            </div>
            <div class="photo-count">{{ item.total }}部</div>
            <div class="rank">
              <img v-if="idx == 0" class="rank-img" src="@/assets/imgs/discover/ranking1.png" alt="" />
              <img v-else-if="idx == 1" class="rank-img" src="@/assets/imgs/discover/ranking2.png" alt="" />
              <img v-else class="rank-img" src="@/assets/imgs/discover/ranking3.png" alt="" />
            </div>
          </EDecryptImg>
          <div class="hot-item-post">
            <div>{{ item.name }}</div>
            <div class="tags">{{ item.title }}</div>
            <div class="hot-item-post-right">
              <span v-if="item.addedTime">{{ item.addedTime.split('T')[0] }}</span>
              <span v-else>{{ item.createdAt.split('T')[0] }}</span>
              <span>点赞量:{{ item.likes | numberFilter }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    showTitile() {
      const map = {
        0: '影视',
        1: '图集',
        2: '帖子',
        3: '播单',
        4: '艺人'
      }
      return map[this.index]
    },
    showKey(index) {
      const map = {
        0: ['watchTimes', 'playTime', 'title', 'addedTime', 'likes']
      }
      return map[this.index]
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    clickRank(item) {
      switch (this.index) {
        case 0:
          this.$router.push({
            path: `/play/longVideo/${item.id}`
          })
          break
        case 1:
          this.$router.push({
            path: `/discover/imgList?id=${item.id}`,
            query: {
              id: item.id
            }
          })

          break
        case 2:
          this.$router.push({
            path: `/community/detail/${item.base.id}`
          })
          break
        case 3:
          this.$router.push({
            path: `/discover/detail`,
            query: {
              id: item.id,
              type: item.type
            }
          })
          break
        case 4:
          this.$router.push({
            path: `/discover/actor/${item.id}`
          })
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  > div {
    color: $txtDefault;
    font-size: 0.28rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0.22rem;
  }
  li {
    display: flex;
    margin-bottom: 0.22rem;
    &:last-child {
      margin-bottom: 0;
    }
    .img {
      width: 2.8rem;
      height: 1.56rem;
      border-radius: 0.08rem;
      :deep() img {
        border-radius: 0.08rem;
      }
    }
    .rank-img {
      width: 0.28rem !important;
      height: auto !important;
    }
    .rank {
      position: absolute;
      left: 0.08rem;
      top: 0.08rem;
    }
    .left {
      position: relative;
      .rank {
        position: absolute;
        left: 0.08rem;
        top: 0.08rem;
      }
      .bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        @include flexbox;
        width: 100%;
        color: $white;
        font-size: 0.2rem;
        font-weight: 400;
        padding: 0 0.08rem 0.08rem;
        > div {
          @include flex-center;
          > img {
            margin-right: 0.04rem;
          }
        }
      }
    }
    .right {
      padding: 0.18rem 0.24rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > div {
        &:nth-child(1) {
          color: $txtDefault;
          font-size: 0.24rem;
          font-style: normal;
          font-weight: 500;
          @include textoverflow(2);
          margin-bottom: 0.12rem;
        }
        &:nth-child(2) {
          @include flexbox;
          color: $grey;
          font-size: 0.2rem;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
    .not-media {
      width: 100%;
      display: flex;

      .mine_top_bg {
        width: 1.56rem;
        height: 1.56rem;
        :deep() img {
          width: 1.56rem;
          height: 1.56rem;
          border-radius: 0.08rem;
        }
        :deep() .photo-yellow {
          position: absolute;
          right: 0.08rem;
          top: 0.08rem;
          width: 0.92rem;
          height: 0.38rem;
          @include flex-center;
          border-radius: 0.08rem;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
          img {
            width: 0.2rem;
            height: 0.2rem;
          }
        }
        :deep() .photo-count {
          position: absolute;
          right: 0rem;
          bottom: 0.08rem;
          width: 100%;
          @include flex-center;
          border-radius: 0.08rem;
          color: #fff;
          display: flex;
          justify-content: flex-end;
          padding-right: 0.08rem;
        }
      }
      .hot-item-post {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .hot-item-post {
        margin-left: 0.24rem;
        > div {
          color: #999;
          font-size: 0.2rem;
          font-style: normal;
          font-weight: 400;
          &:nth-child(1) {
            @include textoverflow;
            color: #333;
            font-family: 'PingFang SC';
            font-size: 0.28rem;
            font-style: normal;
            font-weight: 500;
          }
          &:nth-child(2) {
            margin: 0.08rem 0;
          }
          &:nth-child(3) {
            > span:first-child {
              margin-right: 0.2rem;
            }
          }
        }
        .tags {
          > span {
            margin-right: 0.2rem;
          }
        }
      }
    }
  }
}
</style>
