<template>
  <div class="hot-box">
    <MyTitle :text="title" @onclick="toPage" />
    <div class="hot-container">
      <ul @touchmove.stop>
        <template v-for="(arr, index) in showlist">
          <li v-if="arr && arr.length" :key="index" class="hot-item" @click="toPage({ path: '/discover/girl' })">
            <div v-for="item in arr" :key="item.id" @click.stop="handleClick(item)">
              <EDecryptImg :imgCDN="imgCDN" class="mine_top_bg" :imgURL="item.Avatar || item.avatar" />
              <div class="hot-item-right">
                <div>{{ item.name }}</div>
                <div>{{ item.desc }}</div>
                <div>
                  <span>视频：{{ item.movieCount }}</span>
                  <span>图片：{{ item.photoCount }}</span>
                  <span>点赞：{{ item.likes | numberFilter }}</span>
                </div>
              </div>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    MyTitle: () => import('./title.vue')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    sort: {
      type: Number,
      default: 0
    }
  },
  computed: {
    showlist() {
      const list = [[]]
      let count = 0
      this.list.forEach((v, i) => {
        list[count].push(v)
        if ((i + 1) % 3 == 0 && i !== 0) {
          count++
          list.push([])
        }
      })
      return list
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },

  methods: {
    toPage(item) {
      this.$router.push({
        path: '/discover/girl',
        query: {
          sort: this.sort
        }
      })
    },
    handleClick(item) {
      this.$router.push({
        path: `/discover/actor/${item.id}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.hot-box {
  border-top: 1px solid $greyf0;
  padding-top: 0.36rem;
  margin-top: 0.36rem;
}

.hot-container {
  @media (pointer: coarse) {
    ul::-webkit-scrollbar {
      display: initial;
    }
  }

  ul {
    scrollbar-width: none;
    /* firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    width: 100%;
    overflow-x: scroll;
    @include flexbox($jc: flex-start, $ai: flex-start, $fd: row, $fw: nowrap, $flex: 1);
    flex-shrink: 1;

    .hot-item {
      min-width: 5.74rem;
      // background: $linear1;
      position: relative;
      margin-right: 0.16rem;
    }
  }

  .mine_top_bg {
    width: 1.2rem;
    height: 1.2rem;

    :deep() img {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 0.08rem;
    }
  }

  .hot-item {
    img {
      width: 1.2rem;
    }

    >div {
      @include flex-align-center;
      margin-bottom: 0.2rem;
    }

    &-right {
      margin-left: 0.3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      >div {
        &:nth-child(1) {
          color: #333;
          font-size: 0.32rem;
          font-weight: 400;
        }

        &:nth-child(2) {
          color: #9a9a9a;
          font-size: 0.24rem;
          margin: 0.04rem 0;
          @include textoverflow;
        }

        &:nth-child(3) {
          color: #676767;
          font-size: 0.24rem;
          font-weight: 400;

          span {
            margin-right: 0.18rem;
            position: relative;

            &:nth-child(-n + 2) {
              &::after {
                content: '|';
                position: absolute;
                right: -0.08rem;
                top: 0;
                // transform: translate(0, -50%);
                width: 0.02rem;
                height: 0.24rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
