<template>
  <div class="container-water-fall">
    <EWaterFall :datasList="list">
      <template #left="{ handleLoad, data }">
        <shortVideoshowType1
          :data="data"
          :list="list"
          class="cell-item leftItem"
          @loadedImg="handleLoad"
        ></shortVideoshowType1>
      </template>
      <template #right="{ handleLoad, data }">
        <shortVideoshowType1
          :data="data"
          :list="list"
          class="cell-item rightItem"
          @loadedImg="handleLoad"
        ></shortVideoshowType1>
      </template>
    </EWaterFall>
  </div>
</template>
<script>
// 短视频瀑布流列表
export default {
  components: {
    shortVideoshowType1: () => import('@/components/Home/showType/videoListType/shortVideoshowType1.vue')
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.container-water-fall {
  width: 100%;
  box-sizing: border-box;
  //padding: 0 0.2rem;
  .leftItem {
    margin-right: auto;
  }
  .rightItem {
    margin-left: auto;
  }
  .cell-item {
    width: 3.38rem;
    box-sizing: border-box;
    margin-bottom: 0.14rem;
    animation: fadeIn 1s;
    background: #fff;
    border-radius: 0.1rem;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  :deep(.rowWrap) {
    overflow: hidden;
  }
  :deep(.waterFall) .rowWrap .left > div {
    overflow: unset;
  }
  :deep(.waterFall) .rowWrap .right > div {
    overflow: unset;
  }
}
</style>
