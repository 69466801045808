<template>
  <li>
    <EDecryptImg class="mine_top_bg" :imgURL="novelDetailData.coverImg"> </EDecryptImg>
    <div class="right">
      <div>
        <span>第{{ item.chapterNum }}话</span>
        <span>{{ item.title }}</span>
      </div>
      <div>
        <div class="vip-tps">
          <span v-if="item.isFree" class="free"> 免费 </span>
          <span v-else class="vip"> VIP </span>
          <!-- <span class="has-look"> 已看 </span>
          <span class="look-progress"> 已看 </span>
          <span class="total"> 已看 </span> -->
        </div>
        <div class="playing">
          <img src="@/assets/imgs/share/maskgroup.png" alt="" v-if="item.id == $route.query.chapterId" />
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    novelDetailData: {
      type: Object,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  color: #333;
  height: 1.44rem;
  border-radius: 0.16rem;
  background: rgba(0, 0, 0, 0.05);
  margin-bottom: 0.24rem;
  display: flex;
  .mine_top_bg {
    width: 1.1rem;
    border-radius: 0.16rem;
    height: 1.44rem;
    flex: none;
    :deep() {
      img {
        width: 1.1rem !important;
        height: 1.44rem !important;

        border-radius: 0.16rem !important;
      }
    }
  }
  .right {
    padding: 0.2rem 0.4rem;
    color: #333;
    font-size: 0.28rem;
    flex: 1;
    > div {
      &:nth-child(1) {
        display: flex;
        > span:nth-child(1) {
          margin-right: 0.16rem;
          flex: none;
        }
        > span:nth-child(2) {
          margin-right: 0.16rem;
          @include textoverflow;
          flex: 1;
        }
      }
      &:nth-child(2) {
        @include flexbox;
        margin-top: 0.2rem;
      }
      .vip-tps {
        display: flex;
        span {
          padding: 0.04rem 0.24rem;
        }
        .has-look {
          padding: 0.04rem 0.16rem;
          color: #999;
          font-size: 0.24rem;
          background: rgba(0, 0, 0, 0.05);
        }
        .look-progress {
          color: #ff7a00;
          font-size: 0.24rem;
        }
        .total {
          color: #999;
          font-size: 0.24rem;
        }
        .vip {
          padding: 0.04rem 0.24rem;
          background: #e8619d;
          margin-right: 0.24rem;
          font-size: 0.26rem;
        }
        .free {
          background-color: #fff;
          color: green;
        }
        > span {
          @include flex-center;
          border-radius: 0.08rem;
          color: #fff;
          margin-right: 0.16rem;
        }
      }
    }
  }
  .playing {
    img {
      width: 0.5rem;
    }
  }
}
</style>
