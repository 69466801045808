<template>
  <div class="popup-list">
    <van-popup
      v-model="popupMsg.show"
      class="popup-round"
      :class="popupMsg.type === 'lottery_ok' ? 'lottery_ok_pop' : ''"
      get-container="body"
      :position="popupMsg.position"
      round
      @click-overlay="fn_close(popupMsg.type == 'lotteryType' ? 'yes' : '')"
    >
      <!--play_post 提示-->
      <div v-if="popupMsg.type == 'text'" class="play_post">
        <div class="content">
          <img src="@/assets/imgs/mine/warning.png" alt="" class="warnImges" />
          <div class="text-content">
            <p class="first">您还不是我们的VIP会员</p>
            <p class="second"><span style="color: #e8619d">开通会员</span>畅享完整内容！</p>
          </div>
        </div>
        <div class="btn-wrap">
          <div @click="fn_close('toTask')">做任务得VIP</div>
          <div @click="fn_close('toVip')">开通会员</div>
        </div>
      </div>
      <!--coin 金币 -->
      <div v-if="popupMsg.type == 'coin'" class="coin-pop">
        <div class="content">
          <img src="@/assets/imgs/mine/warning.png" alt="" class="warnImges" />
          <div class="text-content">
            <p class="first">
              您确认支付：<span style="color: #e8619d">{{ popupMsg.price }}金币?</span>
            </p>
            <p class="second">
              <span>当前余额：</span><span style="color: #e8619d">{{ myBalance }}金币</span>
            </p>
          </div>
        </div>
        <div class="btn-wrap">
          <div @click="fn_ok(true)">{{ popupMsg.ok }}</div>
          <div @click="fn_close('myWallet')">{{ popupMsg.cancel }}</div>
        </div>
      </div>
      <div v-if="popupMsg.type == 'change-face-post'" class="change-face-post">
        <div>
          <img src="@/assets/imgs/mine/warning.png" alt="" />
        </div>
        <p>对不起,您的余额不足</p>
        <div>
          {{ popupMsg.price | changeGold }}
          金币
        </div>
        <div>
          <span>当前余额</span>
          <span>{{ popupMsg.balance | changeGold }}金币</span>
        </div>
        <div class="btn">
          <div @click="$router.push('/mine/myWallet')">前往充值</div>
        </div>
      </div>
      <!-- <div v-if="popupMsg.type == 'change-face-enough'" class="change-face-enough">
        <div>
          <span>扣除</span>
          <span>
            {{ popupMsg.price | changeGold }}
            金币</span
          >
        </div>
        <div>
          <span>可用余额</span>
          <span>{{ popupMsg.balance | changeGold }}金币</span>
        </div>
        <div v-if="popupMsg.ok" class="fn-ok-btn" @click="fn_ok(true)">{{ popupMsg.ok }}</div>
      </div> -->
      <div v-if="popupMsg.type == 'change-face-enough'" class="change-face-enough-new">
        <div class="top">
          <div class="close-icon" @click="fn_close()">
            <img src="@/assets/imgs/aiLab/enough_close.png" alt="" />
          </div>
          <div class="box">
            <img src="@/assets/imgs/aiLab/pay_warning.png" alt="" />
            <div>请确认支付金币</div>
            <div>{{ popupMsg.price | changeGold }}金币</div>
            <div>
              <span>当前余额</span>
              <span>{{ popupMsg.balance | changeGold }}金币</span>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div v-if="popupMsg.ok" class="fn-ok-btn" @click="fn_ok(true)">{{ popupMsg.ok }}</div>
          <div v-if="popupMsg.cancel" class="fn-ok-btn" @click="fn_close('ai')">{{ popupMsg.cancel }}</div>
        </div>
      </div>
      <!-- 抽奖弹窗 -->
      <div v-if="popupMsg.type == 'lotteryType'" class="lottery">
        <img v-if="popupMsg.img" :src="popupMsg.img" alt="" />
        <div v-if="popupMsg.content" class="text-content">{{ popupMsg.content }}</div>
        <div v-if="popupMsg.ok" class="ok_btn" @click="fn_ok(true)">{{ popupMsg.ok }}</div>
        <div v-if="popupMsg.cancel" class="cancel_btn" @click="fn_close('yes')">{{ popupMsg.cancel }}</div>
      </div>
      <!-- 抽奖中奖弹窗 -->
      <div v-if="popupMsg.type == 'lottery_ok'" class="lottery_ok">
        <div class="item_img">
          <EDecryptImg :imgCDN="imgCDN" class="lootery_img" :imgURL="popupMsg.img ? popupMsg.img : ''"> </EDecryptImg>
          <div>
            <p v-if="popupMsg.content" class="content">{{ popupMsg.content }}</p>
            <p v-if="popupMsg.desc" class="desc">{{ popupMsg.desc }}</p>
          </div>
        </div>

        <div v-if="popupMsg.ok" class="ok_btn" @click="fn_ok(true, 'lottery')">{{ popupMsg.ok }}</div>
        <div v-if="popupMsg.cancel" class="cancel_btn" @click="fn_close()">{{ popupMsg.cancel }}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    // position: {
    //   type: String,
    //   default() {
    //     return 'bottom'
    //   },
    // }
    img: {
      type: String,
      default() {
        return 'bottom'
      }
    }
  },

  data() {
    return {
      popupMsg: {
        show: false,
        title: '',
        content: '',
        type: 'text',
        cancel: '',
        ok: '',
        position: 'center',
        cb: null,
        price: 0,
        showBalance: false,
        tip: '',
        close: null,
        price: false,
        img: '',
        desc: ''
      }
    }
  },
  computed: {
    // 获取个人余额
    myBalance({ $store }) {
      return $store.getters['getUserInfo'].balance / 100
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {},
  methods: {
    //取消、关闭
    fn_close(type) {
      this.popupMsg.show = false

      if (type === 'yes') {
        this.popupMsg.close(false)
      }
      if (type === 'toTask') {
        this.$router.push('/mine/myShouyi')
      }
      if (type === 'toVip') {
        if (['ComicsDetail', 'NovelDetail'].includes(this.$route.name)) {
          this.$emit('newPopupVip')
        } else {
          this.$router.push('/Mine/vip')
        }
      }
      if (type === 'myWallet') {
        this.$router.push('/Mine/myWallet')
      }
      if (type === 'ai') {
        this.popupMsg.oncancel(1)
        this.popupMsg.show = false
      }
    },
    //确定
    fn_ok(status, type) {
      this.popupMsg.cb(status)
      if (!type) {
        this.popupMsg.show = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-list {
  color: #666666;
}

.change-face-post {
  border-radius: 0.24rem;
  border: 2px solid #535353;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.32rem 0 0;
  width: 6rem;
  text-align: center;

  img {
    width: 0.72rem;
  }

  > p {
    color: #999999;
    font-size: 0.32rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0.34rem 0 0;
  }

  > div {
    &:nth-of-type(2),
    &:nth-of-type(3) {
      margin-top: 0.16rem;
      color: #e8619d;
      font-size: 0.3rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &:nth-of-type(3) {
      display: flex;

      > span:nth-child(1) {
        color: #666666;
        border-radius: 0.04rem;
        background: rgba(0, 0, 0, 0.04);
        font-size: 0.24rem;
        padding: 0.04rem 0.1rem;
        margin-right: 0.1rem;
      }
    }

    &:nth-of-type(4) {
      margin: 0.32rem 0 0;
      border-top: 0.02rem solid #f0f0f0;
      color: #333;
      font-size: 0.28rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      padding: 0.32rem 0;
      width: 100%;

      > div {
        margin: 0 auto;
        background: $gradientBg;
        width: 3.6rem;
        padding: 0.14rem 0.58rem 0.14rem 0.57rem;
        border-radius: 0.56rem;
        white-space: nowrap;
        color: #fff;
      }
    }
  }
}

.disclaimer-tips {
  color: #f77;
  font-family: PingFang HK;
  font-size: 0.26rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.38rem;
}

.change-face-enough {
  border-radius: 0.24rem;
  border: 2px solid #535353;
  background: #292929;
  @include flex-column-center;
  width: 6rem;
  height: 3.62rem;

  > div {
    &:nth-child(1) {
      color: #fff;
      font-family: PingFang SC;
      font-size: 0.32rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      > span:last-child {
        color: #e8619d;
        font-family: PingFang HK;
        font-size: 0.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 0.1rem;
      }
    }

    &:nth-child(2) {
      margin: 0.21rem 0 0.65rem;
      @include flex-align-center;

      > span {
        color: #e8619d;

        &:nth-child(1) {
          border-radius: 0.04rem;
          background: rgba(255, 207, 134, 0.15);
          padding: 0.04rem 0.1rem;
          font-size: 0.24rem;
          margin-right: 0.1rem;
        }

        &:nth-child(2) {
          font-family: PingFang HK;
          font-size: 0.28rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  .fn-ok-btn {
    border-radius: 0.56rem;
    background: #d73c44;
    padding: 0.14rem 1.25rem 0.14rem 1.23rem;
    color: #333;
    font-family: PingFang HK;
    font-size: 0.28rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.change-face-enough-new {
  background-color: #fff;
  width: 5.38rem;
  height: 5.16rem;
  border-radius: 0.12rem;
  position: relative;

  .top {
    padding-top: 0.38rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;

    > .box {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 0.8rem;
      }

      > div {
        color: #333;
        text-align: center;
        font-family: 'PingFang SC';
        font-size: 0.28rem;

        &:nth-of-type(1) {
          margin: 0.34rem 0 0.16rem;
        }

        &:nth-of-type(2) {
          color: #e8619d;
          font-size: 0.28rem;
        }

        &:nth-of-type(3) {
          margin-top: 0.2rem;
          @include flex-align-center;

          > span:first-child {
            padding: 0.04rem 0.08rem 0.04rem 0.16rem;
            border-radius: 2rem;
            background: rgba(0, 0, 0, 0.04);
            margin-right: 0.16rem;
          }

          > span:last-child {
            color: #e8619d;
            font-size: 0.24rem;
          }
        }
      }
    }
  }

  .close-icon {
    img {
      position: absolute;
      right: 0.24rem;
      top: 0.24rem;
      width: 0.36rem;
      height: 0.36rem;
      width: 0.36rem;
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    color: #fff;
    font-family: 'PingFang SC';
    font-size: 0.28rem;
    font-weight: 500;
    padding: 0.4rem 0 0;

    > div {
      display: flex;
      padding: 0.16rem 0.28rem;
      justify-content: center;
      align-items: center;
      border-radius: 2rem;
      white-space: nowrap;
      background: $gradientBg;

      &:nth-child(2) {
        margin-left: 0.36rem;
      }
    }
  }
}

.popup-round {
  max-width: $pcMaxWidth;
}

.text-content {
  font-size: 0.3rem;
  margin: 0.44rem 0;
}

.lottery {
  width: 5.6rem;
  color: #905353;
  font-size: 0.34rem;
  text-align: center;
  padding: 0.65rem 0.6rem;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .text-content {
    margin: 0 0 0.4rem 0;
  }
}

.ok_btn {
  border-radius: 0.36rem;
  background: #ffe49e;
  box-shadow: 0px 4px 10.1px 0px rgba(0, 0, 0, 0.05);
  width: 4.52rem;
  height: 0.8rem;
  line-height: 0.8rem;
  margin-bottom: 0.32rem;
  font-size: 0.28rem;
  text-align: center;
  color: #905353;
}

.cancel_btn {
  border-radius: 0.96rem;
  background: rgba(0, 0, 0, 0.55);
  width: 4.52rem;
  height: 0.8rem;
  color: #fff;
  line-height: 0.8rem;
  font-size: 0.28rem;
  text-align: center;
}

// 中奖弹窗
.lottery_ok_pop {
  background: transparent;
}

.lottery_ok {
  background: url('../../assets/imgs/index/luckyDraw/draw_bg.png') no-repeat;
  background-size: cover;
  width: 5.6rem;
  height: 7.67rem;
  padding: 2.88rem 0.6rem 0 0.6rem;

  .item_img {
    @include flexbox();
    margin-bottom: 0.6rem;
    text-align: center;

    .content {
      font-size: 0.36rem;
    }

    .desc {
      font-size: 0.32rem;
      color: #ffe9a1;
    }
  }

  .lootery_img {
    width: 1.6rem;
    height: 2rem;
    flex-shrink: 0;
  }
}

.play_post {
  width: 5.4rem;
  background: #ffffff;

  .content {
    height: 2.88rem;
    font-size: 0.24rem;
    @include flex-column-center;
    text-align: center;

    .text-content {
      margin-top: 0.4rem;

      .first {
        color: #999999;
      }

      .second {
        color: #666666;
        font-size: 0.28rem;
      }
    }
  }

  .btn-wrap {
    border-top: 0.02rem solid #f0f0f0;
    font-size: 0.28rem;
    height: 1.36rem;
    @include flex-center;

    > div {
      color: #ffffff;
      border-radius: 2rem;
      padding: 0.16rem 0.48rem;
      margin: 0 0.12rem;
    }

    :nth-child(1) {
      background: #666666;
    }

    :nth-child(2) {
      background: $gradientBg;
    }
  }
}

.item-text {
  padding: 0.5rem 0.35rem;
  text-align: center;

  .title {
    font-size: 0.36rem;
    color: #e02020;
  }

  .content {
    font-size: 0.28rem;
    font-size: #6a6a6a;
    padding: 0.4rem 0;

    &.coin {
      @include flex-center;
      font-size: 0.5rem;

      img {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.1rem;
      }
    }
  }

  .btn-wrap {
    display: flex;

    div {
      height: 0.78rem;
      line-height: 0.78rem;
      border-radius: 0.39rem;
      font-size: 0.32rem;
      color: #fff;
      flex: 1;
    }

    .ok {
      background: #ffba57;
    }

    .cancel {
      margin-left: 0.2rem;
      background: #777777;
    }
  }
}

.coin-pop {
  width: 5.4rem;
  background: #ffffff;
  text-align: center;

  .text-content {
    .second {
      margin-top: 0.16rem;

      span {
        &:first-child {
          border-radius: 2rem;
          background: rgba(0, 0, 0, 0.04);
          padding: 0.04rem 0.08rem 0.04rem 0.16rem;
          margin-right: 0.24rem;
        }
      }
    }
  }

  .btn-wrap {
    border-top: 0.02rem solid #f0f0f0;
    font-size: 0.28rem;
    height: 1.36rem;
    @include flex-center;

    > div {
      color: #ffffff;
      border-radius: 2rem;
      padding: 0.16rem 0.48rem;
      margin: 0 0.12rem;
    }

    :nth-child(1) {
      background: $gradientBg;
    }

    :nth-child(2) {
      background: #666666;
    }
  }
}

.warnImges {
  width: 0.71998rem;
  height: 0.72rem;
}
</style>
