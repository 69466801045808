<template>
  <div v-if="itemList && itemList.length" class="fiveListChange">
    <HomeTitle :title="name" :path="`/acg/acgTopicDetail/?title=${name}&type=cartoon&id=${id}`" />
    <ul class="nine-list">
      <li v-for="(item, index) in videoList.length ? videoList : itemList" :key="item.id"
        :class="index === 0 ? 'first_li' : 'other_li'">
        <cartoonOneCard :width="index === 0 ? '6.86rem' : '3.36rem'" :height="index === 0 ? '3.86rem' : '1.86rem'"
          :itemData="item" />
      </li>
      <li class="other_li"></li>
    </ul>
    <div class="footerBtn">
      <div @click="
    $router.push({
      path: `/acg/acgTopicDetail/`,
      query: {
        id: id,
        name: name,
        type: 'cartoon'
      }
    })
    ">
        <img src="~/assets/imgs/home/more2.png" alt="" /> 更多片源
      </div>
      <div @click="fn_videoTopicChange"><img src="~/assets/imgs/home/reload.png" alt="" /> 更换一批</div>
    </div>
  </div>
</template>

<script>
import { videoTopicChange } from 'api/home'
export default {
  components: {
    cartoonOneCard: () => import('./cartoonOneCard.vue'),
    HomeTitle: () => import('@/components/homeTitle.vue')
  },
  props: ['itemList', 'name', 'id'],
  data() {
    return {
      videoList: []
    }
  },
  methods: {
    async fn_videoTopicChange() {
      const res = await videoTopicChange({
        id: this.id,
        count: 5
      })
      if (res.code === 200 && res.data) {
        this.videoList = res.data.mediaList
      } else {
        return this.$toast(res.tip)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fiveListChange {
  margin-bottom: 0.36rem;
}

.nine-list {
  @include flexbox($fw: wrap);

  li {
    flex: none;
    margin-bottom: 0.16rem;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .other_li {
    width: 3.36rem;
  }

  .first_li {
    width: 6.86rem;
  }
}

.footerBtn {
  margin: 0.32rem 0.29rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  >div {
    width: 2.76rem;
    height: 0.64rem;
    border-radius: 0.37rem;
    background-color: #f6f6f6;
    border: 0.01rem solid #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;

    >img {
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.2rem;
    }
  }

  >div:last-child {
    background: #fff;
    border: 0.01rem solid #666666;
  }
}
</style>
