<template>
  <div class="video-list">
    <video-show-type4
      v-for="item in list"
      :key="item.id"
      :data="item"
      class="video-list-item"
      @click.native="$emit('on-choose', item)"
    />
  </div>
</template>
<script>
export default {
  name: 'VideoList1',
  components: {
    VideoShowType4: () => import('@/components/Home/showType/videoListType/videoShowType4.vue')
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
.video-list {
  .video-list-item {
    margin-bottom: 0.24rem;
    &:nth-last-child {
      margin-bottom: 0;
    }
  }
}
</style>
