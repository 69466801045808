<template>
  <div class="activity-container">
    <EBackTop class="mine-head" :title="activityDetail.name"> </EBackTop>
    <div class="activity-listWrap">
      <div class="item">
        <div class="cover-wrap">
          <div class="top">
            <div class="dm-img">
              <EDecryptImg :imgURL="activityDetail.cover" />
            </div>
          </div>
          <div class="bottom">
            <div class="ico">
              <EDecryptImg :imgRadius="'0.2rem'" :imgURL="activityDetail.gameAvatar" />
            </div>
            <div class="game-decial-wrap">
              <div class="game-name-wrap">
                <div class="game-name-top">
                  <div class="title">{{ activityDetail.name }}</div>
                </div>

                <div class="mark">
                  <img src="/newAppMh/home-comic/games/mark.png" alt="" />
                  {{ activityDetail.number && activityDetail.number / 10 }}
                </div>
              </div>
              <div class="tab-list">
                <div
                  v-for="(tagItem, tagIndex) in activityDetail.tags"
                  v-show="tagItem.tagName"
                  :key="tagIndex"
                  :style="{ backgroundColor: tagItem.color }"
                >
                  {{ tagItem.tagName }}
                </div>
              </div>
              <div class="desc">{{ activityDetail.desc }}</div>
            </div>
          </div>
        </div>
        <div class="intro-wrap">
          <div class="title">游戏简介</div>
          <div class="desc-list">
            <div class="introduce">{{ activityDetail.introduce }}</div>
            <div v-for="(sitem, sindex) in activityDetail.nodes" :key="sindex">
              <div class="node-text">{{ sitem.text }}</div>
              <div v-for="(titem, tindex) in sitem.imgs" :key="tindex" class="node-img">
                <EDecryptImg class="dm-t" :imgRadius="'0.2rem'" :imgURL="titem" />
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrap" @click="buyHgame">
          <div v-if="activityDetail.price && !activityDetail.isBuy" class="coin">
            <img src="/newAppMh/mine/pay/coin.png" alt="" />
            {{ activityDetail.price | changeGold }}金币解锁
          </div>
          <div v-else-if="activityDetail.isVip">VIP免费游玩</div>
          <div v-else-if="activityDetail.isBuy" class="coin">已购买</div>
          <div v-else>VIP免费游玩</div>
        </div>
      </div>
    </div>
    <DmPopup ref="popupMsg"></DmPopup>
  </div>
</template>
<script>
import { canBuy } from '@/utils/utils_tools'
import { hGameDetail, hGamePay } from '@/api/home'
export default {
  components: {
    DmPopup: () => import('@/components/Popup/index.vue')
  },
  data() {
    return {
      activityDetail: {}
    }
  },
  computed: {
    isVipMember({ $store }) {
      return $store.getters['user/isVipMember']
    }
  },
  mounted() {
    this.get_detail()
  },
  created() {},
  methods: {
    //获取消息列表
    async get_detail() {
      const res = await hGameDetail({
        id: +this.$route.query.id
      })
      if (res.code === 200) {
        this.activityDetail = res.data
      } else {
        this.$toast(res.tip)
      }
    },
    //购买游戏
    async buyHgame() {
      if (this.activityDetail.price && !this.activityDetail.isBuy) {
        if (canBuy(this.$store, this.activityDetail.price)) {
          this.$refs['popupMsg'].popupMsg = {
            show: true,
            title: '立即支付',
            content: this.activityDetail.price / 100,
            type: 'coin',
            ok: '确定',
            cancel: '',
            position: 'bottom',
            cb: async returnData => {
              const res = await hGamePay({
                id: this.activityDetail.id
              })
              if (res.data.gameUrl) {
                this.activityDetail.isBuy = true
                this.$toast('购买成功')
                this.$refs['popupMsg'].popupMsg.show = false
                window.location.href = res.data.gameUrl
              }
            }
          }
        } else {
          this.$refs['popupMsg'].popupMsg = {
            show: true,
            title: '余额不足',
            content: '您的余额已不足，请充值金币购买',
            type: 'text',
            ok: '立即充值',
            cancel: '',
            position: 'bottom',
            cb: async returnData => {
              this.$router.push('/Mine/myWallet')
              this.$refs['popupMsg'].popupMsg.show = false
            }
          }
        }
      } else if (this.activityDetail.isVip && !this.isVipMember) {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '开通vip',
          content: '开通vip即可查看帖子',
          type: 'text',
          ok: '立即开通',
          cancel: '',
          position: 'bottom',
          cb: async returnData => {
            this.$router.push('/mine/vip-center')
            this.$refs['popupMsg'].popupMsg.show = false
          }
        }
      } else {
        const res = await hGamePay({
          id: this.activityDetail.id
        })
        if (res.data.gameUrl && res.data.playable) {
          window.location.href = res.data.gameUrl
        } else {
          this.$toast(res.tip)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.activity-container {
  padding-bottom: 0.92rem;
  min-height: 100vh;
  .activity-listWrap {
    .item {
      .cover-wrap {
        box-shadow: 0 0.08rem 0.24rem 0 rgba(0, 0, 0, 0.1);
        margin-bottom: 0.4rem;
        background: #fff;
        min-height: 3rem;
        display: flex;
        flex-direction: column;
        font-size: 0.24rem;
        align-items: center;
        .dm-img {
          width: 7.5rem;
          height: 4.15rem;
        }
        .bottom {
          padding: 0.3rem;
          width: 100%;
          display: flex;
          align-items: center;
          .ico {
            width: 0.8rem;
            height: 0.8rem;
          }
          .game-decial-wrap {
            color: #6a6a6a;
            padding-left: 0.2rem;
            .game-name-wrap {
              width: 5.5rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 0.24rem;
              .game-name-top {
                display: flex;
                align-items: center;
                .title {
                  font-size: 0.32rem;
                  margin-right: 0.2rem;
                  white-space: nowrap;
                }
              }
              .mark {
                color: #e9762e;
                font-size: 0.32rem;
                display: flex;
                align-items: center;
                img {
                  width: 0.36rem;
                  margin-right: 0.1rem;
                }
              }
            }
            .tab-list {
              display: flex;
              max-width: 3rem;
              overflow: hidden;
              font-size: 0.24rem;
              color: #fff;
              div {
                white-space: nowrap;
                margin-right: 0.1rem;
                padding: 0 0.1rem;
                border-radius: 999px;
                &:nth-child(even) {
                  background: #4e982e;
                }
                &:nth-child(odd) {
                  background: #ea7e7b;
                }
              }
            }
            .desc {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
            }
          }
        }
      }
      .intro-wrap {
        padding: 0.37rem;
        padding-top: 0;
        .title {
          padding-bottom: 0.1rem;
          font-size: 0.32rem;
        }
        .introduce {
          white-space: pre-line;
        }
        .desc-list {
          .node-text {
            white-space: pre-line;
            padding: 0.1rem 0;
          }
          .node-img {
            margin-bottom: 0.1rem;
          }
        }
      }
      .btn-wrap {
        margin-top: 0.3rem;
        div {
          font-size: 0.32rem;
          margin: 0 auto;
          width: 6.8rem;
          line-height: 0.78rem;
          height: 0.78rem;
          text-align: center;
          border-radius: 0.24rem;
          box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.1);
          background: #fff;
          color: #ff8b00;
        }

        .free {
          color: #0d9c09;
        }
        .coin {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ff7777;
          img {
            margin-right: 0.1rem;
            width: 0.28rem;
          }
        }
      }
    }
  }
}
</style>
