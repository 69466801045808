<template>
  <van-popup :value="show" get-container="body" position="center" class="pop-wrap" @input="toClose">
    <!--play_post 提示-->
    <EBackTop title="引用资源" @close="fn_close('close')" />
    <div class="play_post">
      <ResourcePage />
    </div>
  </van-popup>
</template>
<script>
export default {
  components: {
    ResourcePage: () => import('@/components/Resource/index.vue')
  },
  model: {
    prop: 'show',
    event: 'input'
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      popupMsg: {}
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {},
  methods: {
    toClose() {
      this.$emit('input', false)
    },
    fn_close(type) {
      this.callback[type]?.call()
    }
  }
}
</script>

<style lang="scss" scoped>
.pop-wrap {
  width: 100%;
  .play_post {
    width: 100%;
    height: calc(100% - 20rem);
    background: #ffffff;
    overflow-y: auto;
  }
}
</style>
