<template>
  <div class="comment">
    <div v-if="showOpr" class="header">
      <div class="header-left">
        <span style="font-size: 0.34rem; font-weight: 500">评论</span>
        {{ list.length | numberFilter }}
        <!-- {{ newComments | numberFilter }}+ -->
      </div>
      <div class="header-right">
        <div class="btn" :class="{ active: sortId === 0 }" @click="switchSort(0)">最新</div>
        <div>/</div>
        <div class="btn" :class="{ active: sortId === 1 }" @click="switchSort(1)">最热</div>
      </div>
    </div>
    <ERefreshLoadV3 class="content-main" :finished="finished" :beforeRefresh="refreshData" :moreData="moreData">
      <div class="content-main-wrap">
        <CommentCard
          v-for="item in list"
          :key="item.id"
          :data="item"
          :publisher="publisher"
          @on-reply="clickRespondText"
        ></CommentCard>
      </div>
    </ERefreshLoadV3>
    <!-- 评论 -->
    <div class="submit-box">
      <div v-if="refTarget" class="submit-ref">
        <EDecryptImg :img-u-r-l="refTarget.item.coverImg || refTarget.item.cover" class="cover" />
        <div class="title">{{ refTarget.item.title || refTarget.item.name }}</div>
        <div class="btn" @click="delRefTarget">删除</div>
      </div>
      <div class="line-bot"></div>
      <!-- 是否为会员 -->
      <div class="submit-input">
        <van-field v-model="text" :placeholder="placeholder" />
        <!-- 图片评论组件 -->
        <img src="@/assets/imgs/upoad_img_pic2.svg" class="add_img_box" @click="openResourcePop" />
        <img src="@/assets/imgs/send.svg" class="submit-txt" alt @click="addComment" />
      </div>
    </div>
    <ResourcePop v-model="showResourcePop" />
  </div>
</template>

<script>
import { comCommentAdd, comCommentList } from 'api/comics'
import { numberFilter } from '@/filters/filter'
export default {
  components: {
    // EDecryptImg: () => import('@/components/EDecryptImg/index.vue'),
    // NoData: () => import('@/components/noData.vue'),
    CommentCard: () => import('@/components/comment/CommentCard.vue'),
    ResourcePop: () => import('@/components/Resource/ResourcePop.vue')
  },
  props: {
    disabledPull: {
      // 评论对象id
      type: Boolean,
      default() {
        return false
      }
    },

    objectId: {
      // 评论对象id
      type: Number,
      default() {
        return 0
      }
    },
    bgColor: {
      //评论类型
      type: String,
      default() {
        return '#fff'
      }
    },
    objectype: {
      //评论类型1 // 视频
      // 2 // 帖子
      // 3 // 漫画
      // 4 // 小说
      // 5 // 写真
      type: Number,
      default() {
        return 0
      }
    },
    publisher: {
      // 发布者id
      type: Object,
      default() {
        return {}
      }
    },
    showOpr: {
      type: Boolean,
      default: true
    },
    typeId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      text: '', //评论内容
      pageNum: 1,
      pageSize: 10,
      sortId: 0, // 排序
      activeIndex: 0, // 展开的id
      newComments: 0, //评论数
      parentsId: undefined, // 父级回复id
      list: [], // 评论列表
      //上拉加载
      loading: false,
      //上拉加载完成
      finished: false,
      //下拉刷新
      isLoading: false,
      //评论状态
      commentReady: true,
      showResourcePop: false
    }
  },
  computed: {
    isVipMember({ $store }) {
      return $store.getters['isMember']
    },
    replyTarget() {
      return this.$store.state.history.replyTarget
    },
    refTarget() {
      return this.$store.state.history.refTarget
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    placeholder() {
      if (this.replyTarget) {
        return '回复 ' + this.replyTarget.userName
      }
      return '请在此输入评论'
    }
  },
  watch: {
    typeId(val, newVal) {
      this.switchSort(newVal)
    }
  },
  mounted() {},
  methods: {
    switchSort(e) {
      this.sortId = e
      this.parentsId = undefined
      this.pageNum = 1
      this.list = []
      this.getCommentList()
    },
    numberFilter,
    // 获取评论列表
    async getCommentList() {
      this.loading = true
      const res = await comCommentList({
        objectId: this.objectId,
        objectType: this.objectype,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        parentsId: this.parentsId,
        sortType: this.sortId
      })
      if (res.code === 200) {
        if (!res.data || !res.data.list || res.data.list.length < this.pageSize) {
          this.finished = true
        }
        res.data.list.forEach(item => {
          item.subList = item.subList && Array.isArray(item.subList) ? this.flattenLoop(item.subList) : []
        })
        if (res.data.list) this.list = [...this.list, ...res.data.list]
        //评论数算上子集
        this.newComments = this.list.length
        this.list.forEach(item => {
          this.newComments = this.newComments + item.commentCount
        })
      } else {
        this.finished = true
        return this.$toast(res.tip || res.data)
      }
    },
    flattenLoop(arr) {
      let result = []
      for (let i = 0; i < arr.length; i++) {
        result.push(arr[i])
        if (Array.isArray(arr[i].subList)) {
          result = result.concat(this.flattenLoop(arr[i].subList))
          arr[i].subList = []
        }
      }
      return result
    },
    // 发布评论
    async addComment() {
      // if (!this.isVipMember) {
      //   return this.$toast('会员才参与可以评论!')
      // }
      if (!this.commentReady) {
        return this.$toast('评论操作过快!')
      }
      if (!this.text) {
        return this.$toast('请输入评论内容,或者上传图片')
      }
      this.commentReady = false
      const replyTarget = this.replyTarget || {
        level: 0,
        id: 0,
        parentsId: 0
      }
      const parentsId = replyTarget.level === 1 ? replyTarget?.id : replyTarget?.parentsId
      const res = await comCommentAdd({
        objectId: this.objectId, // 对象id
        objectType: this.objectype, // 对象类型
        parentsId, // 上级id
        replyId: replyTarget?.id, // 回复id
        replyUserName: replyTarget?.userName,
        text: this.text,
        refObject: this.refTarget?.item.id, //引用id
        refType: this.refTarget?.refType //引用资源 1.播单 2.视频 3.写真
      })
      this.activeIndex = 0
      if (res.code === 200) {
        //如果用户持有会员卡 评论免审核 则直接插入数据
        if (res.data.status) {
          const parentNode =
            replyTarget.id === 0 ? { subList: this.list } : this.list.flat(1).find(item => item.id === parentsId)
          const type = replyTarget.id === 0 ? 'unshift' : 'push'
          ;(parentNode.subList = parentNode.subList || [])[type](res.data)
          this.newComments += 1
          this.$emit('comments', this.newComments)
        }
        this.$store.commit('SET_REF_TARGET', null)
        this.$store.commit('SET_REPLY_TARGET', null)
        this.commentReady = true
        this.text = null
        this.parentsId = undefined // 每次发布做一次清除操作
        this.switchSort()
        return this.$toast('评论成功')
      } else {
        this.commentReady = true
        this.finished = true
        return this.$toast(res.tip || res.data)
      }
    },
    // 点击回复按钮
    clickRespondText(item) {
      this.$store.commit('SET_REPLY_TARGET', item)
    },
    // 打开二级评论列表
    openSon(item) {
      this.activeIndex = item.id
      this.pageNum = 1
      this.finished = false
      this.parentsId = item.id

      this.getCommentList()
    },
    //上拉刷新
    refreshData() {
      this.finished = false
      this.list = []
      this.activeIndex = 0
      this.parentsId = undefined
      // 一级列表请求参数
      this.pageNum = 0
    },

    //下拉加载
    async moreData() {
      this.pageNum += 1
      await this.getCommentList()
    },
    delRefTarget() {
      this.$store.commit('SET_REF_TARGET', null)
    },
    openResourcePop() {
      this.$store.commit('SET_REPLY_TARGET', this.replyTarget)
      this.$router.push(`/resource`)
    }
  }
}
</script>

<style lang="scss" scoped>
.comment {
  position: relative;
  // background: #000;
  border-top-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;

  //没有评论数，三角+下面空白需要隐藏
  &.black {
    background: #333 !important;

    .comment-user-name {
    }

    .comment-user-text {
      color: #979797 !important;
    }
  }

  &.active {
    padding-bottom: 1.5rem;
  }

  .header {
    font-size: 0.26rem;
    text-align: left;
    padding: 0.3rem 0;
    margin: 0 0.3rem;
    display: flex;
    justify-content: space-between;
    .header-left {
    }
    .header-right {
      display: flex;
      align-items: center;
      color: #999999;
      .btn {
        &.active {
          color: #e8619d;
        }
      }
    }
  }
}

//  评论主体内容
.content-main {
  :deep() {
    div {
      min-height: 0 !important;
    }
  }
}
.content-main-wrap {
  // height: 6.5rem;
  overflow: auto;
  @include scrollbar-hide;

  .content-main {
    padding: 0;
    margin-top: 0.4rem;
  }
}

.comment-user-res {
  @include flexbox($jc: flex-start);
  font-size: 0.2rem;
  .card_name {
    border-radius: 0.04rem;
    background: linear-gradient(90deg, #00bbbc 0%, #0070c0 100%);
    padding: 0rem 0.12rem;
    margin-right: 0.08rem;
  }
}

// 展开回复
.comment-user-open {
  &-right {
    color: #ff8b00;
  }
}

// 提交按钮
.submit-box {
  position: fixed;
  width: 7.5rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  font-size: 0.3rem;
  background-color: #ffffff;
  box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;

  .submit-ref {
    height: 1.2rem;
    @include flexbox($jc: flex-start, $ai: center);
    background-color: rgba(0, 0, 0, 0.04);
    .cover {
      margin: 0 0.12rem 0 0.32rem;
      max-height: 1rem;
      max-width: 1.76rem;
      flex-shrink: 0;
      flex-grow: 0;
      overflow: hidden;
      border-radius: 0.08rem;
    }
    .title {
      flex-grow: 1;
      color: rgba(154, 154, 154, 1);
      font-size: 0.24rem;
      @include textoverflow(2);
    }
    .btn {
      margin-left: 0.1rem;
      @include flex-center;
      flex-shrink: 0;
      width: 1.2rem;
      height: 100%;
      color: #ffffff;
      background: $gradientBg;
    }
  }
  // 评论发送按钮
  .submit-input {
    position: relative;
    @include flex-align-center;
    margin: 0.3rem auto;
    font-size: 0.26rem;

    .add_img_box {
      margin-left: 0.28rem;
      width: 0.54rem;
      height: 0.54rem;
    }

    .submit-txt {
      margin-left: 0.28rem;
      width: 0.54rem;
      height: 0.54rem;
    }

    :deep(.van-cell) {
      margin-left: 0.32rem;
      border-radius: 0.32rem;
      padding: 0.1rem 0.2rem;
      width: 5.1rem;
      height: 0.64rem;
      background-color: rgba(0, 0, 0, 0.06);
      .van-field__control {
        text-indent: 0.2rem;
      }
    }
  }

  // 开通会员提示按钮
  .submit-btn {
    color: #333;
    background-color: $btnBg;
    width: 6rem;
    height: 0.68rem;
    line-height: 0.68rem;
    text-align: center;
    margin: 0.3rem auto;
    border-radius: 0.5rem;
  }
}

// .color {
//   color: #333;
// }

// 图片组件上传
.img-load-pup {
  @include flexbox($fd: column, $jc: flex-start);
  padding-top: 0.96rem;
  background-color: #292929;
  bottom: 1.35rem;
  .top-box {
    ul {
      @include flex-align-center;

      li {
        margin-right: 0.3rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .load-img-item {
        width: 1.9rem;
        height: 1.9rem;
        flex-shrink: 0;
        position: relative;

        :deep() {
          .warp {
            overflow: visible;
          }
        }

        .close-btn {
          position: absolute;
          right: 0;
          top: -0.14rem;
          width: 0.28rem;
          height: 0.28rem;
        }
      }
    }
  }

  .pic1 {
    width: 0.88rem;
    height: 0.80001rem;
  }

  .load-btn {
    display: flex;
    width: 2.8rem;
    height: 0.64rem;
    padding: 0.22rem 0.84rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.08rem;
    background: #f9c006;
    margin: 0.49rem 0;
  }

  .tip-desc {
    color: #999;
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 400;
    @include flex-align-center;

    img {
      width: 0.192rem;
      height: 0.24rem;
      margin-right: 0.06rem;
    }
  }
}

// 图片列表
.img-msg {
  @include flex-align-center;
  margin-top: 0.16rem;

  li {
    margin-right: 0.16rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &-item {
    width: 1.8rem;
    height: 1.8rem;
  }
}
</style>
