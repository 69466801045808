<template>
  <div>
    <EBackTop title="图片列表" :open-fallback="true"></EBackTop>
    <ul class="list_wrap" v-if="imglist && imglist.length > 0">
      <li v-for="(item, index) in imglist" :key="item.id" @click="handleClick(index)">
        <EDecryptImg :imgCDN="imgCDN" class="bg-img" :imgURL="item" />
      </li>
      <!-- <li></li>
      <li></li> -->
    </ul>
  </div>
</template>

<script>
import { photo_station_detail } from '@/api/discover'
export default {
  data() {
    return {
      imglist: [],
      pData: {}
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  created() {
    this.getList()
  },
  methods: {
    handleClick(index) {
      this.$router.push({ path: '/discover/preview', query: { pData: this.pData, index } })
    },
    async getList() {
      try {
        const res = await photo_station_detail({
          id: +this.$route.query.id
        })
        this.imglist = res.data.pictures
        this.pData = res.data
        this.$store.dispatch('setPhotoList', {
          type: 'add',
          item: this.pData
        })
      } catch (error) {
        this.$toast('请求错误，请稍后再试')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list_wrap {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  padding: 0.2rem;
  li {
    width: 32%;
    height: 3rem;
    border-radius: 0.1rem;
    overflow: hidden;
    margin-bottom: 0.2rem;
    margin-right: 0.14rem;
  }
  > li:nth-child(3n + 3) {
    margin-right: 0;
  }
}
</style>
