<template>
  <div class="content-main">
    <EDecryptImg
      class="user-header"
      :imgCDN="imgCDN"
      :imgURL="data.userAvatar"
      @click.native="$router.push(`/community/info/?id=${data.userId}`)"
    />
    <!-- 第一层用户 -->
    <div class="comment-user">
      <div class="first-comment">
        <div class="comment-user-name">
          <div class="comment-user-name-line">
            <div class="comment-user-name-text">
              <span>{{ data.userName }}</span>
              <template v-if="data.replyUserName">
                <span style="margin: 0 0.08rem">回复了</span><span>{{ data.replyUserName }}</span>
              </template>
              <span v-else-if="data.vipType > 0" class="vip-flag">
                <img src="@/assets/imgs/vip.svg" alt />
              </span>
            </div>
          </div>
          <div class="comment-content">
            <!-- 图片 -->
            <ul class="img-msg">
              <li v-for="(imgdata, index) in data.imageUrls" :key="index">
                <EDecryptImg
                  ref="refImg"
                  :imgCDN="imgCDN"
                  :imgRadius="'0.12rem'"
                  class="img-msg-data"
                  :imgURL="imgdata"
                  @click.native="imagePreview(data.imageUrls, index)"
                ></EDecryptImg>
              </li>
            </ul>
            <!--文字-->
            <p class="comment-user-text">{{ data.text }}</p>
            <div v-if="data.refObject.refType" class="ref-content">
              <EDecryptImg :img-u-r-l="data.refObject.cover" class="cover" object-fit="cover" />
              <div class="title">{{ data.refObject.title }}</div>
            </div>
          </div>
        </div>
        <!-- 回复按钮，时间 操作拦 -->
        <div class="comment-opr">
          <p class="comment-user-time">{{ data.createdAt | timeDiff }}</p>
          <!-- 回复 -->
          <div class="comment-opr-group">
            <div class="comment" @click.stop="clickRespondText(data)"></div>
            <div class="like" :class="data.isLike ? '' : 'no'" @click="likeComment(data)"></div>
            <div class="like-text" :class="data.isLike ? '' : 'no'">{{ data.likes }}</div>
          </div>
        </div>
        <div class="comment-opr1">
          <div>
            <!--更多回复按钮-->
            <div v-if="showExpendBtn && !isExpend" class="expand-reply" @click="toExpend">
              展开{{ data.subList.length }}条回复
            </div>
          </div>
          <div>
            <div v-if="last" class="fold" @click="toFold">
              <span>收起</span>
              <img src="@/assets/imgs/community/up.svg" alt />
            </div>
          </div>
        </div>

        <div v-if="showExpendBtn && isExpend" class="commend-replay">
          <comment-card
            v-for="(sdata, index) in data.subList"
            :key="sdata.id"
            :show-fold="true"
            :data="sdata"
            :last="index + 1 === data.subList.length"
            :publisher="{}"
            @on-reply="clickRespondText"
            @on-fold="isExpend = false"
          ></comment-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { handleVerAutoImg } from '@/utils/utils_tools'
import { collect } from '@/api/home.js'
import { ImagePreview } from 'vant'

export default {
  name: 'CommentCard',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    publisher: {
      type: Object,
      default: () => {}
    },
    showFold: {
      type: Boolean,
      default: false
    },
    last: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExpend: false
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    showExpendBtn() {
      return this.data?.subList?.length
    }
  },
  methods: {
    // 图片预览
    imagePreview(imgArr, index) {
      let domArr = []
      let realUrl = ''
      imgArr.forEach(async item => {
        realUrl = await handleVerAutoImg(`${this.imgCDN}${item}`)
        domArr.push(realUrl)
      })
      ImagePreview({
        images: domArr, //需要预览的图片 URL 数组
        showIndex: true, //是否显示页码
        loop: true, //是否开启循环播放
        startPosition: index, //图片预览起始位置索引

        closeable: true
      })
    },
    //展开回复
    toExpend() {
      this.isExpend = true
    },
    //展开回复
    toFold() {
      this.$emit('on-fold')
    },
    // 点击回复按钮
    clickRespondText(item) {
      this.$emit('on-reply', item)
    },
    // 关闭弹窗
    close() {
      this.showType = false
      this.$emit('close', this.showType)
    },
    async likeComment(data) {
      let param = {
        collectType: 16,
        flag: !data.isLike,
        isCollet: true,
        object_id: data.id,
        type: 2
      }
      await collect(param).then(res => {
        if (res.code === 200) {
          data.isLike = !data.isLike
          data.likes = data.isLike ? data.likes + 1 : Math.max(data.likes - 1, 0)
        } else {
          this.$toast(res.tip)
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.content-main {
  display: flex;
  padding: 0.32rem 0.24rem;
  box-sizing: border-box;
  max-width: 100%;

  &:last-child {
    border-bottom: 0;
  }

  .user-header {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    margin-right: 0.28rem;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .comment-user {
    flex-grow: 1;
    overflow: hidden;
    .first-comment {
      font-size: 0.22rem;
      margin-top: 0.1rem;

      .comment-user-name {
        font-size: 0.3rem;

        .comment-user-name-line {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .comment-user-name-text {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 0.28rem;
            .vip-flag {
              margin-left: 0.18rem;
              width: 0.74rem;
              height: 0.36rem;
              background-color: rgba(255, 237, 210, 1);
              border-radius: 0.38rem;
              @include flex-center;
              display: inline-flex;
              > img {
                width: 0.42rem;
                height: 0.36rem;
              }
            }
          }
        }
      }

      .comment-content {
        margin: 0.16rem 0;
        line-height: 0.4rem;
        .comment-user-text {
          max-width: 100%;
          word-break: break-all;
          word-wrap: break-word;
          opacity: 0.8;
          font-size: 0.24rem;
          display: flex;
          align-items: center;
          img {
            height: 0.22rem;
            margin: 0 0.04rem;
          }
        }
        .ref-content {
          @include flexbox($jc: flex-start, $ai: center);
          background-color: rgba(0, 0, 0, 0.04);
          width: 100%;
          height: 0.96rem;
          padding: 0 0.24rem 0 0;
          .cover {
            margin-right: 0.24rem;
            max-height: 1rem;
            max-width: 1.76rem;
            flex-shrink: 0;
            flex-grow: 0;
            overflow: hidden;
          }
          .title {
            flex-grow: 1;
            color: rgba(154, 154, 154, 1);
            font-size: 0.24rem;
            //overflow: hidden;
            //text-overflow: ellipsis;
            //white-space: nowrap;
            @include textoverflow(2);
          }
        }
      }

      .comment-opr {
        height: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .comment-user-time {
          font-size: 0.24rem;
          margin: 0 0.3rem 0 0;
          color: #999999;
        }
        .comment-opr-group {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          .comment {
            width: 0.4rem;
            height: 0.4rem;
            background: url('~@/assets/imgs/comment_icon.png') center 66%/0.28rem 0.23rem no-repeat;
          }
          .like {
            margin-left: 0.16rem;
            width: 0.4rem;
            height: 0.4rem;
            background: url('~@/assets/imgs/like.svg') center center/0.28rem 0.26rem no-repeat;
            &.no {
              background: url('~@/assets/imgs/unlike.svg') center center/0.28rem 0.26rem no-repeat;
            }
          }
          .like-text {
            font-size: 0.24rem;
            font-weight: 400;
            color: red;
            &.no {
              color: #999999;
            }
          }
        }
      }
      .comment-opr1 {
        margin-top: 0.16rem;
        margin-bottom: 0.22rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .expand-reply {
          color: rgba(255, 126, 0, 1);
          font-size: 0.24rem;
          line-height: 0.4rem;
        }
        .fold {
          @include flex-center;
          color: #ffffff;
          background-color: rgba(0, 0, 0, 0.2);
          width: 1.02rem;
          height: 0.44rem;
          border-radius: 0.4rem;
        }
      }

      // 第二层样式
      .commend-replay {
        border-radius: 0.24rem;
        background-color: rgba(0, 0, 0, 0.02);
        .commend-replay-line {
          width: 100%;
          border-bottom: 0.01rem solid rgba(225, 225, 225, 1);
        }
      }
    }
  }
}
:deep() {
  .van-image-preview__overlay {
    z-index: 99999 !important;
  }

  .van-image-preview {
    z-index: 100000 !important;
  }
}
</style>
