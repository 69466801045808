<template>
  <div>
    <EWaterFall :datas-list="listData" class="waterfalls">
      <template #left="{ data }">
        <ItemInWaterfall :item="data" />
      </template>
      <template #right="{ data }">
        <ItemInWaterfall :item="data" />
      </template>
    </EWaterFall>
  </div>
</template>

<script>
export default {
  components: {
    EWaterFall: () => import('@/components/EWaterFall/index.vue'),
    ItemInWaterfall: () => import('./itemInWaterfall.vue')
  },
  props: {
    listData: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.waterfalls {
  padding: 0.12rem;
  :deep() {
    .left {
      padding: 0 0.05rem 0 0 !important;
    }
    .right {
      padding: 0 0 0 0.05rem !important;
    }
  }
}
</style>
