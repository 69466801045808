<template>
  <div>
    <EBackTop :title="'修改头像'" @click-right="checkRight">
      <slot slot="right" name="right">
        <div style="color: #999">保存</div>
      </slot>
    </EBackTop>
    <div class="nickName_setting">
      <h1>{{ info.nickName }}</h1>
      <!-- 输入任意文本 -->
      <van-field
        v-model="nickName"
        :right-icon="nickName ? 'clear' : ''"
        placeholder="输入新的昵称"
        @click-right-icon="checkClear"
      />
    </div>
  </div>
</template>

<script>
import { update_info } from 'api/user'
export default {
  data() {
    return {
      nickName: undefined
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    info({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  methods: {
    // 完成按钮
    async checkRight() {
      if (!this.nickName) return this.$toast('请输入新的昵称！')
      if (this.isMember) {
        const res = await update_info({
          nickName: this.nickName
        })
        if (res.code === 200) {
          this.$toast('修改成功')
        } else {
          this.$toast(res.tip)
        }
      } else {
        this.$toast('亲，您还不是会员，不能修改噢')
      }
    },
    checkClear() {
      this.nickName = undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.nickName_setting {
  margin: 0.76rem 0.32rem 0 0.32rem;
  h1 {
    font-weight: 600;
    font-size: 0.48rem;
  }
}
:deep() {
  .van-field {
    padding: 0;
    color: #666 !important;
    border-bottom: 0.01rem solid #343434;
    padding-bottom: 0.22rem;
    margin-top: 0.7rem;
    font-size: 0.36rem;
    .van-field__control {
      color: #666 !important;
    }
  }
}
</style>
