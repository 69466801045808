<template>
  <div class="masks">
    <EBackTop
      v-if="lockStatus"
      title="密码锁"
      :background="'transparent'"
      :color="'#fff'"
      :hasSuperiorClick="true"
      @goBack="$store.dispatch('gestureLock/setShowGestureLock', false)"
    >
    </EBackTop>
    <div class="gesturePwd">
      <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0">
        <h4 ref="gestureTitle" class="gestureTitle">请绘制锁屏图形</h4>
        <canvas ref="canvas" class="canvas" style=""></canvas>
        <p v-if="pswObj.lockStatus === 2" class="customer" @click="settingKf">忘记密码，联系客服</p>
      </div>
    </div>
  </div>
</template>

<script>
import { setUserLockscreencode } from 'api/user'
export default {
  components: {},
  data() {
    return {
      currentValue: false,
      ctx: '',
      width: 0,
      height: 0,
      devicePixelRatio: 0,
      chooseType: 3,
      r: '', // 圆半径
      lastPoint: [],
      arr: [],
      restPoint: [],
      pswObj: {
        lockStatus: 2, //0:初次输入密码 1:验证密码阶段 2:已经设置完成
        spassword: undefined
      },
      canvas: ''
    }
  },
  computed: {
    showGestureLock({ $store }) {
      return $store.getters['gestureLock/getShowGestureLock']
    },
    openGestureLock({ $store }) {
      return $store.getters['gestureLock/getOpenGestureLock']
    },
    lockStatus({ $store }) {
      return $store.getters['gestureLock/getLockStatus']
    }
  },

  created() {
    // if (typeof this.value !== 'undefined') {
    //   this.currentValue = this.value;
    // }
  },
  mounted() {
    this.init()
  },
  methods: {
    settingKf() {
      this.$emit('closeEvent')
      this.$router.push({
        path: '/mine/setting/kf',
        query: {
          isLocked: false
        }
      })
      this.$store.dispatch('setShowGestureLock', false)
      this.$store.dispatch('user/setHomeSiwper', false)
    },
    drawCircle(x, y) {
      // 初始化解锁密码面板 小圆圈
      this.ctx.strokeStyle = '#FFFFFF' //密码的点点默认的颜色
      this.ctx.lineWidth = 2 * this.devicePixelRatio
      this.ctx.beginPath()
      this.ctx.arc(x, y, this.r, 0, Math.PI * 2, true)
      this.ctx.closePath()
      this.ctx.stroke()
    },

    // 初始化圆心
    drawPoint(style) {
      for (var i = 0; i < this.lastPoint.length; i++) {
        this.ctx.fillStyle = style
        this.ctx.beginPath()
        this.ctx.arc(this.lastPoint[i].x, this.lastPoint[i].y, this.r / 2, 0, Math.PI * 2, true)
        this.ctx.closePath()
        this.ctx.fill()
      }
    },
    // 初始化状态线条
    drawStatusPoint(type) {
      for (var i = 0; i < this.lastPoint.length; i++) {
        this.ctx.strokeStyle = type
        this.ctx.beginPath()
        this.ctx.arc(this.lastPoint[i].x, this.lastPoint[i].y, this.r, 0, Math.PI * 2, true)
        this.ctx.closePath()
        this.ctx.stroke()
      }
    },
    //style:颜色 初始点位，结束点位
    drawLine(style, po, lastPoint) {
      this.ctx.beginPath()
      this.ctx.strokeStyle = style
      this.ctx.lineWidth = 3 * this.devicePixelRatio
      this.ctx.moveTo(this.lastPoint[0].x, this.lastPoint[0].y)

      for (var i = 1; i < this.lastPoint.length; i++) {
        this.ctx.lineTo(this.lastPoint[i].x, this.lastPoint[i].y)
      }
      this.ctx.lineTo(po.x, po.y)
      this.ctx.stroke()
      this.ctx.closePath()
    },

    // 创建解锁点的坐标，根据canvas的大小来平均分配半径
    createCircle() {
      var n = this.chooseType
      var count = 0
      //计算圆半径
      this.r = this.ctx.canvas.width / (2 + 3 * n)
      this.lastPoint = []
      this.arr = []
      this.restPoint = []
      var r = this.r
      //算出每个圆的中心点坐标
      for (var i = 0; i < n; i++) {
        for (var j = 0; j < n; j++) {
          count++
          var obj = {
            x: j * 3.5 * r + 2 * r,
            y: i * 3.5 * r + 2 * r,
            index: count
          }
          this.arr.push(obj)
          this.restPoint.push(obj)
        }
      }
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)

      for (var i = 0; i < this.arr.length; i++) {
        this.drawCircle(this.arr[i].x, this.arr[i].y)
      }

      //return arr;
    },
    getPosition(e) {
      // 获取touch点相对于canvas的坐标
      var rect = e.currentTarget.getBoundingClientRect()
      var po = {
        x: (e.touches[0].clientX - rect.left) * this.devicePixelRatio,
        y: (e.touches[0].clientY - rect.top) * this.devicePixelRatio
      }
      return po
    },
    update(po) {
      // 核心变换方法在touchmove时候调用
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)

      for (var i = 0; i < this.arr.length; i++) {
        // 每帧先把面板画出来
        this.drawCircle(this.arr[i].x, this.arr[i].y)
      }

      this.drawPoint('#EAEAEA') // 每帧画轨迹
      this.drawStatusPoint('#EAEAEA') // 每帧画轨迹
      this.drawLine('#EAEAEA', po, this.lastPoint) // 每帧画圆心

      for (var i = 0; i < this.restPoint.length; i++) {
        if (Math.abs(po.x - this.restPoint[i].x) < this.r && Math.abs(po.y - this.restPoint[i].y) < this.r) {
          this.drawPoint(this.restPoint[i].x, this.restPoint[i].y)
          this.lastPoint.push(this.restPoint[i])
          this.restPoint.splice(i, 1)
          break
        }
      }
    },
    // 对比两次密码是否相同
    checkPass(psw1, psw2) {
      var p1 = '',
        p2 = ''
      for (var i = 0; i < psw1.length; i++) {
        p1 += psw1[i].index + psw1[i].index
      }
      for (var i = 0; i < psw2.length; i++) {
        p2 += psw2[i].index + psw2[i].index
      }
      return p1 === p2
    },
    // touchend结束之后对密码和状态的处理
    async storePass(psw) {
      if (this.pswObj.lockStatus == 1) {
        if (this.checkPass(this.pswObj.fpassword, psw)) {
          //   this.pswObj.lockStatus = 2;
          this.pswObj.spassword = psw
          let setParam = ''
          this.pswObj.spassword.forEach(item => {
            setParam = setParam + item.index
          })
          this.$store.dispatch('setShowGestureLock', false)
          //两次密码相同，存储密码
          let res = await setUserLockscreencode({ code: setParam })
          if (res.code === 200) {
            window.localStorage.setItem('hs_lockPsw', JSON.stringify(this.pswObj.spassword))
            window.localStorage.setItem('chooseType', this.chooseType)
            this.$store.dispatch('setShowGestureLock', false)
            this.setTitle('密码保存成功', '#2CFF26')
          } else {
            this.setTitle('密码保存失败，请重试', 'red')
          }
        } else {
          this.setTitle('两次不一致，重新输入', 'red')
          this.pswObj.lockStatus = 0
        }
      } else if (this.pswObj.lockStatus == 2) {
        if (this.checkPass(this.pswObj.spassword, psw)) {
          if (this.lockStatus) {
            this.$store.dispatch('gestureLock/setOpenGestureLock', !this.openGestureLock)
          }
          this.$store.dispatch('setShowGestureLock', false)
          this.$emit('closeEvent')
          this.setTitle('解锁成功', '#2CFF26')
        } else {
          this.setTitle('密码错误，请重试', 'red')
        }
      } else {
        if (psw.length < 4) {
          this.setTitle('请连接最少4个点', 'red')
        } else {
          this.pswObj.lockStatus = 1
          this.pswObj.fpassword = psw
          this.setTitle('请确认锁屏图形', '#fff')
        }
      }
    },
    makeState() {
      if (this.pswObj.lockStatus == 2) {
        this.setTitle('请解锁', '#fff')
      }
    },

    //改变头部文字和颜色 还有线条 圆点等颜色
    setTitle(text, color) {
      var gestureTitle = this.$refs.gestureTitle
      gestureTitle.style.color = color
      gestureTitle.innerHTML = text
      if (this.ctx) {
        this.drawStatusPoint(color)
        this.drawPoint(color)
        this.drawLine(color, this.lastPoint[this.lastPoint.length - 1], this.lastPoint) // 每帧画圆心
      }
    },

    //初始化canvas
    initDom() {
      this.devicePixelRatio = window.devicePixelRatio || 1
      var canvas = this.$refs.canvas
      //设置canvas宽高
      var width = 100 * ((window.innerWidth > 540 ? 540 : window.innerWidth) / 750) * 7.5
      var height = 100 * ((window.innerWidth > 540 ? 540 : window.innerWidth) / 750) * 7.5
      // 高清屏锁放
      canvas.style.width = width + 'px'
      canvas.style.height = height + 'px'
      canvas.height = height * this.devicePixelRatio
      canvas.width = width * this.devicePixelRatio
    },
    init() {
      this.initDom()
      this.pswObj = window.localStorage.getItem('hs_lockPsw')
        ? {
            lockStatus: 2,
            spassword: JSON.parse(window.localStorage.getItem('hs_lockPsw'))
          }
        : {
            lockStatus: 0,
            spassword: undefined
          }
      this.lastPoint = []
      this.makeState()
      this.touchFlag = false
      this.canvas = this.$refs.canvas
      this.ctx = this.canvas.getContext('2d')
      this.createCircle()
      this.bindEvent()
    },
    reset() {
      this.makeState()
      this.createCircle()
    },
    bindEvent() {
      var self = this
      this.canvas = this.$refs.canvas
      this.canvas.addEventListener(
        'touchstart',
        function (e) {
          e.preventDefault() // 某些android 的 touchmove不宜触发 所以增加此行代码
          var po = self.getPosition(e)

          for (var i = 0; i < self.arr.length; i++) {
            if (Math.abs(po.x - self.arr[i].x) < self.r && Math.abs(po.y - self.arr[i].y) < self.r) {
              self.touchFlag = true
              self.drawPoint(self.arr[i].x, self.arr[i].y)
              self.lastPoint.push(self.arr[i])
              self.restPoint.splice(i, 1)
              break
            }
          }
        },
        false
      )
      this.canvas.addEventListener(
        'touchmove',
        function (e) {
          if (self.touchFlag) {
            self.update(self.getPosition(e))
          }
        },
        false
      )
      this.canvas.addEventListener(
        'touchend',
        function (e) {
          if (self.touchFlag) {
            self.touchFlag = false
            self.storePass(self.lastPoint)

            setTimeout(function () {
              self.reset()
            }, 1000)
          }
        },
        false
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.masks {
  text-align: center;
  position: fixed;
  z-index: 10001;
  width: 100%;
  height: 100vh;
  max-width: 540px;
  left: 50%;
  transform: translateX(-50%);

  .gesturePwd {
    position: fixed;
    z-index: 88;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #666;
    text-align: center;
    overflow: hidden;
    background-image: url('/GestureLockBg.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    > div {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      .gestureTitle {
        color: #87888a;
        margin-top: 1.7rem;
        font-size: 0.4rem;
        font-weight: normal;
      }
      .canvas {
        background-color: transparent;
        display: inline-block;
        margin-top: 1.52rem;
      }
      .customer {
        color: #87888a;
        margin-top: 1rem;
        font-size: 0.2rem;
      }
    }
  }
}
</style>
