<template>
  <div class="short_video" @click="toShortVideo">
    <div class="cover">
      <EDecryptImgNolazy :imgURL="item.coverImg" class="cover-img"> </EDecryptImgNolazy>
      <div class="video_card_mask">{{ item.title }}</div>
      <!-- <VideoCardMask :data="item"></VideoCardMask> -->
    </div>
    <!-- <VideoCardFooter :data="item"></VideoCardFooter> -->
  </div>
</template>

<script>
export default {
  components: {
    VideoCardFooter: () => import('@/components/Home/VideoCardFooter.vue'),
    VideoCardMask: () => import('@/components/Home/VideoCardMask.vue')
  },
  props: ['item', 'list'],
  data() {
    return {}
  },
  mounted() {},
  methods: {
    // 跳转短视频播放页
    toShortVideo() {
      const index = this.list.findIndex(item => item.id === this.item.id)
      this.$store.commit('SET_VIDEOLIST', this.list)
      this.$store.commit('SET_SHORT_VIDEO_INDEX', index)
      this.$router.push({
        path: '/play/shortVideo'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.short_video {
  width: 2.14rem;
  height: 3.8rem;
  position: relative;
  margin-bottom: 0.32rem !important;
  &:nth-child(2n + 2) {
    margin-right: 0;
  }
  &:nth-last-child(1),
  &:nth-last-child(2) {
    margin-bottom: 0;
  }

  .cover {
    position: relative;
    height: 100%;
    border-radius: 0.12rem;
    overflow: hidden;
    min-height: 0.2rem;
    .video_card_mask {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 0.78rem;
      padding: 0.1rem 0.12rem;
      font-size: 0.22rem;
      font-weight: 400;
      color: #fff;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      border-radius: 0 0 0.12rem 0.12rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
