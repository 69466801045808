<template>
  <van-overlay :show="show" :lock-scroll="false">
    <div class="release">
      <div class="header">
        <img src="@/assets/imgs/community/close.svg" class="close" alt="" @click.stop="toConfirm" />
        <div class="title">选择标签</div>
        <div class="count">{{ active ? 1 : 0 }}/1</div>
      </div>
      <ul>
        <li
          v-for="item in showTagList"
          :key="item.id"
          :class="active === item.id ? 'active' : ''"
          @click="checkItem(item)"
        >
          <EDecryptImg :img-c-d-n="imgCDN" class="resetImg" :img-u-r-l="item.imageUrl"> </EDecryptImg>
          <div class="center">
            <p class="title">{{ item.name }}</p>
            <div class="desc">
              <span>{{ item.totalPost | numberFilter }}个帖子</span>
              <span>{{ item.totalWatches | numberFilter }}个浏览</span>
              <span>{{ item.totalCollects | numberFilter }}个关注</span>
            </div>
          </div>
          <div class="right" :class="active === item.id ? 'active' : ''">
            {{ active === item.id ? '已选择' : '选择' }}
          </div>
        </li>
      </ul>
    </div>
  </van-overlay>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: null
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    postSection({ $store }) {
      return $store.getters['postSection']
    },

    showTagList() {
      // const { cid } = this.$route.query
      return this.postSection
    }
  },
  mounted() {},

  methods: {
    checkItem(item) {
      this.active = item.id
      this.$emit('input', item)
      this.$emit('update:show', false)
    },
    toConfirm() {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.release {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 0 0.32rem;
    height: 1rem;
    z-index: 2;
  }
  ul {
    background-color: #ffffff;
    padding: 1rem 0.32rem;
    li {
      border-radius: 0.16rem;
      padding: 0.28rem 0.24rem;
      @include flexbox($jc: space-between);
      margin-bottom: 0.24rem;
      background-color: rgba(0, 0, 0, 0.03);
      .resetImg {
        width: 0.76rem;
        height: 0.76rem;
        margin-right: 0.24rem;
        border-radius: 0.06rem;
        overflow: hidden;
      }

      .center {
        flex-grow: 1;
        .title {
          font-size: 0.32rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .desc {
          color: #999999;
          font-size: 0.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          > span {
            margin-right: 0.2rem;
          }
        }
      }

      .right {
        background-color: #666666;
        border-radius: 0.26rem;
        width: 1.04rem;
        height: 0.52rem;
        color: #ffffff;
        @include flex-center;
        &.active {
          background: $gradientBg;
        }
      }
    }
  }
}
</style>
