<template>
  <div class="md-video-play">
    <div id="dplayer" ref="dplayer"></div>
    <transition name="van-fade">
      <div v-if="!hasPreview && newVideoInfo.preVideoUrl" class="play-tip" @click="skipPreview">跳過預覽</div>
    </transition>
  </div>
</template>
<script>
import { isWhiteList } from '@/components/web_base/utils_base/EUtils/utils_tools.js'
const HlsJsPlayer = require('xgplayer-hls.js')
export default {
  name: 'JavVideo',
  inject: ['reload'],
  props: {
    videoInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    // 预览
    path1: {
      type: String,
      default() {
        return '/api/app/media/m3u8/'
      }
    },
    // 正片
    path2: {
      type: String,
      default() {
        return '/api/app/media/m3u8ex/'
      }
    },
    // 是否可以观看
    hasPreview: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      lstTime: 0,
      dp: null,
      newVideoInfo: {},
      isWhiteList: false
    }
  },
  computed: {
    token({ $store }) {
      return $store.state.user.token
    },
    // 播放视频url 如果有预览地址则播放预览地址，没有就播放
    url({ path1, hasPreview, path2, newVideoInfo }) {
      if (hasPreview) {
        return path2 + newVideoInfo.id
      } else {
        return newVideoInfo.preVideoUrl ? path1 + newVideoInfo.preVideoUrl : path1 + newVideoInfo.videoUrl
      }
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  watch: {
    videoInfo: {
      handler(n, o) {
        this.newVideoInfo = n
        if (n && o && n.id !== o.id) {
          this.dp.currentTime = 0
          this.dp.src = this.url + `?token=${this.token}`
        }
      },
      immediate: true,
      deep: true
    }
  },

  async mounted() {
    this.isWhiteList = isWhiteList(this.hasPreview)
    if (!this.isWhiteList) {
      this.$emit('skipPreview', !this.hasPreview)
    } else {
      this.$nextTick(() => {
        if (this.newVideoInfo.id) {
          const that = this
          if (this.dp) {
            this.dp.destroy(true)
            this.dp = null
          }

          this.dp = new HlsJsPlayer({
            id: 'dplayer', //容器ID
            lang: 'zh-cn',
            width: '100%',
            height: '4.16rem',
            autoplayMuted: true,
            autoplay: true, // 是否自动播放
            volume: 0.3, // 音量
            loop: false, // 循环
            pic: true, // 画中画
            miniplayer: false, // 迷你播放
            controls: true,
            cssFullscreen: false,
            videoInit: false, // 初始化视频首帧
            playbackRate: [0.5, 0.75, 1, 1.5, 2], // 倍速
            lastPlayTime: 0, // 记忆播放，从多少多少秒开始
            lastPlayTimeHideDelay: 5, //提示文字展示时长（单位：秒）
            rotateFullscreen: false, // 使得视频全屏幕时 横屏
            download: false, //设置download控件显示
            enableVideoDbltouch: true,
            airplay: true,
            playsinline: true,
            // isLive: true, //直播场景设置为true
            // useHls: true,
            preloadTime: 10,
            // url: "https://admin.yejjhsd.com/api/web/media/m3u8/av/cu/61/vb/ku/0b81c5ce442e41f3b7c0aa9ef50ec72a.m3u8",
            url: this.url + `?token=${this.token}`,
            // 开启弹幕
            danmu: {
              closeDefaultBtn: true
            },
            execBeforePluginsCall: [
              () => {
                console.log('Execute before plugins call')
              }
            ],

            'x5-video-player-type': 'h5', //微信同层播放：
            'x5-video-player-fullscreen': 'true', //微信全屏播放：
            'x5-video-orientation': 'landscape' //微信横竖屏控制
          })

          // 如果预览时长为0，则直接提示
          this.dp.on('play', () => {
            if (this.danmakuList && this.danmakuList.length) {
              this.dp.danmu.play()
              if (!this.hasPreview && !this.newVideoInfo.preVideoUrl) {
                if (this.newVideoInfo.preTime === 0) {
                  this.dp.pause()
                  this.$emit('skipPreview', !this.hasPreview)
                }
              }
            }
          })
          // 如果是预告片播放结束给出购买提示
          this.dp.on('ended', () => {
            // 当不可播放完整视频时，如果播放时长大于等于预览市场，给出购买提示
            if (!this.hasPreview && this.newVideoInfo.preVideoUrl) {
              this.$emit('skipPreview', !this.hasPreview)
            }
          })

          // 获取当前视频播放时间
          this.dp.on('timeupdate', () => {
            // 当不可播放完整视频时，如果播放时长大于等于预览时才，给出购买提示
            if (!this.hasPreview && !this.newVideoInfo.preVideoUrl) {
              const curTime = this.dp.currentTime
              if (this.newVideoInfo.preTime === 0) {
                this.dp.pause()
                this.$emit('skipPreview', !this.hasPreview)
              } else if (curTime >= this.newVideoInfo.preTime) {
                this.dp.currentTime = 0
                this.dp.pause()
                this.$emit('skipPreview', !this.hasPreview)
              }
            }
          })
          this.dp.on('seeking', () => {
            // 当不可播放完整视频时，如果播放时长大于等于预览时才，给出购买提示
            if (!this.hasPreview && !this.newVideoInfo.preVideoUrl) {
              this.dp.currentTime = 0
            }
          })
        }
      })
    }
  },
  beforeDestroy() {
    this.$emit('currentTime', this.dp.currentTime) //记录历史时间
    if (this.newVideoInfo.id) {
      if (this.dp) {
        if (this.dp.hls) {
          this.dp.hls.destroy()
        }
        this.dp.src = ''
        this.dp.destroy()
      }
    }
  },

  methods: {
    skipPreview() {
      this.dp.pause()
      this.$emit('skipPreview', !this.hasPreview)
    },
    skipVideoPlay() {
      if (!this.isWhiteList) {
        this.reload()
      }

      if (!this.dp) {
        this.dp.start(this.path2 + this.newVideoInfo.id + `?token=${this.token}`)
      } else {
        this.dp.src = this.path2 + this.newVideoInfo.id + `?token=${this.token}`
      }
      this.dp.play()
    }
  }
}
</script>

<style lang="scss" scoped>
.md-video-play {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: $pcMaxWidth;
  z-index: 199;
  height: 4.16rem;
  @include transformCenter(-50%, 0);

  #dplayer {
    width: 100%;
    height: 100%;
    max-width: $pcMaxWidth;
    background-color: #000;
  }

  .play-tip {
    position: absolute;
    right: 0.2rem;
    bottom: 1rem;
    width: 1.5rem;
    text-align: center;
    height: 0.5rem;
    line-height: 0.5rem;
    border-radius: 0.2rem;
    background-color: rgb(36, 34, 34);
    color: #fff;
    font-size: 0.22rem;
    z-index: 9999;
  }

  :deep() {
    .dplayer-full-in-icon:first-child {
      display: none !important;
    }

    .xgplayer-enter-spinner {
      height: 1rem !important;
      width: 1rem !important;
    }

    .dplayer-full-icon:first-child {
      display: none !important;
    }

    .dplayer-mobile-play {
      display: block !important;
    }
  }
}
</style>
