<template>
  <div class="waterFall">
    <div class="rowWrap">
      <div ref="waterFallTop" class="top">
        <div v-for="(item, index) in waterFallTopArr" :key="index">
          <slot name="top" :data="item" :handleLoad="handleLoad"></slot>
        </div>
      </div>
      <div ref="waterFallBtom" class="btom">
        <div v-for="(item, index) in waterFallBtomArr" :key="index">
          <slot name="btom" :data="item" :handleLoad="handleLoad"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EWaterFallH',
  components: {},
  props: {
    datasList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      // 左右数据
      waterFallTopArr: [],
      waterFallBtomArr: [],
      // 当前加载位置
      nowIndex: 0,
      list: []
    }
  },
  computed: {},
  watch: {
    'datasList.length': {
      handler(n, o) {
        this.list = this.datasList
        // console.log(n)
        //0，或者小于之前为刷新
        if (!n || n < o) {
          this.waterFallTopArr = []
          this.waterFallBtomArr = []
          this.nowIndex = 0
        } else {
          //进行高度计算
          this.handleLoad()
        }
      }
    }
  },
  mounted() {
    this.list = this.datasList
    if (this.list.length) {
      this.handleLoad()
    }
  },
  methods: {
    //计算宽度
    handleLoad() {
      let topWidth = this.$refs['waterFallTop'].offsetWidth
      let btomWidth = this.$refs['waterFallBtom'].offsetWidth
      // // 图片加载自动触发则+1（包括：timeOut，err，finish），目前：只有timeOut，开局全部加载
      if (topWidth > btomWidth && this.list[this.nowIndex]) {
        this.waterFallBtomArr.push(this.list[this.nowIndex])
      }
      if (btomWidth >= topWidth && this.list[this.nowIndex]) {
        // 特殊情况断网
        if (btomWidth == 0 && topWidth == 0 && this.waterFallTopArr.length >= 5) {
          this.waterFallTopArr = []
          this.waterFallBtomArr = []
        }
        this.waterFallTopArr.push(this.list[this.nowIndex])
      }
      this.nowIndex++
    }
  }
}
</script>
<style lang="scss" scoped>
.waterFall {
  height: 100%;
  box-sizing: border-box;

  .rowWrap {
    clear: both;
    overflow: auto;

    .top,
    .btom {
      width: max-content;
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      > div {
        height: 100%;
        overflow: hidden;
        flex-shrink: 0;
      }
    }
  }
}
</style>