<template>
  <div class="my_collect">
    <EBackTop title="我的购买">
      <!-- <slot slot="right" name="right">
        <div class="right-txt">
          <p v-if="!showEdit" class="edit" @click="showEdit = true">编辑</p>
          <div v-else-if="showEdit === true && checkItem" class="all_edit">
            <p>全选</p>
            <p>删除</p>
          </div>
          <div v-else class="close" @click="showEdit = false">取消</div>
        </div>
      </slot> -->
    </EBackTop>
    <ETabV2
      ref="tab"
      class="messages_tab"
      offset-top="1rem"
      :placeholder="false"
      background="#fff"
      :tabList="tabList"
      :activeId="type"
      :animated="false"
      lineSwitch
      :sticky="true"
      @change="changeId"
    >
      <!-- 列表数据 -->
      <template #default="scope">
        <component :is="compComponent(scope.item)" ref="childDom" :type="type" cardType="pay"> </component>
      </template>
    </ETabV2>
  </div>
</template>

<script>
export default {
  components: {
    cartoonListHistory: () => import('@/components/historyItems/cartoonListHistory.vue'),
    photoListHistory: () => import('@/components/historyItems/photoListHistory.vue'),
    comicsListHistory: () => import('@/components/historyItems/comicsListHistory.vue'),
    dreamListHistory: () => import('@/components/historyItems/dreamListHistory.vue')
  },
  data() {
    return {
      tabList: [
        { id: 13, name: '播单' },
        { id: 1, name: '视频' },
        { id: 5, name: '帖子' },
        { id: 9, name: '图集' },
        { id: 8, name: '漫画' },
        { id: 3, name: '动漫' },
        { id: 10, name: '有声小说' },
        { id: 22, name: '剧场' }
      ],
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 关注列表
      dataList: [],
      checkItem: false,
      // 当前页
      pageNum: 1,
      // 当前个数
      pageSize: 10,
      type: 1,
      showEdit: false,
      rightTitle: '编辑'
    }
  },
  methods: {
    changeId(type) {
      this.type = type
    },
    compComponent(item) {
      switch (item.id) {
        case 1:
        case 3:
          return 'cartoonListHistory'
        case 9:
          return 'photoListHistory'
        case 8:
          return 'comicsListHistory'
        case 22:
          return 'dreamListHistory'

        default:
          return 'comicsListHistory'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.right-txt {
  .edit {
    color: $txtActive;
  }
  .close {
    color: $grey;
  }
  .all_edit {
    @include flexbox($jc: flex-start);
    p {
      color: $txtActive;
      &:last-child {
        border-radius: 0.08rem;
        background: $txtActive;
        padding: 0.04rem 0.16rem;
        color: #fff;
      }
    }
  }
}
</style>
