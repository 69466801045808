<template>
  <div class="item" @click="handleClick">
    <EDecryptImgNolazy :imgURL="data.cover" @loadedImg="loadedImg"> </EDecryptImgNolazy>
    <div class="bottom">
      <div class="left">
        <img src="@/assets/imgs/discover/eyes.png" alt="" />
        <span>{{ data.watch.yearWatchs ? data.watch.yearWatchs : data.watches | numberFilter('w') }}</span>
      </div>
      <div class="right">
        <img src="@/assets/imgs/discover/photo-transparent.png" alt="" />
        <span>{{ data.picNum }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['data'],
  data() {
    return {}
  },

  methods: {
    loadedImg() {
      this.$emit('loadedImg')
    },
    handleClick() {
      console.log('this.data', this.data)
      this.$router.push({
        path: `/discover/imgpreview`,
        query: {
          id: this.data.id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.item {
  width: auto;
  height: 2.42rem;
  position: relative;
  ::v-deep {
    .default .realImg {
      width: auto;
    }
  }
}
.bottom {
  color: #fff;
  @include flexbox;
  padding: 0 0.12rem;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  padding-bottom: 0.16rem;
  img {
    margin-right: 0.06rem;
  }
  .left {
    @include flex-align-center;
    font-size: 0.2rem;
    flex: 1;
    img {
      width: 0.28rem;
      height: 0.28rem;
    }
  }
  .right {
    @include flex-center;
    padding: 0 0.08rem;
    border-radius: 0.08rem;
    background: rgba(0, 0, 0, 0.8);
    font-size: 0.2rem;
    flex: none;
    img {
      width: 0.28rem;
      height: 0.28rem;
    }
  }
}
</style>
