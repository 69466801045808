import { render, staticRenderFns } from "./videoList2.vue?vue&type=template&id=59b3c2bb&scoped=true&"
import script from "./videoList2.vue?vue&type=script&lang=js&"
export * from "./videoList2.vue?vue&type=script&lang=js&"
import style0 from "./videoList2.vue?vue&type=style&index=0&id=59b3c2bb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59b3c2bb",
  null
  
)

export default component.exports