<template>
  <div class="container-water-fall">
    <div class="rowWrap">
      <div ref="waterFallLeft" class="left">
        <div v-for="(item, index) in waterFallLeftArr" :key="index" class="cell-item">
          <div class="cart" @click="toPlay(item, datasList)">
            <DecryptImgLoad
              :style="{
                height: item.reset_high != 'auto' ? item.reset_high + 'px' : 'auto'
              }"
              :img-u-r-l="item.picture || item.videoCover || item.coverImg || item.cover"
              :item="item"
              class="img-box"
              @handleLoad="handleLoad"
            >
              <slot name="left" :item="item"></slot>
            </DecryptImgLoad>
          </div>
        </div>
      </div>

      <div ref="waterFallRight" class="right">
        <div v-for="(item, index) in waterFallRightArr" :key="index" class="cell-item">
          <div class="cart" @click="toPlay(item, datasList)">
            <DecryptImgLoad
              :style="{
                height: item.reset_high != 'auto' ? item.reset_high + 'px' : 'auto'
              }"
              :img-u-r-l="item.picture || item.avatar || item.cover || item.coverImg"
              :item="item"
              class="img-box"
              @handleLoad="handleLoad"
            >
              <slot name="right" :item="item"></slot>
            </DecryptImgLoad>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    DecryptImgLoad: () => import('./DecryptImgLoad.vue')
  },
  props: {
    calcImgLoadStatus: {
      type: Boolean,
      default: false
    },
    datasList: {
      type: Array,
      default: () => []
    },
    cartoonItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 左右数据
      waterFallLeftArr: [],
      waterFallRightArr: [],
      // 当前加载位置
      nowIndex: 0,
      clientWidth: 0,
      dplayerShow: false
    }
  },
  computed: {},
  watch: {
    datasList: {
      handler(n, o) {
        //0，或者小于之前为刷新
        if (n.length == 0) {
          this.waterFallLeftArr = []
          this.waterFallRightArr = []
          this.nowIndex = 0
        } else {
          this.$nextTick(() => {
            this.datasList.forEach(item => {
              this.get_viewPicHeight(item)
            })
            //进行高度计算
            this.handleLoad()
          })
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    fn_play_video(item) {
      this.$emit('fn_play_video', item)
    },

    // 跳转短视频播放页
    toPlay(item) {
      if (this.cartoonItem) {
        this.$router.push(`/play/longVideo/${item.id}`)
      } else {
        //初始化状态未完毕不允许观看详情
        let newVideoArr = JSON.parse(JSON.stringify(this.datasList))
        newVideoArr.map(sItem => {
          if (sItem.id === item.id) {
            this.$emit('toDetail', item)
          }
        })
      }
    },
    //初始化图片高度，不能deep会触发监听
    get_viewPicHeight(item) {
      //没有数据，全部默认200高度
      if (!item.height && item.reset_high != 'auto') {
        this.$set(item, 'reset_high', '200')
        return
      }
      if (item.reset_high) return
      //计算当前窗口对应的图片高度
      if (!this.clientWidth) this.clientWidth = (document.querySelector('body').clientWidth * 0.92) / 2
      let newHigh = (this.clientWidth * item.height) / item.width
      if (!Number.isInteger(newHigh)) {
        newHigh = +newHigh.toFixed(3)
      }
      this.$set(item, 'reset_high', newHigh)
    },
    handleLoad($event) {
      let leftHeight = this.$refs['waterFallLeft'].offsetHeight
      let rightHeight = this.$refs['waterFallRight'].offsetHeight
      // 图片加载自动触发则+1（包括：timeOut，err，finish），目前：只有timeOut，开局全部加载
      if ($event) this.nowIndex++
      // >总长度，则可继续下拉
      if (this.waterFallLeftArr.length + this.waterFallRightArr.length >= this.datasList.length) {
        return this.$emit('calcImgLoad', true)
      }
      if (leftHeight > rightHeight && this.datasList[this.nowIndex]) {
        this.waterFallRightArr.push(this.datasList[this.nowIndex])
      }
      if (rightHeight >= leftHeight && this.datasList[this.nowIndex]) {
        // 特殊情况断网
        // if (rightHeight == 0 && leftHeight == 0 && this.waterFallLeftArr.length >= 5) {
        //   this.waterFallLeftArr = []
        //   this.waterFallRightArr = []
        // }
        this.waterFallLeftArr.push(this.datasList[this.nowIndex])
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.container-water-fall {
  width: 100%;
  box-sizing: border-box;

  .xgplay-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .rowWrap {
    clear: both;
    overflow: hidden;

    .left,
    .right {
      display: inline-block;
      width: 50%;
    }

    .left {
      float: left;
      padding-right: 0.07rem;
    }

    .right {
      float: right;
      padding-left: 0.07rem;
    }
  }

  .cell-item {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    //padding: 0.2rem 0.1rem;
    // padding: 0.1rem 0.05rem;
    // margin: 0.1rem 0.05rem;
    margin-bottom: 0.14rem;
    animation: fadeIn 1s;
    background: #fff;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    .cart {
      position: relative;
      overflow: hidden;
      border-radius: 0.16rem 0.16rem 0 0;

      .mask-group-video {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;

        .play_icon {
          width: 0.8rem;
          height: 0.8rem;
        }

        > div {
          &:nth-child(1) {
            text-align: right;
            padding: 0.22rem 0.22rem 0 0;
          }

          &:nth-child(2) {
            position: absolute;
            left: 50%;
            top: 50%;
            @include transformCenter;
          }

          &:nth-child(3) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 0.2rem;
            padding: 0 0.16rem 0.2rem;
            background: linear-gradient(
              180deg,
              rgba(34, 34, 34, 0) 0%,
              rgba(34, 34, 34, 0.63) 66.67%,
              rgba(34, 34, 34, 0.95) 100%
            );

            > div {
              display: flex;
              align-items: center;

              &:nth-child(2) {
                padding: 0.04rem 0.16rem;
                border-radius: 0.32rem;
                background: rgba(34, 34, 34, 0.5);
              }
            }
          }
        }

        .star-img {
          width: 0.36rem;
          height: 0.36rem;
        }

        .fire-img {
          width: 0.28rem;
          height: 0.28rem;
          margin-right: 0.08rem;
        }

        .coin-img {
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 0.08rem;
        }
      }

      .mask-group-photo {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 0.2rem;
          padding: 0 0.16rem 0.2rem;
          background: linear-gradient(
            180deg,
            rgba(34, 34, 34, 0) 0%,
            rgba(34, 34, 34, 0.63) 66.67%,
            rgba(34, 34, 34, 0.95) 100%
          );

          > div {
            display: flex;
            align-items: center;

            &:nth-child(2) {
              padding: 0.04rem 0.16rem;
              border-radius: 0.32rem;
              background: rgba(34, 34, 34, 0.5);
            }
          }
        }

        .fire-img {
          width: 0.28rem;
          height: 0.28rem;
          margin-right: 0.08rem;
        }

        .coin-img {
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 0.08rem;
        }
      }

      .showMask-wrap {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 3;

        img {
          position: absolute;
          top: 0.1rem;
          right: 0.1rem;
          width: 0.25rem;
        }
      }
    }

    .item-body {
      // border: 1px solid #F0F0F0;
      padding: 0.2rem;
      border-radius: 0 0 0.08rem 0.08rem;
      border: 1px solid #787878;
      background: #fff4f4;

      .item-desc {
        font-weight: 400;
        font-size: 0.24rem;
        //line-height: 1em;
        color: #282828;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .item-footer {
        margin-top: 0.16rem;
        position: relative;
        display: flex;
        align-items: center;
        white-space: nowrap;

        .avatar {
          width: 0.38rem;
          height: 0.38rem;
          border-radius: 99px;
          overflow: hidden;
          background-repeat: no-repeat;
          background-size: contain;

          :deep() {
            .warp {
              border-radius: 50%;
            }
          }
        }

        .name {
          max-width: 2.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 0.1rem;
          font-size: 0.24rem;
          color: #333333;
          font-weight: 600;
        }
      }
    }
  }

  .loading-wrap {
    font-size: 0.24rem;
    color: #9392b0;
    @include flex-center;
  }

  .play-icon {
    width: 0.8rem;
    height: 0.8rem;
    position: absolute;
    left: 50%;
    top: 50%;
    @include transformCenter;
  }
}
</style>
