<template>
  <div v-if="itemList && itemList.length" class="six-list-main">
    <HomeTitle
      v-if="name"
      :title="name"
      :path="`/acg/acgTopicDetail/?title=${name}&type=${isNovelItem ? 'novel' : 'comics'}&id=${id}`"
    />
    <ul class="six-list">
      <li v-for="(item, index) in itemList" :key="index">
        <ComicsOneCard
          :isNovelItem="isNovelItem"
          width="2.18rem"
          height="2.86rem"
          :itemData="item"
          :isDetail="isDetail"
          @onRefresh="onRefresh"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {
    ComicsOneCard: () => import('./comicsOneCard.vue'),
    HomeTitle: () => import('@/components/homeTitle.vue')
  },
  props: ['itemList', 'name', 'isNovelItem', 'id', 'isDetail'],
  methods: {
    onRefresh(id) {
      this.$emit('onRefresh', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.six-list-main {
  margin-bottom: 0.36rem;
}
.six-list {
  @include flexbox($jc: flex-start, $fw: wrap);

  li {
    width: 2.18rem;
    margin-right: 0.14rem;
    flex: none;
    margin-bottom: 0.2rem;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
