<template>
  <div class="acg_topic_detail">
    <EBackTop :title="$route.query.title ? $route.query.title : '标签详情'"> </EBackTop>
    <div class="first_main">
      <ul class="first_list">
        <li
          v-for="item in firstList"
          :key="item.id"
          :class="titleActive === item.id ? 'active' : ''"
          @click="changTitleType(item.id)"
        >
          {{ item.name }}
        </li>
        <i class="line" :style="{ left: `${titleActive * 50}%` }"></i>
      </ul>
    </div>
    <ERefreshLoadV3
      ref="ERefreshLoadV3"
      class="main_list"
      :beforeRefresh="onRefresh"
      :moreData="onLoad"
      :finished="finished"
    >
      <template>
        <component
          :is="compComponent($route.query.type)"
          :isNovelItem="$route.query.type === 'novel'"
          class="detail_list"
          :itemList="dataList"
          :datasList="dataList"
          :cartoonItem="true"
        >
          <template #left="data">
            <img
              class="collect_icon"
              :src="
                data.item.isCollect
                  ? require('@/assets/imgs/acg/acg-is-collect.png')
                  : require('@/assets/imgs/acg/acg_no-collect.png')
              "
              alt=""
            />
            <div class="title">
              <img src="@/assets/imgs/acg/comisc_card_play.png" alt="" />
              <p>{{ data.item.title }}</p>
            </div>
          </template>

          <template #right="data">
            <img
              class="collect_icon"
              :src="
                data.item.isCollect
                  ? require('@/assets/imgs/acg/acg-is-collect.png')
                  : require('@/assets/imgs/acg/acg_no-collect.png')
              "
              alt=""
            />
            <div class="title">
              <img src="@/assets/imgs/acg/comisc_card_play.png" alt="" />
              <p>{{ data.item.title }}</p>
            </div>
          </template>
        </component>
      </template>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { mediaTagDetails, novelTagList, comicsTagDetails } from '@/api/acg'
export default {
  components: {
    // 小说或者漫画
    ComicsOrNovelDetail: () => import('@/components/ComicsTypeCard/columnSixCard.vue'),
    // 动漫瀑布流
    CartoonDetail: () => import('@/components/WaterfallList/notAi.vue')
  },
  data() {
    return {
      dataList: [],
      titleActive: 0,
      finished: false,
      pageNum: 0,
      pageSize: 15,
      firstList: [
        {
          id: 0,
          name: '最新'
        },
        {
          id: 1,
          name: '最热'
        }
      ]
    }
  },
  methods: {
    async onLoad() {
      this.pageNum++
      await this.getTopicDetails()
    },
    onRefresh() {
      this.dataList = []
      this.pageNum = 0
    },

    async changTitleType(titleId) {
      this.titleActive = titleId
      this.dataList = []
      this.pageNum = 0
      await this.onLoad()
    },
    compComponent(detailType) {
      switch (detailType) {
        case 'comics':
        case 'novel':
          return 'ComicsOrNovelDetail'
        default:
          return 'CartoonDetail'
      }
    },
    async getTopicDetails() {
      const apiList = {
        comics: comicsTagDetails,
        novel: novelTagList,
        cartoon: mediaTagDetails
      }
      const res = await apiList[this.$route.query.type]({
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        id: +this.$route.query.id,
        sort: this.titleActive
      })
      if (res.data.list && res.data.list.length) {
        this.dataList = [...this.dataList, ...res.data.list]
        if (res.data.list.length < this.pageSize) {
          this.finished = true
        }
      } else if (res.data.mediaList && res.data.mediaList.length) {
        this.dataList = [...this.dataList, ...res.data.mediaList]
        if (res.data.mediaList.length < this.pageSize) {
          this.finished = true
        }
      } else {
        this.finished = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.first_main {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  width: 100%;
  height: 1rem;
  background: #fff;
  max-width: $pcMaxWidth;
  @include flexbox($jc: center);
  //   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
}
.main_list {
  padding: 1rem 0.32rem; //   height: calc(100vh - 4rem);
  z-index: 1;
}

.first_list {
  border-radius: 0.64rem;
  background: rgba(0, 0, 0, 0.06);
  @include flexbox($jc: flex-start);
  position: relative;
  font-size: 0.26rem;
  flex: none;
  width: 2.4rem;
  .line {
    position: absolute;
    border-radius: 0.27rem;
    background: #e8619d;
    top: 0;
    padding: 0.04rem 0.16rem;
    width: 50%;
    height: 0.5rem;
    z-index: -1;
    transition: left 0.1s linear;
  }
  li {
    width: 1.36rem;
    height: 0.5rem;
    text-align: center;
    line-height: 0.5rem;
  }

  .active {
    color: #fff;
  }
}
.collect_icon {
  position: absolute;
  width: 0.58rem;
  height: 0.58rem;
  top: 0;
  right: 0;
}

.title {
  @include flexbox($jc: flex-start);
  margin-top: 0.14rem;
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  p {
    @include textoverflow();
    // width: 100%;
    // height: 0.24rem;
    font-size: 0.24rem;
    color: #fff;
  }
  img {
    width: 0.36rem;
    height: 0.36rem;
    margin-right: 0.12rem;
  }
}
</style>
