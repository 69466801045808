<!--
 * @Author: 
 * @Mail: 
 * @Date: 2024-02-01 23:20:46
 * @LastEditTime: 2024-02-14 11:08:26
 * @LastEditors: 江涛 wangtao524713@gmail.com
 * @FilePath: /haoseH5/src/components/Home/showType/Banner.vue
-->
<template>
  <div v-if="homeAD && homeAD.length" class="middle_banner">
    <EADSwiper :list="homeAD" keyName="cover" @clickImg="clickImg" />
  </div>
</template>
<script>
import { index_home } from 'api/home'
export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    // 获取首页轮播广告
    homeAD({ $store }) {
      return $store.getters['homeAD']
    }
  },
  mounted() {},

  methods: {
    // banner跳转
    clickImg(item) {
      this.activityBtn(item.href)
    }
  }
}
</script>
<style lang="scss" scoped>
.middle_banner {
  width: 6.86rem;
  height: 1.42rem;
  border-radius: 0.16rem;
  overflow: hidden;
  margin: 0 auto;
}
</style>
