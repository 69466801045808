<template>
  <div class="postDetails">
    <!-- 帖子详情 -->
    <div class="details-txt details-title">
      <span>{{ newData.base.title }}</span>
    </div>
    <div class="details-publish">
      <div>{{ newData.base.watches | numberFilter }}浏览</div>
      <div>发布时间:{{ newData.base.createdAt | timeDiff }}</div>
    </div>
    <div class="details-txt">
      <span>{{ newData.node.text }}</span>
    </div>
    <p class="desc">{{ newData.base.videoText }}</p>
    <div class="img-pack">
      <EDecryptImg
        v-for="(img, index) in newData.node.imgs"
        :key="index"
        ref="refImg"
        :img-c-d-n="imgCDN"
        class="img-item"
        :need-padding="false"
        :img-u-r-l="img"
        @click.native.stop="clickImg(img)"
      />
    </div>
    <EDecryptImg v-if="newData.base.videoUrl" class="img-item details-video" :img-u-r-l="newData.base.videoCover">
      <div
        class="play"
        :style="{ background: newData.base.postType !== 1 ? 'rgba(0, 0, 0, 0.5)' : 'none' }"
        @click.stop="fn_playVideo(newData)"
      >
        <!-- 需要金币与未购买与非暗黑交易板块 -->
        <div v-if="newData.base.price && !newData.base.isBought && newData.base.postType !== 1" class="notice-coin">
          <div>
            <span>您只需花费</span>
            <span class="count">{{ newData.base.price / 100 }}</span>
            <span>金币</span>
          </div>
          <div>即可解锁继续查看</div>
          <div>立即解锁</div>
        </div>
        <div v-else-if="isMemberAndNotShop" class="notice-vip" @click.stop="fn_playVideo(newData)">
          <div>请开通<span style="color: #ff8f4c">VIP</span>继续观看</div>
          <div>至尊权限即可享受</div>
          <div>前往开通</div>
        </div>
        <img src="@/assets/imgs/play.svg" alt="" />
      </div>
    </EDecryptImg>

    <!-- 视频展示 同时也是详情里的视频展示-->
    <div v-if="newData.postMediaInfo" class="video-cover" @click.stop="fn_playVideo(newData)">
      <div class="video-pack">
        <div class="video-l">
          <VideoImage :media="newData.postMediaInfo"></VideoImage>
        </div>
        <div class="video-r">
          {{ newData.postMediaInfo.title }}
        </div>
      </div>
    </div>
    <!-- <img-preview v-if="showImgPreview" v-model="imgIndex" :new-data="newData" /> -->
    <ComPlayVideo ref="ComPlayVideo" />
    <notice-vip-pop v-model="showVipPop" @showVip="$router.push('/Mine/vip')" />
    <notice-coin-pop v-model="showCoinPop" @showCoin="$router.push('/Mine/myWallet')" />
    <confirm-coin-pop v-model="showConfirmCoinPop" :price="newData.base.price" @confirm="() => shoudBuy(newData)" />
  </div>
</template>
<script>
import { community_pay } from 'api/community'
import { numberFilter, timeDiffNew } from '@/filters/filter'
import { ImagePreview } from 'vant'
export default {
  components: {
    VideoImage: () => import('@/components/Video/videoImage.vue'),
    // ImgPreview: () => import('@/components/Community/ImgPreview.vue'),
    ComPlayVideo: () => import('@/components/Community/video.vue'),
    NoticeVipPop: () => import('@/components/Popup/noticeVipPop.vue'),
    NoticeCoinPop: () => import('@/components/Popup/noticeCoinPop.vue'),
    ConfirmCoinPop: () => import('@/components/Popup/confirmCoinPop.vue')
  },
  filters: {
    nickNamef(v, tabList) {
      for (let index = 0; index < tabList.length; index++) {
        const element = tabList[index]
        if (v == element.id) {
          return element.name
        }
      }
    }
  },
  props: {
    itemData: {
      type: Object,
      default: () => {
        return {
          base: {},
          node: {}
        }
      }
    },
    showType: {
      type: Number,
      default: () => {
        return 0 //0:帖子列表 1:帖子详情
      }
    }
  },
  data() {
    return {
      newData: {},
      showImgPreview: false,
      imgIndex: 0,
      showVipPop: false,
      showCoinPop: false,
      showConfirmCoinPop: false
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取首页顶部视频分类导航列表
    findCategory({ $store }) {
      return $store.getters['findCategory']
    },
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    isMemberAndNotShop() {
      return !this.newData.base.price && this.newData.base.isVip && !this.newData.base.isBought && !this.isMember
    }
  },
  watch: {
    itemData: {
      handler(n) {
        this.newData = {
          ...n,
          node: Array.isArray(n.nodes)
            ? n.nodes[0]
              ? n.nodes[0]
              : {}
            : n.node && typeof n.node === 'object'
            ? n.node
            : {}
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // 判断是否显示展开收起按钮
    // if (this.newData.node && this.newData.node.text && !this.isDetail) {
    //   this.ifOver = this.$refs.spanBox.offsetHeight > this.$refs.textBox.offsetHeight;
    // }
  },

  methods: {
    // 图片预览
    clickImg(imgUrl) {
      const imgArr = []
      let index = 0
      const domArr = this.$refs['refImg']
      domArr.forEach((itemBlob, indexBlob) => {
        if (imgUrl === itemBlob.imgURL) {
          index = indexBlob
        }
        imgArr.push(itemBlob.realUrl)
      })
      ImagePreview({
        images: imgArr, // 需要预览的图片 URL 数组
        showIndex: true, // 是否显示页码
        loop: true, // 是否开启循环播放
        startPosition: index, // 图片预览起始位置索引
        closeable: true
      })
    },
    changeActive(value) {
      console.log(value, 222)
      this.imgIndex = value
    },
    timeDiffNew,
    numberFilter,
    // 详情跳转
    toDetail(item) {
      this.$router.push(`/community/detail/${item.base.id}`)
    },
    fn_playVideo() {
      let base = this.newData.base
      // 需要金币与未购买与非暗黑交易板块
      if (base.price && !base.isBought && base.postType !== 1) {
        if (this.userInfo.balance < base.price) {
          this.showCoinPop = true
        } else {
          this.showConfirmCoinPop = true
        }
        return
      } else if (!base.price && base.isVip && !base.isBought && !this.isMember) {
        this.showVipPop = true
        return
      }
      this.$refs['ComPlayVideo'].fn_playVideo(base.videoUrl, base.videoCover)
    },
    // 购买弹窗购买按钮
    async shoudBuy(data) {
      try {
        const res = await community_pay({
          id: data.base.id
        })
        if (res.code === 200 && res.data.code === 200) {
          this.$emit('getNewDetail')
          this.showBuy = false
          this.newData.base.isBought = true
          return this.$toast('购买成功')
        } else {
          return this.$toast(res.data.msg)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求错误，请稍后再试！')
      }
    }

    // // 图片预览
    // clickImg(index) {
    //   this.showImgPreview = true
    //   this.imgIndex = index
    // }
  }
}
</script>
<style lang="scss" scoped>
.notice-coin {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  @include flex-column-center;

  > :nth-child(1) {
    font-size: 0.4rem;
    font-weight: 500;
    padding: 0 0.2rem;
    line-height: 0.78rem;

    > .count {
      color: #ff8f4c;
      padding: 0 0.16rem;
    }
  }

  > :nth-child(2) {
    margin-top: 0.32rem;
    font-size: 0.28rem;
    line-height: 0.4rem;
  }

  > :nth-child(3) {
    margin-top: 0.32rem;
    @include flex-center;
    width: 2.72rem;
    height: 0.76rem;
    border-radius: 0.44rem;
    font-size: 0.32rem;
    background: $gradientBg;
  }
}

.notice-vip {
  position: absolute;
  width: 100%;
  height: 100%;
  @include flex-center;
  flex-direction: column;
  color: #fff;
  > :nth-child(1) {
    font-size: 0.32rem;
  }
  > :nth-child(2) {
    margin: 0.2rem;
  }
  > :nth-child(3) {
    // margin-top: 0.32rem;
    @include flex-center;
    width: 2.72rem;
    height: 0.76rem;
    border-radius: 0.44rem;
    font-size: 0.28rem;
    background: $gradientBg;
  }
}

.postDetails {
  margin-bottom: 0.1rem;

  .desc-txt {
    font-size: 0.28rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;

    .at {
      padding: 0.04rem 0.2rem;

      border-radius: 0.06rem;
      background: #ffb267;
      margin-right: 0.13rem;
      font-size: 0.24rem;
      color: #333;
      white-space: nowrap;
    }

    .categoryType {
      color: #1d93d6;
      margin-right: 0.05rem;
    }

    .title {
      font-size: 0.32rem;
    }

    .desc {
      margin-top: 0.24rem;
      @include textoverflow(3);
    }

    .desc_btn {
      color: #ffcf86;
    }
  }

  .details-publish {
    @include flexbox($ai: column);
    color: #999999;
    font-size: 0.24rem;
    padding-bottom: 0.24rem;
    border-bottom: 0.02rem solid #e1e1e1;
    margin-bottom: 0.24rem;
  }

  .details-txt {
    font-size: 0.26rem;
    white-space: pre-line;

    &.details-title {
      font-size: 0.36rem;
      margin-bottom: 0.16rem;
      padding-bottom: 0.28rem;
    }

    .categoryType {
      color: #1d93d6;
      margin-right: 0.05rem;
    }
  }

  .details-video {
    position: relative;
    width: 6.78rem;
    height: 3.82rem;

    .play {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .img-pack {
    margin: 0.2rem 0;
  }

  .img-list {
    @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: wrap);
    margin: 0.14rem 0 0.2rem 0;

    .img-video {
      .play {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 0.6rem;
          height: 0.6rem;
        }
      }
    }

    .img-item {
      margin-top: 0.1rem;
      border-radius: 0.1rem;
      overflow: hidden;
      width: 2.22rem;
      height: 2.22rem;
      margin-right: 0.1rem;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    i {
      width: 2.26rem;
    }

    .img-item:nth-child(3n) {
      margin-right: 0;
    }
  }

  .video-cover {
    margin: 0 auto;

    .video-desc {
      font-size: 0.3rem;
      max-height: 1.3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      white-space: pre-wrap;
    }

    .video-pack {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.2rem 0;

      .video-l {
        min-width: 2.74rem;
        max-width: 2.74rem;
        height: 1.54rem;
        margin-right: 0.2rem;
        border-radius: 0.08rem;
        overflow: hidden;

        ::v-deep {
          .vide_detail {
            height: 0.3rem;
            font-size: 0.18rem;
            padding: 0 0.1rem;
          }

          .video_image {
            height: 100%;
          }
        }
      }

      .video-r {
        font-size: 0.2rem;
        color: #333;
      }
    }

    .sm-video {
      position: relative;

      ::v-deep {
        .warpNoPadding {
          img {
            object-fit: contain;
            max-height: 6.9rem;
          }
        }
      }

      .van-icon-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

:deep() .btn-wrap {
  > div:nth-child(2) {
    background: red;
  }
}

.node_text {
  @include textoverflow(4);
  white-space: pre-line;
  color: #999;
}
</style>
