<!--
 * @Author: 
 * @Mail: 
 * @Date: 2024-01-31 19:51:05
 * @LastEditTime: 2024-02-10 09:38:19
 * @LastEditors: 江涛 wangtao524713@gmail.com
 * @FilePath: /haoseH5/src/components/Home/homeSeach.vue
-->
<template>
  <div class="home-header">
    <div class="frontPage">
      <div class="search-input" @click="$router.push('/search')">
        <img src="~/assets/imgs/home/Search.png" alt class="searchIcon" />
        输入搜索文字
      </div>
    </div>
    <img
      src="@/assets/imgs/home/icon_sign.webp"
      alt
      class="searchLogo"
      @click="$router.push('/mine/myShouyi?type=1')"
    />
    <div class="bank" @click="$router.push('/classType')">
      <img src="@/assets/imgs/home/bank.png" />
      片库
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeSearch',
  mounted() {},
  methods: {
    toTagList() {
      this.$emit('clickHeader')
    }
  }
}
</script>

<style lang="scss" scoped>
.home-header {
  width: 7.5rem;
  height: 0.88rem;
  background-color: #fff;
  padding: 0 0.25rem;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .frontPage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 4.62rem;

    .search-input {
      background: rgba(217, 217, 217, 0.3);
      width: 100%;
      height: 0.6rem;
      line-height: 0.6rem;
      border-radius: 0.7rem;
      color: #939496;
      font-size: 0.28rem;
      padding-left: 0.21rem;

      .searchIcon {
        width: 0.32rem;
        height: 0.32rem;
      }
    }
  }

  .bank {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.32rem;
    border-radius: 0.08rem;
    background: #e8619d;
    width: 1.32rem;
    height: 0.6rem;
    color: #fff;
    margin-left: 0.1rem;
    flex-grow: 0;
    > img {
      flex-shrink: 0;
      width: 0.32rem;
      height: 0.32rem;
      margin-right: 0.05rem;
    }
  }

  .searchLogo {
    width: 0.6rem;
    height: 0.6rem;
    margin-left: 0.24rem;
  }
}
</style>
