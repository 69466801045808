<template>
  <div class="my_shouyi">
    <div class="user_income">
      <img src="@/assets/imgs/mine/incom_left_icon.png" alt="" />
      <span>收益总览</span>
      <div class="incom_num">
        <div class="incom_num_left">
          <p>{{ userIncomeInfo.totalIncome | changeGold }}</p>
          <p>可提现余额{{ userIncomeInfo.totalIncome | changeGold }}元</p>
        </div>
        <div class="btn" @click="$router.push('/Mine/withdraw')">立即提现</div>
      </div>
    </div>
    <!-- nav导航 -->
    <ul class="nav_tabs">
      <li @click="$router.push('/Mine/inComeDetail')">
        <img src="@/assets/imgs/mine/shouyi_icon.png" alt="" />
        收益明细
      </li>
      <li @click="$router.push('/Mine/shareHistory')">
        <img src="@/assets/imgs/mine/share_history.png" alt="" />
        分享记录
      </li>
      <li @click="$router.push('/Mine/myShare')">
        <img src="@/assets/imgs/mine/share_icon.png" alt="" />
        分享推广
      </li>
    </ul>
    <!-- 分享数字 -->
    <div class="share_nums">
      <p class="title">分享领VIP</p>
      <ul>
        <li>
          <p>推广人数</p>
          <p>{{ userIncomeInfo.proxyTotalIncomeUser ? userIncomeInfo.proxyTotalIncomeUser : 0 }}</p>
        </li>
        <li>
          <p>累计天数</p>
          <p>{{ userIncomeInfo.totalDay ? userIncomeInfo.totalDay : 0 }}</p>
        </li>
      </ul>
    </div>
    <!-- 规则说明 -->
    <div class="tips_txt">
      <p class="title">规则说明</p>
      <p>
        1、邀请 <span>好友注册成功</span> 即可获得 <span>会员</span> 和大量<span>积分</span>。<br />
        积分可兑换会员、观看视频等等
      </p>
      <p>2、邀请说明：点击【保存二维码】或【复制推广链接】。获取专属推广链接，推荐分享给其他人下载即可。</p>
    </div>
    <!-- 成功邀请 -->
    <div class="share_succcess">
      <p class="title">成功邀请1人送3天VIP</p>
      <div class="share_bg">
        <p>
          累计邀请 <span>{{ userInfo.invites }}人</span>
        </p>
        <div class="code">
          <EGenerateImg
            ref="EGenerateImg"
            :link="userInfo.inviteUrl"
            :name="`来自${userInfo.nickName}的分享地址.png`"
            :config="config"
          >
          </EGenerateImg>
        </div>
      </div>
      <ul class="btns">
        <li @click="clickSaveImg">保存图片</li>
        <li v-clipboard:copy="userInfo.inviteUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">复制链接</li>
      </ul>
    </div>
    <img class="share_bot" src="@/assets/imgs/mine/share_bot.png" alt="" />
  </div>
</template>

<script>
import { user_income } from '@/api/user'
export default {
  name: 'MineShare',
  components: {},
  data() {
    return {
      config: {
        qrSize: 180,
        scale: 2,
        qrBgwidth: 280,
        qrBgheight: 280
      },
      web: '',
      app: '',
      qrCode: '',
      userIncomeInfo: {}
    }
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    // 落地页
    ldyCdn({ $store }) {
      return $store.getters['ldyCdn']
    }
  },
  mounted() {
    this.getUserIncome()
  },
  methods: {
    onCopy() {
      this.$toast('复制成功')
    },
    onError() {
      this.$toast('不支持复制')
    },
    clickSaveImg() {
      this.$toast('请截图保存')
      // this.$refs['EGenerateImg'].clickSaveImg()
    },
    // 获取用户代理信息
    async getUserIncome() {
      const res = await user_income({})
      if (res.code === 200) {
        this.userIncomeInfo = res.data
      } else {
        return this.$toast('请求错误，请联系后台管理人员！')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my_shouyi {
  padding: 0 0.32rem;
}

.user_income {
  background: rgba(0, 0, 0, 0.04);
  width: 6.86rem;
  height: 2.5rem;
  position: relative;
  font-size: 0.28rem;
  padding: 0.26rem 0.24rem 0.36rem 0.36rem;
  margin: 0.36rem auto;

  img {
    position: absolute;
    left: 0;
    top: 0.2rem;
    width: 0.16rem;
    height: 0.44rem;
  }

  .incom_num {
    @include flexbox($ai: flex-end);

    .incom_num_left {
      p {
        color: #e8619d;
        font-size: 0.64rem;
        font-style: normal;
        font-weight: 600;

        &:last-child {
          color: #333;
          font-size: 0.24rem;
          opacity: 0.7;
        }
      }
    }
    .btn {
      background: $gradientBg;
      width: 1.52rem;
      height: 0.54rem;
      border-radius: 0.27rem;
      text-align: center;
      line-height: 0.54rem;
      color: #fff;
    }
  }
}

.nav_tabs {
  @include flexbox($jc: space-around);
  font-size: 0.28rem;
  margin-bottom: 0.36rem;

  li {
    @include flexbox($jc: center, $fd: column);

    img {
      width: 1rem;
      height: 1rem;
      margin-bottom: 0.16rem;
    }
  }
}

.share_nums {
  margin-bottom: 0.5rem;

  ul {
    @include flexbox($jc: center);

    li {
      width: 3.38rem;
      height: 1.2rem;
      flex-shrink: 0;
      margin-right: 0.14rem;
      text-align: center;
      background: rgba(0, 0, 0, 0.04);
      padding: 0.12rem 0;

      p {
        font-size: 0.4rem;
        margin-bottom: 0;

        &:first-child {
          opacity: 0.6;
          font-size: 0.28rem;
        }
      }
    }
  }
}

.tips_txt {
  span {
    color: $txtActive;
  }

  p {
    margin-bottom: 0.2rem;
  }
}

.title {
  font-size: 0.28rem;
  font-weight: 600;
  margin-bottom: 0.26rem;
}

.share_succcess {
  text-align: center;
  margin-bottom: 0.36rem;

  .share_bg {
    background: url('../../../../assets/imgs/mine/share_bg.png') no-repeat;
    background-size: 100% 100%;
    width: 6.86rem;
    height: 7.44rem;
    margin-bottom: 0.56rem;
    @include flexbox($ai: center, $fd: column, $jc: center);

    p {
      margin-top: 1.3rem;

      span {
        color: $txtActive;
      }
    }

    .code {
      width: 2.8rem;
      height: 2.8rem;
      background: #fff;
      padding: 0.2rem;
      text-align: center;
      margin-top: 0.32rem;
      box-sizing: content-box;
      :deep() {
        .template {
          // width: 100%;
          // height: 100%;

          .qrBg {
            position: static;
            width: 100% !important;
            height: 100% !important;
          }

          canvas {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }

  .btns {
    @include flexbox($jc: space-around);

    li {
      background: $gradientBg;
      color: #fff;
      border-radius: 0.48rem;
      width: 2.4rem;
      height: 0.8rem;
      text-align: center;
      line-height: 0.8rem;
    }
  }
}

.share_bot {
  width: 6.86rem;
  height: 9.84rem;
  margin-bottom: 1.04rem;
}
</style>
