<template>
  <div v-if="itemList && itemList.length" class="pkSixList">
    <HomeTitle :title="name" :path="`/acg/acgTopicDetail/?title=${name}&type=cartoon&id=${id}`" />
    <ul class="nine-list">
      <li v-for="item in itemList" :key="item.id">
        <cartoonOneCard width="2.66rem" height="3.48rem" :itemData="item" />
      </li>
    </ul>
    <!-- 投票 -->
    <div class="poll-main">
      <div class="poll-num">
        <span>{{ oneNum }}</span>
        <span>{{ twoNum }}</span>
      </div>
      <div class="bg-box">
        <span :style="{ width: `${Math.round((oneNum / (oneNum + twoNum)) * 1000) / 10}%` }"></span>
        <span :style="{ width: `${Math.round((twoNum / (oneNum + twoNum)) * 1000) / 10}%` }"></span>
      </div>
      <div class="imgs-box">
        <EDecryptImg
          class="one-cartoon-card_bg"
          :imgURL="itemData.pollOneImage"
          @click.native="pollItem(itemData.pollOneId, 1)"
        ></EDecryptImg>
        <img src="@/assets/imgs/pk-icon.png" alt="" />
        <EDecryptImg
          class="one-cartoon-card_bg"
          :imgURL="itemData.pollTwoImage"
          @click.native="pollItem(itemData.pollTwoId, 2)"
        ></EDecryptImg>
      </div>
    </div>
  </div>
</template>

<script>
import { topicPoll } from 'api/acg'
export default {
  components: {
    cartoonOneCard: () => import('./cartoonOneCard.vue'),
    HomeTitle: () => import('@/components/homeTitle.vue')
  },
  props: ['itemList', 'name', 'id', 'itemData'],
  data() {
    return {
      oneNum: 0,
      twoNum: 0
    }
  },
  mounted() {
    this.oneNum = this.itemData.pollOneCount
    this.twoNum = this.itemData.pollTwoCount
  },
  methods: {
    async pollItem(id, type) {
      // console.log(id, '22')
      const res = await topicPoll({
        id
      })
      if (res.code === 200) {
        if (type === 1) {
          this.oneNum += 1
        } else {
          this.twoNum += 1
        }
        return this.$toast('投票成功！')
      } else {
        return this.$toast(res.tip)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pkSixList {
  margin-bottom: 0.36rem;
}
.nine-list {
  @include flexbox($jc: flex-start, $fw: wrap, $fd: column, $ai: flex-start);
  height: 7.5rem;
  overflow-x: scroll;
  li {
    margin-right: 0.16rem;
    flex: none;
    margin-bottom: 0.16rem;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
.poll-main {
  .poll-num,
  .bg-box,
  .imgs-box {
    @include flexbox();
  }
  .bg-box {
    margin: 0.24rem 0;
    span {
      height: 0.2rem;
      border-radius: 0.1rem;
      &:first-child {
        background: #ff001f;
        margin-right: 0.2rem;
      }
      &:last-child {
        background: #116aff;
      }
    }
  }
  .imgs-box {
    .one-cartoon-card_bg {
      width: 2.02rem;
      height: 0.68rem;
      border-radius: 0.16rem;
      :deep() {
        img {
          border-radius: 0.16rem;
        }
      }
    }
  }
}
</style>
