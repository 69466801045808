<template>
  <div class="cartoon-rank-list">
    <EBackTop :title="'片库分类'"> </EBackTop>
    <div class="content">
      <div class="typeContent">
        <span>类型</span>
        <div>
          <ul>
            <li
              v-for="(item, index) in classTypeList.typeList"
              :key="index"
              :class="item.value === param.type ? 'activeIndex' : ''"
              @click="checkItem('type', item.value)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="typeContent">
        <span>排序</span>
        <div>
          <ul>
            <li
              v-for="(item, index) in classTypeList.sortTypeList"
              :key="index"
              :class="item.value === param.sortType ? 'activeIndex' : ''"
              @click="checkItem('sortType', item.value)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="typeContent">
        <span>标签</span>
        <div>
          <ul>
            <li
              v-for="(item, index) in tagList"
              :key="index"
              :class="item.id === param.tagId ? 'activeIndex' : ''"
              @click="checkItem('tagId', item.id)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="typeContent">
        <span>价格</span>
        <div>
          <ul>
            <li
              v-for="(item, index) in classTypeList.priceTypeList"
              :key="index"
              :class="item.value === param.priceType ? 'activeIndex' : ''"
              @click="checkItem('priceType', item.value)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="typeContent">
        <span>时间</span>
        <div>
          <ul>
            <li
              v-for="(item, index) in classTypeList.dateTypeList"
              :key="index"
              :class="item.value === param.dateType ? 'activeIndex' : ''"
              @click="checkItem('dateType', item.value)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="fliterList">
      <ComicsSearch v-if="param.type === 8" ref="ComicsSearch" :param="param"></ComicsSearch>
      <CartoonSearch v-if="param.type === 3" ref="CartoonSearch" :param="param"></CartoonSearch>
      <NovelSearch v-if="param.type === 10" ref="NovelSearch" :param="param"></NovelSearch>
    </div>
  </div>
</template>

<script>
import { hot_tag_list } from 'api/search'
import { comicsTag, novelTag } from '@/api/acg'
export default {
  components: {
    ComicsSearch: () => import('@/components/Search/classType/comicsSearch'),
    CartoonSearch: () => import('@/components/Search/classType/cartoonSearch'),
    NovelSearch: () => import('@/components/Search/classType/novelSearch')
  },
  layout: 'ClassType',
  data() {
    return {
      param: {
        type: 8, //类型
        sortType: 0, //排序
        priceType: 0, //价格
        dateType: 0, //时间
        tagId: 0 //标签
      },
      tagList: [] //动态标签列表数据
    }
  },
  computed: {
    classTypeList() {
      let param = {
        typeList: [
          { name: '漫画', value: 8 },
          { name: '动漫', value: 3 },
          { name: '有声小说', value: 10 }
        ],
        sortTypeList: [
          { name: '全部', value: 0 },
          { name: '播放最多', value: 1 },
          { name: '收藏最多', value: 2 },
          { name: '最新上架', value: 3 }
        ],
        priceTypeList: [
          { name: '全部', value: 0 },
          { name: '会员', value: 1 },
          { name: '金币', value: 2 },
          { name: '免费', value: 3 }
        ],
        dateTypeList: [
          { name: '全部', value: 0 },
          { name: '本月', value: 1 },
          { name: '三月内', value: 2 },
          { name: '半年内', value: 3 }
        ]
      }
      return param
    }
  },
  async mounted() {
    await this.getTags()
  },

  methods: {
    // 顶部标签选择
    async checkItem(key, value) {
      if (key === 'type') {
        this.param[key] = value
        await this.getTags()
      } else {
        this.param[key] = value
      }
      if (this.param.type === 8) {
        this.$refs['ComicsSearch'].setParam(this.param)
      } else if (this.param.type === 3) {
        this.$refs['CartoonSearch'].setParam(this.param)
      } else if (this.param.type === 10) {
        this.$refs['NovelSearch'].setParam(this.param)
      }
    },
    async getTags() {
      let tagsListApi = {
        8: comicsTag,
        10: novelTag,
        3: hot_tag_list
      }
      await tagsListApi[this.param.type]({ location: this.param.type }).then(res => {
        if (res.code === 200) {
          if (this.param.type == 3) {
            this.tagList = res.data.tagList
          } else {
            this.tagList = res.data.list
          }
          this.param.tagId = 0
        } else {
          this.tagList = []
          return this.$toast(res.tip)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cartoon-rank-list {
  box-sizing: border-box;
  min-height: 100vh;
}
ul,
li {
  margin: 0;
  padding: 0;
}
.content {
  padding: 0.12rem 0.32rem;
  .typeContent {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.12rem 0;
    > span {
      font-size: 0.24rem;
      font-weight: 500;
      color: #333333;
    }
    > div {
      margin-left: 0.32rem;
      max-width: 6rem;
      > ul {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        max-height: 30vh;
        overflow-y: scroll;
        > li {
          flex-shrink: 0;
          height: 0.44rem;
          display: flex;
          align-items: center;
          justify-items: center;
          padding: 0.04rem 0.16rem;
          font-size: 0.22rem;
          border-radius: 0.24rem;
        }
        .activeIndex {
          color: #fff;
          background: red;
          font-size: 0.28rem;
        }
      }
    }
  }
  .fliterList {
    width: 100%;
  }
}
.main_list {
  padding: 0rem 0.32rem; //   height: calc(100vh - 4rem);
}
.collect_icon {
  position: absolute;
  width: 0.58rem;
  height: 0.58rem;
  top: 0;
  right: 0;
}
</style>
