<template>
  <div class="popup-list">
    <van-popup
      :value="show"
      get-container="body"
      position="center"
      round
      closeable
      :close-icon="require('@/assets/imgs/close.png')"
      @input="toClose"
    >
      <!--play_post 提示-->
      <div class="play_post">
        <div class="content">
          <img src="@/assets/imgs/play/warning.svg" alt="" class="warnImges" />
          <div class="text-content">
            <p class="first">您还不是我们的VIP会员</p>
            <p class="second"><span style="color: #e8619d">开通会员</span>畅享完整内容！</p>
          </div>
        </div>
        <div class="btn-wrap">
          <div @click="toTask">做任务得VIP</div>
          <div @click="toVip">开通会员</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'show',
    event: 'input'
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popupMsg: {}
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    toClose() {
      this.$emit('input', false)
    },
    toTask() {
      this.toClose()
      this.$router.push('/mine/myShouyi?type=1')
    },
    toVip() {
      this.toClose()
      this.$emit('showVip')
      // this.$router.push('/Mine/vip')
    }
  }
}
</script>

<style lang="scss" scoped>
.play_post {
  width: 5.4rem;
  background: #ffffff;

  .content {
    height: 2.88rem;
    font-size: 0.24rem;
    @include flex-column-center;
    text-align: center;

    .warnImges {
    }

    .text-content {
      margin-top: 0.4rem;

      .first {
        color: #999999;
      }

      .second {
        color: #666666;
        font-size: 0.28rem;
      }
    }
  }

  .btn-wrap {
    border-top: 0.02rem solid #f0f0f0;
    font-size: 0.28rem;
    height: 1.36rem;
    @include flex-center;

    > div {
      color: #ffffff;
      border-radius: 2rem;
      padding: 0.16rem 0.48rem;
      margin: 0 0.12rem;
    }

    :nth-child(1) {
      background: #666666;
    }

    :nth-child(2) {
      background: $gradientBg;
    }
  }
}
</style>
