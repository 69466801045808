<template>
  <div class="label-pack">
    <div class="tab-wrap">
      <ETab
        ref="postTabs"
        v-model="type"
        class="label-tab"
        :sticky="true"
        :tab-list="category"
        :animated="false"
        lineBg="#333333"
        :line-width="'0.4rem'"
        :title-active-color="'#333333'"
        :title-inactive-color="'#999999'"
        :size-tab="'0.28rem'"
        :size-active="'.32rem'"
        @change="change"
      >
        <template #default="{ item }">
          <actor-video-page
            v-if="item.id === 0 && type === 0"
            :params="queryParams"
            :filter-status="filterStatus"
            @on-filter="switchFilter"
            @mediaInfoFn="mediaInfoFn"
          />
          <actor-video-page
            v-else-if="item.id === 1 && type === 1"
            :params="queryParams"
            :filter-status="filterStatus"
            @on-filter="switchFilter"
            @mediaInfoFn="mediaInfoFn"
          />
          <actor-video-page
            v-else-if="item.id === 2 && type === 2"
            :params="queryParams"
            :filter-status="filterStatus"
            @on-filter="switchFilter"
            @mediaInfoFn="mediaInfoFn"
          />
          <!--        <actor-video-page :params="queryParams" />-->
        </template>
      </ETab>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ActorVideoPageTabs',
  components: {
    ActorVideoPage: () => import('./actorVideoPage.vue')
  },
  props: {
    tagIds: {
      type: Array,
      default: () => []
    },
    filterStatus: {
      type: Boolean,
      default: false
    },
    detailInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      type: 0,
      mediaInfo: {}
    }
  },
  computed: {
    queryParams() {
      return {
        contentType: this.type, //内容类型： 0:视频 1:图集 2:GIFs
        coverType: 1, //封面类型： 1:横版封面 2:竖版封面
        id: +this.$route.params.id, //演员ID
        sort: 1, //排序: 0:最新 1:最热 2:最受好评
        tagIds: this.tagIds //视频标签
      }
    },
    category() {
      return [
        { name: `视频（${this.detailInfo.mediaCount}）`, id: 0 },
        { name: `图片（${this.detailInfo.photoCount}）`, id: 1 },
        { name: `GIFs（${this.detailInfo.gifCount}）`, id: 2 }
      ]
    }
  },
  created() {
    this.type = this.category[0].id
  },
  methods: {
    switchFilter(e) {
      this.$emit('on-filter', e)
    },
    mediaInfoFn(e) {
      this.mediaInfo = e
    },
    change(type) {
      this.type = type
    }
  }
}
</script>

<style scoped lang="scss">
.label-pack {
  background: #ffffff;
  border-radius: 0.42rem 0.42rem 0 0;
  //border-top: 0.01rem solid #535353;
  position: relative;
  padding-top: 0.32rem;
  top: -0.64rem;

  .title {
    padding: 0 0.32rem;
    width: 100%;
    height: 0.44rem;
    font-size: 0.32rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .label-pack-recommend {
    margin-top: 0.24rem;
    padding: 0 0 0 0.32rem;
    display: flex;
    overflow-x: auto;
    @include scrollbar-hide;
    .label-pack-recommend-item {
      margin-right: 0.1rem;
    }
  }
  .divider {
    margin: 0.36rem auto;
    width: 6.86rem;
    height: 0.02rem;
    background: #f0f0f0;
  }
  .tab-wrap {
    position: relative;
    .label-tab {
      :deep(.van-sticky) {
        border-bottom: 0.01rem solid #999999;
      }
      :deep(.van-tabs__nav) {
        background-color: transparent;
        .van-tab {
          flex-grow: 1 !important;
          &.van-tab--active {
            border-bottom: 0.01rem solid #333333;
          }
        }
        .van-tabs__line {
          background-color: #333333 !important;
        }
      }
      :deep() {
        .van-sticky {
          z-index: 9999;
          padding: 0 0.16rem;
          background: #fff;

          .van-tab {
            flex: inherit;
          }
        }

        .van-tabs__nav {
        }
      }
      .list_data {
        @include flexbox($fw: wrap, $ai: flex-start);
        padding: 0 0.32rem;
        .post-item {
          margin-top: 0.36rem;
          border-bottom: 0.01rem solid #f0f0f0;
          .watches_num {
            margin: 0 0 0.24rem;
            display: inline-block;
            background: rgba(221, 0, 27, 0.05);
            border-radius: 0.48rem;
            color: #e8619d;
            padding: 0.04rem 0.16rem;
          }
        }
      }
    }
    .header-right {
      position: absolute;
      right: 0.32rem;
      top: 0.2rem;
      display: flex;
      align-items: center;
      color: #999999;
      .btn {
        &.active {
          color: #e8619d;
        }
      }
    }
  }
}
</style>
