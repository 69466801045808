<template>
  <div class="playlist">
    <EBackTop title="播单"></EBackTop>
    <div class="content">
      <div v-for="arr in topicList" :key="arr.id">
        <MyTitle :text="arr.name" @onclick="handleClick(arr)" />
        <div class="content-item">
          <div v-for="item in arr.playList" :key="item.id">
            <PlayListShowType1 :item="item"></PlayListShowType1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { playlist_page } from '@/api/playList'
import scrollMixin from '@/utils/scrollMixin'
export default {
  mixins: [scrollMixin],
  components: {
    MyTitle: () => import('../components/title.vue'),
    PlayListShowType1: () => import('@/components/Home/showType/playListType/playListShowType1')
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      topicList: []
    }
  },
  computed: {
    findPlayListCategory({ $store }) {
      return $store.getters['findPlayListCategory']
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const res = await playlist_page({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        categoryId: this.findPlayListCategory[0].id
      })
      this.topicList = res.data.topicList
      console.log('res', res)
    },
    handleClick(arr) {
      this.$router.push({
        path: '/home/playListDetail',
        query: {
          id: arr.id,
          name: arr.name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.playlist {
  .content {
    padding: 0.24rem 0.32rem;
  }

  .content-item {
    display: flex;
    flex-wrap: wrap;

    > div {
      width: 3.36rem;
      margin-bottom: 0.24rem;

      &:nth-child(odd) {
        margin-right: 0.14rem;
      }
    }

    .top {
      position: relative;
    }

    .bottom {
      margin-top: 0.16rem;
      color: $txtDefault;
      font-size: 0.26rem;
      font-style: normal;
      font-weight: 500;
      @include textoverflow;
    }

    .subtract {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .bodan-img {
      width: 100%;
    }

    .count-img {
      width: 0.28rem;
      margin-right: 0.08rem;
    }

    .item-img {
      width: 0.26rem;
      margin-right: 0.08rem;
    }

    .pos-bottom {
      position: absolute;
      width: 100%;
      height: 0.76rem;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
      // background: red;
      left: 0;
      bottom: 0;
      border-radius: 0rem 0rem 0.2rem 0.2rem;
      color: $white;
      padding: 0 0.16rem;
      @include flexbox;

      > div {
        @include flex-align-center;
      }
    }
  }
}
</style>
