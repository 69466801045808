<template>
  <EWaterFall :datasList="list">
    <template #left="{ handleLoad, data }">
      <div class="item">
        <DreamShowType2 :data="data" class="leftItem" :sizeType="6" @loadedImg="handleLoad">
          <template #mask="{ data }">
            <slot name="mask" :data="data"> </slot>
          </template>
        </DreamShowType2>
      </div>
    </template>
    <template #right="{ handleLoad, data }">
      <div class="item">
        <DreamShowType2 :data="data" class="rightItem" :sizeType="6" @loadedImg="handleLoad">
          <template #mask="{ data }">
            <slot name="mask" :data="data"> </slot>
          </template>
        </DreamShowType2>
      </div>
    </template>
  </EWaterFall>
</template>
<script>
export default {
  name: 'DreamWaterFallList',
  components: {
    DreamShowType2: () => import('@/components/Home/showType/dreamType/dreamShowType2.vue')
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  position: relative;
  margin-bottom: 0.14rem !important;
}
</style>
