<template>
  <div class="tag-detail">
    <EBackTop
      :title="$route.query.name ? $route.query.name : topicInfoData.name"
      background="transparent"
      color="#ffffff"
    />
    <div class="tag-detail-header-wrap">
      <div class="bg-wrap">
        <!-- <EDecryptImg class="bg" :img-c-d-n="imgCDN" :img-u-r-l="sjImg" /> -->
        <img :src="sjImg" alt="" />
      </div>
    </div>
    <div class="tag-detail-content">
      <ERefreshLoadV3 :autoOnload="true" :finished="finished" :beforeRefresh="refreshData" :moreData="moreData">
        <ETabBar
          :list="sortList"
          :tabbar-config="tabbarConfig"
          :active-num="sort"
          class="tag-detail-tab"
          @change="change"
        >
          <template>
            <div class="post-list">
              <PlayListShowType1 v-for="(item, index) in dataList" :key="index" :item="item" class="post-item" />
            </div>
          </template>
        </ETabBar>
      </ERefreshLoadV3>
    </div>
  </div>
</template>
<script>
import { playlist_topicinfo } from '@/api/playList'
import scrollMixin from '@/utils/scrollMixin'
export default {
  mixins: [scrollMixin],
  name: 'playListDetail',
  components: {
    PlayListShowType1: () => import('@/components/Home/showType/playListType/playListShowType1')
  },
  data() {
    return {
      sort: 0,
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.28rem', //默认字体大小
        fontSizeActive: '0.3rem', //选中字体大小
        fontColor: '#999', //默认字体颜色
        fontColorActive: '#333', //选中字体颜色
        bg: '#ffffff', //默认背景色
        bgActive: '#ffffff', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#333',
        lineWidth: '0', //下划线宽度 String
        sticky: true, //粘性布局
        offsetTop: '0rem', //距顶部距离
        tabFlex: true
      },
      sortList: [
        {
          id: 0,
          name: '最新'
        },
        {
          id: 1,
          name: '最热'
        }
      ],
      pageNum: 0,
      pageSize: 10,
      finished: false, //上拉加载完成
      dataList: [],
      topicInfoData: {},
      formData: {
        name: ''
      },
      id: null
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    sjImg() {
      let num = Math.floor(Math.random() * 4)
      return require(`@/assets/imgs/discover/bq_${num}.png`)
    }
  },
  activated() {
    if (this.$route.query.id !== this.id) {
      this.id = this.$route.query.id
      this.change(0)
    }
  },
  methods: {
    async change(e) {
      //切换排序
      this.pageNum = 1
      this.sort = e
      this.dataList = []
      await this.getMediaTopic()
    },
    async getMediaTopic() {
      const res = await playlist_topicinfo({
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        id: +this.$route.query.id,
        sort: this.sort
      })
      if (res.code === 200) {
        if (res.data) {
          this.topicInfoData = res.data.topic
        }
        if (res.data.topic.playList && res.data.topic.playList.length) {
          this.dataList = [...this.dataList, ...res.data.topic.playList]
          if (res.data.topic.playList.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      }
    },
    //上拉刷新
    refreshData() {
      this.finished = false
      this.pageNum = 0
      this.dataList = []
    },
    //下拉加载
    async moreData() {
      this.pageNum += 1
      await this.getMediaTopic()
    }
  }
}
</script>

<style scoped lang="scss">
.tag-detail {
  :deep(.BackTop) {
    position: fixed;
    z-index: 2;
  }

  .tag-detail-header-wrap {
    position: relative;
    height: 2.58rem;

    .bg-wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #000;
      img {
        opacity: 0.8;
        border: none;
        vertical-align: middle;
        object-fit: cover;
        width: 100%;
        height: 100%;
        background-size: 100%;
      }
      .bg {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        background-color: rgba(0, 0, 0, 0.7);
        //filter: blur(1px);
      }
    }

    .tag-detail-header {
      padding: 1.3rem 0.32rem;
      position: relative;
      display: flex;
      align-items: center;

      .header-left {
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 0.13rem;
        overflow: hidden;
        flex-shrink: 0;
      }

      .header-right {
        margin-left: 0.24rem;

        .header-tag {
          color: #ffffff;
          font-size: 0.28rem;
        }

        .header-an {
          margin-top: 0.12rem;
          display: flex;
          align-items: center;
          color: #999999;
          font-size: 0.24rem;
          line-height: 0.34rem;

          > :nth-child(2) {
            margin-left: 0.16rem;
          }
        }

        .header-desc {
          margin-top: 0.12rem;
          color: #999999;
          font-size: 0.24rem;
          line-height: 0.34rem;
          @include textoverflow(1);
        }
      }
    }
  }

  .tag-detail-content {
    top: -0.6rem;
    position: relative;
    padding: 0.32rem 0;
    background-color: #fff;
    border-radius: 0.4rem 0.4rem 0 0;

    :deep(.van-sticky) {
      z-index: 99 !important;

      .van-tabs__wrap {
        border-bottom: 0.01rem solid #f5f5f5;
      }

      .van-tabs__nav {
        .van-tab {
          width: 50%;
        }
      }

      .van-tab--active {
        &::after {
          transition: all 0.2s ease-in-out;
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.08rem;
          background: url('~@/assets/imgs/tab_bottom.png') center bottom/100% 100% no-repeat;
        }
      }
    }

    .tag-detail-tab {
      .post-list {
        display: flex;
        flex-wrap: wrap;
        padding: 0.24rem;

        .post-item {
          margin-bottom: 0.3rem;

          &:nth-child(odd) {
            margin-right: 0.3rem;
          }
        }
      }
    }
  }
}
</style>
