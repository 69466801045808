<!--
使用方法
    <EHomeSwiper  :list="newSplashAD"  keyName="cover" :config='config' @close="clickBtn"  @clickImg="clickImg" > 
      <img slot="defaultImg" src="~/assets/imgs/homeSwiper.jpg" alt="" />
    </EHomeSwiper>

    imgCDN:图片CDN域名
    list:广告数组（需要遍历数据格式，图片需为img）
    keyName:item里的图片索引
    config: { 轮播其他设置
        countDownTime: 倒计时
        closingTime: 提前可关闭时间
        text: true,提示文字
      },
    @clickImg:点击相对应图片钩子
    @close:关闭钩子
-->
<template>
    <div class="EhomeSwiper">
        <div v-if="adListConfig.list.length">
            <EADSwiper :list="adListConfig.list" :keyName="adListConfig.keyName" :imgCDN="adListConfig.imgCDN"
                @clickImg="clickImg" :EADspeed="speed" :EADdelay="delay" :EADautoplay="autoplay" :EADloop="loop">
            </EADSwiper>
            <div class="count-down" @click.stop="clickBtn">
                {{ countDown }}s<span v-if="countDown <= config.closingTime">({{ config.text }})</span>
            </div>
        </div>
        <div v-else class="defaultImg">
            <slot name="defaultImg"></slot>
            <div class="count-down" @click.stop="clickBtn">
                {{ countDown }}s<span v-if="countDown <= config.closingTime">({{ config.text }})</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'EHomeSwiper',
    components: {
        EADSwiper: () => import('../EADSwiper'),
    },
    props: {
        list: {
            type: Array,
            default () {
                return [];
            },
        },
        imgCDN: {
            typeof: String,
            default () {
                return '';
            },
        },
        keyName: {
            typeof: String,
            default () {
                return 'img';
            },
        },
        config: {
            typeof: Object,
            default () {
                return {
                    countDownTime: 5,
                    closingTime: 3,
                    text: '跳过',
                };
            },
        },
        autoClose: {
            typeof: Boolean,
            default () {
                return false;
            },
        },
        EHomeSwiperConfig: {
            typeof: Object,
            default () {
                return {
                    speed: 300,
                    autoplay: true,
                    loop: true,
                    delay: 2000,
                }
            },
        }
    },
    computed: {
        adListConfig () {
            return {
                list: this.list,
                imgCDN: this.imgCDN,
                keyName: this.keyName,

            };
        },
    },
    async mounted () {
        this.speed = this.EHomeSwiperConfig.speed
        this.autoplay = this.EHomeSwiperConfig.autoplay
        this.loop = this.EHomeSwiperConfig.loop
        this.delay = this.EHomeSwiperConfig.delay
        this.countDown = this.config.countDownTime;
        let interval = setInterval(() => {
            if (this.countDown == 0) {
                if (this.autoClose) {
                    this.$emit('close');
                }
                return clearInterval(interval);
            }
            this.countDown--;
        }, 1000);
    },
    data () {
        return {
            countDown: 0,
            speed: 300,
            autoplay: false,
            loop: true,
            delay: 5000,
        };
    },
    methods: {
        clickBtn (index, arr) {
            if (this.countDown <= this.config.closingTime) {
                this.$emit('close');
            }
        },
        clickImg (item) {
            this.$emit('clickImg', item);
        },
    },
};
</script>

<style lang="scss" scoped>
.EhomeSwiper {
    background: #535353;
    position: fixed;
    z-index: 10000;
    top: 0;
    width: 100%;
    height: 100vh;
    max-width: 540px;
    left: 50%;
    transform: translateX(-50%);

    >div {
        width: 100%;
        height: 100%;
    }

    .defaultImg {
        position: absolute;
        z-index: 5;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        >img {
            width: 100%;
        }
    }
}

.count-down {
    z-index: 10001;
    background: rgba($color: #000000, $alpha: 0.5);
    position: absolute;
    top: 0.3rem;
    right: 0.5rem;
    font-size: 0.28rem;
    width: 1.6rem;
    height: 0.7rem;
    line-height: 0.7rem;
    text-align: center;
    border-radius: 999px;
    color: #fff;
}
</style>
