<template>
  <div
    class="audit-status"
    :class="{ 'is-success': data.base.status == 1, 'is-inprogress': ![1, 4].includes(data.base.status) }"
  >
    <span> {{ data.base.remark }} </span>
    <span>审核{{ data.base.status == 1 ? '通过' : data.base.status == 4 ? '拒绝' : '中' }}</span>
  </div>
</template>
<script>
export default {
  name: 'AuditStatus',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
.audit-status {
  padding-top: 0.18rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ff3a3a;
  font-family: PingFang SC;
  font-size: 0.2rem;
  font-style: normal;
  font-weight: 400;
  border-top: 1px solid #4e4e4e;
  margin-top: 0.36rem;
  &.is-success {
    > span {
      &:nth-child(2) {
        border: 1px solid #00f6f8;
        background: rgba(0, 246, 248, 0.15);
      }
    }
  }
  &.is-inprogress {
    > span {
      &:nth-child(2) {
        border: 1px solid #00f6f8;
        background: rgba(190, 145, 85, 0.3);
      }
    }
  }

  > span {
    &:nth-child(2) {
      flex: none;
      height: 0.42rem;
      border-radius: 0.04rem;
      background: #862424;
      padding: 0 0.16rem;
      margin-left: 0.4rem;
      line-height: 0.42rem;
      color: #000;
      font-family: PingFang SC;
      font-size: 0.24rem;
      font-style: normal;
      font-weight: 400;
    }
  }
}
</style>
