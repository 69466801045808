import Vue from 'vue';
<template>
  <div class="setting_index">
    <EBackTop title="设置中心"></EBackTop>
    <ul class="setting_list">
      <li class="avatar_item" @click="$router.push(`/Mine/setting/avatar?userAvatarUrl=${info.avatarUrl}`)">
        <p class="label">头像</p>
        <div class="value">
          <EDecryptImg :imgCDN="imgCDN" class="md-mine-photo" :imgURL="info.avatarUrl ? info.avatarUrl : ''">
          </EDecryptImg>
          <van-icon name="arrow" size="0.36rem" color="#DDDDDD" />
        </div>
      </li>
      <li @click="$router.push(`/Mine/setting/nickName`)">
        <p class="label">昵称</p>
        <div class="value">
          <p>{{ info.nickName }}</p>
          <van-icon name="arrow" size="0.36rem" color="#DDDDDD" />
        </div>
      </li>
      <li>
        <p class="label">Mr.91撸色ID</p>
        <div class="value">
          <p>{{ info.id }}</p>
          <div
            v-clipboard:copy="JSON.stringify(info.id)"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            class="btn"
          >
            复制
          </div>
        </div>
      </li>
      <li @click="$router.push(`/Mine/setting/userSign`)">
        <p class="label">个性签名</p>
        <div class="value">
          <p>{{ info.introduction ? info.introduction : '未设置' }}</p>
          <van-icon name="arrow" size="0.36rem" color="#DDDDDD" />
        </div>
      </li>
      <li @click="$router.push('/Mine/setting/kf')">
        <p class="label">在线客服</p>
        <div class="value">
          <p>24小时在线</p>
          <van-icon name="arrow" size="0.36rem" color="#DDDDDD" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    info({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  methods: {
    onCopy({ val }) {
      console.log(val)
      this.$toast('复制成功！！！')
    },
    onError() {
      this.$toast('复制失败！！！')
    }
  }
}
</script>

<style lang="scss" scoped>
.setting_index {
  min-height: 100vh;
  background: #fafafa;
}

.setting_list {
  background-color: #fff;
  margin: 0.24rem 0.32rem;

  li {
    @include flexbox();
    margin: 0 0.24rem;
    padding: 0.24rem 0;
    border-bottom: 0.01rem solid #f0f0f0;

    &:last-child {
      border-bottom: none;
    }

    .md-mine-photo {
      width: 0.6rem;
      height: 0.6rem;
    }

    .value {
      @include flexbox();
    }
  }
}

.value {
  color: #999;
}

.btn {
  border-radius: 0.36rem;
  background: #e8619d;
  padding: 0.06rem 0.24rem;
  color: #fff;
  margin-left: 0.2rem;
}
</style>
