<template>
  <div class="popup-list">
    <van-popup
      :value="show"
      get-container="body"
      position="center"
      round
      closeable
      :close-icon="require('@/assets/imgs/close.png')"
      @input="toClose"
    >
      <!--play_post 提示-->
      <div class="play_post">
        <div class="content">
          <img src="@/assets/imgs/aiLab/pay_warning.png" alt="" class="warnImges" />
          <div class="text-content">
            <p class="first">
              您确认支付：<span style="color: #e8619d">
                {{ isVipMember ? ((price * movieDiscount) / 10000).toFixed(1) : price / 100 }}金币</span
              >？
            </p>
            <p class="second">
              <span>当前余额:</span>
              <span> {{ userInfo.balance | changeGold }}金币</span>
            </p>
          </div>
        </div>
        <div class="btn-wrap">
          <!-- <div @click="toPay">确认支付</div>
          <div @click="toPay">确认支付</div> -->
          <div class="movie-gold-btn" @click="toPay(1)">
            <img src="@/assets/imgs/coin2.png" alt="" />
            使用 {{ isVipMember ? ((price * movieDiscount) / 10000).toFixed(1) : price / 100 }}金币
          </div>
          <!-- 观影券在帖子不显示 -->
          <div class="movie-tickets-btn" @click="toPay(2)" v-if="$route.name !== 'CommunityDetail'">
            <img src="@/assets/imgs/movie_icon.png" alt="" />
            观影券:{{ movieTickets ? movieTickets : 0 }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'show',
    event: 'input'
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    price: {
      default: 0
    },
    movieTickets: {
      type: Number,
      default: 0
    },
    movieDiscount: {
      type: Number,
      default: 0
    },
    callback: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      popupMsg: {}
    }
  },
  computed: {
    isVipMember({ $store }) {
      return $store.getters['isMember']
    },
    userInfo() {
      return this.$store.getters['getUserInfo']
    },

    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {},
  methods: {
    toClose() {
      this.$emit('input', false)
    },
    toPay(type) {
      this.toClose()
      this.$emit('confirm', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.play_post {
  width: 5.4rem;
  background: #ffffff;

  .content {
    height: 2.88rem;
    font-size: 0.28rem;
    @include flex-column-center;
    text-align: center;

    .warnImges {
      width: 0.88rem;
      height: 0.88rem;
    }

    .text-content {
      margin-top: 0.4rem;

      .first {
        color: #333333;
      }

      .second {
        margin-top: 0.2rem;
        color: #666666;
        font-size: 0.28rem;
        @include flex-center;

        > :nth-child(1) {
          flex-shrink: 0;
          background-color: rgba(0, 0, 0, 0.04);
          border-radius: 0.42rem;
          width: 1.44rem;
          height: 0.42rem;
          @include flex-center;
        }

        > :nth-child(2) {
          margin-left: 0.16rem;
          font-size: 0.24rem;
          color: rgba(221, 0, 27, 1);
        }
      }
    }
  }

  .btn-wrap {
    border-top: 0.02rem solid #f0f0f0;
    font-size: 0.28rem;
    height: 1.36rem;
    @include flex-center;

    > div {
      color: #ffffff;
      border-radius: 2rem;
      padding: 0.16rem 0.24rem;
      margin: 0 0.12rem;
      background: $gradientBg;
      @include flex-center;

      img {
        margin-right: 0.05rem;
        width: 0.26rem;
      }
    }
  }
}
</style>
