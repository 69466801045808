<template>
  <div class="my-back">
    <EBackTop title="常见问题">
      <slot slot="right" name="right">
        <router-link class="tag" to="/Mine/setting/kf">
          <div class="right_txt">都没解决?<span>问客服</span></div>
        </router-link>
      </slot>
    </EBackTop>
    <!-- <ul>
      <li v-for="(item, index) in textList" :key="index">
        <div class="title" :class="showA === index ? 'padClass' : ''">
          <div class="title_info">
     
            <span :class="showA !== index ? 'textOver' : ''"> {{ item.question }}</span>
          </div>

          <p class="btn" @click="changeShow(index)">{{ showA ? '收起' : '展开' }}</p>
        </div>
        <div v-if="showA === index" class="desc">
          <span> {{ item.answer }}</span>
        </div>
      </li>
    </ul> -->
    <ul class="list_main">
      <li class="title">常见问题和反馈</li>
      <li>
        <p>为什么充值不了？</p>
        <p>由于网络问题，请尝试其他的充值渠道或重启app再次尝试获取充值链接，如未解决，请联系客服。</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { faq_list } from '@/api/home'

export default {
  components: {
    EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue')
  },
  data() {
    return {
      showA: undefined,
      textList: []
    }
  },
  mounted() {
    this.getFaq()
  },
  methods: {
    changeShow(index) {
      if (this.showA === index) {
        this.showA = undefined
      } else {
        this.showA = index
      }
    },
    async getFaq() {
      const res = await faq_list({})
      if (res.code === 200) {
        this.textList = res.data.list
      } else {
        return this.$toast(res.tip)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my-back {
  background-size: cover;
  min-height: 100vh;
}
.right_txt {
  font-size: 0.2rem;
  span {
    color: $txtActive;
  }
}
// ul {
//   padding: 0.36rem;
// }
// li {
//   border-radius: 0.08rem;
//   padding: 0.32rem;
//   margin-top: 0.12rem;
//   background: $txtActive;
//   .title {
//     @include flexbox();
//     color: #fff;
//     font-size: 0.26rem;
//     font-weight: 400;
//     .title_info {
//       @include flexbox($jc: flex-start);
//     }
//     img {
//       width: 0.24rem;
//       margin-right: 0.12rem;
//     }
//     .btn {
//       color: #f9c006;
//     }
//     .textOver {
//       @include textoverflow();
//     }
//   }
//   .padClass {
//     padding-bottom: 0.36rem;
//   }
//   .desc {
//     @include flexbox($jc: flex-start, $ai: flex-start);
//     font-size: 0.26rem;
//     font-weight: 400;
//     padding-top: 0.36rem;
//     color: #fff;
//     span {
//       white-space: pre-line;
//     }
//     img {
//       width: 0.24rem;
//       margin-right: 0.12rem;
//     }
//   }
// }
.list_main {
  padding: 0.36rem 0.32rem;
  .title {
    color: #e8619d;
    font-size: 0.32rem;
    font-weight: 600;
    margin-bottom: 0.24rem;
  }
  li {
    p {
      color: #999;
      font-size: 0.24rem;
      &:first-child {
        color: #666;
        font-weight: 600;
        margin-bottom: 0.2rem;
      }
    }
  }
}
</style>
