<!--
 * @Author:
 * @Mail:
 * @Date: 2024-01-31 15:40:02
 * @LastEditTime: 2024-02-07 21:36:05
 * @LastEditors: 江涛 wangtao524713@gmail.com
 * @FilePath: /haoseH5/src/views/Home/index.vue
-->
<!-- home -->
<template>
  <div class="index-container">
    <HomeSearch class="HomeSearch" />
    <!--    <img v-if="currentTab.isPreview" class="Intersect" src="@/assets/imgs/Intersect.png" alt />-->
    <!-- <ETabBar :list="mediaCategory" :tabbar-config="tabbarConfig" :active-num="activeId" class="ETabBar"
      @change="changeTab">
      <template #default="scope">
        <div class="HomeComponent">
          <component :is="compComponent(scope.data.showType)" :id="activeId ? activeId : mediaCategory[0]?.id"
            :navItem="scope.data" @handleClickTitle="handleClickTitle" />
        </div>
      </template>
</ETabBar> -->
    <ETab
      ref="elTab"
      class="container-tab"
      :sticky="true"
      :tab-list="mediaCategory"
      :v-model="activeId"
      :animated="false"
      :line-switch="true"
      :line-bg="'#e8619d'"
      :line-height="'0.08rem'"
      :line-width="'0.4rem'"
      :title-active-color="'#e8619d'"
      :title-inactive-color="'#999999'"
      :size-tab="'0.32rem'"
      :size-active="'.38rem'"
      @change="changeTab"
    >
      <template #default="scope">
        <div class="HomeComponent">
          <component
            :is="compComponent(scope.item.showType)"
            :id="activeId ? activeId : mediaCategory[0]?.id"
            :navItem="scope.item"
            @handleClickTitle="handleClickTitle"
          />
        </div>
      </template>
    </ETab>
    <div
      v-if="$store.state.config.config.shortVideoEnable"
      class="short-video-enter"
      :style="{ transform: `translate(${showLotteryIcon ? 0 : '34.667vw'})` }"
      @click.stop="toShortVidoe"
    >
      <img src="@/assets/imgs/home/circle.png" alt="" />
    </div>
    <div class="menu" @click="show = true">
      <img src="~/assets/imgs/home/menu.png" alt="" />
    </div>
    <van-popup v-model="show" position="left" class="menuPop">
      <div class="menuPopTitle">导航列表</div>
      <div class="menuPopContent">
        <div
          v-for="(item, index) in mediaCategory"
          :key="index"
          :class="item.id === activeId ? 'active' : ''"
          @click="clickMenu(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </van-popup>
    <div class="lottery_icon" :style="{ transform: `translate(${showLotteryIcon ? 0 : '34.667vw'})` }">
      <div v-if="$store.state.config.config.lotteryEnable" class="lottery-img" @click.stop="toLottery">
        <EDecryptImg :imgURL="$store.state.config.config.lotteryEntryImage" class="cover"> </EDecryptImg>
        <!-- <img src="@/assets/imgs/lottery_icon.webp" alt="" /> -->
      </div>
      <!-- ai悬浮 -->
      <div v-if="$store.state.config.config.aIEnable" class="ai-inside-box" :class="{ close: !isShowAi }">
        <div v-if="isShowAi" class="show-inside">
          <img src="@/assets/imgs/aiLab/close_inside_icon.svg" alt="" @click="isShowAi = !isShowAi" />
          <img src="@/assets/imgs/aiLab/ai_inside.png" alt="" @click="$router.push('/ai/generate')" />
          <!-- <img src="@/assets/imgs/aiLab/icon_ai_arrow.gif" alt="" /> -->
        </div>
        <div v-else class="inside-close">
          <img src="@/assets/imgs/aiLab/inside_close.png" alt="" @click.stop="isShowAi = !isShowAi" />
        </div>
      </div>
    </div>
    <van-popup
      v-model="showModal"
      position="center"
      get-container="body"
      :closeable="false"
      :overlay="false"
      style="
        z-index: 101;
        background-color: rgba(0, 0, 0, 0.7);
        width: 100%;
        height: calc(100% - 2rem);
        bottom: 0;
        backdrop-filter: blur(8px);
      "
    >
      <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%">
        <div style="width: 6.34rem; position: relative">
          <img src="@/assets/imgs/home/darkMode.png" alt="" style="width: 6.34rem" />
          <div
            style="position: absolute; bottom: 0; right: 0; width: 2rem; height: 1rem"
            @click.stop="
              $router.push('/Mine/vip')
              showModal = false
            "
          ></div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
import { JumpTo } from '@/utils/utils_tools'
export default {
  name: 'HomePage',
  components: {
    HomeSearch: () => import('@/components/Home/homeSeach.vue'),
    Default: () => import('@/components/Home/default.vue'),
    PageType1: () => import('@/components/Home/pageType1.vue'),
    PageType2: () => import('@/components/Home/pageType2.vue')
  },
  mixins: [scrollMixin],
  provide() {
    return {
      getVideoPreview: () => this.currentTab.isPreview
    }
  },
  data() {
    return {
      container: null,
      activeId: 0,
      isShowAi: true,
      show: false,
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.32rem', //默认字体大小
        fontSizeActive: '0.32rem', //选中字体大小
        fontColor: '#333333', //默认字体颜色
        fontColorActive: '#E8619D', //选中字体颜色
        bg: '#fff', //默认背景色
        bgActive: '#fff', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#E8619D',
        lineWidth: '0.16rem', //下划线宽度 String
        sticky: true, //粘性布局
        offsetTop: '0rem', //距顶部距离
        tabFlex: true
      },
      showLotteryIcon: true,
      oldScrollTop: 0,
      showModal: false,
      timer: null
    }
  },

  computed: {
    // 获取顶部分类
    mediaCategory({ $store }) {
      return $store.getters['mediaCategory']
    },
    // 获取首页底部轮播广告
    short_videoAD({ $store }) {
      return $store.getters['short_videoAD']
    },
    // 获取token
    token({ $store }) {
      return $store.getters['getToken']
    },
    userInfo() {
      return this.$store.getters['getUserInfo']
    },
    hasDarkPermission() {
      return this.$store.getters['hasDarkPermission']
    },
    currentTab() {
      return this.mediaCategory.find(item => item.id === this.activeId) || {}
    }
  },
  created() {
    // this.container = this.$refs.container
    if (this.mediaCategory) {
      this.activeId = this.mediaCategory[0].id
    }
  },
  activated() {
    this.setDarkModePop()
    window.addEventListener('scroll', this.scrollEvent, true)
  },
  deactivated() {
    this.showModal = false
    console.log('移除监听')
    window.removeEventListener('scroll', this.scrollEvent, true)
  },
  methods: {
    setDarkModePop() {
      const showType = this.mediaCategory.find(item => item.id === this.activeId).showType
      if (!this.mediaCategory.find(item => item.id === this.activeId).isBlur) {
        this.showModal = false
      } else {
        this.showModal = showType === 4 && !this.hasDarkPermission
      }
    },
    scrollEvent() {
      // let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // let scrollStep = scrollTop - this.oldScrollTop
      // this.oldScrollTop = scrollTop
      // this.showLotteryIcon = scrollStep < 0
      this.showLotteryIcon = false
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.scrolledEndEvent()
      }, 300)
    },
    scrolledEndEvent() {
      this.showLotteryIcon = true
      clearTimeout(this.timer)
      this.timer = null
    },
    handleClickTitle(id) {
      this.activeId = id

      this.$refs.elTab.active = id
    },
    toLottery() {
      JumpTo(this.$store.state.config.config.lotteryUrl)
      // window.open(`https://haosezhuanpan.80sygtm.com/?token=${this.token}`)
    },
    changeTab(id) {
      this.activeId = id
      this.setDarkModePop()
      // this.showModal = false
    },
    clickMenu(id) {
      this.activeId = id
      this.$refs.elTab.active = id
    },
    //default 携带金刚区 ,PageType1 视频列表，PageType2 图集列表  4 暗网
    compComponent(data) {
      switch (data) {
        case 1:
        case 4:
          return 'Default'
        case 2:
          return 'PageType1'
        case 3:
          return 'PageType2'
      }
    },
    toShortVidoe() {
      this.$router.push('/play/shortVideo?type=recommend')
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .van-tabs__wrap {
    max-width: 7.5rem;
    // margin: 0 0.88rem 0 0;
  }

  .van-pull-refresh__head {
    height: 0.78rem;
    line-height: 0.78rem;
  }
}

.index-container {
  padding: 0 0 0 0;
  position: relative;
  overflow: hidden;

  .HomeSearch {
    position: fixed;
    top: 0.77rem;
    z-index: 101;
  }

  .Intersect {
    position: fixed;
    width: 0.36rem;
    height: 0.46rem;
    top: 300px;
    left: 0;
    z-index: 12;
  }

  .container-tab {
    :deep() {
      .van-sticky {
        position: fixed;
        top: 0;
        left: calc(50% - 0.44rem);
        right: 0;
        z-index: 101;
        width: calc(100% - 0.88rem);
        transform: translate(-50%, 0);
        max-width: $pcMaxWidth;
      }
    }
  }

  .HomeComponent {
    margin-top: 1.6rem;
  }

  .short-video-enter {
    position: fixed;
    bottom: 2.46rem;
    right: 0.32rem;
    width: 1rem;
    height: 1rem;
    transition: transform 0.5s;
    z-index: 101;

    > img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .menu {
    width: 0.8rem;
    height: 0.8rem;
    position: fixed;
    top: 0;
    right: 0.1rem;
    z-index: 99;
    background-color: #fff;

    > img {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  .menuPop {
    height: 100%;
    width: 4.28rem;
    background: rgba($color: #1d1d26, $alpha: 0.75);
    color: #fff;
    padding: 1.2rem 0.32rem;

    .menuPopTitle {
      font-size: 0.32rem;
    }

    .menuPopContent {
      display: flex;
      flex-flow: wrap;
      margin-top: 0.32 * 2rem;

      > div {
        width: 0.8 * 2rem;
        height: 0.4 * 2rem;
        background-color: #272733;
        margin: 0 0.11rem 0.32rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.12 * 2rem;
        font-weight: 500;
      }

      .active {
        background-color: #e8619d;
        color: #fff;
      }
    }
  }
}

.lottery_icon {
  position: fixed;
  right: 0;
  bottom: 4rem;
  z-index: 101;
  transition: transform 0.5s;

  .lottery-img {
    height: 2rem;
    width: 1.5rem;
    margin: 0 0 0.2rem 0.45rem;
  }

  .ai-inside-box {
    height: 2rem;

    &.close {
      width: 0.8rem;
      height: 1.2rem;
    }

    .show-inside {
      position: relative;
      height: 100%;

      img {
        &:nth-child(1) {
          width: 0.32rem;
          height: 0.32rem;
          position: absolute;
          left: 0.5rem;
          top: 0;
          z-index: 1;
        }

        &:nth-child(2) {
          width: 1.5rem;
          height: 2rem;
          position: absolute;
          right: 0;
          top: 0;
        }

        &:nth-child(3) {
          width: 0.47rem;
          height: 0.27rem;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }

    .inside-close {
      margin-left: 0.8rem;
      width: 0.8rem;

      img {
        position: absolute;
        width: 0.8rem;
        right: 0;
      }
    }
  }
}
</style>
