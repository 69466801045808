<template>
    <div>
        <EBackTop class="header" @click-right="checkRight" :title="'个性签名'">
            <slot slot="right" name="right">
                <div style="color: #999;">
                    保存
                </div>
            </slot>
        </EBackTop>
        <div class="nickName_setting">
            <!-- 输入任意文本 -->
            <van-field v-model="introduction" type="textarea" maxlength="150" rows="4" autosize show-word-limit
                placeholder="有趣的介绍能让你的逼格提高N个档次！..." />
        </div>
    </div>
</template>

<script>
import { update_info } from 'api/user'
export default {
    data() {
        return {
            introduction: undefined
        }
    },
    computed: {
        imgCDN({ $store }) {
            return $store.getters['cdn']
        },
        isMember({ $store }) {
            return $store.getters['isMember']
        },
        info({ $store }) {
            return $store.getters['getUserInfo']
        },
    },
    mounted(){
        this.introduction = this.info.introduction
    },
    methods: {
        // 完成按钮
        async checkRight() {
            if (!this.introduction) return this.$toast('请输入新的昵称！')
            if (this.isMember) {
                const res = await update_info({
                    introduction: this.introduction
                })
                if (res.code === 200) {
                    this.$toast('修改成功')

                } else {
                    this.$toast(res.tip)
                }
            } else {
                this.$toast('亲，您还不是会员，不能修改噢')
            }
        },
        checkClear() {
            this.introduction = undefined
        }
    },

}

</script>

<style lang="scss" scoped>
.nickName_setting {
    margin: 0.76rem 0.32rem 0 0.32rem;

    h1 {
        font-weight: 600;
        font-size: 0.48rem;
    }
}

:deep() {
    .van-field {
        color: #999 !important;
        padding-bottom: 0.22rem;
        font-size: 0.24rem;
        border-radius: 0.16rem;
        background: var(--4, rgba(0, 0, 0, 0.04));
        padding: 0.2rem;

    }
}
</style>