<!--
 * @Author:
 * @Mail:
 * @Date: 2024-02-01 11:52:06
 * @LastEditTime: 2024-02-09 11:48:08
 * @LastEditors: 江涛 wangtao524713@gmail.com
 * @FilePath: /haoseH5/src/components/Home/showType/videoListType/components/videoshowType1.vue
-->
<template>
  <ERefreshLoadV3 ref="ERefreshLoadV3" :beforeRefresh="onRefresh" :moreData="onLoad" :finished="finished">
    <ul class="main_list">
      <li v-for="item in dataList" :key="item.id" class="item">
        <div class="top" @click.stop="handleClick(item)">
          <!-- 遮罩层 -->
          <div v-if="showMask" class="overlay" @click.stop="clickItem(item)">
            <img
              :src="
                item.check
                  ? require('@/assets/imgs/mine/active_check.png')
                  : require('@/assets/imgs/mine/default_check.png')
              "
              alt=""
            />
          </div>
          <div class="bodan-img">
            <EDecryptImg :imgURL="item.cover"> </EDecryptImg>
          </div>
          <div class="pos-bottom">
            <div class="left">
              <img class="count-img" src="@/assets/imgs/discover/layer.png" alt="" />
              <span>{{ item.total }}</span>
            </div>
            <div v-if="item.type == 2" class="right">
              <img class="item-img" src="@/assets/imgs/discover/photo.png" alt="" />
              <span>图片 </span>
            </div>
            <div v-else class="right">
              <img class="item-img" src="@/assets/imgs/discover/video.png" alt="" />
              <span>视频 </span>
            </div>
          </div>
        </div>
        <div class="bottom">{{ item.title }}</div>
        <ul class="tags">
          <li v-for="tag in item.tags" :key="tag.id">{{ tag.name }}</li>
        </ul>
      </li>
    </ul>
  </ERefreshLoadV3>
</template>

<script>
import { collect_list, collect_del } from 'api/user'

export default {
  components: {},
  props: {
    cardType: {
      type: String,
      default: 'Collect'
    },
    showMask: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 关注列表
      dataList: [],
      // 当前页
      pageNum: 0,
      // 当前个数
      pageSize: 10,
      showUpBtn: false
    }
  },
  computed: {
    info({ $store }) {
      return $store.getters['getUserInfo']
    },
    // 获取长视频历史记录
    getPlayList({ $store }) {
      return $store.getters['getPlayList']
    }
  },

  mounted() {},

  methods: {
    handleClick(item) {
      this.$router.push({
        path: '/discover/detail',
        query: {
          id: item.id,
          type: item.type
        }
      })
    },
    clickItem(item) {
      this.$set(item, 'check', !item.check)
    },
    async delOne() {
      if (this.cardType === 'history') {
        this.$store.dispatch('setPlayList', { type: 'del', arr: this.dataList.filter(item => !item.check) })
        this.dataList = JSON.parse(JSON.stringify(this.getPlayList))
        this.finished = true
      } else if (this.cardType === 'collect') {
        const res = await collect_del({
          collectType: 13,
          objectIds: this.dataList.filter(item => item.check).map(item => item.id),
          type: 1
        })
        if (res.code === 200) {
          this.pageNum = 1
          this.dataList = []
          await this.getPlayCollectList()
        } else {
          return this.$toast('操作失败，请联系后台管理员！')
        }
      }
    },
    toSelectAll(val = true) {
      this.dataList.forEach(item => {
        this.$set(item, 'check', val)
      })
    },
    // 下拉加载
    async onLoad() {
      this.pageNum++
      if (this.cardType === 'collect') {
        await this.getPlayCollectList()
      } else if (this.cardType === 'history') {
        this.dataList = JSON.parse(JSON.stringify(this.getPlayList))
        this.finished = true
      }
    },
    // 上拉刷新
    async onRefresh() {
      this.pageNum = 0
      this.dataList = []
      this.getPlayCollectList()
    },
    // 收藏列表
    async getPlayCollectList() {
      const res = await collect_list({
        collectType: 13,
        userId: this.info.id,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        type: 1
      })
      if (res.code == 200) {
        this.dataList = [...this.dataList, ...(res.data.playList || [])]
        if (res.data.playList?.length < this.pageSize || !res.data.playList) {
          this.finished = true
        }
      } else {
        this.finished = true
      }
    }
    // 购买列表 pay_history
    // async getPlayPayList() {
    //   const res = await pay_history({
    //     type: 13,
    //     pageSize: this.pageSize,
    //     pageNum: this.pageNum
    //   })
    //   if (res.code == 200) {
    //     this.dataList = [...this.dataList, ...(res.data.dataList || [])]
    //     if (res.data.dataList?.length < this.pageSize || !res.data.dataList) {
    //       this.finished = true
    //     }
    //   } else {
    //     this.finished = true
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
.main_list {
  padding: 0.32rem;
  @include flexbox($fw: wrap);
}
.item {
  width: 1.68 * 2rem;
  margin-bottom: 0.16rem;
  .top {
    width: 1.68 * 2rem;
    height: 1.73 * 2rem;
    position: relative;
    background: #7a6a6a;
    border-radius: 0.2rem;
    position: relative;

    .bodan-img {
      width: 1.68 * 2rem;
      height: 1.68 * 2rem;
      overflow: hidden;
      border-radius: 0.2rem;
      border-top: 0.05rem solid #fff;
      box-sizing: content-box;
      position: absolute;
      top: 0.08rem;
    }

    .pos-bottom {
      position: absolute;
      width: 100%;
      height: 0.76rem;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
      // background: red;
      left: 0;
      bottom: 0;
      border-radius: 0rem 0rem 0.2rem 0.2rem;
      color: $white;
      padding: 0 0.16rem;
      @include flexbox;

      > div {
        @include flex-align-center;
      }

      .count-img {
        width: 0.28rem;
        margin-right: 0.08rem;
      }

      .item-img {
        width: 0.26rem;
        margin-right: 0.08rem;
      }
    }
  }

  .bottom {
    margin-top: 0.16rem;
    color: $txtDefault;
    font-size: 0.26rem;
    font-style: normal;
    font-weight: 500;
    @include textoverflow;
  }
}
.overlay {
  width: 100%;
  height: 100%;
  border-radius: 0.2rem;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  img {
    width: 0.48rem;
    height: 0.48rem;
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
  }
}
</style>
