<template>
  <div class="ranking-list-box">
    <EBackTop title="排行榜"></EBackTop>
    <ETabBar :list="topTabList" :tabbar-config="tabbarConfig" :active-num="activeId" @change="tabbarChange">
      <template #default="scope">
        <ul class="my-tab-list">
          <li
            v-for="item in bottomTabList"
            :key="item.id"
            :class="{ active: item.id === activeIndex }"
            @click="clickTab(item.id)"
          >
            {{ item.name }}
          </li>
        </ul>
        <ERefreshLoadV3 ref="ERefreshLoadV3" :beforeRefresh="beforeRefresh" :moreData="onLoad" :finished="finished">
          <ShowList :list="ranklist" :showType="type" />
        </ERefreshLoadV3>
      </template>
    </ETabBar>
  </div>
</template>

<script>
import { ranking_list } from '@/api/discover'
import scrollMixin from '@/utils/scrollMixin'
export default {
  components: {
    ShowList: () => import('./components/showList.vue')
  },
  mixins: [scrollMixin],
  data() {
    return {
      activeId: 1,
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.32rem', //默认字体大小
        fontSizeActive: '0.32rem', //选中字体大小
        fontColor: '#333333', //默认字体颜色
        fontColorActive: '#E8619D', //选中字体颜色
        bg: '#fff', //默认背景色
        bgActive: '#fff', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#E8619D',
        lineWidth: '0.16rem', //下划线宽度 String
        sticky: true, //粘性布局
        offsetTop: '0rem', //距顶部距离
        tabFlex: true
      },
      tabbarConfig2: {
        barWidth: '4.8rem,',
        barHeight: '0.88rem',
        fontSize: '0.24rem', //默认字体大小
        fontSizeActive: '0.32rem', //选中字体大小
        fontColor: '#333333', //默认字体颜色
        fontColorActive: '#fff', //选中字体颜色
        bg: '#f2f2f2', //默认背景色
        bgActive: '#E8619D', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#fff',
        lineWidth: '0.16rem', //下划线宽度 String
        sticky: true, //粘性布局
        offsetTop: '0rem', //距顶部距离
        tabFlex: true
      },
      topTabList: [
        {
          id: 1,
          name: '影视'
        },
        {
          id: 2,
          name: '图集'
        },
        {
          id: 4,
          name: '帖子'
        },
        {
          id: 3,
          name: '播单'
        },
        {
          id: 5,
          name: '艺人'
        }
      ],
      bottomTabList: [
        {
          id: 1,
          name: '日榜'
        },
        {
          id: 2,
          name: '周榜'
        },
        {
          id: 3,
          name: '月榜'
        },
        {
          id: 4,
          name: '年榜'
        },
        {
          id: 5,
          name: '综合'
        }
      ],
      activeIndex: 1,
      finished: false,
      pageNum: 1,
      pageSize: 10,
      rankType: 1,
      type: 1,
      ranklist: []
    }
  },
  computed: {
    category({ $store }) {
      return $store.getters['mediaCategory']
    }
  },
  methods: {
    tabbarChange(id) {
      this.type = id
      this.ranklist = []
      this.pageNum = 0
      this.onLoad()
    },
    async onLoad() {
      this.pageNum++
      await this.getList()
    },
    // 下拉刷新
    async beforeRefresh() {
      this.pageNum = 0
      this.finished = false
      this.ranklist = []
    },
    async getList() {
      try {
        const res = await ranking_list({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          rankType: this.rankType,
          type: this.type
        })
        if (res.code === 200) {
          const map = {
            1: 'mediaList',
            2: 'photoList',
            3: 'playList',
            4: 'postList',
            5: 'actorList'
          }
          const list = res.data[map[this.type]]
          this.ranklist = [...this.ranklist, ...list]
          if (res.data && list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
          return this.$toast(res.tip)
        }
      } catch (error) {
        this.finished = true
        this.$toast('请求错误，请稍后再试！')
      }
    },
    clickTab(id) {
      this.activeIndex = id
      this.rankType = id
      this.ranklist = []
      this.pageNum = 0
      this.onLoad()
    },
    compComponent(data) {
      switch (data) {
        case 0:
          return 'RankVideo'
        case 2:
          return 'RankPhoto'
        case 3:
          return 'RankPost'
        case 4:
          return 'RankPlayList'
        case 5:
          return 'RankGirl'
        default:
          return 'RankVideo'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my-tab-list {
  display: flex;
  width: 4.8rem;
  height: 0.42rem;
  margin: 0 auto;
  border-radius: 0.21rem;
  background: rgba(0, 0, 0, 0.05);
  padding: 0;
  > li {
    flex: 1;
    @include flex-center;
    color: #999;
    text-align: center;
    font-family: 'PingFang SC';
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 0.27rem;
    transition: all 0.5s;
    &.active {
      background: #e8619d;
      color: #fff;
    }
  }
}
.ranking-list-box {
  width: 100%;
}
:deep() .van-tabs__nav {
  justify-content: space-evenly;
}
</style>
