<template>
  <ul class="six-list">
    <DreamShowType1 v-for="(data, index) in list" :key="index" :data="data" />
  </ul>
</template>
<script>
export default {
  name: 'DreamList',
  components: {
    DreamShowType1: () => import('@/components/Home/showType/dreamType/dreamShowType1.vue')
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
.six-list {
  @include flexbox($jc: flex-start, $fw: wrap);

  > div {
    width: 2.18rem;
    margin-right: 0.14rem;
    flex: none;
    margin-bottom: 0.2rem;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
