<template>
  <div class="video_card_mask">
    <div>
      <img src="@/assets/imgs/home/playLogo.png" alt="" />
      <span>{{ data.watchTimes | numberFilter }}</span>
    </div>
    <div>{{ data.playTime | secondToDate }}</div>
  </div>
</template>
<script>
export default {
  name: 'VideoCardMask',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.video_card_mask {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.6rem;
  padding: 0 0.12rem;
  font-size: 0.22rem;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

  > div {
    display: flex;
    align-items: center;

    img {
      width: 0.28rem;
      height: 0.28rem;
      margin-right: 0.04rem;
    }
  }
}
</style>
