<template>
  <div class="bind-phone">
    <EBackTop title="登陆"></EBackTop>
    <!-- 登陆box -->
    <div class="login-box">
      <!-- 切换按钮 -->
      <van-tabs v-model="loginActive" class="wallet_tabs" @change="change">
        <van-tab title="手机登陆"></van-tab>
        <van-tab title="账号登陆"></van-tab>
      </van-tabs>
      <!-- 登陆输入 -->
      <div class="login-input">
        <!-- 手机号登陆 -->
        <div v-if="loginActive === 0" class="bind-phone-main">
          <p v-if="showCodeTip" class="err-desc">对不起,验证码错误,请重新输入</p>
          <div class="phone-number">
            <van-field v-model="country" class="country-input" placeholder="编码" />
            <div class="line"></div>
            <van-field
              v-model="mobile"
              class="phone-input"
              type="number"
              maxlength="11"
              placeholder="输入您的手机号码"
            />
          </div>
          <div class="code-number">
            <van-field
              v-model="captcha"
              maxlength="6"
              type="number"
              class="captcha-input"
              placeholder="输入短信验证码"
            />
            <div class="btn" :class="{ disabled: time }" @click="getCaptcha">
              {{ time ? num + 's' : '获取验证码' }}
            </div>
          </div>
        </div>
        <!-- 账号登陆 -->
        <div v-else class="user-input">
          <p v-if="showPwdTip" class="err-desc">对不起,账号或密码输入错误,请重新输入</p>
          <div class="phone-number">
            <van-field v-model="userName" class="phone-input" placeholder="输入用户名" />
          </div>
          <div class="code-number">
            <van-field v-model="pwd" type="password" placeholder="请输入密码" />
          </div>
        </div>
        <div class="tip_desc">
          <span @click.stop="$router.push(`/mine/setting/accoutCodeCamera`)">使用账号二维码凭证登录</span>
          <div v-if="loginActive">
            <span> 没有账号？ </span>
            <span class="active_txt" @click.stop="$router.push('/mine/register')">去注册</span>
          </div>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="submit" @click="logoinBtn">登录</div>
    </div>
  </div>
</template>

<script>
import { phone_login_api, account_login_api, login_captcha_api } from '@/api/login'
export default {
  components: {
    EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue')
  },
  data() {
    return {
      country: '+86',
      mobile: '',
      captcha: undefined, //验证码
      time: false, //定时器开关
      num: 60,
      countryArr: [],
      timer: null,
      myCategoryShow: [
        { id: 0, name: '手机登陆' },
        { id: 1, name: '账号登陆' }
      ],
      loginActive: 0,
      userName: '',
      pwd: '',
      showCodeTip: false,
      showPwdTip: false
    }
  },
  mounted() {},
  methods: {
    change(id) {
      this.loginActive = id
      this.showCodeTip = false
      this.showPwdTip = false
    },
    //游客登录
    async loginNewGuest() {
      const res = await this.$getYkInfo({
        affCode: this.store.state.user.channel ? this.store.state.user.channel : '{}',
        devID: this.store.state.user.uid
      })
      if (res && res.code === 200) {
        this.$store.dispatch('setUserInfo', res.data)
        this.$store.dispatch('setToken', res.data.token)
        this.$toast('登录成功！！！')
        setTimeout(() => {
          this.$router.push(`/mine`)
        }, 500)
      } else {
        this.$toast('登录失败！！！')
      }
    },
    // 获取验证码
    async getCaptcha() {
      if (this.num != 60) return
      this.countryArr = this.country.split('+')
      if (!this.country || !this.countryArr[1]) {
        return this.$toast('请输入国家编码')
      }
      if (!this.mobile) {
        return this.$toast('请输入手机号')
      }

      this.time = true
      if (this.time) {
        this.timer = setInterval(() => {
          if (this.num > 0) {
            this.num--
          } else {
            this.time = false
            this.num = 60
            window.clearInterval(this.timer)
          }
        }, 1000)
        const res = await login_captcha_api({
          country: this.countryArr[1],
          mobile: this.mobile
        })
        if (res.code === 200) {
          return this.$toast('验证码已发送，请输入验证码')
        } else {
          return this.$toast(res.tip)
        }
      }
    },
    // 登陆按钮
    async logoinBtn() {
      let res = ''
      if (this.loginActive === 0) {
        this.countryArr = this.country.split('+')
        if (!this.country || !this.countryArr[1]) {
          return this.$toast('请输入国家编码')
        }
        if (!this.mobile) {
          return this.$toast('请输入手机号')
        }
        if (!this.captcha) {
          return this.$toast('请输入验证码')
        }
        res = await phone_login_api({
          captcha: this.captcha, // 验证码
          country: this.countryArr[1], // 编码
          mobile: this.mobile // 手机号
        })
      } else {
        if (!this.userName) {
          return this.$toast('请输入用户名')
        }
        if (!this.pwd) {
          return this.$toast('请输入密码')
        }
        res = await account_login_api({
          account: this.userName,
          password: this.pwd
        })
      }

      if (res.code === 200) {
        this.$store.dispatch('setUserInfo', res.data)
        this.$store.dispatch('setToken', res.data.token)
        this.$router.push('/mine')
        return this.$toast('登陆成功')
      } else {
        if (this.loginActive === 0 && res.code === 1007) {
          this.showCodeTip = true
        }
        if (this.loginActive === 1) {
          this.showPwdTip = true
        }
        this.time = false
        this.num = 60
        window.clearInterval(this.timer)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bind-phone {
  min-height: 100vh;
  font-size: 0.28rem;
  background: url('../../../assets/imgs/mine/login_bg.png') no-repeat;
  background-size: cover;
}

:deep() {
  .van-cell {
    background: transparent;
    border: none;
    .van-field__body {
      width: 100%;
      height: 0.54rem;
    }
  }
  .van-cell::after {
    display: none;
  }
}

// 登陆页样式
.back_icon {
  width: 0.26rem;
  height: 0.34rem;
  margin-left: 0.5rem;
}

.login-box {
  width: 6.98rem;
  height: 8rem;
  border-radius: 0.24rem;
  margin: 0.82rem auto 0 auto;
  border-radius: 0.24rem;
  background: url('../../../assets/imgs/mine/login_box_bg.png') no-repeat;
  background-size: cover;
  border: 0.686px solid var(--windows-stroke-glass-specular, rgba(255, 255, 255, 0.4));
  background-blend-mode: luminosity;
  position: relative;
  @include flex-column-center();
  text-align: center;

  &::after {
    backdrop-filter: blur(1.25px);
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .login-input {
    width: 6.32rem;
    height: 4.6rem;
    margin: 0.51rem 0 0.58rem 0;
    padding: 0.4rem 0.3rem 0.62rem 0.4rem;
    border-radius: 0.2rem;
    border: 0.5px solid rgba(205, 205, 205, 0.5);
    background: rgba(255, 255, 255, 0.3);
    background-blend-mode: color-burn;
    box-shadow: 1px 1px 0.5px 0px rgba(255, 255, 255, 0.5) inset;
  }
}

.err-desc {
  color: #f77;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: PingFang SC;
  font-size: 0.22rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.36811rem;
  /* 167.321% */
}

// 切换tabs
.wallet_tabs {
  :deep() {
    .van-tabs__wrap {
      height: 0.63rem;
      @include flex-center;
    }

    .van-tabs__nav {
      width: 3.08rem;
      height: 0.63rem;
      border-radius: 0.48rem;

      background-blend-mode: color-burn;
      padding-bottom: 0;
      margin-left: 0.24rem;
      margin-top: 0.03rem;
      padding: 0 0.02rem;

      //border: 1px solid #E8619D;
      .van-tab {
        font-size: 0.24rem !important;
        z-index: 2;
      }

      .van-tabs__line {
        width: 1.36rem;
        height: 0.47rem;
        border-radius: 0.24rem;
        background: $btnBg;
      }

      .van-tabs__line {
        top: 0.06rem;
      }
      .van-tab--active {
        color: #fff;
      }
    }
  }
}

.phone-number {
  @include flexbox($jc: flex-start);
  border: 0.01rem dashed #999;
  box-sizing: border-box;
  border-radius: 0.15776rem;
  margin: 0.3rem 0 0.4rem 0;

  .country-input {
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: PingFang HK;
    font-size: 0.26rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.28923rem;
    /* 111.241% */

    padding: 0;
    width: 1rem;

    :deep() {
      .van-field__control {
        text-align: center;
      }
    }
  }

  .line {
    height: 0.3rem;
    width: 0.02rem;
    background: #545560;
  }
}

.code-number {
  @include flexbox();
  border: 0.01rem dashed #999;
  box-sizing: border-box;
  border-radius: 0.15776rem;

  .btn {
    display: flex;
    padding: 0.04rem 0.08rem;
    align-items: center;
    gap: 0.2rem;
    border-radius: 0.04rem;
    background: #e8619d;
    margin-right: 0.24rem;
    text-align: center;
    font-size: 0.22rem;
    font-weight: 400;
    white-space: nowrap;
  }
  .disabled {
    background: rgba($color: $btnBg, $alpha: 0.5);
  }
}

.tip_desc {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: PingFang SC;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  @include flexbox();
  margin-top: 0.5rem;
  color: #666;
  .active_txt {
    color: $txtActive;
  }
}

.submit {
  display: inline-flex;
  padding: 0.24rem 2.56rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.08rem;
  background: $gradientBg;
  color: #fff;
}

.login-logo {
  @include flex-center;
  margin: 1rem 0 0.92rem 0;

  img {
    width: 1.6rem;
    height: 0.88rem;
    flex-shrink: 0;
  }
}
</style>
