<template>
  <div class="waterFall">
    <div class="rowWrap">
      <div ref="waterFallLeft" class="left">
        <div v-for="(item, index) in evenList" :key="index">
          <slot name="left" :data="item"></slot>
        </div>
      </div>
      <div ref="waterFallRight" class="right">
        <div v-for="(item, index) in oddList" :key="index">
          <slot name="right" :data="item"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EWaterFall',
  props: {
    datasList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    // 偶数
    oddList() {
      return this.datasList.filter((item, index) => {
        return this.isOdd(index)
      })
    },
    // 奇数
    evenList() {
      return this.datasList.filter((item, index) => {
        return this.isEven(index)
      })
    }
  },
  methods: {
    isOdd(number) {
      return number % 2 !== 0
    },
    isEven(number) {
      return number % 2 === 0
    }
  }
}
</script>
<style lang="scss" scoped>
.waterFall {
  .rowWrap {
    display: flex;
    width: 100%;
    .left,
    .right {
      position: relative;
      width: calc(100% / 2);
      box-sizing: border-box;
      padding: 5px;
    }
  }
}
</style>