<template>
  <!-- 选集盒子 -->
  <div v-if="list && list.length" class="anthology-box">
    <div class="top">
      <span>选集</span>
      <div @click="switchMode">
        <img :src="currentModeLabel.icon" alt="" class="icon" />
        <span class="label">{{ currentModeLabel.name }}</span>
      </div>
    </div>
    <ul
      class="chapter-list"
      :class="{
        wrap: innerMode === 2
      }"
    >
      <li
        v-for="(item, index) in list"
        :key="item[keyName]"
        :class="item[keyName] === value ? 'active' : ''"
        @click="onChange(item)"
      >
        <div>{{ index + 1 }}</div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'EpisodeInfo',
  props: {
    mode: {
      type: Number,
      default: 1 //缩略模式 1  横滑模式 2
    },
    keyName: {
      type: String,
      default: 'id'
    },
    value: {
      type: Number,
      required: true
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      innerMode: 1
    }
  },
  computed: {
    currentModeLabel() {
      switch (this.innerMode) {
        case 1:
          return {
            name: '缩略模式',
            icon: require('@/assets/imgs/mode1.png')
          }
        case 2:
          return {
            name: '横滑模式',
            icon: require('@/assets/imgs/mode2.png')
          }
        default:
          return {
            name: '缩略模式',
            icon: require('@/assets/imgs/mode1.png')
          }
      }
    }
  },
  watch: {
    mode: {
      handler(val) {
        this.innerMode = val || 1
      },
      immediate: true
    }
  },
  methods: {
    onChange(item) {
      this.$emit('input', item[this.keyName])
    },
    switchMode() {
      this.innerMode = this.innerMode === 1 ? 2 : 1
      this.$emit('update:mode', this.innerMode)
    }
  }
}
</script>

<style scoped lang="scss">
.anthology-box {
  position: relative;
  overflow-y: hidden;
  .top {
    @include flexbox($ai: center);
    padding-right: 0.32rem;
    span {
      &:first-child {
        font-size: 0.32rem;
        font-weight: 600;
      }
    }
    .icon {
      font-size: 0.32rem;
      font-weight: 600;
    }
    .label {
      margin-left: 0.08rem;
    }
  }
  .chapter-list {
    @include flexbox($jc: flex-start);
    margin: 0.2rem 0 0 0;
    overflow-x: auto;
    &.wrap {
      flex-wrap: wrap;
      li {
        &:nth-child(6n + 6) {
          margin-right: 0;
        }
      }
    }
    li {
      width: 0.96rem;
      height: 0.96rem;
      font-size: 0.28rem;
      border-radius: 0.16rem;
      background: rgba(0, 0, 0, 0.1);
      text-align: center;
      line-height: 0.96rem;
      margin-right: 0.2rem;
      margin-bottom: 0.24rem;
      position: relative;
      flex: none;
    }
    .active {
      border-radius: 0.16rem;
      background: rgba(221, 0, 27, 0.1);
      color: $txtActive;
    }
  }
}
</style>
