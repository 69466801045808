<template>
  <div class="kf">
    <EBackTop class="header" :title="'常见问题解答'" @goBack="$router.go(-1)" />
    <div class="top-header">
      <div class="top-btnWrap">
        <img src="@/assets/imgs/mine/kf.png" alt="" />
        需要人工帮助? <span @click="$router.push('/mine/setting/kf')">&nbsp;召唤人工客服</span>
      </div>
    </div>
    <div class="iframe_box" frameborder="0">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="msg-box" v-for="(item, index) in allMsgList" :key="index">
          <div class="default-box" v-if="!item.checkTitle">
            <p @click="checkAdd(leftItem)" v-for="(leftItem, leftIndex) in item" :key="leftItem.id">
              {{ leftIndex + 1 }}.{{ leftItem.question }}
            </p>
          </div>
          <div class="right-box" v-if="item.checkTitle">
            <p>{{ item.checkTitle }}</p>
          </div>
          <div class="left-box" v-if="item.checkAnswer">
            <div>
              <p>{{ index + 1 }}.{{ item.checkTitle }}</p>
              <p>答：{{ item.checkAnswer }}</p>
              <p class="kf-tips">需要人工帮助? <span @click="$router.push('/mine/setting/kf')">召唤人工客服</span></p>
            </div>
          </div>
        </div>
      </van-pull-refresh>
      <!-- 发布按钮 -->
      <div class="submit-box" @click="$router.push('/mine/setting/kf')">人工客服</div>
    </div>
  </div>
</template>

<script>
import { aicustomerserviceList } from '@/api/home'

export default {
  components: {},
  data() {
    return {
      isLoading: false,
      allMsgList: []
    }
  },
  mounted() {
    this.onRefresh()
  },
  methods: {
    onRefresh() {
      this.allMsgList = []
      this.getAicustomerserviceListFn()
    },
    checkAdd(checkVal) {
      checkVal.checkTitle = checkVal.question
      checkVal.checkAnswer = checkVal.answer
      this.allMsgList.push(checkVal)
    },
    async getAicustomerserviceListFn() {
      const res = await aicustomerserviceList({})
      if (res.code === 200) {
        if (res.data.list && res.data.list.length) {
          this.allMsgList.push(res.data.list)
        } else {
          this.$toast(res.tip)
        }
      } else {
        return this.$toast(res.tip)
      }
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.kf {
  font-size: 0.28rem;
  :deep() {
    .van-list__finished-text {
      padding-top: 0.8rem;
    }
  }
  .iframe_box {
    padding: 1rem 0;
  }
  .top-header {
    position: fixed;
    width: 100%;
    height: 1rem;
    background: #fff;
    top: 0.99rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2000;
    .top-btnWrap {
      position: absolute;
      bottom: 0.2rem;
      left: 50%;
      transform: translateX(-50%);
      background: #e6e6e6;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      width: 4.8rem;
      height: 0.56rem;

      img {
        width: 0.32rem;
        height: 0.32rem;
        margin-right: 0.1rem;
      }
      span {
        color: #f64562;
      }
    }
  }
  .msg-box {
    padding: 0 0.4rem 0.5rem 0.4rem;
    position: relative;
    z-index: 0;
  }

  .default-box {
    border-radius: 0.35rem 0.35rem 0.35rem 0px;
    padding: 0.4rem;
    background: #e6e6e6;
    max-width: 60%;
    p {
      margin-bottom: 0.1rem;
      text-decoration: underline;
    }
  }

  .left-box {
    @include flexbox($jc: flex-start);
    border-radius: 0.32rem 0.32rem 0.32rem 0px;
    padding: 0.3rem;
    background: #e6e6e6;
    max-width: 70%;
    p,
    div {
      margin-top: 10px;
    }
    .kf-tips {
      border-radius: 0.5rem;
      background: #a1a1a1;
      padding: 0.15rem 0.3rem;
      font-size: 0.24rem;
      span {
        color: #ffdb9e;
      }
    }
  }

  .right-box {
    margin-bottom: 0.4rem;
    @include flexbox($jc: flex-end);
    p {
      border-radius: 0.3rem 0.3rem 0px 0.3rem;
      background: #d8699b;
      font-size: 0.28rem;
      color: #fff;
      padding: 0.3rem;
      text-align: right;
    }
  }
}
.submit-box {
  position: fixed;
  left: 50%;
  font-size: 0.32rem;
  color: #fff;
  bottom: 0.2rem;
  transform: translateX(-50%);
  display: flex;
  width: 6.4rem;
  padding: 0.2rem;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: $gradientBg;
  z-index: 2000;
}
</style>
