<!--
 * @Author: 
 * @Mail: 
 * @Date: 2023-07-04 17:11:46
 * @LastEditTime: 2023-07-04 17:13:19
 * @LastEditors: 
 * @FilePath: /web_base/component_base/EStartSwiper/index.vue
-->
<template>
  <van-swipe v-if="show && nowAd.length" class="my-swipe" :autoplay="3000" ref="homeSwiper" indicator-color="white">
    <van-swipe-item v-for="(item, index) in nowAd" :key="index">
      <EDecryptImg :imgURL="item.cover" :imgCDN="cdn" @click.native="clickAd(item)" class="item-img">
        <div class="count-down" @click.stop="clickBtn">{{ countDown }}s<span v-if="countDown < 4">(跳过)</span></div>
      </EDecryptImg>
    </van-swipe-item>
  </van-swipe>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: 'EStartSwiper',
  data() {
    return {
      countDown: 7,
      show: true,
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => {
        //初始化数组
        return [];
      },
    },
    cdn: {
      type: String,
      default: () => {
        return '';
      },
    },
  },
  computed: {
    nowAd() {
      let newArr = JSON.parse(JSON.stringify(this.data));
      if (newArr.length) {
        return [newArr[Math.floor(Math.random() * newArr.length)]];
      } else {
        return [];
      }
    },
  },
  mounted() {
    let interval = setInterval(() => {
      if (this.countDown == 0) return clearInterval(interval);
      this.countDown--;
    }, 1000);
  },
  methods: {
    clickAd(item) {
      this.$emit('clickImg', item);
    },
    // 点击按钮
    clickBtn(index, arr) {
      if (this.countDown < 4) {
        this.show = false;
        this.$emit('clickBtn');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-swipe {
  background: #535353;
  position: fixed;
  z-index: 10000;
  top: 0;
  width: 100%;
  height: 100vh;
  max-width: 540px;
  left: 50%;
  transform: translateX(-50%);

  .item-img {
    position: relative;
    .count-down {
      background: rgba($color: #000000, $alpha: 0.5);
      position: absolute;
      top: 0.3rem;
      right: 0.5rem;
      font-size: 0.28rem;
      width: 1.6rem;
      height: 0.7rem;
      line-height: 0.7rem;
      text-align: center;
      border-radius: 999px;
    }
  }
}
</style>
