<template>
  <div class="resource-wrap">
    <EBackTop title="引用资源"> </EBackTop>
    <ETabBar :list="category" :tabbar-config="tabbarConfig" :active-num="activeId" class="ETabBar" @change="changeTab">
      <template #default="{ data }">
        <div class="resource-content">
          <!--          收藏-->
          <resource-coll-page-tab v-if="data.id === 1 && activeId === 1" @on-choose="toChoose" />
          <!--          历史-->
          <resource-history-page-tab v-else-if="data.id === 2 && activeId === 2" @on-choose="toChoose" />
          <!--          发布-->
          <resource-publish-page-tab v-else-if="data.id === 3 && activeId === 3" @on-choose="toChoose" />
          <!--          搜索-->
          <resource-search-page-tab
            v-else-if="data.id === 4 && activeId === 4"
            :show-input="true"
            @on-choose="toChoose"
          />
        </div>
      </template>
    </ETabBar>
  </div>
</template>

<script>
export default {
  name: 'Resource',
  components: {
    ResourceCollPageTab: () => import('components/Resource/ResourceCollPageTab.vue'),
    ResourceHistoryPageTab: () => import('components/Resource/ResourceHistoryPageTab.vue'),
    ResourcePublishPageTab: () => import('components/Resource/ResourcePublishPageTab.vue'),
    ResourceSearchPageTab: () => import('components/Resource/ResourceSearchPageTab.vue')
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.enterRoute = from
    })
  },
  data() {
    return {
      enterRoute: {},
      activeId: 1,
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.32rem', //默认字体大小
        fontSizeActive: '0.32rem', //选中字体大小
        fontColor: '#333333', //默认字体颜色
        fontColorActive: '#E8619D', //选中字体颜色
        bg: '#fff', //默认背景色
        bgActive: '#fff', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#E8619D',
        lineWidth: '0.16rem', //下划线宽度 String
        sticky: true, //粘性布局
        offsetTop: '1rem', //距顶部距离
        tabFlex: true
      },
      category: [
        {
          id: 1,
          name: '收藏'
        },
        {
          id: 2,
          name: '历史'
        },
        {
          id: 3,
          name: '发布'
        },
        {
          id: 4,
          name: '搜索'
        }
      ]
    }
  },
  methods: {
    changeTab(id) {
      this.activeId = id
    },
    toChoose(item) {
      this.$store.commit('SET_REF_TARGET', item)
      this.$router.replace(this.enterRoute.fullPath)
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.van-tabs__nav) {
  justify-content: center;
}
.resource-content {
  padding: 0 0.32rem;
}
</style>
