<template>
  <div class="recommend-card-item">
    <div class="recommend-card-item-left">
      <EDecryptImg :img-c-d-n="imgCDN" :img-u-r-l="data.cover" />
    </div>
    <div class="recommend-card-item-right">
      <div class="recommend-card-item-title">{{ data.title }}</div>
      <div class="recommend-card-item-count">
        <img src="@/assets/imgs/community/fire.svg" alt="" />
        {{ data.hotValue | numberFilter }}
      </div>
    </div>
  </div>
</template>
<script>
import { numberFilter } from '@/filters/filter'

export default {
  name: 'RecommendCardItem',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    numberFilter,
    toSelect(item) {
      this.$emit('input', item.id)
    }
  }
}
</script>

<style scoped lang="scss">
.recommend-card-item {
  padding: 0.16rem 0.12rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0.06rem;
  @include flex-center;
  .recommend-card-item-left {
    background-color: rgba(196, 196, 196, 1);
    overflow: hidden;
    border-radius: 0.06rem;
    width: 0.72rem;
    height: 0.72rem;
    flex-shrink: 0;
    flex-grow: 0;
  }
  .recommend-card-item-right {
    margin-left: 0.18rem;
    flex-grow: 1;
    .recommend-card-item-title {
      @include textoverflow(1);
    }
    .recommend-card-item-count {
      color: rgba(255, 45, 45, 1);
      font-size: 0.24rem;
    }
  }
}
</style>
