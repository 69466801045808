<template>
  <div class="works">
    <div class="works_avatar_top">
      <EDecryptImg
        :imgCDN="imgCDN"
        class="works_avatar_icon"
        :imgURL="userInfo.avatarUrl ? userInfo.avatarUrl : ''"
      ></EDecryptImg>
      <div class="works_avatar_right">
        <p>{{ userInfo.nickName }}</p>
        <p>剩余观看次数：{{ userInfo.leftWatchTimes }}/{{ userInfo.totalWatchTimes }}</p>
      </div>
      <div class="works_avatar_btn" @click="$router.push('/Mine/signIn')">
        <img src="@/assets/imgs/mine/myShouyi/signin.png" />
        签到
      </div>
    </div>
    <!-- <div class="vip_btn" @click="$router.push('/Mine/vip')">开通VIP不限次数观看</div> -->
    <div class="integral_box">
      <div class="integral_box_left">
        <p>邀请人数{{ userInfo.invites }}人</p>
        <p>我的积分{{ userIncomeInfo.countPoints }}</p>
      </div>
      <p class="btn" @click="$router.push('/Mine/exchangeVip')">兑换VIP</p>
    </div>
    <!-- 任务列表 -->
    <div class="works_list">
      <!-- 任务类型 -->
      <ul class="top_title">
        <li
          v-for="item in titleList"
          :key="item.id"
          class="title_item"
          :class="titleId == item.id ? 'title_item_on' : ''"
          @click="checkTitle(item)"
        >
          {{ item.text }}
        </li>
      </ul>

      <!-- 萌新任务 -->
      <ul v-if="titleId == 1">
        <li v-for="item in taskList1" :key="item.id" class="works_item">
          <div class="works_item_left">
            <EDecryptImg :imgCDN="imgCDN" class="works_avatar" :imgURL="item.img ? item.img : ''"></EDecryptImg>
            <div class="item_desc">
              <p>
                <span>{{ item.title }}</span>
                <span>+{{ item.rewardPoint }}积分</span>
              </p>
              <p>{{ item.desc }}</p>
            </div>
          </div>

          <div
            class="item_btn"
            :class="item.status == 0 ? '' : item.status == 2 ? 'isOk' : 'awaitCheck'"
            @click="receiveItem(item)"
          >
            {{
              item.status == 0 && item.taskType == 6
                ? '去邀请'
                : item.status == 0 && item.taskType !== 6
                ? '去完成'
                : item.status == 2
                ? '已领取'
                : '待领取'
            }}
          </div>
        </li>
      </ul>
      <!-- 每日福利 -->
      <ul v-if="titleId == 2">
        <li v-for="item in taskList2" :key="item.id" class="works_item">
          <div class="works_item_left">
            <EDecryptImg :imgCDN="imgCDN" class="works_avatar" :imgURL="item.img ? item.img : ''"></EDecryptImg>
            <div class="item_desc">
              <p>
                <span>{{ item.title }}</span>
                <span>+{{ item.rewardPoint }}积分</span>
              </p>
              <p>{{ item.desc }}</p>
            </div>
          </div>

          <div
            class="item_btn"
            :class="item.status == 0 ? '' : item.status == 2 ? 'isOk' : 'awaitCheck'"
            @click="receiveItem(item)"
          >
            {{
              item.status == 0 && item.taskType == 6
                ? '去邀请'
                : item.status == 0 && item.taskType !== 6
                ? '去完成'
                : item.status == 2
                ? '已领取'
                : '待领取'
            }}
          </div>
        </li>
      </ul>
    </div>
    <!-- 积分兑换 -->
    <div class="redeem">
      <p class="title">积分兑换</p>
      <ul>
        <li v-for="item in userIncomeInfo.prizeList" :key="item.id">
          <EDecryptImg :imgCDN="imgCDN" class="item_bg" :imgURL="item.cover ? item.cover : ''"></EDecryptImg>
          <div class="item_desc">
            <p>花费{{ item.pointsNum }}积分</p>
            <p @click="redeemItem(item)">立即兑换</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { checkin_page, prize_redeem } from '@/api/user'
import { advertise_click } from '@/api/activity'

import { JumpTo } from '@/utils/utils_tools'
export default {
  name: 'MineShare',
  components: {},
  data() {
    return {
      config: {
        qrSize: 200
      },
      web: '',
      app: '',
      qrCode: '',
      userIncomeInfo: {},

      titleList: [
        { id: 1, text: '萌新任务' },
        { id: 2, text: '每日福利' }
      ],
      titleId: 1,
      taskList1: [],
      taskList2: []
    }
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    // 落地页
    ldyCdn({ $store }) {
      return $store.getters['ldyCdn']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {
    this.getUserIncome()
  },
  methods: {
    // 切换任务类型
    checkTitle(item) {
      this.titleId = item.id
    },

    // 积分兑换
    async redeemItem() {
      // const res = await prize_redeem({
      //   prizeId: item.id
      // })
      // if (res.code === 200) {
      //   this.getUserIncome()
      //   await this.$toast('领取成功')
      // } else {
      //   return this.$toast(res.tip)
      // }
      this.$router.push('/Mine/exchangeVip')
    },
    // 领取奖励
    async receiveItem(item) {
      if (item.status == 0) {
        if (item.goTo) {
          JumpTo(item.goTo)
        } else {
          return
        }
      } else if (item.status == 1) {
        const res = await advertise_click({
          id: item.missionId
        })
        if (res.code === 200) {
          this.getUserIncome()
          return this.$toast('领取成功！')
        } else {
          return this.$toast(res.tip)
        }
      } else {
        return
      }
    },
    onCopy() {
      this.$toast('复制成功')
    },
    onError() {
      this.$toast('不支持复制')
    },
    clickSaveImg() {
      this.$refs['EGenerateImg'].clickSaveImg()
    },
    // 获取用户代理信息
    async getUserIncome() {
      const res = await checkin_page({})
      if (res.code === 200) {
        this.taskList1 = []
        this.taskList2 = []
        this.userIncomeInfo = res.data
        res.data.taskList.forEach(v => {
          if (v.taskType == 1) {
            this.taskList1.push(v)
          }
          if (v.taskType == 2) {
            this.taskList2.push(v)
          }
        })
      } else {
        return this.$toast('请求错误，请联系后台管理人员！')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.works {
  padding: 0.34rem 0.32rem;
}

.works_avatar_top {
  @include flexbox($jc: flex-start);
  border-bottom: 1px dashed #999;
  padding-bottom: 0.3rem;

  .works_avatar_icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.2rem;
  }

  .works_avatar_right {
    p {
      font-size: 0.28rem;

      &:last-child {
        color: $grey;
        margin-top: 0.08rem;
      }
    }
  }

  .works_avatar_btn {
    width: 1.64rem;
    height: 0.72rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.48rem;
    background: $gradientBg;
    font-size: 0.32rem;
    font-weight: 400;
    margin-left: 1.2rem;
    color: #fff;

    img {
      width: 0.28rem;
      height: 0.28rem;
      margin-right: 0.1rem;
    }
  }
}

.works_item {
  width: 6.5rem;
  @include flexbox();
  border-radius: 0.16rem;
  background: #fff;
  padding: 0.22rem 0.24rem;
  margin-bottom: 0.2rem;

  &:last-child {
    margin-bottom: 0;
  }

  .works_avatar {
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.24rem;
  }

  .works_item_left {
    @include flexbox($jc: flex-start);

    .item_desc {
      span {
        font-size: 0.28rem;
        font-weight: 600;

        &:last-child {
          color: #f54570;
          font-weight: 400;
          font-size: 0.24rem;
          margin-left: 0.16rem;
        }
      }

      p {
        &:last-child {
          color: #aaa;
          font-size: 0.24rem;
        }
      }
    }
  }

  .item_btn {
    background: $txtActive;
    padding: 0.04rem 0.24rem;
    border-radius: 0.72rem;
    color: #fff;
  }
}

.vip_btn {
  background: $gradientBg;
  border-radius: 0.44rem;
  padding: 0.22rem 2.2rem;
  margin: 0.36rem 0;
  color: #fff;
}

.integral_box {
  @include flexbox();
  margin: 0.4rem 0.3rem;

  .integral_box_left {
    color: $grey;
    font-size: 0.24rem;
    @include flexbox($jc: flex-start);

    p {
      &:first-child {
        margin-right: 0.24rem;
      }
    }
  }

  .btn {
    background: #4b4b4b;
    color: #fff;
    padding: 0.04rem 0.24rem;
    border-radius: 0.72rem;
  }
}

// 任务列表
.works_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.2rem;
  background: rgba(0, 0, 0, 0.04);
  padding: 0.44rem 0.16rem;
  margin-bottom: 0.36rem;

  .top_title {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.36rem;
    border: 0.5px solid #999;
    margin-bottom: 0.4rem;
    font-size: 0.32rem;
    font-weight: 400;
    color: #999;
    .title_item {
      padding: 0.05rem 0.1rem;
      border-radius: 0.36rem;
      margin: 0.03rem;
    }
    .title_item_on {
      background-color: #e8619d;
      color: #fff;
    }
  }
}

// 积分兑换
.redeem {
  ul {
    @include flexbox($fw: wrap);

    li {
      margin-bottom: 0.14rem;
      .item_bg {
        width: 3.36rem;
        height: 1.52rem;
        border-radius: 0.16rem 0.16rem 0rem 0rem;
        .title_txt {
          position: absolute;
          top: 0.3rem;
          left: 0.24rem;
          color: #ccc;
          font-size: 0.24rem;

          .sub_title {
            font-size: 0.32rem;
            color: #fff;
            font-weight: 600;
          }
        }
      }
      .item_desc {
        background: rgba(0, 0, 0, 0.04);
        @include flexbox();
        padding: 0.18rem 0.24rem;
        border-radius: 0rem 0rem 0.16rem 0.16rem;
        p {
          &:last-child {
            background: $txtActive;
            color: #fff;
            padding: 0.06rem 0.16rem;
            border-radius: 0.21rem;
          }
        }
      }
    }
  }
}

.title {
  margin-bottom: 0.22rem;
  font-size: 0.36rem;
  font-weight: 600;
}
.isOk {
  border-radius: 0.72rem;
  background: #f6f6f6 !important;
  color: #999 !important;
}
.awaitCheck {
  background: $gradientBg;
  color: #fff;
}
</style>
