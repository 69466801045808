<template>
  <div class="item">
    <div class="left">
      <EDecryptImg :imgURL="data.coverImg">
        <VideoType :data="data"></VideoType>
      </EDecryptImg>
    </div>
    <div class="right">
      <div>{{ data.title }}</div>
      <div class="date">{{ data.createdAt | timeYmd }}</div>
    </div>
  </div>
</template>

<script>
// 评论引用资源
export default {
  components: {
    VideoType: () => import('@/components/VideoType.vue')
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    toPlay(data) {
      if (data) {
        this.$router.push(`/play/longVideo/${data.id}`)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.item {
  height: 1.6rem;
  overflow: hidden;
  flex-shrink: 0;
  @include flexbox(flex-start, center);
  .left {
    flex-shrink: 0;
    width: 2.86rem;
    height: 1.6rem;
    border-radius: 0.06rem;
    overflow: hidden;
    position: relative;
  }

  .right {
    flex-grow: 1;
    padding: 0.12rem 0.2rem;

    > div {
      font-size: 0.28rem;
      font-weight: 500;
      color: #333;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      /* 这里是超出几行省略 */
      overflow: hidden;
    }
    .date {
      margin-top: 0.12rem;
      line-height: 0.36rem;
      color: rgba(154, 154, 154, 1);
      font-size: 0.24rem;
    }
  }
}
</style>
