<template>
  <!-- 常规播放页 -->
  <ShortVideo></ShortVideo>
</template>

<script>
export default {
  components: {
    ShortVideo: () => import('@/components/ShortVideo/index.vue')
  }
}
</script>
