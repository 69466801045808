<template>
  <div class="resource">
    <!--查询-->
    <ResourceOpr v-model="searchParams" class="resource-opr" :show-input="showInput" @input="changeSearchParams">
    </ResourceOpr>
    <ERefreshLoadV3 ref="ERefreshLoadV3" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
      <!--  结果展示-->
      <!--      播单-->
      <PlayList1
        v-if="searchParams.sort === 13"
        :list="playList"
        @on-choose="$emit('on-choose', { item: $event, refType: 1 })"
      />
      <!--      视频-->
      <VideoList1
        v-else-if="searchParams.sort === 1"
        :list="mediaList"
        @on-choose="$emit('on-choose', { item: $event, refType: 2 })"
      />
      <!--      图集-->
      <ImgList2
        v-else-if="searchParams.sort === 9"
        :list="photoList"
        @on-choose="$emit('on-choose', { item: $event, refType: 3 })"
      />
    </ERefreshLoadV3>
  </div>
</template>
<script>
export default {
  name: 'ResourceCollPageTab',
  components: {
    ResourceOpr: () => import('@/components/Resource/ResourceOpr.vue'),
    PlayList1: () => import('@/components/Home/listType/playList/playList1.vue'),
    VideoList1: () => import('@/components/Home/listType/videoList/videoList1.vue'),
    ImgList2: () => import('@/components/Home/listType/imgList/imgList2.vue')
  },
  props: {
    showInput: {
      type: Boolean,
      default: false
    },
    toChoose: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      searchParams: {
        sort: 1,
        search: ''
      },
      pageNum: 0,
      pageSize: 10,
      finished: false,
      photoList: [], //图集列表
      playList: [], //播单列表
      mediaList: [] //视频列表
    }
  },
  computed: {
    userId() {
      return this.$store.getters.userId
    }
  },
  methods: {
    changeSearchParams() {
      this.pageNum = 1
      this.photoList = []
      this.playList = []
      this.mediaList = []
      this.getList()
    },
    async onLoad() {
      this.pageNum += 1
      await this.getList()
    },
    // 下拉刷新
    async onRefresh() {
      this.finished = false
      //表示处于可以下拉状态
      this.pageNum = 0
      this.photoList = []
      this.playList = []
      this.mediaList = []
    },
    // 获取收藏短视频列表
    async getList() {
      throw new Error('需要实现')
    }
  }
}
</script>

<style scoped lang="scss">
.resource {
  .resource-opr {
    background-color: #ffffff;
  }
}
</style>
