<template>
  <div class="post_history_item">
    <div class="post-bar">
      <div class="time">{{ itemData.base.createdAt | timeDiffNew }}</div>
      <div v-if="itemData.base.status === 4" class="status" @click="showWhy = !showWhy">查看原因</div>
    </div>
    <!-- 原因 -->
    <p v-if="showWhy" class="tip">
      {{ itemData.base.remark }}
      <van-icon name="clear" color="#E8619D" size="0.32rem" @click="showWhy = false" />
    </p>
    <postShowType1 :data="itemData" class="post-item"></postShowType1>
    <div class="divider"></div>
  </div>
</template>

<script>
export default {
  components: {
    postShowType1: () => import('@/components/Home/showType/postListType/postShowType1.vue')
  },
  props: {
    itemData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showWhy: false
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取首页顶部视频分类导航列表
    findCategory({ $store }) {
      return $store.getters['findCategory']
    },
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    isMemberAndNotShop() {
      return (
        (!this.itemData.base.price && !this.itemData.base.isBought && !this.isMember) || this.userInfo.vipType == 11
      )
    }
  },
  methods: {
    // 详情跳转
    toDetail(item) {
      this.$router.push(`/community/detail/${item.base.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.post-bar {
  margin-bottom: 0.24rem;
  @include flexbox($jc: space-between, $ai: center);
  .time {
    display: flex;
    padding: 0.04rem 0.16rem;
    align-items: flex-start;
    gap: 0.2rem;
    border-radius: 0.48rem;
    background: rgba(221, 0, 27, 0.05);
    color: #e8619d;
  }
  .status {
    display: flex;
    padding: 0.04rem 0.12rem;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    border-radius: 0.64rem;
    background: #246bfd;
    color: #ffffff;
  }
}
.tip {
  border-radius: 0.08rem;
  background: rgba(221, 0, 27, 0.1);
  color: $txtActive;
  margin: 0.4rem 0.64rem 0.36rem;
  padding: 0.26rem 0.4rem;
  position: relative;
  :deep() {
    .van-icon-clear {
      position: absolute;
      right: 0;
      top: 0;
      width: 0.32rem;
      height: 0.32rem;
    }
  }
}

.divider {
  margin-bottom: 0.28rem;
  display: flex;
  width: 6.86rem;
  height: 0.02rem;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}
</style>
