<template>
  <div class="exchange-container">
    <EBackTop :title="'兑换中心'" />
    <!-- 列表数据 -->
    <ERefreshLoadV3
      ref="ERefreshLoadV3"
      class="main_list"
      :beforeRefresh="onRefresh"
      :moreData="onLoad"
      :finished="finished"
    >
      <div class="exchange-codeWrap">
        <input v-model="redeemcode" placeholder="请输入兑换码" type="text" />
      </div>
      <div class="submit" @click="submitRedeemcode">立刻兑换</div>
      <ul class="exchange-listWrap">
        <li class="item item-title">
          <div class="left top">
            <div>兑换码</div>
          </div>

          <div class="center top">
            <div>兑换类型</div>
          </div>
          <div class="right top">
            <div>兑换时间</div>
          </div>
        </li>
        <li v-for="(item, index) in redeemcodeList" :key="index" class="item item-decial">
          <div class="left left-add">
            {{ item.code }}
          </div>
          <div class="center center-add">
            {{ item.desc }}
          </div>
          <div class="right right-add">
            {{ item.activedAt.split('T')[0] }}
          </div>
        </li>
      </ul>
    </ERefreshLoadV3>
  </div>
</template>
<script>
import { redeemcode_user, redeemcode_list } from 'api/user'
export default {
  components: {},
  data() {
    return {
      redeemcode: '',
      finished: false,
      // 兑换列表
      redeemcodeList: [],
      // 请求类型
      getType: '',
      // 当前页
      pageNum: 0,
      // 当前个数
      pageSize: 50
    }
  },
  computed: {},

  methods: {
    // 获取兑换列表
    async getRedeemcodeList() {
      const res = await redeemcode_list({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      if (res.code === 200) {
        if (res.data && res.data.list && res.data.list.length) {
          this.redeemcodeList = [...this.redeemcodeList, ...res.data.list]
          if (res.data.list.length < this.pageSize) {
            res.data.list.length
          }
        } else {
          this.finished = true
        }
      } else {
        this.$toast(res.tip)
        this.finished = true
      }
    },
    // 下拉加载
    async onLoad() {
      this.pageNum++
      await this.getRedeemcodeList()
    },
    // 上拉刷新
    async onRefresh() {
      this.finished = false
      this.redeemcodeList = []
    },
    // 提交兑换码
    async submitRedeemcode() {
      if (!this.redeemcode) return this.$toast('请输入兑换码！！!')
      const res = await redeemcode_user({
        code: this.redeemcode.trim()
      })
      if (res.code === 200) {
        this.redeemcode = ''
        this.$toast('恭喜兑换成功！！！')
        this.onRefresh()
      } else {
        this.redeemcode = ''
        this.$toast(res.tip)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.exchange-container {
  min-height: 100vh;
  box-sizing: border-box;
  // background: #f1f5fb;
  .exchange-codeWrap {
    position: relative;
    box-sizing: border-box;
    margin: 0.5rem 0.3rem;
    text-align: center;
    border-radius: 0.09rem;
    box-sizing: border-box;
    font-size: 0;
    height: 0.8rem;
    input {
      width: 100%;
      border: 0;
      outline: none;
      font-size: 0.26rem;
      line-height: 0.6rem;
      border-radius: 0.05rem;
      text-indent: 0.15rem;
      height: 100%;
      border-radius: 0.64rem;
      border: 2px solid $btnBg;
      background: transparent;
    }
  }
  .exchange-listWrap {
    min-height: 40vh;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.26rem;
      width: 100%;
      white-space: nowrap;
      text-align: center;
      line-height: 0.48rem;
      color: #787979;
      padding: 0.15rem 0.64rem;
      font-size: 0.24rem;
      &-title {
        font-size: 0.28rem;
      }
      &:nth-child(odd) {
        background: rgba(0, 0, 0, 0.04);
      }
      &:nth-child(even) {
        background: #fff;
      }
      .left {
        width: 33.33%;
      }
      .center {
        width: 33.33%;
      }
      .right {
        width: 33.33%;
      }
    }
  }
}
.submit {
  border-radius: 0.46rem;
  background: $gradientBg;
  width: 4.8rem;
  height: 0.72rem;
  flex-shrink: 0;
  margin: 0.44rem auto 0.88rem auto;
  line-height: 0.72rem;
  text-align: center;
  color: #fff;
}
</style>
