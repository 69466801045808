<template>
  <div class="top-avatar">
    <div class="top-avatar-top">
      <div class="left">
        <div class="subtrac">
          <img src="@/assets/imgs/discover/Subtract1.png" alt="" />
          <img src="@/assets/imgs/discover/Subtract.png" alt="" />
        </div>
        <!-- <img src="@/assets/imgs/temp/3410.png" alt="" /> -->
        <EDecryptImg class="mine_top_bg" :imgURL="detail.cover || detail.avatar" />
        <div v-if="detail.type === 1" class="type">
          <img src="@/assets/imgs/discover/video.png" alt="" />
          视频
        </div>
        <div v-else class="type">
          <img src="@/assets/imgs/discover/photo.png" alt="" />
          图片
        </div>
      </div>
      <div class="right">
        <div>{{ detail.title }}</div>
        <div>
          <img src="@/assets/imgs/temp/3410.png" alt="" />
          <span>{{ detail.userName }}</span>
        </div>
        <div>作品数量{{ detail.total }}</div>
      </div>
    </div>
    <div v-if="showBottom" class="top-avatar-bottom">
      <div v-if="detail.someData" class="more">
        <div @click="clickComment">
          <img src="@/assets/imgs/discover/dot.png" alt="" />
          <span>{{ detail.someData.comments ? detail.someData.comments : 0 }}</span>
        </div>
        <div @click="addLike(detail, 2)">
          <!-- <img src="@/assets/imgs/discover/thumbs-up.svg" alt="" /> -->
          <img v-if="detail.isLike" src="@/assets/imgs/play/likeIcon.png" alt="" />
          <img v-else src="@/assets/imgs/play/like.png" alt="" />
          <span>{{ detail.someData.likes }}</span>
        </div>
        <div @click="clickShare">
          <img src="@/assets/imgs/discover/share.png" alt="" />
          <span>分享</span>
        </div>
      </div>
      <div class="less">
        <div @click="addLike(detail, 1)">
          <!-- <img src="@/assets/imgs/discover/Star.svg" alt="" /> -->
          <img v-if="detail.isCollect" src="@/assets/imgs/play/collectIcon.png" alt="" />
          <img v-else src="@/assets/imgs/play/collectIconS.png" alt="" />
          <span>收藏</span>
        </div>
      </div>
    </div>
    <SharePopup
      :sharePopShow="showCommunityShare"
      @close="
        e => {
          showCommunityShare = e
        }
      "
    />
  </div>
</template>

<script>
import { collect } from 'api/home'
export default {
  components: {
    SharePopup: () => import('@/components/SharePopup.vue')
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    },
    showBottom: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showCommunityShare: false
    }
  },
  methods: {
    async clickShare(item) {
      this.showCommunityShare = true
    },
    clickComment() {
      this.$emit('showComment')
    },
    shareBtn() {
      this.showCommunityShare = true
    },
    async addLike(item, type) {
      // 收藏信息
      try {
        const res = await collect({
          // flag: true,
          flag: type === 1 ? !item.isCollect : !item.isLike,
          object_id: item.id,
          collectType: 13,
          type: type
        })
        if (res.code === 200) {
          this.$toast.clear()
          this.$toast(res.tip)
          this.$emit('getNewDetail', {
            ...item,
            type
          })
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        return this.$toast('请求失败，请稍后再试')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top-avatar {
  padding: 0.36rem 0.32rem;

  .left {
    width: 2.6rem;
    height: 2.6rem;
    position: relative;
    img {
      width: 100%;
    }
    .type {
      width: 0.92rem;
      height: 0.38rem;
      @include flex-center;
      position: absolute;
      right: 0.16rem;
      bottom: 0.16rem;
      color: $white;
      background: rgba(0, 0, 0, 0.5);
      img {
        width: 0.2rem;
        margin-right: 0.08rem;
      }
    }
  }
  .subtrac {
    position: relative;
    img {
      position: absolute;
      &:nth-child(2) {
        left: 50%;
        top: -0.1rem;
        width: 98%;
        transform: translateX(-50%);
      }
      &:nth-child(1) {
        left: 50%;
        top: -0.2rem;
        width: 94%;
        transform: translateX(-50%);
      }
    }
  }
  .top-avatar-top {
    @include flex-align-center;
  }
  .right {
    margin-left: 0.32rem;
    font-size: 0.28rem;
    img {
      width: 0.4rem;
      border-radius: 50%;
      margin-right: 0.08rem;
    }
    > div {
      color: #999;
      &:nth-child(1) {
        color: #fff;
        font-size: 0.32rem;
        font-style: normal;
        font-weight: 500;
        @include textoverflow;
      }
      &:nth-child(2) {
        font-style: normal;
        font-weight: 500;
        margin: 0.2rem 0;
      }
    }
  }
  .top-avatar-bottom {
    margin-top: 0.32rem;
    color: $white;
    @include flexbox;
    padding: 0 0.41rem;
    img {
      width: 0.36rem;
      margin-bottom: 0.04rem;
      transform: translateY(-0.36rem);
      filter: drop-shadow(#fff 0 0.36rem);
    }
    .more {
      @include flex-align-center;
      > div {
        margin-right: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
      }
    }
    .less {
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
      }
    }
  }
  .mine_top_bg {
    border-radius: 0.08rem;
    :deep() {
      img {
        border-radius: 0.16rem;
      }
    }
  }
}
</style>
