<template>
  <div class="my_share">
    <EBackTop title="分享推广" @click-right="$router.push('/Mine/shareHistory')">
      <slot slot="right" name="right">
        <div class="right-txt">分享记录</div>
      </slot>
    </EBackTop>
    <div class="my_share_pd">
      <div class="share_bg">
        <p>
          累计邀请 <span>{{ userInfo.invites }}人</span>
        </p>
        <div class="code">
          <EGenerateImg
            ref="EGenerateImg"
            :link="userInfo.inviteUrl"
            :name="`来自${userInfo.nickName}的分享地址.png`"
            :config="config"
          >
          </EGenerateImg>
        </div>
        <p class="my_code">
          <span>我的推广码</span>
          <span>{{ userInfo.inviteCode }}</span>
        </p>
      </div>
      <ul class="btns">
        <li @click="clickSaveImg">保存图片</li>
        <li v-clipboard:copy="userInfo.inviteUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">复制链接</li>
      </ul>
      <!-- 规则说明 -->
      <div class="tips_txt">
        <p class="title">规则说明</p>
        <p>
          1、邀请 <span>好友注册成功</span> 即可获得 <span>会员</span> 和大量<span>积分</span>。<br />
          积分可兑换会员、观看视频等等
        </p>
        <p>2、邀请说明：点击【保存二维码】或【复制推广链接】。获取专属推广链接，推荐分享给其他人下载即可。</p>
      </div>
      <img class="share_bot" src="@/assets/imgs/mine/share_bot.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      config: {
        qrSize: 180,
        scale: 2,
        qrBgwidth: 280,
        qrBgheight: 280
      },
      web: '',
      app: '',
      qrCode: '',
      userIncomeInfo: {}
    }
  },

  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    // 落地页
    ldyCdn({ $store }) {
      return $store.getters['ldyCdn']
    }
  },
  mounted() {
    // this.onRefresh()
  },
  methods: {
    onCopy() {
      this.$toast('复制成功')
    },
    onError() {
      this.$toast('不支持复制')
    },
    clickSaveImg() {
      this.$refs['EGenerateImg'].clickSaveImg()
    }
  }
}
</script>
<style lang="scss" scoped>
.my_share_pd {
  padding: 0.24rem 0.32rem;
}

.share_bg {
  background: url('../../../assets/imgs/mine/share_bg.png') no-repeat;
  background-size: 100% 100%;
  width: 6.86rem;
  height: 7.44rem;
  margin-bottom: 0.56rem;
  @include flexbox($ai: center, $fd: column, $jc: center);

  p {
    margin-top: 2.3rem;

    span {
      color: $txtActive;
    }
  }

  .code {
    width: 2.8rem;
    height: 2.8rem;
    background: #fff;
    padding: 0.2rem;
    text-align: center;
    margin-top: 0.32rem;
    box-sizing: content-box;

    :deep() {
      .template {
        // width: 100%;
        // height: 100%;

        .qrBg {
          position: static;
          width: 100% !important;
          height: 100% !important;
        }

        canvas {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }

  .my_code {
    @include flexbox($jc: center);
    margin-top: 0.4rem;

    span {
      color: #333;

      &:last-child {
        font-weight: 600;
        font-size: 0.48rem;
        color: $txtActive;
        margin-left: 0.24rem;
      }
    }
  }
}

.title {
  font-size: 0.28rem;
  font-weight: 600;
  margin: 0.26rem 0;
}

.tips_txt {
  span {
    color: $txtActive;
  }

  p {
    margin-bottom: 0.2rem;
  }
}

.btns {
  @include flexbox($jc: space-around);

  li {
    background: $gradientBg;
    color: #fff;
    border-radius: 0.48rem;
    width: 2.4rem;
    height: 0.8rem;
    text-align: center;
    line-height: 0.8rem;
  }
}

.share_bot {
  width: 6.86rem;
  height: 9.84rem;
  margin-top: 0.6rem;
}
</style>
