<template>
  <div class="video-card-footer">
    <div class="title">
      <img src="@/assets/imgs/acg/comisc_card_play.png" alt="" />
      <span>{{ data.title }}</span>
    </div>
    <SerialStatus :data="data"></SerialStatus>
  </div>
</template>
<script>
export default {
  name: 'VideoCardFooter',
  components: {
    SerialStatus: () => import('@/components/Home/SerialStatus.vue')
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.video-card-footer {
  margin-top: 0.08rem;
  .title {
    @include flexbox($jc: flex-start);
    @include textoverflow(1);
    img {
      width: 0.24rem;
      height: 0.24rem;
      margin-right: 0.12rem;
    }
  }
}

.serialStatus {
  display: flex;
  margin-top: 0.1rem;
  div {
    border-radius: 0.04rem;
    font-size: 0.2rem;
    padding: 0.02rem 0.1rem;
  }
  .loading {
    color: rgba(39, 132, 184, 1);
  }
  .finished {
    color: rgba(255, 125, 5, 1);
  }
}
</style>
