<template>
  <div class="community">
    <div v-if="lotteryTopAd.length" class="swiperPack">
      <EADSwiper :list="lotteryTopAd" key-name="cover" :EADconfig="EADconfig" :img-c-d-n="imgCDN" @clickImg="clickImg">
      </EADSwiper>
    </div>
    <div class="tagList">
      <EDecryptImg v-for="(item, index) in findTagList" :key="index" :imgCDN="imgCDN" :imgURL="item.imageUrl"
        class="tag" @click.native="$router.push(`/community/tagDetail/${item.id}?title=${item.name}`)">
        <div class="abbox">
          <div style="font-size: 0.3rem; font-weight: 500">{{ item.name }}</div>
          <div style="font-size: 0.2rem; font-weight: 400">{{ item.totalPost }}个帖子</div>
        </div>
      </EDecryptImg>
      <!-- <div class="tag" v-for="(item,index) in findTagList" :key="index">


      </div> -->
    </div>
    <tag-select v-model="sort" :data="tagList" @input="sortSwitch" />
    <ERefreshLoadV3 :finished="finished" :beforeRefresh="refreshData" :moreData="moreData">
      <div class="post-list">
        <post-item-with-opr v-for="(item, index) in communityList" :key="index" :data="item" class="post-item">
          <template #top="{ data }">
            <top-avatar :data="data" class="top-avatar" />
          </template>
        </post-item-with-opr>
      </div>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { JumpTo } from '@/utils/utils_tools'
import { community_list } from 'api/community'

export default {
  name: 'CommunityIndex',

  components: {
    TagSelect: () => import('@/components/Community/TagSelect.vue'),
    TopAvatar: () => import('@/components/Community/topAvatar.vue'),
    PostItemWithOpr: () => import('@/components/Community/postItemWithOpr.vue')
  },
  props: {
    navItem: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      sort: 0,
      tagList: [
        {
          title: '推荐',
          id: 0
        },
        {
          title: '最新',
          id: 1
        },
        {
          title: '最热',
          id: 2
        },
        {
          title: '精华',
          id: 3
        },
        {
          title: '视频',
          id: 4
        }
      ],
      pageNum: 0,
      pageSize: 5,
      loading: false, //上拉加载
      finished: false, //上拉加载完成
      refreshing: false, //下拉刷
      communityList: [],
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      }
    }
  },
  computed: {
    lotteryTopAd() {
      return this.$store.getters['commityAD']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    findTagList({ $store }) {
      return $store.getters['postSection'].filter(item => item.postCategoryId === this.navItem.item.id)
    }
  },
  methods: {
    sortSwitch(sort) {
      //切换排序
      this.pageNum = 1
      this.sort = sort
      this.communityList = []
      this.getPostList()
    },
    clickImg(item) {
      const { href } = item
      if (!href) return
      JumpTo(href)
    },

    async getPostList() {
      try {
        const res = await community_list({
          categoryId: this.navItem.item.id,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          sort: this.sort
        })
        if (res.code === 200) {
          this.communityList = [...this.communityList, ...res.data.list]
          //  图片超出9张只显示9张
          this.communityList.map(item => {
            if (item.imgs?.length > 9) {
              item.imgs = item.imgs.splice(0, 9)
            }
          })
          if (!res.data.list || res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      } catch (error) {
        this.finished = true
        this.$toast('请求错误，请稍后再试')
      }
    },
    //上拉刷新
    refreshData() {
      this.finished = false
      this.pageNum = 0
      this.communityList = []
    },
    //下拉加载
    async moreData() {
      this.pageNum += 1
      await this.getPostList()
    }
  }
}
</script>

<style scoped lang="scss">
.community {
  padding: 0 0.32rem;

  .swiperPack {
    margin: 0 auto 0.36rem;
    width: 6.86rem;
    border-radius: 0.16rem;
    overflow: hidden;
    height: 2.86rem;
  }

  .tagList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .tag {
      color: #ffffff;
      border-radius: 0.08rem;
      width: 2.22rem;
      height: 1rem;
      overflow: hidden;
      position: relative;
      margin-right: 0.1rem;
      margin-bottom: 0.2rem;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      &::after {
        content: '';
        display: block;
        backdrop-filter: blur(2px);
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
      }

      .abbox {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 10;
      }
    }
  }

  .post-list {
    .post-item {
      margin-top: 0.36rem;
      border-bottom: 0.01rem solid #f0f0f0;

      .top-avatar {
        margin-bottom: 0.24rem;
      }
    }
  }
}
</style>
