<template>
  <div class="palylist_publish">
    <EBackTop title="新建播单" @click-right="addPublish">
      <slot slot="right" name="right">
        <div class="right-txt">保存</div>
      </slot>
    </EBackTop>
    <ul class="add_menu">
      <li @click.stop="uploadImgFn">
        <p class="label">播单封面</p>
        <div class="label_value">
          <img v-if="!cover" class="cover" src="@/assets/imgs/mine/add_play_list_icon.png" alt />
          <EDecryptImg v-else class="cover" :imgURL="cover"></EDecryptImg>
          <van-icon size="0.32rem" name="arrow" color="#7F7F7F" />
        </div>
        <UploadImg ref="uploadImg" style="display: none" @result="result" />
      </li>
      <li>
        <van-field v-model="playlistTitle" maxlength="12" placeholder="请填写播单标题" show-word-limit />
      </li>
      <li @click="show = true">
        <p class="label">播单类型</p>
        <div class="label_value">
          {{ type == 1 ? '视频播单' : type == 2 ? '图片播单' : '请选择' }}
          <van-icon color="#7F7F7F" size="0.32rem" name="arrow" />
        </div>
      </li>
      <li @click="showTip = true">
        <p class="label">
          当前包含
          <span>0</span>个资源
        </p>
        <div class="label_value add"><van-icon color="#fff" name="add-o" size="0.32rem" />新增</div>
      </li>
    </ul>
    <van-popup v-model="show" class="type-pop" position="bottom">
      <ul>
        <li class="type_item" @click="checkType(1)">
          <img src="@/assets/imgs/mine/video_type_playlist.png" alt />
          视频播单
        </li>
        <li class="type_item" @click="checkType(2)">
          <img src="@/assets/imgs/mine/photo_type_playlist.png" alt />
          图片播单
        </li>
        <li>取消</li>
      </ul>
    </van-popup>
    <van-popup v-model="showTip" class="tip-pop" position="center">
      <div>
        <p>
          播单创建完成后,在‘视频详情’ 或 ‘图集详情’ 点击
          <span>分享</span> 即可添加到播单.
        </p>
        <p class="btn" @click="showTip = false">确定</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { creatPlayList } from '@/api/playList'
export default {
  components: {
    UploadImg: () => import('@/components/Imgs/UploadImg')
  },
  inject: ['reload'],
  data() {
    return {
      playlistTitle: undefined,
      cover: undefined,
      type: undefined,
      show: false,
      showTip: false
    }
  },

  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },

    getPlayListData({ $store }) {
      return $store.getters['getPlayListData']
    }
  },

  mounted() {},

  methods: {
    uploadImgFn() {
      this.$refs.uploadImg.$refs.Uploader.chooseFile()
    },

    async addPublish() {
      // const res = await creatPlayList({
      //   cover: this.cover[0],
      //   title: this.playlistTitle,
      //   type: this.type
      // })
      // if (res.code === 200) {
      //   this.reload()
      //   return this.$toast('新建播当完成，请等待审核~')
      // } else {
      //   return this.$toast(res.msg)
      // }

      // 存本地
      if (!this.cover) {
        return this.$toast('请选择封面图')
      }
      if (!this.playlistTitle) {
        return this.$toast('请填写播单标题')
      }
      if (!this.type) {
        return this.$toast('请选择播单类型')
      }

      let dataId = 0
      if (this.getPlayListData.length > 0) {
        dataId = this.getPlayListData[this.getPlayListData.length - 1].id + 1
      }
      let data = {
        id: dataId,
        cover: this.cover[0],
        title: this.playlistTitle,
        type: this.type,
        total: 0,
        userAvartar: this.userInfo.avatarUrl,
        userId: this.userInfo.id,
        userName: this.userInfo.nickName,
        isLocal: true, // 是否是本地存储
        mediaIds: [], //视频列表
        actressphoto: [], //图片列表
        topicId: 0 //播单主题
      }

      this.$store.dispatch('setPlayListData', {
        type: 'add',
        item: data
      })

      // console.log('user', this.userInfo, this.getPlayListData)
      return this.$toast('创建成功')
    },

    checkType(type) {
      this.show = false
      this.type = type
    },
    result(res) {
      this.cover = res
    }
  }
}
</script>

<style lang="scss" scoped>
.right-txt {
  padding: 0.04rem 0.12rem;
  border-radius: 0.08rem;
  background: rgba(221, 0, 27, 0.1);
  color: $txtActive;
}

.add_menu {
  padding: 0.36rem 0.32rem;
  li {
    border-radius: 0.16rem;
    background: rgba(0, 0, 0, 0.05);
    padding: 0.22rem 0.18rem 0.22rem 0.24rem;
    margin-bottom: 0.24rem;
    @include flexbox();
    :deep() {
      .van-cell {
        background-color: transparent !important;
        padding: 0;
        .van-cell__value {
          @include flexbox();
        }
        .van-field__word-limit {
          color: $txtActive;
        }
      }
    }
    .label {
      flex: none;

      span {
        color: $txtActive;
      }
    }

    .label_value {
      @include flexbox($jc: flex-start);

      .cover {
        width: 0.64rem;
        height: 0.64rem;
        margin-right: 0.08rem;
      }
    }

    .add {
      border-radius: 0.46rem;
      background: #e8619d;
      color: #fff;
      padding: 0.08rem 0.16rem;

      .van-icon {
        margin-right: 0.08rem;
      }
    }
  }
}
.type-pop {
  background: transparent;
  padding: 0.3rem 0.34rem;
  li {
    background: #fff;
    @include flexbox($jc: center);
    padding: 0.32rem 0;
    &:first-child {
      border-radius: 0.12rem 0.12rem 0 0;
    }
    &:nth-child(2) {
      border-radius: 0 0 0.12rem 0.12rem;
    }
    img {
      margin-right: 0.24rem;
      width: 0.32rem;
      height: 0.32rem;
    }
    &:last-child {
      margin-top: 0.24rem;
      border-radius: 0.12rem;
    }
  }
}
.tip-pop {
  padding: 0rem 0.38rem;
  text-align: center;
  background: transparent;
  font-size: 0.3rem;
  div {
    background: #fff;
    border-radius: 0.12rem;
    padding: 0 0.26rem;
    width: 6.76rem;
    p {
      padding: 0.32rem 0;
    }
    span {
      color: $txtActive;
    }
    .btn {
      padding: 0.32rem 0;
      border-top: 0.01rem solid #f0f0f0;
      color: $txtActive;
    }
  }
}
</style>
