<template>
  <div class="item">
    <div class="cover" @click="handleClick(data)">
      <EDecryptImgNolazy :imgURL="data.coverImg" @loadedImg="loadedImg"> </EDecryptImgNolazy>
      <div slot="mask" class="videoFooter">
        <img src="@/assets/imgs/home/cartoonPlay.png" alt="" />
        {{ data.title }}
      </div>
      <slot name="mask" :data="data"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['data'],
  data() {
    return {}
  },

  mounted() {},

  methods: {
    handleClick() {
      this.$router.push(`/play/longVideo/${this.data.id}`)
    },
    loadedImg() {
      this.$emit('loadedImg')
    }
  }
}
</script>
<style lang="scss" scoped>
.item {
  width: 3.36rem;
  .cover {
    width: 100%;
    border-radius: 0.06rem 0.06rem 0 0;
    overflow: hidden;
    position: relative;
    .top {
      position: absolute;
      width: 0.29 * 2rem;
      z-index: 1;
      right: 0rem;
      top: 0rem;
      > img {
        width: 0.29 * 2rem;
        height: 0.29 * 2rem;
      }
    }

    .videoFooter {
      position: absolute;
      bottom: 0rem;
      width: 100%;
      height: 0.6rem;
      line-height: 0.6rem;
      padding: 0 0.12rem;
      font-size: 0.22rem;
      font-weight: 400;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      color: #fff;

      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        img {
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 0.12rem;
        }
      }
    }
  }
}
</style>
