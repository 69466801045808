<template>
  <div v-if="itemList && itemList.length" class="sbhp-list-main">
    <HomeTitle
      :title="name"
      :path="`/acg/acgTopicDetail/?title=${name}&type=${isNovelItem ? 'novel' : 'comics'}&id=${id}`"
    />
    <ul class="sbhp-list">
      <li v-for="item in itemList" :key="item.id">
        <ComicsOneCard :isNovelItem="isNovelItem" width="2.66rem" height="3.48rem" :itemData="item" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {
    ComicsOneCard: () => import('./comicsOneCard.vue'),
    HomeTitle: () => import('@/components/homeTitle.vue')
  },
  props: ['itemList', 'name', 'isNovelItem', 'id']
}
</script>

<style lang="scss" scoped>
.sbhp-list-main {
  margin-bottom: 0.36rem;
}
.sbhp-list {
  @include flexbox($jc: flex-start);
  padding-bottom: 0.36rem;
  border-bottom: 0.01rem solid #f0f0f0;
  overflow-x: auto;

  li {
    width: 2.66rem;
    margin-right: 0.16rem;
    flex: none;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
