<template>
  <ERefreshLoadV3 ref="ERefreshLoadV3" :beforeRefresh="onRefresh" :moreData="onLoad" :finished="finished">
    <div class="main_list">
      <EWaterFall :datasList="dataList">
        <template #left="{ handleLoad, data }">
          <div class="item">
            <ImgshowType2 :data="data" class="leftItem" @loadedImg="handleLoad"> </ImgshowType2>
            <!-- 遮罩层 -->
            <div v-if="showMask" class="overlay" @click.stop="clickItem(data)">
              {{ data.check }}
              <img
                :src="
                  data.check
                    ? require('@/assets/imgs/mine/active_check.png')
                    : require('@/assets/imgs/mine/default_check.png')
                "
                alt=""
              />
            </div>
          </div>
        </template>
        <template #right="{ handleLoad, data }">
          <div class="item">
            <ImgshowType2 :data="data" class="rightItem" @loadedImg="handleLoad"> </ImgshowType2>
            <!-- 遮罩层 -->
            <div v-if="showMask" class="overlay" @click.stop="clickItem(data)">
              <img
                :src="
                  data.check
                    ? require('@/assets/imgs/mine/active_check.png')
                    : require('@/assets/imgs/mine/default_check.png')
                "
                alt=""
              />
            </div>
          </div>
        </template>
      </EWaterFall>
    </div>
  </ERefreshLoadV3>
</template>

<script>
import { collect_list, collect_del, pay_history } from 'api/user'

export default {
  components: {
    ImgshowType2: () => import('components/Home/showType/pictrueListType/imgshowType2.vue')
  },
  props: {
    cardType: {
      type: String,
      default: 'collect'
    },
    showMask: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 关注列表
      dataList: [],
      // 当前页
      pageNum: 0,
      // 当前个数
      pageSize: 10,
      showUpBtn: false
    }
  },
  computed: {
    info({ $store }) {
      return $store.getters['getUserInfo']
    },
    // 获取长视频历史记录
    getPhotoList({ $store }) {
      return $store.getters['getPhotoList']
    }
  },

  mounted() {},

  methods: {
    clickItem(item) {
      this.$set(item, 'check', !item.check)
    },
    async delOne() {
      if (this.cardType === 'history') {
        this.$store.dispatch('setPhotoList', { type: 'del', arr: this.dataList.filter(item => !item.check) })
        this.dataList = JSON.parse(JSON.stringify(this.getPhotoList))
        this.finished = true
      } else if (this.cardType === 'collect') {
        // 收藏列表删除
        const res = await collect_del({
          collectType: 9,
          objectIds: this.dataList.filter(item => item.check).map(item => item.id),
          type: 1
        })
        if (res.code === 200) {
          this.pageNum = 1
          this.dataList = []
          await this.getPostCollectList()
        } else {
          return this.$toast('操作失败，请联系后台管理员！')
        }
      }
    },
    toSelectAll(val = true) {
      this.dataList.forEach(item => {
        this.$set(item, 'check', val)
      })
    },
    // 下拉加载
    async onLoad() {
      this.pageNum++
      if (this.cardType === 'collect') {
        await this.getPostCollectList()
      } else if (this.cardType === 'pay') {
        await this.getPhotoPayList()
      } else if (this.cardType === 'history') {
        this.dataList = JSON.parse(JSON.stringify(this.getPhotoList))
        this.finished = true
      }
    },
    // 上拉刷新
    async onRefresh() {
      this.pageNum = 0
      this.dataList = []
    },
    // 收藏列表
    async getPostCollectList() {
      const res = await collect_list({
        collectType: 9,
        userId: this.info.id,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        type: 1
      })
      if (res.code == 200) {
        this.dataList = [...this.dataList, ...(res.data.photoList || [])]

        if (res.data.photoList?.length < this.pageSize || !res.data.photoList) {
          this.finished = true
        }
      } else {
        this.finished = true
      }
    },
    // 购买列表 pay_history
    async getPhotoPayList() {
      const res = await pay_history({
        type: 9,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      })
      if (res.code == 200) {
        this.dataList = [...this.dataList, ...(res.data.photoList || [])]
        if (res.data.photoList?.length < this.pageSize || !res.data.photoList) {
          this.finished = true
        }
      } else {
        this.finished = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.main_list {
  padding: 0.32rem;
}
.item {
  position: relative;
}
.overlay {
  width: 100%;
  height: 100%;
  border-radius: 0.2rem;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  img {
    width: 0.48rem;
    height: 0.48rem;
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
  }
}
.post-item {
  border-bottom: 0.01rem solid #f0f0f0;
}
</style>
