<template>
  <div class="share_history">
    <EBackTop title="分享记录"></EBackTop>
    <p class="total_nums">
      已分享人数: <span>{{ userInfo.invites }}</span
      >人
    </p>
    <ERefreshLoadV3
      ref="ERefreshLoadV3"
      class="main_list"
      :beforeRefresh="onRefresh"
      :moreData="onLoad"
      :finished="finished"
    >
      <ul class="share_listWrap">
        <li v-for="(item, index) in transList" :key="index" class="item">
          <div class="item_left">
            <EDecryptImg class="item_avatar_icon" :imgURL="item.avatarUrl ? item.avatarUrl : ''"> </EDecryptImg>
            <p>{{ item.nickName }}</p>
          </div>
          <span>注册时间:{{ item.updatedAt | timeYmd }}</span>
        </li>
      </ul>
    </ERefreshLoadV3>
  </div>
</template>
<script>
import { inviteList } from 'api/user'
export default {
  data() {
    return {
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 关注列表
      transList: [],
      // 请求类型
      getType: '',
      // 当前页
      pageNum: 0,
      // 当前个数
      pageSize: 10,
      showReject: false,
      activeItem: 0,
      status: 1
    }
  },

  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  mounted() {
    // this.onRefresh()
  },
  methods: {
    // 获取流水记录
    async get_transList() {
      try {
        const res = await inviteList({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.transList = [...this.transList, ...res.data.userList]
          if (res.data && res.data.userList.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
          return this.$toast(res.tip)
        }
      } catch (error) {
        this.finished = true
        console.log(error)
      }
    },
    showRejectFn(item) {
      this.activeItem = item
      this.showReject = true
    },
    // 下拉加载
    async onLoad() {
      this.pageNum++
      await this.get_transList()
    },
    // 上拉刷新
    onRefresh() {
      this.pageNum = 0
      this.transList = []
      this.finished = false
    },
    onCopy() {
      this.$toast('复制成功！！！')
    },
    onError() {
      this.$toast('复制失败！！！')
    }
  }
}
</script>
<style lang="scss" scoped>
.income_detail {
  color: rgba($color: #333, $alpha: 0.5);
}
.total_nums {
  margin-top: 0.24rem;
  text-align: center;
  span {
    color: $txtActive;
  }
}
.share_listWrap {
  padding: 0.24rem 0.32rem;
  li {
    padding: 0.12rem 0.24rem;
    border-radius: 0.12rem;
    background: rgba(0, 0, 0, 0.04);
    @include flexbox();
    margin-bottom: 0.24rem;
    .item_left {
      @include flexbox($jc: flex-start);
      p {
        font-size: 0.32rem;
        font-weight: 600;
      }
    }
    .item_avatar_icon {
      width: 0.76rem;
      height: 0.76rem;
      margin-right: 0.24rem;
    }
  }
}
.time {
  color: $grey;
}
</style>
