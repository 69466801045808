<template>
  <div class="waterFall">
    <div class="rowWrap">
      <div ref="waterFallLeft" class="left">
        <div v-for="(item, index) in waterFallLeftArr" :key="index">
          <slot name="left" :data="item" :handleLoad="handleLoad"></slot>
        </div>
      </div>
      <div ref="waterFallRight" class="right">
        <div v-for="(item, index) in waterFallRightArr" :key="index">
          <slot name="right" :data="item" :handleLoad="handleLoad"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EWaterFall',
  components: {},
  props: {
    datasList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      // 左右数据
      waterFallLeftArr: [],
      waterFallRightArr: [],
      // 当前加载位置
      nowIndex: 0,
      list: []
    }
  },
  computed: {},
  watch: {
    'datasList.length': {
      handler(n, o) {
        this.list = this.datasList
        // console.log(n)
        //0，或者小于之前为刷新
        if (!n || n < o) {
          this.waterFallLeftArr = []
          this.waterFallRightArr = []
          this.nowIndex = 0
        } else {
          //进行高度计算
          this.handleLoad()
        }
      }
    }
  },
  mounted() {
    this.list = this.datasList
    if (this.list.length) {
      this.handleLoad()
    }
  },
  methods: {
    //计算高度
    handleLoad() {
      let leftHeight = this.$refs['waterFallLeft'].offsetHeight
      let rightHeight = this.$refs['waterFallRight'].offsetHeight
      // // 图片加载自动触发则+1（包括：timeOut，err，finish），目前：只有timeOut，开局全部加载
      if (leftHeight > rightHeight && this.list[this.nowIndex]) {
        this.waterFallRightArr.push(this.list[this.nowIndex])
      }
      if (rightHeight >= leftHeight && this.list[this.nowIndex]) {
        // 特殊情况断网
        if (rightHeight == 0 && leftHeight == 0 && this.waterFallLeftArr.length >= 5) {
          this.waterFallLeftArr = []
          this.waterFallRightArr = []
        }
        this.waterFallLeftArr.push(this.list[this.nowIndex])
      }
      this.nowIndex++
    }
  }
}
</script>
<style lang="scss" scoped>
.waterFall {
  width: 100%;
  box-sizing: border-box;

  .rowWrap {
    clear: both;
    overflow: hidden;
    height: 100%;

    .left,
    .right {
      display: inline-block;
      width: 50%;
      > div {
        min-height: 2.2rem;
        overflow: hidden;
      }
    }

    .left {
      float: left;
    }

    .right {
      float: right;
    }
  }
}
</style>