<template>
  <div>
    <EBackTop :title="'消息中心'"> </EBackTop>
    <ETabV2
      ref="tab"
      class="messages_tab"
      offset-top="1rem"
      :placeholder="false"
      background="#fff"
      :tabList="tabList"
      :activeId="type"
      :animated="false"
      :sizeActive="'.38rem'"
      lineSwitch
      :sticky="true"
      @change="changeId"
    >
      <template #default="scope">
        <ERefreshLoadV3
          ref="ERefreshLoadV3"
          class="main_list"
          :beforeRefresh="onRefresh"
          :moreData="onLoad"
          :finished="finished"
          :autoOnload="false"
        >
          <component :is="compComponent(scope)" v-for="(item, index) in dataList" :key="index" :itemData="item">
          </component>
        </ERefreshLoadV3>
      </template>
    </ETabV2>
  </div>
</template>

<script>
import { message_list } from '@/api/user'
export default {
  components: {
    AnnouncementItem: () => import('@/components/messages/announcementItem.vue'),
    CommentsItem: () => import('@/components/messages/commentsItem.vue'),
    PrivateLetter: () => import('@/components/messages/privateLetter.vue'),
    ReplyItem: () => import('@/components/messages/replyItem.vue'),
    ZanItem: () => import('@/components/messages/zanItem.vue')
  },
  data() {
    return {
      introduction: undefined,
      type: 1,
      finished: false,
      pageSize: 10,
      pageNum: 0,
      tabList: [
        {
          id: 1,
          name: '私信'
        },
        {
          id: 2,
          name: '评论'
        },
        {
          id: 3,
          name: '点赞'
        },
        {
          id: 4,
          name: '回复'
        },
        {
          id: 0,
          name: '公告'
        }
      ],
      dataList: []
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    info({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  mounted() {
    this.pageNum = 0
    this.dataList = []
    this.onLoad()
  },
  methods: {
    changeId(item) {
      this.type = item
      this.pageNum = 0
      this.dataList = []
      this.onLoad()
    },

    onRefresh() {
      this.pageNum = 0
      this.dataList = []
    },
    async onLoad() {
      this.pageNum++
      await this.getMessageList()
    },
    async getMessageList() {
      const res = await message_list({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        type: this.type
      })
      if (res.code === 200) {
        const listType = {
          0: 'list',
          1: 'dialoglist',
          2: 'commentList',
          3: 'likeList',
          4: 'replyList'
        }
        if (res.data[listType[this.type]] && res.data[listType[this.type]].length > 0) {
          this.dataList = [...this.dataList, ...res.data[listType[this.type]]]
          if (res.data[listType[this.type]].length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
        }
      } else {
        this.finished = true
        return this.$toast(res.tip)
      }
    },
    compComponent(data) {
      switch (data.item.id) {
        case 0:
          return 'AnnouncementItem'
        case 1:
          return 'CommentsItem'
        case 2:
          return 'PrivateLetter'
        case 3:
          return 'ReplyItem'
        case 4:
          return 'ZanItem'
        default:
          return 'AnnouncementItem'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.messages_tab {
  margin-top: 0.3rem;
}
</style>
