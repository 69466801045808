<template>
  <div class="community-topic-wrap">
    <div class="featuredTopics">
      <div class="title">话题精选</div>
      <SquareExhibit :circleList="topicList" />
    </div>
    <!-- 搜索 -->
    <!--    <div class="search-clickbox-box" @keyup.enter="fn_search">-->
    <!--      <van-field-->
    <!--        class="search-clickbox"-->
    <!--        type="text"-->
    <!--        placeholder="搜索话题可以获得更多互动"-->
    <!--        @click.native="$router.push('/community/topicList')"-->
    <!--      />-->
    <!--      <img src="@/assets/imgs/community/Microphone1.png" alt="" />-->
    <!--    </div>-->
    <div class="community-topic" :style="{ height }">
      <tag-select-ver v-model="sort" :data="categoryList" class="community-topic-tabs" @input="sortSwitch" />
      <!--      <ERefreshLoadV3 :finished="finished" :beforeRefresh="refreshData" :moreData="moreData" style="width: 100%">-->
      <div class="community-topic-content">
        <van-index-bar ref="indexBar">
          <template v-for="item in recommendList">
            <van-index-anchor :key="'bar' + item.id" :index="`${item.name}`" style="height: 0; overflow: hidden" sticky>
              {{ item.name }}
            </van-index-anchor>
            <recommend-card :key="item.id" :data="item" class="community-topic-content-card" />
          </template>
        </van-index-bar>
      </div>
      <!--      </ERefreshLoadV3>-->
    </div>
  </div>
</template>
<script>
import { discuss_category, discuss_top, select_discuss } from 'api/community'

export default {
  name: 'CommunityTopic',

  components: {
    SquareExhibit: () => import('@/components/Community/communityTopic/SquareExhibit/index.vue'),
    TagSelectVer: () => import('@/components/Community/communityTopic/TagSelectVer.vue'),
    RecommendCard: () => import('@/components/Community/communityTopic/recommendCard/RecommendCard.vue')
  },
  props: {
    navItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      sort: 0,
      categoryList: [],
      recommendList: [],
      pageNum: 1,
      pageSize: 1000,
      finished: false,
      //话题精选
      topicList: [],
      //搜索内容
      searchTxt: '',
      showSearch: false,
      // 搜索显示列表
      searchCircleList: [],
      height: '200px'
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {
    this.featured_topic()
    this.computeHeight()
    this.getList()
  },
  methods: {
    computeHeight() {
      let height = window.innerHeight
      const fontSize = +document.documentElement.style.fontSize.replace('px', '')
      this.height = height / fontSize - 3.12 + 'rem'
    },
    //周话题精选
    async featured_topic() {
      let res = await discuss_top({})
      if (res.code === 200) {
        this.topicList = [...res.data.topDiscussList]
      } else {
        this.$toast(res.tip)
      }
    },
    selectCategoryList() {
      select_discuss({
        // category: this.sort,
        content: this.searchTxt,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code === 200) {
          if (this.searchTxt) {
            this.searchCircleList = res.data.categoryList
            this.finished = true
            return
          }
          this.recommendList = [...this.recommendList, ...res.data.categoryList]
          if (res.data.categoryList.length < this.pageSize || !res.data.categoryList) {
            this.finished = true
          }
        } else {
          this.finished = true
        }
      })
    },
    async getList() {
      const res = await discuss_category({})
      if (res.code === 200) {
        this.categoryList = res.data.categoryList
        if (this.categoryList && this.categoryList.length) {
          this.sort = this.categoryList[0].id
          await this.selectCategoryList()
        } else {
          this.finished = true
        }
      } else {
        this.finished = true
      }
    },
    sortSwitch(sort) {
      console.log(this.categoryList.find(item => item.id === sort).name)
      this.$refs.indexBar.scrollTo(this.categoryList.find(item => item.id === sort).name)
      // this.pageNum = 1
      // this.recommendList = []
      // this.selectCategoryList()
    },
    //上拉刷新
    refreshData() {
      this.finished = false
      this.pageNum = 0
      this.recommendList = []
    },
    //下拉加载
    async moreData() {
      this.pageNum += 1
      if (this.categoryList.length) {
        await this.selectCategoryList()
      } else {
        await this.getList()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.community-topic-wrap {
  .featuredTopics {
    background-image: url('~@/assets/imgs/community/element.png');
    background-size: 5.2rem 1.76rem;
    background-repeat: no-repeat;
    background-position: right top;
    margin-top: 0.24rem;
    margin-bottom: 0.2rem;
    min-height: 3rem;

    .title {
      font-size: 0.3rem;
      height: 0.3rem;
      line-height: 0.3rem;
      color: #333;
      margin-left: 0.24rem;
      margin-bottom: 0.2rem;
      font-weight: 500;
    }
  }
  .search-clickbox-box {
    position: relative;
    width: 100%;
    max-width: $pcMaxWidth;
    z-index: 2;
    background-color: #fff;
    padding: 0.2rem 0;

    // border-bottom: 1px solid #eee;
    .search-clickbox {
      margin: 0 auto;
      width: 92%;
      height: 0.64rem;
      border-radius: 25px;
      background: #f8f8f8;
      color: #818185;
      font-size: 0.3rem;
      display: flex;
      align-items: center;

      ::v-deep {
        .van-field__control {
          text-indent: 0.5rem;
          font-size: 0.22rem;
          color: #333;
        }
      }

      &::after {
        border-bottom: 0;
      }
    }

    img {
      position: absolute;
      left: 0.3rem;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      margin-left: 0.4rem;
      width: 0.24rem;
      height: 0.24rem;
    }
  }
  .community-topic {
    display: flex;
    background-color: #f7f7f7;
    overflow: hidden;
    .community-topic-tabs {
      width: 1.52rem;
      flex-shrink: 0;
      background-color: #fff;
      overflow-y: auto;
    }
    .community-topic-content {
      padding: 0.24rem 0.34rem 0.9rem 0.24rem;
      overflow-y: auto;
      ::v-deep {
        .van-index-bar__sidebar {
          display: none;
        }
        .van-index-anchor {
          font-size: 0.3rem;
          line-height: 0.3rem;
          color: #333333;
          padding-top: 0.1rem;
          padding-left: 0;
        }
        .van-index-anchor--sticky {
          background-color: #f7f7f7;
        }
      }
      .community-topic-content-card {
        margin-bottom: 0.24rem;
      }
    }
  }
}
</style>
