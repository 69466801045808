<template>
  <div class="lady-info">
    <div class="lady-icon-box">
      <EDecryptImg :img-u-r-l="data.avatar" class="lady-icon" />
      <div class="lady-icon-flag">女优</div>
    </div>
    <div class="lady-name">{{ data.name }}</div>
    <div class="lady-type">{{ data.office }}</div>
    <div class="lady-more" @click="$router.push(`/discover/actor/${data.id}`)">查看</div>
  </div>
</template>
<script>
export default {
  name: 'LadyListType1',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleClick(data) {
      if (data) {
        this.$router.push(`/discover/actor/${data.id}`)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.lady-info {
  width: 1.2rem;
  text-align: center;
  .lady-icon-box {
    position: relative;
    .lady-icon {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      overflow: hidden;
    }
    .lady-icon-flag {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0.6rem;
      height: 0.24rem;
      border-radius: 0.12rem;
      background: $gradientBg;
      font-size: 0.2rem;
      color: #ffffff;
    }
  }

  .lady-name {
    margin-top: 0.1rem;
    font-weight: 500;
    font-size: 0.24rem;
    line-height: 0.36rem;
    color: #333333;
  }

  .lady-type {
    margin-top: 0.04rem;
    font-style: normal;
    font-weight: 400;
    font-size: 0.2rem;
    line-height: 0.28rem;
    color: #999999;
  }
  .lady-more {
    margin: 0.1rem auto;
    @include flex-center;
    width: 0.86rem;
    height: 0.4rem;
    border: 0.01rem solid #ffa800;
    color: #ffa800;
    font-size: 0.24rem;
    line-height: 0.32rem;
    border-radius: 0.56rem;
  }
}
</style>
