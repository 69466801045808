var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"session-main",style:({ height: _vm.height })},[_c('EBackTop',{staticClass:"back",attrs:{"open-fallback":true,"title":_vm.friendInfo.nickName}}),_c('van-pull-refresh',{attrs:{"pulling-text":"","success-text":"","loosing-text":"加载消息中...","pull-distance":"20","head-height":"40"},on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('div',{staticClass:"conetnt-list message-listWrap"},[_vm._l((_vm.msgList),function(item,index){return _c('div',{key:index,staticClass:"msg-inner-item",class:{ right: item.fromId === _vm.userInfo.id }},[_c('EDecryptImg',{class:{
            avatarLeft: item.fromId !== _vm.userInfo.id,
            avatarRight: item.fromId === _vm.userInfo.id
          },attrs:{"imgURL":item.fromId === _vm.userInfo.id ? _vm.userInfo.avatarUrl : item.avatar}}),_c('div',{staticClass:"msg-item msg-align-left",class:{
            reply: item.fromId !== _vm.userInfo.id,
            sendMsg: item.fromId === _vm.userInfo.id,
            'msg-align-left': item.fromId !== _vm.userInfo.id,
            'msg-align-right': item.fromId === _vm.userInfo.id
          }},[(!item.picture)?_c('div',{staticClass:"msg-item-content",class:{
              'msg-border-ridius-right': item.fromId === _vm.userInfo.id,
              'msg-color-right': item.fromId === _vm.userInfo.id,
              'msg-border-ridius-left': item.fromId !== _vm.userInfo.id,
              'msg-color-left': item.fromId !== _vm.userInfo.id
            }},[_c('p',[_vm._v(" "+_vm._s(item.text)+" ")])]):_c('div',{staticClass:"msg-item-picture",class:{
              'msg-border-ridius-right': item.fromId === _vm.userInfo.id,
              'msg-color-right': item.fromId === _vm.userInfo.id,
              'msg-border-ridius-left': item.fromId !== _vm.userInfo.id,
              'msg-color-left': item.fromId !== _vm.userInfo.id
            }},[_c('EDecryptImg',{attrs:{"imgURL":item.picture}})],1),_c('div',{staticClass:"msg-item-time",class:{
              'msg-item-time-right': item.fromId === _vm.userInfo.id,
              'msg-item-time-left': item.fromId !== _vm.userInfo.id
            }},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAt))+" ")])])],1)}),_c('div',{attrs:{"id":"scrollIntoViewChat"}})],2)]),_c('div',{staticClass:"comment-text"},[_c('van-field',{staticClass:"send-input",attrs:{"placeholder":"请输入你的文字内容"},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c('div',{staticClass:"uploadImg-wrap"},[_c('van-uploader',{ref:"Uploader",staticClass:"btn",attrs:{"after-read":_vm.onReadImg,"multiple":"","accept":"image/*"}})],1),_c('div',{staticClass:"send-text",on:{"click":_vm.sendMsg}})],1),_c('NoticeVipPop',{model:{value:(_vm.showVipPop),callback:function ($$v) {_vm.showVipPop=$$v},expression:"showVipPop"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }