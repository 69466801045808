<template>
  <div>
    <div class="title" @click.stop="handleClickTitle">{{ title }} <img src="@/assets/imgs/home/more.png" alt="" /></div>
    <div v-if="list && list.length > 0" class="content" @touchmove.stop>
      <VideoShowType5 v-for="data in list" :key="data.id" :data="data" class="content-item" />
    </div>
  </div>
</template>
<script>
import { JumpTo } from '@/utils/utils_tools'
export default {
  name: 'ShortVideo',
  components: {
    VideoShowType5: () => import('@/components/Home/showType/videoListType/videoShowType5.vue')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    id: {
      type: Number,
      default: () => {
        return 0
      }
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    handleClickTitle() {
      if (this.item.jumpType) {
        if (this.item.jumpUrl) {
          JumpTo(`${this.item.jumpUrl}?showType=${this.item.showType}}`)
        } else {
          this.$router.push({
            path: '/home/videoTopicDetail',
            query: {
              id: this.item.id,
              name: this.item.title,
              showType: this.item.showType || ''
            }
          })
        }
      } else {
        this.$emit('handleClickTitle', this.item.jumpCategoryId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  height: 0.44rem;
  font-size: 0.32rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  margin-top: 0.24rem;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  @include scrollbar-hide();
  &-item {
    flex-shrink: 0;
    margin-right: 0.2rem;
  }
}
</style>
