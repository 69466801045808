<template>
  <div class="index-more">
    <EBackTop :title="title" :fixed="true" background="transparent" color="#ffffff" />
    <div class="tag-detail-header-wrap">
      <div class="bg-wrap">
        <img class="bg" :src="bgUrl[Math.floor(Math.random() * bgUrl.length)]" />
      </div>
    </div>
    <!--切换面板 -->
    <div class="more-pack">
      <ETabBar :list="category" :tabbarConfig="tabbarConfig" @change="changeId">
        <ERefreshLoadV3
          ref="ERefreshLoadV3"
          :autoOnload="false"
          :beforeRefresh="onRefresh"
          :moreData="onLoad"
          :finished="finished"
          class="more-scroll"
        >
          <component
            :is="compComponent(showStatus)"
            :id="+$route.query.id"
            :list="mediaList"
            :typeTxt="'详情'"
            :isShortPlaylet="mediaType === 4"
          ></component>
        </ERefreshLoadV3>
      </ETabBar>
    </div>
  </div>
</template>
<script>
import scrollMixin from '@/utils/scrollMixin'
import { mediaTopic } from 'api/acg'
/* showStatus
 * {value:1, label:"长视频展示"},
 * {value:2, label:"短视频展示"},
 * { value:3, label:"全部展示"},
 * { value:4, label:"短剧"},
 * */
export default {
  name: 'VideoTopicDetail',
  components: {
    VideoList2: () => import('@/components/Home/listType/videoList/videoList2.vue'), //长视频两列
    VideoList3: () => import('@/components/Home/listType/videoList/videoList3.vue'), //长视频三列（未改）
    shortVideoList1: () => import('@/components/Home/listType/videoList/shortVideoList1.vue'), //短视频瀑布流
    shortVideoList9: () => import('@/components/Home/listType/videoList/shortVideoList9.vue'), //短视频九宫格
    RefreshLoadContainer: () => import('@/components/RefreshLoadContainer.vue')
  },
  mixins: [scrollMixin],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.getTopicList()
      vm.formData = from
      window.scrollTo(0, 0)
    })
  },
  data() {
    return {
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.32rem', //默认字体大小
        fontSizeActive: '0.32rem', //选中字体大小
        fontColor: '#999', //默认字体颜色
        fontColorActive: '#333333', //选中字体颜色
        bg: '#fff', //默认背景色
        bgActive: '#fff', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#333',
        lineWidth: '40%', //下划线宽度 String
        tabFlex: false,
        sticky: true, //粘性布局
        offsetTop: '0rem' //距顶部距离
      },
      sort: 0,
      showStatus: 3,
      mediaType: 1,
      title: '',
      pageNum: 0,
      pageSize: 10,
      formData: undefined,
      finished: false,
      mediaList: [],
      bgUrl: [
        require('@/assets/imgs/videoTopic_bg1.png'),
        require('@/assets/imgs/videoTopic_bg2.png'),
        require('@/assets/imgs/videoTopic_bg3.png'),
        require('@/assets/imgs/videoTopic_bg4.png')
      ]
    }
  },
  computed: {
    category() {
      return [
        { name: '最新', id: 0 },
        { name: '最热', id: 1 }
      ]
    },
    id() {
      return this.$route.query.id
    }
  },

  activated() {
    // this.showStatus = +this.$route.query.showStatus
    this.showStatus = +this.$route.query.showType
    if (this.formData.name !== 'PlayLongVideo') {
      this.$refs.ERefreshLoadV3.onRefresh()
    }
  },
  methods: {
    // async  getTopicList() {
    //     this.pageNum = 1
    //     const res = await mediaTopic({
    //       id: +this.$route.query.id,
    //       mediaType: this.mediaType,
    //       pageNum:1,
    //       pageSize:10,
    //       sort: this.sort //0:最新 1:最热 2:最受好评
    //     })
    //     if (res.data.mediaList && res.data.mediaList.length) {
    //     this.mediaList = []

    //     }
    //   },
    // 下啦刷新
    onRefresh() {
      this.pageNum = 0
      this.mediaList = []
    },
    async onLoad() {
      this.pageNum++
      await this.fn_media_details()
    },
    async changeId(type) {
      this.sort = type
      this.$refs.ERefreshLoadV3.onRefresh()
    },
    async fn_media_details() {
      const res = await mediaTopic({
        id: +this.$route.query.id,
        mediaType: this.mediaType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sort: this.sort //0:最新 1:最热 2:最受好评
      })
      if (res.code === 200 && res.data) {
        this.title = res.data.name
        if (res.data.mediaList && res.data.mediaList.length) {
          this.mediaList = [...this.mediaList, ...res.data.mediaList]
          if (res.data.mediaList.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      } else {
        this.finished = true
        this.$toast(res.tip)
      }
    },

    compComponent(type) {
      // 短视频主题
      let shortArr = [6, 18]
      // switch (type) {
      //   case 1: // 长视频*
      //     return 'VideoList2'
      //   case 2: // 短视频
      //   case 4: // 短剧
      //     return 'shortVideoList1'
      //   default:
      //     return 'VideoList2'
      // }
      if (shortArr.includes(type)) {
        return 'shortVideoList9'
      } else {
        return 'VideoList2'
      }
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.BackTop) {
  position: fixed;
  z-index: 1;
}
.Intersect {
  position: fixed;
  width: 24px;
  height: 32px;
  top: 300px;
  left: 0;
  z-index: 12;
}
.tag-detail-header-wrap {
  position: relative;
  height: 3.44rem;
  .bg-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      // background-color: rgba(0, 0, 0, 0.7);
      //filter: blur(1px);
    }
  }

  .tag-detail-header {
    padding: 1.3rem 0.32rem;
    position: relative;
    display: flex;
    align-items: center;
    .header-left {
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 0.13rem;
      overflow: hidden;
      flex-shrink: 0;
    }
    .header-right {
      margin-left: 0.24rem;
      .header-tag {
        color: #ffffff;
        font-size: 0.28rem;
      }
      .header-an {
        margin-top: 0.12rem;
        display: flex;
        align-items: center;
        color: #999999;
        font-size: 0.24rem;
        line-height: 0.34rem;
        > :nth-child(2) {
          margin-left: 0.16rem;
        }
      }
      .header-desc {
        margin-top: 0.12rem;
        color: #999999;
        font-size: 0.24rem;
        line-height: 0.34rem;
        @include textoverflow(1);
      }
    }
  }
}

.index-more {
  .more-scroll {
    padding: 0 0.32rem;
    border-radius: 0.48rem 0.32rem 0 0;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.05);
  }
}
.more-pack {
  top: -0.6rem;
  position: relative;
  padding: 0.32rem 0;
  background-color: #fff;
  border-radius: 0.4rem 0.4rem 0 0;
  // :deep(.pull-container) {
  //   height: calc(100vh - 3.44rem + 0.6rem);
  //   overflow-y: auto;
  // }

  ul {
    display: flex;
    align-items: center;
    position: absolute;
    right: calc(50% - 3.45rem);
    top: 0.5rem;
    z-index: 999;
    color: rgba(51, 51, 51, 0.5);
    font-size: 0.32rem;
    .active {
      color: #333333;
    }
    li {
      padding: 0 0.1rem;
    }
  }
}
</style>
