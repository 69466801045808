<template>
  <ERefreshLoadV3 ref="PhotoSearchList" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
    <div class="content">
      <div v-for="(item, index) in comicsList" :key="index">
        <comicsOneCard :isNovelItem="false" width="2.18rem" height="2.86rem" :itemData="item" />
      </div>
    </div>
  </ERefreshLoadV3>
</template>

<script>
import { search } from 'api/search'
export default {
  name: 'ComicsSearch',
  components: {
    ComicsOneCard: () => import('components/ComicsTypeCard/comicsOneCard.vue')
  },
  props: ['navItem', 'id'],
  data() {
    return {
      pageNum: 0,
      pageSize: 20,
      content: '',
      finished: false,
      comicsList: []
    }
  },

  mounted() {},

  methods: {
    setContent(val) {
      this.content = val
      console.log('漫画列表刷新')
      this.$refs['PhotoSearchList'].onRefresh()
    },
    async onLoad() {
      this.pageNum += 1
      await this.getSearch()
    },
    async onRefresh() {
      this.finished = false
      //表示处于可以下拉状态
      this.pageNum = 0
      this.comicsList = []
    },

    // 获取数据
    async getSearch() {
      if(this.content) {
        await search({
        type: this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        content: this.content
        }).then(res => {
          if (res.code === 200 && res.data.comicsList) {
            this.comicsList = [...this.comicsList, ...res.data.comicsList]
            if (res.data.comicsList.length < this.pageSize || !res.data.comicsList) {
              this.finished = true
            }
          } else {
            this.finished = true
            this.$toast(res.tip)
          }
        })
      } else {
        // return this.$toast('搜索内容不能为空')
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-flow: wrap;
  margin-top: 0.24rem;
  padding: 0 0.32rem;
  > div {
    width: 2.18rem;
    margin-bottom: 0.24rem;
    margin-right: 0.14rem;
  }

  > div:nth-child(3n) {
    margin-right: 0;
  }
}
</style>
