<template>
  <div class="title" @click="toDetail">{{ title }} <img src="~/assets/imgs/home/more.png" alt="" /></div>
</template>

<script>
export default {
  props: ['title', 'path'],
  methods: {
    toDetail() {
      this.$router.push(this.path)
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  height: 0.44rem;
  font-size: 0.32rem;
  font-weight: 500;
  @include flexbox();
  margin-bottom: 0.24rem;
}
</style>
