<template>
  <van-popover :value="true">
    <div class="img-preview">
      <EBackTop title="图片预览"> </EBackTop>
      <div class="content">
        <van-swipe :initial-swipe="initialIndex" class="my-swipe" indicator-color="white">
          <van-swipe-item v-for="(img, index) in imgList" :key="index">
            <div class="swipe-item">
              <EDecryptImg :img-c-d-n="imgCDN" :img-u-r-l="img" object-fit="contain"> </EDecryptImg>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="footer">
        <div class="footer-l">
          <template v-if="showOpr">
            <div class="item" :style="{ color: newData.base.isLike ? '#00F6F8' : '#999999' }"
              @click="addLike(newData.base, 2)">
              <van-icon style="margin-right: 0.1rem" name="like-o" />
              {{ newData.base.isLike ? '取消点赞' : '点赞' }}
            </div>
            <div class="item" :style="{ color: newData.base.isCollect ? '#00F6F8' : '#999999' }"
              @click="addLike(newData.base, 1)">
              <van-icon name="star-o" style="margin-right: 0.1rem" />
              {{ newData.base.isCollect ? '取消收藏' : '收藏' }}
            </div>
            <div class="item" :style="{ color: '#999999' }" @click="clickShare">
              <img src="@/assets/imgs/fx.svg" alt="" />
              分享
            </div>
          </template>
        </div>
        <div class="footer-r">
          <div class="footer-page">{{ value + 1 }}/{{ imgList.length }}</div>
        </div>
      </div>
    </div>
  </van-popover>
</template>
<script>
import { collect } from 'api/home'

export default {
  name: 'ImgPreview',
  props: {
    newData: {
      type: Object,
      default: () => { }
    },
    //显示操作拦
    showOpr: {
      type: Boolean,
      default: true
    },
    value: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showShare: false,
      initialIndex: 0,
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      }
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    imgList() {
      return this.newData?.node?.imgs || []
    }
  },
  mounted() {
    console.log(this.value, '0000')
    this.initialIndex = this.value
  },
  methods: {
    // 添加喜欢
    async addLike(item, type) {
      // 收藏信息
      try {
        const res = await collect({
          // flag: true,
          flag: type === 1 ? !item.isCollect : !item.isLike,
          object_id: item.id,
          collectType: 5,
          type: type
        })
        if (res.code === 200) {
          this.$emit('getNewDetail')
          this.$bus.emit('getNewCommunityList')
          this.$toast(res.tip)
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试')
      }
    },
    changeSlide(e) {
      this.$emit('input', e)
    },
    async clickShare() {
      this.showShare = true
    }
  }
}
</script>

<style scoped lang="scss">
.img-preview {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  @include flex-column-center;

  .content {
    flex-grow: 1;

    :deep(img) {
      object-fit: contain !important;
    }

    .my-swipe {
      height: 100%;
      overflow: hidden;

      .swipe-item {
        height: 100%;
        @include flex-center;
      }
    }
  }

  .photo-ads {
    height: 3rem;
  }

  .footer {
    flex-grow: 0;
    display: flex;
    justify-content: space-between;
    color: #999999;
    height: 2.5rem;
    width: 100%;
    padding: 0.73rem 0.32rem;

    .footer-l {
      display: flex;

      .item {
        display: flex;
        align-items: center;
        margin-right: 0.64rem;
        font-size: 0.24rem;
      }

      img {
        width: 0.28rem;
        height: 0.2rem;
        margin-right: 0.1rem;
      }
    }

    .footer-r {
      display: flex;
      align-items: center;

      .footer-page {
        @include flex-center;
        width: 0.7rem;
        height: 0.42rem;
        border-radius: 0.24rem;
        background: #0000000a;
        font-size: 0.24rem;
        color: #666666;
      }
    }
  }
}
</style>
