<template>
  <div class="comisc_main">
    <!-- 广告 -->

    <div v-if="adsType === 2 && iconAcgAds.length" class="acg_ads">
      <AdAvatarList :list="iconAcgAds" :wrap="true" key-name="avatar" />
    </div>
    <EADSwiper v-else-if="adsType !== 3 && lotteryTopAd.length" class="acg_swiper1" :list="lotteryTopAd"
      key-name="cover" :EADconfig="EADconfig" :img-c-d-n="imgCDN" @clickImg="clickImg">
    </EADSwiper>
    <ERefreshLoadV3 ref="ERefreshLoadV3" class="main_list" :beforeRefresh="onRefresh" :moreData="onLoad"
      :finished="finished">
      <component :is="compComponent(item.showType)" v-for="item in topicList" :id="item.id" :key="item.id"
        :title="item.name" :list="item.mediaList" class="main_mt" :isNovelItem="true">
      </component>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { JumpTo } from '@/utils/utils_tools'
import { advertiseClick } from 'api/home'
import { dream_home } from 'api/acg'
export default {
  name: 'Dream',
  components: {
    topicType19: () => import('@/components/Home/topicType/topicType19.vue'),
    topicType21: () => import('@/components/Home/topicType/topicType21.vue'),
    topicType22: () => import('@/components/Home/topicType/topicType22.vue'),
    topicType23: () => import('@/components/Home/topicType/topicType23.vue'),
    topicType24: () => import('@/components/Home/topicType/topicType24.vue'),
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue')
  },
  props: {
    id: {
      type: Number
    },
    adsType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      },
      finished: false,
      pageSize: 10,
      pageNum: 0,
      topicList: []
    }
  },
  computed: {
    lotteryTopAd() {
      return this.$store.getters['comicsTopAd']
    },
    iconAcgAds() {
      return this.$store.getters['iconAcgAds']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() { },
  methods: {
    async clickImg(item) {
      if (item.href) {
        JumpTo(item.href)
      } else {
        JumpTo(item.download_url)
      }
      const res = await advertiseClick({
        id: item.id
      })

      if (res.code !== 200) {
        return this.$toast(res.tip)
      }
    },
    compComponent(showType) {
      // 19 - 九宫格（剧场）
      // 20 - 六宫格（剧场）
      // 21 - 竖版横滑（剧场）
      // 22 - 横版横滑（剧场）
      // 23 - 横五宫格（剧场）
      // 24 - 横四宫格（剧场）
      // 25 - 横六宫格（剧场
      // 2，3，6，9，10，11 五宫格加换一换 六宫格加换一换 竖版横排 横版横排 九宫格 pk六宫格
      switch (showType) {
        case 2:
          return 'fiveListChange'
        case 3:
          return 'sixListChange'
        case 6:
          return 'sbhpList'
        case 9:
          return 'hbspList'
        case 10:
          return 'nineList'
        case 11:
          return 'pkSixList'
        case 19:
        case 20:
          return 'topicType19'
        case 21:
          return 'topicType21'
        case 22:
          return 'topicType22'
        case 23:
          return 'topicType23'
        case 24:
          return 'topicType24'
        case 25:
          return 'topicType24'
        default:
          return 'topicType19'
      }
    },
    onRefresh() {
      this.topicList = []
      this.pageNum = 0
    },
    async onLoad() {
      this.finished = true
      this.pageNum++
      await this.getComicsHome()
    },
    async getComicsHome() {
      await dream_home({
        id: this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code === 200) {
          if (res.data.topicList) this.topicList = [...this.topicList, ...res.data.topicList]
          if (res.data.topicList.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main_list {
  padding: 0.29rem 0.32rem 1.29rem 0.32rem;
}

.acg_swiper1 {
  height: 2.86rem;
}

.acg_ads {
  padding: 0.28rem 0.32rem 0;
}
</style>
