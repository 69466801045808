<template>
  <div class="kf">
    <EBackTop class="header" :title="'在线客服'" @goBack="onClickLeft" :placeholder="false" />
    <div class="iframe-box" frameborder="0">
      <iframe class="iframe" :src="url"></iframe>
    </div>
  </div>
</template>

<script>
import { kf } from 'api/home'
import { fallback } from 'components/web_base/utils_base/EUtils'

export default {
  components: {},
  data() {
    return {
      url: ''
    }
  },
  computed: {
    apiUrl({ $store }) {
      return $store.getters['apiUrl']
    }
  },
  mounted() {
    this.getKfUrl()
  },
  methods: {
    onClickLeft() {
      if (this.$route.query.isLocked) {
        this.$store.dispatch('setShowGestureLock', true)
        this.$store.dispatch('user/setHomeSiwper', true)
        this.$router.replace('/')
        return
      }
      if (this.openFallback) {
        fallback(this)
      } else {
        this.$router.go(-1)
      }
    },
    async getKfUrl() {
      const res = await kf()
      if (res.code === 200) {
        let url = res.data.endpoint.replace('kefu/customer/im?', '?')
        this.url = 'https://kefuh5.6lc53h.com/' + url
      } else {
        return this.$toast('获取在线客服地址失败，请稍后再试')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.kf {
  padding-top: 1rem;
  height: 100vh;
  font-size: 0.28rem;
  .iframe-box {
    height: 100%;
    box-sizing: border-box;
    .iframe {
      width: 100%;
      max-width: $pcMaxWidth;
      height: 100%;
    }
  }
}
</style>
