<template>
  <div class="recharge">
    <EBackTop title="金币充值" @click-right="$router.push('/Mine/recharge')">
      <slot slot="right" name="right">
        <div class="right-txt">充值记录</div>
      </slot>
    </EBackTop>
    <!-- 当前余额 -->
    <div class="gold_balance">
      <!-- <div class="balance_num_left">
        金币余额
        <span class="line"></span>
        <span class="num">{{ userInfo.balance | changeGold }}</span>
      </div>
      <div class="balance_num_right" @click="$router.push('/Mine/transList')">
        金币明细
        <van-icon name="arrow" />
      </div> -->
      <div>
        <div class="top">
          充值金币 <img src="@/assets/imgs/mine/showTips_icon.png" alt="" @click="showPopoverRecharge = true" />
        </div>
        <div class="nums">{{ userInfo.recharge | changeGold }}</div>
        <div class="bot" @click="$router.push('/Mine/transList')">金币明细 >></div>
        <van-overlay :show="showPopoverRecharge" @click="showPopoverRecharge = false">
          <img v-if="showPopoverRecharge" class="recharge_tips" src="@/assets/imgs/mine/recharge_tips_img.png" alt="" />
        </van-overlay>
      </div>
      <div class="line"></div>
      <div class="income_gold">
        <div class="top">
          收益金币 <img src="@/assets/imgs/mine/showTips_icon.png" alt="" @click="showPopoverIncome = true" />
        </div>
        <div class="nums">{{ userInfo.income | changeGold }}</div>
        <div class="bot" @click="$router.push('/Mine/withdraw')">提现</div>
        <van-overlay :show="showPopoverIncome" @click="showPopoverIncome = false">
          <img v-if="showPopoverIncome" class="income_tips" src="@/assets/imgs/mine/income_tips_img.png" alt="" />
        </van-overlay>
      </div>
    </div>
    <!-- 金币列表 -->
    <ul class="check-type">
      <li v-for="(item, index) in priceList" :key="item.id" @click="changeActive(index)">
        <div class="check_item" :class="{ active: active === index }">
          <div class="top-text">
            {{ item.goldBase }}金币
            <!-- <img  src="@/assets/imgs/mine/gold.png" alt="" /> -->
          </div>
          <p class="price">
            ¥<span>{{ item.payAmount | changeGold }}</span>
          </p>
          <p v-if="item.giftGold > 0" class="name">加赠{{ item.giftGold | changeGold }}金币</p>
        </div>
      </li>
    </ul>
    <!-- 支付小贴士 -->

    <!-- <div class="other-btn" @click="$router.push('/mine/setting/kf')">5000以上的大额充值 请点击这里</div>
    <div class="tips-list">
      <span>支付小帖士</span><br />
      1.跳转后请及时付款，超时支付无法到账，要重新发起<br />
      2.每天发起支付不能超过5次，连续发起且未支付，账号可能被加入黑名单<br />
      3.支付通道在夜间比较忙碌，为保证您的观影体验，您可以选择白天支付，晚上观看，谢谢您的理解
    </div> -->

    <!-- 支付按钮 -->
    <div class="buy-btn" @click="showPopFn">
      <span class="pay_txt">{{ activeData.payAmount | changeGold }}金币/立即支付</span>
      <p>支付问题反馈，点击联系 <span @click="$router.push('/Mine/setting/kf')">在线客服</span></p>
    </div>
    <!-- 底部支付弹窗 -->
    <van-popup v-model="showPop" position="bottom" :style="{ height: '50vh' }" class="submit-pop">
      <div class="title">请选择支付方式</div>

      <ul class="pay-list">
        <li
          v-for="(item, index) in activeData.rchgType"
          :key="index"
          class="item"
          :class="{ active: item.type === payMode }"
          @click="fn_sel(item.type)"
        >
          <div v-if="item.type === 'alipay'" class="left">
            <img src="@/assets/imgs/mine/zfb2.png" alt="" />
            {{ item.typeName }}
          </div>
          <div v-if="item.type === 'wechat'" class="left">
            <img src="@/assets/imgs/mine/weChat2.png" alt="" />
            {{ item.typeName }}
          </div>
          <div v-if="item.type === 'overage'" class="left">
            <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
          </div>
          <div v-if="item.type === 'union'" class="left">
            <img src="@/assets/imgs/mine/bank.png" alt="" />{{ item.typeName }}
          </div>
          <div v-if="item.type === 'daichong'" class="left">
            <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
          </div>
          <div class="right">
            <div v-if="item.type == payMode" class="active"></div>
          </div>
        </li>
      </ul>
      <!-- <div class="tip-wrap">如有疑问，请咨询<span @click.stop="$router.push('/mine/setting/kf')">在线客服</span></div> -->
      <div class="tip-wrap">
        <p class="title_txt">付款提示：<br /></p>
        1、因超时支付无法到账，请重新发起。<br />
        2、每天发起支付不能超过5次，连续发起且未支付，账号可能被加入黑名单。<br />
      </div>
      <!-- 开通按钮 -->
      <div class="btn" @click="fn_submit">
        <div class="txt">确认支付</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { paytypeinfo, recharge_sumbit } from 'api/home'
export default {
  data() {
    return {
      priceList: [],
      active: 0,
      activeData: {},
      showPop: false,
      payMode: '',
      showPopoverRecharge: false,
      showPopoverIncome: false
    }
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  async mounted() {
    await this.getPaytypeinfo()
    if (this.priceList && this.priceList.length > 0) {
      this.activeData = this.priceList[0]
      if (this.activeData.rchgType && this.activeData.rchgType.length !== 0) {
        this.payMode = this.activeData.rchgType[0].type
      }
    }
  },
  methods: {
    // 获取购买金币列表
    async getPaytypeinfo() {
      const res = await paytypeinfo({})
      if (res.code === 200) {
        this.priceList = res.data.payModes
      } else {
        this.$toast(res.tip)
      }
    },
    // 打开底部弹窗
    showPopFn() {
      this.showPop = true
    },
    // 切换卡片类型
    changeActive(index) {
      this.active = index
      this.activeData = this.priceList[index]
      if (this.activeData.rchgType && this.activeData.rchgType.length !== 0) {
        this.payMode = this.activeData.rchgType[0].type
      }
    },
    // 选择支付方式
    fn_sel(type) {
      this.payMode = type
    },
    // 支付金币
    async fn_submit() {
      if (!this.payMode) return this.$toast('请选择支付通道！！！')
      this.$toast.loading({
        duration: 0,
        message: '跳转中请稍后'
      })
      const res = await recharge_sumbit({
        payAmount: this.activeData.payAmount,
        payMode: this.payMode,
        productId: this.activeData.id,
        rchgUse: 2
      })
      if (res.code === 200) {
        this.showPop = false
        if (!res.data) return this.$toast('购买成功！！！')
        if (res.data && res.data.isOpenNewBrowser) {
          this.downloadFileByTagA(res.data.payUrl)
        }
        this.$toast.clear()
      } else {
        this.$toast.clear()
        this.$toast(res.tip)
      }
    },
    /* 利用a标签跳转safari浏览器机制 */
    downloadFileByTagA(fileUrl) {
      const TagA = document.createElement('a')
      TagA.href = fileUrl
      // TagA.target = '__blank';
      TagA.className = 'oInput'
      TagA.style.display = 'none'
      // 兼容ios safari浏览器
      const e = document.createEvent('MouseEvent')
      e.initEvent('click', false, false)
      TagA.dispatchEvent(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.recharge {
  min-height: 100vh;
  padding-bottom: 2rem;
}

.right-txt {
  font-size: 0.28rem;
  color: #c8c8c8;
}

// 金币余额
.gold_balance {
  // height: 1.2rem;
  border-radius: 0.12rem;
  @include flexbox($jc: space-around);
  margin: 0.48rem 0.32rem;
  padding: 0.48rem 0.32rem;
  background: url('../../../assets/imgs/mine/exchange_vip_bg.png') no-repeat;
  background-size: 100% 100%;
  text-align: center;
  .line {
    width: 0.01rem;
    height: 2rem;
    background: rgba(139, 50, 0, 0.5);
  }
  .top {
    @include flexbox($jc: flex-start, $ai: center);
    img {
      width: 0.28rem;
      height: 0.28rem;
      margin-left: 0.12rem;
    }
  }
  .nums {
    color: #8b3200;

    font-size: 0.6rem;
    font-weight: 700;
  }
  .bot {
    color: #8b3200;
  }
  .income_gold {
    .bot {
      border-radius: 0.34rem;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 2px 2px 0px rgba(139, 50, 0, 0.1);
      padding: 0.06rem 0.24rem;
      font-size: 0.26rem;
    }
  }
  .recharge_tips {
    width: 3.2rem;
    top: 1.5rem;
    position: relative;
    left: 0rem;
  }
  .income_tips {
    width: 3.2rem;
    top: 1.3rem;
    position: relative;
    right: -1rem;
  }
}

.tips-box {
  margin: 0.4rem 0 0.46rem 0.37rem;

  span {
    &:first-child {
      border-radius: 0.08rem;
      background: rgba(190, 145, 85, 0.2);
      color: #be9155;
      font-family: PingFang HK;
      font-size: 0.26rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right: 0.23rem;
      padding: 0.04rem 0.08rem;
    }
  }
}

// 金币列表

.check-type {
  @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: wrap);
  padding: 0 0.36rem;

  li {
    width: 30%;
    margin-right: 5%;
    margin-bottom: 0.24rem;

    .check_item {
      @include flexbox($jc: center, $ai: center, $fd: column);
      border-radius: 0.24rem;
      height: 2.55rem;
      border-radius: 0.16rem;
      background: var(--4, rgba(0, 0, 0, 0.04));
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    .top-text {
      font-size: 0.32rem;
    }

    .name {
      color: $grey;
      margin-top: 0.16rem;
      // text-decoration: line-through;
    }

    .price {
      margin-top: 0.16rem;

      span {
        font-size: 0.56rem;
        font-weight: 600;
      }
    }

    .active {
      border-radius: 0.16rem;
      background: linear-gradient(148deg, #fae3d0 0%, #eec7b2 101.54%, #efcab5 101.55%);

      .top-text {
        color: #241110;
      }

      .name {
        color: #822f22;
      }

      .price {
        color: $txtActive;
      }
    }
  }
}

.other-btn {
  border-radius: 0.39rem;
  background: $btnBg;
  color: #333;
  width: 4.6rem;
  height: 0.7rem;
  margin: 0.5rem auto 0.62rem auto;
  line-height: 0.7rem;
  text-align: center;
  font-size: 0.28rem;
}

.tips-list {
  margin-left: 0.4rem;
  line-height: 0.4rem;

  span {
    border-radius: 0.08rem;
    background: rgba(190, 145, 85, 0.2);
    color: #be9155;
    font-size: 0.26rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.04rem 0.08rem;
    margin-bottom: 0.2rem;
    display: inline-block;
  }
}

.buy-btn {
  @include flexbox($jc: center, $fd: column);
  max-width: $pcMaxWidth;
  width: 100%;
  background: var(--, #fff);
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.05);
  position: fixed;
  bottom: 0;
  left: 50%;
  height: 1rem;
  transform: translate(-50%, 0);
  padding: 0.34rem 0.5rem;
  box-sizing: content-box;

  .pay_txt {
    border-radius: 0.46rem;
    background: $gradientBg;
    width: 6.5rem;
    height: 0.9rem;
    flex-shrink: 0;
    text-align: center;
    line-height: 0.9rem;
    color: #fff;
    font-size: 0.36rem;
  }

  p {
    color: #999;

    font-size: 0.24rem;
    margin-top: 0.12rem;
    span {
      color: #e8619d;
    }
  }
}

.submit-pop {
  border-radius: 0.48rem 0.48rem 0rem 0rem;
  background: #fff;
  height: 6rem;
  overflow: auto;

  .title {
    text-align: center;
    margin: 0.36rem 0;
    font-size: 0.36rem;
    padding-bottom: 0.4rem;
    border-bottom: 0.01rem solid #333;
  }
  .pay-list {
    .item {
      @include flexbox();
      padding: 0 0.46rem;
      margin-top: 0.36rem;

      img {
        width: 0.54rem;
        height: 0.54rem;
        margin-right: 0.16rem;
      }

      .right {
        width: 0.28rem;
        height: 0.28rem;
        border-radius: 50%;
        border: 0.02rem solid #ffb267;
        position: relative;

        .active {
          width: 0.2rem;
          height: 0.2rem;
          background: #ffb267;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .btn {
    z-index: 2;
    position: fixed;
    bottom: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.6rem;
    background: $gradientBg;
    width: 6.5rem;
    height: 0.9rem;
    flex-shrink: 0;
    color: #fff;

    .txt {
      font-size: 0.3rem;
      line-height: 0.9rem;
      text-align: center;
    }
  }

  .tip-wrap {
    font-size: 0.24rem;
    margin-top: 0.5rem;
    padding: 0 0.4rem;
    opacity: 0.7;

    .title_txt {
      opacity: 1;
      font-weight: 600;
      font-size: 0.28rem;
      margin-bottom: 0.24rem;
    }
  }
}
</style>
