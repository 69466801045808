<template>
  <div class="facephoto-container">
    <EBackTop :background="'#fff'" class="mine-head" color="#333" title="AI脱衣">
      <template #right>
        <van-icon name="wap-nav" color="#333" size="25" @click="isShowPop = !isShowPop" />
      </template>
    </EBackTop>
    <div class="facephoto-content">
      <div class="insert-photo-wrap">
        <div v-if="uploadStatus === 0" class="insert-photo-wrap-start">
          <img src="@/assets/imgs/aiLab/section3/svg1.svg" alt="" />
          <van-uploader ref="Uploader" :show-upload="false" :after-read="onReadImg" multiple accept="image/*">
            请选择您要脱衣的素材
          </van-uploader>
          <div>
            <img src="@/assets/imgs/aiLab/section3/svg2.svg" alt="" />
            <span>禁止使用未成年人图片 (后台检测封号)</span>
          </div>
        </div>
        <div v-if="uploadStatus === 1" class="insert-photo-wrap-wait">
          <img :src="upLoadImgs[0].content" alt="" />
          <div>
            <div>
              <img src="@/assets/imgs/coin2.png" alt="" />
              <span>扣除金币</span>
            </div>
            <span>-{{ stripItem.price / 100 }}</span>
          </div>
          <div>
            <div @click="checkBalance">提交</div>
            <div @click="clearChoose">重新选择</div>
          </div>
        </div>
        <div v-else-if="uploadStatus === 2" class="insert-photo-wrap-loading">
          <img src="@/assets/imgs/aiLab/section3/img1.png" alt="" class="loading-img" />
        </div>
        <div v-else-if="uploadStatus === 3" class="insert-photo-wrap-finished">
          <div>上传成功</div>
          <div>请前往<span @click="toGenerate">我的AI生成</span>查看结果</div>
        </div>
      </div>
      <div class="matieral-content">
        <div class="up-material">
          <div>效果展示</div>
          <div @click="showModal = !showModal">
            <img src="@/assets/imgs/aiLab/section3/svg3.svg" alt="" />
            <span>素材示范</span>
          </div>
        </div>
        <div class="insert-photo" @click="fn_play_video">
          <!-- <img src="@/assets/imgs/aiLab/section3/img2.png" alt="" /> -->
          <!-- <DecryptImgLoad :imgURL="stripItem.picture" :item="stripItem" class="img-box" /> -->
          <EDecryptImg ref="refImg" :imgCDN="imgCDN" class="resetImg" :imgURL="stripItem.picture">
            <img class="play_icon" src="@/assets/imgs/aiLab/play.png" alt="" />
          </EDecryptImg>
        </div>
        <!-- <div class="tips">
          <div>注意事项</div>
          <div>
            <div>
              1.选择一张人脸清晰，
              <span>不得有任何遮挡的照片</span>
              上传（注意：只含有一个人物和脸部，图片不能过暗）
            </div>
            <div>2.选择一个心仪的视频/图片模版，点击生成，生成时间需要x-x分钟，耐心等待。（图片模版可自行上传）</div>
            <div>3.在xxxx记录查看生成进度，生成成功后可以点击进行下载，也可以在线观看</div>
            <div><span> 4.不支持多人图片，禁止未成年人图片</span></div>
          </div>
        </div> -->
        <div class="tips">
          <div>注意事项</div>
          <div>
            <div>1.照片尽量提供人物正面脸部照片</div>
            <div>2.照片清晰度及光度不能太低</div>
            <div>3.不支持多人照片</div>
            <div>4.<span> 禁止未成年人图片</span></div>
          </div>
        </div>
      </div>
      <!-- <span class="choose-modal" @click="showModal = !showModal">选择模版</span> -->
    </div>

    <van-popup v-model="showModal" position="bottom" class="error-tips-pop2">
      <div class="up-material">
        <div>素材规范</div>
        <div>
          <img src="@/assets/imgs/aiLab/error_tips.svg" alt="" />
          <span style="color: #fff">
            上传素材需要为正脸,素材清晰, <span style="color: red">严禁使用未成年素材</span>
          </span>
        </div>
        <ul class="errorTipsList">
          <li v-for="(item, index) in errorTipsList" :key="index">
            <img :src="item.img" alt="" />
            <img :src="item.icon" alt="" />
            <div>{{ item.text }}</div>
            <div v-if="index === 0" style="color: #e8619d">(比基尼最佳)</div>
          </li>
        </ul>
      </div>
    </van-popup>
    <ComPlayVideo ref="ComPlayVideo" />
    <DmPopup ref="popupMsg"></DmPopup>
    <ChangeFace :isShow="isShowPop" />
  </div>
</template>

<script>
import { imgsCompress } from '@/utils/utils_tools'
import { uploadImgs } from 'api/home'
import { aiorder_strip_submit, aitemplate_list } from '@/api/ai'

export default {
  name: 'UploadFace',
  components: {
    UploadImgSpecial: () => import('../UploadImgSpecial/index.vue'),
    ComPlayVideo: () => import('@/views/ai/components/video-m3u8.vue'),
    DmPopup: () => import('@/components/Popup/index.vue'),
    ChangeFace: () => import('../components/leftPopup.vue')
  },
  data() {
    return {
      isShowPop: false,
      uploadStatus: 0, //0 未选择 1 已选择待提交 2 提交中 3 已提交
      upLoadImgs: [], // 已上传图片
      maxLength: 1,
      loading: false,
      refreshing: false,
      listData: [],
      finished: false, //
      activeIndex: 1,
      showModal: false,
      errorTipsList: [
        {
          img: require('@/assets/imgs/aiLab/section3/error1.jpg'),
          icon: require('@/assets/imgs/aiLab/corret_icon.svg'),
          text: '正朝向,素材清晰'
        },
        {
          img: require('@/assets/imgs/aiLab/section3/error2.jpg'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '包裹的过于严实'
        },
        {
          img: require('@/assets/imgs/aiLab/section3/error3.jpg'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '像素太过模糊'
        },
        {
          img: require('@/assets/imgs/aiLab/section3/error4.jpg'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '多人'
        }
      ],
      stripItem: {},
      payType: 0
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  created() {
    this.get_ai_template()
  },
  methods: {
    checkBalance() {
      if (this.userInfo.balance < this.stripItem.price && this.userInfo.aiFreeRemoveClothCount <= 0) {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '注意',
          content: '对不起,您的余额不足',
          type: 'change-face-post',
          ok: '前往充值',
          // cancel: '做任务得VIP',
          position: 'center',
          showBalance: true,
          balance: this.userInfo.balance,
          price: this.stripItem.price,
          cb: async returnData => {
            this.$router.push('/mine/myWallet')
          }
        }
      } else {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '确认支付',
          showBalance: true,
          type: 'change-face-enough',
          ok: '使用金币',
          price: this.stripItem.price,
          cancel: `使用免费脱衣次数:${this.userInfo.aiFreeRemoveClothCount}`,
          position: 'center',
          balance: this.userInfo.balance,
          cb: async returnData => {
            // this.shoudBuy(item)
            this.payType = 0
            this.toSubmit(0)
          },
          oncancel: async returnData => {
            if (this.userInfo.aiFreeRemoveClothCount) {
              this.payType = 1
              this.toSubmit(1)
            } else {
              this.$router.push({
                path: '/Mine/myShouyi',
                query: {
                  type: 1
                }
              })
            }
          }
        }
      }
    },
    fn_play_video() {
      this.$refs.ComPlayVideo.fn_playVideo(this.stripItem.videoUrl, this.stripItem.videoCover)
    },
    async get_ai_template() {
      const res = await aitemplate_list({
        atType: 2,
        contentType: 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sort: 0
      })
      this.stripItem = res.data.list[0]
    },
    clearChoose() {
      this.upLoadImgs = []
      this.uploadStatus = 0
    },
    //选择图片
    onReadImg(file) {
      file = Array.isArray(file) ? file : [file]
      console.log(file)
      if (this.upLoadImgs && file.length + this.upLoadImgs.length > this.maxLength) {
        return this.$toast('最多传1张')
      }
      this.upLoadImgs.push(...file)
      this.uploadStatus = 1
      // this.uploadImg()
    },
    toSubmit() {
      //付款
      this.uploadImg()
    },
    // 上传
    async uploadImg(file) {
      this.uploadStatus = 2
      file = this.upLoadImgs
      // 组合
      const formData = new FormData()
      let newBlobList = []
      if (!file.length) {
        newBlobList = await imgsCompress([file])
      } else {
        newBlobList = await imgsCompress(file)
      }
      for (let index = 0; index < newBlobList.length; index++) {
        formData.append('upload[]', newBlobList[index])
      }

      // 上传
      this.$toast.loading({ duration: 0, message: '图片上传中请稍后' })
      const res = await uploadImgs(formData)
      if (res.code === 200) {
        this.upLoadImg = res.data.paths
        this.realUploadStrip()
      } else {
        this.uploadStatus = 0
        this.$toast(res.tip || res.msg)
      }
    },
    async realUploadStrip() {
      try {
        const item = {
          aoContentType: 1,
          sourceImgs: this.upLoadImg,
          tplId: this.stripItem.id,
          payType: this.payType
        }
        const res = await aiorder_strip_submit(item)
        if (res.code == 200) {
          this.$toast.clear()
          this.$toast('上传成功')
          this.uploadStatus = 3
          this.$store.dispatch('get_userInfo')
        } else {
          if (res.code === 3009) {
            this.$toast('余额不足！')
          } else {
            this.$toast(res.data.msg)
          }
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    toGenerate() {
      this.$router.push('/ai/myGenerate')
    },
    handleClick(index) {
      this.activeIndex = index
    },
    resultCover(imgs) {
      this.cover = imgs
    }
  }
}
</script>

<style lang="scss" scoped>
.facephoto-container {
  //padding: 0 0.36rem;
  background: #fff;

  .facephoto-content {
    padding-top: 0.4rem;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    // position: relative;
    .insert-photo-wrap {
      height: 5.4rem;
      background: rgba(0, 0, 0, 0.04);
      margin: 0 0.36rem;
      border-radius: 0.12rem;
      border: 0.5px dashed #535353;

      background: rgba(0, 0, 0, 0.04);

      &-start {
        display: flex;
        align-items: center;
        flex-direction: column;

        > :nth-child(1) {
          margin-top: 1.24rem;
          width: 0.8rem;
          height: 0.8rem;
        }

        > :nth-child(2) {
          margin-top: 0.36rem;
          border-radius: 0.06rem;
          background: $gradientBg;
          color: #fff;
          font-size: 0.3rem;
          letter-spacing: 0.03rem;
          width: 3.88rem;
          height: 0.68rem;
          @include flex-center;
        }

        > :nth-child(3) {
          margin-top: 0.57rem;
          @include flex-center;

          > :nth-child(1) {
            width: 0.18rem;
            height: 0.22rem;
          }

          > :nth-child(2) {
            margin-left: 0.12rem;
            color: #ff2323;
            font-size: 0.22rem;
          }
        }
      }

      &-wait {
        height: 100%;
        @include flex-center;
        flex-direction: column;

        > :nth-child(1) {
          width: 3.6rem;
          height: 2.6rem;
          object-fit: contain;
        }

        > :nth-child(2) {
          margin-top: 0.4rem;
          width: 2.6rem;
          @include flex-center;
          justify-content: flex-start;

          > div {
            width: 1.7rem;
            height: 0.48rem;
            // display: flex;
            border-radius: 0.35rem;
            border: 0.01rem dashed #535353;
            background: #333;
            color: #ffcf86;
            font-size: 0.26rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 0.24rem;
              margin-right: 0.1rem;
            }
          }

          > span {
            color: #ffcf86;
            font-size: 0.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 0.27rem;
          }
        }

        > :nth-child(3) {
          margin-top: 0.37rem;
          width: 5.34rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > div {
            flex: 1;
            @include flex-center;
            border-radius: 0.12rem;

            &:nth-child(1) {
              background: #d73c44;
              height: 0.77rem;
              color: #fff;
              margin-right: 0.54rem;
            }

            &:nth-child(2) {
              background: #555;
              height: 0.77rem;
              color: #fff;
            }
          }
        }
      }

      &-loading {
        .loading-img {
          width: 100%;
          height: 100%;
        }
      }

      &-finished {
        height: 100%;
        @include flex-center;
        flex-direction: column;

        > :nth-child(1) {
          @include flex-center;
          width: 3.04rem;
          height: 0.84rem;
          border-radius: 0.12rem;
          border: 0.01rem solid #d73c44;
          background: rgba(215, 60, 68, 0.1);
        }

        > :nth-child(2) {
          line-height: 0.28rem;
          margin-top: 0.48rem;
          font-size: 0.28rem;

          > span {
            padding: 0 0.16rem;
            color: #e8619d;
          }
        }
      }
    }
  }

  .up-material {
    @include flexbox;

    > div {
      &:nth-child(1) {
        color: #333;
        font-size: 0.34rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include flex-align-center;

        &::before {
          content: '';
          width: 0.12rem;
          height: 0.25rem;
          background-color: #d73c44;
          display: block;
          border-radius: 0.12rem;
          margin-right: 0.16rem;
        }
      }

      &:nth-child(2) {
        @include flex-center;
        justify-content: flex-start;

        > :nth-child(1) {
          width: 0.28rem;
          height: 0.18rem;
        }

        > :nth-child(2) {
          margin-left: 0.14rem;
          color: #e8619d;
          font-size: 0.24rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  .matieral-content {
    padding: 0.6rem 0.36rem;

    .insert-photo {
      margin-top: 0.24rem;

      > :nth-child(1) {
        width: 3.2rem;
        height: 2rem;
      }

      .play_icon {
        width: 0.56rem;
        height: 0.56rem;
        position: absolute;
        left: 50%;
        top: 50%;
        @include transformCenter;
      }
    }

    .cover_load {
      width: 3rem;
      height: 3rem;
      border-radius: 0.12rem;
      border: 1px dashed #535353;
      background: #111;

      :deep() .img-item {
        height: 1.6rem !important;
        width: 1.6rem !important;
        margin-right: 0.32rem !important;
        margin-top: 0 !important;
        border-radius: 0.08rem !important;

        // margin-right: 0.32rem;
        img {
          border-radius: 0.08rem !important;
        }
      }

      .cover_bg {
        // margin-bottom: 0.2rem;
        // margin-left: 0.3rem;
        .video_title {
          margin-bottom: 0.38rem;
        }
      }
    }

    .button-group {
      > div {
        padding: 0.24rem 0.64rem;
        text-align: center;

        &:nth-child(1) {
          border-radius: 0.06rem;
          border: 1px solid #535353;
          background: #333;
          color: #fff;
          font-family: PingFang SC;
          font-size: 0.3rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0.24rem;
        }

        &:nth-child(2) {
          border-radius: 0.06rem;
          background: #d73c44;
          color: #fff;
          font-family: PingFang SC;
          font-size: 0.3rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.03rem;
        }
      }
    }

    .tips {
      margin-top: 0.7rem;
      padding-bottom: 0.7rem;

      > div {
        &:nth-child(1) {
          color: #333;
          font-family: PingFang SC;
          font-size: 0.34rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          @include flex-align-center;

          &::before {
            content: '';
            width: 0.12rem;
            height: 0.25rem;
            background-color: #d73c44;
            display: block;
            border-radius: 0.12rem;
            margin-right: 0.16rem;
          }
        }

        &:nth-child(2) {
          color: #333;
          font-family: PingFang SC;
          font-size: 0.26rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 0.31rem;

          span {
            color: #ff0f00;
          }
        }
      }
    }
  }

  // .choose-modal {
  //   position: absolute;
  //   right: 0.36rem;
  //   top: 0.45rem;
  //   border-radius: 0.39rem;
  //   background: rgba(0, 0, 0, 0.5);
  //   color: #fff;
  //   font-family: PingFang SC;
  //   font-size: 0.3rem;
  //   font-style: normal;
  //   font-weight: 400;
  //   line-height: normal;
  //   width: 1.84rem;
  //   height: 0.74rem;
  //   @include flex-center;
  // }
  .van-popup--bottom {
    border-radius: 0.42rem 0.42rem 0rem 0rem;
    border: 1px solid #535353;
    background: #292929;
  }
}

.error-tips-pop2 {
  padding: 0.6rem 0.36rem;

  .up-material {
    display: block;

    > div {
      &:nth-child(2) {
        margin: 0.34rem 0 0.47rem;
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;

        > img {
          width: 0.28rem;
          margin-right: 0.09rem;
        }
      }
    }
  }

  .errorTipsList {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.15rem;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1.8rem;
      margin-right: 0.51rem;
      text-align: center;
      color: #fff;
      text-align: center;
      font-size: 0.24rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0.34rem;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    img {
      &:nth-of-type(1) {
        width: 1.8rem;
        height: 2.2rem;
        border-radius: 0.12rem;
      }

      &:nth-of-type(2) {
        width: 0.32rem;
        height: 0.32rem;
        margin: 0.15rem 0 0.06rem;
      }
    }
  }
}
</style>
