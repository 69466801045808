<template>
  <div v-if="itemList && itemList.length" class="nine-list-main">
    <HomeTitle :title="name" :path="`/acg/acgTopicDetail/?title=${name}&type=cartoon&id=${id}`" />
    <ul class="nine-list">
      <li v-for="item in itemList" :key="item.id">
        <cartoonOneCard width="2.18rem" height="2.86rem" :itemData="item" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {
    cartoonOneCard: () => import('./cartoonOneCard.vue'),
    HomeTitle: () => import('@/components/homeTitle.vue')
  },
  props: ['itemList', 'name', 'id']
}
</script>

<style lang="scss" scoped>
.nine-list-main {
  margin-bottom: 0.36rem;
}
.nine-list {
  @include flexbox($jc: flex-start, $fw: wrap);
  li {
    margin-right: 0.16rem;
    flex: none;
    width: 2.18rem;
    margin-bottom: 0.16rem;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
