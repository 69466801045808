<template>
  <van-popup v-model="isShow" @close="close">
    <div class="share-pop">
      <!-- <img src="@/assets/imgs/subpage/share-bg.png" alt="" /> -->
      <div class="avatar">
        <EDecryptImg :imgCDN="imgCDN" class="avatar-img" :imgURL="userInfo.avatarUrl" />
        <div class="right-intro">
          <div>Mr.91撸色</div>
          <div>你的色情顾问</div>
        </div>
      </div>
      <div class="share-bg">
        <img src="@/assets/imgs/share/share_bg.png" alt="" />
      </div>
      <div>
        <div class="left" ref="sharePopup">
          <EGenerateImg ref="EGenerateImg" :link="qrCode" :config="config"></EGenerateImg>
        </div>
        <!-- <div class="right">
          <div @click="copyLink">复制链接</div>
          <div @click="savePicture">保存图片</div>
        </div> -->
        <div class="right-text">
          <div>@{{ '用户昵称' }}</div>
          <div>
            <span>每邀请</span>
            <span>1人</span>
            <span>送</span>
            <span>1天</span>
            <span>会员</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div @click="copyLink">复制链接</div>
        <div @click="savePicture">保存图片</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import html2canvas from 'html2canvas'
export default {
  data() {
    return {
      isShow: false,
      qrCode: '',
      config: {
        qrBgwidth: '1.9rem'
      }
    }
  },
  props: {
    sharePopShow: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  methods: {
    close() {
      this.$emit('close', false)
    },
    copyLink() {
      this.$copyText(this.qrCode).then(() => {
        this.$toast('复制剪贴板成功')
      })
    },
    savePicture() {
      html2canvas(this.$refs.sharePopup, {
        dpi: 300,
        scale: 2,
        useCORS: true // 解决文件跨域问题
      }).then(canvas => {
        canvas.toBlob(
          blob => {
            const url = URL.createObjectURL(blob)
            // 可以上传后端或者直接显示
            this.downloadIamge(url, '邀请二维码.png')
          },
          'image/jpeg',
          0
        )
      })
    },
    downloadIamge(href, name) {
      const eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      document.body.appendChild(eleLink)
      eleLink.click()
      document.body.removeChild(eleLink)
      this.$toast('如果无法下载图片，请截图保存')
    }
  },
  watch: {
    sharePopShow(n, o) {
      this.isShow = n
    }
  },
  computed: {
    // 获取渠道参数
    channel({ $store }) {
      return $store.getters['getChannel']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo() {
      return this.$store.getters['getUserInfo']
    }
  },
  created() {
    const channel = this.channel
    this.qrCode = window.location.href
    if (channel.hasOwnProperty('dc') || channel.hasOwnProperty('pc')) {
      this.qrCode.concat(`?pc=${channel.dc}`)
    } else if (channel.hasOwnProperty('pc')) {
      this.qrCode.concat(`?pc=${channel.pc}`)
    }
  }
}
</script>

<style lang="scss">
.van-popup--center {
  background-color: transparent;
}
.share-pop {
  width: 6rem;
  border-radius: 0.2rem;
  background: #fff;
  padding: 0.16rem 0.42rem 0.5rem 0.46rem;
  > img {
    width: 100%;
  }
  > div {
    margin-top: 0.32rem;
    display: flex;
  }
  .left {
    width: 1.9rem;
    height: 1.9rem;
    background-color: #fff;
    border-radius: 0.08rem;
    margin-right: 0.48rem;
    position: relative;
    .template {
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      width: 100%;
      height: 100%;
      .qrBg {
        left: 0;
        top: 0;
      }
    }
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .right {
    display: flex;
    justify-content: space-between;
    margin-top: 0.58rem;
    > div {
      flex: 1;
      border-radius: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 0.76rem;
    }
    > div:nth-child(1) {
      background: #f6f6f6;
      color: #333;
      font-size: 0.28rem;
    }
    > div:nth-child(2) {
      background: $gradientBg;
      color: #fff;
      font-size: 0.28rem;
      font-weight: 500;
    }
  }
  .avatar-img {
    width: 1rem;
    height: 1rem;
    border-radius: 0.24rem;
    margin-right: 0.24rem;
    img {
      border-radius: 0.24rem;
    }
  }
  .right-intro {
    padding-top: 0.12rem;
    color: #333;
    font-size: 0.24rem;
    > div:last-child {
      color: #999;
      margin-top: 0.06rem;
    }
  }
  .share-bg {
    width: 5.12rem;
    height: 3rem;
    padding: 0;
    margin: 0.3rem auto 0;
    img {
      width: 5.12rem;
      height: 100%;
    }
  }
  .right-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > div {
      &:first-child {
        color: #333;
        font-size: 0.32rem;
        font-weight: 500;
      }
      &:last-child {
        font-size: 0.24rem;
        font-weight: 400;
        opacity: 0.9;
        margin-top: 0.16rem;
        > span:nth-child(even) {
          color: #e8619d;
        }
      }
    }
  }
}
.ETabBar .van-tabs .van-sticky {
  z-index: 1 !important;
}
</style>

