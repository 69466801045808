<template>
  <div class="recommend-card">
    <div class="recommend-card-header">{{ data.name }}</div>
    <div class="recommend-card-content">
      <recommend-card-item
        v-for="item in data.discussList"
        :key="item.id"
        :data="item"
        class="recommend-card-content-item"
        @click.native="toTopicDetail(item)"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'RecommendCard',
  components: {
    RecommendCardItem: () => import('@/components/Community/communityTopic/recommendCard/RecommendCardItem.vue')
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  methods: {
    toSelect(item) {
      this.$emit('input', item.id)
    },
    toTopicDetail(item) {
      this.$router.push(
        `/community/topicDetail/${item.id}?title=${item.title}&hotValue=${item.hotValue}&cover=${item.cover}&publishCount=${item.publishCount}`
      )
    }
  }
}
</script>

<style scoped lang="scss">
.recommend-card {
  .recommend-card-header {
    font-size: 0.3rem;
    line-height: 0.26rem;
    color: #333333;
  }
  .recommend-card-content {
    margin-top: 0.14rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .recommend-card-content-item {
      width: 2.58rem;
      margin-bottom: 0.24rem;
    }
  }
}
</style>
