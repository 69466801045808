<template>
  <ERefreshLoadV3 ref="PostSearchList" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
    <div class="content">
      <PostShowType1 v-for="(item, index) in postList" :key="index" :data="item" class="post-item">
        <template #top="{ data }">
          <TopAvatar :data="data" class="top-avatar"></TopAvatar>
        </template>
      </PostShowType1>
    </div>
  </ERefreshLoadV3>
</template>

<script>
import { search } from 'api/search'
export default {
  name: 'PostSearch',
  components: {
    PostShowType1: () => import('@/components/Home/showType/postListType/postShowType1.vue'),
    TopAvatar: () => import('@/components/Community/topAvatar.vue')
  },
  props: ['navItem', 'id'],
  data() {
    return {
      pageNum: 0,
      pageSize: 10,
      content: '',
      finished: false,
      postList: []
    }
  },

  mounted() {},

  methods: {
    //设置搜索参数
    setContent(val) {
      this.content = val
      console.log('帖子列表刷新')
      this.$refs['PostSearchList'].onRefresh()
    },

    async onLoad() {
      this.pageNum += 1
      await this.getSeach()
    },

    async onRefresh() {
      this.finished = false
      //表示处于可以下拉状态
      this.pageNum = 0
      this.postList = []
    },

    // 获取数据
    async getSeach() {
      await search({
        type: this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        content: this.content
      }).then(res => {
        if (res.code === 200 && res.data.postList) {
          this.postList = [...this.postList, ...res.data.postList]
          if (res.data.postList.length < this.pageSize || !res.data.postList) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-flow: wrap;
  margin-top: 0.24rem;
  padding: 0 0.32rem;
}
</style>
