<template>
  <div class="wrapper">
    <div
      v-for="item in data"
      :key="item.id"
      class="item"
      :class="{ active: item.id === value }"
      @click="toSelect(item)"
    >
      {{ item.title }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'TagSelect',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    value: {
      type: Number
    }
  },
  data() {
    return {}
  },
  methods: {
    toSelect(item) {
      this.$emit('input', item.id)
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  @include flex-align-center;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 0.4rem;
  .item {
    flex-shrink: 0;
    flex-grow: 0;
    width: 1.16rem;
    height: 0.52rem;
    border-radius: 0.28rem;
    @include flex-center;
    color: #999999;
    transition: all 0.2s ease-in-out;
    &.active {
      color: #ffffff;
      background-color: #e8619d;
    }
  }
}
</style>
