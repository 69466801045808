<template>
  <div class="record-container">
    <EBackTop title="充值记录"></EBackTop>
    <ERefreshLoadV3
      ref="ERefreshLoadV3"
      class="main_list"
      :beforeRefresh="onRefresh"
      :moreData="onLoad"
      :finished="finished"
    >
      <ul class="record-listWrap">
        <li v-for="(item, index) in transList" :key="index" class="item">
          <p class="item_type">{{ item.currencyType === 1 ? '金币' : '充值购买' }}</p>
          <p class="item_num">账单编号：{{ item.orderNo }}</p>
          <p class="item_status">状态：{{ item.status == -1 ? '失败' : item.status == 0 ? '进行中' : '成功' }}</p>
          <p class="item_time">{{ item.createdAt | dateFormat08 }}</p>
        </li>
      </ul>
    </ERefreshLoadV3>
  </div>
</template>
<script>
import { recharge_list } from 'api/user'
export default {
  components: {},
  data() {
    return {
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 关注列表
      transList: [],
      // 请求类型
      getType: '',
      // 当前页
      pageNum: 0,
      // 当前个数
      pageSize: 10
    }
  },
  mounted() {},
  methods: {
    // 获取流水记录
    async get_transList() {
      try {
        const res = await recharge_list({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.transList = [...this.transList, ...res.data.list]
          if (res.data && res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
          return this.$toast(res.tip)
        }
      } catch (error) {
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 下拉加载
    async onLoad() {
      this.pageNum++
      await this.get_transList()
    },
    // 上拉刷新
    onRefresh() {
      this.pageNum = 0
      this.transList = []
    },
    onCopy() {
      this.$toast('复制成功！！！')
    },
    onError() {
      this.$toast('复制失败！！！')
    }
  }
}
</script>
<style lang="scss" scoped>
.record-container {
  .record-listWrap {
    margin: 0.3rem 0;
    padding: 0 0.34rem;

    .item {
      padding: 0.38rem 0 0.24rem 0.32rem;
      border-bottom: 0.01rem solid #f0f0f0;
      font-size: 0.3rem;

      .item_type {
        font-size: 0.36rem;
        font-weight: 600;
      }
      .item_num {
        font-weight: 500;
        margin-top: 0.12rem;
      }
      .item_status {
        margin: 0.12rem 0;
        opacity: 0.6;
      }
      .item_time {
        font-size: 0.24rem;
        opacity: 0.6;
      }
    }
  }
}
</style>
