<template>
  <div class="community-topic-wrap">
    <EBackTop :title="$route.meta.title"> </EBackTop>
    <!-- 搜索 -->
    <div class="search-clickbox-box" @keyup.enter="fn_search">
      <van-field
        v-model="searchTxt"
        :clearable="true"
        class="search-clickbox"
        type="text"
        placeholder="请输入您想搜索的内容"
        @blur="fn_search"
        @clear="clear_text"
      />
      <img src="@/assets/imgs/community/Microphone1.png" alt="" />
    </div>
    <div class="community-topic">
      <tag-select-ver v-model="sort" :data="categoryList" class="community-topic-tabs" @input="sortSwitch" />
      <ERefreshLoadV3 :finished="finished" :beforeRefresh="refreshData" :moreData="moreData" style="width: 100%">
        <div class="community-topic-content">
          <recommend-card
            v-for="item in recommendList"
            :key="item.id"
            :data="item"
            class="community-topic-content-card"
          />
        </div>
      </ERefreshLoadV3>
    </div>
  </div>
</template>
<script>
import { discuss_category, select_discuss } from 'api/community'

export default {
  name: 'CommunityTopic',

  components: {
    TagSelectVer: () => import('@/components/Community/communityTopic/TagSelectVer.vue'),
    RecommendCard: () => import('@/components/Community/communityTopic/recommendCard/RecommendCard.vue')
  },
  props: {
    navItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      sort: 0,
      categoryList: [],
      recommendList: [],
      pageNum: 1,
      pageSize: 10,
      finished: false,
      //话题精选
      topicList: [],
      //搜索内容
      searchTxt: ''
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {},
  methods: {
    //清除搜索内容
    clear_text() {
      // this.selectCategoryList()
    },
    //搜索
    fn_search() {
      this.selectCategoryList()
    },
    selectCategoryList() {
      select_discuss({
        category: this.sort,
        content: this.searchTxt,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code === 200) {
          if (this.searchTxt) {
            this.recommendList = res.data.categoryList
            return
          }
          this.recommendList = [...this.recommendList, ...res.data.categoryList]
          if (res.data.categoryList.length < this.pageSize || !res.data.categoryList) {
            this.finished = true
          }
        }
      })
    },
    async getList() {
      discuss_category().then(res => {
        if (res.code === 200) {
          this.categoryList = res.data.categoryList
          this.sort = this.categoryList[0].id
          this.selectCategoryList()
        }
      })
    },
    sortSwitch() {
      this.pageNum = 1
      this.recommendList = []
      this.selectCategoryList()
    },
    //上拉刷新
    refreshData() {
      this.finished = false
      this.pageNum = 0
      this.recommendList = []
    },
    //下拉加载
    async moreData() {
      this.pageNum += 1
      if (this.categoryList.length) {
        await this.selectCategoryList()
      } else {
        this.getList()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.community-topic-wrap {
  .search-clickbox-box {
    position: relative;
    width: 100%;
    max-width: $pcMaxWidth;
    z-index: 2;
    background-color: #fff;
    padding: 0.2rem 0;

    // border-bottom: 1px solid #eee;
    .search-clickbox {
      margin: 0 auto;
      width: 92%;
      height: 0.64rem;
      border-radius: 25px;
      background: #f8f8f8;
      color: #818185;
      font-size: 0.3rem;
      display: flex;
      align-items: center;

      ::v-deep {
        .van-field__control {
          text-indent: 0.5rem;
          font-size: 0.22rem;
          color: #333;
        }
      }

      &::after {
        border-bottom: 0;
      }
    }

    img {
      position: absolute;
      left: 0.3rem;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      margin-left: 0.4rem;
      width: 0.24rem;
      height: 0.24rem;
    }
  }
  .community-topic {
    display: flex;
    background-color: #f7f7f7;
    .community-topic-tabs {
      width: 1.52rem;
      flex-shrink: 0;
      background-color: #fff;
    }
    .community-topic-content {
      padding: 0.24rem 0.34rem 0 0.24rem;
      .community-topic-content-card {
        margin-bottom: 0.24rem;
      }
    }
  }
}
</style>
