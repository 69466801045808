<template>
  <div class="resource">
    <!--查询-->
    <ERefreshLoadV3 ref="ERefreshLoadV3" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
      <PlayList1 :list="playList" @on-choose="$emit('on-choose', $event)" />
    </ERefreshLoadV3>
  </div>
</template>
<script>
import ResourcePage from './ResourcePage.vue'
import { myPlayList } from 'api/playList'
export default {
  name: 'ResourcePublishPageTab',
  extends: ResourcePage,
  data() {
    return {}
  },
  computed: {},
  methods: {
    // 获取收藏短视频列表
    async getList() {
      try {
        const res = await myPlayList({
          status: 1, //0.审核中 1.已发布 2.未通过
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          userId: this.userId
        })
        if (res.code === 200) {
          this.playList = [...this.playList, ...(res.data.playList || [])]
          if (res.data.playList?.length < this.pageSize || !res.data.playList) {
            this.finished = true
          }
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.resource {
  .resource-opr {
    background-color: #ffffff;
  }
}
</style>
