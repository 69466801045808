<template>
  <div class="girllist-top">
    <ul>
      <li :class="{ active: activeIndex === 0 }" @click="handleClick(0)">
        最多播放
        <img src="@/assets/imgs/discover/italic.png" alt="" />
      </li>
      <li :class="{ active: activeIndex === 1 }" @click="handleClick(1)">
        最近添加
        <img src="@/assets/imgs/discover/italic.png" alt="" />
      </li>
      <li :class="{ active: activeIndex === 2 }" @click="handleClick(2)">最高评分</li>
    </ul>
    <!-- <img src="@/assets/imgs/discover/vertical.png" alt="" class="divider" /> -->

    <!-- <div :class="{ active: filterStatus, btn: true }" @click="switchFilter">
      <img v-if="filterStatus" src="@/assets/imgs/discover/filterRed.png" alt="" />
      <img v-else src="@/assets/imgs/discover/filter.png" alt="" />
      <span>筛选</span>
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'ActorFilter',
  model: {
    prop: 'activeIndex',
    event: 'input'
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0
    },
    filterStatus: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick(idx) {
      this.$emit('input', idx)
    }
    // switchFilter() {
    //   this.$emit('on-filter', !this.filterStatus)
    // }
  }
}
</script>
<style scoped lang="scss">
.girllist-top {
  @include flexbox;
  color: $grey;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  > ul {
    display: flex;
    li {
      margin-right: 0.12rem;
      transition: all 0.5s;
      &.active {
        color: $txtActive;
      }
    }
    img {
      width: 0.12rem;
    }
  }
  .divider {
    height: 0.28rem;
  }
  > .btn {
    @include flex-center;
    width: 1.32rem;
    height: 0.52rem;
    img {
      height: 0.28rem;
      &:nth-of-type(2) {
        height: 0.34rem;
        margin: 0 0.08rem 0 0.1rem;
      }
    }
    &.active {
      background-color: rgba(221, 0, 27, 0.15);
      border-radius: 0.4rem;
      color: #e8619d;
    }
  }
}
</style>
