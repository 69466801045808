<!--
使用方法
   <EVideoCover :imgURL="imgurl" :videoURL="videoUrl"></EVideoCover>

    imgURL:封面url
    videoUrl:视频m3u8（加接口 加路径 加token的完整版）例如： this.videoUrl = '/api/app/media/m3u8ex/' + this.data.preVideoUrl + `?token=${this.token}`;

-->
<template>
  <div class="video-item-cover" @click.stop="changeVideo">
    <div v-if="showVideo" id="player"></div>
    <EDecryptImg v-else :img-u-r-l="imgURL">
      <slot></slot>
    </EDecryptImg>
  </div>
</template>
<script>
import { BASEEventBus } from '../../plugin_base/event-bus.js';
export default {
  name: 'EVideoCover',
  components: {
    EDecryptImg: () => import('../EDecryptImg'),
  },
  props: {
    imgURL: {
      type: String,
      default() {
        return '';
      },
    },
    videoURL: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      showVideo: false,
      player: undefined,
    };
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
  },
  created() {
    let that = this;
    BASEEventBus.$on('changeVideo', function () {
      if (that.showVideo) {
        that.player.destroy(true);
        that.showVideo = false;
      }
    });
  },
  mounted() {},
  methods: {
    async changeVideo() {
      BASEEventBus.$emit('changeVideo');
      if (this.showVideo) {
        this.player.destroy(true);
        this.showVideo = false;
      } else {
        if (this.videoURL) {
          this.showVideo = true;
          this.$nextTick(() => {
            this.initPlayer();
          });
        } else {
          this.$toast('当前视频不支持预览');
        }
      }
    },

    async initPlayer() {
      const HlsJsPlayer = require('xgplayer-hls.js');
      let that = this;
      this.player = new HlsJsPlayer({
        id: 'player', //容器ID
        lang: 'zh-cn',
        fluid: true, //流式布局
        autoplay: true, // 是否自动播放
        volume: 0.3, // 音量
        autoplayMuted: true,
        loop: false, // 循环
        controls: true,
        cssFullscreen: false,
        videoInit: true, // 初始化视频首帧
        playbackRate: [0.5, 0.75, 1, 1.5, 2], // 倍速
        lastPlayTime: 0, // 记忆播放，从多少多少秒开始
        lastPlayTimeHideDelay: 5, //提示文字展示时长（单位：秒）
        rotateFullscreen: false, // 使得视频全屏幕时 横屏
        download: false, //设置download控件显示
        airplay: true,
        playsinline: true,
        preloadTime: 10,
        url: this.videoURL,
        // 开启弹幕
        danmu: {
          closeDefaultBtn: true,
        },
        execBeforePluginsCall: [
          () => {
            console.log('Execute before plugins call');
          },
        ],
        'x5-video-player-type': 'h5', //微信同层播放：
        'x5-video-player-fullscreen': 'true', //微信全屏播放：
        'x5-video-orientation': 'landscape', //微信横竖屏控制
      });
      this.player.once('canplay', () => {
        this.isReady = true;
      });
      this.player.on('error', error => {
        console.log(error);
      });
      this.player.on('ended', () => {
        that.player.destroy(true);
        that.showVideo = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.video-item {
  overflow: hidden;
  display: block;
  margin-bottom: 0.2rem;

  // padding-right: 5px;
  .video-item-cover {
    width: 100%;
    height: 100%;
    &.isVertical {
      height: 3.2rem;
    }

    &.width100 {
      height: 3.95rem;
    }

    height: 2rem;
    border-radius: 0.08rem;
    overflow: hidden;
  }
}
</style>
