<template>
  <div v-if="itemList && itemList.length" class="six-list-main">
    <ul class="six-list">
      <li v-for="item in itemList" :key="item.id">
        <comicsOneCard :isNovelItem="isNovelItem" width="2.18rem" height="2.86rem" :itemData="item" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {
    comicsOneCard: () => import('./comicsOneCard.vue')
  },
  props: ['itemList', 'isNovelItem'],
  mounted() {}
}
</script>

<style lang="scss" scoped>
.six-list-main {
  margin-bottom: 0.36rem;
}
.six-list {
  @include flexbox($jc: flex-start, $fw: wrap);

  li {
    width: 2.18rem;
    margin-right: 0.14rem;
    flex: none;
    margin-bottom: 0.2rem;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
