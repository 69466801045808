<template>
  <div class="home-header" @click="$router.push('/Discover/search')">
    <div class="frontPage">
      <div class="search-input">
        <img src="@/assets/imgs/home/Search.png" alt="" class="searchIcon" />
        女优名字
      </div>
    </div>
    <span>搜索</span>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.home-header {
  height: 0.88rem;
  background-color: #fff;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  .frontPage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 6.2rem;
    .search-input {
      background: rgba(217, 217, 217, 0.3);
      width: 7.2rem;
      height: 0.6rem;
      line-height: 0.6rem;
      border-radius: 0.2rem 0 0 0.2rem;
      color: #939496;
      font-size: 0.28rem;
      padding-left: 0.21rem;
      @include flex-align-center;
      .searchIcon {
        width: 0.28rem;
        height: 0.28rem;
        vertical-align: middle;
        margin-right: 0.2rem;
      }
    }
  }
  > span {
    color: #fff;
    font-size: 0.26rem;
    background: $gradientBg;
    padding: 0.1rem;
    border-radius: 0 0.2rem 0.2rem 0;
  }
  .searchLogo {
    width: 0.6rem;
    height: 0.6rem;
    margin-left: 0.24rem;
  }
}
</style>
