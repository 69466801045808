<template>
  <div class="home-index">
    <ERefreshLoadV3 ref="ERefreshLoadV3" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
      <div v-if="navItem.adsType === 2 && homeAD.length">
        <AdAvatarList :list="iconHomeAds" :wrap="true" key-name="avatar" />
      </div>
      <div v-else-if="navItem.adsType !== 3 && homeAD.length" class="top_banner">
        <EADSwiper :imgCDN="imgCDN" :list="homeAD" key-name="cover" @clickImg="clickImg" />
      </div>
      <JGList v-if="iconAdsShow && jumptabList.length" class="maindom" :list="jumptabList"></JGList>
      <div class="content">
        <VideoshowType1 v-for="(item, index) in mediaList" :key="index" :data="item"></VideoshowType1>
      </div>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { index_home } from 'api/home'
import { JumpTo } from '@/utils/utils_tools'
export default {
  name: 'PageType1', //纯视频模版
  components: {
    EADSwiper: () => import('@/components/web_base/component_base/EADSwiper/index.vue'),
    VideoshowType1: () => import('./showType/videoListType/videoshowType1'),
    JGList: () => import('@/components/Home/showType/JGList'), //金刚区
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue')
  },
  props: ['navItem', 'id'],
  data() {
    return {
      pageNum: 0,
      pageSize: 10,
      finished: false,
      // remDataList: [], // 推荐
      // actorList: [], //女优列表
      jumptabList: [], //金刚区列表
      mediaList: [],
      iconAdsShow: false //视频列表
      // recomendActorList: [], //推荐女优列表
      // shortList: [], //短视频列表
      // tagList: [], //标签列表
      // topicList: [] //主题列表
    }
  },
  computed: {
    // 获取首页轮播广告
    homeAD({ $store }) {
      return $store.getters['homeAD']
    },
    iconHomeAds({ $store }) {
      return $store.getters['iconHomeAds']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },

  methods: {
    async onLoad() {
      this.pageNum += 1
      await this.getHomePageData()
    },
    // 下拉刷新
    async onRefresh() {
      this.finished = false
      //表示处于可以下拉状态
      this.jumptabList = []
      this.pageNum = 0
      this.mediaList = []
    },

    // banner跳转
    clickImg(item) {
      JumpTo(item.href)
    },

    // 获取首页数据
    async getHomePageData() {
      await index_home({
        id: this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code === 200) {
          this.iconAdsShow = res.data.iconAdsShow
          this.mediaList = [...this.mediaList, ...res.data.mediaList]
          if (res.data.jumptabList) this.jumptabList = [...this.jumptabList, ...res.data.jumptabList]
          if (res.data.mediaList.length < this.pageSize || !res.data.mediaList) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.home-index {
  padding: 0 0.32rem;

  .top_banner {
    width: 6.86rem;
    height: 2.86rem;
    border-radius: 0.16rem;
    overflow: hidden;
    margin: 0 auto;
  }
}

.content {
  display: flex;
  flex-flow: wrap;
  margin-top: 0.24rem;

  >div {
    height: auto !important;
    margin-bottom: 0.24rem;
    margin-right: 0.14rem;
  }

  >div:nth-child(2n) {
    margin-right: 0;
  }
}

.maindom {
  padding-top: 0.18rem;
  padding-bottom: 0.18rem;
  box-sizing: content-box;
}
</style>
