<template>
  <div class="record-container">
    <EBackTop title="金币明细"></EBackTop>
    <ERefreshLoadV3
      ref="ERefreshLoadV3"
      class="main_list"
      :beforeRefresh="onRefresh"
      :moreData="onLoad"
      :finished="finished"
    >
      <ul class="record-listWrap">
        <li v-for="(item, index) in transList" :key="index" class="item">
          <div class="orderNo">
            <span>订单编号:{{ item.orderNo }}</span>
            <div
              v-clipboard:copy="item.orderNo"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              class="copy-btn"
            >
              <span>复制</span>
            </div>
          </div>
          <div class="desc-type">
            <span>{{ item.currencyType === 1 ? '金币购买' : 'VIP购买' }}</span>
            <span> {{ item.desc }}</span>
          </div>
          <div class="createdAt">
            <span> {{ item.createdAt | dateFormat08 }}</span>
            <div>
              <span :class="item.currencyType === 1 ? 'green' : 'red'">{{
                `${item.currencyType === 1 ? '+' : '-'}`
              }}</span>
              <span :class="item.currencyType === 1 ? 'green' : 'red'">{{ item.coinAmount | changeMoneyYuan }}</span>
            </div>
          </div>
        </li>
      </ul>
    </ERefreshLoadV3>
  </div>
</template>
<script>
import { transList } from 'api/user'
export default {
  components: {},
  data() {
    return {
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 关注列表
      transList: [],
      // 请求类型
      getType: '',
      // 当前页
      pageNum: 0,
      // 当前个数
      pageSize: 10
    }
  },
  mounted() {
    // this.onRefresh()
  },
  methods: {
    // 获取流水记录
    async get_transList() {
      try {
        const res = await transList({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.transList = [...this.transList, ...res.data.list]
          if (res.data && res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
          return this.$toast(res.tip)
        }
      } catch (error) {
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 下拉加载
    onLoad() {
      this.pageNum++
      this.get_transList()
    },
    // 上拉刷新
    onRefresh() {
      this.pageNum = 0
      this.transList = []
    },
    onCopy() {
      this.$toast('复制成功！！！')
    },
    onError() {
      this.$toast('复制失败！！！')
    }
  }
}
</script>
<style lang="scss" scoped>
.record-listWrap {
  margin: 0.3rem 0;
  padding: 0 0.34rem;

  .item {
    border-radius: 0.2rem;
    background: var(--4, rgba(0, 0, 0, 0.04));
    padding: 0.42rem;
    margin-top: 0.32rem;
  }
  .orderNo {
    @include flexbox();
  }
  .desc-type {
    border-radius: 0.6rem;
    background: rgba(0, 0, 0, 0.08);
    display: inline-block;
    padding: 0.02rem 0.16rem;
    margin: 0.08rem 0;
    span {
      &:last-child {
        color: #1472ff;
        opacity: 0.6;
      }
    }
  }
  .createdAt {
    @include flexbox();
    span {
      &:first-child {
        opacity: 0.5;
      }
      &:last-child {
        font-size: 0.28rem;
      }
    }
  }
  .red {
    color: $txtActive;
  }
  .green {
    color: #009a88;
  }
  .copy-btn {
    border-radius: 2rem;
    background: $btnBg;
    padding: 0.04rem 0.16rem;
    width: 0.96rem;
    height: 0.53rem;
    text-align: center;
    font-size: 0.32rem;
    white-space: nowrap;
    color: #fff;
  }
}
</style>
