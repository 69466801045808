<template>
  <van-popup v-if="datingAd.length" v-model="isShow" class="activity-img-pop" @close="handleClose">
    <div class="activity-img" @click.stop="imgJub">
      <EDecryptImg :imgCDN="imgCDN" :imgURL="activityImg"> </EDecryptImg>
      <div><van-icon name="close" size="35" color="#fff" @click.stop="handleClose" /></div>
    </div>
  </van-popup>
</template>

<script>
import { JumpTo } from '@/utils/utils_tools'
import { advertiseClick } from 'api/home'
export default {
  data() {
    return {
      isShow: false,
      datingAd: [],
      activeIndex: 0
    }
  },
  computed: {
    activityImg({ $store }) {
      return $store.getters['popAD'][this.activeIndex].cover
    },

    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  created() {
    this.datingAd = this.$store.getters.popAD
    this.isShow = this.datingAd.length > 0
  },
  methods: {
    handleClose() {
      if (this.datingAd.length && this.datingAd.length - 1 === this.activeIndex) {
        this.isShow = false
      } else {
        this.activeIndex += 1
      }
    },
    async imgJub() {
      if (this.datingAd[this.activeIndex].href) {
        JumpTo(this.datingAd[this.activeIndex].href)
        if (this.datingAd[this.activeIndex].href.startsWith('http') == false) {
          this.datingAd = []
          this.isShow = false
        }
        const res = await advertiseClick({
          id: this.datingAd[this.activeIndex].id
        })
        if (res !== 200) {
          return this.$toast(res.tip)
        }
      }
      // this.$store.commit('SET_ACTIVITY_POP', false);
      //   this.showActivityImg = this.activityPop;
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-img-pop {
  background-color: transparent !important;
  overflow-y: visible;
  // background: red;
  // z-index: 2003 !important;

  .activity-img {
    text-align: center;
    width: 6.7rem;
    height: 100%;

    :deep(.van-icon) {
      margin-top: 0.2rem;
    }
  }
}
</style>
