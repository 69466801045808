<template>
  <div class="index-label">
    <EBackTop
      :background="filterStatus ? '#ffffff' : 'transparent'"
      class="bg"
      :class="{ fixed: !filterStatus }"
      :color="filterStatus ? undefined : '#ffffff'"
    >
      <template #right>
        <template>
          <span @click="clearSearch">清除</span>
        </template>
      </template>
    </EBackTop>
    <actor-info v-if="!filterStatus" :id="+$route.params.id" @postData="postData" />
    <tag-select v-else v-model="selectedList" />

    <!--    切换面板-->
    <actor-video-page-tabs
      :style="{ backgroundColor: filterStatus ? '#F5F5F5' : '#ffffff' }"
      :tag-ids="selectedListIds"
      :filter-status="filterStatus"
      :detailInfo="detailInfo"
      @on-filter="switchFilter"
    />
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
export default {
  name: 'DiscoverActor',
  mixins: [scrollMixin],
  components: {
    ActorVideoPageTabs: () => import('./actorVideoPageTabs.vue'),
    ActorInfo: () => import('./actorInfo.vue'),
    TagSelect: () => import('@/components/TagSelect/index.vue')
  },
  data() {
    return {
      isFold: true,
      filterStatus: false,
      selectedList: [],
      detailInfo: {}
    }
  },
  computed: {
    selectedListIds() {
      return this.selectedList.map(v => v.id)
    }
  },
  methods: {
    postData(obj) {
      this.detailInfo = obj
    },
    switchFilter(e) {
      this.filterStatus = e
    },
    clearSearch() {
      this.selectedList = []
    }
  }
}
</script>
<style lang="scss" scoped>
.index-label {
  color: #999999;
  .bg.fixed {
    position: fixed;
    top: 0;
    z-index: 2;
  }
}
</style>
