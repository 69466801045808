<template>
  <div class="home-index">
    <ERefreshLoadV3 ref="ERefreshLoadV3" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
      <div v-if="navItem.adsType === 2 && homeAD.length">
        <AdAvatarList :list="iconHomeAds" :wrap="true" key-name="avatar" />
      </div>
      <div v-if="navItem.adsType !== 3 && homeAD.length" class="top_banner">
        <EADSwiper :imgCDN="imgCDN" :list="homeAD" key-name="cover" @clickImg="clickImg" />
      </div>
      <ETabBar
        :list="btnType"
        :tabbar-config="tabbarConfig"
        :active-num="activeId"
        class="HomeComponent-Etab"
        @change="changeTab"
      >
        <template>
          <div class="HomeComponent">
            <EWaterFall :datasList="photoList">
              <template #left="{ handleLoad, data }">
                <ImgshowType2 :data="data" class="leftItem" @loadedImg="handleLoad"></ImgshowType2>
              </template>
              <template #right="{ handleLoad, data }">
                <ImgshowType2 :data="data" class="rightItem" @loadedImg="handleLoad"></ImgshowType2>
              </template>
            </EWaterFall>
          </div>
        </template>
      </ETabBar>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { index_home } from 'api/home'
import { JumpTo } from '@/utils/utils_tools'
export default {
  name: 'PageType2',
  components: {
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue'),
    EADSwiper: () => import('@/components/web_base/component_base/EADSwiper/index.vue'),
    ImgshowType2: () => import('components/Home/showType/pictrueListType/imgshowType2.vue')
  },
  props: ['navItem', 'id'],
  data() {
    return {
      tabbarConfig: {
        barWidth: '4.48rem,',
        barHeight: '0.6rem',
        fontSize: '0.26rem', //默认字体大小
        fontSizeActive: '0.26rem', //选中字体大小
        fontColor: '#666666', //默认字体颜色
        fontColorActive: '#fff', //选中字体颜色
        bg: 'rgba(0,0,0,0.05)', //默认背景色
        bgActive: '#E8619D', //选中背景色
        lineBott: '0rem',
        lineColorActive: '#E8619D',
        lineWidth: '0rem', //下划线宽度 String
        sticky: false, //粘性布局
        tabFlex: true
      },
      btnType: [
        { name: '精选推荐', id: 2 },
        { name: '最新发布', id: 0 },
        { name: '最高热度', id: 1 }
      ],
      activeId: 2,
      pageNum: 0,
      pageSize: 9,
      finished: false,
      photoList: []

      // 推荐
    }
  },
  computed: {
    // 获取首页轮播广告
    homeAD({ $store }) {
      return $store.getters['homeAD']
    },
    iconHomeAds({ $store }) {
      return $store.getters['iconHomeAds']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },

  methods: {
    changeTab(id) {
      this.activeId = id
      this.pageNum = 0
      this.photoList = []
      this.onLoad()
    },
    async onLoad() {
      this.pageNum += 1
      await this.getHomePageData()
    },
    // 下拉刷新
    async onRefresh() {
      this.finished = false
      //表示处于可以下拉状态
      this.pageNum = 0
      this.photoList = []
    },

    // banner跳转
    clickImg(item) {
      JumpTo(item.href)
    },
    // 获取首页数据
    async getHomePageData() {
      await index_home({
        id: this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sort: this.activeId
      }).then(res => {
        if (res.code === 200) {
          this.photoList = [...this.photoList, ...res.data.photoList]
          if (res.data.photoList.length < this.pageSize || !res.data.photoList) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.home-index {
  padding: 0 0.32rem;

  .top_banner {
    width: 6.86rem;
    height: 2.86rem;
    border-radius: 0.16rem;
    overflow: hidden;
    margin: 0 auto;
  }

  .HomeComponent-Etab {
    margin: 0.12 * 2rem 0;

    ::v-deep {
      .van-tabs__nav {
        width: 2.24 * 2rem;
        border-radius: 0.15 * 2rem;
        justify-content: center;

        .van-tab {
          background-color: rgba(0, 0, 0, 0);
          padding: 0 0.1rem;
        }

        .van-tab--active {
          background-color: #e8619d;
          border-radius: 0.15 * 2rem;
          font-size: 0.26rem !important;
        }
      }
    }
  }

  .typeBtn {
    height: 0.3 * 2rem;
    margin: 0.12 * 2rem 0;
    padding: 0.04 * 2rem;
    background-color: rgba($color: #000000, $alpha: 0.05);
    border-radius: 0.15 * 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: 0.68 * 2rem;
      height: 0.22 * 2rem;
      font-size: 0.13 * 2rem;
      font-weight: 400;
      color: #666;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .select {
      background-color: #e8619d;
      color: #fff;
      border-radius: 0.15 * 2rem;
    }
  }
}

.leftItem,
.rightItem {
  width: 1.68 * 2rem;
  margin-bottom: 0.14rem;
  border-radius: 0.16rem;
}

.leftItem {
  margin-right: 0.07rem;
}

.rightItem {
  margin-left: 0.07rem;
}
</style>
