<template>
  <div class="top">
    <span>{{ text }}</span>
    <van-icon name="arrow" size="0.32rem" @click="handleClick" />
  </div>
</template>

<script>
export default {
  props: ['text'],
  methods: {
    handleClick() {
      this.$emit('onclick')
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  position: relative;
  @include flexbox;
  color: $txtDefault;
  font-size: 0.32rem;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0.24rem;
}
</style>
