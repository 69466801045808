<template>
  <div class="release">
    <EBackTop title="问题反馈"></EBackTop>
    <div class="listWrap">
      <div class="title">问题类型</div>
      <div class="itemWrap">
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          :class="showActive === item.id ? 'active' : ''"
          @click="handelClick(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <div class="release-main">
      <!-- 描述信息 -->
      <van-field
        v-model="text"
        maxlength="2000"
        autosize
        required
        type="textarea"
        placeholder="请详细描述您的意见问题，非常感谢您对我们的支持！"
      />
      <div class="tip">不能超过2000字符</div>
      <ul class="img-show">
        <EDecryptImg
          v-for="(item, index) in upLoadImgs"
          ref="refImgParent"
          :key="index"
          :upLoadImgs="upLoadImgs"
          :rowNum="3"
          :item="item"
          :showClose="true"
        ></EDecryptImg>
      </ul>
      <div class="btn-wrap">
        <div class="btn1" @click="loadImgFn">
          <van-icon name="plus" />
          插入图片
        </div>
        <UploadImgs v-show="false" ref="loadImg" :maxLength="9" :upLoadImgs="upLoadImgs" @result="onReadImg">
        </UploadImgs>
        <div class="btn2" @click="submit">提交</div>
      </div>
    </div>
    <div class="lxkfWrap">
      <img @click="$router.push('/Mine/setting/kfProblem')" src="@/assets/imgs/mine/lxkf.png" />
    </div>
  </div>
</template>

<script>
import { feedback_add } from '@/api/user'
const TYPEDATA = [
  { id: 1, name: '账号问题' },
  { id: 2, name: '没找到资源' },
  { id: 3, name: 'APP不好用' },
  { id: 4, name: '视频播放失败' },
  { id: 5, name: '视频播放卡顿' },
  { id: 6, name: '分类有误' },
  { id: 7, name: '充值' },
  { id: 8, name: '提现' },
  { id: 0, name: '其他' }
]
export default {
  components: {
    EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue'),

    UploadImgs: () => import('@/components/Imgs/UploadImg/index.vue')
  },
  data() {
    return {
      list: Object.freeze(TYPEDATA),
      showActive: 1,
      upLoadImgs: [],
      text: '',
      textList: [
        '1.每一条楼凤只能举报一次不能重复举报',
        '2.楼凤举报有截图证明的合适以后可享受楼凤退款',
        '3.购买楼凤后超过7天再进行举报则视为常规举报，不享受退款处理'
      ]
    }
  },
  mounted() {},
  methods: {
    handelClick(val) {
      this.showActive = val
    },
    //读取img
    onReadImg(list) {
      if (!list.length) return
      this.upLoadImgs = [...this.upLoadImgs, ...list]
    },
    loadImgFn() {
      this.$refs.loadImg.$refs.Uploader.chooseFile()
    },
    async submit() {
      if (!this.text || this.text.length < 8) {
        return this.$toast('意见反馈字数不可少于8个字')
      }
      // if (this.upLoadImgs.length<4) {
      //   return this.$toast('图片不能少于4张')
      // }
      const res = await feedback_add({
        content: this.text,
        pics: this.upLoadImgs,
        type: 0
      })
      if (res.code === 200) {
        setTimeout(() => {
          this.$router.go('-1')
        }, 2000)
        return this.$toast('已提交成功！')
      } else {
        return this.$toast(res.tip)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.release {
  min-height: 100vh;
  .listWrap {
    padding: 0.2rem 0.28rem 0;
    .title {
      margin-bottom: 0.25rem;
      color: #e8619d;
      font-size: 0.32rem;
    }
    .itemWrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        display: flex;
        margin-bottom: 0.2rem;
        width: 2.2rem;
        height: 0.64rem;
        justify-content: center;
        align-items: center;
        font-size: 0.26rem;
        color: #333;
        border-radius: 0.5rem;
        background: rgba(0, 0, 0, 0.1);
      }
      .item:nth-of-type(n + 7) {
        margin-bottom: 0;
      }
      .active {
        color: #fff !important;
        background: linear-gradient(101deg, #ffea7a 3.16%, #ff74b2 65.51%, #ff74b2 93.52%) !important;
      }
    }
  }
  .release-main {
    padding: 0.3rem;
    :deep() {
      .van-field__control {
        color: #000 !important;
      }
    }

    .van-cell {
      width: 6.78rem;
      height: 3.2rem;
      margin-top: 0.2rem;
      border-radius: 0.16rem;

      :deep() {
        .van-field__control {
          color: #fff;
        }
      }
    }
    .van-cell::after {
      display: none;
    }
    .tip {
      padding-top: 0.1rem;
      color: #ff7777;
    }
  }
  .img-show {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.2rem;
    :deep() {
      li {
        &.rowNum3 {
          height: 2.12rem;
        }
      }
    }
  }
  .btn-wrap {
    display: flex;
    padding-top: 0.2rem;
    @include flexbox();
    .btn1 {
      border-radius: 0.06rem;
      background: #e6e6e6;
      display: flex;
      padding: 0.16rem 0.64rem;
      justify-content: center;
      align-items: center;
      gap: 0.24rem;
      img {
        margin-right: 0.1rem;
        width: 0.33rem;
        height: 0.28rem;
      }
    }
    .btn2 {
      width: 3rem;
      height: 0.74rem;
      text-align: center;
      line-height: 0.74rem;
      border-radius: 0.06rem;
      background: linear-gradient(101deg, #ffea7a 3.16%, #ff74b2 65.51%, #ff74b2 93.52%);
      color: #fff;
    }
  }
  .lxkfWrap {
    width: 100%;
    padding: 0.3rem;
    text-align: right;
    img {
      width: 1rem;
      height: 1rem;
    }
  }
}
</style>
