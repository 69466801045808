<template>
  <div class="item" @click="handleClick">
    <div class="left">
      <div class="bodan-img">
        <EDecryptImg :imgURL="data.cover"> </EDecryptImg>
      </div>
      <div class="pos-bottom">
        <div class="left">
          <template v-if="data.type === 2">
            <img class="item-img" src="@/assets/imgs/discover/photo.png" alt="" />
            <span>图片</span>
          </template>
          <template v-else>
            <img class="item-img" src="@/assets/imgs/discover/video.png" alt="" />
            <span>视频</span>
          </template>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="play-list-item-title">{{ data.title }}</div>
      <div class="play-list-item-count">「{{ data.total }}个动图」</div>
      <div class="play-list-item-date">{{ data.createdAt | timeYmd }}</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  computed: {},

  mounted() {},

  methods: {
    handleClick() {
      this.$router.push({
        path: '/discover/detail',
        query: {
          id: this.data.id,
          type: this.data.type
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.item {
  width: 100%;
  @include flexbox(flex-start, center);
  .left {
    flex-shrink: 0;
    width: 1.44rem;
    height: 1.44rem;
    position: relative;
    background: #7a6a6a;
    border-radius: 0.2rem;

    .bodan-img {
      width: 1.44rem;
      height: 1.44rem;
      overflow: hidden;
      border-radius: 0.2rem;
      border-top: 0.05rem solid #fff;
      box-sizing: content-box;
      position: absolute;
      top: 0.08rem;
    }
    .pos-bottom {
      position: absolute;
      width: 100%;
      height: 0.76rem;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
      // background: red;
      left: 0;
      bottom: 0;
      border-radius: 0 0 0.2rem 0.2rem;
      color: $white;
      padding: 0 0.16rem;
      font-size: 0.2rem;
      @include flexbox;

      .left {
        @include flex-align-center;

        position: absolute;
        width: 0.96rem;
        height: 0.42rem;
        left: 0.08rem;
        bottom: 0.08rem;
        background: #00000080;
        border-radius: 0.08rem;
        color: #ffffff;
        @include flex-center;
        .item-img {
          width: 0.26rem;
          margin-right: 0.08rem;
        }
      }
    }
  }

  .right {
    margin: 0 0 0 0.24rem;
    flex-grow: 1;
    .play-list-item-title {
      font-weight: 500;
      font-size: 0.28rem;
      line-height: 0.4rem;
      color: #333333;
    }
    .play-list-item-count {
      font-weight: 500;
      font-size: 0.28rem;
      line-height: 0.4rem;
      color: #333333;
    }
    .play-list-item-date {
      font-weight: 500;
      font-size: 0.24rem;
      line-height: 0.36rem;
      color: rgba(154, 154, 154, 1);
    }
  }
}
</style>
