<template>
  <div class="play-list">
    <play-list-show-type3
      v-for="item in list"
      :key="item.id"
      :data="item"
      class="play-list-item"
      @click.native="$emit('on-choose', item)"
    />
  </div>
</template>
<script>
export default {
  name: 'PlayList1',
  components: {
    PlayListShowType3: () => import('../../showType/playListType/playListShowType3.vue')
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
.play-list {
  .play-list-item {
    margin-bottom: 0.24rem;
    &:nth-last-child {
      margin-bottom: 0;
    }
  }
}
</style>
