<template>
  <div class="cartoon-rank-list">
    <EBackTop :title="''">
      <template #title>
        <ul class="top_title">
          <li
            v-for="(item, index) in classTypeList.typeList"
            :key="index"
            :class="item.value === param.type ? 'activeIndex' : ''"
            @click="checkItem('type', item.value)"
          >
            {{ item.name }}
          </li>
        </ul>
      </template>
    </EBackTop>

    <div class="content" v-if="isOpenClose">
      <div class="typeContent">
        <span>标签</span>
        <div>
          <van-icon
            @click="showAll"
            class="icon"
            :name="!isShowAll ? 'arrow-down' : 'arrow-up'"
            color="#000"
            size="0.28rem"
          />
          <van-icon
            @click="showAll"
            class="icon2"
            :name="!isShowAll ? 'arrow-down' : 'arrow-up'"
            color="#000"
            size="0.28rem"
          />
          <ul :style="{ 'flex-wrap': isShowAll ? 'wrap' : 'nowrap' }">
            <li
              v-for="(item, index) in tagList"
              :key="index"
              :class="item.id === param.tagId ? 'activeIndex' : ''"
              @click="checkItem('tagId', item.id)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="typeContent">
        <span>价格</span>
        <div>
          <ul>
            <li
              v-for="(item, index) in classTypeList.priceTypeList"
              :key="index"
              :class="item.value === param.priceType ? 'activeIndex' : ''"
              @click="checkItem('priceType', item.value)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="typeContent">
        <span>时间</span>
        <div>
          <ul>
            <li
              v-for="(item, index) in classTypeList.dateTypeList"
              :key="index"
              :class="item.value === param.dateType ? 'activeIndex' : ''"
              @click="checkItem('dateType', item.value)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="typeContent">
        <span>排序</span>
        <div>
          <ul>
            <li
              v-for="(item, index) in classTypeList.sortTypeList"
              :key="index"
              :class="item.value === param.sortType ? 'activeIndex' : ''"
              @click="checkItem('sortType', item.value)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="fliterList">
      <div class="floatBtn" @click="isOpenClose = !isOpenClose">
        <van-icon :name="!isOpenClose ? 'arrow-down' : 'arrow-up'" size="0.20rem" />
        <span>{{ !isOpenClose ? '展开' : '收起' }}</span>
      </div>
      <VideoSearch v-if="param.type === 1" ref="VideoSearch" :param="param"></VideoSearch>
      <PhotoSearch v-if="param.type === 9" ref="PhotoSearch" :param="param"></PhotoSearch>
      <PostSearch v-if="param.type === 5" ref="PostSearch" :param="param"></PostSearch>
    </div>
  </div>
</template>

<script>
import { tag_list } from 'api/search'
export default {
  components: {
    VideoSearch: () => import('@/components/Search/classType/videoSearch'),
    PhotoSearch: () => import('@/components/Search/classType/photoSearch'),
    PostSearch: () => import('@/components/Search/classType/postSearch')
  },
  layout: 'ClassType',
  data() {
    return {
      isOpenClose: true,
      isShowAll: false,
      param: {
        type: 1, //类型
        sortType: 0, //排序
        priceType: 0, //价格
        dateType: undefined, //时间
        tagId: 0 //标签
      },
      tagList: [] //动态标签列表数据
    }
  },
  computed: {
    classTypeList() {
      let param = {
        typeList: [
          { name: '影片', value: 1 },
          { name: '图集', value: 9 },
          { name: '文章', value: 5 }
        ],
        sortTypeList: [
          { name: '综合排序', value: 0 },
          { name: '最多播放', value: 1 },
          { name: '收藏最多', value: 2 },
          { name: '最新上架', value: 3 }
        ],
        priceTypeList: [
          { name: '全部类型', value: 0 },
          { name: 'VIP', value: 1 },
          { name: '金币', value: 2 },
          { name: '免费', value: 3 }
        ],
        dateTypeList: [
          { name: '本月', value: 1 },
          { name: '三月内', value: 2 },
          { name: '半年内', value: 3 },
          { name: '更久', value: 0 }
        ]
      }
      return param
    }
  },
  async mounted() {
    await this.gettag()
  },

  methods: {
    showAll() {
      this.isShowAll = !this.isShowAll
    },
    // 顶部标签选择
    async checkItem(key, value) {
      if (key === 'type') {
        this.param[key] = value
        await this.gettag()
      } else {
        this.param[key] = value
      }
      if (this.param.type === 1) {
        this.$refs['VideoSearch'].setParam(this.param)
      } else if (this.param.type === 9) {
        this.$refs['PhotoSearch'].setParam(this.param)
      } else if (this.param.type === 5) {
        this.$refs['PostSearch'].setParam(this.param)
      }
    },
    async gettag() {
      await tag_list({ location: this.param.type }).then(res => {
        if (res.code === 200) {
          // console.log(res.data.tagList.length)
          this.tagList = res.data.tagList.slice(0, 100)
          this.tagList.unshift({ name: '全部标签', id: 0 })
          this.param.tagId = 0
        } else {
          this.tagList = []
          return this.$toast(res.tip)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cartoon-rank-list {
  box-sizing: border-box;
  min-height: 100vh;
}
ul,
li {
  margin: 0;
  padding: 0;
}
.top_title {
  display: flex;
  li {
    padding: 0 0.25rem;
  }
  .activeIndex {
    color: #e8619d;
  }
}
.content {
  padding: 0.12rem 0.32rem 0;
  .typeContent {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.12rem 0;
    > span {
      font-size: 0.24rem;
      font-weight: 600;
      color: #333333;
    }
    > div {
      margin-left: 0.32rem;
      max-width: 6rem;
      > ul {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        max-height: 10vh;
        overflow-x: hidden;
        overflow-y: scroll;
        > li {
          flex-shrink: 0;
          height: 0.44rem;
          display: flex;
          align-items: center;
          justify-items: center;
          padding: 0.02rem 0.2rem;
          font-size: 0.22rem;
          border-radius: 0.24rem;
        }
        .activeIndex {
          color: #fff;
          background: #e8619d;
          font-size: 0.24rem;
        }
      }
    }
  }
  > .typeContent:first-child {
    > div {
      position: relative;
      max-width: 5.4rem !important;
      > ul {
        > li {
          margin-bottom: 0.12rem;
        }
      }
      .icon {
        position: absolute;
        top: 0.05rem;
        right: -0.45rem;
      }
      .icon2 {
        position: absolute;
        top: 0.13rem;
        right: -0.45rem;
      }
    }
  }
}
.fliterList {
  position: relative;
  width: 100%;
  .floatBtn {
    width: 0.8rem;
    font-size: 0.2rem;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    color: #fff;
    height: 0.32rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.12rem auto 0;
    background: $btnBg;
  }
}
</style>
