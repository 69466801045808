<template>
  <div class="itemWrap" @click="goToImgList">
    <EDecryptImg :imgCDN="imgCDN" class="mine_top_bg" :imgURL="item.cover"> </EDecryptImg>
    <div class="bottom">
      <div class="left">
        <img src="@/assets/imgs/discover/eyes.png" alt="" />
        <span>{{ item.real.watchTimes | numberFilter('w') }}</span>
      </div>
      <div class="right">
        <img src="@/assets/imgs/discover/photo-transparent.png" alt="" />
        <span>{{ item.photoCount || item.pictures?.length }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    goToImgList() {
      this.$router.push({
        path: '/discover/imgList',
        query: {
          id: this.item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.itemWrap {
  position: relative;
  margin-bottom: 0.1rem;
  border-radius: 0.12rem;
  overflow: hidden;
  .bottom {
    position: absolute;
    height: 0.4rem;
    width: 100%;
    color: #fff;
    background: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0));
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 0.15rem;
    .left,
    .right {
      display: flex;
      align-items: center;
      img {
        width: 0.3rem;
        margin-right: 0.05rem;
      }
    }
  }
}
</style>
