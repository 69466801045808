<template>
  <div class="hot-play">
    <template v-if="playList.length">
      <div class="title padding" @click="$router.push('/discover/playlist')">
        相关播单 <img src="@/assets/imgs/home/more.png" alt="" />
      </div>
      <div class="content">
        <div class="hot-play-first">
          <PlayListShowType2 v-for="(item, index) in playList" :key="index" class="hot-play-item" :data="item" />
        </div>
      </div>
    </template>

    <!-- <template v-if="avList.length">
      <div class="title padding">
        AV精选 <img src="@/assets/imgs/home/more.png" alt="" @click="$router.push('/search?type=1')" />
      </div>
      <div class="hot-play-second">
        <VideoShowType3 v-for="(item, index) in avList" :key="index" class="hot-play-item" :data="item">
        </VideoShowType3>
      </div>
    </template> -->
  </div>
</template>

<script>
export default {
  components: {
    PlayListShowType2: () => import('./playListType/playListShowType2.vue'),
    VideoShowType3: () => import('./videoListType/videoshowType3.vue')
  },
  props: {
    avList: {
      type: Array,
      default: () => {
        return []
      }
    },
    playList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },

  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.hot-play {
  .padding {
    padding: 0 0.32rem;
  }
  .title {
    margin-top: 0.24rem;
    width: 100%;
    height: 0.44rem;
    font-size: 0.32rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content {
    padding-left: 0.32rem;
    .hot-play-first {
      margin-top: 0.24rem;
      @include flexbox(flex-start);
      overflow-x: auto;
      @include scrollbar-hide;
      .hot-play-item {
        margin-right: 0.1rem;
      }
    }
  }

  .hot-play-second {
    margin-top: 0.24rem;
    padding-left: 0.32rem;
    @include flexbox(flex-start);
    overflow-x: auto;
    @include scrollbar-hide;
    .hot-play-item {
      margin-right: 0.14rem;
    }
  }
}
</style>
