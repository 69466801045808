<template>
  <div class="playlist-detail">
    <div class="top-bg">
      <EBackTop
        title="播单详情"
        :background="background"
        :color="color"
        :open-fallback="true"
        :fixed="true"
        :placeholder="true"
      >
      </EBackTop>
      <TopAvatar :detail="getLocalPlayListData" :showBottom="false" />
      <EDecryptImg class="playlist-img" :imgURL="getLocalPlayListData.cover" />
    </div>
    <div class="recommend">
      <!--      <div class="Indicator"></div>-->

      <div class="recommend-containe">
        <RecommendVideo v-if="type === 1" :playlist="mediaList" />
        <RecommendPhoto v-else :listData="photoList" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiscoverLocalDetail',
  components: {
    TopAvatar: () => import('../components/topAvatar.vue'),
    RecommendVideo: () => import('../playList/components/video.vue'),
    RecommendPhoto: () => import('../components/waterfall.vue')
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      showComment: false,
      background: 'transparent',
      color: '#fff'
    }
  },
  computed: {
    type() {
      return +this.$route.query.type
    },
    playListId() {
      return +this.$route.query.id
    },
    // 获取历史记录
    getCartoonList({ $store }) {
      return $store.getters['getCartoonList']
    },
    //获取长视频历史记录
    getLongVideoList({ $store }) {
      return $store.getters['longVideoList']
    },
    // 获取历史记录
    getDreamList({ $store }) {
      return $store.getters['getDreamList']
    },
    getPhotoList({ $store }) {
      return $store.getters['getPhotoList']
    },
    getLocalPlayListData({ $store }) {
      let list = JSON.parse(JSON.stringify($store.getters['getPlayListData']))
      return list.find(item => item.type === this.type && item.id === this.playListId) || {}
    },
    mediaList() {
      const list = [...this.getCartoonList, ...this.getLongVideoList, ...this.getDreamList]
      return list.filter(item => this.getLocalPlayListData.mediaIds.includes(item.id))
    },
    photoList() {
      const list = [...this.getPhotoList]
      return list.filter(item => this.getLocalPlayListData.actressphoto.includes(item.id))
    }
  },
  async mounted() {
    window.addEventListener('scroll', this.scrollHandle) //绑定页面滚动事件
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandle)
  },
  methods: {
    // 监听滚动高度
    scrollHandle(e) {
      let top = e.srcElement.scrollingElement.scrollTop // 获取页面滚动高度
      if (top >= 200) {
        this.background = '#fff'
        this.color = '#000'
      } else {
        this.background = 'transparent'
        this.color = '#fff'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.playlist-detail {
  position: relative;
  min-height: 100vh;
  background: #f5f5f5;
}
.top-bg {
  min-height: 2.35 * 2rem;
  position: relative;
  .playlist-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    &::after {
      content: '';
      left: 0;
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);

      /* 背景模糊 */
      backdrop-filter: blur(10px);
      z-index: 1;
    }
  }
  .top-avatar {
    position: relative;
    z-index: 3;
  }
}

.recommend {
  background: #f5f5f5;
  width: 100%;
  border-radius: 0.4rem 0.4rem 0 0;
  padding-top: 0.24rem;
  min-height: calc(100vh - 6.2rem);
  position: relative;
  z-index: 2;
  margin-top: -0.3rem;
  .recommend-containe {
    padding: 0.32rem;
  }
  .Indicator {
    width: 0.72rem;
    height: 0.1rem;
    background-color: #333;
    border-radius: 99px;
    margin: 0 auto;
  }
}
</style>
