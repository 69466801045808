<template>
  <div class="video-title">
    <!-- 标题 -->
    <div class="video-name">
      <VideoType :data="videoInfo"></VideoType>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ videoInfo.title }}
    </div>
    <!-- 标签 -->
    <ul class="video-label">
      <li v-for="(item, index) in videoInfo.tagList" :key="index" class="label-item" @click="clickTag(item)">
        {{ item.name }}
      </li>
    </ul>
    <div class="video-opr">
      <div class="video-opr-left">播放量: {{ videoInfo.watchTimes | numberFilter }}</div>
      <div class="video-opr-right">
        <img
          :src="
            videoInfo.isStampede ? require('@/assets/imgs/play/unlike-a.png') : require('@/assets/imgs/play/unlike.png')
          "
          alt
          class="video-opr-down"
          @click="checkLike(videoInfo, 3)"
        />
        <div class="percent-box">
          <div class="percent-label">推荐率{{ percent + '%' }}</div>
          <progressbar :value="percent" class="progressbar" />
        </div>
        <img
          :src="videoInfo.isLike ? require('@/assets/imgs/play/like-a.png') : require('@/assets/imgs/play/like_tt.png')"
          alt
          class="video-opr-up"
          @click="checkLike(videoInfo, 2)"
        />
      </div>
    </div>
    <div class="video-opr1">
      <!-- 赞 -->
      <div class="interactive" @click="checkLike(videoInfo, 2)">
        <img v-if="videoInfo.isLike" src="@/assets/imgs/play/like-a.png" alt class="active" />
        <img v-else src="@/assets/imgs/play/like_tt.png" alt />
        <div>{{ videoInfo.likes | numberFilter }}</div>
      </div>
      <!-- 分享 -->
      <div class="interactive comments" @click="clickShare">
        <img src="@/assets/imgs/play/share.png" alt />
        <div>分享</div>
      </div>
      <!-- 收藏 -->
      <div class="interactive" @click="checkLike(videoInfo, 1)">
        <img v-if="videoInfo.isCollect" src="@/assets/imgs/play/collectIcon.png" alt class="active" />
        <img v-else src="@/assets/imgs/play/collectIconS.png" alt />
        <div>{{ videoInfo.collects | numberFilter }}</div>
      </div>
      <img
        v-if="!videoInfo.isAddedPlaylist && !isInLocalPlayListData"
        class="addPlaylist"
        src="@/assets/imgs/play/add_play_list.png"
        alt
        @click="addPlaylistFn()"
      />
      <img v-else class="addPlaylist" src="@/assets/imgs/play/added_play_list.png" alt />
      <!-- 分享弹窗 -->
      <BlShare
        :isShow.sync="showShare"
        :options="options"
        @select="shareBtn"
        @cancel="
          e => {
            showShare = e
          }
        "
      />
      <SharePopup
        :sharePopShow="showCommunityShare"
        @close="
          e => {
            showCommunityShare = e
          }
        "
      />
      <AddPlaylistPopup v-model="showPop" :type="1" :objectId="videoInfo.id || 0" @success="$emit('updateVideoInfo')" />
    </div>
  </div>
</template>
<script>
import { collect } from 'api/home'

export default {
  name: 'PlayInfoOpr',
  components: {
    BlShare: () => import('@/components/Share/index.vue'),
    Progressbar: () => import('@/components/Progressbar.vue'),
    SharePopup: () => import('@/components/SharePopup.vue'),
    AddPlaylistPopup: () => import('@/components/Popup/addPlaylistPop.vue'),
    VideoType: () => import('@/components/VideoType.vue')
  },
  props: {
    videoInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showShare: false,
      showCommunityShare: false,
      showPop: false,
      options: [
        [
          { name: '社区分享', icon: require('@/assets/imgs/share/share.png') },
          { name: '添加到播单', icon: require('@/assets/imgs/share/playlist.png') }
        ],
        [
          { name: '微信', icon: require('@/assets/imgs/share/wechat.png') },
          { name: '朋友圈', icon: require('@/assets/imgs/share/friends.png') },
          { name: '微博', icon: require('@/assets/imgs/share/weibo.png') },
          { name: '复制链接', icon: require('@/assets/imgs/share/link.png') }
        ]
      ]
    }
  },
  computed: {
    percent() {
      if (!this.videoInfo.likes && !this.videoInfo.stampede) {
        return 100
      }
      return ((this.videoInfo.likes / (this.videoInfo.stampede + this.videoInfo.likes)) * 100).toFixed(0)
    },
    getLocalPlayListData({ $store }) {
      return JSON.parse(JSON.stringify($store.getters['getPlayListData']))
    },
    isInLocalPlayListData() {
      return this.getLocalPlayListData.some(item => {
        return item.mediaIds.some(mediaId => mediaId === this.videoInfo.id)
      })
    }
  },
  mounted() {
    console.log(this.videoInfo, 'vvv')
  },
  methods: {
    doCopy(text) {
      this.$copyText(text).then(() => {
        this.$toast('复制剪贴板成功')
      })
    },
    share_fn() {
      this.showShare = true
    },
    addPlaylistFn() {
      this.showPop = true
    },
    // 视频收藏接口
    async checkLike(item, type) {
      const res = await collect({
        collectType: item.videoType === 21 ? 1 : item.videoType,
        flag: type == 1 ? !item.isCollect : type == 2 ? !item.isLike : !item.isStampede,
        object_id: item.id,
        type: type
        // type: type //1:收藏 2:点赞 3:踩
      })
      if (res.code === 200) {
        const typeMap = {
          1: { property: 'isCollect', counter: 'collects', successMsg: '收藏成功', failMsg: '收藏取消' },
          2: { property: 'isLike', counter: 'likes', successMsg: '点赞成功', failMsg: '点赞取消' },
          3: { property: 'isStampede', counter: 'stampede', successMsg: '踩踏成功', failMsg: '踩踏取消' }
        }
        if (typeMap.hasOwnProperty(type)) {
          const { property, counter, successMsg, failMsg } = typeMap[type]
          item[property] = !item[property]
          if (this.videoInfo[property]) {
            item[counter] += 1
            return this.$toast(successMsg)
          } else {
            item[counter] -= 1
            return this.$toast(failMsg)
          }
        }
      } else {
        return this.$toast(res.tip)
      }
    },
    async clickShare(item) {
      this.showShare = true
    },
    // 点击分享按钮
    shareBtn({ name }, index) {
      if (name == '社区分享') {
        this.showShare = false
        this.showCommunityShare = true
      } else if (name == '添加到播单') {
        this.showShare = false

        this.showPop = true
      } else {
        this.doCopy(
          this.shareUrl +
            '/#' +
            this.$route.path +
            '?pc=' +
            this.$store.getters['getUserInfo'].inviteCode +
            '&dc=h5share'
        )
      }
    },
    clickTag(item) {
      if (item.id) {
        this.$router.push({
          path: '/home/videoTagDetail',
          query: {
            id: item.id,
            name: item.name
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.video-title {
  padding: 0;

  .video-name {
    position: relative;
    margin: 0;
    font-size: 0.28rem;
  }

  .video-label {
    margin-top: 0.36rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;

    .label-item {
      border-radius: 0.08rem;
      background: rgba(221, 0, 27, 0.1);
      padding: 0.04rem 0.12rem;
      font-size: 0.24rem;
      font-weight: 400;
      color: #e8619d;
    }
  }

  .video-opr {
    margin-top: 0.36rem;
    @include flexbox(space-between);

    .video-opr-left {
      font-size: 0.24rem;
      line-height: 0.36rem;
      color: #999999;
    }

    .video-opr-right {
      @include flex-center;

      .video-opr-down {
        margin-right: 0.08rem;
        width: 0.36rem;
        height: 0.36rem;
      }

      .percent-box {
        @include flexbox($jc: flex-start, $ai: center);
        flex-direction: column;
        color: #ff4040;

        .progressbar {
          width: 1.4rem;
          height: 0.04rem;
        }

        .percent-label {
          margin-top: 0.04rem;
          font-size: 0.2rem;
          line-height: 0.26rem;
        }
      }

      .video-opr-up {
        margin-left: 0.08rem;
        width: 0.36rem;
        height: 0.36rem;
      }
    }
  }

  .video-opr1 {
    @include flexbox(center);
    font-size: 0.28rem;
    color: #9493b1;
    margin-top: 0.36rem;

    .interactive {
      margin: 0 0.5rem;
      text-align: center;
      overflow: hidden;

      img {
        width: 0.48rem;
        height: 0.48rem;
      }

      .active {
        transform: translateY(-0.48rem);
        filter: drop-shadow(red 0 0.48rem);
      }

      div {
        font-size: 0.2rem;
        line-height: 0.31rem;
        color: #787979;
      }
    }
  }
}

.addPlaylist {
  height: 0.56rem;
}
</style>
