<template>
  <van-share-sheet
    class="md-share-popup"
    :value="isShow"
    :options="options"
    :close-on-click-overlay="false"
    @select="select"
    @cancel="cancel"
    @click-overlay="cancel"
  >
  </van-share-sheet>
</template>
<script>
export default {
  name: 'MdShare',
  props: {
    value: {
      type: Boolean,
      default() {
        return false
      }
    },
    options: {
      type: Array,
      default() {
        return [
          { name: '微信', icon: require('@/assets/imgs/share/wechat.png') },
          { name: '朋友圈', icon: require('@/assets/imgs/share/friends.png') },
          { name: '微博', icon: require('@/assets/imgs/share/weibo.png') },
          { name: '复制链接', icon: require('@/assets/imgs/share/link.png') }
        ]
      }
    },
    title: {
      type: String,
      default() {
        return '立即分享給好友'
      }
    },
    description: {
      type: String,
      default() {
        return '分享面板'
      }
    },
    isShow: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel', false)
    },
    select(option, index) {
      this.$emit('select', option, index)
    }
  }
}
</script>

<style lang="scss" scoped>
.md-share-popup {
  max-width: $pcMaxWidth;
  left: 0;
  margin: 0 auto;
  padding-top: 0.48rem;
}

:deep() {
  .van-share-sheet__options {
    padding: 0.1rem;
    justify-content: space-evenly;
  }
}
</style>
