<template>
  <div class="acgFilterSearch">
    <EBackTop title="筛选">
      <slot v-if="!showFilterResult" slot="right">
        <div class="right" @click="showFilterResult = true">重新筛选</div>
      </slot>
    </EBackTop>
    <van-popup v-model="showFilterResult" :overlay="false" class="top-pop" position="top">
      <ETabV2
        ref="tab"
        class="acgFilterSearch_tabs"
        :placeholder="false"
        background="transparent"
        :tabList="tabList"
        :activeId="activeId"
        :animated="false"
        :sizeActive="'.28rem'"
        :sticky="true"
        @change="changeId"
      >
        <template #title="{ item }">
          <div class="tba-item">
            <div>
              {{ item.name }}
            </div>
            <img
              v-if="item.id == activeId"
              class="active_icon"
              src="@/assets/imgs/acg/tabs_bot_icon_active.png"
              alt=""
            />
          </div>
        </template>
      </ETabV2>
      <!-- 选择标签组件 -->
      <div class="options-component">
        <!-- 分类 -->
        <ul class="category-list">
          <li v-for="item in categoryList" :key="item.id" @click="checkCateGoryId(item.id)">
            <EDecryptImg
              class="category-item-bg"
              :class="categoryId === item.id ? 'category-item-active' : ''"
              :imgURL="item.coverImg"
            >
            </EDecryptImg>
            <p class="title">{{ item.name }}</p>
          </li>
        </ul>
        <!-- 一级标签 -->
        <ul class="tagList">
          <li v-for="item in tagList" :key="item.id" @click="checkTagItem(item.id)">
            <p class="title" :class="parentId === item.id ? 'active' : ''">{{ item.name }}</p>
          </li>
        </ul>
        <!-- 二级标签 -->
        <ul class="secondTagList">
          <li v-for="item in secondaryTagList" :key="item.id" @click="checkSecondTags(item)">
            <p class="title" :class="hasSecondTags(item.id) ? 'active' : ''">{{ item.name }}</p>
          </li>
        </ul>
        <!-- 重置多选按钮 -->
        <div class="btn-mian">
          <p @click="clearCheck">重置多选</p>
          <p @click="submit">确定</p>
        </div>
        <!-- 多选列表 -->
        <ul v-if="secondTagsList && secondTagsList.length" class="checkSeconds">
          <li v-for="item in secondTagsList" :key="item.id">
            {{ item.name }}
            <van-icon name="clear" color="#E8619D" size="0.32rem" class="close-icon" @click="closeSecondTag(item.id)" />
          </li>
        </ul>
        <div v-else class="noData-txt">请在上方选择您想筛选的标签</div>
        <div class="line-box" @click="showFilterResult = false">
          <p class="line"></p>
        </div>
      </div>
    </van-popup>

    <!-- 筛选结果显示 -->
    <Result
      ref="resultMain"
      class="result-main"
      :type="activeId"
      :parentId="parentId"
      :secondTags="secondTags"
    ></Result>
  </div>
</template>

<script>
import { filterOption } from '@/api/acg'
export default {
  components: {
    Result: () => import('./components/result.vue')
  },
  data() {
    return {
      activeId: this.$route.params.activeId,
      tabList: [
        {
          id: 1,
          name: '漫画'
        },
        {
          id: 2,
          name: '动漫'
        },
        {
          id: 3,
          name: '有声小说'
        }
      ],

      categoryId: this.$route.params.categoryId,
      parentId: this.$route.params.parentId,
      categoryList: [],
      secondaryTagList: [],
      tagList: [],
      secondTags: this.$route.params.secondTags,
      secondTagsList: [],
      showFilterResult: false,
      showChild: false
    }
  },
  async created() {
    await this.getFilterOptions()
  },
  mounted() {},
  methods: {
    hasSecondTags(val) {
      return this.secondTags.includes(val)
    },
    checkCateGoryId(categoryId) {
      if (categoryId === this.categoryId) return
      this.categoryId = categoryId
      this.parentId = 0
      this.secondTags = []
      this.secondTagsList = []
      this.getFilterOptions()
    },
    changeId(id) {
      this.activeId = id
      this.categoryId = 0
      this.parentId = 0
      this.secondTags = []
      this.secondTagsList = []
      this.getFilterOptions()
    },
    clearCheck() {
      this.secondTags = []
      this.secondTagsList = []
    },
    checkTagItem(parentId) {
      if (parentId === this.parentId) return
      this.parentId = parentId
      this.secondTags = []
      this.secondTagsList = []
      this.getFilterOptions()
    },
    checkSecondTags(secondTga) {
      if (!this.secondTags.includes(secondTga.id)) {
        if (this.secondTags.length >= 5 || this.secondTagsList.length >= 5) {
          return this.$toast('二级标签最多选择五个！')
        }
        this.secondTags.push(secondTga.id)
        this.secondTagsList.push(secondTga)
      }
    },
    async getFilterOptions() {
      const res = await filterOption({
        type: this.activeId,
        categoryId: this.categoryId,
        parentId: this.parentId
      })
      if (res.code === 200) {
        this.categoryList = res.data.categoryList
        this.secondaryTagList = res.data.secondaryTagList
        this.tagList = res.data.tagList
      } else {
        return this.$toast(res.tip)
      }
    },
    closeSecondTag(closeId) {
      this.secondTagsList = this.secondTagsList.filter(item => {
        return item.id !== closeId
      })
      this.secondTags = this.secondTags.filter(item => {
        return item !== closeId
      })
    },
    submit() {
      this.showFilterResult = false
      this.$refs['resultMain'].getFilterResult('pull')
    }
  }
}
</script>

<style lang="scss" scoped>
.acgFilterSearch-main {
  padding: 0.28rem 0.34rem;
}
.right {
  border-radius: 0.3rem;
  background: rgba(221, 0, 27, 0.1);
  padding: 0.06rem 0.16rem;
  color: #e8619d;
}
.acgFilterSearch_tabs {
  margin-bottom: 0.24rem;
  height: 0.8rem;
  font-size: 0.28rem;
  :deep() {
    .van-tab {
      flex: none;
      font-size: 0.28rem;
    }
    .van-sticky--fixed {
      top: 1rem !important;
    }
    .van-tabs__line {
      display: none;
    }
  }
}
.tba-item {
  @include flexbox($jc: center, $fd: column);
}
.active_icon {
  width: 0.24rem;
  height: 0.06rem;
  margin-top: 0.1rem;
}
.category-list {
  @include flexbox($jc: flex-start);
  margin: 0.36rem 0 0.3rem 0;
  overflow-x: auto;
  li {
    margin-right: 0.1rem;
    .category-item-bg {
      width: 2.22rem;
      height: 0.92rem;
      border-radius: 0.16rem;
      :deep() {
        img {
          border-radius: 0.16rem;
        }
      }
    }
    .category-item-active {
      width: 2.8rem;
      height: 1.16rem;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    }
    .title {
      font-size: 0.28rem;
      color: #9a9a9a;
      margin-top: 0.06rem;
      text-align: center;
    }
  }
}
.tagList {
  @include flexbox($jc: flex-start);
  margin: 0.36rem 0 0.3rem 0;
  overflow-x: auto;
  li {
    flex: none;
  }
  .title {
    border-radius: 0.28rem;
    background: rgba(0, 0, 0, 0.03);
    padding: 0.06rem 0.46rem 0.08rem 0.46rem;
    font-size: 0.28rem;
  }
  .active {
    color: $txtActive;
    background: rgba(221, 0, 27, 0.1);
  }
}
.secondTagList {
  max-height: 3rem;
  @include flexbox($jc: flex-start, $fw: wrap);
  overflow-y: auto;
  li {
    border-radius: 0.12rem;
    margin-right: 0.16rem;
    margin-bottom: 0.24rem;
    .title {
      border: 0.5px dashed var(--2, #666);
      padding: 0.16rem;
      border-radius: 0.12rem;
    }
    .active {
      color: $txtActive;
      background: rgba(221, 0, 27, 0.1);
      border: none;
    }
  }
}
.btn-mian {
  @include flexbox($jc: flex-start);
  margin-top: 0.64rem;
  p {
    border-radius: 0.44rem;
    text-align: center;
    line-height: 0.68rem;

    &:first-child {
      width: 2rem;
      height: 0.68rem;
      border: 0.01rem solid #666666;
      margin-right: 0.32rem;
    }
    &:last-child {
      width: 4.56rem;
      height: 0.68rem;
      background: $gradientBg;
      color: #fff;
    }
  }
}
.checkSeconds {
  @include flexbox($jc: flex-start);
  border-radius: 0.24rem;
  background: rgba(0, 0, 0, 0.03);
  padding: 0.38rem 0.32rem;
  margin-top: 0.46rem;

  li {
    border: 0.5px dashed var(--2, #666);
    padding: 0.16rem;
    border-radius: 0.12rem;
    margin-right: 0.32rem;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    .close-icon {
      position: absolute;
      top: -0.2rem;
      right: -0.2rem;
    }
  }
}
.noData-txt {
  text-align: center;
  width: 6.86rem;
  height: 1.38rem;
  padding: 0rem 1.86rem 0rem 1.88rem;
  border-radius: 0.24rem;
  background: rgba(0, 0, 0, 0.03);
  line-height: 1.38rem;
  margin-top: 0.46rem;
}
.top-pop {
  margin-top: 1.1rem;
  padding: 0.28rem 0.3rem;
  border-radius: 0rem 0rem 0.36rem 0.36rem;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 3%);
  .line-box {
    .line {
      border-radius: 2rem;
      background: #333;
      width: 0.72rem;
      height: 0.1rem;
      margin: 0.32rem auto;
    }
  }
}
</style>
