<template>
  <div>
    <EBackTop title="美图主题"></EBackTop>
    <ERefreshLoad
      :finished="finished"
      :loading="loading"
      :refreshing="refreshing"
      @refreshData="refreshData"
      @moreData="moreData"
    >
      <div>
        <ETabBar
          ref="themeTabbar"
          :list="topTabList"
          :tabbar-config="tabbarConfig"
          :active-num="activeId"
          @change="tabbarChange"
        >
          <div style="margin: 0 0.32rem">
            <WaterFall :listData="photo_list" />
          </div>
        </ETabBar>
      </div>
    </ERefreshLoad>
  </div>
</template>

<script>
import { photo_topic, photo_topic_list } from '@/api/discover'
export default {
  components: {
    WaterFall: () => import('../components/waterfall.vue')
  },
  data() {
    return {
      topTabList: [],
      activeId: Number(this.$route.query.id),
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.32rem', //默认字体大小
        fontSizeActive: '0.32rem', //选中字体大小
        fontColor: '#333333', //默认字体颜色
        fontColorActive: '#E8619D', //选中字体颜色
        bg: '#fff', //默认背景色
        bgActive: '#fff', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#E8619D',
        lineWidth: '0.44rem', //下划线宽度 String
        sticky: true, //粘性布局
        offsetTop: '0rem', //距顶部距离
        tabFlex: true
      },
      pageNum: 1,
      pageSize: 10,
      loading: false, //上拉加载
      finished: false, //上拉加载完成
      refreshing: false, //下拉刷
      photo_list: []
    }
  },
  async created() {
    await this.get_tags()
    if (this.$route.query.id) {
      this.activeId = +this.$route.query.id
    }
    this.getList()
  },
  methods: {
    tabbarChange(id) {
      console.log('id', id)
      this.activeId = id
      this.photo_list = []
      this.pageNum = 1
      this.getList('pull')
    },
    async getList(type) {
      try {
        const tab = this.$refs.themeTabbar.newActive
        const res = await photo_topic_list({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          Id: this.activeId ? this.activeId : tab
        })
        if (res.code === 200) {
          this.loading = false
          this.refreshing = false
          if (type == 'pull') {
            this.photo_list = []
          }
          this.photo_list = [...this.photo_list, ...res.data.photoList]
          if (!res.data.photoList || res.data.photoList.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
          this.$toast(res.tip)
        }
      } catch (error) {
        this.loading = false
        this.refreshing = false
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试')
      }
    },
    refreshData() {
      this.finished = false
      this.refreshing = true
      this.pageNum = 1
      this.photo_list = []
      this.getList()
    },
    async moreData() {
      this.loading = true
      this.pageNum += 1
      await this.getList()
    },
    async get_tags() {
      const res = await photo_topic()
      this.topTabList = res.data
    }
  }
}
</script>

<style lang="scss" scoped></style>
