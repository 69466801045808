<template>
  <div class="announcement_list">
    <div class="top">
      <span>#Mr.{{ info.nickName }}</span>
      <span>{{ itemData.createdAt | dateFormat08 }}</span>
    </div>
    <p>{{ itemData.content }}</p>
  </div>
</template>

<script>
export default {
  props: ['itemData'],
  data() {
    return {}
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    info({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.announcement_list {
  margin: 0.32rem 0.24rem 0.24rem 0.32rem;
}
.top {
  @include flexbox();
  margin-bottom: 0.24rem;

  span {
    &:first-child {
      font-weight: 600;
    }
  }
}
p {
  font-size: 0.28rem;
  padding-bottom: 0.24rem;
  border-bottom: 0.01rem solid #f0f0f0;
}
</style>
