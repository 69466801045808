<!-- home -->
<template>
  <div>
    <EBackTop title="立即提现" @click-right="$router.push('/Mine/wihtdrawHistory')">
      <slot slot="right" name="right">
        <div class="right-txt">提现记录</div>
      </slot>
    </EBackTop>

    <div v-if="hasInfo" class="wihtdraw_main">
      <!-- 可提现金额 -->
      <div class="user_wihtdraw">
        <img src="@/assets/imgs/mine/incom_left_icon.png" alt="" />
        <span>余额(元)</span>
        <div class="wihtdraw_num">
          {{ userInfo.balance | changeGold }}
        </div>
      </div>
      <!-- 提现操作 -->
      <ul class="wihtdraw_submit">
        <li class="flex_item">
          <span class="label">提现币类:</span>
          <div class="value">人民币</div>
        </li>
        <li class="flex_item">
          <span class="label">提现金额:</span>
          <van-field v-model="form.money" placeholder="单笔提现金额范围 250-10000元" type="digit" />
        </li>
        <li class="flex_item">
          <span class="label">提现方式:</span>
          <div class="value_right blank_type">
            <img src="@/assets/imgs/mine/yin_lian.png" alt="" />
            <span>银行卡</span>
            <div class="btn" @click="$router.push('/Mine/myBlank')">
              {{ form.accountNo ? '重新选择' : '请选择银行卡' }}
            </div>
          </div>
        </li>
        <li v-if="form.accountNo" class="flex_item">
          <span class="label">银行卡号:</span>
          <div class="value_right">
            <span>6226522112211250</span>
          </div>
        </li>
        <li class="flex_item">
          <span class="label">手续费率:</span>
          <div class="value_right handling_fee">
            <span>10%</span>
            <p>本次提现手续：<span>0</span> 实际到账金额：<span>0</span></p>
          </div>
        </li>
        <li>
          <span class="label">提现规则:</span>
          <p class="tips">
            1.收款账户卡号和姓名必须一致,为避免恶意刷单,到账时间为T+15天 <br />
            2.银行卡每次提现最低400元起
          </p>
        </li>
      </ul>
      <div class="submit_btn">确认提现</div>
      <p class="bot_tip">
        提现将在15个工作日内到账，如未收到，请联系<span @click="$router.push('/Mine/setting/kf')">在线客服</span>
      </p>
    </div>
    <div v-else class="wihtdraw-none">暂无提现渠道，请稍后再来查看</div>
  </div>
</template>

<script>
import { user_income, withdrawal_info, withdrawal_submit } from '@/api/user'
export default {
  components: {},
  data() {
    return {
      title: '代理提现',
      userIncomeInfo: {},
      form: {
        accountName: undefined, // 账户持有人
        accountNo: undefined, // 交易账户
        bankName: undefined, // 银行名
        bankSite: undefined, //开户行
        money: undefined // 金额
      },
      withdrawInfo: {},
      itemInfo: {},
      hasInfo: false
    }
  },
  computed: {
    // 获取首页推荐页轮播广告
    avAD({ $store }) {
      return $store.getters['avAD']
    },

    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  async mounted() {
    await this.mineWithdrawalInfo()
    this.getUserIncome()
  },
  beforeDestroy() {},

  methods: {
    // 提现按钮
    withdrawBtn() {
      if (this.userIncomeInfo.balance <= 0) return this.$toast('您暂无可提现额度')
      if (!this.form.money) return this.$toast('请输入提现金额')
      if (this.form.money > this.userIncomeInfo.balance / 100) return this.$toast('提现数额大于可提现余额')
      if (!this.form.accountName) return this.$toast('请输入提现人姓名')
      if (!this.form.bankName) return this.$toast('请输入银行卡名称')
      if (!this.form.accountNo) return this.$toast('请输入提现账户')
      // 提现金额限制
      if (this.form.money > this.userIncomeInfo.maximum / 100 || this.form.money < this.userIncomeInfo.minimum / 100)
        return this.$toast(
          `提现金额超出提现额度限制,当前提现方式最提现额度为${this.userIncomeInfo.minimum / 100}~${
            this.userIncomeInfo.maximum / 100
          }`
        )
      this.mineWithdrawalSubmit()
    },
    // 申请提现请求
    async mineWithdrawalSubmit() {
      const res = await withdrawal_submit({
        accountName: this.form.accountName,
        accountNo: this.form.accountNo,
        bankName: this.form.bankName,
        mode: 'app',
        money: this.form.money * 100
      })
      if (res.code === 200) {
        return this.$toast(res.tip)
      } else {
        return this.$toast(res.tip)
      }
    },
    // 获取用户代理信息
    async getUserIncome() {
      const res = await user_income({})
      if (res.code === 200) {
        this.userIncomeInfo = res.data
      } else {
        return this.$toast('请求错误，请联系后台管理人员！')
      }
    },
    // 获取提现信息
    async mineWithdrawalInfo() {
      const res = await withdrawal_info()
      if (res.code === 200) {
        this.withdrawInfo = res.data.info
        if (this.withdrawInfo.list && this.withdrawInfo.list.length > 0) {
          this.withdrawInfo.list.map(item => {
            if (item.name === '银联') {
              this.hasInfo = true
            }
          })
        } else {
          this.hasInfo = false
        }
      } else {
        return this.$toast(res.tip)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wihtdraw_main {
  padding: 0.36rem;

  .user_wihtdraw {
    background: rgba(0, 0, 0, 0.04);
    width: 6.86rem;
    height: 2.5rem;
    position: relative;
    font-size: 0.28rem;
    padding: 0.26rem 0.24rem 0.36rem 0.36rem;

    img {
      position: absolute;
      left: 0;
      top: 0.2rem;
      width: 0.16rem;
      height: 0.44rem;
    }

    .wihtdraw_num {
      font-size: 0.64rem;
      opacity: 0.7;
      font-weight: 600;
    }
  }
}

.wihtdraw_submit {
  li {
    margin-top: 0.48rem;
  }
  .flex_item {
    @include flexbox($jc: flex-start);

    .value {
      color: rgba($color: #333, $alpha: 0.6);
    }
    .value_right {
      @include flexbox($jc: flex-start);

      img {
        width: 0.6rem;
        height: 0.6rem;
      }
    }
  }
}
.label {
  font-size: 0.28rem;
  font-weight: 600;
  margin-right: 0.32rem;
  flex: none;
}
:deep() {
  .van-cell {
    border-radius: 0.08rem;
    background: rgba(0, 0, 0, 0.04) !important;
  }
}
.blank_type {
  span {
    margin: 0 0.36rem 0 0.12rem;
    color: rgba($color: #333, $alpha: 0.6);
  }
  .btn {
    border-radius: 0.32rem;
    background: #ff8208;
    padding: 0.08rem 0.24rem;
    color: #fff;
  }
}
.handling_fee {
  span {
    color: #ff8208;
    margin-right: 0.3rem;
  }
}
.tips {
  color: $grey;
  margin-top: 0.26rem;
}
.submit_btn {
  background: $gradientBg;
  color: #fff;
  padding: 0.22rem 2.2rem;
  border-radius: 0.44rem;
  margin-top: 3.2rem;
  margin-bottom: 0.2rem;
  text-align: center;
  font-size: 0.36rem;
}
.bot_tip {
  text-align: center;
  span {
    color: $txtActive;
  }
}
.wihtdraw-none {
  text-align: center;
  font-size: 0.3rem;
  margin: 0.3rem 0;
  // border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0.2rem 0.26rem;
}
</style>
