<template>
  <div class="community-default">
    <div class="item-data" @click="toDetail(newData)">
      <!--      <top-avatar :data="newData" class="top-avatar" />-->
      <!-- 发布时间 -->
      <!-- <div class="watches_num">
        <p>{{ newData.base.watches }}次浏览</p>
        <p>{{ newData.base.createdAt | timeDiff }}</p>
      </div> -->
      <slot :data="newData" name="top"></slot>
      <PostItemDetail :itemData="newData"></PostItemDetail>
    </div>
    <!-- 详情图片跳转 -->
    <EDecryptImg
      v-if="newData.pictureLink"
      :img-c-d-n="imgCDN"
      class="img-link"
      :need-padding="false"
      :img-u-r-l="newData.jumpPicture"
      @click.native="txtLink(newData.pictureLink)"
    >
    </EDecryptImg>
    <!-- 详情文字跳转 -->
    <div v-if="newData.titleLink" class="txt-link" @click.stop="txtLink(newData.titleLink)">
      {{ newData.jumpTitle }}
    </div>
    <div class="contact-info" v-if="!newData.base.isBought && newData.base.price !== 0" @click="showContact(newData)">
      <img src="@/assets/imgs/coin2.png" alt="" srcset="" />
      <span>{{ newData.base.price / 100 }}金币解锁联系方式</span>
    </div>
    <template v-else>
      <div class="real-contact contact-info" v-if="newData.base.contact">
        <span>{{ newData.base.contact }}</span>
        <span @click="doCopy(newData.base.contact)">复制</span>
      </div>
    </template>
    <!-- 详情页底部 -->
    <div class="detail-foot">
      <div class="foot-l">
        <div class="item" @click="addLike(newData.base, 2)">
          <img v-if="newData.base.isLike" src="@/assets/imgs/play/likeIcon.png" alt="" />
          <img v-else src="@/assets/imgs/play/like.png" alt="" />
          {{ newData.base.likes | numberFilter }}
        </div>
        <div class="item" @click="addLike(newData.base, 1)">
          <img v-if="newData.base.isCollect" src="@/assets/imgs/play/collectIcon.png" alt="" />
          <img v-else src="@/assets/imgs/play/collectIconS.png" alt="" />
          {{ newData.base.collects | numberFilter }}
        </div>
        <div class="item" :style="{ color: '#999999' }" @click="clickShare">
          <img src="@/assets/imgs/fx.svg" alt="" />
          分享
        </div>
      </div>
    </div>
    <Share :is-show="showShare" @cancel="showShare = false" @select="selectShare" />
    <notice-coin-pop v-model="showCoinPop" @showCoin="showCoin" />
    <confirm-coin-pop v-model="showConfirmCoinPop" :price="newData.base.price" @confirm="() => shoudBuy(newData)" />
    <!-- 会员购买弹窗-->
    <BuyVipPop ref="BuyVipPop" />
    <!--  金币购买弹窗-->
    <BuyGoldPop :videoInfo="newData.base" ref="BuyGoldPop" />
  </div>
</template>

<script>
import { collect } from 'api/home'
import { community_pay } from 'api/community'
import { JumpTo } from '@/utils/utils_tools'
import { numberFilter } from '@/filters/filter'
export default {
  name: 'Default',
  components: {
    PostItemDetail: () => import('@/components/Community/postItemDetail.vue'),
    Share: () => import('@/components/Share/index.vue'),
    NoticeCoinPop: () => import('@/components/Popup/noticeCoinPop.vue'),
    ConfirmCoinPop: () => import('@/components/Popup/confirmCoinPop.vue'),
    BuyVipPop: () => import('@/components/Popup/buyVipPop.vue'),
    BuyGoldPop: () => import('@/components/Popup/buyGoldPop.vue')
  },
  filters: {
    nickNamef(v, tabList) {
      for (let index = 0; index < tabList.length; index++) {
        const element = tabList[index]
        if (v == element.id) {
          return element.name
        }
      }
    }
  },
  props: ['itemData'],
  data() {
    return {
      newData: {},
      showBuy: false,
      showPopover: false,
      spliceList: [],
      showShare: false,
      showCoinPop: false,
      showConfirmCoinPop: false
    }
  },
  computed: {
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取首页顶部视频分类导航列表
    findCategory({ $store }) {
      return $store.getters['findCategory']
    },
    // 获取首页求片id
    wantToId({ $store }) {
      return $store.getters['wantToId']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  watch: {
    itemData: {
      handler(n, o) {
        this.newData = n
        console.log(this.newData)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    showCoin() {
      this.$refs.BuyGoldPop.showClosed = true
    },
    numberFilter,
    showContact(item) {
      if (this.userInfo.balance < item.base.price) {
        this.showCoinPop = true
      } else {
        this.showConfirmCoinPop = true
      }
    },

    closed(showBuy) {
      this.showBuy = showBuy
    },
    // 删除记录点击事件
    checkItem(item) {
      item.clickMask = !item.clickMask
      if (item.clickMask) {
        if (this.spliceList.length > 0) {
          this.spliceList.map((sItem, index, arr) => {
            if (item.id === sItem.id) {
              arr.splice(index, 1, item.id)
            }
          })
          this.spliceList.push(item.id)
        } else {
          this.spliceList.push(item.id)
        }
      } else {
        this.spliceList.map((sItem, index) => {
          if (item.id === sItem.id) {
            this.spliceList.splice(index, 1)
          }
        })
      }
      this.$emit('clickItem', this.spliceList)
      this.$forceUpdate()
    },
    // 详情跳转
    toDetail(item) {
      this.$router.push(`/community/detail/${item.base.id}?type=${this.$route.path.includes('/trade') ? '1' : '2'}`)
      // if (!item.price || (item.price && item.isBuy)) {
      //   this.$router.push(`/community/detail/${item.id}`)
      // } else {
      //   return this.$toast('购买后才可查看详情！')
      // }
    },
    /**
     * 点击私信
     */
    clickPrivateLetter(data) {
      if (this.isMember) {
        this.$router.push({
          path: `/oneSession/${data.userId}`,
          query: {
            nickName: data.userName,
            avatar: data.userAvatar
          }
        })
      } else {
        this.$toast('开通会员才能发起私聊！')
      }
    },
    // 剪贴板
    doCopy(text) {
      this.$copyText(text).then(() => {
        this.$toast('复制剪贴板成功')
      })
    },
    // 点击分享按钮
    shareBtn(options, index) {
      switch (index) {
        case 0:
          let url = this.$store.getters['shareUrl'] + '/'
          if (url[url.length - 1] == '/') {
            url = url.slice(0, url.length - 1)
          }
          let str = url + '/#' + '/community/detail/' + this.newData.id
          this.doCopy(str)
          break
        default:
          this.$router.push({ path: '/mine/share' })
          break
      }
    },
    async clickShare() {
      this.showShare = true
    },
    selectShare() {
      this.showShare = false
    },
    // 添加喜欢
    async addLike(item, type) {
      // 收藏信息
      try {
        const res = await collect({
          // flag: true,
          flag: type === 1 ? !item.isCollect : !item.isLike,
          object_id: item.id,
          collectType: 5,
          type: type
        })
        if (res.code === 200) {
          this.$emit('getNewDetail')
          this.$bus.emit('getNewCommunityList')
          this.$toast(res.tip)
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试')
      }
    },

    // 帖子购买
    async goBuyPost() {
      this.showBuy = true
    },
    // 购买弹窗购买按钮
    async shoudBuy(data) {
      try {
        const res = await community_pay({
          id: data.base.id
        })
        if (res.code === 200 && res.data.code === 200) {
          this.$emit('getNewDetail')
          this.showBuy = false
          return this.$toast('购买成功')
        } else {
          return this.$toast(res.data.msg)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求错误，请稍后再试！')
      }
    },
    // 详情活动跳转
    txtLink(url) {
      JumpTo(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-info {
  padding: 0.12rem 0.16rem;
  border-radius: 0.08rem;
  border: 2px solid #e8619d;
  margin: 0 auto 0.64rem;
  width: fit-content;
  color: #e8619d;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.34rem; /* 141.667% */
  > div:nth-child(1) {
    display: flex;
    align-items: center;
  }
  img {
    width: 0.3rem;
    margin-right: 0.1rem;
  }
  .real-contact {
    > span:nth-child(1) {
      margin-right: 0.2rem;
    }
  }
}
.detail-foot {
  margin-top: 0.36rem;
  padding-left: 0.4rem;
  .foot-l {
    display: flex;
    align-items: center;
    color: #999999;
    .item {
      display: flex;
      align-items: center;
      margin-right: 0.64rem;
      font-size: 0.24rem;
    }
    img {
      width: 0.28rem;
      height: 0.28rem;
      margin-right: 0.1rem;
    }
  }
}
.callNum {
  padding: 0.2rem;
  margin: 0.23rem auto;
  font-size: 0.28rem;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    background-color: #ff669d;
    color: #000;
    padding: 0.1rem;
    border-radius: 4px;
  }
}
.callMe {
  width: 100%;
  height: 0.64rem;
  font-size: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba($color: #000000, $alpha: 0.6);
  border-radius: 4px;
}
.wantToSee {
  width: 100%;
  justify-content: center !important;
  margin-top: 0.2rem !important;
  margin-bottom: 0 !important;
}
.hotcomment {
  width: 100%;
  height: 0.58rem;
  border-radius: 5px;
  background: rgba(255, 239, 239, 0.5);
  display: flex;
  align-items: center;
  padding: 0 0.24rem;
  margin-bottom: 0.24rem;
  img {
    width: 0.6rem;
    margin-right: 0.1rem;
    margin-top: -0.02rem;
  }
  p {
    font-size: 0.25rem;
    color: #2e2e2e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.community-default {
  position: relative;

  margin-bottom: 0.2rem;
  padding-bottom: 0.3rem;
  .dplayer-wrap {
    z-index: 9999;
  }
}
.item-data {
  // width: 7rem;
  // margin: 0.25rem auto;
  // background: #000;
  border-radius: 14px;
  // 文本
  .desc-txt {
    font-size: 0.26rem;
    color: #939496;
    white-space: pre-line;
  }
  .top-avatar {
    margin-bottom: 0.24rem;
  }
}
.noDetail {
  border-bottom: 0.15rem solid #f2f2f2;
  background: #fff;
  // border-bottom: 0.01rem solid #5b5b6f;
  // 文本
  .desc-txt {
    @include textoverflow(3);
    font-size: 0.26rem;
    color: #939496;
    white-space: pre-line;
    position: relative;
  }
}

.more {
  color: $btnBg;
  font-size: 0.26rem;
  text-align: right;
  margin: 0;
}
.img-list {
  @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: wrap);
  margin: 0.3rem 0;
  .img-item {
    width: 2.26rem;
    height: 2.26rem;
    margin-right: 0.1rem;
    margin-top: 0.1rem;
  }
  .img-item:nth-child(3n) {
    margin-right: 0;
  }
}
.video-cover {
  margin: 0 auto;
}
.sm-video {
  position: relative;
  :deep() {
    .warpNoPadding {
      img {
        object-fit: contain;
        max-height: 6.9rem;
      }
    }
  }
  .van-icon-play {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transformCenter();
  }
}
// 底部信息
.footer {
  font-size: 0.24rem;
  display: flex;
  justify-content: space-between;
  // border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
  .foot-l {
    display: flex;
    align-items: center;
    color: #999999;
    .item {
      display: flex;
      align-items: center;
      margin-right: 0.64rem;
    }
    img {
      width: 0.28rem;
      height: 0.2rem;
      margin-right: 0.1rem;
    }
  }
  .foot-r {
    flex: none;
    display: flex;
    padding: 0.02rem 0.08rem;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #246bfd;
    font-size: 0.24rem;
  }

  .comment {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.2rem;
    div {
      display: flex;
      align-items: center;
    }
    img {
      margin-right: 0.1rem;
      height: 0.3rem;
    }
  }
  &-left {
    @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: nowrap);
    color: #939496;
    width: 100%;
    margin-top: 0.2rem;
    div {
      display: flex;
      align-items: center;
      width: 33.33%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 0.1rem;
        height: 0.3rem;
      }
    }
    &.wantTo {
      @include flexbox($jc: right, $ai: center, $fd: row, $fw: nowrap);
    }
  }
  &-right {
    color: #000000;
    font-size: 0.24rem;
    // background: linear-gradient(to right, #fd9c3a, #fc342d);
    background: $btnBg;
    padding: 0.03rem 0.27rem;
    border-radius: 0.34rem;

    span {
      font-size: 0.24rem;
    }
    img {
      width: 0.22rem;
      height: 0.22rem;
      margin: 0 0.03rem;
    }
  }
}
.audit-status {
  padding-top: 0.18rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ff3a3a;
  font-family: PingFang SC;
  font-size: 0.2rem;
  font-style: normal;
  font-weight: 400;
  border-top: 1px solid #4e4e4e;
  margin-top: 0.36rem;
  &.is-success {
    > span {
      &:nth-child(2) {
        border: 1px solid #e8619d;
        background: rgba(0, 246, 248, 0.15);
      }
    }
  }
  &.is-inprogress {
    > span {
      &:nth-child(2) {
        border: 1px solid #e8619d;
        background: rgba(190, 145, 85, 0.3);
      }
    }
  }

  > span {
    &:nth-child(2) {
      flex: none;
      height: 0.42rem;
      border-radius: 0.04rem;
      background: #862424;
      padding: 0 0.16rem;
      margin-left: 0.4rem;
      line-height: 0.42rem;
      color: #000;
      font-family: PingFang SC;
      font-size: 0.24rem;
      font-style: normal;
      font-weight: 400;
    }
  }
}
// 评论数量
.comments-count {
  // border-top: 0.15rem solid #f2f2f2;
  // border-bottom: 0.01rem solid #f2f2f2;
  padding: 0.14rem 0.24rem;
  width: 100%;
  font-size: 0.28rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .comments-count-l {
    color: #2e2e2e;
  }
  .comments-count-r {
    color: #bfbfbf;
  }
  span {
    margin-left: 0.1rem;
    display: inline-block;
  }
}
.img-link {
  width: 100%;
}
.txt-link {
  @include textoverflow();
  font-size: 0.26rem;
  padding: 0.02rem 0.3rem;
  text-align: center;
  width: 3.64rem;
  border: 0.02rem solid #9493b1;
  color: $btnBg;
  margin: 0.3rem auto;
  border-radius: 0.12rem;
}
.status {
  font-size: 0.24rem;
  margin-left: 0.32rem;
  width: 1.5rem;

  :deep() {
    .van-popover__wrapper {
      padding: 0;
      white-space: nowrap;
    }
  }
  span {
    border: none;
  }
  .tg {
    color: #00bc6c;
  }
  .jj {
    color: #ff1919;
  }
}
</style>
<style lang="scss">
.van-popover--dark {
  .van-popover__content {
    font-size: 0.28rem !important;
    padding: 0.1rem;
    color: #ff1919;
  }
}
// 选择视频
.sel-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: flex-end;
  border-radius: 0.12rem;
  img {
    width: 0.4rem;
    height: 0.4rem;
  }
}
.watches_num {
  // @include flexbox();
  color: rgba(255, 255, 255, 0.5);
  font-family: PingFang SC;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
