<template>
  <div>
    <EBackTop title="筛选">
      <template #right>
        <span @click="clearSearch">清除</span>
      </template>
    </EBackTop>
    <div class="photo-water">
      <div class="list-container">
        <ul>
          <li
            v-for="(item, index) in actorSearchTagList"
            :key="index"
            :class="{ active: activeIndex1 == item.id }"
            @click="handleClick1(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="tags">
        <ul>
          <li
            v-for="(item, index) in showTagList"
            :key="index"
            :class="{ active: selectedList.findIndex(v => v.id == item.id) !== -1 }"
            @click="handleClick2(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="selected-tags">
        <ul>
          <li
            v-for="(item, index) in selectedList"
            :key="index"
            :class="{ active: activeIndex2 == index }"
            @click="handleClick3(item.id)"
          >
            {{ item.name }}
            <img src="@/assets/imgs/discover/close.svg" alt="" />
          </li>
        </ul>
      </div>
      <ERefreshLoad
        :finished="finished"
        :loading="loading"
        :refreshing="refreshing"
        @refreshData="refreshData"
        @moreData="moreData"
      >
        <GirlList :mediaActorList="mediaActorList" @activeIndexChange="activeIndexChange" />
      </ERefreshLoad>
    </div>
  </div>
</template>

<script>
import { actor_search, actor_search_tag } from '@/api/discover'
export default {
  components: {
    GirlList: () => import('../components/girlList.vue')
  },
  data() {
    return {
      activeIndex1: 0,
      activeIndex2: 0,
      actorSearchTagList: [],
      selectedList: [],
      pageNum: 1,
      pageSize: 10,
      loading: false, //上拉加载
      finished: false, //上拉加载完成
      refreshing: false, //下拉刷
      mediaActorList: [],
      sort: 0
    }
  },
  computed: {
    showTagList() {
      if (!this.actorSearchTagList.length) return []
      return this.actorSearchTagList.find(v => v.id === this.activeIndex1).tagInfoList || []
    }
  },
  async created() {
    await this.getTagList()
    if (this.$route.query.id) {
      let filterItem = null
      this.actorSearchTagList.find(v => {
        v.tagInfoList.find(item => {
          if (item.id == this.$route.query.id) {
            filterItem = item
          }
        })
      })
      if (filterItem) this.selectedList.push(filterItem)
    }
    this.getList()
  },
  methods: {
    handleClick1(item) {
      this.activeIndex1 = item.id
    },
    handleClick2(item) {
      if (this.selectedList.length >= 5) {
        return this.$toast('最多只能选择 5 个')
      }
      if (this.selectedList.length) {
        const idx = this.selectedList.findIndex(v => v.id === item.id)
        if (idx !== -1) return
      }
      this.activeIndex2 = item.id

      this.selectedList.push(item)
      this.getList('pull')
    },
    handleClick3(id) {
      this.selectedList = this.selectedList.filter(v => v.id !== id)
    },
    async getTagList() {
      const res = await actor_search_tag()
      this.actorSearchTagList = res.data.actorSearchTagList
    },
    async getList(type) {
      try {
        const res = await actor_search({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          tagIds: this.selectedList.map(v => v.id),
          // tagIds: [22196],
          // actorType: 1,
          sort: this.sort
        })
        if (res.code === 200) {
          this.loading = false
          this.refreshing = false
          if (type == 'pull') {
            this.mediaActorList = []
          }
          this.mediaActorList = [...this.mediaActorList, ...res.data.mediaActorList]
          if (!res.data.mediaActorList || res.data.mediaActorList.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
          this.$toast(res.tip)
        }
      } catch (error) {
        this.loading = false
        this.refreshing = false
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试')
      }
    },
    isSelected(id) {
      const idx = this.selectedList.findIndex(v => {
        v.id === id
      })
      return idx != -1
    },
    refreshData() {
      this.finished = false
      this.refreshing = true
      this.pageNum = 1
      this.getList('pull')
    },
    async moreData() {
      this.loading = true
      this.pageNum += 1
      await this.getList()
    },
    activeIndexChange(idx) {
      this.sort = idx
      this.getList('pull')
    },
    clearSearch() {
      this.selectedList = []

      this.getList('pull')
    }
  }
}
</script>

<style lang="scss" scoped>
.photo-water {
  padding: 0.18rem 0.32rem;
  .list-container {
    @media (pointer: coarse) {
      ul::-webkit-scrollbar {
        display: initial;
      }
    }
    ul {
      scrollbar-width: none;
      /* firefox */
      -ms-overflow-style: none;
      border-radius: 0.08rem;
      /* IE 10+ */
      width: 100%;
      overflow-x: scroll;
      @include flexbox($jc: flex-start, $ai: flex-start, $fd: row, $fw: nowrap, $flex: 1);
      flex-shrink: 1;
      > li {
        min-width: 1.28rem;
        height: 0.52rem;
        background: #808080;
        border-radius: 0.08rem;
        position: relative;
        margin-right: 0.16rem;
        margin-bottom: 0.08rem;
        @include flex-center;
        color: #fff;
        padding: 0.08rem 0.16rem;
        &.active {
          background-color: #e8619d;
        }
      }
    }
  }
  .tags {
    padding: 0.24rem 0;
    border-bottom: 0.02rem solid #f0f0f0;
    > ul {
      display: flex;
      flex-wrap: wrap;
      > li {
        padding: 0.08rem 0.16rem;
        border-radius: 0.08rem;
        background: #f6f6f6;
        margin-right: 0.19rem;
        &.active {
          background: rgba(221, 0, 27, 0.15);
          color: #e8619d;
        }
      }
    }
  }
  .selected-tags {
    padding: 0.26rem 0 0.34rem;
    > ul {
      display: flex;
      flex-wrap: wrap;
      > li {
        padding: 0.08rem 0.16rem;
        border-radius: 0.08rem;
        background: rgba(221, 0, 27, 0.15);
        @include flex-center;
        margin-right: 0.38rem;
        margin-bottom: 0.28rem;
        position: relative;
        > img {
          position: absolute;
          right: -0.1rem;
          top: -0.1rem;
          width: 0.32rem;
        }
      }
    }
  }
}
</style>
