<template>
  <div class="item" @click="toPlay(data)">
    <div class="cover">
      <ESCVideoCover class="item-bg" :imgURL="data.coverImg" :videoURL="videoURL">
        <!-- 视频分类标签 -->
        <VideoType :data="data"></VideoType>
      </ESCVideoCover>
      <VideoCardMask :data="data"></VideoCardMask>
    </div>
    <VideoCardFooter :data="data"></VideoCardFooter>
  </div>
</template>

<script>
//横排模块长视频卡片
export default {
  components: {
    VideoType: () => import('@/components/VideoType.vue'),
    VideoCardFooter: () => import('@/components/Home/VideoCardFooter.vue'),
    VideoCardMask: () => import('@/components/Home/VideoCardMask.vue')
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      videoPath: '/api/app/media/m3u8pre/'
    }
  },
  computed: {
    token({ $store }) {
      return $store.state.user.token
    },
    videoURL() {
      if (this.data.preVideoUrl) {
        return this.videoPath + this.data.id + '?token=' + this.token
      } else {
        return ''
      }
    }
  },
  mounted() {},

  methods: {
    toPlay(data) {
      if (data) {
        this.$router.push(`/play/longVideo/${data.id}`)
      }
    },
    clickTag(item) {
      if (item.id) {
        this.$router.push({
          path: '/home/videoTagDetail',
          query: {
            id: item.id,
            name: item.name
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.item {
  width: 1.36 * 2rem;
  //height: 1.44 * 2rem;

  .cover {
    width: 100%;
    height: 0.76 * 2rem;
    border-radius: 0.12rem 0.12rem 0 0;
    overflow: hidden;
    position: relative;
  }
}
</style>
