<template>
  <div class="photo-station">
    <EBackTop title="套图站"></EBackTop>
    <ETabBar
      ref="photoTabbar"
      :list="topTabList"
      :tabbar-config="tabbarConfig"
      :active-num="activeId"
      @change="tabbar_change"
    >
      <template #default="scope">
        <ERefreshLoadV3
          ref="ERefreshLoadV3"
          :autoOnload="autoOnload"
          :finished="finished"
          :beforeRefresh="refreshData"
          :moreData="moreData"
        >
          <div class="round-tab">
            <div>
              <span :class="{ active: activeTop == 0 }" @click="handleClickTop(0)">图集</span>
              <span :class="{ active: activeTop == 1 }" @click="handleClickTop(1)">网黄</span>
            </div>
          </div>
          <div class="sidebar-tab-conent">
            <van-sidebar v-model="activeKeyDefault">
              <van-sidebar-item
                v-for="item in fixedList.concat(topTabList[scope.index].tagInfoList)"
                :key="item.id"
                :title="item.name"
                @click="sidebar_change(item)"
              ></van-sidebar-item>
            </van-sidebar>
            <div class="content">
              <WaterFall v-if="activeTop == 0" :listData="showlist" />
              <ModelList v-else :showlist="showlist" />
            </div>
          </div>
        </ERefreshLoadV3>
      </template>
    </ETabBar>
  </div>
</template>

<script>
import { photo_station, photo_station_tags } from '@/api/discover'

export default {
  components: {
    ModelList: () => import('../components/modelList.vue'),
    WaterFall: () => import('../components/waterfall.vue')
  },
  data() {
    return {
      topTabList: [],
      activeId: 0,
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.32rem', //默认字体大小
        fontSizeActive: '0.32rem', //选中字体大小
        fontColor: '#333333', //默认字体颜色
        fontColorActive: '#E8619D', //选中字体颜色
        bg: '#fff', //默认背景色
        bgActive: '#fff', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#E8619D',
        lineWidth: '0.44rem', //下划线宽度 String
        sticky: true, //粘性布局
        offsetTop: '0rem', //距顶部距离
        tabFlex: true,
        animated: false
      },
      activeKey: 2,
      activeKeyDefault: 1,
      activeTop: 0,
      pageNum: 0,
      pageSize: 10,
      finished: false, //上拉加载完成
      // tagsId: [],
      showlist: [],
      autoOnload: true,
      fixedList: [
        { id: 1, name: '我的收藏' },
        { id: 2, name: '最新上架' },
        { id: 3, name: '推荐' }
      ]
    }
  },
  computed: {},
  async created() {
    await this.get_tags()
  },
  methods: {
    async sidebar_change(item) {
      this.activeKey = item.id
      this.autoOnload = true
      this.$refs.ERefreshLoadV3.onRefresh()
    },
    async tabbar_change() {
      this.activeKey = 2
      this.activeKeyDefault = 1
      this.pageNum = 1
      this.autoOnload = false
      this.showlist = []
      await this.getList('pull')
    },
    async handleClickTop(idx) {
      this.activeTop = idx
      this.autoOnload = true
      this.$refs.ERefreshLoadV3.onRefresh()
    },
    async get_tags() {
      const res = await photo_station_tags()
      this.topTabList = res.data
      this.activeId = this.topTabList[0].id
    },
    async getList(type) {
      try {
        const res = await photo_station({
          categoryId: this.activeId,
          contentType: this.activeTop,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          tagsId: this.activeKey
        })
        if (res.code === 200) {
          if (this.activeTop == 1) {
            if (res.data.actorList) {
              this.showlist = [...this.showlist, ...res.data.actorList]
            }
            //  图片超出6张只显示6张
            if (!res.data.actorList || res.data.actorList?.length < this.pageSize) {
              this.finished = true
            }
          } else {
            if (res.data.photoList) {
              if (res.data.photoList) {
                this.showlist = [...this.showlist, ...res.data.photoList]
              }
            }
            //  图片超出6张只显示6张
            if (!res.data.photoList || res.data.photoList?.length < this.pageSize) {
              this.finished = true
            }
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      } catch (error) {
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试')
      }
    },
    async refreshData() {
      this.pageNum = 0
      this.showlist = []
    },
    async moreData() {
      this.pageNum += 1
      await this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.photo-station {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  :deep() .van-tabs__nav {
    //justify-content: center;
  }
}

.photo-water {
  padding: 0.4rem 0.32rem;
}

:deep() .van-tabs__nav {
  overflow-x: auto;
  @include scrollbar-hide;
}

.pull-container {
}

.van-sidebar {
  width: 1.44rem;

  .van-sidebar-item {
    padding: 0.16rem 0.24rem;
    @include flex-center;
    background-color: #fff;
    white-space: nowrap;

    &.van-sidebar-item--select {
      background: rgba(221, 0, 27, 0.1);

      &::before {
        width: 0px;
      }
    }
  }
}

.round-tab {
  @include flex-center;
  height: 0.8rem;
  background: #f7f7f7;

  > div {
    width: 1.92rem;
    height: 0.4rem;
    border-radius: 0.21rem;
    background: rgba(0, 0, 0, 0.05);
    display: flex;

    > span {
      color: #999;
      font-size: 0.24rem;
      font-weight: 400;
      flex: 1;
      @include flex-center;
      border-radius: 0.27rem;
      transition: all 0.5s;

      &.active {
        background: #e8619d;
        color: $white;
      }
    }
  }
}

.sidebar-tab-conent {
  display: flex;

  .content {
    flex: 1;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;

    > li {
      width: 1.94rem;
      background-color: red;
      margin-right: 0.1rem;
      margin-bottom: 0.08rem;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
