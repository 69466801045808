<template>
  <div
    class="one-cartoon-card"
    :style="{
      width: sizeStyle.width
    }"
    @click="toPlay"
  >
    <EDecryptImg class="one-cartoon-card_bg" :style="sizeStyle" :imgURL="data.coverImg ? data.coverImg : ''">
      <!-- 视频分类标签 -->
      <!-- <VideoType :data="data"></VideoType> -->
    </EDecryptImg>
    <div class="title">
      <img src="@/assets/imgs/acg/icon-park-outline_movie.png" alt="" />
      <span>{{ data.title }}</span>
    </div>
  </div>
</template>

<script>
import sizeMap from '@/components/Home/showType/dreamType/sizeMap.js'
export default {
  components: {
    // VideoType: () => import('@/components/VideoType.vue')
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    sizeType: {
      type: Number,
      default: 1
    }
  },
  computed: {
    sizeStyle() {
      return sizeMap[this.sizeType]
    }
  },
  methods: {
    toPlay() {
      this.$router.push(`/play/longVideo/${this.data.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.one-cartoon-card {
  flex-shrink: 0;
  flex-grow: 0;

  .one-cartoon-card_bg {
    position: relative;
    border-radius: 0.08rem;

    :deep() {
      img {
        border-radius: 0.08rem;
      }
    }

    .collect_icon {
      position: absolute;
      width: 0.58rem;
      height: 0.58rem;
      top: 0;
      right: 0;
    }
  }

  .title {
    height: 0.7rem;
    margin: 0.12rem 0 0 0.08rem;
    @include flexbox($jc: flex-start);
    color: #333;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @include textoverflow(2);

    img {
      width: 0.28rem;
      height: 0.28rem;
      margin-right: 0.12rem;
    }
  }
}
</style>
