<template>
  <ul class="sidebar-tab-conent-list">
    <li v-for="item in showlist" :key="item.id" @click="handleClick(item.id)">
      <div class="top">
        <!-- <img class="bg-img" src="@/assets/imgs/temp/301.png" alt="" /> -->
        <EDecryptImg :imgCDN="imgCDN" class="bg-img" :imgURL="item.cover || item.avatar" />
        <div class="top-poa"></div>
        <div class="bottom-poa">
          <div class="left">
            <img src="@/assets/imgs/discover/love.png" alt="" />
            <span>{{ item.real.Collects }}</span>
          </div>
          <div class="right">
            <img src="@/assets/imgs/discover/thumbs-up.svg" alt="" />
            <span>{{ item.real.Likes }}</span>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="text">{{ item.name }}</div>
        <div class="imgs">
          <div>
            <img src="@/assets/imgs/discover/video-red.png" alt="" />
            <span>{{ item.movieCount }}</span>
          </div>
          <div>
            <img src="@/assets/imgs/discover/photo-red.png" alt="" />
            <span>{{ item.photoCount }}</span>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    showlist: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      topTabList: [
        {
          id: 1,
          name: '影视'
        },
        {
          id: 2,
          name: '图集'
        },
        {
          id: 3,
          name: '帖子'
        },
        {
          id: 4,
          name: '播单'
        },
        {
          id: 5,
          name: '女优'
        },
        {
          id: 45,
          name: '影视'
        },
        {
          id: 455,
          name: '图集'
        },
        {
          id: 86,
          name: '帖子'
        },
        {
          id: 95,
          name: '播单'
        },
        {
          id: 54,
          name: '女优'
        }
      ]
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    handleClick(id) {
      this.$router.push(`/discover/actor/${id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-tab-conent-list {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  // height: calc(100vh - 52px - 0.88rem - 0.8rem - 0.1rem);
  padding-top: 0.1rem;

  > li {
    width: 1.94rem;
    margin-right: 0.1rem;
    margin-bottom: 0.08rem;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .bg-img {
    width: 100%;
    border-radius: 0.08rem 0.08rem 0 0;
  }

  .top {
    position: relative;

    .default {
      width: 1.96rem;
      height: 1.96rem;

      :deep() img {
        border-radius: 0.08rem 0.08rem 0 0;
      }
    }
  }

  .bottom-poa {
    position: absolute;
    color: $white;
    left: 0;
    bottom: 0;
    @include flexbox;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    opacity: 0.775;
    color: #fff;
    font-size: 0.2rem;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    padding: 0 0.08rem 0.08rem;

    > div {
      @include flex-align-center;
    }

    img {
      margin-right: 0.04rem;
      width: 0.26rem;
    }
  }

  .bottom {
    background: #f7f7f7;
    padding-top: 0.1rem;
    font-size: 0.2rem;
    border-radius: 0 0 0.08rem;

    > div {
      &:nth-child(1) {
        color: #000;
        font-weight: 400;
        text-align: center;
      }

      img {
        width: 0.2rem;
      }

      &:nth-child(2) {
        color: #999;
        font-weight: 400;
        text-align: center;
      }
    }

    .imgs {
      margin-top: 0.12rem;
      padding: 0 0.08rem 0.08rem;
      @include flexbox;

      > div {
        @include flex-align-center;

        img {
          margin-right: 0.04rem;
        }
      }
    }
  }
}
</style>
