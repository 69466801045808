<template>
  <div class="photo-water">
    <div class="list-container">
      <ul>
        <li
          v-for="(item, index) in actorSearchTagList"
          :key="index"
          :class="{ active: activeIndex1 === item.id }"
          @click="handleClick1(item)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="tags">
      <ul>
        <li
          v-for="(item, index) in showTagList"
          :key="index"
          :class="{ active: value.findIndex(v => v.id === item.id) !== -1 }"
          @click="handleClick2(item)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="selected-tags">
      <ul>
        <li
          v-for="(item, index) in value"
          :key="index"
          :class="{ active: activeIndex2 === index }"
          @click="handleClick3(item.id)"
        >
          {{ item.name }}
          <img src="@/assets/imgs/discover/close.svg" alt="" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { actor_search_tag } from '@/api/discover'
export default {
  name: 'TagSelect',
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeIndex1: 0,
      activeIndex2: 0,
      actorSearchTagList: []
    }
  },
  computed: {
    showTagList() {
      if (!this.actorSearchTagList.length) return []
      return this.actorSearchTagList.find(v => v.id === this.activeIndex1).tagInfoList || []
    }
  },
  created() {
    this.getTagList()
  },
  methods: {
    handleClick1(item) {
      this.activeIndex1 = item.id
    },
    handleClick2(item) {
      if (this.value.length >= 5) {
        return this.$toast('最多只能选择 5 个')
      }
      if (this.value.length) {
        const idx = this.value.findIndex(v => v.id === item.id)
        if (idx !== -1) return
      }
      this.activeIndex2 = item.id

      const value = [...this.value, item]
      this.$emit('input', value)
    },
    handleClick3(id) {
      const value = this.value.filter(v => v.id !== id)
      this.$emit('input', value)
    },
    async getTagList() {
      const res = await actor_search_tag()
      this.actorSearchTagList = res.data.actorSearchTagList
    }
  }
}
</script>

<style lang="scss" scoped>
.photo-water {
  padding: 0.18rem 0.32rem;
  .list-container {
    @media (pointer: coarse) {
      ul::-webkit-scrollbar {
        display: initial;
      }
    }
    ul {
      scrollbar-width: none;
      /* firefox */
      -ms-overflow-style: none;
      border-radius: 0.08rem;
      /* IE 10+ */
      width: 100%;
      overflow-x: scroll;
      @include flexbox($jc: flex-start, $ai: flex-start, $fd: row, $fw: nowrap, $flex: 1);
      flex-shrink: 1;
      > li {
        min-width: 1.28rem;
        height: 0.52rem;
        background: #808080;
        border-radius: 0.08rem;
        position: relative;
        margin-right: 0.16rem;
        margin-bottom: 0.08rem;
        @include flex-center;
        color: #fff;
        padding: 0.08rem 0.16rem;
        &.active {
          background-color: #e8619d;
        }
      }
    }
  }
  .tags {
    padding: 0.24rem 0;
    border-bottom: 0.02rem solid #f0f0f0;
    > ul {
      display: flex;
      flex-wrap: wrap;
      > li {
        padding: 0.08rem 0.16rem;
        border-radius: 0.08rem;
        background: #f6f6f6;
        margin-right: 0.19rem;
        &.active {
          background: rgba(221, 0, 27, 0.15);
          color: #e8619d;
        }
      }
    }
  }
  .selected-tags {
    padding: 0.26rem 0 0.34rem;
    > ul {
      display: flex;
      flex-wrap: wrap;
      > li {
        padding: 0.08rem 0.16rem;
        border-radius: 0.08rem;
        background: rgba(221, 0, 27, 0.15);
        @include flex-center;
        margin-right: 0.38rem;
        margin-bottom: 0.28rem;
        position: relative;
        > img {
          position: absolute;
          right: -0.1rem;
          top: -0.1rem;
          width: 0.32rem;
        }
      }
    }
  }
}
</style>
