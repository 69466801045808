<template>
  <div class="community-default">
    <div class="item-data" @click="toDetail(data)">
      <slot name="top" :data="data"></slot>
      <post-item :item-data="data"></post-item>
      <!-- 底部信息展示 -->
      <post-item-opr :data="data" />
      <AuditStatus v-if="isShowAudit"> </AuditStatus>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostItemWithOpr',
  components: {
    PostItemOpr: () => import('@/components/Community/postItemOpr.vue'),
    postItem: () => import('@/components/Community/postItem.vue'),
    AuditStatus: () => import('@/components/Community/auditStatus.vue')
  },
  filters: {},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isShowAudit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showBuy: false,
      showPopover: false,
      spliceList: [],
      showShare: false
    }
  },
  computed: {
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },

  methods: {
    // 详情跳转
    toDetail(item) {
      this.$router.push(`/community/detail/${item.base.id}?type=${this.$route.path.includes('/trade') ? '1' : '2'}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.community-default {
  position: relative;

  margin-bottom: 0.2rem;
  padding-bottom: 0.3rem;
}
</style>
<style lang="scss">
.van-popover--dark {
  .van-popover__content {
    font-size: 0.28rem !important;
    padding: 0.1rem;
    color: #ff1919;
  }
}
</style>
