<template>
  <ERefreshLoadV3 ref="ActorSearchList" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
    <div class="content">
      <div v-for="(item, index) in actorList" :key="index">
        <LadyListType3 :data="item"></LadyListType3>
      </div>
    </div>
  </ERefreshLoadV3>
</template>

<script>
import { search } from 'api/search'
export default {
  name: 'VideoSearch',
  components: {
    LadyListType3: () => import('@/components/Home/showType/ladyListType/ladyListType3.vue')
  },
  props: ['navItem', 'id'],
  data() {
    return {
      pageNum: 0,
      pageSize: 10,
      content: '',
      finished: false,
      actorList: []
    }
  },

  mounted() {},

  methods: {
    //设置搜索参数
    setContent(val) {
      this.content = val
      console.log('女优列表刷新')
      this.$refs['ActorSearchList'].onRefresh()
    },

    async onLoad() {
      this.pageNum += 1
      await this.getSeach()
    },
    async onRefresh() {
      this.finished = false
      //表示处于可以下拉状态
      this.pageNum = 0
      this.actorList = []
    },

    // 获取数据
    async getSeach() {
      await search({
        type: this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        content: this.content
      }).then(res => {
        if (res.code === 200 && res.data.actorList) {
          this.actorList = [...this.actorList, ...res.data.actorList]
          if (res.data.actorList.length < this.pageSize || !res.data.actorList) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-flow: wrap;
  margin-top: 0.24rem;
  padding: 0 0.32rem;
}
</style>
