<template>
  <div class="app-container">
    <!-- 锁屏 -->
    <GestureLock v-if="showGestureLock" @closeEvent="closeEvent"></GestureLock>
    <div v-if="!splashShow" class="layout-content">
      <!-- <Head v-if="!$route.meta.noHasHeader" @clickHeader="$router.push(`/hot_tag`)" /> -->
      <keep-alive>
        <router-view v-if="isRouterAlive && $route.meta.keepAlive && !isFirstLogin"></router-view>
      </keep-alive>
      <router-view v-if="isRouterAlive && !$route.meta.keepAlive && !isFirstLogin"></router-view>
    </div>
    <div v-if="$route.meta.hasFooter" class="layout-footer">
      <EFootBar :data="tabbars" height="auto" :placeholder="false" background="#FFF" @change="handleChange" />
    </div>

    <!-- 应用列表弹窗 -->
    <van-popup
      v-if="lotteryAd && lotteryAd.length > 0 && getshowAppList"
      v-model="getshowAppList"
      class="app-list-pop"
      @close="closeAppLisy"
    >
      <EADSwiper
        :list="lotteryTopAd"
        class="swiper"
        keyName="cover"
        :EADconfig="EADconfig"
        :imgCDN="imgCDN"
        @clickImg="clickImg"
      >
      </EADSwiper>
      <ul class="app-list">
        <li v-for="(item, index) in lotteryAd" :key="index" @click="clickItem(item)">
          <EDecryptImg :imgCDN="imgCDN" :imgURL="item.avatar" class="app_item"> </EDecryptImg>
          <div class="btn">立即下载</div>
        </li>
        <li></li>
        <li></li>
      </ul>
      <div class="close_btn" @click.stop="closeAppLisy"><van-icon name="close" size="40" color="#fff" /></div>
    </van-popup>

    <!-- 活动公告弹窗 showAnnouncemnetPop-->
    <van-popup v-model="showAnnouncemnetPop" class="account-img-pop">
      <div class="account-img">
        <div class="topText">-- 活动公告 --</div>
        <div class="titlePack" @click="submit">
          <p v-if="announcement && announcement?.title" class="title">{{ announcement.title }}</p>
          <p v-if="announcement && announcement?.content" class="desc">{{ announcement.content }}</p>
        </div>
        <div class="btn" @click="closeAccountImg"></div>
      </div>
    </van-popup>

    <!--启动页广告-->
    <div v-if="splashShow" class="splashAD">
      <van-button v-if="msg && !isShowQd" class="splash-close" plain type="info" @click="closeSplashAD">
        {{ msg }}</van-button
      >
      <EADSwiper
        v-if="splashAD.length > 0 && !isShowQd"
        :height="'100vh'"
        class="top-swiper"
        :list="newSplashAD"
        :EADconfig="EADconfig"
        keyName="cover"
        :imgCDN="imgCDN"
        @clickImg="clickImg"
      >
      </EADSwiper>
      <img v-else class="splash-ad-imgs splashAD" src="../../assets/imgs/qidong.png" alt="" />
    </div>

    <van-overlay z-index="10000" :show="splashStaticShow">
      <van-button v-if="msg" class="splash-close" plain type="info" @click="closeSplashAD"> {{ msg }}</van-button>
      <img class="splash-ad-imgs splashAD" src="../../assets/imgs/qidong.png" alt="" />
    </van-overlay>
    <!-- 首页弹窗 -->
    <ActivityPopup v-if="showActivityImg" />
    <!-- 下载按钮 -->
    <!-- <div v-if="showBtn" class="downApk" @click="downApk">
      <img src="@/assets/imgs/link.svg" alt="" />
      点击下载好色App
    </div> -->
  </div>
</template>

<script>
import { downApp, appsClick, advertiseClick } from 'api/home'
import { JumpTo } from '@/utils/utils_tools'
export default {
  name: 'AppLayout',
  components: {
    EFootBar: () => import('@/components/web_base/component_base/EFootBar/index.vue'),
    ActivityPopup: () => import('./activityPopup.vue'),
    GestureLock: () => import('@/components/GestureLock.vue')
    // Head: () => import('@/components/Home/header.vue'),
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isShowQd: true,
      showBtn: false,
      newSplashAD: [],
      msg: '',
      splashShow: false,
      // showPageArray:['/home','/comics','/community','/mine'],
      showActivityImg: false,
      tabbars: [
        {
          title: '首页',
          to: {
            name: 'Home'
          },
          icon: {
            active: require('assets/imgs/footer/home_active.png'),
            defaultActive: require('assets/imgs/footer/home_default.png')
          }
        },

        {
          title: '二次元',
          to: {
            name: 'Acg'
          },
          icon: {
            active: require('assets/imgs/footer/acg_active.png'),
            defaultActive: require('assets/imgs/footer/acg_default.png')
          }
        },
        {
          title: '社区',
          to: {
            name: 'Community'
          },
          icon: {
            active: require('assets/imgs/footer/community_active.png'),
            defaultActive: require('assets/imgs/footer/community_default.png')
          }
        },
        {
          title: '发现',
          to: {
            name: 'Discover'
          },
          icon: {
            active: require('assets/imgs/footer/discover_active.png'),
            defaultActive: require('assets/imgs/footer/discover_default.png')
          }
        },
        {
          title: '我的',
          to: {
            name: 'Mine'
          },
          icon: {
            active: require('assets/imgs/footer/mine_active.png'),
            defaultActive: require('assets/imgs/footer/mine_default.png')
          }
        }
      ],
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      },
      showAnnouncemnetPop: false, //公告弹窗开关
      getshowAppList: false,
      splashStaticShow: true,
      isRouterAlive: true
    }
  },
  computed: {
    showGestureLock({ $store }) {
      return $store.getters['getShowGestureLock']
    },
    // 启动页面
    hasSplashStatus() {
      return this.$store.getters['hasSplashStatus']
      // return false
    },
    splashAD({ $store }) {
      return $store.getters['splashAD']
    },
    isZsMember({ $store }) {
      return $store.getters['isZsMember']
    },
    // 获取公告弹窗开关
    announcementPop({ $store }) {
      return $store.getters['announcementPop']
    },
    // 获取公告内容开关
    announcement({ $store }) {
      return $store.getters['announcement']
    },
    // 获取首页活动弹窗内容
    activityImg({ $store }) {
      return $store.getters['popAD']
    },
    // 获取首页活动内容弹窗开关
    activityPop({ $store }) {
      return $store.getters['activityPop']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取应用列表头部广告
    lotteryTopAd({ $store }) {
      return $store.getters['gridTopAD']
    },
    // 获取应用列表
    lotteryAd({ $store }) {
      return $store.getters['iconNineListAds']
    },
    config({ $store }) {
      return $store.getters['config']
    },
    // 是否第一次登陆
    isFirstLogin({ $store }) {
      return $store.getters['user/getFirstLogin']
    }
  },
  watch: {
    splashShow: {
      handler(newval) {
        if (newval) {
          this.$nextTick(() => {
            // this.splashStaticShow = false
          })
        }
      },
      immediate: true
    }
  },
  created() {
    if (window.localStorage.getItem('hs_lockScreen') === 'true') {
      this.$store.dispatch('setShowGestureLock', true)
    }
    this.getshowAppList = this.$store.state.shortVideo.showAppList
    let isIos = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
    let isWebApp = navigator.standalone
    if (isIos) {
      if (this.isSafari()) {
        this.showBtn = true
        if (isWebApp) {
          this.showBtn = false
        }
      } else {
        this.showBtn = false
      }
    } else {
      this.showBtn = true
    }
    // 如果参数中含有dc则因此
    if (this.$route.query.dc) {
      this.showBtn = false
    }
    setTimeout(() => {
      this.isShowQd = false
    }, 2000)
    if (!this.isFirstLogin) {
      this.setAllDialog()
    }
  },
  methods: {
    // 解锁页面关闭各种广告弹窗
    closeEvent() {
      this.splashShow = false
      this.closeAppLisy()
      this.closeAccountImg()
      this.closeActivityImg()
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    // app.vue里面进行了ref调用！！！！
    setAllDialog() {
      this.getshowAppList = this.$store.state.shortVideo.showAppList
      if (this.hasSplashStatus && !this.isFirstLogin) {
        let newArr = JSON.parse(JSON.stringify(this.splashAD))
        this.newSplashAD = newArr
        this.splashShow = true
        this.$nextTick(() => {
          this.splashStaticShow = false
        })
      }
      this.$nextTick(() => {
        let i = 3
        this.timer = setInterval(() => {
          i--
          this.msg = i + 's'
          if (i <= 0) {
            this.msg = '跳过'
            clearInterval(this.timer)
          }
        }, 1000)
      })
    },
    // 关闭应用列表弹窗
    closeAppLisy() {
      this.getshowAppList = false
      this.$store.commit('SET_showAppList', false)

      // 公告弹窗
      if ((this.announcement && this.announcement.title) || (this.announcement && this.announcement.content)) {
        this.showAnnouncemnetPop = this.announcementPop
      }
      // 当公告弹窗没显示的时候就开启首页广告弹窗
      if (!this.showAnnouncemnetPop) {
        this.showActivityImg = this.activityPop
      }
    },
    // 关闭公告弹窗
    closeAccountImg() {
      this.$store.commit('SET_ANNOUNCEMENT_POP', false)
      this.showAnnouncemnetPop = this.announcementPop
      // 活动弹窗
      this.showActivityImg = this.activityPop
    },
    submit() {
      if (this.announcement && this.announcement.jumpUrl) {
        JumpTo(this.announcement.jumpUrl)
      }
      this.$store.commit('SET_ANNOUNCEMENT_POP', false)
      this.showAnnouncemnetPop = this.announcementPop
    },

    closeActivityImg() {
      this.$store.commit('SET_ACTIVITY_POP', false)
      this.showActivityImg = this.activityPop
    },

    handleChange(v) {
      console.log('tab value:', v)
    },
    // 顶部轮播图点击事件
    async clickImg(item) {
      if (item.href) {
        JumpTo(item.href)
      } else {
        JumpTo(item.download_url)
      }
      const res = await advertiseClick({
        id: item.id
      })

      if (res.code !== 200) {
        return this.$toast(res.tip)
      }
    },
    async clickItem(item) {
      if (item.href) {
        JumpTo(item.href)
      } else {
        JumpTo(item.download_url)
      }
      const res = await appsClick({
        id: item.id
      })
      if (res.code !== 200) {
        return this.$toast(res.tip)
      }
    },
    closeSplashAD() {
      if (this.msg == '跳过') {
        this.$store.commit('SET_SPLASH_STATUS', false)
        this.splashShow = false
        this.splashStaticShow = false
        this.msg = ''
      }
    },

    async downApk() {
      let isIos = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
      if (isIos) {
        let url = this.$store.getters['getUserInfo'].inviteUrl.split('?')[0] + 'iosBetaH'
        window.location.href = url
      } else {
        let params = {
          appId: 594,
          channel: '{}',
          domain: window.location.protocol + '//' + window.location.host, //落地页域名
          terminal: 'android'
        }
        try {
          const res = await downApp(params)
          if (res.code === 200) {
            window.location.href = res.data?.link[0]
          } else {
            return this.$toast(res.tip)
          }
        } catch (error) {
          return this.$toast('请求失败，请稍后再试')
        }
      }
    },
    isSafari() {
      var ua = navigator.userAgent.toLowerCase()
      if (
        ua.indexOf('applewebkit') > -1 &&
        ua.indexOf('mobile') > -1 &&
        ua.indexOf('safari') > -1 &&
        ua.indexOf('linux') === -1 &&
        ua.indexOf('android') === -1 &&
        ua.indexOf('chrome') === -1 &&
        ua.indexOf('ios') === -1 &&
        ua.indexOf('browser') === -1
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// 公共样式穿透
:deep() {
  .BackTop {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  }

  .E-tab {
    .van-tab--active {
      color: $txtActive;
    }

    .van-tabs__line {
      background: $txtActive !important;
      width: 0.44rem;
      height: 0.06rem;
    }
  }
}

.account-img-pop {
  width: 5.62rem;
  height: 6.79rem;
  font-size: 0.26rem;
  background: #000;
  background: url('../../assets/imgs/gc-bg.png') no-repeat;
  background-size: 100% 100%;

  // .topText {
  //   text-align: center;
  //   color: #fff;
  //   font-size: 0.36rem;
  //   line-height: 0.64rem;
  //   background: linear-gradient(90deg, #00bbbc 0%, #0070c0 100%);
  // }

  .titlePack {
    margin-bottom: 0.5rem;
    padding: 1rem 0.8rem 0 0.5rem;
    height: 4.78rem;
    overflow: auto;

    .desc {
      white-space: pre-wrap;
    }
  }

  .btn {
    width: 3rem;
    height: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    font-size: 0.28rem;
    margin: 0.8rem 0 0 2rem;
  }
}

:deep() {
  .van-tabbar {
    padding-top: 0.2rem;
    padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom)) !important;
    /* 兼容 iOS < 11.2 */
    padding-bottom: calc(0.2rem + env(safe-area-inset-bottom)) !important;
    box-sizing: border-box;
  }
}

.downApk {
  position: fixed;
  right: 0;
  bottom: 3.2rem;
  font-size: 0.26rem;
  z-index: 100;
  height: 0.6rem;
  text-align: center;
  line-height: 0.6rem;
  color: #fff;
  border-radius: 60px 0px 0px 60px;
  background: var(--icon, linear-gradient(95deg, #493afd 0%, #752dfc 100%));
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 0.49rem;
    height: 0.45rem;
    margin-right: 0.2rem;
  }
}

.layout-footer {
  position: fixed;
  z-index: 100;
  width: 100%;
  max-width: $pcMaxWidth;
  bottom: 0;
  left: 50%;
  padding: 0 0.25rem;
  @include transformCenter(-50%, 0);
  height: $footerHeight;

  :deep() {
    .van-tabbar {
      border-top: 0.01rem solid #797979;
    }

    .van-tabbar-item--active {
      &::after {
        display: none;
      }

      .van-tabbar-item__text {
        color: #e8619d;
      }
    }

    .van-tabbar-item {
      .van-tabbar-item__icon {
        img {
          width: 0.44rem;
          height: 0.44rem;
        }
      }
    }

    .van-tabbar-item__text {
      color: #666;
    }
  }
}

.app-container {
  height: 100%;
  overflow: hidden;

  .splash-close {
    width: 1.2rem;
    text-align: center;
    position: absolute;
    right: 0.1rem;
    top: 0.1rem;
    height: auto;
    padding: 0.1rem 0.25rem;
    background: rgba(0, 0, 0, 0.4);
    z-index: 10001;
    border-radius: 0.35rem;
    font-size: 0.26rem;
    font-weight: 600;
    color: #fff;
    border: none;
  }
}

.splashAD {
  width: 7.5rem;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 9999;
  font-size: 0;
  overflow: hidden;
  max-width: 15rem;
  left: 50%;
  transform: translate(-50%, 0);

  .top-swiper {
    height: 100vh;

    :deep() {
      .default {
        height: 100%;

        .warp {
          height: 100vh;

          img {
            height: 100%;
          }
        }
      }
    }
  }
}

// 应用列表弹窗
.app-list-pop {
  width: 100%;
  max-width: $pcMaxWidth;
  padding: 0.53rem 0.4rem;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.6);
  overflow-y: visible;
  z-index: 999999;
  @include flexbox($fd: column, $jc: center);

  .swiper {
    height: 1.8rem;

    :deep() {
      img {
        object-fit: cover !important;
      }
    }
  }

  .app-list {
    @include flexbox($fw: wrap, $jc: space-around);
    margin-top: 0.36rem;
    max-height: 8rem;
    overflow-y: auto;
    position: relative;

    li {
      width: 1.5rem;
      margin-bottom: 0.33rem;
      margin-right: 0.44rem;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .app_item {
        width: 1.5rem;
        height: 1.5rem;

        :deep() {
          img {
            border-radius: 0.12rem;
          }
        }
      }

      .btn {
        border-radius: 0.06rem;
        background: $btnBg;
        padding: 0.06rem 0;
        margin-top: 0.24rem;
        text-align: center;
        color: #fff;
        white-space: nowrap;
      }
    }
  }

  .close_btn {
    text-align: center;
  }
}
</style>
