<template>
  <div class="wrap">
    <EBackTop title="图片预览" :open-fallback="true"></EBackTop>
    <div>
      <van-swipe :show-indicators="false" @change="onChange" :initial-swipe="indexNum">
        <van-swipe-item v-for="(item, index) in pData.pictures" :key="index">
          <EDecryptImg :imgCDN="imgCDN" class="bg-img" :imgURL="item" />
        </van-swipe-item>
      </van-swipe>
      <div class="detail-foot">
        <div class="foot-l">
          <div class="item" @click="addLike(pData, 2)">
            <img v-if="pData.isLike" src="@/assets/imgs/play/likeIcon.png" alt="" />
            <img v-else src="@/assets/imgs/play/like_tt.png" alt="" />
            {{ pData.likeCount | numberFilter }}
          </div>
          <div class="item" @click="addLike(pData, 1)">
            <img v-if="pData.isCollect" src="@/assets/imgs/play/collectIcon.png" alt="" />
            <img v-else src="@/assets/imgs/play/collectIconS.png" alt="" />
            {{ pData.collectCount | numberFilter }}
          </div>
          <div class="item" :style="{ color: '#999999' }" @click="clickShare">
            <img src="@/assets/imgs/fx.svg" alt="" />
            分享
          </div>
        </div>
        <div v-if="pData.pictures" class="custom-indicator">
          <span>{{ indexNum + 1 }}</span>
          /
          {{ pData.pictures.length }}
        </div>
      </div>
    </div>
    <BlShare
      :isShow.sync="showShare"
      :options="options"
      @select="shareBtn"
      @cancel="
        e => {
          showShare = e
        }
      "
    />
    <SharePopup
      :sharePopShow="showCommunityShare"
      @close="
        e => {
          showCommunityShare = e
        }
      "
    />
    <AddPlaylistPopup v-model="showPop" :type="2" :objectId="+$route.query.id" />
  </div>
</template>

<script>
import { photo_station_detail } from '@/api/discover'
import { collect } from 'api/home'
export default {
  components: {
    BlShare: () => import('@/components/Share/index.vue'),
    SharePopup: () => import('@/components/SharePopup.vue'),
    AddPlaylistPopup: () => import('@/components/Popup/addPlaylistPop.vue')
  },
  data() {
    return {
      indexNum: 0,
      EADconfig: {
        speed: 300,
        autoplay: false,
        loop: true,
        delay: 2000
      },
      pageNum: 1,
      pageSize: 10,
      loading: false, //上拉加载
      finished: false, //上拉加载完成
      refreshing: false, //下拉刷
      showlist: [],
      pData: {},
      showShare: false,
      showCommunityShare: false,
      options: [
        [
          { name: '社区分享', icon: require('@/assets/imgs/share/share.png') },
          { name: '添加到播单', icon: require('@/assets/imgs/share/playlist.png') }
        ],
        [
          { name: '微信', icon: require('@/assets/imgs/share/wechat.png') },
          { name: '朋友圈', icon: require('@/assets/imgs/share/friends.png') },
          { name: '微博', icon: require('@/assets/imgs/share/weibo.png') },
          { name: '复制链接', icon: require('@/assets/imgs/share/link.png') }
        ]
      ],
      showPop: false,
      showVipPop: true
    }
  },
  computed: {
    lotteryTopAd({ $store }) {
      return $store.getters['lotteryTopAd']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
    // isZsMember({ $store }) {
    //   return $store.getters['isZsMember']
    // }
  },
  created() {
    console.log('8888', this.$route)
    this.pData = this.$route.query.pData
    this.indexNum = +this.$route.query.index
    // this.getList()
  },

  methods: {
    doCopy(text) {
      this.$copyText(text).then(() => {
        this.$toast('复制剪贴板成功')
      })
    },
    shareBtn({ name }, index) {
      if (name == '社区分享') {
        this.showShare = false
        this.showCommunityShare = true
      } else if (name == '添加到播单') {
        this.showShare = false

        this.showPop = true
      } else {
        this.doCopy(
          this.shareUrl +
            '/#' +
            this.$route.path +
            '?pc=' +
            this.$store.getters['getUserInfo'].inviteCode +
            '&dc=h5share'
        )
      }
    },
    async clickShare(item) {
      this.showShare = true
    },
    onChange(index) {
      this.indexNum = index
    },
    async addLike(item, type) {
      // 收藏信息
      try {
        const res = await collect({
          flag: type === 1 ? !item.isCollect : !item.isLike,
          object_id: item.id,
          collectType: 9,
          type: type
        })
        if (res.code === 200) {
          this.$toast.clear()
          this.$toast(res.tip)
          this.$nextTick(() => {
            if (type === 2) {
              if (!item.isLike) {
                this.pData.likeCount++
                this.$set(this.pData, 'isLike', true)
              } else {
                this.pData.likeCount--
                this.$set(this.pData, 'isLike', false)
              }
            } else {
              if (!item.isCollect) {
                this.pData.collectCount++
                this.$set(this.pData, 'isCollect', true)
              } else {
                this.pData.collectCount--
                this.$set(this.pData, 'isCollect', false)
              }
            }
          })
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        return this.$toast('请求失败，请稍后再试')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  margin-top: 0.5rem;
}
.detail-foot {
  margin-top: 0.36rem;
  @include flexbox;
  padding: 0 0.28rem;

  .custom-indicator {
    border-radius: 0.24rem;
    background: rgba(0, 0, 0, 0.04);
    padding: 0.04rem 0.24rem;
    color: #666;
    font-size: 0.28rem;
    font-weight: 400;

    > span {
      color: #e8619d;
    }
  }

  .foot-l {
    display: flex;
    align-items: center;
    color: #999999;

    .item {
      display: flex;
      align-items: center;
      margin-right: 0.64rem;
      font-size: 0.24rem;
    }

    img {
      width: 0.28rem;
      height: 0.28rem;
      margin-right: 0.1rem;
    }
  }
}
</style>
