<template>
  <div>
    <div class="topTab">
      <van-icon name="arrow-left" size="25" @click="$router.go('-1')" />
      <div class="search-box">
        <img class="search-img" src="@/assets/imgs/search/search.png" />
        <input v-model="content" class="input" placeholder="搜索的内容" />
      </div>
      <span class="search-btn" @click="searchBtn">搜索</span>
    </div>

    <div ref="container" class="index-container">
      <ETabBar
        :list="activeList"
        :tabbar-config="tabbarConfig"
        :active-num="activeId"
        class="ETabBar"
        @change="changeTab"
      >
        <template #default="scope">
          <component
            :is="compComponent(scope.data.id)"
            :id="scope.data.id"
            :ref="'item' + scope.index"
            :navItem="scope"
          />
        </template>
      </ETabBar>
    </div>

    <!-- 片库入口 -->
    <!-- <img
      class="pianku_main_icon"
      src="@/assets/imgs/acg/pianku_main_icon.png"
      alt=""
      @click="$router.push('/Acg/classType')"
    /> -->
  </div>
</template>

<script>
export default {
  name: 'SearchPage',
  components: {
    ComicsSearch: () => import('@/components/Search/searchPage/comicsSearch.vue'),
    NovelSearch: () => import('@/components/Search/searchPage/novelSearch.vue'),
    CartoonSearch: () => import('@/components/Search/searchPage/cartoonSearch.vue'),
    Dream: () => import('@/components/Search/searchPage/dream.vue') // 剧场
  },
  data() {
    return {
      activeList: [
        { name: '漫画', id: 8 },
        { name: '动漫', id: 3 },
        { name: '有声小说', id: 10 },
        { name: '剧场', id: 22 }
      ],
      activeId: 8,
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.32rem', //默认字体大小
        fontSizeActive: '0.32rem', //选中字体大小
        fontColor: '#333333', //默认字体颜色
        fontColorActive: '#E8619D', //选中字体颜色
        bg: '#fff', //默认背景色
        bgActive: '#fff', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#E8619D',
        lineWidth: '0.28rem', //下划线宽度 String
        sticky: true, //粘性布局
        offsetTop: '0', //距顶部距离
        tabFlex: true
      },
      pageNum: 0,
      pageSize: 20,
      content: ''
    }
  },

  computed: {},

  mounted() {},

  activated() {
    this.content = this.$route.params.value
    this.$store.state.searchValue = this.$route.params.value

    setTimeout(() => {
      this.getSeach()
    }, 500)
  },

  methods: {
    searchBtn() {
      this.getSeach()
    },
    compComponent(data) {
      switch (data) {
        case 8:
          return 'ComicsSearch'
        case 3:
          return 'CartoonSearch'
        case 10:
          return 'NovelSearch'
        case 22:
          return 'Dream'
        default:
          return 'ComicsSearch'
      }
    },

    // 获取数据
    async getSeach() {
      if (this.activeId === 8) {
        this.$nextTick(() => {
          this.$refs['item0'].setContent(this.content)
        })
      } else if (this.activeId === 3) {
        this.$nextTick(() => {
          this.$refs['item1'].setContent(this.content)
        })
      } else if (this.activeId === 10) {
        this.$nextTick(() => {
          this.$refs['item2'].setContent(this.content)
        })
      } else if (this.activeId === 22) {
        this.$nextTick(() => {
          this.$refs['item3'].setContent(this.content)
        })
      }
    },
    //返回按钮
    goBack() {
      this.$router.go(-1)
    },
    changeTab(id) {
      this.activeId = id
      this.getSeach()
    }
  }
}
</script>
<style lang="scss" scoped>
.topTab {
  padding: 0 0.35rem 0 0.2rem;
  background: #fff;
  width: 100%;
  height: 1rem;
  position: fixed;
  top: 0rem;
  left: 0rem;
  z-index: 9;
  @include flexbox();
  font-size: 0.28rem;
  .search-box {
    display: flex;
    align-items: center;
    width: 5rem;
    border-radius: 0.5rem;
    background: rgba(153, 153, 153, 0.1);
    padding: 0.1rem 0.2rem;
    .search-img {
      width: 0.32rem;
      padding-left: 0.1rem;
    }
    .input {
      background-color: transparent;
      width: 100%;
      font-size: 0.24rem;
      margin-left: 0.1rem;
    }
  }

  .search-btn {
    color: #fff;
    border-radius: 0.08rem;
    background: linear-gradient(101deg, #ffea7a 3.23%, #ff74b2 47.25%, #ff74b2 84.38%, #ffea7a 102.17%);
    padding: 0.05rem 0.24rem;
  }
}
.index-container {
  padding: 2rem 0 0 0;
  position: relative;
  overflow: hidden;

  .HomeSearch {
    position: fixed;
    top: 0.88rem;
    z-index: 1;
  }

  .ETabBar {
    //z-index: 0;
    :deep() {
      .van-sticky {
        position: fixed;
        top: 1rem !important;
        left: 0.3rem !important;
        &.van-sticky--fixed {
          left: 4.05rem !important;
        }
      }
      .content {
        margin-top: 0 !important;
      }
    }
  }
}
::v-deep {
  .van-nav-bar__title {
    margin-left: 0.96rem;
  }
}
.search-input {
  background: rgba(217, 217, 217, 0.3);
  width: 2.67 * 2rem;
  height: 0.6rem;
  line-height: 0.6rem;
  border-radius: 0.12 * 2rem 0.15 * 2rem 0.15 * 2rem 0.04 * 2rem;
  color: #939496;
  font-size: 0.28rem;
  padding-left: 0.21rem;
  box-sizing: border-box;

  .searchIcon {
    width: 0.32rem;
    height: 0.32rem;
  }
}
.pianku_main_icon {
  position: fixed;
  top: 1.25rem;
  right: 0.32rem;
  width: 1.16rem;
  height: 0.36rem;
  z-index: 100;
}
.right {
  font-size: 0.32rem;
  color: #333;
}
</style>
