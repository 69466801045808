<template>
  <div class="video_wrap">
    <div class="tagContent" v-if="tagVideoList && tagVideoList.length > 0">
      <div class="tag_name">{{ content }}<span class="icon">|</span>标签内容</div>
      <div class="tag_list">
        <VideoShowType1 v-for="(item, index) in tagVideoList" :key="index" :data="item" class="item"> </VideoShowType1>
      </div>
      <div class="look_more" @click="handelMore">查看更多</div>
    </div>
    <div class="tab_top">
      <div>
        <span :class="{ active: activeIndex == 0 }" @click="handleClick(0)">综合排序</span>&nbsp;/
        <span :class="{ active: activeIndex == 1 }" @click="handleClick(1)">最新上架</span>&nbsp;/
        <span :class="{ active: activeIndex == 2 }" @click="handleClick(2)">最多观看</span>
      </div>
      <div class="longShort" @click="longShortFn">
        <span>{{ longShort ? '长视频' : '短视频' }}</span>
        <img src="@/assets/imgs/search/change_icon.png" alt="" />
      </div>
    </div>
    <ERefreshLoadV3 ref="VideoSearchList" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
      <videoWaterFallList :list="mediaList" v-if="!longShort">
        <template #mask="{ data }">
          <div v-if="data.videoType === 21 && !hasDarkPermission" class="mask" @click.stop="$router.push('/Mine/vip')">
            <div class="first">本视频为暗网内容</div>
            <div class="second">
              <span>前往</span>
              <div class="toVip">开通会员</div>
            </div>
          </div>
        </template>
      </videoWaterFallList>
      <ul v-else class="main_list">
        <VideoShowType1 v-for="(item, index) in mediaList" :key="index" :data="item" class="item"> </VideoShowType1>
      </ul>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { search, search_tag_detail } from 'api/search'
export default {
  name: 'VideoSearch',
  components: {
    videoWaterFallList: () => import('@/components/Home/listType/videoList/videoWaterFallList.vue'),
    VideoShowType1: () => import('@/components/Home/showType/videoListType/videoshowType1.vue')
  },
  props: ['navItem', 'id'],
  data() {
    return {
      longShort: true,
      activeIndex: 0,
      pageNum: 1,
      pageSize: 10,
      content: '',
      finished: false,
      mediaList: [],
      tagVideoList: []
    }
  },
  computed: {
    hasDarkPermission() {
      return this.$store.getters['hasDarkPermission']
    }
  },
  watch: {
    content(newVal, oldVal) {
      if (newVal) {
        this.search_tag_detailFn()
      }
    }
  },
  methods: {
    handelMore() {
      this.$router.push({
        path: '/search/SearchTagVideo',
        query: {
          content: this.content
        }
      })
    },
    handleClick(index) {
      this.activeIndex = index
      this.pageNum = 1
      this.finished = false
      this.mediaList = []
      this.getSeach()
    },
    longShortFn() {
      this.longShort = !this.longShort
      this.pageNum = 1
      this.finished = false
      this.mediaList = []
      this.getSeach()
    },
    //设置搜索参数
    setContent(val) {
      this.content = val
      console.log('视频列表刷新')
      this.$refs['VideoSearchList'].onRefresh()
    },

    async onLoad() {
      this.pageNum++
      this.finished = false
      await this.getSeach()
    },
    async onRefresh() {
      this.pageNum = 0
      this.mediaList = []
    },

    // 获取数据
    async getSeach() {
      if (this.content) {
        await search({
          type: this.longShort ? 1 : 2,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          content: this.content,
          excludedTag: true,
          sortType: this.activeIndex
        }).then(res => {
          if (res.code === 200 && res.data.mediaList) {
            this.mediaList = [...this.mediaList, ...res.data.mediaList]
            if (res.data.mediaList.length < this.pageSize || !res.data.mediaList) {
              this.finished = true
            }
          } else {
            this.finished = true
            this.$toast(res.tip)
          }
        })
      } else {
        // return this.$toast('搜索内容不能为空')
      }
    },
    async search_tag_detailFn() {
      const res = await search_tag_detail({
        contentType: 1,
        pageNum: 1,
        pageSize: 6,
        tag: this.content
      })
      console.log(res)
      if (res.code === 200 && res.data.mediaList) {
        this.tagVideoList = res.data.mediaList
      } else {
        this.$toast(res.tip)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video_wrap {
  padding: 0 0.32rem;
  .tagContent {
    margin-bottom: 0.3rem;
    font-size: 0.24rem;
    .tag_name {
      .icon {
        padding: 0 0.1rem;
        font-weight: 600;
        color: $txtActive;
      }
    }
    .tag_list {
      margin: 0.2rem 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .look_more {
      border-radius: 0.5rem;
      height: 0.5rem;
      width: 100%;
      text-align: center;
      line-height: 0.5rem;
      border: 0.01rem solid rgb(194, 191, 191);
    }
  }
  .tab_top {
    display: flex;
    justify-content: space-between;
    font-size: 0.24rem;
    color: gray;
    margin-bottom: 0.2rem;
    > div:nth-child(1) {
      .active {
        color: $txtActive;
      }
    }
    > div:nth-child(2) {
      color: $txtActive;
      display: flex;
      align-items: center;
      img {
        width: 0.16rem;
        height: 0.16rem;
        margin-left: 0.1rem;
      }
    }
  }
  .main_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      margin-bottom: 0.2rem;
    }
  }
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    @include flex-center;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    color: #fff;
    font-size: 0.28rem;
    font-weight: 400;
    .second {
      margin-top: 0.08rem;
      @include flex-center;
      .toVip {
        margin-left: 0.08rem;
        padding: 0.04rem 0.12rem;
        border-radius: 0.5rem;
        background: rgba(131, 37, 37, 0.5);
        color: #fff;
        font-size: 0.24rem;
      }
    }
  }
}
</style>
