<template>
  <div class="playlist-video">
    <ul>
      <li v-for="item in playlist" :key="item.id" @click="handleClick(item)">
        <div class="top">
          <!-- <img src="@/assets/imgs/temp/3410.png" alt="" /> -->
          <EDecryptImg class="playlist-img" :imgURL="item.coverImg" />
          <div class="top-poa">
            <div>
              <img src="@/assets/imgs/temp/3410.png" alt="" />
              <span>{{ item.watchTimes | numberFilter }}</span>
            </div>
            <span>
              {{ item.playTime | secondToDate }}
            </span>
          </div>
        </div>
        <div class="bottom">
          <div class="bottom-overflow">{{ item.title }}</div>
          <div>
            <div class="tags">
              <span>{{ item.tags[0] }}</span>
            </div>
            <div>
              <img src="@/assets/imgs/discover/comment.svg" alt="" />
              <span>评论 {{ item.comments }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    playlist: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleClick(item) {
      this.$router.push({
        path: `/play/longVideo/${item.id}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.playlist-video {
  color: $white;
  > ul {
    display: flex;
    flex-wrap: wrap;
    > li {
      width: 3.36rem;
      margin-bottom: 0.24rem;
      &:nth-child(odd) {
        margin-right: 0.14rem;
      }
    }
  }
  .top {
    position: relative;
    .playlist-img {
      :deep() {
        img {
          width: 100%;
          height: 1.88rem;
          border-radius: 0.06rem 0.06rem 0rem 0rem;
        }
      }
    }

    &-poa {
      position: absolute;
      left: 0;
      bottom: 0.12rem;
      @include flexbox;
      width: 100%;
      padding: 0 0.12rem;
      img {
        width: 0.28rem;
        height: 0.28rem;
        margin-right: 0.08rem;
      }
    }
  }
  .bottom-overflow {
    @include textoverflow($clamp: 2);
  }
  .bottom {
    display: flex;
    flex-direction: column;
    > div {
      &:nth-child(1) {
        color: #333;
        font-size: 0.26rem;
        font-weight: 500;
        padding: 0.12rem 0 0 0.2rem;
        flex: 1;
      }
      &:nth-child(2) {
        @include flexbox;
        color: #999;
        font-size: 0.22rem;
        font-style: normal;
        font-weight: 400;
        padding: 0.14rem 0.08rem 0;
        flex: none;
        > div {
          display: flex;
          &:nth-child(1) {
            flex: 1;
            > span {
              padding: 0.02rem 0.08rem;
              border-radius: 0.06rem;
              background: #e8619d;
              color: #fff;
            }
          }
          &:nth-child(2) {
            flex: none;
            @include flex-align-center;
            img {
              width: 0.22rem;
              margin-right: 0.06rem;
            }
          }
        }
      }
    }
    &-overflow {
      @include textoverflow($clamp: 2);
    }
    .tags {
      > span {
        margin-right: 0.08rem;
        *:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
