<script>
import { getHistoryList } from 'api/user'
import ResourcePage from './ResourcePage.vue'
export default {
  name: 'ResourceCollPageTab',
  extends: ResourcePage,
  data() {
    return {}
  },
  computed: {},
  methods: {
    // 获取收藏短视频列表
    async getList() {
      try {
        const res = await getHistoryList({
          type: this.searchParams.sort, //1AV（长视频） 2小视频 3动漫 5帖子 6楼风 7女优 8漫画 9写真 10小说 11漫画视频 13播单
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          userId: this.userId
        })
        if (res.code === 200) {
          this.photoList = [...this.photoList, ...(res.data.photoList || [])]
          this.mediaList = [...this.mediaList, ...(res.data.mediaList || [])]
          this.playList = [...this.playList, ...(res.data.playList || [])]
          switch (this.searchParams.sort) {
            case 1:
              if (res.data.mediaList?.length < this.pageSize || !res.data.mediaList) {
                this.finished = true
              }
              return
            case 9:
              if (res.data.photoList?.length < this.pageSize || !res.data.photoList) {
                this.finished = true
              }
              return
            case 13:
              if (res.data.playList?.length < this.pageSize || !res.data.playList) {
                this.finished = true
              }
              return
          }
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.resource {
  .resource-opr {
    background-color: #ffffff;
  }
}
</style>
