<!--
 * @Author:
 * @Mail:
 * @Date: 2024-02-01 11:52:06
 * @LastEditTime: 2024-02-10 16:33:11
 * @LastEditors: 江涛 wangtao524713@gmail.com
 * @FilePath: /haoseH5/src/components/Home/showType/SwiperPic.vue
-->
<template>
  <div>
    <div class="title" @click.stop="handleClickTitle">{{ title }} <img src="~/assets/imgs/home/more.png" alt="" /></div>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div v-for="(item, index) in list.slice(0, 4)" :key="index">
            <ImgshowType1 :data="item"></ImgshowType1>
          </div>
        </div>
        <div v-if="list.length > 4" class="swiper-slide">
          <div v-for="(item, index) in list.slice(4, 8)" :key="index">
            <ImgshowType1 :data="item"></ImgshowType1>
          </div>
        </div>
        <div v-if="list.length > 8" class="swiper-slide">
          <div v-for="(item, index) in list.slice(8, 12)" :key="index">
            <ImgshowType1 :data="item"></ImgshowType1>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination"></div>
  </div>
</template>

<script>
import { JumpTo } from '@/utils/utils_tools'

export default {
  components: {
    ImgshowType1: () => import('./pictrueListType/imgshowType1') //套图站主题
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      listData: 58
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.initSwiper()
    })
  },
  methods: {
    async initSwiper() {
      this.swiper = new Swiper('.swiper-container', {
        pagination: {
          el: '.pagination'
        }
      })
    },
    handleClickTitle() {
      if (this.item.jumpType) {
        if (this.item.jumpUrl) {
          JumpTo(`${this.item.jumpUrl}?showType=${this.item.showType}}`)
        } else {
          this.$router.push({
            path: '/home/videoTopicDetail',
            query: {
              id: this.item.id,
              name: this.item.title,
              showType: this.item.showType || ''
            }
          })
        }
      } else {
        this.$emit('handleClickTitle', this.item.jumpCategoryId)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  width: 100%;
  height: 0.44rem;
  font-size: 0.32rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swiper-container {
  margin-top: 0.24rem;
  width: 100%;
  overflow-x: auto;
  margin-top: 0.24rem;
  height: 5.04rem;
}

.pagination {
  margin: 0 auto;
  text-align: center;
}

.swiper-slide {
  display: flex;
  flex-flow: wrap;

  > div {
    margin-right: 0.13rem;
  }

  > div:nth-child(2n) {
    margin-right: 0;
  }
}

::-webkit-scrollbar {
  display: none;
}
</style>
