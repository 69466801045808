<template>
  <div class="lady-info">
    <div class="img">
      <EDecryptImg :imgURL="data.avatar"> </EDecryptImg>
    </div>
    <div class="title">{{ data.name }}</div>
    <div class="desc">{{ data.desc }}</div>
    <div class="look" @click="handleClick(data)">查看</div>
  </div>
</template>
<script>
export default {
  name: 'LadyListType1',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleClick(data) {
      if (data) {
        this.$router.push(`/discover/actor/${data.id}`)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.lady-info {
  width: 1.2rem;
  height: 2.5rem;
  margin-right: 0.36rem;
  flex-shrink: 0;
}
.img {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 99px;
  overflow: hidden;
}
.title {
  font-size: 0.24rem;
  font-weight: 500;
  color: #333333;
  text-align: center;
  margin-top: 0.12rem;
  line-height: 0.36rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.desc {
  width: 100%;
  height: 0.28rem;
  font-size: 0.2rem;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin-top: 0.04rem;
  line-height: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.look {
  margin: 0.1rem auto 0;
  width: 0.96rem;
  height: 0.4rem;
  border: 0.01rem solid #ffa800;
  border-radius: 0.56rem;
  color: #ffa800;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
