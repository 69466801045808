<template>
  <ERefreshLoadV3 ref="PhotoSearchList" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
    <div class="content">
      <EWaterFall :datasList="photoList">
        <template #left="{ handleLoad, data }">
          <ImgshowType2 :data="data" class="leftItem" @loadedImg="handleLoad"></ImgshowType2>
        </template>
        <template #right="{ handleLoad, data }">
          <ImgshowType2 :data="data" class="rightItem" @loadedImg="handleLoad"></ImgshowType2>
        </template>
      </EWaterFall>
    </div>
  </ERefreshLoadV3>
</template>

<script>
import { search } from 'api/search'
export default {
  name: 'PhotoSearch',
  components: {
    ImgshowType2: () => import('components/Home/showType/pictrueListType/imgshowType2.vue')
  },
  props: ['navItem', 'id'],
  data() {
    return {
      pageNum: 0,
      pageSize: 10,
      content: '',
      finished: false,
      photoList: []
    }
  },

  mounted() {},

  methods: {
    setContent(val) {
      this.content = val
      console.log('图集列表刷新')
      this.$refs['PhotoSearchList'].onRefresh()
    },
    async onLoad() {
      this.pageNum += 1
      await this.getSearch()
    },
    async onRefresh() {
      this.finished = false
      //表示处于可以下拉状态
      this.pageNum = 0
      this.photoList = []
    },

    // 获取数据
    async getSearch() {
      await search({
        type: this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        content: this.content
      }).then(res => {
        if (res.code === 200 && res.data.photoList) {
          this.photoList = [...this.photoList, ...res.data.photoList]
          if (res.data.photoList.length < this.pageSize || !res.data.photoList) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-flow: wrap;
  margin-top: 0.24rem;
  padding: 0 0.32rem;
  .leftItem,
  .rightItem {
    width: 1.68 * 2rem;
    margin-bottom: 0.14rem;
    border-radius: 0.16rem;
  }
  .leftItem {
    margin-right: 0.07rem;
  }
  .rightItem {
    margin-left: 0.07rem;
  }
}
</style>
