<template>
  <div class="zanItem">
    <EDecryptImg :imgCDN="imgCDN" class="replyItem_avatar" :imgURL="itemData.avatar ? itemData.avatar : ''">
    </EDecryptImg>
    <div class="replyItem_right">
      <div class="replyItem_right_left">
        <p>{{ itemData.nickName }}</p>
        <p class="time">{{ itemData.createdAt | dateFormat08 }}</p>
        <p>{{ itemData.content }}</p>
      </div>
      <!-- <EDecryptImg :imgCDN="imgCDN" class="replyItem_right_right" :imgURL="info.avatarUrl ? info.avatarUrl : ''">
      </EDecryptImg> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['itemData'],
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    info({ $store }) {
      return $store.getters['getUserInfo']
    }
  }
}
</script>

<style lang="scss" scoped>
.zanItem {
  margin: 0.32rem 0.24rem 0.24rem 0.32rem;
  padding-bottom: 0.24rem;
  border-bottom: 0.01rem solid #f0f0f0;
  @include flexbox($ai: flex-start, $jc: flex-start);

  .replyItem_avatar {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    margin-right: 0.2rem;
    flex: none;
  }
}

.replyItem_right {
  @include flexbox();

  .replyItem_right_left {
    font-size: 0.28rem;

    .time {
      color: #999;
      margin: 0.08rem 0 0.22rem 0;
      font-size: 0.24rem;
    }
  }

  .replyItem_right_right {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.08rem;
    flex: none;
  }
}
</style>
