<template>
  <div class="warp">
    <img :key="imgCDN + imgURL" ref="imgSize" class="img" :src="props" @load="handleLoad('finish')" />
    <slot></slot>
  </div>
</template>
<script>
import { handleVerImg } from '@/utils/utils_tools'
export default {
  props: ['imgURL', 'item'],
  data() {
    return {
      show: false,
      timer: null,
      cover: '',
      myDB: {
        name: 'imgs',
        version: '1',
        db: null,
        storeName: 'photo'
      },
      newprops: '',
      timerOut: null,
      timerOutStatus: false
    }
  },
  computed: {
    photo({ imgURL }) {
      return {
        url: imgURL,
        blob: 'xxxxxx'
      }
    },
    props({ cover }) {
      if (cover) {
        return cover
      }
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  destroyed() {
    if (this.cover.indexOf('blob:http') !== -1) {
      window.URL.revokeObjectURL(this.cover)
    }
    if (this.timer) {
      clearTimeout(this.timer)
    }
  },
  async mounted() {
    this.handler()
    this.handleLoad('timeOut')
  },
  methods: {
    // 删除/销毁 indexdb
    deleteDB(name) {
      var deleteDb = window.indexedDB.deleteDatabase(name)
      deleteDb.onsuccess = () => {
        console.log('complete')
      }
    },
    // 图片路径解密操作
    async handler() {
      if (this.imgURL && this.imgURL.length !== 0) {
        this.testFn(this.myDB.name)
      }
    },
    openDB(name, version) {
      var version = version || 1
      var request = window.indexedDB.open(name, version)
      request.onerror = e => {
        console.log(e.currentTarget.error.message)
      }
      request.onsuccess = e => {
        this.myDB.db = e.target.result
        this.addData(this.myDB.db, this.myDB.storeName)
      }
      request.onupgradeneeded = e => {
        var db = e.target.result
        if (!db.objectStoreNames.contains(this.myDB.storeName)) {
          var store = db.createObjectStore(this.myDB.storeName, {
            autoIncrement: true
          })
          store.createIndex('urlIndex', 'url', { unique: true })
        }
      }
    },
    addData(db, storeName) {
      var transaction = db.transaction(storeName, 'readwrite')
      var store = transaction.objectStore(storeName)
      const url = store.index('urlIndex')
      const request = url.get(this.imgURL)
      request.onsuccess = async e => {
        if (e.target.result && e.target.result.url) {
          this.cover = window.URL
            ? window.URL.createObjectURL(new Blob([e.target.result.blob]))
            : window.webkitURL.createObjectURL(new Blob([e.target.result.blob]))
        } else {
          const res = await handleVerImg(this.imgCDN + this.imgURL)
          let transaction = db.transaction(storeName, 'readwrite')
          let store = transaction.objectStore(storeName)
          store.add({
            url: this.imgURL,
            blob: res
          })
          this.cover = window.URL
            ? window.URL.createObjectURL(new Blob([res]))
            : window.webkitURL.createObjectURL(new Blob([res]))
        }
      }
      request.onerror = async e => {
        const res = await handleVerImg(this.imgCDN + this.imgURL)
        this.cover = window.URL
          ? window.URL.createObjectURL(new Blob([res]))
          : window.webkitURL.createObjectURL(new Blob([res]))
      }
    },
    //大体思路1.先计算一个假高度。2.进来全部设置超时加载。3.图片加载完成再高度自适应。
    handleLoad($event) {
      if (this.imgURL && $event == 'finish') {
        return (this.item.reset_high = 'auto')
      }
      this.$emit('handleLoad', $event)
    },
    async testFn() {
      const res = await handleVerImg(this.imgCDN + this.imgURL)
      this.cover = window.URL
        ? window.URL.createObjectURL(new Blob([res]))
        : window.webkitURL.createObjectURL(new Blob([res]))
    }
  }
}
</script>
<style lang="scss" scoped>
.default {
  width: 100%;
  height: 100%;
}
.warp {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-image: url('../../../static/loading.png');
  background-size: cover;
  background-position: 0% 50%;
  font-size: 0;
}
img {
  filter: drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.8));
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.6s;
  -webkit-user-drag: none;
}
</style>
