<template>
  <div class="Welfare">
    <ul class="sub_title">
      <li
        v-for="item in tabList"
        :key="item.id"
        :class="activeIndex === item.id ? 'active' : ''"
        @click="activeIndex = item.id"
      >
        {{ item.name }}
      </li>
    </ul>
    <!-- 轮播 -->
    <EADSwiper class="swiper" :list="lotteryTopAd" keyName="cover" :EADconfig="EADconfig" @clickImg="clickImg">
    </EADSwiper>
    <Default v-if="activeIndex == 0" />
    <Index v-else />
  </div>
</template>

<script>
import { JumpTo } from '@/utils/utils_tools'
import { advertiseClick } from 'api/home'

export default {
  name: '',
  components: {
    Default: () => import('./welfareCompoents/default.vue'),
    Index: () => import('./welfareCompoents/index.vue')
  },
  data() {
    return {
      activeIndex: 0,
      tabList: [
        { id: 0, name: '应用' },
        { id: 1, name: '游戏' }
      ],

      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      }
    }
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    // 落地页
    ldyCdn({ $store }) {
      return $store.getters['ldyCdn']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取应用列表头部广告
    lotteryTopAd({ $store }) {
      return $store.getters['entertainmentAD']
    }
  },

  methods: {
    changeId(id) {
      this.activeIndex = id
    },
    async clickImg(item) {
      if (item.href) {
        JumpTo(item.href)
      } else {
        JumpTo(item.download_url)
      }
      const res = await advertiseClick({
        id: item.id
      })

      if (res.code !== 200) {
        return this.$toast(res.tip)
      }
    },
    compComponent(data) {
      switch (data.item.id) {
        case 0:
          return 'Default'
        default:
          return 'Index'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Welfare {
}
.swiper {
  margin: 0.24rem 0 0.36rem 0;
}
.sub_title {
  @include flexbox($jc: center);
  width: 2.96rem;
  height: 0.6rem;
  border-radius: 0.3rem;
  background: rgba(0, 0, 0, 0.04);
  text-align: center;
  line-height: 0.6rem;
  margin: 0.24rem auto;
  position: relative;

  li {
    border-radius: 0.42rem;
    text-align: center;
    width: 1.48rem;
    transition: all 0.3s linear;
  }

  .active {
    color: #fff;
    background-color: $txtActive;
    opacity: 1;
  }
}
</style>
