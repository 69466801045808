<template>
  <div class="serialStatus">
    <div v-if="data.updateStatus === 1" class="finished">已完结 • 共{{ data.newChapter || 0 }}话</div>
    <div v-else class="loading">连载中 • 更新{{ data.newChapter || 0 }}话</div>
  </div>
</template>
<script>
export default {
  name: 'SerialStatus',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.serialStatus {
  display: flex;
  margin-top: 0.1rem;
  font-size: 0.2rem;

  div {
    border-radius: 0.04rem;
    padding: 0.02rem 0.1rem;
  }
  .finished {
    color: rgba(39, 132, 184, 1);
  }
  .loading {
    color: rgba(255, 125, 5, 1);
  }
}
</style>
