<template>
  <div class="application">
    <EBackTop :title="'应用中心'" />
    <!-- 列表数据 -->
    <ERefreshLoadV3
      ref="ERefreshLoadV3"
      class="main_list"
      :beforeRefresh="onRefresh"
      :moreData="onLoad"
      :finished="finished"
    >
      <div class="app-item">
        <div v-for="item in appsList" :key="item.id" class="info-box" @click="down(item.download_url)">
          <EDecryptImg class="avatar" :imgURL="item.avatar" />
          <div class="info">
            {{ item.name }}
          </div>
          <!-- btn -->
          <!-- <div class="btn" @click="down(item.download_url)">立即下载</div> -->
        </div>
        <i></i>
        <i></i>
      </div>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { apps_list } from 'api/home'
// import { appsClick } from 'api/home'
import { JumpTo } from '@/utils/utils_tools'
export default {
  name: 'Application',
  components: {
    EDecryptImg: () => import('@/components/web_base/component_base/EDecryptImg/index.vue')
  },
  data() {
    return {
      finished: false,
      pageNum: 0,
      pageSize: 10,
      skeleton: false,
      appsList: []
    }
  },

  methods: {
    // 下拉加载
    async onLoad() {
      this.pageNum++
      await this.getApps()
    },
    // 上拉刷新
    async onRefresh() {
      this.finished = false
      this.redeemcodeList = []
    },
    // 获取应用列表
    async getApps() {
      try {
        const res = await apps_list({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.loading = false
          this.refreshing = false
          this.appsList = [...this.appsList, ...res.data.list]
          if (res.data.list.length < this.pageSize || !res.data.list) {
            this.finished = true
          }
        } else {
          this.finished = true
          return this.$toast(res.tip)
        }
      } catch (error) {
        this.finished = true
        console.log(error)
      }
    },
    // 活动跳转
    async down(item) {
      JumpTo(item)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-item {
  @include flexbox($jc: space-between, $fw: wrap);
  padding: 0.36rem 0.52rem;
  .info-box {
    @include flexbox($jc: flex-start, $ai: center, $fd: column, $fw: nowrap);
  }
  .avatar {
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 0.24rem;
    :deep() {
      img {
        border-radius: 0.24rem;
      }
    }
  }
  i {
    width: 1.7rem;
  }
  .info {
    margin-top: 0.16rem;
    font-size: 0.28rem;
    font-weight: 600;
  }
}
</style>
