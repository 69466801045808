<template>
  <div class="comics_detail">
    <div class="top_main">
      <!-- 背景 -->
      <EBackTop
        :background="'transparent'"
        :fixed="false"
        class="mine-head"
        color="#fff"
        title=""
        :hasSuperiorClick="true"
        :open-fallback="true"
        @click-right="addLike"
        @goBack="onClickLeft"
      >
        <slot slot="right" name="right">
          <div class="add">
            <van-icon
              v-if="!novelDetailData.isCollect"
              color="#ffb267"
              :name="require('@/assets/imgs/acg/shelf.png')"
              style="margin-right: 0.08rem"
            />
            <van-icon v-else color="#ffb267" name="success" />
            {{ novelDetailData.isCollect ? '已加入' : '加入书架' }}
          </div>
        </slot>
      </EBackTop>
      <EDecryptImg class="mine_top_bg" :imgURL="novelDetailData.coverImg"> </EDecryptImg>
      <div class="comics_info_main">
        <EDecryptImg class="comics_right_bg" :imgURL="novelDetailData.coverImg"> </EDecryptImg>
        <div class="comics_info">
          <p class="title">{{ novelDetailData.title }}</p>
          <div class="tags">
            <span
              v-for="item in novelDetailData.tags"
              :key="item.id"
              @click.stop="$router.push(`/acg/AcgTagsList??title=${item.name}&type=novel&id=${item.id}`)"
              >#{{ item.name }}</span
            >
          </div>
          <p class="desc">{{ novelDetailData.desc }}</p>
          <SerialStatus :data="novelDetailData" class="serialStatus"></SerialStatus>
        </div>
      </div>
    </div>
    <!-- 底部信息 -->
    <div class="bot_main">
      <!--      <ERefreshLoadV3-->
      <!--        ref="ERefreshLoadV3"-->
      <!--        class="main_list"-->
      <!--        :beforeRefresh="onRefresh"-->
      <!--        :moreData="onLoad"-->
      <!--        :finished="finished"-->
      <!--      >-->
      <!-- 选集盒子 -->
      <div class="anthology-box">
        <EpisodeInfo
          :value="novelDetailData.watchNum"
          keyName="chapterNum"
          :list="chapterList"
          @input="handleChoose"
        ></EpisodeInfo>
        <!-- 开始阅读按钮 -->
        <div class="to-read" @click="toRead">
          {{ novelDetailData.watchNum > 1 ? `续看${novelDetailData.watchNum}话` : `开始阅读` }}
        </div>
      </div>
      <!-- 广告轮播 -->
      <div v-if="mineAD.length" class="comicsAd">
        <EADSwiper
          :list="mineAD"
          :slidesPerView="1"
          keyName="cover"
          :EADconfig="EADconfig"
          :imgCDN="imgCDN"
          @clickImg="clickImg"
        >
        </EADSwiper>
      </div>
      <!-- 切换tabs -->
      <div class="tabs">
        <!-- 最热最新 -->
        <div v-if="activeId === 2" class="header-right">
          <div class="btn" :class="{ active: sortId === 0 }" @click="switchSort(0)">最新</div>
          <div>/</div>
          <div class="btn" :class="{ active: sortId === 1 }" @click="switchSort(1)">最热</div>
        </div>
        <ETabV2
          class="comicsDetail_tabs"
          background="transparent"
          :tabList="tabList"
          :activeId="activeId"
          :sizeActive="'.28rem'"
          :sizeTab="'.28rem'"
          :sticky="true"
          :ellipsis="false"
          @change="change"
        >
          <template #title="{ item }">
            <div class="tba-item">
              <div>
                {{ item.name }} <span v-if="item.id == 2">{{ novelDetailData.comments | numberFilter }}</span>
              </div>
              <img v-if="item.id == activeId" class="active_icon" src="@/assets/imgs/acg/tabs_bot_icon.png" alt="" />
            </div>
          </template>
        </ETabV2>
        <SixList
          v-if="activeId === 1"
          :key="1"
          :isNovelItem="true"
          :itemList="lookNovelList"
          :isDetail="true"
          @onRefresh="childonRefresh"
        />
        <Comment
          v-else-if="activeId === 2"
          :key="2"
          ref="comment"
          class="comment-box"
          :object-id="+$route.query.id"
          :objectype="4"
          :show-opr="false"
        />
      </div>
      <!--      </ERefreshLoadV3>-->
    </div>
    <DmPopup ref="popupMsg" @newPopupVip="newPopupVip"></DmPopup>
    <!-- 会员购买弹窗-->
    <BuyVipPop ref="BuyVipPop" />
    <!--  金币购买弹窗-->
    <BuyGoldPop :videoInfo="novelDetailData" ref="BuyGoldPop" />
  </div>
</template>

<script>
import { novelDetail } from '@/api/acg'
import { collect } from '@/api/home'
import { JumpTo } from '@/utils/utils_tools'
import { picViewRightTo } from '@/utils/utils_tools'

export default {
  components: {
    SixList: () => import('@/components/ComicsTypeCard/sixList.vue'),
    Comment: () => import('@/components/comment/index.vue'),
    DmPopup: () => import('@/components/Popup/index.vue'),
    EpisodeInfo: () => import('@/components/Home/EpisodeInfo.vue'),
    SerialStatus: () => import('@/components/Home/SerialStatus.vue'),
    BuyVipPop: () => import('@/components/Popup/buyVipPop.vue'),
    BuyGoldPop: () => import('@/components/Popup/buyGoldPop.vue')
  },
  data() {
    return {
      novelDetailData: {},
      chapterList: [],
      lookNovelList: [],
      pageNum: 0,
      pageSize: 10,
      finished: false,
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      },
      sortId: 0,
      activeId: 1,
      tabList: [
        {
          id: 1,
          name: '推荐'
        },
        {
          id: 2,
          name: '评论'
        }
      ],
      object_id: +this.$route.query.id
    }
  },
  computed: {
    mineAD({ $store }) {
      return $store.getters['mineAD']
    },
    info({ $store }) {
      return $store.getters['getUserInfo']
    },

    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    shoudBuy() {
      if (this.novelDetailData.isVip && !this.isVipMember) {
        return 1
      } else if (this.novelDetailData.price && !this.novelDetailData.isBuy) {
        return 2
      } else {
        if (this.novelDetailData.isVip) {
          return 7 //VIP观看
        } else if (this.novelDetailData.isBuy) {
          return 8 //已购观看
        } else {
          return 9 //免费
        }
      }
    }
  },
  mounted() {
    this.pageNum = 1
    this.getComicsDetail()
  },
  beforeDestroy() {
    this.setHistory()
  },
  methods: {
    newPopupVip() {
      this.$refs.BuyVipPop.showClosed = true
    },
    // 设置历史
    setHistory() {
      let novelData = JSON.parse(JSON.stringify(this.novelDetailData))
      this.$store.dispatch('setNovelHisList', {
        type: 'add',
        item: novelData
      })
    },
    async addLike() {
      const res = await collect({
        flag: !this.novelDetailData.isCollect,
        collectType: 10,
        type: 1,
        object_id: this.object_id
      })
      if (res.code === 200) {
        this.novelDetailData.isCollect = !this.novelDetailData.isCollect
        if (this.novelDetailData.isCollect) {
          return this.$toast('已添加书架')
        } else {
          return this.$toast('移除书架')
        }
      } else {
        return this.$toast(res.tip)
      }
    },
    onClickLeft() {
      this.$router.push('/acg')
    },
    handleChoose(chapterNum) {
      this.novelDetailData.watchNum = chapterNum
    },
    clickImg(item) {
      JumpTo(item.href)
    },
    change(e) {
      this.activeId = e
    },
    switchSort(sort) {
      this.sortId = sort
      this.$refs.comment.switchSort(this.sortId)
    },
    toRead() {
      const item = this.chapterList.find(v => v.chapterNum == this.novelDetailData.watchNum)
      picViewRightTo(
        this,
        item ? item.id : this.chapterList[0].id,
        2,
        this.novelDetailData.price,
        this.novelDetailData.isCollect,
        this.shoudBuy,
        this.object_id
      )
      // const item = this.chapterList.find(v => v.chapterNum == this.novelDetailData.watchNum)
      // this.$router.push({
      //   path: '/acg/player',
      //   query: { id: this.object_id, chapterId: item ? item.id : this.chapterList[0].id }
      // })
    },
    onRefresh() {
      this.pageNum = 0
      this.lookNovelList = []
    },
    childonRefresh(id) {
      this.object_id = id
      this.pageNum = 0
      this.lookNovelList = []
      this.onLoad()
    },
    async onLoad() {
      this.pageNum++
      await this.getComicsDetail()
    },
    async getComicsDetail() {
      const res = await novelDetail({
        id: this.object_id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sort: this.sortId
      })
      if (res.code === 200) {
        console.log(res.data.novelData, 'res.data.novelData')
        this.novelDetailData = res.data.novelData
        this.chapterList = res.data.chapterList
        if (res.data.novelData.lookNovel && res.data.novelData.lookNovel.length) {
          this.lookNovelList = [...this.lookNovelList, ...res.data.novelData.lookNovel]
          if (res.data.novelData.lookNovel.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top_main {
  height: 5.52rem;
  position: relative;
  .mine_top_bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    &::after {
      content: '';
      left: 0;
      top: 0;
      position: absolute;
      background: rgba(42, 22, 20, 0.6);
      backdrop-filter: blur(6px);
      height: 6.4rem;
      width: 100%;
    }
  }
}
.comics_info_main {
  @include flexbox($jc: flex-start);
  padding: 0.2rem 0.32rem;
  color: #fff;
  .comics_right_bg {
    width: 2.56rem;
    height: 3.4rem;
    border-radius: 0.16rem;
    margin-right: 0.24rem;
    flex: none;
    :deep() {
      img {
        border-radius: 0.16rem;
      }
    }
  }
  .comics_info {
    position: relative;
    .title {
      font-size: 0.4rem;
    }
    .tags {
      @include flexbox($jc: flex-start, $fw: wrap);
      margin: 0.24rem 0;
      span {
        border-radius: 0.32rem;
        background: var(--30, #3b3b4d);
        padding: 0.06rem 0.16rem;
        color: var(--80, #ccc);
        margin-right: 0.24rem;
        margin-bottom: 0.12rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .desc {
      color: #999;
      @include textoverflow(3);
    }

    .serialStatus {
      margin-top: 0.32rem;
      font-size: 0.26rem;
    }
  }
}
.add {
  @include flexbox();
  border-radius: 0.24rem;
  border: 1px solid #ffb267;
  padding: 0.03rem 0.2rem;
  color: #ffb267;
}
.bot_main {
  border-radius: 0.4rem 0.4rem 0rem 0rem;
  background: #f5f5f5;
  margin-top: -0.5rem;
  padding: 0.36rem 0.32rem;
  position: relative;
}
.anthology-box {
  position: relative;
  .top {
    @include flexbox($ai: flex-start);
    span {
      &:first-child {
        font-size: 0.32rem;
        font-weight: 600;
      }
    }
    .line {
      width: 0.72rem;
      height: 0.1rem;
      border-radius: 2rem;
      background: #333;
    }
  }
}

.chapter-list {
  margin: 0.2rem 0 0.36rem 0;
}
.to-read {
  width: 5.68rem;
  height: 0.88rem;
  background: $gradientBg;
  color: #fff;
  text-align: center;
  line-height: 0.88rem;
  margin: 0 auto 0.36rem auto;
  border-radius: 0.44rem;
  font-size: 0.36rem;
  font-weight: 500;
}
.comicsAd {
  margin-bottom: 0.36rem;
}
.tabs {
  position: relative;
  .header-right {
    position: absolute;
    right: 0;
    top: 0.1rem;
    width: 1.24rem;
    height: 0.36rem;
    align-items: center;
    color: #999999;
    z-index: 10;
    @include flexbox($jc: flex-start);
    .btn {
      &.active {
        color: #e8619d;
      }
    }
  }
}
.comicsDetail_tabs {
  padding-bottom: 0.2rem;
  border-bottom: 0.01rem solid #e0e0e0;
  margin-bottom: 0.24rem;
  height: 0.8rem;
  font-size: 0.28rem;
  :deep() {
    .van-tab {
      flex: none;

      font-size: 0.28rem;
      color: #999;
    }
    .van-sticky {
    }
    .van-tabs__line {
      display: none;
    }
    .van-tab--active {
      color: #333 !important;
    }
  }
}
.tba-item {
  @include flexbox($jc: center, $fd: column);
}
.active_icon {
  width: 0.24rem;
  height: 0.06rem;
  margin-top: 0.1rem;
}
</style>
