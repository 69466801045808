<template>
  <div v-if="list && list.length" class="topic-type-22">
    <HomeTitle v-if="title" :title="title" :path="`/acg/acgTopicDetail/?title=${title}&type=dream&id=${id}`" />
    <ul class="topic-type-22-list">
      <li v-for="item in list" :key="item.id">
        <DreamShowType1 :sizeType="5" :data="item" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TopicType22',
  components: {
    DreamShowType1: () => import('@/components/Home/showType/dreamType/dreamShowType1.vue'),
    HomeTitle: () => import('@/components/homeTitle.vue')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    id: {
      type: Number,
      default: () => {
        return 0
      }
    },
    item: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
.topic-type-22 {
  margin-bottom: 0.36rem;
}
.topic-type-22-list {
  @include flexbox($jc: flex-start);
  padding-bottom: 0.36rem;
  border-bottom: 0.01rem solid #f0f0f0;
  overflow-x: auto;
  margin-bottom: 0.36rem;
  li {
    width: 2.66rem;

    margin-right: 0.16rem;
    flex: none;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
