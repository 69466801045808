<template>
  <div class="games_list">
    <div class="title">
      <img src="@/assets/imgs/mine/incom_left_icon.png" alt="" />
      官方推荐
    </div>
    <ERefreshLoadV3
      ref="ERefreshLoadV3"
      class="main_list"
      :beforeRefresh="onRefresh"
      :moreData="onLoad"
      :finished="finished"
    >
      <ul class="games_list_data">
        <li v-for="(item, index) in dataList" :key="index" @click="toDown(item)">
          <EDecryptImg class="games_item_cover" :imgURL="item.cover ? item.cover : ''"> </EDecryptImg>
          <p>{{ item.name }}</p>
        </li>
      </ul>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { hGameList } from '@/api/home'
import { JumpTo } from '@/utils/utils_tools'
export default {
  data() {
    return {
      finished: false,
      pageSize: 10,
      pageNum: 1,
      dataList: []
    }
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    // 落地页
    ldyCdn({ $store }) {
      return $store.getters['ldyCdn']
    }
  },
  methods: {
    onRefresh() {
      this.pageNum = 0
      this.dataList = []
    },
    async onLoad() {
      this.pageNum++
      await this.getHgameList()
    },
    toDown(item) {
      // this.$router.push(`/Mine/Hgame/detail?id=${item.id}`)
      if (item.url) {
        JumpTo(item.url)
      }
    },
    async getHgameList() {
      const res = await hGameList({
        pageSize: this.pageSize,
        pageNum: this.pageNum
      })
      if (res.code === 200) {
        if (res.data.list && res.data.list.length > 0) {
          this.dataList = [...this.dataList, ...res.data.list]
          if (res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
        }
      } else {
        this.finished = true
        return this.$toast(res.tip)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  @include flexbox($jc: flex-start);
  font-size: 0.32rem;
  font-weight: 600;

  img {
    margin-right: 0.16rem;
    width: 0.16rem;
    height: 0.44rem;
  }
}

.games_list_data {
  padding: 0.24rem 0.32rem;

  li {
    margin-bottom: 0.24rem;

    .games_item_cover {
      width: 6.86rem;
      height: 1.92rem;
      margin-bottom: 0.12rem;
    }
  }
}
</style>
