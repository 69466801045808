<template>
  <div class="tag-detail">
    <EBackTop :title="topicInfo.title" background="transparent" color="#ffffff" />
    <div class="tag-detail-header-wrap">
      <div class="bg-wrap">
        <EDecryptImg
          class="bg"
          :img-c-d-n="imgCDN"
          img-u-r-l="/image/1f9/4t/t9/1ya/e0635bb91044801d923400248bdc2bf5.jpeg"
        />
      </div>
      <div class="tag-detail-header">
        <div class="header-left">
          <EDecryptImg :img-c-d-n="imgCDN" :img-u-r-l="topicInfo.cover" />
        </div>
        <div class="header-right">
          <div class="header-tag">#{{ topicInfo.title }}</div>
          <div class="header-an">
            <div>{{ topicInfo.publishCount | numberFilter }}个帖子</div>
            <div>{{ topicInfo.hotValue | numberFilter }}浏览</div>
          </div>
          <div class="header-desc">{{ topicInfo.title }}</div>
        </div>
      </div>
    </div>
    <div class="tag-detail-content">
      <ERefreshLoadV3 :finished="finished" :beforeRefresh="refreshData" :moreData="moreData">
        <ETabBar
          :list="sortList"
          :tabbar-config="tabbarConfig"
          :active-num="sort"
          class="tag-detail-tab"
          @change="change"
        >
          <template #default="{ data }">
            <div class="post-list">
              <PostItemWithOpr v-for="(item, index) in communityList" :key="index" :data="item" class="post-item">
                <template #top="{ data }">
                  <TopAvatar :data="data" class="top-avatar" />
                </template>
              </PostItemWithOpr>
            </div>
          </template>
        </ETabBar>
      </ERefreshLoadV3>
    </div>
  </div>
</template>
<script>
import { discuss_content, section_list } from 'api/community'

export default {
  name: 'TagDetail',
  components: {
    PostItemWithOpr: () => import('@/components/Community/postItemWithOpr.vue'),
    TopAvatar: () => import('@/components/Community/topAvatar.vue')
  },
  data() {
    return {
      sort: 0,
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.28rem', //默认字体大小
        fontSizeActive: '0.3rem', //选中字体大小
        fontColor: '#999', //默认字体颜色
        fontColorActive: '#333', //选中字体颜色
        bg: '#ffffff', //默认背景色
        bgActive: '#ffffff', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#333',
        lineWidth: '0', //下划线宽度 String
        sticky: true, //粘性布局
        offsetTop: '0rem', //距顶部距离
        tabFlex: true
      },
      sortList: [
        {
          id: 0,
          name: '最新'
        },
        {
          id: 1,
          name: '最热'
        }
      ],
      pageNum: 0,
      pageSize: 10,
      finished: false, //上拉加载完成
      communityList: []
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    topicInfo() {
      return this.$route.query
    }
  },
  methods: {
    change(e) {
      //切换排序
      this.pageNum = 1
      this.sort = e
      this.communityList = []
      this.getPostList()
    },
    async getPostList() {
      try {
        const res = await discuss_content({
          discussId: +this.$route.params.id,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          sort: this.sort,
          contentType: 5
        })
        if (res.code === 200) {
          this.communityList = [...this.communityList, ...res.data.postList]
          //  图片超出6张只显示6张
          this.communityList.map(item => {
            if (item.imgs?.length > 9) {
              item.imgs = item.imgs.splice(0, 9)
            }
          })
          if (!res.data.postList || res.data.postList.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.finished = true
        this.$toast('请求错误，请稍后再试')
      }
    },
    //上拉刷新
    refreshData() {
      this.finished = false
      this.pageNum = 0
      this.communityList = []
    },
    //下拉加载
    async moreData() {
      this.pageNum += 1
      await this.getPostList()
    }
  }
}
</script>

<style scoped lang="scss">
.tag-detail {
  :deep(.BackTop) {
    position: fixed;
    z-index: 2;
  }
  .tag-detail-header-wrap {
    position: relative;
    height: 4.18rem;
    .bg-wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      .bg {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        background-color: rgba(0, 0, 0, 0.7);
        //filter: blur(1px);
      }
    }

    .tag-detail-header {
      padding: 1.3rem 0.32rem;
      position: relative;
      display: flex;
      align-items: center;
      .header-left {
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 0.13rem;
        overflow: hidden;
        flex-shrink: 0;
      }
      .header-right {
        margin-left: 0.24rem;
        .header-tag {
          color: #ffffff;
          font-size: 0.28rem;
        }
        .header-an {
          margin-top: 0.12rem;
          display: flex;
          align-items: center;
          color: #999999;
          font-size: 0.24rem;
          line-height: 0.34rem;
          > :nth-child(2) {
            margin-left: 0.16rem;
          }
        }
        .header-desc {
          margin-top: 0.12rem;
          color: #999999;
          font-size: 0.24rem;
          line-height: 0.34rem;
          @include textoverflow(1);
        }
      }
    }
  }
  .tag-detail-content {
    top: -0.6rem;
    position: relative;
    padding: 0.64rem 0;
    background-color: #fff;
    border-radius: 0.4rem 0.4rem 0 0;
    :deep(.van-tabs__wrap) {
      border-bottom: 0.01rem solid #f5f5f5;
      .van-sticky--fixed {
        z-index: 9999;
      }
      .van-tabs__nav {
        .van-tab {
          width: 50%;
        }
      }
      .van-tab--active {
        &::after {
          transition: all 0.2s ease-in-out;
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.08rem;
          background: url('~@/assets/imgs/tab_bottom.png') center bottom/100% 100% no-repeat;
        }
      }
    }
    .tag-detail-tab {
      :deep() {
        .van-sticky {
          width: 100%;
          max-width: $pcMaxWidth;
        }
      }
      .post-list {
        padding: 0 0.32rem;
        .post-item {
          margin-top: 0.36rem;
          border-bottom: 0.01rem solid #f0f0f0;
          .top-avatar {
            margin-bottom: 0.24rem;
          }
        }
      }
    }
  }
}
</style>
