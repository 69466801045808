<template>
  <div class="video-list">
    <div v-for="item in list" :key="item.id">
      <video-show-type1 :data="item"></video-show-type1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoList2',
  components: {
    VideoShowType1: () => import('../../showType/videoListType/videoshowType1.vue')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },

  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.video-list {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;

  > div {
    width: 3.36rem;
    height: 3rem;
    margin-bottom: 0.24rem;
  }
}
</style>
