<template>
  <div v-if="list && list.length" class="topic-type-23">
    <HomeTitle v-if="title" :title="title" :path="`/acg/acgTopicDetail/?title=${title}&type=dream&id=${id}`" />
    <div class="topic-type-23-list">
      <div v-for="(data, index) in list" :key="data.id">
        <DreamShowType1 :data="data" :sizeType="index === 0 ? 3 : 1" />
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    DreamShowType1: () => import('@/components/Home/showType/dreamType/dreamShowType1.vue'),
    HomeTitle: () => import('@/components/homeTitle.vue')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    id: {
      type: Number,
      default: () => {
        return 0
      }
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.topic-type-23 {
  margin-bottom: 0.36rem;
}
.topic-type-23-list {
  @include flexbox($fw: wrap);
  > div {
    flex: none;
    margin-bottom: 0.16rem;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
