<template>
  <ERefreshLoadV3 ref="ERefreshLoadV3" :beforeRefresh="onRefresh" :moreData="onLoad" :finished="finished">
    <ul class="main_list">
      <li v-for="(item, index) in dataList" :key="item.id" class="item">
        <post-item-with-opr :key="index" :data="item" class="post-item">
          <template #top="{ data }">
            <top-avatar :data="data" class="top-avatar" />
          </template>
        </post-item-with-opr>
        <!-- 遮罩层 -->
        <div v-if="showMask" class="overlay" @click.stop="clickItem(item)">
          <img
            :src="
              item.check
                ? require('@/assets/imgs/mine/active_check.png')
                : require('@/assets/imgs/mine/default_check.png')
            "
            alt=""
          />
        </div>
      </li>
    </ul>
  </ERefreshLoadV3>
</template>

<script>
import { collect_list, collect_del } from 'api/user'

export default {
  components: {
    PostItemWithOpr: () => import('@/components/Community/postItemWithOpr.vue'),
    TopAvatar: () => import('@/components/Community/topAvatar.vue')
  },
  props: {
    cardType: {
      type: String,
      default: 'collect'
    },
    showMask: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newList: [],
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 关注列表
      dataList: [],
      // 当前页
      pageNum: 0,
      // 当前个数
      pageSize: 10,
      showUpBtn: false
    }
  },
  computed: {
    info({ $store }) {
      return $store.getters['getUserInfo']
    },
    // 获取长视频历史记录
    getPostList({ $store }) {
      return $store.getters['getPostList']
    }
  },

  mounted() {},

  methods: {
    clickItem(item) {
      this.$set(item, 'check', !item.check)
    },
    async delOne() {
      if (this.cardType === 'history') {
        this.$store.dispatch('setPostList', { type: 'del', arr: this.dataList.filter(item => !item.check) })
        this.dataList = JSON.parse(JSON.stringify(this.getPostList))
        this.finished = true
      } else if (this.cardType === 'collect') {
        // 收藏列表删除
        const res = await collect_del({
          collectType: 5,
          objectIds: this.dataList.filter(item => item.check).map(item => item.base.id),
          type: 1
        })
        if (res.code === 200) {
          this.pageNum = 1
          this.dataList = []
          await this.getPostCollectList()
        } else {
          return this.$toast('操作失败，请联系后台管理员！')
        }
      }
    },
    toSelectAll(val = true) {
      this.dataList.forEach(item => {
        this.$set(item, 'check', val)
      })
    },
    // 下拉加载
    async onLoad() {
      this.pageNum++
      if (this.cardType === 'collect') {
        await this.getPostCollectList()
      } else if (this.cardType === 'history') {
        this.dataList = JSON.parse(JSON.stringify(this.getPostList))
        this.finished = true
      }
    },
    // 上拉刷新
    async onRefresh() {
      this.pageNum = 0
      this.getPostCollectList()
      this.dataList = []
    },
    // 收藏列表
    async getPostCollectList() {
      const res = await collect_list({
        collectType: 5,
        userId: this.info.id,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        type: 1
      })
      if (res.code == 200) {
        this.dataList = [...this.dataList, ...(res.data.postList || [])]

        if (res.data.postList?.length < this.pageSize || !res.data.postList) {
          this.finished = true
        }
      } else {
        this.finished = true
      }
    }
    // 购买列表 pay_history
    // async getPlayPayList() {
    //   const res = await pay_history({
    //     type: 13,
    //     pageSize: this.pageSize,
    //     pageNum: this.pageNum
    //   })
    //   if (res.code == 200) {
    //     this.dataList = [...this.dataList, ...(res.data.dataList || [])]
    //     if (res.data.dataList?.length < this.pageSize || !res.data.dataList) {
    //       this.finished = true
    //     }
    //   } else {
    //     this.finished = true
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
.main_list {
  padding: 0.32rem;
  @include flexbox($fw: wrap);
}
.item {
  position: relative;
  margin-bottom: 0.02rem;
}
.overlay {
  width: 100%;
  height: calc(100% - 1.05rem);
  border-radius: 0.2rem;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  img {
    width: 0.48rem;
    height: 0.48rem;
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
  }
}
.post-item {
  border-bottom: 0.01rem solid #f0f0f0;
}
</style>
