<!-- home -->
<template>
  <div class="trade-container">
    <!-- && type === homecategoryV2[0].id -->
    <!-- <SwiperShow :imgList="avAD" v-if="avAD && avAD.length"/> -->
    <!-- :class="{ 'index-container': !isTrade, 'trade-container': isTrade, 'mt-top': type == 10172 }" -->
    <!-- 我的发布 -->

    <!--    <div class="myPubilc" @click="$router.push('/trade/myPost')" v-if="type == 1">我的发布</div>-->
    <SearchBox class="search-box" @onRelease="openSheet" />
    <!--切换面板 -->
    <ETab
      class="container-tab"
      :sticky="true"
      :tab-list="findCategory"
      :v-model="type"
      :animated="false"
      :line-switch="true"
      :line-bg="'#E8619D'"
      :line-height="'0.08rem'"
      :line-width="'0.4rem'"
      :title-active-color="'#E8619D'"
      :title-inactive-color="'#999999'"
      :size-tab="'0.32rem'"
      :swipe-threshold="4"
      :size-active="'.38rem'"
      @change="change"
    >
      <template #default="scope">
        <div class="index-container">
          <component :is="compComponent(scope.item)" :nav-item="scope"></component>
        </div>
      </template>
    </ETab>
    <!-- 发布按钮 -->
    <div class="ub_btn" :style="{ transform: `translate(${showIcon ? 0 : '34.667vw'})` }" @click="openSheet"></div>
    <van-action-sheet v-model="showUpBtn" title="选择发布类型">
      <ul class="btn_detail">
        <li class="btn-item" @click.stop="$router.push(`/community/publish?postType=${'img'}&from=2&cid=${type}`)">
          <img src="@/assets/imgs/community/up_photo.png" alt />
          <div class="label">图片</div>
        </li>
        <li class="btn-item" @click.stop="$router.push(`/community/publish?postType=${'video'}&from=2&cid=${type}`)">
          <img src="@/assets/imgs/community/up_video.png" alt />
          <div class="label">视频</div>
        </li>
        <li class="btn-item" @click.stop="$router.push(`/community/publish?postType=${'txt'}&from=2&cid=${type}`)">
          <img src="@/assets/imgs/community/up_photo_txt.png" alt />
          <div class="label">图文</div>
        </li>
      </ul>
    </van-action-sheet>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
export default {
  name: 'CommunityInd',
  components: {
    // SwiperShow: () => import('@/components/Swiper/index.vue'),
    // AnHeiYou: () => import('@/components/AnHeiYou/home.vue'),
    // AnWangJiaoYi: () => import('@/components/AnWangJiaoYi/home.vue'),
    // DiWangTiaoJiao: () => import('@/components/DiWangTiaoJiao/home.vue'),
    CommunityIndex: () => import('@/components/Community/CommunityIndex.vue'),
    CommunityTopic: () => import('@/components/Community/communityTopic/CommunityTopic.vue'),
    SearchBox: () => import('@/components/Community/SeachBox.vue')
  },
  mixins: [scrollMixin],
  data() {
    return {
      type: 0, // 切换的id
      showUpBtn: false,
      showIcon: true,
      oldScrollTop: 0
    }
  },
  computed: {
    findCategory({ $store }) {
      return $store.getters['postCategory']
    }
  },
  created() {
    this.type = this.findCategory[0].id
  },
  activated() {
    console.log('开始监听')
    window.addEventListener('scroll', this.scrollEvent, true)
  },
  deactivated() {
    console.log('移除监听')
    window.removeEventListener('scroll', this.scrollEvent, true)
  },
  methods: {
    scrollEvent() {
      this.showIcon = false
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.scrolledEndEvent()
      }, 300)
    },
    scrolledEndEvent() {
      this.showIcon = true
      clearTimeout(this.timer)
      this.timer = null
    },
    change(type) {
      this.type = type
    },
    //选择帖子类型
    openSheet() {
      this.showUpBtn = true
    },
    compComponent(data) {
      // 1=普通 2=话题
      switch (data.showType) {
        case 2:
          return 'CommunityTopic'
        default:
          return 'CommunityIndex'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.trade-container {
  .search-box {
    position: fixed;
    top: 0;
    left: 50%;
    right: 0;
    z-index: 99;
    width: 100%;
    transform: translate(-50%, 0);
    max-width: $pcMaxWidth;
  }

  .container-tab {
    :deep() {
      .van-sticky {
        position: fixed;
        top: 0.88rem;
        left: 50%;
        right: 0;
        z-index: 99;
        width: 100%;
        transform: translate(-50%, 0);
        max-width: $pcMaxWidth;
      }

      .van-tabs__nav {
        justify-content: flex-start;
        background-size: cover;
      }

      .van-tab {
        flex: none;
      }
    }
  }
}

.myPubilc {
  border-radius: 0.12rem;
  background: #00f6f8;
  padding: 0.08rem 0.16rem;
  position: fixed;
  top: 0.2rem;
  right: 0.1rem;
  z-index: 100;
  color: #333;
}

.ub_btn {
  position: fixed;
  width: 0.96rem;
  height: 0.96rem;
  background: url('~@/assets/imgs/community/publish.png') center center/100% 100% no-repeat;
  right: 0.36rem;
  top: 60%;
  transition: transform 0.5s;
  z-index: 10;

  img {
    width: 0.9rem;
    height: 0.9rem;

    margin-bottom: 0.36rem;
  }
}
.btn_detail {
  padding-top: 0.7rem;
  padding-bottom: 0.76rem;
  @include flex-center;
  text-align: center;
  .btn-item {
    margin: 0.25rem;
    color: #999999;
    .label {
      margin-top: 0.16rem;
    }
  }
}

.index-container {
  margin-top: calc(44px + 0.88rem);
  position: relative;
  background-size: cover;
  padding: 0.32rem 0 1.04rem;
}
</style>
