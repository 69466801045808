<template>
  <div>
    <div class="girllist">
      <div class="girllist-top">
        <ul>
          <li :class="{ active: activeIndex == 0 }" @click="handleClick(0)">
            热门推荐
            <img src="@/assets/imgs/discover/italic.png" alt="" />
          </li>
          <li :class="{ active: activeIndex == 1 }" @click="handleClick(1)">
            最多片量
            <img src="@/assets/imgs/discover/italic.png" alt="" />
          </li>
          <li :class="{ active: activeIndex == 2 }" @click="handleClick(2)">最高评分</li>
        </ul>
        <!-- <div @click="$router.push('/discover/filter')"> -->
        <div>
          <img src="@/assets/imgs/discover/vertical.png" alt="" />
          <img src="@/assets/imgs/discover/filter.png" alt="" />
          <span>女优筛选</span>
        </div>
      </div>
    </div>
    <div class="hot-item">
      <div v-for="item in mediaActorList" :key="item.id" @click="clickActor(item)">
        <!-- <img src="@/assets/imgs/temp/hbch.png" alt="" /> -->
        <EDecryptImg :imgCDN="imgCDN" class="cover-img" :imgURL="item.avatar" />
        <div class="hot-item-right">
          <div>{{ item.name }}</div>
          <div>{{ item.desc }}</div>
          <div>
            <span>视频：{{ item.movieCount }}</span>
            <span>图片：{{ item.photoCount }}</span>
            <span>点赞：{{ item.real.likes | numberFilter }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mediaActorList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeIndex: 0
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    handleClick(idx) {
      this.activeIndex = idx
      this.$emit('activeIndexChange', idx)
    },
    clickActor(item) {
      this.$router.push({
        path: `/discover/actor/${item.id}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.girllist {
  &-top {
    @include flexbox;
    color: $grey;
    font-family: 'PingFang SC';
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 400;

    > ul {
      display: flex;

      li {
        margin-right: 0.12rem;
        transition: all 0.5s;

        &.active {
          color: $txtActive;
        }
      }

      img {
        width: 0.12rem;
      }
    }

    > div {
      @include flex-align-center;
      flex: 1;
      justify-content: flex-end;
      img {
        height: 0.28rem;

        &:nth-of-type(2) {
          height: 0.34rem;
          margin: 0 0.08rem 0 0.1rem;
        }
      }
    }
  }
}

.hot-item {
  margin-top: 0.24rem;

  .cover-img {
    width: 1.56rem;
    height: 1.56rem;
    border-radius: 0.06rem;
    flex-shrink: 0;

    :deep() img {
      border-radius: 0.06rem;
    }
  }

  > div {
    @include flex-align-center;
    margin-bottom: 0.2rem;
  }

  &-right {
    margin-left: 0.3rem;

    > div {
      &:nth-child(1) {
        color: $txtDefault;
        font-size: 0.32rem;
        font-style: normal;
        font-weight: 400;
      }

      &:nth-child(2) {
        color: $grey;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        @include textoverflow;
        margin: 0.08rem 0;
      }

      &:nth-child(3) {
        display: flex;
        color: $grey67;

        span {
          margin-right: 0.18rem;
          position: relative;

          &:nth-child(-n + 2) {
            &::after {
              content: '|';
              position: absolute;
              right: -0.08rem;
              top: 0;
              // transform: translate(0, -50%);
              width: 0.02rem;
              height: 0.24rem;
            }
          }
        }
      }
    }
  }
}
</style>
