<template>
  <div v-if="list && list.length" class="topic-type-24">
    <HomeTitle v-if="title" :title="title" :path="`/acg/acgTopicDetail/?title=${title}&type=dream&id=${id}`" />
    <div class="topic-type-24-list">
      <DreamShowType1 v-for="data in list" :key="data.id" :sizeType="2" :data="data" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    DreamShowType1: () => import('@/components/Home/showType/dreamType/dreamShowType1.vue'),
    HomeTitle: () => import('@/components/homeTitle.vue')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    id: {
      type: Number,
      default: () => {
        return 0
      }
    },
    item: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
.topic-type-24 {
  margin-bottom: 0.36rem;
}
.topic-type-24-list {
  //border-bottom: 0.01rem solid #f0f0f0;
  margin-bottom: 0.36rem;
  @include flexbox($jc: flex-start, $fw: wrap);

  > div {
    margin-right: 0.14rem;
    flex: none;
    margin-bottom: 0.2rem;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}
</style>
