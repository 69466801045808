<template>
  <EWaterFall :datasList="list">
    <template #left="{ handleLoad, data }">
      <div class="item">
        <shortVideoshowType1 :list="list" :data="data" class="leftItem" :sizeType="2" @loadedImg="handleLoad">
          <template #mask="{ data: item }">
            <slot name="mask" :data="item"></slot>
          </template>
        </shortVideoshowType1>
      </div>
    </template>
    <template #right="{ handleLoad, data }">
      <div class="item">
        <shortVideoshowType1 :list="list" :data="data" class="rightItem" :sizeType="2" @loadedImg="handleLoad">
          <template #mask="{ data: item }">
            <slot name="mask" :data="item"></slot>
          </template>
        </shortVideoshowType1>
      </div>
    </template>
  </EWaterFall>
</template>
<script>
export default {
  name: 'DreamWaterFallList',
  components: {
    shortVideoshowType1: () => import('@/components/Home/showType/videoListType/shortVideoshowType1.vue')
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  position: relative;
}
.right {
  margin-left: auto;
}
.leftItem,
.rightItem {
  margin-bottom: 0.14rem !important;
  border-radius: 0.08rem;
}

.rightItem {
  margin-left: auto;
}
</style>
