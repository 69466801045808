<template>
  <div class="record-container">
    <EBackTop title="提现记录"></EBackTop>
    <ERefreshLoadV3
      ref="ERefreshLoadV3"
      class="main_list"
      :before-refresh="onRefresh"
      :more-data="onLoad"
      :finished="finished"
    >
      <ul class="record-listWrap">
        <li v-for="(item, index) in transList" :key="index" class="item">
          <ul>
            <li>
              <p class="order_num">
                <span>订单编号:{{ item.tradeNo }}</span>
                <span
                  v-clipboard:copy="item.tradeNo"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  class="btn"
                  >复制</span
                >
              </p>
              <p class="statu">
                <span class="statu_txt"
                  >{{ item.mode == 1 ? '支付宝' : '银行卡' }}-{{ item.status == 1 ? '已拒绝' : '已完成' }}</span
                >
                <span v-if="item.status == 1" class="reject_btn" @click="showRejectFn(item)">查看原因</span>
              </p>
              <p class="time">
                <span>{{ item.createdAt | dateFormat08 }}</span>
                <span class="price">{{ item.amount | changeMoneyYuan }}元</span>
              </p>
              <p v-if="showReject && item == activeItem" class="show_rejecj" @click="showReject = false">
                {{ item.reason }}
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </ERefreshLoadV3>
  </div>
</template>
<script>
import { withdrawal_list } from 'api/user'
export default {
  components: {},
  data() {
    return {
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 关注列表
      transList: [],

      // 当前页
      pageNum: 0,
      // 当前个数
      pageSize: 10,
      showReject: false,
      activeItem: 0
    }
  },
  mounted() {
    // this.onRefresh()
  },
  methods: {
    onCopy() {},
    onError() {},
    // 获取流水记录
    async get_transList() {
      try {
        const res = await withdrawal_list({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.transList = [...this.transList, ...res.data.list]
          if (res.data && res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
          return this.$toast(res.tip)
        }
      } catch (error) {
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    showRejectFn(item) {
      this.activeItem = item
      this.showReject = true
    },
    // 下拉加载
    async onLoad() {
      this.pageNum++
      await this.get_transList()
    },
    // 上拉刷新
    onRefresh() {
      this.pageNum = 0
      this.transList = []
      this.finished = false
    }
  }
}
</script>
<style lang="scss" scoped>
.record-container {
  .record-listWrap {
    margin: 0.3rem 0;
    padding: 0 0.34rem;

    .item {
      position: relative;
      padding: 0.38rem 0.24rem;
      border-radius: 0.2rem;
      background: var(--4, rgba(0, 0, 0, 0.04));
      font-size: 0.28rem;
      margin-bottom: 0.24rem;
      .order_num {
        @include flexbox();

        .btn {
          padding: 0.08rem 0.24rem;
          border-radius: 2rem;
          background-color: $txtActive;
          color: #fff;
        }
      }

      .statu {
        @include flexbox($jc: flex-start);
        margin: 0.08rem 0;

        .statu_txt {
          padding: 0.04rem 0.16rem;
          border-radius: 0.6rem;
          background: rgba(0, 0, 0, 0.08);
          color: rgba($color: #333, $alpha: 0.6);
          font-size: 0.24rem;
          margin-right: 0.24rem;
        }

        .reject_btn {
          color: #0087ff;
          font-size: 0.24rem;
        }
      }
    }

    .time {
      @include flexbox();
      color: #666;

      .price {
        color: $txtActive;
      }
    }
  }
  .show_rejecj {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 124, 140, 0.15);

    backdrop-filter: blur(2px);
    @include flexbox($jc: center);
    // &::before{
    //      position: absolute;
    //         top: 0;
    //         left: 0;
    //         height: 100%;
    //         width: 100%;

    // }
  }
}
</style>
