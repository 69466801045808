<template>
  <div class="comics_detail">
    <div class="top_main">
      <!-- 背景 -->
      <EBackTop
        :background="'transparent'"
        @goBack="goBack"
        :fixed="false"
        color="#fff"
        title=""
        @click-right="addLike"
      >
        <slot slot="right" name="right">
          <div class="add">
            <van-icon
              v-if="!comicsDetailData?.isCollect"
              color="#ffb267"
              :name="require('@/assets/imgs/acg/shelf.png')"
              style="margin-right: 0.08rem"
            />
            <van-icon v-else color="#ffb267" name="success" />
            {{ comicsDetailData?.isCollect ? '已加入' : '加入书架' }}
          </div>
        </slot>
      </EBackTop>
      <EDecryptImg class="mine_top_bg" :imgURL="comicsDetailData?.coverImg"> </EDecryptImg>
      <div class="comics_info_main">
        <EDecryptImg class="comics_right_bg" :imgURL="comicsDetailData?.coverImg"> </EDecryptImg>
        <div class="comics_info">
          <p class="title">{{ comicsDetailData?.title }}</p>
          <div class="tags">
            <span
              v-for="item in comicsDetailData?.tags"
              :key="item.id"
              @click.stop="$router.push(`/acg/AcgTagsList??title=${item.name}&type=comics&id=${item.id}`)"
              >#{{ item.name }}</span
            >
          </div>
          <p class="desc">{{ comicsDetailData?.desc }}</p>
        </div>
      </div>
    </div>
    <!-- 底部信息 -->
    <div class="bot_main">
      <!-- 选集盒子 -->
      <div class="anthology-box">
        <div class="top">
          <span>选集</span>
          <span class="line"></span>
          <span @click="handelStatus = !handelStatus">
            <img v-if="handelStatus" src="@/assets/imgs/acg/up_sl.png" alt="" />
            <img v-else src="@/assets/imgs/acg/up_lb.png" alt="" />
            {{ handelStatus ? '缩略模式' : '列表模式' }}
          </span>
        </div>
        <ul class="chapter-list" :class="!handelStatus ? 'upstyle' : ''">
          <li
            v-for="item in chapterList"
            :key="item.id"
            :class="item.chapterNum === comicsDetailData?.watchNum ? 'active' : ''"
            @click="toPicview(item)"
          >
            <div>{{ item.chapterNum }}</div>
          </li>
        </ul>
        <!-- 开始阅读按钮 -->
        <div class="to-read" @click="toRead">
          {{ comicsDetailData?.watchNum > 1 ? `续看${comicsDetailData?.watchNum}话` : `开始阅读` }}
        </div>
      </div>
      <!-- 广告轮播 -->
      <div v-if="comicsTopAd.length" class="comicsAd">
        <EADSwiper :list="comicsTopAd" :slidesPerView="1" keyName="cover" :EADconfig="EADconfig" @clickImg="clickImg">
        </EADSwiper>
      </div>

      <!-- 切换tabs -->
      <div class="tabs">
        <!-- 最热最新 -->
        <div v-if="activeId == 2" class="header-right">
          <div class="btn" :class="{ active: sortId === 0 }" @click="switchSort(0)">最新</div>
          <div>/</div>
          <div class="btn" :class="{ active: sortId === 1 }" @click="switchSort(1)">最热</div>
        </div>
        <ETabV2
          class="comicsDetail_tabs"
          background="transparent"
          :tabList="tabList"
          :activeId="activeId"
          :sizeActive="'.28rem'"
          :sizeTab="'.28rem'"
          :sticky="true"
          :ellipsis="false"
          @change="e => (activeId = e)"
        >
          <template #title="{ item }">
            <div class="tba-item">
              <div>
                {{ item.name }} <span v-if="item.id == 2">{{ comicsDetailData?.comments | numberFilter }}</span>
              </div>
              <img v-if="item.id == activeId" class="active_icon" src="@/assets/imgs/acg/tabs_bot_icon.png" alt="" />
            </div>
          </template>
        </ETabV2>
        <List v-if="activeId === 1" :id="$route.query.id" :sortId="sortId" />
        <Comment
          v-else-if="activeId === 2"
          :key="2"
          class="comment-box"
          @comments="comments"
          :typeId="sortId"
          :object-id="+$route.query.id"
          :objectype="3"
          :show-opr="false"
        />
      </div>
    </div>
    <DmPopup ref="popupMsg" @newPopupVip="newPopupVip"></DmPopup>
    <!-- 会员购买弹窗-->
    <BuyVipPop ref="BuyVipPop" />
    <!--  金币购买弹窗-->
    <BuyGoldPop :videoInfo="comicsDetailData" ref="BuyGoldPop" />
  </div>
</template>

<script>
import { comicsDetail } from '@/api/acg'
import { picViewRightTo } from '@/utils/utils_tools'
import { JumpTo } from '@/utils/utils_tools'
import scrollMixin from '@/utils/scrollMixin'
import { collect } from 'api/home'
export default {
  mixins: [scrollMixin],
  components: {
    List: () => import('./components/list.vue'),
    Comment: () => import('@/components/comment/index.vue'),
    DmPopup: () => import('@/components/Popup/index.vue'),
    BuyVipPop: () => import('@/components/Popup/buyVipPop.vue'),
    BuyGoldPop: () => import('@/components/Popup/buyGoldPop.vue')
  },
  data() {
    return {
      handelStatus: true,
      comicsDetailData: {},
      chapterList: [],
      pageNum: 1,
      pageSize: 10,
      finished: false,
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      },
      sortId: 0,
      activeId: 1,

      tabList: [
        {
          id: 1,
          name: '推荐'
        },
        {
          id: 2,
          name: '评论'
        }
      ]
    }
  },
  computed: {
    //获取漫画轮播
    comicsTopAd({ $store }) {
      return $store.getters['comicsTopAd']
    },

    info({ $store }) {
      return $store.getters['getUserInfo']
    },

    isVipMember({ $store }) {
      return $store.getters['user/isVipMember']
    },
    //判断是否能看
    shoudBuy() {
      if (this.comicsDetailData.isVip && !this.isVipMember) {
        return 1
      } else if (this.comicsDetailData.price && !this.comicsDetailData.isBuy) {
        return 2
      } else {
        if (this.comicsDetailData.isVip) {
          return 7 //VIP观看
        } else if (this.comicsDetailData.isBuy) {
          return 8 //已购观看
        } else {
          return 9 //免费
        }
      }
    }
  },
  watch: {
    $route: {
      handler(n) {
        if (n.query.id) {
          this.getComicsDetail()
        }
      },
      deep: true,
      immediate: true
    }
  },
  beforeDestroy() {
    this.setHistory()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    newPopupVip() {
      this.$refs.BuyVipPop.showClosed = true
    },
    comments(val) {
      this.$set(this.comicsDetailData, 'comments', val)
    },
    // 设置历史
    setHistory() {
      let comicsDetailData = JSON.parse(JSON.stringify(this.comicsDetailData))
      this.$store.dispatch('setComicsHisList', {
        type: 'add',
        item: comicsDetailData
      })
    },
    clickImg(item) {
      JumpTo(item.href)
    },
    toPicview(item) {
      //漫画预览页，直接拿id显示当前
      picViewRightTo(this, item.id, 1, this.comicsDetailData.price, this.comicsDetailData.isCollect, this.shoudBuy)
    },
    switchSort(sort) {
      this.sortId = sort
    },
    toRead() {
      // this.$router.push(`/acg/ComicsReadPage?id=${this.comicsDetailData.id}&title=${this.comicsDetailData.title}`)
      picViewRightTo(
        this,
        this.chapterList[this.comicsDetailData.watchNum == 0 ? 0 : this.comicsDetailData.watchNum - 1].id,
        1,
        this.comicsDetailData.price,
        this.comicsDetailData.isCollect,
        this.shoudBuy
      )
    },

    async addLike() {
      const res = await collect({
        flag: !this.comicsDetailData.isCollect,
        object_id: +this.$route.query.id,
        type: 1,
        collectType: 8
      })
      if (res.code === 200) {
        this.comicsDetailData.isCollect = !this.comicsDetailData.isCollect
        if (this.comicsDetailData.isCollect) {
          return this.$toast('已添加书架')
        } else {
          return this.$toast('移除书架')
        }
      } else {
        return this.$toast(res.tip)
      }
    },
    async getComicsDetail() {
      const res = await comicsDetail({
        id: +this.$route.query.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sort: this.sortId
      })
      if (res.code === 200) {
        this.comicsDetailData = res.data.comicsData
        this.chapterList = res.data.chapterList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top_main {
  height: 5.52rem;
  position: relative;
  .add {
    @include flexbox();
    border-radius: 0.24rem;
    border: 1px solid #ffb267;
    padding: 0.03rem 0.2rem;
    color: #ffb267;
  }
  .mine_top_bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    &::after {
      content: '';
      left: 0;
      top: 0;
      position: absolute;
      background: rgba(42, 22, 20, 0.6);
      backdrop-filter: blur(6px);
      height: 6.4rem;
      width: 100%;
    }
  }
  .comics_info_main {
    @include flexbox($jc: flex-start);
    padding: 0.2rem 0.32rem;
    color: #fff;
    .comics_right_bg {
      width: 2.56rem;
      height: 3.4rem;
      border-radius: 0.16rem;
      margin-right: 0.24rem;
      overflow: hidden;
      flex: none;
    }
    .comics_info {
      position: relative;
      .title {
        font-size: 0.4rem;
        @include textoverflow(2);
      }
      .tags {
        @include flexbox($jc: flex-start);
        margin: 0.24rem 0;
        max-width: 4rem;
        overflow-x: scroll;
        span {
          border-radius: 0.32rem;
          background: var(--30, #3b3b4d);
          padding: 0.06rem 0.16rem;
          color: var(--80, #ccc);
          margin-right: 0.24rem;
          margin-bottom: 0.12rem;
          flex: none;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .desc {
        color: #999;
        @include textoverflow(3);
      }
    }
  }
}

.bot_main {
  border-radius: 0.4rem 0.4rem 0rem 0rem;
  background: #f5f5f5;
  margin-top: 0rem;
  padding: 0.36rem 0.32rem;
  position: relative;
  .anthology-box {
    position: relative;
    .top {
      @include flexbox($ai: flex-start);
      span {
        &:first-child {
          font-size: 0.32rem;
          font-weight: 600;
        }
        &:last-child {
          display: flex;
          align-items: center;
          img {
            width: 0.24rem;
            height: 0.24rem;
            margin-right: 0.1rem;
          }
        }
      }
      .line {
        width: 0.72rem;
        height: 0.1rem;
        border-radius: 2rem;
        background: #333;
      }
    }
    .chapter-list {
      @include flexbox($jc: flex-start);
      margin: 0.2rem 0 0.36rem 0;
      overflow-x: auto;
      li {
        width: 0.96rem;
        height: 0.96rem;
        font-size: 0.28rem;
        border-radius: 0.16rem;
        background: rgba(0, 0, 0, 0.1);
        text-align: center;
        line-height: 0.96rem;
        margin-right: 0.2rem;
        position: relative;
        flex: none;
      }
      .active {
        border-radius: 0.16rem;
        background: rgba(221, 0, 27, 0.1);
        color: $txtActive;
      }
    }
    .upstyle {
      flex-wrap: wrap;
      > li:nth-child(6n + 6) {
        margin: 0.2rem 0 0.2rem !important;
      }
    }
    .to-read {
      width: 5.68rem;
      height: 0.88rem;
      background: $gradientBg;
      color: #fff;
      text-align: center;
      line-height: 0.88rem;
      margin: 0 auto 0.36rem auto;
      border-radius: 0.44rem;
      font-size: 0.36rem;
      font-weight: 500;
    }
  }
  .comicsAd {
    margin-bottom: 0.36rem;
  }
  .tabs {
    position: relative;
    .header-right {
      position: absolute;
      right: 0;
      top: 0.3rem;
      width: 1.24rem;
      height: 0.36rem;
      align-items: center;
      color: #999999;
      z-index: 10;
      @include flexbox($jc: flex-start);
      .btn {
        &.active {
          color: #e8619d;
        }
      }
    }
    .comicsDetail_tabs {
      padding-bottom: 0.2rem;
      border-bottom: 0.01rem solid #e0e0e0;
      margin-bottom: 0.24rem;
      height: 0.8rem;
      font-size: 0.28rem;
      .tba-item {
        @include flexbox($jc: center, $fd: column);
      }
      .active_icon {
        width: 0.24rem;
        height: 0.06rem;
        margin-top: 0.1rem;
      }
      :deep() {
        .van-tab {
          flex: none;
          font-size: 0.28rem;
          color: #999;
        }
        .van-sticky {
        }
        .van-tabs__line {
          display: none;
        }
        .van-tab--active {
          color: #333 !important;
        }
      }
    }
  }
}
</style>
