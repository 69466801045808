<template>
  <div>
    <EBackTop title="发布规则"> </EBackTop>
    <div class="publish-rule" v-html="content"></div>
  </div>
</template>
<script>
export default {
  name: 'PublishRule',
  data() {
    return {
      content: `
欢迎优秀的您加入Mr.色，我们珍视每一位UP主，始终致力于为大家带来最优质的产品与服务。您的作品在妖精动漫的售卖无时间、次数限制，销售的次数越多，获得的收入就会越多。无论您是在睡觉还是在工作，它们都将持续为您带来源源不断的收入。

上传规则
1.UP主及普通用户上传收费视频比例为2:1，即上传2个免费视频才可上传1个收费视频。
2.原创举牌up主上传收费视频比例为1:1，即上传1个免费视频才可上传1个收费视频。
3.视频清晰度需在360P以上，且时长不小于30秒。
4.套图大于6张才可以设置价格。
5.审核时间为48小时内，请在[创作中心]查收反馈。
6.视频中的当事人须满18岁以上，且当事人同意视频被上传分享。

审核规则
1.原创拍摄、原创剪辑作品，会更容易通过并获得官方推荐。
2.禁止直接搬运网络视频，重复率高且不容易通过，多次违规将降低账号推荐权重。
3.禁止在视频/图片中添加个人联系方式或插入广告网址将不会通过审核。
4.禁止上传幼女、人兽、真实强奸等侵害他人的视频。
5.加强用户隐私性，允许原创视频为人物面部等重要部分添加遮挡或马赛克。
6.上传的视频内容不符合上传要求将不会通过审核，如若退回视频未作修改再次发起审核将禁止上传。

定价规则
1.发布内容默认为免费，用户可根据作品内容质量调整为金币视频。
2.认证UP主发布原创举牌长视频，建议定价50-200金币
3.原创举牌作品，建议定价30-50金币
4.原创剪辑作品，建议定价10-20金币
5.非原创短片，建议定价1-10金币
    </div>`
    }
  }
}
</script>

<style scoped lang="scss">
.publish-rule {
  padding: 0.24rem 0.32rem;
  font-weight: 400;
  font-size: 0.24rem;
  line-height: 0.44rem;
  text-align: justify;
  white-space: pre-line;
  color: #666666;
}
</style>
