<template>
  <div v-if="list && list.length" class="topic-type-19">
    <HomeTitle v-if="title" :title="title" :path="`/acg/acgTopicDetail/?title=${title}&type=dream&id=${id}`" />
    <ul class="six-list">
      <DreamShowType1 v-for="(data, index) in list" :key="index" :data="data" />
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TopicType19',
  components: {
    DreamShowType1: () => import('@/components/Home/showType/dreamType/dreamShowType1.vue'),
    HomeTitle: () => import('@/components/homeTitle.vue')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    id: {
      type: Number,
      default: () => {
        return 0
      }
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    onRefresh(id) {
      this.$emit('onRefresh', id)
    }
  }
}
</script>

<style scoped lang="scss">
.topic-type-19 {
}
</style>

<style lang="scss" scoped>
.topic-type-19 {
  margin-bottom: 0.36rem;
}
.six-list {
  @include flexbox($jc: flex-start, $fw: wrap);

  > div {
    width: 2.18rem;
    margin-right: 0.14rem;
    flex: none;
    margin-bottom: 0.2rem;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
