<template>
  <ERefreshLoadV3 ref="VideoSearchList" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
    <div class="content">
      <div v-for="(item, index) in mediaList" :key="index">
        <VideoshowType1 :data="item"></VideoshowType1>
      </div>
    </div>
  </ERefreshLoadV3>
</template>

<script>
import { tag_category } from 'api/search'
export default {
  name: 'VideoSearch',
  components: {
    VideoshowType1: () => import('@/components/Home/showType/videoListType/videoshowType1')
  },
  props: ['navItem', 'id'],
  data() {
    return {
      pageNum: 0,
      pageSize: 10,
      param: {
        type: 1, //类型
        sortType: 0, //排序
        priceType: 0, //价格
        dateType: 0, //时间
        tagId: 0 //标签
      },
      finished: false,
      mediaList: []
    }
  },

  mounted() {},

  methods: {
    //设置搜索参数
    setParam(val) {
      this.param = JSON.parse(JSON.stringify(val))
      console.log('视频列表刷新')
      this.$refs['VideoSearchList'].onRefresh()
    },

    async onLoad() {
      this.pageNum += 1
      await this.getSeach()
    },
    async onRefresh() {
      this.finished = false
      //表示处于可以下拉状态
      this.pageNum = 0
      this.mediaList = []
    },

    // 获取数据
    async getSeach() {
      let param = {
        type: this.param.type,
        sortType: this.param.sortType,
        priceType: this.param.priceType,
        dateType: this.param.dateType,
        tagId: this.param.tagId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      await tag_category(param).then(res => {
        if (res.code === 200 && res.data.mediaList) {
          this.mediaList = [...this.mediaList, ...res.data.mediaList]
          if (res.data.mediaList.length < this.pageSize || !res.data.mediaList) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-flow: wrap;
  margin-top: 0.24rem;
  padding: 0 0.32rem;

  > div {
    width: 3.36rem;
    margin-bottom: 0.24rem;
    margin-right: 0.14rem;
  }

  > div:nth-child(2n) {
    margin-right: 0;
  }
}
</style>
