<template>
  <div class="square_exhibit">
    <div v-if="title" class="top">
      <div>{{ title }}</div>
      <div v-if="jump">{{ jump }}<van-icon name="arrow" /></div>
    </div>
    <div class="exhibit_list">
      <ul @touchmove.stop>
        <!-- 周话题精选 -->
        <li
          v-for="(item, index) in circleList"
          :key="index"
          class="topics_list"
          :style="
            index % 5 == 0
              ? 'background: linear-gradient(109.99deg,#00dbd4 -0.6%,#31e5b0 46.87%,#31e5b0 100.66%)'
              : index % 5 == 1
              ? 'background: linear-gradient(109.99deg, #F99FE7 -0.6%, #F38ECA 46.87%, #FFA5ED 100.66%)'
              : index % 5 == 2
              ? 'background: linear-gradient(109.99deg, #F99E68 -0.6%, #FAB076 46.87%, #FBC788 100.66%)'
              : index % 5 == 3
              ? 'background: linear-gradient(109.99deg, #C2BFCE -0.6%, #CDCADC 46.87%, #DBD8E7 100.66%)'
              : 'background: linear-gradient(109.99deg, #ABF459 -0.6%, #ABF459 46.87%, #DCFF7E 100.66%)'
          "
          @click="toTopicDetail(item)"
        >
          <div class="topics_margin">
            <h2 class="topics_title">#{{ item.title }}</h2>
            <div class="topics_active">
              <div>
                <span>{{ item.careUser | numberFilter }}</span
                >参与
              </div>
              <div>
                <span>{{ item.hotValue | numberFilter }}</span
                >互动数
              </div>
            </div>
            <div class="topics_Intercom">
              <div class="topics_avatar">
                <EDecryptImg class="portrait" :imgURL="item.cover" />
              </div>
              <div>
                帖子<em>{{ item.postCount | numberFilter('w') }}</em>
              </div>
              <span>|</span>
              <div>
                浏览<em>{{ item.publishCount | numberFilter('w') }}</em>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default() {
        return ''
      }
    },
    jump: {
      type: String,
      default() {
        return ''
      }
    },
    circleList: {
      type: Array,
      default() {
        return []
      }
    },
    router: {
      type: String,
      default() {
        return ''
      }
    },
    useType: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      imgURL: ''
    }
  },
  mounted() {},
  methods: {
    toTopicDetail(item) {
      this.$router.push(
        `/community/topicDetail/${item.id}?title=${item.title}&hotValue=${item.hotValue}&cover=${item.cover}&publishCount=${item.publishCount}`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.square_exhibit {
  // margin-bottom: .2rem;
  .top {
    font-weight: 500;
    font-size: 0.28rem;
    display: flex;
    justify-content: space-between;
    padding: 0.34rem 0.24rem;
  }

  .exhibit_list {
    @media (pointer: coarse) {
      ul::-webkit-scrollbar {
        display: initial;
      }
    }

    ul {
      scrollbar-width: none;
      /* firefox */
      -ms-overflow-style: none;
      /* IE 10+ */
      width: 100%;
      overflow-x: scroll;
      @include flexbox($jc: flex-start, $ai: flex-start, $fd: row, $fw: nowrap);
      padding-left: 0.22rem;
      flex-shrink: 1;

      .hot_topic {
        padding: 16px 12px;
        background: #ffffff;
        min-width: 6rem;
        height: 2.28rem;
        border: 0.02rem solid #f8f8f8;
        border-radius: 0.08rem;
        font-size: 0.28rem;
        margin-right: 0.26rem;

        .hot_topic_li {
          // width: 6rem;
          display: flex;
          justify-content: space-between;
          height: 0.3rem;
          line-height: 0.3rem;
          margin-bottom: 0.4rem;

          .hot_topic_le {
            display: flex;

            div {
              width: 3.7rem;
              overflow: hidden;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }

            h2 {
              margin: 0;
              margin-right: 0.1rem;
            }
          }

          span {
            font-size: 0.24rem;
            color: #999;
          }
        }
      }

      .hot_topic2 {
        width: 4.16rem;
        height: 2.88rem;
        background: linear-gradient(238.7deg, #bcdfff 0.89%, #e9f3ff 8.81%, #ffffff 22.57%);
        box-shadow: 0 0.08rem 0.22rem 0.08rem rgba(214, 218, 213, 0.3);
        border-radius: 0.08rem;
        margin-bottom: 0.28rem;
        margin-right: 0.16rem;

        .hot_topic_li {
          margin: 0 0.4rem;
          height: 0.64rem;
          display: flex;
          margin-top: 0.24rem;

          .portrait {
            width: 0.64rem;
            height: 0.64rem;
            margin-right: 0.18rem;
          }

          .hot_topic_tite {
            width: 2.6rem;
            font-size: 0.24rem;
            color: #999;

            .hot_topic_le {
              font-size: 0.28rem;
              color: #333;
              display: flex;
              height: 0.28rem;
              line-height: 0.28rem;
              margin-bottom: 0.06rem;

              h2 {
                margin: 0;
                margin-right: 0.1rem;
              }

              .title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      .hot_circle {
        width: 2.16rem;
        height: 3.36rem;
        background: #000;
        border-radius: 0.08rem;
        position: relative;
        margin-right: 0.16rem;

        .portrait {
          width: 2.16rem;
          height: 3.36rem;

          ::v-deep {
            .warp {
              border-radius: 0.08rem;
            }
          }
        }

        .hot_circle-btn {
          font-size: 0.22rem;
          text-align: center;
          padding: 0 0.16rem;
          height: 0.36rem;
          line-height: 0.36rem;
          background: #16d2d6;
          color: #fff;
          border-radius: 0.3rem;
          position: absolute;
          top: 0.16rem;
          right: 0.08rem;
        }

        .hot_circle-text {
          height: 1.2rem;
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 0 0.16rem;
          border-bottom-left-radius: 0.08rem;
          border-bottom-right-radius: 0.08rem;

          .title {
            @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: nowrap);

            img {
              width: 0.2rem;
              margin-right: 0.02rem;
            }
          }

          p {
            margin: 0;
            font-size: 0.24rem;
            line-height: 0.3rem;
            color: #fff;
            @include textoverflow();
          }

          p:nth-child(1) {
            color: #16d2d6;
            margin-top: 0.16rem;
          }
        }
      }

      .hot_circle2 {
        width: 2.16rem;
        height: 1.2rem;
        background: #000;
        border-radius: 0.1rem;
        position: relative;
        margin-right: 0.16rem;

        .portrait {
          width: 2.16rem;
          height: 1.2rem;

          ::v-deep {
            .warp {
              border-radius: 0.08rem;
            }
          }
        }

        .hot_circle-text {
          height: 1.2rem;
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 0 0.16rem;
          border-radius: 0.1rem;

          p {
            margin: 0;
            font-size: 0.24rem;
            line-height: 0.3rem;
            color: #fff;
            @include textoverflow();
            font-weight: 600;
          }

          p:nth-child(1) {
            color: #16d2d6;
            margin-top: 0.5rem;
            font-size: 0.18rem;
            font-weight: 400;
          }
        }
      }

      .hot_iJoined {
        min-width: 2.24rem;
        max-width: 2.24rem;
        height: 2.24rem;
        background: #000;
        border-radius: 0.08rem;
        position: relative;
        margin-right: 0.16rem;
        overflow: hidden;

        .default {
          width: 100%;
          height: 100%;
        }

        .hot_iJoined-text {
          height: 0.6rem;
          position: absolute;
          left: 0%;
          right: 0%;
          bottom: 0%;
          background: rgba(0, 0, 0, 0.5);
          backdrop-filter: blur(10px);
          color: #fff;
          font-size: 0.24rem;
          line-height: 0.6rem;
          padding: 0 0.16rem;

          p {
            margin: 0;
          }

          img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0.28rem;
            height: 0.28rem;
          }
        }
      }

      .topics_list {
        min-width: 6.44rem;
        height: 2.5rem;
        border-radius: 0.08rem;
        position: relative;
        margin-right: 0.16rem;
        overflow: hidden;
        background: linear-gradient(109.99deg, #00dbd4 -0.6%, #31e5b0 46.87%, #31e5b0 100.66%);

        .topics_margin {
          margin: 0.3rem 0.24rem;
          color: #333;

          .topics_title {
            font-size: 0.36rem;
            margin: 0;
            height: 0.36rem;
            line-height: 0.36rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
          }

          .topics_active {
            font-size: 0.22rem;
            display: flex;
            align-items: center;
            margin: 0.16rem 0 0.24rem 0;

            div {
              margin-right: 0.4rem;
              line-height: 0.36rem;
              > span {
                vertical-align: top;
                margin-right: 0.03rem;
                font-weight: 500;
                font-size: 0.36rem;
              }
            }
          }

          .topics_Intercom {
            width: 100%;
            height: 0.8rem;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 0.08rem;
            display: flex;
            line-height: 0.8rem;

            .topics_avatar {
              margin-right: 0.24rem;
              .portrait {
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 0.08rem;
                overflow: hidden;
              }
            }

            & > :nth-child(3) {
              font-weight: 500;
              margin: 0 0.24rem;
            }
            & > :nth-child(2),
            & > :nth-child(4) {
              font-size: 0.28rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>
