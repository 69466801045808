<template>
  <!--
使用方法
    <ERefreshLoadV2 :beforeRefresh="resetParam" :moreData="onLoad" :finished="finished">
        <template></template>
    </ERefreshLoadV2>

    async resetParam(id) { //重置参数
      this.finished = false;
      this.pageNum = 0;
      this.pageSize = 6;
      在这里晴空列表数据
    },

    async onLoad() {
      this.pageNum += 1;
      this.getList()
    },
    getList(){
        在请求内部做判断是否结束
        this.finished = true;
    }

    刷新时：只重置参数，后调用load请求数据，
    加载时：调用load请求数据
-->
  <div class="pull-container">
    <van-pull-refresh ref="backup" v-model="childRefreshing" :disabled="$route.meta.isCloseRefresh || disabled">
      <slot name="top"></slot>
      <van-list ref="list" v-if="skeleton" v-model="childLoading" :finished="finished" finished-text="没有更多了" :immediate-check="true" @load="onLoad">
        <template v-if="childRefreshing || (isFirst && childLoading)">
          <slot name="refreshing">
            <ERefreshingPage />
            <!-- <div style="height: 1000px"></div> -->
          </slot>
        </template>
        <template v-else>
          <div class="minHeight">
            <slot></slot>
          </div>
        </template>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
export default {
  name: 'ERefreshLoadV2',
  components: {},
  props: {
    finished: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
    skeleton: {
      type: Boolean,
      default() {
        return true;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    moreData: {
      type: Function,
      required: true,
      default() {
        return async () => {};
      },
    },

    beforeRefresh: {
      type: Function,
      required: true,
      default() {
        return async () => {};
      },
    },
  },
  data() {
    return {
      childLoading: false,
      childRefreshing: false,
      isFirst: true,
    };
  },
  watch: {
    childRefreshing: {
      handler(n, o) {
        //同步data到props
        n && this.onRefresh();
      },
    },
  },
  methods: {
    async onLoad() {
      this.isFirst && (await Promise.resolve(this.beforeRefresh()));
      await Promise.resolve(this.moreData());
      this.isFirst = false;
      this.childLoading = false;
    },
    async onRefresh() {
      await Promise.resolve(this.beforeRefresh());
      await this.onLoad();
      this.childRefreshing = false;
    },
  },
};
</script>

<style scoped lang="scss">
.pull-container {
  position: relative;
}
.minHeight {
  min-height: 10vh;
}
</style>
