<template>
  <div class="play-info">
    <PlayInfoOpr :video-info="videoInfo" class="padding" @updateVideoInfo="$emit('updateVideoInfo')" />
    <!--    演员信息-->
    <PlayActorInfo
      v-if="videoInfo.actor && videoInfo.actor.length"
      :video-info="videoInfo"
      class="actor-info padding"
    />
    <EpisodeInfo
      v-if="episodeInfo"
      :value="videoInfo.id || 0"
      :list="episodeInfo.mediaInfo"
      @input="$router.replace(`/play/longVideo/${$event}`)"
    ></EpisodeInfo>
    <!-- 视频广告 -->
    <div v-if="$store.state.config.config.mediaPlayAdsType == 2 && iconVideoPlayAds.length" class="swiperPack">
      <AdAvatarList :list="iconVideoPlayAds" key-name="avatar"> </AdAvatarList>
    </div>
    <div v-else-if="$store.state.config.config.mediaPlayAdsType !== 3 && playAD.length" class="swiperPack">
      <EADSwiper
        :list="playAD"
        :img-c-d-n="imgCDN"
        :slidesPerView="1"
        keyName="cover"
        :EADconfig="EADconfig"
        :imgCDN="imgCDN"
        @clickImg="clickImg"
      >
      </EADSwiper>
    </div>
    <!--    相关播单-->
    <HotPlayerList :avList="avRecommand" :playList="playList" class="hot-list" />
    <!-- 分割线 -->
    <div class="divider padding"></div>
    <!--    猜你喜欢-->
    <ERefreshLoadV3 ref="ERefreshLoadV3" :beforeRefresh="onRefresh" :moreData="onLoad" :finished="finished">
      <GuessLike
        v-if="videoLike.length"
        :id="+$route.params.id"
        :list="videoLike"
        title="猜你喜欢"
        class="guess-list padding"
      />
    </ERefreshLoadV3>
    <!--    推荐女优-->
    <RecommendLady v-if="avList.length" title="推荐女优" :list="avList" class="lady-recommend" />
  </div>
</template>

<script>
import { play_actor_list, play_play_list, video_like } from 'api/play'
import { advertiseClick } from 'api/home'
import { JumpTo } from '@/utils/utils_tools'
export default {
  name: 'Play',
  components: {
    PlayInfoOpr: () => import('@/components/Play/playInfoOpr.vue'),
    PlayActorInfo: () => import('@/components/Play/playActorInfo.vue'),
    HotPlayerList: () => import('@/components/Home/showType/HotPlayerList.vue'),
    GuessLike: () => import('@/components/Play/GuessLike.vue'), //四宫格(换一换)1
    RecommendLady: () => import('@/components/Home/showType/RecommendLady.vue'),
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue'),
    EpisodeInfo: () => import('@/components/Home/EpisodeInfo.vue')
  },
  inject: ['reload'],
  props: {
    videoInfo: {
      type: Object,
      default: () => ({})
    },
    episodeInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      finished: false,
      pageNum: 0,
      pageSize: 10,
      videoLike: [],
      avRecommand: [],
      avList: [],
      playList: [],
      EADconfig: {
        speed: 300,
        autoplay: false,
        loop: true,
        delay: 2000
      }
    }
  },
  computed: {
    playAD({ $store }) {
      return $store.getters['playAD']
    },
    // 长视频icon列表
    iconVideoPlayAds({ $store }) {
      return $store.getters['iconPlayListAds']
    },
    shareUrl() {
      let url = this.$store.getters['shareUrl'] + '/'
      if (url[url.length - 1] == '/') {
        url = url.slice(0, url.length - 1)
      }
      return url
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  watch: {
    $route: {
      async handler() {
        // await this.getVideoLike()
        await this.getPlayList()
        await this.getActorList()
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    onRefresh() {
      this.pageNum = 0
      this.videoLike = []
    },
    async onLoad() {
      this.pageNum++
      await this.getVideoLike()
    },
    async clickImg(item) {
      JumpTo(item.href)
      const res = await advertiseClick({
        id: item.id
      })
      if (res !== 200) {
        return this.$toast(res.tip)
      }
    },

    // 剪贴板
    doCopy(text) {
      this.$copyText(text).then(() => {
        this.$toast('复制剪贴板成功')
      })
    },

    // 获取猜你喜欢列表
    async getVideoLike() {
      // try {
      const res = await video_like({
        id: +this.$route.params.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      if (res.code === 200) {
        this.videoLike = [...this.videoLike, ...res.data.mediaList]
        if (res.data.mediaList?.length < this.pageSize || !res.data.mediaList) {
          this.finished = true
        }
      } else {
        this.finished = true
        return this.$toast(res.tip)
      }
      // } catch (error) {
      //   return this.$toast('请求错误，请稍后再试!')
      // }
    },
    async getPlayList() {
      try {
        const res = await play_play_list({
          id: +this.$route.params.id
        })
        if (res.code === 200) {
          this.avRecommand = res.data.mediaList
          this.playList = res.data.playList
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        return this.$toast('请求错误，请稍后再试!')
      }
    },
    // 获取猜你喜欢列表
    async getActorList() {
      try {
        const res = await play_actor_list({
          id: +this.$route.params.id
        })
        if (res.code === 200) {
          this.avList = res.data.mediaActor
          // this.avList = [{}, {}]
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        return this.$toast('请求错误，请稍后再试!')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.play-info {
  padding-bottom: 1.34rem;

  .padding {
    padding: 0 0.32rem;
  }

  .actor-info {
    margin-top: 0.36rem;
  }

  .anthology-box {
    margin-bottom: 0.12rem;
  }

  .swiperPack {
    width: 100%;
    border-radius: 0.1rem;
    overflow: hidden;
    padding: 0.32rem 0.32rem 0 0.32rem;
  }

  .hot-list {
    // margin-top: 0.36rem;
  }

  .divider {
    margin: 0.36rem 0.32rem;
    border-bottom: 0.02rem solid #f0f0f0;
  }

  .lady-recommend {
    margin-top: 0.68rem;
  }
}
</style>
