<template>
  <div class="income_detail">
    <EBackTop title="收益明细"></EBackTop>
    <ERefreshLoadV3
      ref="ERefreshLoadV3"
      class="main_list"
      :beforeRefresh="onRefresh"
      :moreData="onLoad"
      :finished="finished"
    >
      <ul class="income_listWrap">
        <li v-for="(item, index) in transList" :key="index" class="item">
          <p class="title">{{ item.incomeType === 30 ? '代理受益' : '帖子受益' }}</p>
          <p class="desc">{{ item.desc }}</p>
          <p class="bot">
            <span>{{ item.createdAt | dateFormat08 }}</span>
            <span>+{{ item.coinAmount | changeMoneyYuan }}金币</span>
          </p>
        </li>
      </ul>
    </ERefreshLoadV3>
  </div>
</template>
<script>
import { income_detail } from 'api/user'
export default {
  components: {},
  data() {
    return {
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 关注列表
      transList: [],
      // 请求类型
      getType: '',
      // 当前页
      pageNum: 0,
      // 当前个数
      pageSize: 10,
      showReject: false,
      activeItem: 0,
      status: 1
    }
  },
  mounted() {
    // this.onRefresh()
  },
  methods: {
    // 获取流水记录
    async get_transList() {
      try {
        const res = await income_detail({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.transList = [...this.transList, ...res.data.list]
          if (res.data && res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
          return this.$toast(res.tip)
        }
      } catch (error) {
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    showRejectFn(item) {
      this.activeItem = item
      this.showReject = true
    },
    // 下拉加载
    async onLoad() {
      this.pageNum++
      await this.get_transList()
    },
    // 上拉刷新
    onRefresh() {
      this.pageNum = 0
      this.transList = []
    },
    onCopy() {
      this.$toast('复制成功！！！')
    },
    onError() {
      this.$toast('复制失败！！！')
    }
  }
}
</script>
<style lang="scss" scoped>
.income_detail {
  color: rgba($color: #333, $alpha: 0.5);
  ul {
    padding: 0.36rem 0.32rem;
    li {
      padding-bottom: 0.24rem;
      border-bottom: 0.01rem solid #f0f0f0;
      margin-bottom: 0.2rem;
    }
  }
  .title {
    font-size: 0.28rem;
    color: rgba($color: #333, $alpha: 1);
    font-weight: 600;
  }
  .desc {
    margin: 0.08rem 0;
  }
  .bot {
    @include flexbox();
    span {
      &:last-child {
        font-size: 0.28rem;
        color: $txtActive;
      }
    }
  }
}
</style>
