<template>
  <div class="line-wrap" @click="lineSelectionShow = !lineSelectionShow">
    <div class="line-btn" @click.stop="openPop">
      <span>切换线路</span><img src="@/assets/imgs/play/lineIcon.svg" alt="" class="icon" />
    </div>
    <van-overlay :show="lineSelectionShow">
      <div class="pop-wrap">
        <div class="pop-content">
          <div class="header">选择标签</div>
          <ul class="center">
            <li v-for="item in lineSelectionList" :key="item" class="single-item" @click.stop="fn_switch(item)">
              <div class="single-select" :class="{ active: lineSelectionName === item }"></div>
              <div class="single-label">{{ item }}</div>
            </li>
          </ul>
        </div>
      </div>
    </van-overlay>
    <notice-vip-pop v-model="showVipPop" :callback="vipPopCallback" />
  </div>
</template>
<script>
export default {
  name: 'SelectLine',
  components: {
    NoticeVipPop: () => import('@/components/Popup/noticeVipPop.vue')
  },
  data() {
    return {
      lineSelectionShow: false,
      lineSelectionName: '主线路',
      lineSelectionList: ['主线路', '线路1', '线路2', '线路3', '线路4'],
      vipPopCallback: {
        toTask: () => {
          this.showVipPop = false
          this.$router.push('/mine/myShouyi')
        },
        toVip: () => {
          this.showVipPop = false
          this.$router.push('/Mine/vip')
        }
      },
      showVipPop: false
    }
  },
  methods: {
    openPop() {
      this.lineSelectionShow = true
    },
    fn_switch(item) {
      this.lineSelectionShow = false
      if (!this.isVipMember) {
        this.showVipPop = true
        return
      }
      this.lineSelectionName = item
      this.$emit('on-switch', item)
    }
  }
}
</script>

<style scoped lang="scss">
.line-btn {
  @include flex-center;
  /* 切换线路 */
  font-weight: 400;
  font-size: 0.24rem;
  line-height: 0.34rem;

  color: #999999;
  & > .icon {
    width: 0.24rem;
    height: 0.24rem;
    margin-left: 0.16rem;
  }
}
.pop-wrap {
  @include flex-center;
  height: 100%;
  .pop-content {
    width: 5.6rem;
    height: 2.24rem;
    border-radius: 0.16rem;
    background-color: #ffffff;
    overflow: hidden;
    text-align: center;
    .header {
      background: $gradientBg;
      width: 100%;
      height: 0.64rem;
      line-height: 0.64rem;
      font-weight: 400;
      font-size: 0.3rem;
      color: #ffffff;
    }
    .center {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0.64rem 0.36rem;
      .single-item {
        @include flex-center;
        width: 1.04rem;
        flex-shrink: 0;
        .single-select {
          width: 0.24rem;
          height: 0.24rem;
          @include flex-center;
          background-color: #eeeeee;
          border-radius: 50%;
          &:before {
            content: '';
            border-radius: 50%;
            width: 0.14rem;
            height: 0.14rem;
            transition: background-color 0.2s ease-in-out;
          }
          &.active::before {
            background-color: #e8619d;
          }
        }
        .single-label {
          margin-left: 0.08rem;
        }
      }
    }
  }
}
</style>
