<template>
  <div class="item" @click="handleClick">
    <div class="top">
      <div class="bodan-img">
        <EDecryptImg :imgURL="data.cover"> </EDecryptImg>
      </div>
      <div v-if="data.type === 2" class="right">
        <img class="item-img" src="@/assets/imgs/discover/photo.png" alt="" />
        <span>图片 </span>
      </div>
      <div v-else class="right">
        <img class="item-img" src="@/assets/imgs/discover/video.png" alt="" />
        <span>视频 </span>
      </div>
      <div class="pos-bottom">
        <div class="left">
          <img class="count-img" src="@/assets/imgs/discover/layer.png" alt="" />
          <span>{{ data.total }}</span>
        </div>
      </div>
    </div>
    <div class="bottom">{{ data.title }}</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  computed: {},

  mounted() {},

  methods: {
    handleClick() {
      this.$router.push({
        path: '/discover/detail',
        query: {
          id: this.data.id,
          type: this.data.type
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.item {
  width: 2.22rem;

  .top {
    width: 2.22rem;
    height: 2.22rem;
    position: relative;
    background: #7a6a6a;
    border-radius: 0.2rem;

    .bodan-img {
      width: 2.22rem;
      height: 2.22rem;
      overflow: hidden;
      border-radius: 0.2rem;
      border-top: 0.05rem solid #fff;
      box-sizing: content-box;
      position: absolute;
      top: 0.08rem;
    }
    .right {
      position: absolute;
      width: 0.96rem;
      height: 0.42rem;
      right: 0.2rem;
      top: 0.2rem;
      background: #00000080;
      border-radius: 0.08rem;
      color: #ffffff;
      @include flex-center;
      .item-img {
        width: 0.26rem;
        margin-right: 0.08rem;
      }
    }
    .pos-bottom {
      position: absolute;
      width: 100%;
      height: 0.76rem;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
      // background: red;
      left: 0;
      bottom: 0;
      border-radius: 0rem 0rem 0.2rem 0.2rem;
      color: $white;
      padding: 0 0.16rem;
      @include flexbox;

      > div {
        @include flex-align-center;
      }

      .count-img {
        width: 0.28rem;
        margin-right: 0.08rem;
      }
    }
  }

  .bottom {
    margin-top: 0.16rem;
    color: $txtDefault;
    font-size: 0.26rem;
    font-style: normal;
    font-weight: 500;
    @include textoverflow;
  }
}
</style>
