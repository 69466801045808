<template>
  <div class="comisc_main">
    <div>
      <div v-if="adsType === 2 && iconAcgAds.length" class="acg_ads">
        <AdAvatarList :list="iconAcgAds" :wrap="true" key-name="avatar" />
      </div>
      <EADSwiper v-else-if="adsType !== 3 && lotteryTopAd.length > 0" class="acg_swiper1" :list="lotteryTopAd"
        key-name="cover" :EADconfig="EADconfig" :img-c-d-n="imgCDN" @clickImg="clickImg">
      </EADSwiper>
    </div>
    <!-- 金刚区 -->
    <EADSwiper v-if="bannerList && bannerList.length > 0" class="acg_swiper2" :list="bannerList" key-name="cover"
      :EADconfig="EADconfig" :img-c-d-n="imgCDN" @clickImg="clickImg">
    </EADSwiper>
    <ERefreshLoadV3 ref="ERefreshLoadV3" class="main_list" :beforeRefresh="onRefresh" :moreData="onLoad"
      :finished="finished">
      <component :is="compComponent(item.showType)" v-for="item in topicList" :id="item.id" :key="item.id"
        :name="item.name" :itemList="item.mediaList" :itemData="item" class="main_mt">
      </component>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { media_home } from '@/api/acg'
import { JumpTo } from '@/utils/utils_tools'
import { advertiseClick } from 'api/home'
export default {
  components: {
    sbhpList: () => import('@/components/CartoonTypeCard/sbhpList.vue'),
    fiveListChange: () => import('@/components/CartoonTypeCard/fiveListChange.vue'),
    hbspList: () => import('@/components/CartoonTypeCard/hbspList.vue'),
    nineList: () => import('@/components/CartoonTypeCard/nineList.vue'),
    pkSixList: () => import('@/components/CartoonTypeCard/pkSixList.vue'),
    sixListChange: () => import('@/components/CartoonTypeCard/sixListChange.vue'),
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue')
  },
  props: {
    adsType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      },
      finished: false,
      pageSize: 10,
      pageNum: 0,
      topicList: [],
      bannerList: []
    }
  },
  computed: {
    lotteryTopAd() {
      return this.$store.getters['comicsTopAd']
    },
    iconAcgAds() {
      return this.$store.getters['iconAcgAds']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() { },
  methods: {
    async clickImg(item) {
      if (item.href) {
        JumpTo(item.href)
      } else {
        JumpTo(item.download_url)
      }
      const res = await advertiseClick({
        id: item.id
      })

      if (res.code !== 200) {
        return this.$toast(res.tip)
      }
    },
    compComponent(showType) {
      // 2，3，6，9，10，11 五宫格加换一换 六宫格加换一换 竖版横排 横版横排 九宫格 pk六宫格
      switch (showType) {
        case 2:
          return 'fiveListChange'
        case 3:
          return 'sixListChange'
        case 6:
          return 'sbhpList'
        case 9:
          return 'hbspList'
        case 10:
          return 'nineList'
        case 11:
          return 'pkSixList'
        default:
          return 'sixListChange'
      }
    },
    onRefresh() {
      this.finished = false
      this.topicList = []
      this.pageNum = 0
    },
    async onLoad() {
      this.pageNum++
      await this.getComicsHome()
    },
    async getComicsHome() {
      const res = await media_home({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      if (res.code === 200) {
        if (res.data && res.data.bannerList) {
          this.bannerList = res.data.bannerList
        }
        if (res.data && res.data.topicList && res.data.topicList.length) {
          this.topicList = [...this.topicList, ...res.data.topicList]
          if (res.data.topicList.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
        }
      } else {
        this.finished = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main_list {
  padding: 0.29rem 0.32rem 1.29rem 0.32rem;
}

.acg_swiper1 {
  margin-top: 0.56rem;
  height: 2.86rem;
}

.acg_swiper2 {
  height: 1.98rem;
  width: 7.5rem;
  margin-top: 0.2rem;

  :deep(.swiper-container) {
    width: 100%;
  }
}

.acg_ads {
  padding: 0.28rem 0.32rem 0;
}
</style>
