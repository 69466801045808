<template>
  <div class="exchange">
    <EBackTop title="会员兑换"></EBackTop>
    <div class="exchange_main">
      <div class="top_num">
        <p>当前积分</p>
        <p>{{ userInfo.points }}</p>
      </div>
      <ERefreshLoadV3 class="main_list" :beforeRefresh="onRefresh" :moreData="onLoad" :finished="finished">
        <ul class="change_list">
          <li v-for="item in lotteryList" :key="item.id">
            <div>
              <img src="@/assets/imgs/mine/exchange_vip_icon.png" alt="" />
              <p class="nums">{{ item.pointNum }}积分</p>
              <p class="desc">{{ item.name }}</p>
            </div>
            <span class="btn" @click="checkRedeem(item)">兑换</span>
          </li>
          <i></i>
        </ul>
        <!-- 兑换记录 -->
        <div class="change_history">
          <span class="title">兑换记录</span>
          <div v-if="redeemedList && redeemedList.length > 0" class="history_list">
            <div v-for="item in redeemedList" :key="item.id" class="item">
              <p>{{ item.desc }}</p>
              <p>{{ item.activedAt | dateFormat08 }}</p>
            </div>
          </div>
          <div v-else class="noData">您还没有兑换过会员喔~</div>
        </div>
      </ERefreshLoadV3>
    </div>
  </div>
</template>
<script>
import { getLotteryList, getLotteryRedeem, getLotteryRedeemHistory } from 'api/user'

export default {
  components: {},
  data() {
    return {
      lotteryList: [],
      redeemedList: [],
      finished: false,

      // 当前页
      pageNum: 0,
      // 当前个数
      pageSize: 50
    }
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    // 落地页
    ldyCdn({ $store }) {
      return $store.getters['ldyCdn']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  async mounted() {
    await this.getLotteryList()
  },
  methods: {
    // 下拉加载
    async onLoad() {
      this.pageNum++
      await this.getRedeemedListList()
    },
    // 上拉刷新
    onRefresh() {
      this.pageNum = 0
      this.redeemedList = []
    },
    // 获取兑换历史
    async getRedeemedListList() {
      const res = await getLotteryRedeemHistory({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      if (res.code === 200) {
        this.refreshing = false
        this.loading = false
        if (!res.data.list) {
          res.data.list = []
        }
        this.redeemedList = [...this.redeemedList, ...res.data.list]
        if (res.data && res.data.list.length < this.pageSize) {
          this.finished = true
        }
      } else {
        this.$toast(res.tip)

        this.finished = true
      }
    },
    // 获取奖品列表
    async getLotteryList() {
      const res = await getLotteryList({})
      if (res.code === 200) {
        if (!res.data.list) {
          res.data.list = []
        }
        this.lotteryList = res.data.list
      } else {
        this.$toast(res.tip)
      }
    },
    // 奖品兑换
    async checkRedeem(item) {
      const res = await getLotteryRedeem({
        prizeId: item.id
      })
      if (res.code == 200) {
        this.$store.dispatch('get_userInfo')
        return this.$toast('兑换成功')
      } else {
        return this.$toast(res.tip)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.exchange_main {
  padding: 0.49rem 0.64rem;

  .top_num {
    background: url('../../../assets/imgs/mine/exchange_vip_bg.png') no-repeat;
    background-size: 100% 100%;
    text-align: center;
    margin-bottom: 0.37rem;
    padding: 0.53rem 0;

    p {
      font-size: 0.32rem;

      &:last-child {
        color: #8b3200;
        font-size: 0.64rem;
        font-weight: 700;
      }
    }
  }

  .change_list {
    @include flexbox($fw: wrap, $ai: flex-start);

    li {
      text-align: center;
      width: 30%;
      margin-bottom: 0.24rem;
      div {
        border-radius: 0.08rem;
        background: linear-gradient(148deg, #fae3d0 0%, #eec7b2 101.54%, #efcab5 101.55%);
        padding: 0.32rem 0.35rem 0.32rem 0.33rem;
        @include flexbox($fd: column, $jc: center);
        margin-bottom: 0.18rem;

        img {
          width: 0.56rem;
          height: 0.56rem;
        }

        .nums {
          color: #8b3200;
          font-size: 0.3rem;
          font-weight: 500;
          margin: 0.2rem 0;
          white-space: nowrap;
          @include textoverflow();
        }

        .desc {
          border-radius: 0.04rem;
          background: rgba(255, 255, 255, 0.4);
          padding: 0 0.24rem;
          white-space: nowrap;
          color: $txtActive;
          @include textoverflow();
          max-width: 2rem;
          display: block !important;
        }
      }

      .btn {
        border-radius: 0.04rem;
        background: #8b3200;
        padding: 0.02rem 0.36rem;
        color: #fff;
        font-size: 0.26rem;
      }
    }

    i {
      width: 30%;
    }
  }
}
.change_history {
  border-radius: 0.12rem;
  border: 1px solid #ffc9aa;
  background: rgba(255, 201, 170, 0.28);
  width: 6.22rem;
  height: 5.06rem;
  margin: 0.64rem auto 0 auto;
  text-align: center;
  .title {
    border-radius: 0.04rem;
    background: $txtActive;
    color: #fff;
    padding: 0.06rem 0.24rem;
  }
}
.history_list {
  margin-top: 0.18rem;
  padding: 0 0.32rem;
  overflow-x: scroll;
  max-height: 80%;
}
.item {
  @include flexbox();
  padding: 0.16rem 0;
  border-bottom: 0.01rem solid rgba(139, 50, 0, 0.3);

  span {
    color: $txtActive;
  }
  .time {
    color: $grey;
  }
}
.noData {
  margin-top: 30%;
  color: #8b3200;
  font-size: 0.26rem;
}
</style>
