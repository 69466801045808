<!-- home -->
<template>
  <div class="trade-container">
    <EBackTop class="mine-head"> </EBackTop>
    <!--切换面板 -->
    <ETab
      class="container-tab"
      :sticky="true"
      :tabList="topTabLabelList"
      :v-model="type"
      :animated="false"
      :lineSwitch="true"
      :lineBg="'#E8619D'"
      :sizeTab="'0.28rem'"
      :sizeActive="'.36rem'"
      :activeId="type"
    >
      <template #default="scope">
        <component :is="compComponent(scope.data)"></component>
      </template>
    </ETab>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'

export default {
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    myShouYi: () => import('./components/myShouYi.vue'),
    Works: () => import('./components/Works.vue'),
    Welfare: () => import('./components/Welfare.vue')
  },
  mixins: [scrollMixin],
  data() {
    return {
      type: 0, // 切换的id
      topTabLabelList: [
        { showType: 0, name: '我的收益' },
        { showType: 1, name: '福利任务' },
        { showType: 2, name: '福利推荐' }
      ]
    }
  },
  computed: {
    // 获取首页推荐页轮播广告
    avAD({ $store }) {
      return $store.getters['avAD']
    },

    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },

  created() {
    if (this.$route.query.type) {
      this.type = Number(this.$route.query.type)
    }
  },
  beforeDestroy() {},
  methods: {
    //  0:暗黑游  1:帝王调教 模版 2:暗网交易
    compComponent(data) {
      switch (data) {
        case 0:
          return 'myShouYi'
        case 1:
          return 'Works'
        case 2:
          return 'Welfare'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.trade-container {
  min-height: $minHeight;

  .mine-head {
    :deep() {
      .van-nav-bar {
        z-index: 98;
      }
    }
  }

  .container-tab {
    :deep() {
      .van-sticky {
        position: fixed;
        top: 0;
        left: 1rem;
        right: 0;
        z-index: 99;
        width: 100%;

        max-width: $pcMaxWidth;
      }

      .van-tabs__nav {
        justify-content: flex-start;
        background-color: #fff;
      }

      .van-tab {
        flex: none;
      }
    }
  }
}
</style>
