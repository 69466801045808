<template>
  <div class="release">
    <EBackTop :title="routeTitle">
      <template #right> <span @click="toPublishRule">发布规则</span> </template>
    </EBackTop>
    <div class="release_main">
      <div>
        <!-- 选择 -->
        <div class="check_tag">
          <div class="check_right" @click="toTypeList">
            <span>{{ itemName.name ? itemName.name : '#选择 帖子标签' }}</span>
            <van-icon name="arrow" color="#BCBCBC" class="right_icon" />
          </div>
        </div>
        <!-- 内容填写 -->
        <div class="content_box">
          <!-- 描述信息 -->
          <van-field v-model="title" maxlength="50" class="title_box" required placeholder="请填写标题" />
          <van-field
            v-if="postType !== 'img'"
            v-model="text"
            class="text_box"
            maxlength="1000"
            autosize
            type="textarea"
            placeholder="随手发张手里的涩图吧..."
          />
          <div class="check_tag">
            <div class="check_right" @click="toSelectPrice">
              <span>{{ '请设置解锁价格0~999金币，不设置则免费' }}</span>
              <div>
                <span class="price">{{ price ? `${price}金币` : '' }}</span>
                <van-icon name="arrow" color="#BCBCBC" class="right_icon" />
              </div>
            </div>
          </div>
          <!--          <van-field v-model.number="price" class="title_box" placeholder="请设置解锁价格0~999金币，不设置则免费" />-->
        </div>

        <!-- 图片上传 -->
        <div class="pohoto_box cover_bg">
          <div class="video_title">
            <span class="check_txt">添加图集</span>
            <span style="color: #e8619d; margin-left: 0.08rem">最大每张1M以内</span>
          </div>
          <UploadImg ref="uploadImgClear" :max-length="100" @result="resultImgs" />
        </div>

        <!-- 视频上传 -->
        <div v-if="postType === 'video'" class="video_box">
          <div class="video_box_title">
            <span class="check_txt video_title">添加视频</span>
            <!--            <span class="video_title"> 只能添加一个视频,没有封面显示视频第一帧 </span>-->
            <span style="color: #e8619d; margin-left: 0.08rem"> 最大300M以内 </span>
          </div>
          <div>
            <div class="video_bot">
              <UploadVideo ref="uploadVideo" @result="resultVideo" />
              <!-- <img class="cover_bg" v-if="!cover[0]" @click="loadCover" src="'@/assets/imgs/community/load_img_bg.png'" alt="">
        <EDecryptImg class="cover_bg" v-else :imgCDN="imgCDN" :imgURL="cover[0]" /> -->
              <!--              <div class="cover_load">-->
              <!--                <UploadImgSpecial ref="coverLoad" class="cover_bg" :max-length="1" @result="resultCover" />-->
              <!--              </div>-->
              <!--              <div class="info-text">*视频大小不超过300M</div>-->
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="btn" @click="submit">确认发布</div>
      </div>
    </div>
    <PriceSelectPop v-model="price" :show.sync="showPricePop" />
    <TagSelectPop v-model="itemName" :show.sync="showTagePop" />
  </div>
</template>

<script>
import { community_publish } from '@/api/dating'

export default {
  components: {
    UploadImg: () => import('@/components/Imgs/UploadImg/index.vue'),
    // UploadImgSpecial: () => import('@/components/Imgs/UploadImgSpecial/index.vue'),
    UploadVideo: () => import('@/components/Video/UploadVideo/index.vue'),
    PriceSelectPop: () => import('@/components/Community/publish/PriceSelectPop.vue'),
    TagSelectPop: () => import('@/components/Community/publish/TagSelectPop.vue')
  },
  data() {
    return {
      text: '',
      price: undefined,
      taskID: '',
      video: '',
      title: '',
      imgs: [],
      cover: [],
      itemName: {},
      contact: '',
      showPricePop: false,
      showTagePop: false
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    postSection({ $store }) {
      return $store.getters['postSection']
    },
    isFromTrade() {
      return this.$route.query.from === '1'
    },
    postType() {
      return this.$route.query.postType
    },
    routeTitle() {
      switch (this.postType) {
        case 'video':
          return '发布视频'
        case 'img':
          return '发布图片'
        case 'txt':
          return '发布图文'
        default:
          return '发布帖子'
      }
    }
  },
  activated() {
    if (this.$store.state.history.postSelect) {
      this.itemName = this.$store.state.history.postSelect
    }
  },
  deactivated() {
    this.$store.commit('setPostSelect', {})
  },

  methods: {
    // 视频上传回调
    resultVideo({ taskID, videoUri }) {
      this.taskID = taskID
      this.video = videoUri
    },
    resultCover(imgs) {
      this.cover = imgs
    },
    // 图片上传回调
    resultImgs(imgs) {
      this.imgs = imgs
    },
    loadCover() {
      this.$refs.coverLoad.$refs.Uploader.chooseFile()
    },
    async submit() {
      if (this.postType !== 'img' && (!this.text || this.text.length < 8)) {
        return this.$toast('描述信息字数不可少于8个字')
      }
      if (!this.itemName.id) {
        return this.$toast('请选择帖子标签')
      }
      if (this.$route.query.postType !== 'video') {
        if (!this.imgs || this.imgs.length === 0) {
          return this.$toast('请上传图片!')
        }
      }

      if (this.imgs.length > 9) {
        return this.$toast('图片不能大于9张')
      }
      if (this.$route.query.postType === 'video') {
        if (!this.video) return this.$toast('请上传视频!')
      }

      try {
        const res = await community_publish({
          title: this.title,
          text: this.text,
          cover: this.cover[0],
          imgs: this.imgs,
          price: this.price * 100,
          video: this.video,
          taskID: this.taskID,
          type: this.isFromTrade ? 1 : 2,
          contact: this.contact,
          sectionId: this.itemName.id,
          categoryId: Number(this.$route.query.cid)
        })
        if (res.code === 200) {
          this.$toast('提交成功，请等待审核')
          this.$store.commit('setPostSelect', {})
          this.title = ''
          this.text = ''
          this.taskID = ''
          this.video = ''
          this.price = undefined
          this.contact = ''
          this.imgs = []
          this.cover = []
          this.$refs.uploadImgClear.upLoadImgs = []
          this.itemName = {}
          if (this.$route.query.postType == 'video') {
            this.$refs.uploadVideo.clearInfo()
          }
          this.$router.replace('/community')
        } else {
          return this.$toast(res.tip || res.data.msg || '上传失败')
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求错误，请稍后再试！')
      }
    },
    toPublishRule() {
      this.$router.push('/community/publishRule')
    },
    toTypeList() {
      this.showTagePop = true
    },
    toSelectPrice() {
      this.showPricePop = true
    }
  }
}
</script>

<style lang="scss" scoped>
.release_main {
  min-height: calc(100vh - 1rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #666666;

  > div:nth-child(1) {
    padding: 0.32rem;
  }
}

.van-cell {
  border-radius: 0.1rem;
  margin-top: 0.2rem;
  background-color: rgba(0, 0, 0, 0.05);
  :deep() {
    .van-field__control {
      color: #666666;
      &::placeholder {
        color: #666666;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
.video_title {
  color: #999;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.van-cell::after {
  display: none;
}

.check_tag {
  @include flexbox($jc: flex-start);
  margin-top: 0.2rem;
  .check_right {
    border-radius: 0 0.08rem 0.08rem 0;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    width: 100%;
    height: 1rem;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 0 0.24rem;
  }
  .price {
    color: #ffb267;
  }
  .right_icon {
    width: 0.16rem;
    height: 0.26rem;
    flex-shrink: 0;
    font-weight: 600;
  }
}

.check_txt {
  color: #666666;
  font-size: 0.32rem;
  line-height: 0.44rem;
}

.content_box {
  margin-top: 0.24rem;
  .text_box {
    height: 1.8rem;
    border-radius: 0.08rem;
    margin-bottom: 0.24rem;
  }
}

.set_video {
  margin-top: 0.52rem;
}

.video_box {
  margin: 0.52rem 0;

  .video_box_title {
    margin-bottom: 0.2rem;
  }
}

.pohoto_box {
  margin-top: 0.24rem;
}

.cover_bg {
  // margin-bottom: 0.2rem;
  // margin-left: 0.3rem;
  .video_title {
    margin-bottom: 0.24rem;
    font-size: 0.24rem;
  }
  :deep() {
    .uploadImg-wrap {
      width: 2.22rem !important;
      height: 2.22rem !important;
    }
    .img-item {
      width: 2.22rem !important;
      height: 2.22rem !important;
      margin-top: 0 !important;
      margin-bottom: 0.1rem;
      margin-right: 0.1rem !important;
      border-radius: 0.16rem;
      overflow: hidden;
      &:nth-child(3n + 3) {
        margin-right: 0 !important;
      }
    }
    .upload-img-item {
      img {
      }
    }
  }
}

.video_bot {
  @include flexbox($jc: flex-start, $ai: flex-start);
  > .global-uploader {
    margin-right: 0.32rem;
  }
  > .info-text {
    color: #ff5454;
    font-size: 0.26rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 1.6rem;
    margin-left: 0.39rem;
  }
  .cover_load {
    @include flexbox($fd: column, $jc: center);
    :deep() .img-item {
      height: 1.6rem !important;
      width: 1.6rem !important;
      margin-right: 0.32rem !important;
      margin-top: 0 !important;
      border-radius: 0.08rem !important;
      // margin-right: 0.32rem;
      img {
        border-radius: 0.08rem !important;
      }
    }
  }
}

.btn {
  border-radius: 0.46rem;
  background: $gradientBg;
  width: 5.68rem;
  height: 0.88rem;
  flex-shrink: 0;
  text-align: center;
  line-height: 0.9rem;
  margin: 0.3rem auto;
  color: #fff;

  font-size: 0.32rem;
}

.van-overlay {
  @include flex-center;

  z-index: 1001;
}
</style>
