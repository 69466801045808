<template>
  <div>
    <ETabV2
      v-if="showSort"
      ref="tab"
      offset-top="1rem"
      :placeholder="false"
      :tabList="topTabList"
      :activeId="sort"
      sizeActive="0.28rem"
      sizeTab="0.28rem"
      :animated="false"
      :sticky="true"
      :lineSwitch="true"
      @change="tabbarChange"
    >
    </ETabV2>
    <ERefreshLoadV3 ref="ERefreshLoadV3" :beforeRefresh="onRefresh" :moreData="onLoad" :finished="finished">
      <ul class="main_list">
        <li v-for="item in dataList" :key="item.id" class="item">
          <ComicsOneCard :isNovelItem="true" width="2.18rem" height="2.86rem" :itemData="item">
            <template #mask="{ data }">
              <div v-if="showMask" class="overlay" @click.stop="clickItem(data)">
                <img
                  :src="
                    data.check
                      ? require('@/assets/imgs/mine/active_check.png')
                      : require('@/assets/imgs/mine/default_check.png')
                  "
                  alt=""
                />
              </div>
            </template>
          </ComicsOneCard>
        </li>
        <i></i>
        <i></i>
      </ul>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { collect_list, collect_del, pay_history } from 'api/user'

export default {
  components: {
    ComicsOneCard: () => import('@/components/ComicsTypeCard/comicsOneCard.vue')
  },
  props: {
    cardType: {
      type: String,
      default: 'collect'
    },
    showMask: {
      type: Boolean,
      default: false
    },
    showSort: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sort: 0,
      topTabList: [
        { name: '最近更新', id: 0 },
        { name: '最新关注', id: 1 }
      ],
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 关注列表
      dataList: [],
      // 当前页
      pageNum: 0,
      // 当前个数
      pageSize: 10,
      showUpBtn: false
    }
  },
  computed: {
    info({ $store }) {
      return $store.getters['getUserInfo']
    },
    // 获取历史记录
    getNovelHisList({ $store }) {
      return $store.getters['getNovelHisList']
    }
  },

  mounted() {},

  methods: {
    clickItem(item) {
      this.$set(item, 'check', !item.check)
    },
    async delOne() {
      if (this.cardType === 'history') {
        this.$store.dispatch('setNovelHisList', { type: 'del', arr: this.dataList.filter(item => !item.check) })
        this.dataList = JSON.parse(JSON.stringify(this.getNovelHisList))
        this.finished = true
      } else if (this.cardType === 'collect') {
        // 收藏列表删除
        const res = await collect_del({
          collectType: 10,
          objectIds: this.dataList.filter(item => item.check).map(item => item.id),
          type: 1
        })
        if (res.code === 200) {
          this.pageNum = 1
          this.dataList = []
          await this.getPostCollectList()
        } else {
          return this.$toast('操作失败，请联系后台管理员！')
        }
      }
    },
    toSelectAll(val = true) {
      this.dataList.forEach(item => {
        this.$set(item, 'check', val)
      })
    },
    // 下拉加载
    async onLoad() {
      this.pageNum++
      if (this.cardType === 'collect') {
        await this.getPostCollectList()
      } else if (this.cardType === 'history') {
        this.dataList = JSON.parse(JSON.stringify(this.getNovelHisList))
        this.finished = true
      }
    },
    // 上拉刷新
    async onRefresh() {
      this.pageNum = 0
      this.dataList = []
    },
    // 收藏列表
    async getPostCollectList() {
      const res = await collect_list({
        collectType: 10,
        userId: this.info.id,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        type: 1,
        sort: this.sort
      })
      if (res.code == 200) {
        this.dataList = [...this.dataList, ...(res.data.novelList || [])]

        if (res.data.novelList?.length < this.pageSize || !res.data.novelList) {
          this.finished = true
        }
      } else {
        this.finished = true
      }
    },
    // 购买列表 pay_history
    async getPlayPayList() {
      const res = await pay_history({
        type: 10,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      })
      if (res.code === 200) {
        this.dataList = [...this.dataList, ...(res.data.dataList || [])]
        if (res.data.dataList?.length < this.pageSize || !res.data.dataList) {
          this.finished = true
        }
      } else {
        this.finished = true
      }
    },
    async tabbarChange(val) {
      this.sort = val
      this.$refs.ERefreshLoadV3.onRefresh()
    }
  }
}
</script>
<style lang="scss" scoped>
.main_list {
  padding: 0.32rem 0.32rem 0.32rem;
  @include flexbox($fw: wrap);
  i {
    width: 2.18rem;
  }
}
.item {
  position: relative;
  margin-bottom: 0.24rem;
}
.overlay {
  width: 100%;
  height: 100%;
  border-radius: 0.2rem;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  img {
    width: 0.48rem;
    height: 0.48rem;
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
  }
}
.post-item {
  border-bottom: 0.01rem solid #f0f0f0;
}

::v-deep {
  .van-tab--active {
    .van-tab__text {
      color: #333 !important;
      font-weight: 500;
    }
  }
  .van-tab {
    flex: 0 0 auto;
    line-height: 44px;
    .van-tab__text {
      color: rgba(102, 102, 102, 1);
    }
  }
}
</style>
