<template>
  <!-- 单个动漫样式，宽高由外部定义 -->
  <div class="one-cartoon-card" @click="toPlay">
    <EDecryptImg
      class="one-cartoon-card_bg"
      :imgURL="itemData.coverImg ? itemData.coverImg : ''"
      :style="{ width: width, height: height }"
    >
      <!-- 视频分类标签 -->
      <!-- <VideoType :data="itemData"></VideoType> -->
      <img v-if="isNovelItem" class="isNovelIcon" src="@/assets/imgs/acg/ysxh_left_icon.png" alt="" />
      <div v-if="isNovelItem" class="title">
        <img src="@/assets/imgs/acg/cartoon_bot_icon.png" alt="" />
        <p>{{ itemData.title }}</p>
      </div>
    </EDecryptImg>
    <VideoCardFooter :data="itemData"></VideoCardFooter>
  </div>
</template>

<script>
export default {
  components: {
    VideoCardFooter: () => import('@/components/Home/VideoCardFooter.vue')
    // VideoType: () => import('@/components/VideoType.vue')
  },
  props: ['itemData', 'width', 'height', 'isNovelItem'],
  methods: {
    toPlay() {
      if (!this.isNovelItem) {
        this.$router.push(`/play/longVideo/${this.itemData.id}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.one-cartoon-card_bg {
  position: relative;
  border-radius: 0.08rem;

  :deep() {
    img {
      border-radius: 0.08rem;
    }
  }

  .collect_icon {
    position: absolute;
    width: 0.58rem;
    height: 0.58rem;
    top: 0;
    right: 0;
  }

  .title {
    @include flexbox($jc: flex-start);
    position: absolute;
    bottom: 0.14rem;
    left: 0.12rem;
    color: #fff;

    p {
      @include textoverflow();
    }

    img {
      width: 0.24rem;
      height: 0.24rem;
      margin-right: 0.12rem;
    }
  }
}

.isNovelIcon {
  width: 0.72rem;
  height: 0.72rem;
  position: absolute;

  top: 0.16rem;
  leftt: 0.16rem;
}

.video-type {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.35rem;
  line-height: 0.35rem;
  color: #fff;
  font-size: 0.26rem;
  font-weight: 600;

  div {
    height: 100%;
    height: 0.35rem;
    line-height: 0.35rem;
    text-align: center;
    border-radius: 0.08rem 0rem 0.16rem 0rem;
  }

  &-vip {
    width: 0.64rem;
    background: $gradientBg;
  }

  &-gold {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0.68rem;
    background: linear-gradient(278deg, #892dff 3.02%, #521b99 97.58%);

    img {
      width: 0.22rem;
      height: 0.22rem;
      margin-right: 0.04rem;
    }
  }

  &-free {
    width: 0.64rem;
    background: #1a81ec;
  }
}
</style>
