<template>
  <div>
    <div class="histroy-item">
      <div @click.stop="handleClick">
        <EDecryptImg class="cover" :imgURL="itemData.cover ? itemData.cover : ''">
          <div v-if="itemData.type == 1" class="type_icon">
            <img src="@/assets/imgs/mine/video_type_playlist.png" alt />
            视频
          </div>
          <div v-else class="type_icon">
            <img src="@/assets/imgs/mine/photo_type_playlist.png" alt />
            图片
          </div>
        </EDecryptImg>
      </div>

      <div class="histroy-item-post">
        <div class="title">
          <span @click.stop="handleClick">{{ itemData.title }}</span>
          <div class="edit-icon">
            <img src="@/assets/imgs/edit-icon.png" alt @click="updateItemFn" />
            <span v-if="itemData.isLocal" class="edit-icon-btn" @click="isShare = true">分享</span>
            <span v-else class="edit-icon-btn2">已分享</span>
          </div>
        </div>
        <div class="publish_user">
          <EDecryptImg class="mine_top_bg" :imgURL="itemData.userAvartar ? itemData.userAvartar : ''"></EDecryptImg>
          <span>{{ itemData.userName }}</span>
        </div>
        <div class="desc">
          <span class="type">{{ itemData.total }} {{ itemData.type == 1 ? '视频' : '图片' }}</span>
          <!-- 状态为拒绝时的查看按钮 我的发布页面-->
          <span v-if="itemData.status == 2 && itemContent == 'publish'" class="status" @click="showWhy = true"
            >查看原因</span
          >
          <!-- <span v-else class="time">{{ itemData.createdAt | timeDiffNew }}</span> -->
          <img class="img-del" src="@/assets/imgs/del-icon.png" alt @click="delItemFn" />
        </div>
      </div>
    </div>
    <!-- 原因 -->
    <p v-if="showWhy" class="tip">
      {{ itemData.rejectDesc }}
      <van-icon name="clear" color="#E8619D" size="0.32rem" @click="showWhy = false" />
    </p>

    <!-- 编辑弹窗 -->
    <van-popup v-model="isUpdate" class="popup-update">
      <div class="box">
        <div class="title">编辑播单</div>
        <div class="label">播单标题</div>
        <input v-model="title" class="input" placeholder="请输入播单标题" />
        <div class="label">播单封面</div>
        <div class="add-img">
          <div @click.stop="uploadImgFn">
            <img v-if="!cover" class="cover" src="@/assets/imgs/mine/add_play_list_icon.png" alt />
            <EDecryptImg v-else class="cover" :imgURL="cover"></EDecryptImg>
          </div>
          <UploadImg ref="uploadImg" style="display: none" @result="result" />
          <span>最大每张1M以内</span>
        </div>
        <div class="btn-line">
          <span class="btn1" @click="isUpdate = false">取消</span>
          <span class="btn2" @click="submitUpdate">确认</span>
        </div>
      </div>
    </van-popup>

    <!-- 删除弹窗 -->
    <van-popup v-model="isDel" class="popup-del">
      <div class="box">
        <div class="title">您确定要删除该播单吗？</div>
        <div class="btn-line">
          <span class="btn1" @click="submitDel">确认</span>
          <span class="btn2" @click="isDel = false">取消</span>
        </div>
      </div>
    </van-popup>

    <!-- 分享 -->
    <div class="shareBox">
      <van-popup id="popupShare" v-model="isShare" class="popup-share">
        <div class="box">
          <div class="title">分享播单</div>
          <div class="label">播单主题</div>
          <div class="select" @click="isShareItem = true">
            <input v-model="value1.name" placeholder="请选择主题" readonly />
            <van-icon class="icon" name="play" size="0.28rem" color="#999" />
          </div>
          <div class="btn-line">
            <span class="btn1" @click="isShare = false">取消</span>
            <span class="btn2" @click="onSubit">提交</span>
          </div>
        </div>
      </van-popup>
      <van-action-sheet v-model="isShareItem" :actions="actions" @select="onSelect" />
    </div>
  </div>
</template>

<script>
import { playlist_del, playlist_update, playlist_topicList, creatPlayList } from 'api/playList'

export default {
  components: {
    UploadImg: () => import('@/components/Imgs/UploadImg/index.vue')
  },
  props: ['itemData', 'itemContent', 'isEdit'],

  data() {
    return {
      showWhy: false,
      title: undefined,
      isDel: false,
      isUpdate: false,
      isShare: false,
      cover: undefined,
      actions: [],
      value1: {},
      isShareItem: false
    }
  },
  mounted() {
    this.getTopicList()
  },

  methods: {
    async delItemFn() {
      this.isDel = true
    },

    // 删除
    async submitDel() {
      // 本地存储
      if (this.itemData.isLocal) {
        this.$store.dispatch('setPlayListData', { type: 'delItem', item: this.itemData })
        this.isDel = false
        this.$emit('reloadList')
      } else {
        const res = await playlist_del({
          playListIds: [this.itemData.id]
        })
        if (res.code === 200) {
          this.$emit('reloadList')
          this.isDel = false
          return this.$toast('删除成功！')
        } else {
          this.isDel = false
          return this.$toast(res.tip)
        }
      }
    },

    async updateItemFn() {
      this.isUpdate = true
    },

    // 编辑
    async submitUpdate() {
      if (this.itemData.isLocal) {
        // 本地
        if (this.title || this.cover) {
          let data = {}
          if (this.title) {
            data = {
              id: this.itemData.id,
              cover: this.itemData.cover,
              title: this.title
            }
          }
          if (this.cover) {
            data = {
              id: this.itemData.id,
              cover: this.cover[0],
              title: this.itemData.title
            }
          }
          if (this.title && this.cover) {
            data = {
              id: this.itemData.id,
              cover: this.cover[0],
              title: this.title
            }
          }

          this.$store.dispatch('setPlayListData', {
            type: 'update',
            item: data
          })
          this.$emit('reloadList')
        }
      } else {
        this.getPlaylistUpdate()
      }
      this.isUpdate = false
    },

    // 播单编辑提交
    async getPlaylistUpdate() {
      if (this.title || this.cover) {
        let data = {}
        if (this.title) {
          data = {
            id: this.itemData.id,
            cover: this.itemData.cover,
            title: this.title
          }
        }
        if (this.cover) {
          data = {
            id: this.itemData.id,
            cover: this.cover[0],
            title: this.itemData.title
          }
        }
        if (this.title && this.cover) {
          data = {
            id: this.itemData.id,
            cover: this.cover[0],
            title: this.title
          }
        }
        const res = await playlist_update(data)
        if (res.code === 200) {
          this.$emit('reloadList')
          return this.$toast('更新成功,请等待审核！')
        } else {
          return this.$toast(res.tip)
        }
      }
    },

    // 获取播单主题列表
    async getTopicList() {
      const res = await playlist_topicList({})
      if (res.code === 200) {
        this.actions = res.data.topicList
      } else {
        return this.$toast(res.tip)
      }
    },

    // 图片上传回调
    result(res) {
      this.cover = res
    },

    uploadImgFn() {
      this.$refs.uploadImg.$refs.Uploader.chooseFile()
    },

    onSelect(item) {
      // console.log('item', item)
      this.value1 = item
      this.isShareItem = false
    },

    // 分享提交
    onSubit() {
      if (this.value1.id) {
        this.isShare = false
        this.addPublish()
        this.$store.dispatch('setPlayListData', { type: 'delItem', item: this.itemData })
      } else {
        return this.$toast('请选择主题')
      }
    },

    // 创建播单
    async addPublish() {
      const res = await creatPlayList({
        cover: this.itemData.cover,
        title: this.itemData.title,
        type: this.itemData.type,
        mediaIds: this.itemData.mediaIds, // 视频id列表
        actressphoto: this.itemData.actressphoto, // 图片id列表
        TopicId: this.value1.id // 播单主题
      })
      if (res.code === 200) {
        // this.reload()
        this.$emit('reloadList')
        return this.$toast('分享播当完成，请等待审核~')
      } else {
        return this.$toast(res.msg)
      }
    },

    // 跳转播单详情
    handleClick() {
      const path = this.itemData.isLocal ? '/discover/localDetail' : '/discover/detail'
      this.$router.push({
        path,
        query: {
          id: this.itemData.id,
          type: this.itemData.type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.histroy-item {
  @include flex-align-center;
  margin-bottom: 0.28rem;

  .cover {
    width: 1.44rem;
    height: 1.44rem;

    margin-right: 0.24rem;
    position: relative;
    :deep() {
      img {
        border-radius: 0.12rem;
      }
    }
  }
  .type_icon {
    position: absolute;
    padding: 0.02602rem 0.05204rem;
    border-radius: 0.05204rem;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    bottom: 0.09rem;
    left: 0.08rem;
    @include flexbox($jc: flex-start);
    img {
      width: 0.2rem;
      height: 0.2rem;
      margin-right: 0.05rem;
      border-radius: 0;
    }
  }
}

.histroy-item-post {
  flex: 1;

  .title {
    font-size: 0.28rem;
    font-weight: 600;
    @include flexbox();
    .edit-icon {
      img {
        width: 0.36rem;
        margin-left: 0.12rem;
      }

      .edit-icon-btn {
        font-size: 0.24rem;
        font-weight: 400;
        color: #fff;
        border-radius: 0.36rem;
        padding: 0.05rem 0.2rem;
        background-color: #246bfd;
        margin-left: 0.2rem;
      }
      .edit-icon-btn2 {
        font-size: 0.24rem;
        font-weight: 400;
        color: #a7253d;
        border-radius: 0.36rem;
        padding: 0.05rem 0.2rem;
        background: linear-gradient(148deg, #efcab5 0%, #eec7b2 0.01%, #fae3d0 101.55%);
        margin-left: 0.2rem;
      }
    }
  }

  .publish_user {
    @include flexbox($jc: flex-start);
    color: #999;
    margin: 0.12rem 0;

    .mine_top_bg {
      width: 0.4rem;
      height: 0.4rem;

      margin-right: 0.12rem;

      :deep() {
        img {
          border-radius: 50%;
        }
      }
    }
  }

  .desc {
    @include flexbox();

    .type {
      padding: 0.04rem 0.16rem;
      border-radius: 0.32rem;
      background: rgba(0, 0, 0, 0.1);
      margin-right: 0.12rem;
    }

    .status {
      border-radius: 0.48rem;
      background: #246bfd;
      padding: 0.04rem 0.16rem;
      color: #fff;
      margin-right: 0.12rem;
    }

    .time {
      border-radius: 0.48rem;
      background: rgba(221, 0, 27, 0.05);
      padding: 0.04rem 0.16rem;
      color: $txtActive;
    }

    .img-del {
      width: 0.36rem;
      height: 0.36re;
    }
  }
}

.tip {
  border-radius: 0.08rem;
  background: rgba(221, 0, 27, 0.1);
  color: $txtActive;
  margin: 0.32rem 0.64rem;
  padding: 0.26rem 0.4rem;
  position: relative;
  :deep() {
    .van-icon-clear {
      position: absolute;
      right: 0;
      top: 0;
      width: 0.32rem;
      height: 0.32rem;
    }
  }
}
.edit-submit-pop {
  background: transparent;
  .isDel-txt {
    padding: 0.32rem 0.25rem;
    color: #e8619d;
    background: #fff;
    text-align: center;
    margin: 0 0.31rem 0.24rem 0.31rem;
    border-radius: 0.12rem;
  }
  .hasBg {
    padding: 0.32rem 3.1rem;
    background: $gradientBg;
    color: #fff;
    border-radius: 0.12rem;
    margin: 0 0.31rem 0.24rem 0.31rem;
  }
  .hasClose {
    padding: 0.32rem 3.1rem;
    background: #fff;
    text-align: center;
    margin: 0 0.31rem 0.24rem 0.31rem;
    border-radius: 0.12rem;
  }
  .update-box {
    background: #fff;
    margin: 0 0.31rem 0.24rem 0.31rem;
    border-radius: 0.12rem;
    padding: 0.48rem 0.36rem;
    p {
      text-align: center;
      margin-bottom: 0.32rem;
    }
    :deep() {
      .van-field {
        border-radius: 0.16rem;
        background: #f8f8f8;
      }
    }
  }
}

.popup-update {
  background: #fff;
  border-radius: 0.16rem;

  .box {
    width: 80vw;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.3rem;
    .title {
      width: 100%;
      font-size: 0.3rem;
      font-weight: 400;
      color: #fff;
      background: $gradientBg;
      text-align: center;
      border-radius: 0.16rem 0.16rem 0 0;
      padding: 0.15rem 0;
    }
    .label {
      font-size: 0.28rem;
      font-weight: 500;
      margin: 0.26rem 0.3rem 0;
    }
    .input {
      font-size: 0.28rem;
      border-radius: 6px;
      border: 1px solid var(--4, rgba(0, 0, 0, 0.04));
      margin: 0.26rem 0.3rem 0;
      padding: 0.05rem 0.2rem;

      &::placeholder {
        color: #999;
      }
    }
    .add-img {
      display: flex;
      align-items: flex-end;
      margin: 0 0.3rem;

      .cover {
        width: 1.4rem;
        height: 1.4rem;
        margin-top: 0.26rem;
        margin-right: 0.2rem;
      }

      span {
        font-size: 0.24rem;
        color: #e8619d;
      }
    }

    .btn-line {
      display: flex;
      justify-content: space-between;
      margin: 0.26rem 0.3rem 0;

      span {
        width: 2.4rem;
        font-size: 0.3rem;
        border-radius: 0.06rem;
        padding: 0.1rem 0;
        text-align: center;
      }
      .btn1 {
        background-color: #e6e6e6;
      }
      .btn2 {
        background: $gradientBg;
        color: #fff;
      }
    }
  }
}

.popup-del {
  background: #fff;
  border-radius: 0.16rem;

  .box {
    width: 65vw;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.3rem;

    .title {
      font-size: 0.3rem;
      font-weight: 400;
      margin: 0.3rem;
      text-align: center;
    }
    .btn-line {
      display: flex;
      justify-content: space-between;
      margin: 0 0.8rem;
      span {
        font-size: 0.28rem;
        font-weight: 400;
        padding: 0.1rem 0.3rem;
        color: #fff;
      }
      .btn1 {
        border-radius: 32px;
        background: #ff213c;
      }
      .btn2 {
        border-radius: 32px;
        background: #333;
      }
    }
  }
}

.shareBox {
  :deep() {
    .van-popup--center {
      top: 40%;
    }
  }
  .popup-share {
    border-radius: 0.16rem;

    .box {
      width: 80vw;
      display: flex;
      flex-direction: column;
      padding-bottom: 0.3rem;
      background: #fff;
      .title {
        width: 100%;
        font-size: 0.3rem;
        font-weight: 400;
        color: #fff;
        background: $gradientBg;
        text-align: center;
        border-radius: 0.16rem 0.16rem 0 0;
        padding: 0.15rem 0;
      }
      .label {
        font-size: 0.28rem;
        font-weight: 500;
        margin: 0.26rem 0.3rem 0;
      }

      .select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.26rem 0.3rem 0;
        border-radius: 0.12rem;
        border: 1px solid rgba(0, 0, 0, 0.04);
        padding: 0.1rem;

        input {
          font-size: 0.28rem;
        }
        .icon {
          transform: rotate(90deg);
        }
      }

      .btn-line {
        display: flex;
        justify-content: space-between;
        margin: 0.26rem 0.3rem 0;

        span {
          width: 2.4rem;
          font-size: 0.3rem;
          border-radius: 0.06rem;
          padding: 0.1rem 0;
          text-align: center;
        }
        .btn1 {
          background-color: #e6e6e6;
        }
        .btn2 {
          background: $gradientBg;
          color: #fff;
        }
      }
    }
  }
}
</style>
