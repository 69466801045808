<template>
  <div class="my_collect">
    <EBackTop title="我的收藏">
      <slot slot="right" name="right">
        <div class="right-txt">
          <p v-if="!showEdit" class="edit" @click="showEdit = true">编辑</p>

          <div v-else class="all_edit">
            <p @click.stop="selectAll">全选</p>
            <p @click.stop="del">删除</p>
          </div>
        </div>
      </slot>
    </EBackTop>
    <ETabV2
      ref="tab"
      class="messages_tab"
      offset-top="1rem"
      :placeholder="false"
      background="#fff"
      :tabList="tabList"
      :activeId="type"
      :animated="false"
      lineSwitch
      :sticky="true"
      @change="changeId"
    >
      <!-- 列表数据 -->
      <template #default="scope">
        <component
          :is="compComponent(scope.item)"
          :ref="`childDom${scope.item.id}`"
          :showMask="showEdit"
          :type="scope.item.id"
          cardType="collect"
        ></component>
      </template>
    </ETabV2>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
export default {
  components: {
    playListHistory: () => import('@/components/historyItems/playListHistory.vue'),
    postListHistory: () => import('@/components/historyItems/postListHistory.vue'),
    photoListHistory: () => import('@/components/historyItems/photoListHistory.vue'),
    comicsListHistory: () => import('@/components/historyItems/comicsListHistory.vue'),
    cartoonListHistory: () => import('@/components/historyItems/cartoonListHistory.vue'),
    novelListHistory: () => import('@/components/historyItems/novelListHistory.vue'),
    videoListHistory: () => import('@/components/historyItems/videoListHistory.vue'),
    dreamListHistory: () => import('@/components/historyItems/dreamListHistory.vue')
  },
  data() {
    return {
      tabList: [
        { id: 1, name: '影视' },
        { id: 5, name: '帖子' },
        { id: 9, name: '图集' },
        { id: 13, name: '播单' }
        // { id: 8, name: '漫画' },
        // { id: 3, name: '动漫' },
        // { id: 10, name: '有声小说' },
        // { id: 22, name: '剧场' }
      ],
      checkItem: false,
      // 当前页
      type: 1,
      showEdit: false,
      rightTitle: '编辑'
    }
  },
  methods: {
    changeId(activeId) {
      this.type = activeId
    },
    compComponent(item) {
      switch (item.id) {
        case 13:
          return 'playListHistory'
        case 1:
          return 'videoListHistory'
        case 5:
          return 'postListHistory'
        case 9:
          return 'photoListHistory'
        case 8:
          return 'comicsListHistory'
        case 3:
          return 'cartoonListHistory'
        case 10:
          return 'novelListHistory'
        case 22:
          return 'dreamListHistory'
        default:
          return 'playListHistory'
      }
    },
    del() {
      let dataList = this.$refs[`childDom${this.type}`].dataList
      if (dataList.find(item => item.check == true)) {
        Dialog.confirm({
          title: '提示',
          message: '确认删除该收藏？'
        }).then(() => {
          this.showEdit = false
          this.$refs[`childDom${this.type}`].delOne()
        })
      } else {
        Toast({
          message: '请选择删除内容！',
          position: 'top'
        })
      }
    },
    selectAll() {
      this.$refs[`childDom${this.type}`]?.toSelectAll()
    }
  }
}
</script>

<style lang="scss" scoped>
.right-txt {
  .edit {
    color: $txtActive;
  }

  .all_edit {
    @include flexbox($jc: flex-start);
    p {
      color: $txtActive;
      &:last-child {
        border-radius: 0.08rem;
        background: $txtActive;
        padding: 0.04rem 0.16rem;
        color: #fff;
        margin-left: 0.2rem;
      }
    }
  }
}

::v-deep {
  .van-tabs__nav {
    .van-tab {
      flex-grow: 0;
      flex: unset;
    }
  }
}
</style>
