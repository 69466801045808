<template>
  <div class="recommend">
    <div class="Indicator"></div>
    <ERefreshLoadV3 :finished="finished" :beforeRefresh="refreshData" :moreData="moreData">
      <ETabBar :list="topTabList" :tabbar-config="tabbarConfig" :active-num="activeId" @change="tabbarChange">
        <div class="recommend-containe">
          <RecommendVideo v-if="type == '1'" :playlist="playlist" />
          <RecommendPhoto v-else :listData="playlist" />
        </div>
      </ETabBar>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { playlist_detail_list } from '@/api/playList'
export default {
  components: {
    RecommendVideo: () => import('../playList/components/video.vue'),
    RecommendPhoto: () => import('../components/waterfall.vue')
  },
  data() {
    return {
      activeId: 0,
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.28rem', //默认字体大小
        fontSizeActive: '0.3rem', //选中字体大小
        fontColor: '#999', //默认字体颜色
        fontColorActive: '#333', //选中字体颜色
        bg: '#f5f5f5', //默认背景色
        bgActive: '#f5f5f5', //选中背景色
        lineBott: '0rem',
        lineColorActive: '#333',
        lineWidth: '2.5rem', //下划线宽度 String
        lineHeight: '.04rem', //下划线高度 String
        sticky: true, //粘性布局
        offsetTop: '1rem', //距顶部距离
        tabFlex: true
      },
      topTabList: [
        {
          id: 1,
          name: '精选推荐'
        },
        {
          id: 2,
          name: '最新发布'
        },
        {
          id: 3,
          name: '最高热度'
        }
      ],
      pageNum: 1,
      pageSize: 10,
      sort: 1,
      playlist: [],
      finished: false,
      type: this.$route.query.type
    }
  },
  methods: {
    async tabbarChange(sort) {
      this.sort = sort
      this.playlist = []
      this.pageNum = 1
      this.finished = false
      await this.getList()
    },
    async getList() {
      const res = await playlist_detail_list({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        playListId: Number(this.$route.query.id),
        sort: this.sort
      })
      if (this.$route.query.type == 1) {
        if (res.code === 200) {
          this.$emit('playListDetail', res.data)
          if (!res.data || !res.data.mediaList || res.data.mediaList.length < this.pageSize) {
            this.finished = true
          }
          if (res.data.mediaList) this.playlist = [...this.playlist, ...res.data.mediaList]
        } else {
          this.finished = true
          return this.$toast(res.tip || res.data)
        }
      } else {
        if (res.code === 200) {
          this.$emit('playListDetail', res.data)
          if (!res.data || !res.data.actressphotoList || res.data.actressphotoList.length < this.pageSize) {
            this.finished = true
          }
          if (res.data.actressphotoList) this.playlist = [...this.playlist, ...res.data.actressphotoList]
        } else {
          this.finished = true
          return this.$toast(res.tip || res.data)
        }
      }
    },
    refreshData() {
      this.finished = false
      // 一级列表请求参数
      this.pageNum = 0
      this.playlist = []
    },
    async moreData() {
      this.pageNum++
      await this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.recommend {
  background: #f5f5f5;
  width: 100%;
  border-radius: 0.4rem 0.4rem 0rem 0rem;
  padding-top: 0.24rem;
  min-height: calc(100vh - 6.2rem);
  position: relative;
  z-index: 2;
  margin-top: -0.3rem;
  .recommend-containe {
    padding: 0 0.32rem;
  }
  .Indicator {
    width: 0.72rem;
    height: 0.1rem;
    background-color: #333;
    border-radius: 99px;
    margin: 0 auto;
  }
  ::v-deep {
    .van-tabs__nav {
      // justify-content: space-evenly;

      .van-tab {
        flex: 1;
        border-bottom: 0.01rem solid #999999;
      }
    }
  }
}
</style>
