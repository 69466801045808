<template>
  <div class="lady-info" @click.stop="handleClick(data)">
    <EDecryptImg class="mine_top_bg" :imgURL="data.Avatar || data.avatar" />
    <div class="hot-item-right">
      <div>{{ data.Name || data.name }}</div>
      <div>{{ data.Desc || data.desc }}</div>
      <div>
        <span>视频：{{ data.movieCount || data.MovieCount }}</span>
        <span>图片：{{ data.photoCount || data.PhotoCount }}</span>
        <span>点赞：{{ data.likeCount || data.LikeCount }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LadyListType1',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleClick(data) {
      if (data) {
        this.$router.push(`/discover/actor/${data.id}`)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.lady-info {
  @include flex-align-center;
  margin-bottom: 0.2rem;
  .mine_top_bg {
    width: 1.2rem;
    height: 1.2rem;
    :deep() img {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 0.08rem;
    }
  }
  .hot-item-right {
    margin-left: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > div {
      &:nth-child(1) {
        color: #333;
        font-size: 0.32rem;
        font-weight: 400;
      }
      &:nth-child(2) {
        color: #9a9a9a;
        font-size: 0.24rem;
        margin: 0.04rem 0;
        @include textoverflow;
      }
      &:nth-child(3) {
        color: #676767;
        font-size: 0.24rem;
        font-weight: 400;
        span {
          margin-right: 0.18rem;
          position: relative;
          &:nth-child(-n + 2) {
            &::after {
              content: '|';
              position: absolute;
              right: -0.08rem;
              top: 0;
              // transform: translate(0, -50%);
              width: 0.02rem;
              height: 0.24rem;
            }
          }
        }
      }
    }
  }
}
</style>
