<template>
  <div class="main_list">
    <VideoshowType1 v-for="(item, index) in list" :key="index" :data="item"></VideoshowType1>
  </div>
</template>

<script>
export default {
  components: {
    VideoshowType1: () => import('./videoListType/videoshowType1')
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.main_list {
  @include flexbox($fw: wrap);
}
</style>
