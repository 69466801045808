<template>
  <div class="wrapper">
    <div
      v-for="item in data"
      :key="item.id"
      class="item"
      :class="{ active: item.id === value }"
      @click="toSelect(item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'TagSelect',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    value: {
      type: Number
    }
  },
  data() {
    return {}
  },
  methods: {
    toSelect(item) {
      this.$emit('input', item.id)
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  .item {
    flex-shrink: 0;
    flex-grow: 0;
    height: 0.55rem;
    line-height: 0.55rem;
    @include flex-center;
    color: #666666;
    transition: all 0.2s ease-in-out;
    width: 100%;
    margin-bottom: 0.32rem;
    font-size: 0.3rem;
    @include textoverflow(1);
    text-align: center;
    &.active {
      color: #e8619d;
      background-color: rgba(221, 0, 27, 0.15);
    }
  }
}
</style>
