<template>
  <div class="item" @click="handleClick(data)">
    <div class="piclevel1">
      <div>
        <EDecryptImg :imgURL="data.cover"> </EDecryptImg>
      </div>
      <div class="piclevel2">
        <div><EDecryptImg :imgURL="data.pictures[0]"> </EDecryptImg></div>
        <div class="piclevel3">
          <EDecryptImg :imgURL="data.pictures[1]"> </EDecryptImg>
          <EDecryptImg :imgURL="data.pictures[2]"> </EDecryptImg>
        </div>
      </div>
    </div>
    <div class="title">{{ data.name }}</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['data'],
  data() {
    return {}
  },

  mounted() {},

  methods: {
    handleClick() {
      this.$router.push({
        path: `/discover/imgList`,
        query: {
          id: this.data.id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.item {
  width: 3.36rem;
  height: 2.42rem;
  .piclevel1,
  .piclevel3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .piclevel1 {
    > div {
      width: 1.66rem;
      height: 1.9rem;
      border-radius: 0.08rem;
      overflow: hidden;
    }
  }
  .piclevel2 {
    > div:first-child {
      width: 1.66rem;
      height: 0.86rem;
      border-radius: 0.08rem;
      overflow: hidden;
    }
  }
  .piclevel3 {
    margin-top: 0.04rem;
    > div {
      width: 0.8rem;
      height: 1rem;
      border-radius: 0.08rem;
      overflow: hidden;
    }
  }
  .title {
    font-weight: 400;
    font-size: 0.28rem;
    margin-top: 0.06rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
