<!-- home -->
<template>
  <div>
    <EBackTop title="我的银行卡" @click-right="editBlank">
      <slot slot="right" name="right">
        <div class="right-txt">{{ !showEdit ? '编辑' : '完成' }}</div>
      </slot>
    </EBackTop>
    <ERefreshLoadV3
      ref="ERefreshLoadV3"
      class="main_list"
      :beforeRefresh="onRefresh"
      :moreData="onLoad"
      :finished="finished"
    >
      <ul class="blank_list">
        <li v-for="item in 10" :key="item" class="blank_item">
          <div class="item_left">
            <p class="top_name">
              <span>银行名称</span>
              <span>持卡人姓名</span>
            </p>
            <p>储蓄卡:6217 0001 3000 8255 555</p>
          </div>

          <van-radio v-if="showEdit" :name="item" @click="change(item)">
            <template #icon="props">
              <img class="img-icon" src="@/assets/imgs/mine/del_radio.png" />
            </template>
          </van-radio>
          <van-radio
            v-else
            :name="item"
            :class="activeItem === item ? 'active_radio' : ''"
            icon-size="0.24rem"
            @click="change(item)"
          >
          </van-radio>
        </li>
        <!-- add -->
        <li class="add_blank" @click="showAdd = true">
          <van-icon name="plus" />
          添加银行卡
        </li>
      </ul>
    </ERefreshLoadV3>
    <!-- 添加银行卡弹窗 -->
    <van-popup v-model="showAdd" class="add_pop">
      <div class="content_main">
        <p class="title">添加银行卡</p>
        <ul>
          <li>
            <p class="label">
              <img src="@/assets/imgs/mine/sub_line.png" alt="" />
              请输入开户行名称
            </p>
            <van-field v-model="form.name" placeholder="请输入开户行名称" />
          </li>
          <li>
            <p class="label">
              <img src="@/assets/imgs/mine/sub_line.png" alt="" />
              请输入持卡人姓名
            </p>
            <van-field v-model="form.name" placeholder="请输入持卡人姓名" />
          </li>
          <li>
            <p class="label">
              <img src="@/assets/imgs/mine/sub_line.png" alt="" />
              请输入银行卡账号
            </p>
            <van-field v-model="form.name" placeholder="请输入银行卡账号" />
          </li>
        </ul>
      </div>
      <p class="add_btn" @click="addBlank">确定</p>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from 'vant'
export default {
  components: {},
  data() {
    return {
      finished: false,
      radio: 0,
      showEdit: false,
      // 当前页
      pageNum: 1,
      // 当前个数
      pageSize: 10,
      activeItem: 0,
      showAdd: false,
      form: {}
    }
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  async mounted() {},
  beforeDestroy() {},

  methods: {
    // 下拉加载
    onLoad() {
      this.pageNum++
      // this.get_transList()
      this.finished = true
    },
    // 上拉刷新
    onRefresh() {
      this.pageNum = 1
      this.transList = []
      this.finished = false
      // this.get_transList()
    },
    change(item) {
      if (this.showEdit) {
        Dialog.confirm({
          title: '提示',
          message: '确认删除该银行卡？'
        })
          .then(() => {
            // on confirm
          })
          .catch(() => {
            // on cancel
          })
      } else {
        this.activeItem = item
      }
    },

    editBlank() {
      this.showEdit = !this.showEdit
    },
    addBlank() {
      this.showAdd = false
    }
  }
}
</script>
<style lang="scss" scoped>
.blank_list {
  padding: 0.4rem 0.32rem;

  .blank_item {
    border-radius: 0.12rem;
    background: rgba(0, 0, 0, 0.04);
    padding: 0.3rem 0.24rem;
    @include flexbox();
    margin-bottom: 0.24rem;

    .item_left {
      font-size: 0.24rem;

      .top_name {
        margin-bottom: 0.16rem;

        span {
          &:first-child {
            font-size: 0.32rem;
            font-weight: 600;
            margin-right: 0.1rem;
          }
        }
      }
    }

    :deep() {
      .van-radio {
        overflow: visible;
      }

      .van-icon-success {
        &::before {
          display: none;
        }
      }
    }
  }
}

.active_radio {
  background-color: $txtActive;
  border-radius: 50%;
  border: 1px solid $txtActive;
}

.add_blank {
  border-radius: 0.08rem;
  border: 1px dashed #999;
  font-size: 0.32rem;
  font-weight: 600;
  padding: 0.52rem 0;
  text-align: center;
}

.img-icon {
  width: 0.4rem;
  height: 0.4rem;
}

.add_pop {
  background-color: transparent;
}

.content_main {
  background-color: #fff;
  border-radius: 0.12rem;

  .title {
    padding: 0.32rem 0;
    border-bottom: 0.01rem solid #f0f0f0;
    text-align: center;
    font-size: 0.34rem;
  }

  ul {
    padding: 0.48rem 0.64rem;

    li {
      margin-bottom: 0.32rem;

      .label {
        font-size: 0.28rem;
        margin-bottom: 0.12rem;

        img {
          width: 0.1rem;
          height: 0.4rem;
        }

        @include flexbox($jc: flex-start, $ai: flex-end);
      }

      :deep() {
        .van-cell {
          border-radius: 0.08rem;
          background: rgba(0, 0, 0, 0.04);
          width: 5.8rem;
        }
      }
    }
  }
}

.add_btn {
  color: $txtActive;
  border-radius: 0.12rem;
  background: #fff;
  padding: 0.32rem 0;
  text-align: center;
  margin-top: 0.24rem;
  font-size: 0.28rem;
}
</style>
