<template>
  <van-overlay :show="show">
    <div class="price-select">
      <div class="price-select-header">设置价格</div>
      <div class="price-select-content">
        <div
          v-for="item in priceList"
          :key="item"
          class="price-select-item"
          :class="{ active: initValue === item }"
          @click="toSelect(item)"
        >
          <img src="~@/assets/imgs/community/light.png" alt="" />
          <span>{{ item }}</span>
        </div>
        <div class="price-select-item other">
          <van-field
            :value="initValue"
            maxlength="3"
            class="field"
            type="number"
            placeholder="其他金额"
            @change="toInput"
          />
        </div>
      </div>
      <div class="price-select-footer">
        <div class="price-select-cancel" @click="toCancel">稍后再说</div>
        <div class="price-select-confirm" @click="toConfirm">确定设置</div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
export default {
  name: 'PriceSelectPop',
  props: {
    value: {
      type: Number,
      default: undefined
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      initValue: 0,
      priceList: [10, 20, 30, 40, 50, 60, 70, 80]
    }
  },
  computed: {},
  mounted() {
    this.initValue = this.value !== 0 && !this.value ? undefined : this.value
  },
  methods: {
    toSelect(e) {
      this.initValue = e
    },
    toInput(e) {
      this.initValue = +e.target.value
    },
    toConfirm() {
      this.$emit('input', this.initValue)
      this.$emit('update:show', false)
    },
    toCancel() {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style scoped lang="scss">
.price-select {
  background-color: #ffffff;
  width: 6rem;
  height: 6rem;
  border-radius: 0.2rem;
  color: #666666;
  .price-select-header {
    position: relative;
    height: 1.4rem;
    line-height: 1.4rem;
    text-align: center;
    font-size: 0.4rem;
    font-weight: 500;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 0.02rem;
      position: absolute;
      background: linear-gradient(
        90deg,
        rgba(78, 78, 78, 0) -27.67%,
        rgba(78, 78, 78, 0.3) 42.37%,
        rgba(78, 78, 78, 0) 118.5%
      );
    }
  }
  .price-select-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.36rem 0.6rem;
    .price-select-item {
      @include flex-center;
      width: 1.44rem;
      height: 0.72rem;
      border: 0.01rem dotted #666666;
      border-radius: 0.16rem;
      margin-right: 0.24rem;
      margin-bottom: 0.24rem;
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
      &.active {
        border: 0.01rem solid #e8619d;
        border-radius: 0.16rem;
        background: rgba(221, 0, 27, 0.1);
      }
      > img {
        margin-right: 0.16rem;
        color: rgba(51, 51, 51, 1);
      }
      &.other {
        border: none;
        .field {
          padding: 0.18rem 0.24rem;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.1);
          border-radius: 0.16rem;
        }
      }
    }
  }
  .price-select-footer {
    @include flexbox($jc: center);
    padding: 0 0.6rem;
    color: #ffffff;
    .price-select-cancel {
      width: 1.92rem;
      height: 0.72rem;
      background-color: #666666;
      @include flex-center;
      border-radius: 0.38rem;
      border: none;
    }
    .price-select-confirm {
      margin-left: 0.24rem;
      width: 2.64rem;
      height: 0.72rem;
      background: linear-gradient(141.62deg, #ffae61 10.1%, #ff1101 46.78%, #ff00b9 86.53%);
      @include flex-center;
      border-radius: 0.38rem;
    }
  }
}
</style>
