<!--
 * @Author: 
 * @Mail: 
 * @Date: 2024-01-31 15:40:02
 * @LastEditTime: 2024-02-16 14:42:50
 * @LastEditors: 江涛 wangtao524713@gmail.com
 * @FilePath: /haoseH5/src/views/Home/index.vue
-->
<!-- home -->
<template>
  <div>
    <EBackTop :hasSuperiorClick="true" :color="'#000'" :background="'#fff'" :placeholder="true" @goBack="goback()">
      <slot slot="title">
        <input v-model="content" type="text" class="search-input" placeholder="输入搜索文字" />
      </slot>
      <slot slot="right">
        <div class="right" @click="getSeach">搜索</div>
      </slot>
    </EBackTop>
    <div ref="container" class="index-container">
      <ETabBar
        :list="activeList"
        :tabbar-config="tabbarConfig"
        :active-num="activeId"
        class="ETabBar"
        @change="changeTab"
      >
        <template #default="scope">
          <component :is="compComponent(scope.data.id)" :id="activeId" :ref="'item' + scope.index" :navItem="scope" />
        </template>
      </ETabBar>
    </div>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
export default {
  mixins: [scrollMixin],
  name: 'SearchPage',
  components: {
    ActorSearch: () => import('@/components/Search/searchPage/actorSearch'),
    PlaylistSearch: () => import('@/components/Search/searchPage/playlistSearch')
  },
  data() {
    return {
      activeList: [
        { name: '播单', id: 13 },
        { name: '女优', id: 7 }
      ],
      activeId: 13,
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.32rem', //默认字体大小
        fontSizeActive: '0.32rem', //选中字体大小
        fontColor: '#333333', //默认字体颜色
        fontColorActive: '#E8619D', //选中字体颜色
        bg: '#fff', //默认背景色
        bgActive: '#fff', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#E8619D',
        lineWidth: '0.28rem', //下划线宽度 String
        sticky: true, //粘性布局
        offsetTop: '0.99rem', //距顶部距离
        tabFlex: true
      },
      pageNum: 1,
      pageSize: 10,
      content: ''
    }
  },

  computed: {},

  activated() {
    this.activeId = this.$route.query.type ? +this.$route.query.type : this.activeId
  },
  methods: {
    compComponent(data) {
      switch (data) {
        case 13:
          return 'PlaylistSearch'
        case 7:
          return 'ActorSearch'
      }
    },

    // 获取数据
    async getSeach() {
      if (this.activeId === 13) {
        this.$nextTick(() => {
          this.$refs['item0'].setContent(this.content)
        })
      } else if (this.activeId === 7) {
        this.$nextTick(() => {
          this.$refs['item1'].setContent(this.content)
        })
      }
    },
    //返回按钮
    goback() {
      this.$router.go(-1)
    },
    changeTab(id) {
      this.activeId = id
      this.getSeach()
    }
  }
}
</script>
<style lang="scss" scoped>
.index-container {
  padding: 0rem 0 0 0;
  position: relative;
  overflow: hidden;

  .HomeSearch {
    position: fixed;
    top: 0.88rem;
    z-index: 1;
  }

  .ETabBar {
    z-index: 0;
  }
}

::v-deep {
  .van-nav-bar__title {
    margin-left: 0.96rem;
  }
}

.search-input {
  background: rgba(217, 217, 217, 0.3);
  width: 2.67 * 2rem;
  height: 0.6rem;
  line-height: 0.6rem;
  border-radius: 0.12 * 2rem 0.15 * 2rem 0.15 * 2rem 0.04 * 2rem;
  color: #939496;
  font-size: 0.28rem;
  padding-left: 0.21rem;
  box-sizing: border-box;

  .searchIcon {
    width: 0.32rem;
    height: 0.32rem;
  }
}
.pianku_main_icon {
  position: fixed;
  top: 1.25rem;
  right: 0.32rem;
  width: 1.16rem;
  height: 0.36rem;
  z-index: 100;
}

.right {
  font-size: 0.32rem;
  color: #333;
}
</style>
