<template>
  <div class="signIn">
    <div class="sign_back" @click="$router.go(-1)">
      <van-icon name="arrow-left" size="28" color="#fff" />
    </div>

    <div class="sign_points">
      <span class="text">我的积分</span>
      <span class="num">{{ userInfo.points }}</span>
      <span class="btn" @click="$router.push('/Mine/exchangeVip')">去兑换</span>
    </div>

    <div class="sign_box">
      <div class="box_title">
        <div class="title_left">做每日任务 领奖励</div>
        <div class="title_right">
          已累计签到
          <span>{{ userIncomeInfo.checkedDays }}</span>
          天
        </div>
      </div>

      <div class="box_card">
        <div v-for="item in userIncomeInfo.todaySetup" :key="item.day_num">
          <div
            v-if="item.day_num != 7"
            class="card_item"
            :class="userIncomeInfo.checkedDays > item.day_num - 1 ? 'card_item_on' : ''"
          >
            <img
              v-if="userIncomeInfo.checkedDays > item.day_num - 1"
              class="icon"
              src="@/assets/imgs/mine/myShouyi/subscript.png"
            />
            <span class="day">第{{ item.day_num }}天</span>
            <img class="img" src="@/assets/imgs/mine/myShouyi/icon.png" />
            <span class="text" v-if="item.points">
              积分 {{ item.points[0] }}
              <span v-if="item.points.length > 1">+{{ item.points[1] }}</span>
            </span>
          </div>
          <div v-else class="card_item_7" :class="userIncomeInfo.checkedDays > 6 ? 'card_item_on' : ''">
            <img v-if="userIncomeInfo.checkedDays > 6" class="icon" src="@/assets/imgs/mine/myShouyi/subscript.png" />
            <div class="item_line1">
              <div class="line1_left">
                <span class="day">第{{ item.day_num }}天</span>
                <span class="btn">超值</span>
              </div>
              <img class="line1_right" src="@/assets/imgs/mine/myShouyi/day7.png" />
            </div>
            <span class="text" v-if="item.points">
              积分 {{ item.points[0] }}
              <span>+{{ item.points[1] }}</span>
            </span>
          </div>
        </div>
      </div>

      <div class="box_btn">
        <span v-if="!userIncomeInfo.todayChecked" class="btn1" @click="getCheckinClick">立即签到</span>
        <span v-else class="btn2">
          <img src="@/assets/imgs/mine/myShouyi/icon1.png" />
          已签到
        </span>
      </div>
    </div>

    <div class="sign_ruler">
      <div class="ruler_title">签到规则</div>
      <div class="text">
        1.需要
        <span>每天连续签到</span>，若未连续签到则重新从第一天开始；
      </div>
      <div class="text">
        2.连续签到可获得，
        <span>额外积分奖励</span>；
      </div>
      <div class="text">3.积分可兑换各类奖品（VIP体验卷，AI脱衣/换脸，观影券等）</div>
    </div>
  </div>
</template>

<script>
import { checkin_page, userInfo, checkin_click } from '@/api/user'

export default {
  data() {
    return {
      userIncomeInfo: {}
    }
  },

  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },

  mounted() {
    this.getUserInfo()
    this.getUserIncome()
  },

  methods: {
    async getUserInfo() {
      const res = await userInfo()
      if (res && res.code === 200) {
        this.$store.dispatch('setUserInfo', res.data)
      }
    },

    // 获取用户签到信息
    async getUserIncome() {
      const res = await checkin_page({})
      if (res.code === 200) {
        this.userIncomeInfo = res.data
      } else {
        return this.$toast('请求错误，请联系后台管理人员！')
      }
    },

    // 签到
    async getCheckinClick() {
      const res = await checkin_click({})
      if (res.code === 200) {
        this.getUserIncome()
        this.getUserInfo()
        return this.$toast('签到成功')
      } else {
        return this.$toast('请求错误，请联系后台管理人员！')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.signIn {
  width: 100vw;
  height: 100vh;
  background-image: url('~@/assets/imgs/mine/myShouyi/bg.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-color: #fef6f1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sign_back {
    position: fixed;
    top: 0.8rem;
    left: 0.3rem;
  }

  .sign_points {
    width: 4rem;
    height: 0.8rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 0.32rem;
    border-radius: 0.48rem;
    background-color: #fc658acf;
    margin-top: 2.5rem;
    font-size: 0.3rem;
    font-weight: 400;

    .text {
      color: #fff;
    }
    .num {
      color: #ffd600;
    }
    .btn {
      width: 1.12rem;
      height: 0.48rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fdaec0;
      border-radius: 0.25rem;
      font-size: 0.26rem;
      font-weight: 400;
      color: #ff243e;
    }
  }

  .sign_box {
    width: 6.86rem;
    background-color: #fff;
    border-radius: 0.32rem;
    margin-top: 0.5rem;
    padding: 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .box_title {
      width: 96%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title_left {
        font-size: 0.32rem;
        font-weight: 500;
        color: #874a46;
      }
      .title_right {
        font-size: 0.28rem;
        font-weight: 400;
        color: #874a46;
        background-color: #fff5f1;
        border-radius: 0.62rem;
        padding: 0.1rem 0.3rem;
        span {
          color: #e8619d;
        }
      }
    }

    .box_card {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0.1rem;
      .card_item {
        width: 1.44rem;
        background-color: #fff9f7;
        border-radius: 0.12rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0.3rem;
        .icon {
          width: 0.42rem;
          height: 0.42rem;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 0.12rem 0 0 0;
        }
        .day {
          font-size: 0.3rem;
          font-weight: 500;
          color: #874a46;
          margin-top: 0.2rem;
        }
        .img {
          width: 0.3rem;
          height: 0.3rem;
          margin-top: 0.2rem;
        }
        .text {
          width: 100%;
          background-color: #ffe9e1;
          padding: 0.1rem 0;
          border-radius: 0 0 0.12rem 0.12rem;
          font-size: 0.26rem;
          font-weight: 400;
          color: #874a46;
          margin-top: 0.2rem;
          text-align: center;
          span {
            display: inline-block;
            margin-left: -5px;
            color: #ff9900;
          }
        }
      }
      .card_item_on {
        border: 0.5px solid #ff5c9d;
      }
      .card_item_7 {
        width: 2.98rem;
        background-color: #fff9f7;
        border-radius: 0.12rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0.3rem;

        .icon {
          width: 0.42rem;
          height: 0.42rem;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 0.12rem 0 0 0;
        }
        .item_line1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .line1_left {
            display: flex;
            flex-direction: column;
            .day {
              font-size: 0.3rem;
              font-weight: 500;
              color: #874a46;
              margin-top: 0.2rem;
            }
            .btn {
              font-size: 0.26rem;
              font-weight: 400;
              border-radius: 0.6rem;
              padding: 0.03rem 0.1rem;
              background: linear-gradient(147deg, #ff9393 18.86%, #ff37a3 101.55%);
              color: #fff;
            }
          }
          .line1_right {
            width: 1.4rem;
            margin-left: 0.2rem;
          }
        }
        .text {
          width: 100%;
          background-color: #ffe9e1;
          padding: 0.1rem 0;
          border-radius: 0 0 0.12rem 0.12rem;
          font-size: 0.26rem;
          font-weight: 400;
          color: #874a46;
          margin-top: 0.2rem;
          text-align: center;
          span {
            display: inline-block;
            margin-left: -5px;
            color: #ff9900;
          }
        }
      }
    }

    .box_btn {
      span {
        width: 4.8rem;
        height: 0.84rem;
        border-radius: 0.6rem;
        font-size: 0.32rem;
        font-weight: 400;
        margin: 0.5rem 0 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
      .btn1 {
        background: linear-gradient(147deg, #ff748f 18.86%, #ff2e79 101.55%);
      }
      .btn2 {
        background: #baa6ae;
        img {
          width: 0.32rem;
          margin-right: 0.1rem;
        }
      }
    }
  }

  .sign_ruler {
    width: 6.86rem;
    background-color: #fff;
    border-radius: 0.32rem;
    margin-top: 0.5rem;
    padding: 0.3rem;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    .ruler_title {
      font-size: 0.32rem;
      font-weight: 500;
      color: #874a46;
    }
    .text {
      color: #874a46;
      font-size: 0.26rem;
      font-weight: 400;
      // display: flex;
      // flex-wrap: wrap;
      white-space: pre-wrap;
      margin-top: 0.2rem;
      line-height: 0.38rem;
      span {
        color: #ff3471;
      }
    }
  }
}
</style>
