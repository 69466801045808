<template>
  <div class="progress-bar">
    <span ref="fill" class="progress-fill" :style="[{ 'background-color': color, width: value + '%' }]"></span>
  </div>
</template>
<script>
export default {
  name: 'Progressbar',
  props: {
    color: {
      type: String,
      default: 'rgba(221, 0, 27, 1)'
    },
    value: {
      type: [String, Number],
      default: 0
    }
  },

  methods: {}
}
</script>
<style lang="scss" scoped>
.progress-bar {
  width: 0.96rem;
  background-color: rgb(232, 232, 232);
  height: 0.08rem;
  border-radius: 0.08rem;
  overflow: hidden;
  position: relative;
  .progress-fill {
    display: block;
    position: absolute;
    height: 100%;
    max-width: 100%;
    border-radius: 42px;
    width: 0;
    transition: 0.2s width ease-in-out;
  }
}
</style>
