<template>
  <van-popup :value="show" get-container="body" position="bottom" @input="toClose">
    <div class="top">
      <div class="title">
        <van-icon name="arrow-left" />
        <span>添加到播单</span>
        <div @click="toAdd">新建播单</div>
      </div>
      <ul class="playlist">
        <li v-for="item in playList" :key="item.id" :class="{ active: item.checked }" @click="handleCheck(item)">
          <EDecryptImg class="mine_top_bg" :imgURL="item.cover">
            <div class="photo-yellow">
              <img v-if="item.type === 1" src="@/assets/imgs/discover/video.png" alt />
              <img v-else src="@/assets/imgs/discover/photo-yellow.png" alt />
              <span>{{ item.type === 1 ? '视频' : '图片' }}</span>
            </div>
            <div class="subtrac">
              <img src="@/assets/imgs/discover/Subtract1.png" alt />
              <img src="@/assets/imgs/discover/Subtract.png" alt />
            </div>
          </EDecryptImg>
          <div class="hot-item-post">
            <div>
              <div class="hot-item-post-title">{{ item.title }}</div>
              <EDecryptImg class="mine_top_bg_avatar" :imgURL="item.userAvartar">
                <span class="hot-item-post-user">{{ item.userName }}</span>
              </EDecryptImg>
              <div class="hot-item-post-count">
                {{ item.total }}
                {{ item.type === 1 ? '个视频' : '张图片' }}
              </div>
            </div>
            <div class="check-box">
              <img v-if="item.checked" src="@/assets/imgs/share/checked.png" alt />
              <img v-else src="@/assets/imgs/share/uncheck.png" alt />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="!hasChecking" class="bottom-btn" @click="toClose">取消</div>
    <div v-else class="bottom-btn checking" @click="handleAdd">确认</div>
  </van-popup>
</template>

<script>
import { myPlayList, sharePlayList } from '@/api/playList'
export default {
  model: {
    prop: 'show',
    event: 'input'
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: Number,
      required: true
    },
    type: {
      type: Number,
      default: 1 //1 视频 2图片
    }
  },
  data() {
    return {
      popupMsg: {},
      pageNum: 1,
      pageSize: 10,
      playList: []
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    hasChecking() {
      return this.playList.some(v => v.checked)
    },
    getLocalPlayListData({ $store }) {
      return JSON.parse(JSON.stringify($store.getters['getPlayListData']))
    }
  },
  mounted() {},
  created() {
    this.get_play_list()
  },
  methods: {
    toClose() {
      this.$emit('input', false)
    },
    async get_play_list() {
      try {
        const res = await myPlayList({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          status: 3,
          userId: this.userInfo.id
        })
        this.playList = [...res.data.playList, ...this.getLocalPlayListData].filter(item => item.type === this.type)
        console.log('res', res)
      } catch (error) {
        console.log('error', error)
      }
    },
    handleCheck(item) {
      this.$set(item, 'checked', !item.checked)
    },
    async handleAdd() {
      try {
        const item = this.playList.find(v => v.checked)
        if (item.isLocal) {
          if (this.type === 1) {
            if (!Array.isArray(item.mediaIds)) item.mediaIds = []
            item.mediaIds.unshift(this.objectId)
            item.mediaIds = [...new Set(item.mediaIds)]
          } else if (this.type === 2) {
            if (!Array.isArray(item.actressphoto)) item.actressphoto = []
            item.actressphoto.unshift(this.objectId)
            item.actressphoto = [...new Set(item.actressphoto)]
          }
          this.$store.dispatch('setPlayListData', {
            type: 'update',
            item
          })
          this.toClose()
          setTimeout(() => {
            this.get_play_list()
          }, 1000)
        } else {
          const res = await sharePlayList({
            objectId: this.objectId,
            playListId: item.id,
            playListType: item.type
          })
          this.toClose()
          this.$toast(res.tip)

          if (res.code === 200) {
            this.$emit('success')
            setTimeout(() => {
              this.get_play_list()
            }, 1000)
          }
        }
      } catch (error) {
        this.$toast('操作失败')
      } finally {
      }
    },
    toAdd() {
      this.toClose()
      this.$router.push('/playList/publish')
    }
    // toTask() {
    //   this.toClose()
    //   this.$router.push('/mine/myShouyi')
    // },
    // toVip() {
    // /playList/publish
    //   this.toClose()
    //   this.$router.push('/Mine/vip')
    // }
  }
}
</script>

<style lang="scss" scoped>
.van-popup--bottom {
  width: 6.84rem;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
  .title {
    @include flex-align-center;
    color: #333;
    font-size: 0.36rem;
    justify-content: space-between;
    height: 1.2rem;
    padding: 0 0.24rem 0 0.36rem;
    > div {
      width: 1.44rem;
      height: 0.48rem;
      @include flex-center;
      border-radius: 0.34rem;
      border: 0.5px solid #e8619d;
      color: #e8619d;
      font-size: 0.28rem;
    }
    border-bottom: 1px solid #f0f0f0;
  }
  .top {
    background: #fff;
    border-radius: 0.12rem;
  }
  .bottom-btn {
    height: 1.08rem;
    margin: 0.3rem 0;
    background: #fff;
    border-radius: 0.12rem;
    @include flex-center;
    color: #333;
    font-size: 0.32rem;
    &.checking {
      background: $gradientBg;
      color: #fff;
    }
  }
}
.playlist {
  color: #333;
  font-size: 0.28rem;
  font-weight: 500;
  padding-bottom: 0.3rem;
  > li {
    display: flex;
    padding: 0.24rem 0.32rem;
    border-radius: 0.12rem;
    .mine_top_bg_avatar {
      display: flex;
      color: #999;
      font-size: 0.2rem;
      font-weight: 400;
      height: auto;
      align-items: center;
      margin: 0.12rem 0;
      :deep() img {
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        margin: 0 0.12rem 0 0;
      }
    }
    &.active {
      background: #f0f0f0;
    }
  }
  .mine_top_bg {
    width: 1.44rem;
    margin-right: 0.24rem;
    height: 1.44rem;
    flex: none;
    border-radius: 0.08rem;
    :deep() img {
      border-radius: 0.08rem;
    }
  }
  .photo-yellow {
    position: absolute;
    left: 0.08rem;
    bottom: 0.08rem;
    width: 0.92rem;
    height: 0.38rem;
    @include flex-center;
    border-radius: 0.08rem;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 0.2rem;
    img {
      width: 0.2rem;
      margin-right: 0.05rem;
    }
  }

  .hot-item-post {
    @include flexbox();
    flex: 1;
    &-title {
    }
    &-user {
      color: #999;
      font-size: 0.2rem;
    }
    &-count {
      padding: 0.04rem 0.16rem;
      border-radius: 0.32rem;
      background: rgba(0, 0, 0, 0.1);
      @include flex-center;
    }
  }
  .subtrac {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    img {
      position: absolute;
      &:nth-child(2) {
        left: 50%;
        top: -0.09rem;
        width: 98%;
        transform: translateX(-50%);
      }
      &:nth-child(1) {
        left: 50%;
        top: -0.18rem;
        width: 94%;
        transform: translateX(-50%);
      }
    }
  }
  .check-box {
    > img {
      width: 0.32rem;
    }
  }
  .default {
    height: 1.44rem;
    flex: none;
  }
}
</style>
