<template>
  <ERefreshLoadV3 ref="NovelSearchList" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
    <div class="content">
      <div v-for="(item, index) in novelList" :key="index">
        <comicsOneCard :isNovelItem="true" width="2.18rem" height="2.86rem" :itemData="item" />
      </div>
    </div>
  </ERefreshLoadV3>
</template>

<script>
import { acg_category } from 'api/search'
export default {
  name: 'NovelSearch',
  components: {
    ComicsOneCard: () => import('components/ComicsTypeCard/comicsOneCard.vue')
  },
  props: ['navItem', 'id'],
  data() {
    return {
      pageNum: 0,
      pageSize: 20,
      param: {
        type: 3, //类型
        sortType: 0, //排序
        priceType: 0, //价格
        dateType: 0, //时间
        tagId: 0 //标签
      },
      finished: false,
      novelList: []
    }
  },

  mounted() {},

  methods: {
    //设置搜索参数
    setParam(val) {
      this.param = JSON.parse(JSON.stringify(val))
      console.log('小说列表刷新')
      this.$refs['NovelSearchList'].onRefresh()
    },
    async onLoad() {
      this.pageNum += 1
      await this.getSearch()
    },
    async onRefresh() {
      this.finished = false
      //表示处于可以下拉状态
      this.pageNum = 0
      this.novelList = []
    },

    // 获取数据
    async getSearch() {
      let param = {
        type: 3,
        sortType: this.param.sortType,
        priceType: this.param.priceType,
        dateType: this.param.dateType,
        tagId: this.param.tagId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      await acg_category(param).then(res => {
        if (res.code === 200 && res.data.novelList) {
          this.novelList = [...this.novelList, ...res.data.novelList]
          if (res.data.novelList.length < this.pageSize || !res.data.novelList) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-flow: wrap;
  margin-top: 0.24rem;
  padding: 0 0.32rem;
  > div {
    width: 2.18rem;
    margin-bottom: 0.24rem;
    margin-right: 0.14rem;
  }

  > div:nth-child(3n) {
    margin-right: 0;
  }
}
</style>
