<template>
  <div class="actor-info">
    <div class="actor-info-title">本片演员资料</div>
    <div class="actor-info-list">
      <div v-for="item in videoInfo.actor" :key="item.id" class="actor-info-item" @click="toSelect(item)">
        <EDecryptImg class="actor-img" imgURL="/image/l7/1a1/30f/ml/6600048bb4999365023e6c14964f080d.jpg">
        </EDecryptImg>
        <div class="actor-label">{{ item.name }}</div>
      </div>
    </div>
    <div v-if="selectedActor" class="actor-info-desc">
      <div>出生日期: {{ selectedActor.birthday }}</div>
      <div>所属事务所: {{ selectedActor.office }}</div>
      <div>身高三圍：{{ selectedActor.bwh }}</div>
      <div>
        {{ selectedActor.desc }}
      </div>
      <div @click="toMore">查看更多>></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PlayActorInfo',
  props: {
    videoInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedActor: null
    }
  },
  mounted() {
    this.selectedActor = this.videoInfo.actor[0]
  },
  methods: {
    toSelect(item) {
      this.selectedActor = item
    },
    toMore() {
      this.$router.push(`/discover/actor/${this.selectedActor.id}`)
    }
  }
}
</script>

<style scoped lang="scss">
.actor-info {
  color: #333333;
  .actor-info-title {
    font-size: 0.36rem;
    line-height: 0.5rem;
    margin-bottom: 0.24rem;
  }
  .actor-info-list {
    @include flexbox(flex-start);
    @include scrollbar-hide;
    overflow-x: auto;
    .actor-info-item {
      color: #999999;
      &:not(:last-child) {
        margin-right: 0.1rem;
      }
      &:nth-child(1) {
        color: #e8619d;
      }
      .actor-img {
        width: 1.64rem;
        height: 1.64rem;
        border-radius: 0.08rem;
        overflow: hidden;
      }
      .actor-label {
        font-size: 0.28rem;
        line-height: 0.4rem;
        @include textoverflow(1);
      }
    }
  }
  .actor-info-desc {
    margin-top: 0.24rem;
    colpr: #999999;
    > div {
      line-height: 0.4rem;
      font-size: 0.28rem;
      &:not(:last-child) {
        margin-bottom: 0.08rem;
      }
    }
    & > :nth-child(4) {
      @include textoverflow(2);
    }
    & > :nth-child(5) {
      color: #246bfd;
    }
  }
}
</style>
