<template>
  <div class="item" @click="toPlay(data)">
    <div class="cover">
      <ESCVideoCover class="item-bg" :imgURL="data.coverImg" :videoURL="videoURL">
        <!-- 视频分类标签 -->
        <!-- <VideoType :data="data"></VideoType> -->
      </ESCVideoCover>
      <!-- <VideoCardMask :data="data"></VideoCardMask> -->
      <div class="video_card_mask">
        <div>
          <img src="@/assets/imgs/home/playLogo.png" alt="" />
          <span>{{ data.watchTimes | numberFilter }}</span>
        </div>
        <div>{{ data.playTime | secondToDate }}</div>
      </div>
      <!-- <slot name="mask" :data="data"></slot> -->
    </div>
    <VideoCardFooter :data="data" :showMsg="false"></VideoCardFooter>
  </div>
</template>

<script>
export default {
  components: {
    // VideoType: () => import('@/components/VideoType.vue'),
    VideoCardFooter: () => import('@/components/Home/VideoCardFooter.vue'),
    VideoCardMask: () => import('@/components/Home/VideoCardMask.vue')
  },
  inject: ['getVideoPreview'],
  props: ['data'],
  data() {
    return {
      videoPath: '/api/app/media/m3u8pre/'
    }
  },
  computed: {
    isVideoPreview() {
      return this.getVideoPreview && this.getVideoPreview()
    },
    token({ $store }) {
      return $store.state.user.token
    },
    videoURL() {
      return ''
      if (!this.isVideoPreview) return ''
      if (this.data.preVideoUrl) {
        return this.videoPath + this.data.id + '?token=' + this.token
      } else {
        return ''
      }
    }
  },

  methods: {
    toPlay(data) {
      if (data) {
        this.$router.push(`/play/longVideo/${data.id}`)
      }
    },
    clickTag(item) {
      if (item.id) {
        this.$router.push({
          path: '/home/videoTagDetail',
          query: {
            id: item.id,
            name: item.name
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.item {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;

  .cover {
    width: 100%;
    height: 3.84rem;
    border-radius: 0.06rem 0.06rem 0 0;
    overflow: hidden;
    position: relative;

    .item-bg {
      height: 100%;
    }
    .video_card_mask {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 0.6rem;
      padding: 0 0.12rem;
      font-size: 0.22rem;
      font-weight: 400;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      > div {
        display: flex;
        align-items: center;

        img {
          width: 0.28rem;
          height: 0.28rem;
          margin-right: 0.04rem;
        }
      }
    }
  }
}
</style>
