<template>
  <div class="bookshelf-wrap">
    <EBackTop>
      <template v-if="showEdit" #left>
        <div style="font-size: 0.36rem; font-weight: 500; color: #333333">
          <van-icon name="arrow-left" />
          <span>书架</span>
        </div>
      </template>
      <template v-else #title>
        <div style="font-size: 0.36rem; font-weight: 500; color: #333333">书架</div>
      </template>
      <template #right>
        <!-- 右上下拉列表 -->
        <div class="toggle-nav-wrap">
          <van-checkbox v-if="showEdit" v-model="isSelectAll" checked-color="#F39200" class="btn-check"
            >全选</van-checkbox
          >
          <div v-if="showEdit" class="btn-del" @click="del">删除</div>
          <div class="btn-edit" :class="{ active: showEdit }" @click.stop="showEdit = !showEdit">
            {{ showEdit ? '取消编辑' : '编辑' }}
          </div>
        </div>
        <div class="share" @click="$router.push({ path: `/Mine/myShouyi` })">
          <div class="share-icon"></div>
          <div class="share-text">分享</div>
        </div>
      </template>
    </EBackTop>
    <ETabV2
      ref="tab"
      offset-top="1rem"
      :placeholder="false"
      :tabList="category"
      :activeId="type"
      sizeActive="0.34rem"
      sizeTab="0.3rem"
      :animated="false"
      :sticky="true"
      @change="changeId"
    >
      <!-- 列表数据 -->
      <template #default="scope">
        <component
          :is="compComponent(scope.item)"
          :ref="`childDom${scope.item.id}`"
          :showMask="showEdit"
          :showSort="true"
          :type="scope.item.id"
          cardType="collect"
        ></component>
      </template>
    </ETabV2>
  </div>
</template>
<script>
import { Dialog, Toast } from 'vant'

export default {
  components: {
    playListHistory: () => import('@/components/historyItems/playListHistory.vue'),
    postListHistory: () => import('@/components/historyItems/postListHistory.vue'),
    photoListHistory: () => import('@/components/historyItems/photoListHistory.vue'),
    comicsListHistory: () => import('@/components/historyItems/comicsListHistory.vue'),
    cartoonListHistory: () => import('@/components/historyItems/cartoonListHistory.vue'),
    novelListHistory: () => import('@/components/historyItems/novelListHistory.vue'),
    videoListHistory: () => import('@/components/historyItems/videoListHistory.vue'),
    dreamListHistory: () => import('@/components/historyItems/dreamListHistory.vue')
  },
  data() {
    return {
      //右上下拉列表
      menuObj: {
        showSetting: false
      },
      type: 8,
      category: [
        { id: 8, name: '漫画' },
        { id: 3, name: '动漫' },
        { id: 10, name: '有声小说' },
        { id: 22, name: '剧场' }
      ],
      showEdit: false,
      rightTitle: '编辑'
      //下拉
    }
  },
  computed: {
    isSelectAll: {
      get() {
        return this.$refs[`childDom${this.type}`]?.dataList.every(item => item.check)
      },
      set(val) {
        this.selectAll(val)
      }
    }
  },
  methods: {
    changeId(type) {
      this.type = type
    },
    compComponent(data) {
      switch (data.id) {
        case 13:
          return 'playListHistory'
        case 1:
          return 'videoListHistory'
        case 5:
          return 'postListHistory'
        case 9:
          return 'photoListHistory'
        case 8:
          return 'comicsListHistory'
        case 3:
          return 'cartoonListHistory'
        case 10:
          return 'novelListHistory'
        case 22:
          return 'dreamListHistory'
        default:
          return 'playListHistory'
      }
    },
    del() {
      let dataList = this.$refs[`childDom${this.type}`].dataList
      if (dataList.find(item => item.check == true)) {
        Dialog.confirm({
          title: '提示',
          message: '确认删除该记录？'
        }).then(() => {
          this.showEdit = false
          this.$refs[`childDom${this.type}`].delOne()
        })
      } else {
        Toast({
          message: '请选择删除内容！',
          position: 'top'
        })
      }
    },
    selectAll(val) {
      this.$refs[`childDom${this.type}`]?.toSelectAll(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.bookshelf-wrap {
  min-height: 100vh;
  padding-bottom: 1.66rem;
  color: #6a6a6a;
  position: relative;
  .toggle-nav-wrap {
    @include flexbox();
    .btn-check {
      ::v-deep {
        .van-icon {
          color: #d9d9d9;
        }
      }
    }
    .btn-del {
      margin: 0 0.12rem 0 0.32rem;
      display: flex;
      padding: 0.08rem 0.24rem;
      align-items: flex-start;
      border-radius: 0.08rem;
      background: #f39200;
      color: #ffffff;
    }
    .btn-edit {
      @include flex-center;
      font-size: 0.28rem;
      padding: 0.08rem 0.24rem;
      border-radius: 0.08rem;
      background: #e8619d;
      color: #fff;
      &.active {
        color: rgba(102, 102, 102, 1);
        background: #e4e4e4;
      }
    }
  }
  .share {
    position: absolute;
    top: 1.2rem;
    right: 0.27rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    width: 1rem;
    &-icon {
      width: 0.3rem;
      height: 0.3rem;
      background: url('~@/assets/imgs/share.png') center center/100% 100% no-repeat;
    }

    &-text {
      font-size: 0.3rem;
      margin-left: 0.08rem;
    }
  }
  ::v-deep {
    .van-tab--active {
      .van-tab__text {
        color: #333 !important;
        font-weight: 500;
      }
    }
    .van-tab {
      flex: 0 0 auto;
      line-height: 44px;
      .van-tab__text {
        color: rgba(102, 102, 102, 1);
      }
    }
  }
}
</style>
