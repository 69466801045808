import { render, staticRenderFns } from "./VideoCardMask.vue?vue&type=template&id=e8b8a920&scoped=true&"
import script from "./VideoCardMask.vue?vue&type=script&lang=js&"
export * from "./VideoCardMask.vue?vue&type=script&lang=js&"
import style0 from "./VideoCardMask.vue?vue&type=style&index=0&id=e8b8a920&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8b8a920",
  null
  
)

export default component.exports