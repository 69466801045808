<template>
  <van-popup
    class="save-topical"
    v-model="showClosed"
    round
    position="bottom"
    get-container="body"
    @closed="showClosed = false"
  >
    <div class="title">
      <div><span>开通会员</span>畅享完整内容！</div>
      <div>请选择会员类型</div>
    </div>
    <!-- top图片 -->
    <div class="imgWrap">
      <EDecryptImg :imgCDN="imgCDN" class="imgWrap_photo" :imgURL="activeItem.userVipImage" />
    </div>
    <!-- 会员卡类型 -->
    <div class="typeList">
      <div
        class="item"
        :class="item.id === activeItem.id ? 'active' : ''"
        v-for="(item, index) in list"
        :key="index"
        @click="selectItem(item)"
      >
        <span>¥{{ item.money / 100 }}</span>
        <span>{{ item.name }}</span>
      </div>
      <div style="width: 2rem"></div>
      <div style="width: 2rem"></div>
    </div>
    <!-- 会员特权 -->
    <img class="imgSty" src="@/assets/imgs/mine/vip_privilege.png" alt="" />
    <div class="powerList">
      <div class="p_item" v-for="item in activeItem.rightDataList" :key="item.id">
        <EDecryptImg :imgCDN="imgCDN" class="p_photo" :imgURL="item.image" />
        <div class="p_font">
          <div>{{ item.name }}</div>
          <div>{{ item.desc }}</div>
        </div>
      </div>
      <div class="p_item"></div>
    </div>
    <!-- 开通按钮 -->
    <div class="btn" @click="openPop">
      <span v-if="!activeItem.isBuy">¥{{ activeItem.money | changeGold }}/立即支付</span>
      <span class="txt" v-else>已开通</span>
    </div>
    <!-- 底部支付弹窗 -->
    <van-popup v-model="shoudBuy" position="bottom" :style="{ height: '50vh' }" class="submit-pop">
      <div class="title">请选择支付方式</div>
      <div class="price">
        <span class="price">已选择：{{ activeItem.title }}(¥{{ activeItem.money | changeGold }})</span>
      </div>
      <ul class="pay-list">
        <li
          :key="index"
          v-for="(item, index) in activeItem.rchgType"
          @click="fn_sel(item.type)"
          class="item"
          :class="{ active: item.type === payMode }"
        >
          <div class="left" v-if="item.type === 'alipay'">
            <img v-if="payMode === item.type" src="@/assets/imgs/mine/zfb2.png" alt="" />
            <img v-else src="@/assets/imgs/mine/zfb2.png" alt="" />{{ item.typeName }}
          </div>
          <div class="left" v-if="item.type === 'wechat'">
            <img v-if="payMode === item.type" src="@/assets/imgs/mine/weChat2.png" alt="" />
            <img v-else src="@/assets/imgs/mine/weChat2.png" alt="" />{{ item.typeName }}
          </div>
          <div class="left" v-if="item.type === 'overage'">
            <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
          </div>
          <div class="left" v-if="item.type === 'union'">
            <img src="@/assets/imgs/mine/bank.png" alt="" />{{ item.typeName }}
          </div>
          <div class="left" v-if="item.type === 'daichong'">
            <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
          </div>
          <div class="right">
            <div class="active" v-if="item.type === payMode"></div>
          </div>
        </li>
      </ul>
      <div class="tip-wrap">如有疑问，请咨询<span @click.stop="$router.push('/mine/setting/kf')">在线客服</span></div>
      <!-- 开通按钮 -->
      <div class="buy-btn" @click="fn_submit">
        <div class="txt">确认支付</div>
      </div>
    </van-popup>
  </van-popup>
</template>
<script>
import { vip_list, recharge_sumbit } from 'api/home'
import { userInfo } from 'api/user'
export default {
  name: 'buyVipPop',
  data() {
    return {
      showClosed: false,
      list: [],
      powerList: [],
      message: [],
      activeItem: {},
      shoudBuy: false,
      hasBuy: false,
      payMode: '',
      hourC: 0,
      hourT: 0
    }
  },
  computed: {
    user({ $store }) {
      return $store.getters['getUserInfo']
    },
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 时间差计算
    time() {
      const nowTiem = new Date().getTime()
      const newTime = Date.parse(this.activeItem.activityTime)
      const other = newTime - nowTiem
      if (other && other > 0) {
        return other
      } else {
        return 0
      }
    }
  },
  async created() {
    await this.getVipList()
    void this.getUserInfo()
    const minute = 1000 * 60
    const hour = minute * 60
    this.hourT = Date.parse(this.user.promotionExpiredAt) - new Date().getTime()
    this.hourC = (Date.parse(this.user.promotionExpiredAt) - new Date().getTime()) / hour
  },
  methods: {
    // 选中会员卡
    selectItem(item) {
      this.activeItem = item
    },
    // 获取用户信息
    async getUserInfo() {
      const res = await userInfo()
      if (res && res.code === 200) {
        this.$store.dispatch('setUserInfo', res.data)
      }
    },
    // 获取会员卡列表
    async getVipList() {
      try {
        const res = await vip_list()

        if (res.code === 200) {
          this.list = res.data[0]?.cardInfoList

          this.activeItem = this.list[0]
          this.powerList = this.list[0]?.rightDataList
          this.payMode = this.list[0]?.rchgType[0]?.type
        } else {
          return this.$toast(res.tip || '请求错误，请稍后再试！')
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求错误，请稍后再试！')
      }
    },
    openPop() {
      if (this.user.vipType == 11 && this.user.vipExpire > 0) {
        return this.$toast('您已成为商家卡用户，无法购买其他会员卡!')
      }
      if (this.activeItem.isNewUserCard && this.hourC < 0) {
        return this.$toast('当前会员卡必须在24小时内购买！')
      }
      if (!this.activeItem.isBuy) {
        this.shoudBuy = true
      }
    },
    // 选择支付方式
    fn_sel(type) {
      this.payMode = type
    },
    // 支付金币
    async fn_submit() {
      if (!this.payMode) return this.$toast('请选择支付通道！！！')
      this.$toast.loading({
        duration: 0,
        message: '跳转中请稍后'
      })
      const res = await recharge_sumbit({
        payAmount: this.activeItem.money,
        payMode: this.payMode,
        productId: this.activeItem.id,
        rchgUse: this.activeItem.rchgUse
      })
      if (res.code === 200) {
        this.showPop = false
        if (!res.data) {
          this.hasBuy = true
          this.getUserInfo()
          this.$toast('购买成功！！！')
        }
        if (res.data && res.data.isOpenNewBrowser) {
          this.downloadFileByTagA(res.data.payUrl)
        }
        this.$toast.clear()
      } else {
        this.$toast.clear()
        this.$toast(res.tip)
      }
    },
    /* 利用a标签跳转safari浏览器机制 */
    downloadFileByTagA(fileUrl) {
      const TagA = document.createElement('a')
      TagA.href = fileUrl
      // TagA.target = '__blank';

      TagA.className = 'oInput'
      TagA.style.display = 'none'

      // 兼容ios safari浏览器
      const e = document.createEvent('MouseEvent')
      e.initEvent('click', false, false)
      TagA.dispatchEvent(e)
    }
  }
}
</script>

<style scoped lang="scss">
.save-topical {
  border-radius: 0.42rem 0.42rem 0 0;
  padding: 0 0.32rem 1rem;
  background: #fff;
  height: 75%;
  overflow-y: auto;
  width: 100%;
}

.title {
  text-align: center;
  margin: 0.5rem 0 0.2rem;

  > div:first-child {
    font-size: 0.28rem;
    color: #666;

    span {
      color: #e8619d;
    }
  }

  > div:last-child {
    margin-top: 0.2rem;
    color: #282828;
    font-size: 0.32rem;
  }
}

.imgWrap {
  width: 6.86rem;
  height: 4.4rem;
  border-radius: 0.2rem;

  .imgWrap_photo {
    width: 100%;
  }
}

.typeList {
  display: flex;
  margin-top: 0.3rem;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 0.24rem;

  .item {
    position: relative;
    width: 2rem;
    height: 1.76rem;
    border-radius: 0.2rem;
    border: 1px solid #ffd0e5;
    background: linear-gradient(180deg, #fff 0%, #ffdfed 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.3rem;

    > span:first-child {
      color: #ff75b3;
      font-size: 0.4rem;
    }
  }

  .active {
    color: #fff;
    border: 1px solid #f0599d !important;
    background: linear-gradient(128deg, #ffea7a 12.27%, #ff74b2 39.16%, #ff74b2 83.71%) !important;

    > span:first-child {
      color: #fff !important;
    }
  }
}

.imgSty {
  width: 6.86rem;
  height: 0.36rem;
  margin: 0 auto 0.2rem;
}

.powerList {
  font-size: 0.24rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  .p_item {
    width: 48%;
    height: 0.64rem;
    display: flex;
    margin-bottom: 0.4rem;

    .p_photo {
      width: 0.64rem;
      height: 0.64rem;
      margin-right: 0.15rem;
    }

    .p_font {
      flex: 1;

      > div:last-child {
        width: 2.3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 0.2rem;
        color: #999;
      }
    }
  }
}

.btn {
  z-index: 2;
  position: fixed;
  bottom: 0.3rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.5rem;
  color: #fff;
  font-size: 0.36rem;
  background: linear-gradient(101deg, #ffea7a 3.16%, #ff74b2 65.51%, #ff74b2 93.52%);
  width: 6.86rem;
  padding: 0.22rem 2.2rem;
}

.submit-pop {
  border-radius: 0.48rem 0.48rem 0 0;
  border: 1px solid #862424;
  background: #fff;
  height: 6rem;
  overflow: auto;
  font-size: 0.3rem;

  .title {
    text-align: center;
    margin: 0.36rem 0;
  }

  .price {
    color: #be9055;
    font-size: 0.28rem;
  }

  .pay-list {
    .item {
      @include flexbox();
      padding: 0 0.46rem;
      margin-top: 0.36rem;

      img {
        width: 0.54rem;
        height: 0.54rem;
        margin-right: 0.16rem;
      }

      .right {
        width: 0.28rem;
        height: 0.28rem;
        border-radius: 50%;
        border: 0.02rem solid #ed9200;
        position: relative;

        .active {
          width: 0.2rem;
          height: 0.2rem;
          background: #ed9200;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    // 选中状态
  }
}

.tip-wrap {
  text-align: center;
  margin: 0.52rem;

  span {
    color: #be9155;
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.buy-btn {
  z-index: 2;
  position: fixed;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.6rem;
  background: $btnBg;
  width: 6.5rem;
  height: 0.9rem;
  flex-shrink: 0;

  .txt {
    color: #fff;
    font-size: 0.42rem;
    font-weight: 500;
    line-height: 0.9rem;
    text-align: center;
  }
}
</style>
