<template>
  <ERefreshLoadV3 ref="PlaylistSearchList" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
    <div class="content">
      <div v-for="(item, index) in playList" :key="index">
        <PlayListShowType1 :item="item"></PlayListShowType1>
      </div>
    </div>
  </ERefreshLoadV3>
</template>

<script>
import { search } from 'api/search'
export default {
  name: 'PlaylistSearch',
  components: {
    PlayListShowType1: () => import('@/components/Home/showType/playListType/playListShowType1')
  },
  props: ['navItem', 'id'],
  data() {
    return {
      pageNum: 0,
      pageSize: 10,
      content: '',
      finished: false,
      playList: []
    }
  },

  mounted() {},

  methods: {
    //设置搜索参数
    setContent(val) {
      this.content = val
      console.log('播单列表刷新')
      this.$refs['PlaylistSearchList'].onRefresh()
    },

    async onLoad() {
      this.pageNum += 1
      await this.getSeach()
    },

    async onRefresh() {
      this.finished = false
      //表示处于可以下拉状态
      this.pageNum = 0
      this.playList = []
    },

    // 获取数据
    async getSeach() {
      await search({
        type: this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        content: this.content
      }).then(res => {
        if (res.code === 200 && res.data.playList) {
          this.playList = [...this.playList, ...res.data.playList]
          if (res.data.playList.length < this.pageSize || !res.data.playList) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      })
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-flow: wrap;
  margin-top: 0.24rem;
  padding: 0 0.32rem;

  > div {
    width: 3.36rem;
    margin-bottom: 0.24rem;
    margin-right: 0.14rem;
  }

  > div:nth-child(2n) {
    margin-right: 0;
  }
}
</style>
