<template>
  <div class="privateLetterItem">
    <div class="privateLetterItem_top">
      <span>{{ itemData.title }}</span>
      <span>{{ itemData.createdAt | dateFormat08 }}</span>
    </div>
    <div class="desc">{{ itemData.content }}</div>
    <div class="avatarMain_list">
      <span>{{ itemData.userTotal }}个人为你点赞</span>
      <ul class="avatar_list">
        <li v-for="item in itemData.userList" :key="item">
          <EDecryptImg :imgCDN="imgCDN" class="privateLetterItem_avatar" :imgURL="item.avatarUrl ? item.avatarUrl : ''">
          </EDecryptImg>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['itemData'],
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    info({ $store }) {
      return $store.getters['getUserInfo']
    }
  }
}
</script>

<style lang="scss" scoped>
.privateLetterItem {
  margin: 0.32rem 0.24rem 0.24rem 0.32rem;
  padding-bottom: 0.24rem;
  border-bottom: 0.01rem solid #f0f0f0;

  .privateLetterItem_top {
    @include flexbox();

    span {
      &:first-child {
        font-weight: 600;
      }
    }
  }
}

.avatarMain_list {
  @include flexbox($jc: flex-start);
}

.desc {
  margin: 0.24rem 0;
  color: #999;
}

.avatarMain_list {
  span {
    width: 1.6rem;
    font-size: 0.24rem;
  }
}

.avatar_list {
  @include flexbox($jc: flex-start);
  flex: 1;
  max-width: calc(100% - 1.6rem);
  overflow: auto;

  li {
    margin-right: -0.2rem;
    width: 0.52rem;
    height: 0.52rem;
  }
}
</style>
