<template>
  <!-- 单个动漫样式，宽高由外部定义 -->
  <div
    class="one-comisc-card"
    :style="{
      width: width
    }"
    @click="toDetail"
  >
    <EDecryptImg
      class="one-comisc-card_bg"
      :style="{ width: width, height: height }"
      :imgURL="itemData.coverImg ? itemData.coverImg : ''"
    >
      <!-- 视频分类标签 -->
      <!-- <VideoType :data="itemData"></VideoType> -->
      <img v-if="isNovelItem" class="isNovelIcon" src="@/assets/imgs/acg/ysxh_left_icon.png" alt="" />
      <slot name="mask" :data="itemData"></slot>
    </EDecryptImg>
    <AcgCardFooter :data="itemData"></AcgCardFooter>
  </div>
</template>

<script>
export default {
  components: {
    AcgCardFooter: () => import('@/components/Home/AcgCardFooter.vue')
    // VideoType: () => import('@/components/VideoType.vue')
  },
  props: ['itemData', 'width', 'height', 'isNovelItem', 'isDetail'],
  data() {
    return {
      isCollect: false
    }
  },
  watch: {
    itemData(nval) {
      this.isCollect = nval
    }
  },
  mounted() {},
  methods: {
    toDetail() {
      if (!this.isNovelItem) {
        if (this.isDetail) {
          this.$router.replace(`/acg/ComicsDetail/?id=${this.itemData.id}`)
        } else {
          this.$router.push(`/acg/ComicsDetail/?id=${this.itemData.id}`)
        }
      } else {
        if (this.isDetail) {
          // this.$router.push(`/acg/NovelDetail/?id=${this.itemData.id}`)
          this.$emit('onRefresh', this.itemData.id)
        } else {
          this.$router.push(`/acg/NovelDetail/?id=${this.itemData.id}`)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.one-comisc-card {
  width: 100%;
  height: 100%;
}
.one-comisc-card_bg {
  position: relative;
  border-radius: 0.08rem;
  z-index: 1;
  :deep() {
    img {
      border-radius: 0.08rem;
    }
  }
}
.title {
  @include flexbox($jc: flex-start);
  margin-top: 0.14rem;
  span {
    @include textoverflow();
  }
  img {
    width: 0.36rem;
    height: 0.36rem;
    margin-right: 0.12rem;
  }
}
.isNovelIcon {
  width: 0.72rem;
  height: 0.72rem;
  position: absolute;

  bottom: 0.16rem;
  left: 0.16rem;
}
</style>
