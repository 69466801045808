<template>
  <div class="application">
    <EBackTop :title="'加群开车'" />
    <div class="list_main">
      <ul class="top_list">
        <li class="title">官方用户交流社群</li>
        <li class="sub_title">一起看片一起分享心得</li>
        <li class="top_list_main">
          <div class="left-img">
            <EDecryptImg :imgURL="1" class="left-img-icon" />
            <div class="title-desc">
              <p>Telegram</p>
              <p>立即加群，神秘福利等你领！</p>
            </div>
          </div>
          <p class="btn">立即加入</p>
        </li>
      </ul>
      <ul>
        <li class="sub_title">代理合作/商务合作</li>
        <li class="top_list_main">
          <div class="left-img">
            <EDecryptImg :imgURL="1" class="left-img-icon" />
            <div class="title-desc">
              <p>Telegram</p>
              <p>立即加群，神秘福利等你领！</p>
            </div>
          </div>
          <p class="btn">立即加入</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
.list_main {
  padding: 0.32rem 0.36rem;
}
.top_list {
  .title {
    color: $txtActive;
    font-size: 0.32rem;
    font-weight: 500;
  }
}
.sub_title {
  color: #666;
  font-size: 0.24rem;
  font-weight: 400;
  margin: 0.12rem 0 0.24rem 0;
}
.top_list_main {
  padding: 0.36rem 0.24rem;
  @include flexbox();
  border-radius: 0.16rem 0.16rem 0rem 0rem;
  background: var(--4, rgba(0, 0, 0, 0.04));
}
.left-img {
  @include flexbox($jc: flex-start);
  .left-img-icon {
    width: 0.8rem;
    height: 0.8rem;
    flex-shrink: 0;
    margin-right: 0.24rem;
  }
}
.btn {
  background: $gradientBg;
  color: #fff;
  padding: 0.1rem 0.24rem;
  border-radius: 0.72rem;
}
</style>
