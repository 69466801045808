<template>
  <div class="comisc_main">
    <ERefreshLoadV3 ref="PhotoSearchList" :before-refresh="onRefresh" :more-data="onLoad" :finished="finished">
      <div class="content">
        <DreamWaterFallList :list="mediaList"> </DreamWaterFallList>
      </div>
    </ERefreshLoadV3>
  </div>
</template>

<script>
import { search } from 'api/search'
export default {
  name: 'DreamSearch',
  components: {
    DreamWaterFallList: () => import('@/components/Home/listType/dreaLlist/DreamWaterFallList.vue')
  },
  props: ['navItem', 'id'],
  data() {
    return {
      pageNum: 0,
      pageSize: 10,
      content: '',
      finished: false,
      mediaList: []
    }
  },

  mounted() {},

  methods: {
    setContent(val) {
      this.content = val
      console.log('剧场列表刷新')
      this.$refs['PhotoSearchList'].onRefresh()
    },
    async onLoad() {
      this.pageNum += 1
      await this.getSearch()
    },
    async onRefresh() {
      this.finished = false
      //表示处于可以下拉状态
      this.pageNum = 0
      this.mediaList = []
    },

    // 获取数据
    async getSearch() {
      await search({
        type: this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        content: this.content
      }).then(res => {
        if (res.code === 200 && res.data.mediaList) {
          this.mediaList = [...this.mediaList, ...res.data.mediaList]
          if (res.data.mediaList.length < this.pageSize || !res.data.mediaList) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-flow: wrap;
  margin-top: 0.24rem;
  padding: 0 0.32rem;
  .rightItem {
    margin-bottom: 0.14rem;
  }
}
</style>
