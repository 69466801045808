<template>
  <van-popup v-model="show" position="left" class="ai-left-pop">
    <div>
      <img class="play_icon" src="@/assets/imgs/aiLab/left_pop1.svg" alt="" />
      <span>产品列表</span>
    </div>
    <ul>
      <li @click="toPage('/ai/uploadFace')">AI脱衣</li>
      <li @click="toPage('/ai/facePhoto?type=photo')">
        AI换脸
        <span>(图片)</span>
      </li>
      <li @click="toPage('/ai/facePhoto?type=video')">
        AI换脸
        <span>(视频)</span>
      </li>
    </ul>
  </van-popup>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false
    }
  },
  watch: {
    isShow(nval) {
      this.show = nval
    }
  },
  methods: {
    toPage(path) {
      this.$router.push(path)
      this.$emit('get_new_list')
    }
  }
}
</script>

<style lang="scss" scoped>
.ai-left-pop {
  height: 100vh;
  width: 2.6rem;
  > div {
    font-family: PingFang SC;
    font-size: 0.34rem;
    font-style: normal;
    font-weight: 500;
    padding: 1.07rem 0 0.32rem;
    margin-left: 0.36rem;
    border-bottom: 1px solid #999;
    img {
      width: 0.3rem;
      height: 0.3rem;
      margin-right: 0.07rem;
    }
  }
  > ul {
    margin-left: 0.36rem;
    font-family: PingFang SC;
    font-size: 0.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    li {
      padding: 0.64rem 0;
      border-bottom: 0.5px dashed #999;
      &:nth-child(2) {
        > span {
          color: #e8619d;
        }
      }
      &:nth-child(3) {
        > span {
          color: #e8619d;
        }
      }
    }
  }
}
</style>
