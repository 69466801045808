<template>
  <div class="my_focus">
    <EBackTop title="我的关注"> </EBackTop>
    <ETabBar :list="tabList" :tabbarConfig="tabbarConfig" :activeNum="type" @change="changeId">
      <ERefreshLoadV3
        ref="ERefreshLoadV3"
        :autoOnload="false"
        :beforeRefresh="onRefresh"
        :moreData="onLoad"
        :finished="finished"
      >
        <ul>
          <li v-for="item in dataList" :key="item.id" @click="goToUserDetail(item.id)">
            <div class="img_wrap">
              <EDecryptImg :imgCDN="imgCDN" class="avatar_img" :imgURL="item.avatar"> </EDecryptImg>
            </div>
            <div>{{ item.name }}</div>
            <div @click.stop="focusFn(item.id)">已关注</div>
          </li>
        </ul>
      </ERefreshLoadV3>
    </ETabBar>
  </div>
</template>

<script>
import { care_list } from '@/api/user'
import { care_cancel } from '@/api/home'
export default {
  data() {
    return {
      tabbarConfig: {
        barWidth: '7.5rem',
        barHeight: '0.88rem',
        fontSize: '0.32rem', //默认字体大小
        fontSizeActive: '0.32rem', //选中字体大小
        fontColor: '#333333', //默认字体颜色
        fontColorActive: '#E8619D', //选中字体颜色
        bg: '#fff', //默认背景色
        bgActive: '#fff', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#E8619D',
        lineWidth: '0.26rem', //下划线宽度 String
        sticky: true, //粘性布局
        offsetTop: '0rem', //距顶部距离
        tabFlex: true
      },
      tabList: [
        { id: 0, name: '用户' },
        { id: 1, name: '女优' },
        { id: 2, name: '网黄' }
      ],
      type: 0,
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 关注列表
      dataList: [],
      // 当前页
      pageNum: 1,
      // 当前个数
      pageSize: 10
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  created() {
    this.get_care_list()
  },
  methods: {
    changeId(activeId) {
      this.type = activeId
      this.onRefresh()
      this.get_care_list()
    },
    async focusFn(id) {
      const res = await care_cancel({ Ids: [id], userType: this.type })
      if (res.code === 200) {
        this.onRefresh()
        this.get_care_list()
      } else {
        this.$toast(res.tip)
      }
    },
    goToUserDetail(id) {
      if (this.type == 0) {
        this.$router.push(`/community/info?id=${id}`)
      } else {
        this.$router.push(`/discover/actor/${id}`)
      }
    },
    onRefresh() {
      this.pageNum = 0
      this.dataList = []
    },
    async onLoad() {
      this.pageNum++
      await this.get_care_list()
    },
    async get_care_list() {
      const res = await care_list({
        actorType: this.type
      })
      if (res.code === 200) {
        if (res.data.list && res.data.list.length) {
          this.dataList = [...this.dataList, ...res.data.list]
          if (res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.$toast(res.tip)
        }
      } else {
        this.finished = true
        return this.$toast(res.tip)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my_focus {
  :deep() {
    .van-sticky--fixed {
      top: 0.9rem !important;
    }
  }
  ul {
    height: 100%;
    padding: 0.28rem;
    li {
      display: flex;
      align-items: center;
      padding: 0.22rem 0.24rem;
      background: #f0efef;
      margin-bottom: 0.3rem;
      border-radius: 0.2rem;
      position: relative;
      .img_wrap {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 0.5rem;
        overflow: hidden;
        margin-right: 0.22rem;
      }
      > div:nth-child(2) {
        font-size: 0.3rem;
        font-weight: 600;
      }
      > div:nth-child(3) {
        position: absolute;
        right: 0.2rem;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        background: $btnBg;
        border-radius: 0.5rem;
        width: 1.2rem;
        height: 0.4rem;
        text-align: center;
        line-height: 0.4rem;
      }
    }
  }
}
</style>
